// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Button, Form, message } from "antd";
import { PageBody } from "./styled";
import { Card1 as Card, ITable, RadarChart } from "@gago/frame";
import { cloneDeep, round, sumBy } from "lodash";

import { HeaderBox } from "../../containers/header-box";
import { Card1 } from "../../containers/card1";
import { LeftModule1 } from "./left-module1";
import { LeftModule2 } from "./left-module2";

import blue from "./img/blue.png";
import green from "./img/green.png";
import yellow from "./img/yellow.png";
import { chartStyle3 as chartStyle, colorPalette } from "../../color-palette";
import { getDataS1N1, getDataS2N1 } from "../../utils/example-data";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { CropPercentChart1 } from "@gago/lib/components/crop-percent-chart-1";
import { AddOverlay } from "../../containers/add-overlay";
import GermplasmTestProvider from "../../network/providers/germplasm-test-report.provider";
import ModalForm from '@gago/lib/components/template-modal-form';

// tslint:disable-next-line: ter-max-len
import { AreaList} from "../../models/expert-database";
import { ExportPath, TestReport, ReportAddRequrest, ReportList, ReportSummary, ReportUpateRequrest, TestReportDistribute, TestReportTrend, TestReportTypeDistribute, ReportListRequest } from "../../models/germplasm-test-report";
import { CropCategory } from "../../models/germplasm-database";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import { PaginationConfig } from "antd/lib/table";
import ExpertDataBaseProvider from "../../network/providers/expert-database.provider";
import { FormComponentProps } from "antd/lib/form";


const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", ""],
  data: [],
  suffix: ["", "", "", "份"],
  prefix: ["", "", "", ""],
};

const dataS1N1: ITable<1, 1> = {
  title: "",
  subtitle: "总份数",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", ""],
  data: [],
  suffix: ["", "", "份"],
  prefix: ["", "", ""],
};

const inputArray = [
  {
    label:'种质编号',
    field:'accessionNumber',
    placeholder:'请输入种质编号',
    value:''
  },{
    label:'天赋河套编号',
    field:'genebankNumber',
    placeholder:'请输入天赋河套编号',
    value:'无'
  },{
    label:'检测单位',
    field:'detectionDepartment',
    placeholder:'请输入检测单位',
    value:''
  },{
    label:'送检单位',
    field:'inspectDepartment',
    placeholder:'请输入送检单位',
    value:''
  }
]
const dateInfo = {
  label:'检测时间',
  field:'testTime'
}
interface S {
  /** 对话框是否可见 */
  visible: boolean;
  /** 汇总 */
  reportSummary?: ReportSummary;
  /** 趋势 */
  reportTrend?: TestReportTrend[];
  /** 类型分布 */
  testReportTypeDistribute?: TestReportTypeDistribute[];
  /** 各省分布 */
  regionSummary?: TestReportDistribute[];
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 种质数据列表 */
  reportList?: ReportList;
  /** 顶部数据搜索 */
  topSearch?: any;
  /** 当前页数 */
  pagination?: PaginationConfig;
  // 导入导出弹框是否可见
  isShow:boolean;
  // 省市区域信息
  areaList:AreaList[]
  // 存储 this.props.form
  formMap?:any;
  // 上传文件的url地址
  uploadUrl:string;
  // 导入导出下载模板请求所需参数
  requestKeys:string[];
  // 选择的数据行数
  selectRowKeysCopy:string[];
  /** 顶部卡片类型 */
  cardType?: number;
  /** 顶部搜索表单 */
  topForm?: FormComponentProps["form"];
}

/**
 * 种质测试报告管理
 * @author maoshengtao
 */
export class GermplasmTestReport extends React.PureComponent<{}, S> {

  state: S = {
    visible: false,
    topSearch: {},
    isShow:false,
    uploadUrl:'/api/v1/plant/file/upload',
    requestKeys:['accessionNumber',  'inspectDepartment', 'detectionDepartment', 'areaName', 'testTime'],
    selectRowKeysCopy:[],
    areaList:[]
  };

  componentDidMount() {
    this.getCropCategory();
    this.getStorageYears();
    this.getResourceSummary();
    this.getResourceTrend();
    this.getCategorySummary();
    this.getRegionSummary();
    this.getAreaList();
  }

  /** get 作物分类 */
  getCropCategory = async () => {
    const cropCategory = await GermplasmDatabaseProvider.getCropCategory();
    /** 第一次加载列表项 */
    if (cropCategory && cropCategory.length > 0) {
      this.setState({
        topSearch: {
          oneLevel: cropCategory[0].name,
          twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        }
       })
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    this.setState({ cropCategory });
  }

  /** get 入库年份 */
  getStorageYears = async () => {
    const storageYears = await GermplasmDatabaseProvider.getStorageYears() || undefined;
    this.setState({ storageYears });
  }

  /** get 列表 */
  getResourceList = async (req: ReportListRequest) => {
    const { cardType } = this.state;
    const params: ReportListRequest = {
      ...req,
      oneLevel: req.oneLevel === "全部" ? undefined : req.oneLevel,
      twoLevel: req.twoLevel === "全部" ? undefined : req.twoLevel,
      isExistReport: 1,
    }
    GermplasmTestProvider.getReportList(params).then((reportList) => {
      this.setState({ reportList });
    }).catch((err) => {
      message.error("获取列表失败");
    });
  }

  /**获取省市区地址 */
  getAreaList = async () => {
    const areaList = await ExpertDataBaseProvider.getAreaList() || [];
    this.setState({areaList})
  }

  /** get 汇总 */
  getResourceSummary = async () => {
    const reportSummary = await GermplasmTestProvider.getReportSummary() || undefined;
    this.setState({ reportSummary });
  }

  /** get 趋势 */
  getResourceTrend = async () => {
    const reportTrend = await GermplasmTestProvider.getTestReportTrend() || undefined;
    this.setState({ reportTrend });
  }

  /** get 测试报告类型分布 */
  getCategorySummary = async () => {
    const testReportTypeDistribute = await GermplasmTestProvider.getTestReportTypeDistribute() || undefined;
    this.setState({ testReportTypeDistribute });
  }

  /** get 测试报告分布 */
  getRegionSummary = async () => {
    const regionSummary = await GermplasmTestProvider.getTestReportDistribute() || undefined;
    this.setState({ regionSummary });
  }

  /** top search */
  onTopSearch = (topSearch: any) => {
    const { pagination } = this.state;
    this.handleCardClick(0);
    this.setState({ topSearch }, () => {
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.报告年份,
        accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        inspectDepartment: topSearch.送检单位,
        size: 10,
        page: pagination && pagination.current || 1,
      });
    });
  }

  /** 删除种质资源数据 */
  resourceDel = async (id: string) => {
    GermplasmDatabaseProvider.resourceDel(id).then((data) => {
      if (data && data.code === 200) {
        message.success("删除成功");
        const { topSearch } = this.state;
        this.getResourceList({
          oneLevel: topSearch.作物分类,
          twoLevel: topSearch.二类名称,
          year: topSearch.入库年份,
          accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
          accessionName: topSearch.种质名称,
          inspectDepartment: topSearch.送检单位,
          size: 10,
          page: 1,
        });
      } else {
        message.error("删除失败");
      }
    }).catch((err) => {
      message.error("删除失败");
    });
  }

  /** 处理分页编号请求 */
  handlePagination = (pagination: PaginationConfig) => {
    if (pagination && pagination.current) {
      const { topSearch, cardType } = this.state;
      this.setState({ pagination });
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.入库年份,
        accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        inspectDepartment: topSearch.送检单位,
        size: 10,
        page: pagination.current,
        provinceName: cardType === 3 ? "内蒙" : undefined,
      });
    }
  }
    // 获取表单的selectRowKeys
    handleSelectRowKeys = (params:string[]) => {
      this.setState({
        selectRowKeysCopy:params
      })
    }
  
    // 处理导入导出弹框是否开启
    handleModalShow = ()  => {
      this.setState({
        isShow:true
      })
    }
  
    // 关闭导入导出弹框
    handleCancelModal = () => {
      this.setState({
        isShow:false
      })
    }
  
  
    // 处理导出操作
    handleExportData = async () => {
      const { selectRowKeysCopy, reportList } = this.state;
      let ids:string[] = [];
      const token = window.sessionStorage.getItem("token") as string;
      if (selectRowKeysCopy && selectRowKeysCopy.length == 0) {
        message.info('您还没有选择导出的数据！')
      } else {
        // selectRowKeysCopy && selectRowKeysCopy.forEach((item:string) => {
        //   if (reportList &&  reportList.list && reportList.list.length > 0) {
        //     const ind:any = reportList.list.findIndex(resource => resource.accessionNumber)
        //     ids.push(reportList.list[ind].a)
        //   }
        // })
        const idString:string = selectRowKeysCopy.join(',');
        const data = await GermplasmTestProvider.getReportPath({accessionNumbers:idString});
        
        message.info('文件正在压缩')
        if (data.message === '压缩成功') {
          GermplasmTestProvider.download(`/output/${data.path}`);
          // Germpla.download(`/output/${data.path}`)
          // window.location.href =`${window.config.publisher}/api/v1/output/${data.path}?token=${token}`
        }
      }
    } 
  
    // 处理导入确定
    handleImport = async (params:any) => {
      if (!params.reportUrl) {
        return message.error('请选择导入文件')
      }
      await GermplasmTestProvider.addTestReport(params).then(data => {
        if (data?.code === 200) {
          this.handleCancelModal();
          message.success('导入成功，请稍后刷新查看最新数据')
        }
      }).catch(err => {
        message.error('操作失败')
      });
      
    }

  render() {
    const { reportList, cropCategory, storageYears, areaList,requestKeys, uploadUrl, pagination } = this.state;
    return(
      <HeaderBox
        names={["种质测试报告管理"]}
      >
        <PageBody>

          <div className="page-left">
            <div className="top-card-list">
              {this.renderTopCardList()}
            </div>
            <div className="page-left-bottom">
              <div className="search-module">
                <LeftModule1
                  onTopSearch={this.onTopSearch}
                  cropCategory={cropCategory}
                  storageYears={storageYears}
                  onLoad={this.topFormLoad}
                />
              </div>
              {this.renderButtonList()}
              <div className="table-module">
                <LeftModule2
                  reportList={reportList}
                  resourceDel={this.resourceDel}
                  handlePagination={this.handlePagination}
                  handleSelectRowKeys={this.handleSelectRowKeys}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
          <div className="page-right">
            {this.renderRightCard1()}
            {this.renderRightCard2()}
            {this.renderRightCard3()}
          </div>
          <ModalForm
            visible={this.state.isShow}
            cropCategory={cropCategory}
            dateInfo={dateInfo}
            areaList={areaList}
            uploadUrl={uploadUrl}
            inputArray={inputArray}
            requestKeys={requestKeys}
            onOk={this.handleImport}
            onCancel={this.handleCancelModal}
          />
        </PageBody>
      </HeaderBox>
    );
  }


  renderRightCard1 = () => {
    const { reportTrend } = this.state;
    if (!reportTrend) {
      return null;
    }
    const chartData = cloneDeep(dataS2N1);
    chartData.data = reportTrend.map((item) => {
      return [`${item.year}`, "测试报告增加趋势", `${item.year}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"测试报告增加趋势"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
        <AxisChart
          chartData={chartData}
          colorPalette={{ ...colorPalette, subColor: ["#f78011", ...colorPalette.subColor] }}
          basicConfigOption={{
            seriesType: "line",
            lineSmooth: true,
          }}
        />
        </div>
      </Card>
    );
  }

  renderRightCard2 = () => {
    const { testReportTypeDistribute } = this.state;
    if (!testReportTypeDistribute) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = testReportTypeDistribute.map((item) => {
      return [`${item.classify}`, `${item.classify}`, round(item.count, 2)];
    });
    chartData.title = `${sumBy(chartData.data, (item) => item[2])}`;
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"测试报告类型分布"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ minHeight: 200, display: "flex", alignItems: "center"  }}>
          <CropPercentChart1
            theme="horizontal"
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#2970ff", "#00aaff", "#70e2ff", "#ffb300", "#ff5275", ...colorPalette.subColor],
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard3 = () => {
    const { regionSummary } = this.state;
    if (!regionSummary || regionSummary && regionSummary.length === 0) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = regionSummary.map((item) => {
      return [`${item.region}`, `${item.region}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"测试报告各省分布"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
          <RadarChart
            theme="light"
            colorPalette={colorPalette}
            chartData={chartData}
          />
        </div>
      </Card>
    );
  }

  renderButtonList = () => {
    return (
      <div className="button-list">
        <div className="button-list-left">
          {/* <Button onClick={this.handleAddClick}>
              新增
          </Button> */}
          {/* <Button type="primary" style={{ margin: "0px 10px" }}  >
              下载导入模板
          </Button> */}
        </div>
        <div className="button-list-right">
          <Button
            icon="download"
            onClick={this.handleModalShow}
          >
              导入
          </Button>
          <Button
            onClick={this.handleExportData}
            icon="export"
            style={{ margin: "0px 10px" }}
          >
              导出
          </Button>
        </div>
      </div>
    );
  }

  /** top form load */
  topFormLoad = (form: FormComponentProps["form"]) => {
    this.setState({ topForm: form });
  }

  /** handle clear top search */
  handleClearTopSearch = () => {
    const { topForm } = this.state;
    this.setState({ topSearch: {} });
    topForm && topForm.resetFields();
  }

  /** handle top Card click */
  handleCardClick = (n: number) => {
    if (n === this.state.cardType) {
      return;
    }

    const { topForm, cropCategory } = this.state;
    if (n === 1 && cropCategory) {
      this.handleClearTopSearch();
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    if (n === 2 && cropCategory) {
      this.handleClearTopSearch();
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }

    if (n === 3 && cropCategory) {
      this.handleClearTopSearch();
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        provinceName: "内蒙",
      });
    }

    this.setState({ cardType: n });
  }

  /** render 顶部卡片列表 */
  renderTopCardList = () => {
    const { reportSummary, cardType } = this.state;
    if (!reportSummary) {
      return null;
    }
    return (
      <>
        {
          [
            {
              title: reportSummary && reportSummary.allCount || 0,
              suffix: "(条)",
              description: "种质检测报告数量",
              img: green,
              type: 1,
            },
            {
              title: reportSummary && reportSummary.reportCount || 0,
              suffix: "(份)",
              description: "有检测报告种质数量",
              img: yellow,
              type: 2,
            },
            {
              title: reportSummary && reportSummary.bmReportCount || 0,
              suffix: "(份)",
              description: "巴彦淖尔市种质检测报告数量",
              img: blue,
              type: 3,
            },
          ].map((item) => {
            return (
              <Card1
                style={{ width: 276 }}
                active={cardType === item.type}
                onClick={this.handleCardClick.bind(this, item.type)}
                key={item.title}
                title={item.title}
                suffix={item.suffix}
                description={item.description}
                img={item.img}
              />
            );
          })
        }
      </>
    );
  }

}
