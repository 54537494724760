import React, { Component } from "react";
import { observable, action, transaction } from "mobx";
import { observer } from "mobx-react";
import { Button, Table, Popconfirm, Input } from "antd";
import { ColumnProps } from "antd/lib/table";

import { UserModalComponent, FormData } from "./user-modal";
import UserManageStore from "../user-manage.store";
import UserManageService, { UserInfo } from "../user-manage.service";

import styles from "./table.module.scss";
import moment from "moment";

const { Search } = Input;
const pagination = { size: "small", showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ["5", "10", "20"] };
// tslint:disable:jsx-no-lambda
@observer
export class TableComponent extends Component {

  @observable title = "";
  @observable visible = false;
  @observable confirmLoading = false;
  @observable formData: FormData = {
    displayName: "",
    // @ts-ignore
    telephone: null,
    // @ts-ignore
    departmentId: null,
    // @ts-ignore
    dataAuthorityId: null,
    role: "",
    // @ts-ignore
    roleId: undefined,
    // @ts-ignore
    password: undefined,
  };
  @observable isEdit = false;

  componentWillMount() {
    UserManageStore.resetDepartmentId();
  }

  // tslint:disable no-unused-variable
  private columns: Array<ColumnProps<UserInfo>> = [
    {
      title: "序号",
      key: "index",
      className: styles.tableColumnIndex,
      // @ts-ignore
      render: (text, record, index) => index + 1,
    },
    {
      title: "姓名",
      dataIndex: "displayName",
      className: styles.tableColumnName,
    },
    {
      title: "角色",
      dataIndex: "roleName",
      className: styles.tableColumnName,
    },
    {
      title: "联系方式",
      dataIndex: "telephone",
      className: styles.tableColumnPhone,
    },
    {
      title: "更新时间",
      dataIndex: "updatedAt",
      className: styles.tableColumnTime,
      render: (text: string) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "操作",
      key: "action",
      className: styles.tableColumnAction,
      // @ts-ignore
      render: (text, record) => {
        return (
          <>
            <Popconfirm title="确认要删除此用户吗？" onConfirm={this.deleteUserById(record.userId)}>
              <span className={styles.tableAction}>删除</span>
            </Popconfirm>
            <span className={styles.tableAction} onClick={() => this.editUser(record)}>编辑</span>
          </>
        );
      },
    },
  ];

  private deleteUserById(id: number) {
    return () => {
      UserManageService.deleteUser(id).then(() => {
        UserManageService.getAllUsers();
      });
    };
  }

  @action.bound
  private editUser(record: UserInfo) {
    transaction(() => {
      this.formData.userId = record.userId;
      this.formData.roleId = record.roleId;
      this.formData.displayName = record.displayName;
      this.formData.telephone = record.telephone;
      this.formData.departmentId = record.departmentId;
      this.formData.dataAuthorityId = record.dataAuthorityId;
      this.title = "编辑用户";
      this.visible = true;
      this.isEdit = true;
    });
  }

  @action.bound
  private createUser() {
    transaction(() => {
      this.formData.displayName = "";
      // @ts-ignore
      this.formData.telephone = null;
      // @ts-ignore
      this.formData.departmentId = null;
      // @ts-ignore
      this.formData.dataAuthorityId = null;
      // @ts-ignore
      this.formData.roleId = undefined;
      this.title = "新建用户";
      this.visible = true;
      this.isEdit = false;
    });
  }

  @action.bound
  private cancelModal() {
    this.title = "";
    this.visible = false;
  }

  @action.bound
  private setConfirmLoading(value: boolean) {
    this.confirmLoading = value;
  }

  @action.bound
  private submitForm = async ({ userId, ...data }: FormData) => {
    this.setConfirmLoading(true);
    if (this.title === "新建用户") {
      await UserManageService.createUser({ username: data.telephone, ...data });
    } else {
      // @ts-ignore
      await UserManageService.updateUser(userId, data);
    }
    await UserManageService.getAllUsers();
    this.setConfirmLoading(false);
    this.cancelModal();
  }

  handleSearchUsers = (value: string) => {
    UserManageService.getAllUsers(value);
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.search}>
          <div className={styles.searchInput}>
            <Search
              placeholder="请输入姓名或联系方式"
              onSearch={this.handleSearchUsers}
              style={{ width: 200 }}
            />
          </div>
          <Button
            type="primary"
            icon="plus"
            className={styles.createUserButton}
            onClick={this.createUser}
          >新建用户
          </Button>
        </div>
        <Table
          className={styles.table}
          // @ts-ignore
          rowClassName={styles.tableRow}
          rowKey="userId"
          dataSource={UserManageStore.filterUsers}
          columns={this.columns}
          pagination={pagination}
        />
        <UserModalComponent
          title={this.title}
          visible={this.visible}
          confirmLoading={this.confirmLoading}
          onCancel={this.cancelModal}
          data={this.formData}
          submitForm={this.submitForm}
          edit={this.isEdit}
        />
      </div>
    );
  }
}
