import React from "react";
import styled from "styled-components";
// import scrollBarStyle from "../scrollbar-style";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display:none;
  }
`;

export interface AutoScrollProps {
  /** 不启用自动滚动 */
  unUseAutoScroll?: boolean;
  /** 滚动到底 首次加载时间 */
  initScrollTime?: number;
  /** 定时器滚动间隔 默认为 50ms*/
  scrollTime?: number;
}

interface AutoScrollState {

}

/**
 * 自动滚动
 * @author luyaxiang
 * @export
 * @class AutoScroll
 * @extends {React.Component}
 */
export class AutoScroll extends React.Component<AutoScrollProps, AutoScrollState> {
  /** 用来滚动的元素 */
  private scrollInstance = React.createRef<HTMLDivElement>();
  /** 更新周期指针 */
  private timeoutPointer: any;
  /** 上一个的高度 */
  private preScrollTop = -1;
  /** 两次高度相同的次数 */
  private equal = 0;
  state: AutoScrollState = {

  };

  componentDidMount() {
    const { scrollTime } = this.props;
    this.upgradeScroll(scrollTime);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutPointer);
  }

  /** 更新滚动 */
  upgradeScroll = (time?: number, l?: string) => {
    const { unUseAutoScroll, initScrollTime, scrollTime } = this.props;
    if (unUseAutoScroll) return;
    if (!this.scrollInstance) { // srender没有完成，再等会吧~
      setTimeout(() => { this.upgradeScroll(scrollTime); }, 500);
      return;
    }
    clearTimeout(this.timeoutPointer);
    this.timeoutPointer = setTimeout(() => {
      if (this.scrollInstance === null) {
        return;
      }

      if (!this.scrollInstance.current) {
        return;
      }
      const oldST = this.scrollInstance.current.scrollTop;
      if (oldST === this.preScrollTop) { // 滚到底了
        this.equal++;
        if (this.equal > 7) {
          this.equal = 0;
          this.scrollInstance.current.scrollTop = 0;
          this.upgradeScroll(initScrollTime || 2000);
        } else {
          this.upgradeScroll(scrollTime || 50);
        }
      } else {
        // tslint:disable-next-line: restrict-plus-operands
        this.scrollInstance.current.scrollTop = oldST + 1;
        this.preScrollTop = oldST;
        this.upgradeScroll(scrollTime || 50);
      }
    }, time || 50);
  }

  /** 鼠标进来 */
  handleMouseEnter = () => {
    clearTimeout(this.timeoutPointer);
  }

  /** 鼠标离开 */
  handleMouseLeave = () => {
    this.upgradeScroll(this.props.scrollTime);
  }

  render() {
    const { children } = this.props;
    return (
      <Wrapper
        ref={this.scrollInstance}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
      </Wrapper>
    );
  }
}
