import React, { useState, useEffect } from "react";
type UnPromisify<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer U> ? U : any;
// tslint:disable-next-line: ban-types
export const useProvider = <T extends (...args: any) => Promise<any>>(p: Parameters<T>, providerFun: T ) => {

  const [state, setState] = useState<UnPromisify<T>>();
  useEffect(() => {
    providerFun(...p)
      .then((res: UnPromisify<T>) => {
        setState(res);
      })
      .catch((err: any) => {
        console.log(`useProvider error, ${providerFun}: ${err}`);
      });
  }, [JSON.stringify(p)]);

  return state;
};
