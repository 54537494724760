import { BaseInfo } from "../../models/germplasm-database";

// tslint:disable
export const TESTREPORTLIST: BaseInfo[] = [
  {
      name: "测试报告0",
      list: [
          {
              name: "送检单位",
              eName: "inspect_department",
              type: "input",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "检测单位",
              eName: "detection_department",
              type: "input",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "所在地区",
              eName: "province_name",
              type: "cascader",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "检测时间",
              eName: "test_time",
              type: "date",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "报告上传",
              eName: "report_url",
              message: "请先上传报告",
              type: "upload",
              unit: null,
              selectData: [],
              required: true
          }
      ]
  }
]

export const BREEDINGREPORTLIST: BaseInfo[] = [
  {
      name: "育种报告0",
      list: [
        //   {
        //       name: "所属单位",
        //       eName: "department",
        //       type: "input",
        //       unit: null,
        //       selectData: [],
        //       required: true
        //   },
          {
              name: "育种单位",
              eName: "breeding_department",
              type: "input",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "育种类型",
              eName: "breeding_type",
              type: "select",
              unit: null,
              selectData: [
                  "分子标记辅助育种",
                  "遗传修饰育种"
              ],
              required: true
          },
          {
            name: "所在地区",
            eName: "province_name",
            type: "cascader",
            unit: null,
            selectData: [],
            required: true
        },
        {
            name: "育种时间",
            eName: "breeding_time",
            type: "date",
            unit: null,
            selectData: [],
            required: true
        },
          {
              name: "报告上传",
              message: "请先上传报告",
              eName: "report_url",
              type: "upload",
              unit: null,
              selectData: [],
              required: true
          }
      ]
  }
]