import React, { Component, PureComponent, ReactNode } from "react";
import { matchPath, Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { isEmpty, isNull, noop, isUndefined } from "lodash";
import { MapboxProvider } from "@gago-react-gl/gago-react-gl";
import { AppRoot1 } from "@gago/frame/es/app-roots/app-root-1";
import { Icon } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import { colorPalette } from "../../color-palette";

import { hot } from "react-hot-loader";
import { User } from "../../models/user";
import {
  ExtendRouterConfig, GERMPLAS_ROUTER_CONFIG, RETURN_HOME_ROUTE_CONFIG,
  BREEDING_ROUTER_CONFIG, EXTENSION_ROUTER_CONFIG, GERMPLAS_PATH, EXTENSION_PATH, BREEDING_PATH,
} from "./route-config";
import { withContext } from "../../contexts";
import user, { UserContext, UserState } from "../../contexts/user";
import Login from "../../pages/login";
import Home from "../../pages/home";
import { RootStyle } from "./style";
import { AdaptBody } from "@gago/lib/components/adapt-body";
import { NavIcon } from "@gago/lib/components/route-nav/styled";
import styled from "styled-components";
import { RqCode } from "../../pages/rq-code";
import SyUrlContextProvider, { IframePage } from "../../pages/Iframe-page";
import { serverConfig } from "../../network/server-config";
import SourceInfo from "../../pages/source-info";
import { TracingSource } from "../../pages/tracing-source";

moment.locale("zh-cn");

// tslint:disable:jsx-no-lambda jsx-no-multiline-js
// tslint:disable:variable-name jsx-no-lambda
// 根据routeInfo构建Router
const getRouter = (parentPath: string, routerList: ExtendRouterConfig[]): ReactNode => {
  if (!Array.isArray(routerList)) {
    throw TypeError(`this param(routerList) is array type, but get a ${typeof routerList}`);
  }

  return routerList.map((routerItem) => {
    const { key, component, routes } = routerItem;
    const currentPath = parentPath + key;
    if (!isEmpty(routes)) {
      return getRouter(currentPath, routes);
    }
    if (component) {
      return (<Route exact key={currentPath} path={currentPath} component={component} />);
    }
    return null;
  });
};

/**
 * 根据pathname获取当前route的信息
 * @author ZQ.xia
 * @param routers
 * @param parentPath
 * @param pathname
 */
export const getRouteInfoFromPath = (routers: ExtendRouterConfig[], parentPath: string, pathname: string) => {
  let routersInfo: ExtendRouterConfig;
  const getCurrentRouteInfo = (routerList: ExtendRouterConfig[], curParentPath: string) => {
    if (!Array.isArray(routerList)) {
      throw TypeError(`this param(routerList) is array type, but get a ${typeof routerList}`);
    }

    routerList.forEach((routerItem) => {
      const { key, routes } = routerItem;
      const currentPath = curParentPath + key;
      const isMatch = matchPath(pathname, { path: currentPath, exact: false, strict: false });
      if (isNull(isMatch)) return;

      if (!isEmpty(routes)) { getCurrentRouteInfo(routes, currentPath); }
      const isExactMatch = matchPath(pathname, { path: currentPath, exact: true, strict: false });
      if (!isNull(isExactMatch)) routersInfo = routerItem;
    });
  };
  getCurrentRouteInfo(routers, parentPath);
  // @ts-ignore
  return routersInfo ? routersInfo : null;
};

/**
 * 获取routeConfig前通过权限验证
 * @author ZQ.xia
 * @param routers 路由(菜单)配置项
 * @param parentPath 该路由(菜单)配置项的父级pathname
 * @param userInfo 用户信息（内包含了路由权限信息）
 */
export const getRouteWithPermission = (routers: ExtendRouterConfig[], parentPath: string, userInfo: User) => {
  const { web } = userInfo.rolesInfo;
  /** 如果是admin用户，可以看到所有界面 */
  if (userInfo.role === "admin") return routers;
  /** 如果没有任何权限，则无法看到任何界面 */
  if (isUndefined(web) || isEmpty(web)) return [];

  return routers.reduce<ExtendRouterConfig[]>((accu, currentRoute) => {
    const { permission, routes, key } = currentRoute;
    let routesWithPermission: ExtendRouterConfig[] = [];
    const currentPath = parentPath + key;
    /** 如果不需要进行权限控制，则其包括子菜单直接放开权限，否则进入下一步权限验证阶段 */
    if (!permission) return [...accu, currentRoute];

    // 验证是否有权限
    const hasPermission = web.some((pathname) => {
      const pathnameItem = matchPath(pathname, { path: currentPath, exact: true, strict: false });
      return !isNull(pathnameItem);
    });
    if (!hasPermission) return accu;

    /** 如果有子级菜单，当前菜单项是否需要 看 是否有子级路由存在权限 */
    if (!isEmpty(routes)) {
      routesWithPermission = getRouteWithPermission(routes, currentPath, userInfo);
      if (isEmpty(routesWithPermission)) return accu;
      return [...accu, { ...currentRoute, routes: routesWithPermission }];
    }

    return [...accu, currentRoute];
  }, []);
};

interface AppState {
}

const AppRootWithStyle = styled(AppRoot1)`
  .nav .nav-container .logo {
    height: auto;
    padding: 0;
  }

  .app-children {
    overflow: unset;
    width: calc(100% - 200px);
  }
`;

/**
 * 应用入口
 *
 * @author 张卓诚
 * @date 2018-12-29
 * @class App
 * @extends {(Component<LoadingState & RouteComponentProps & UserState>)}
 */
class App extends PureComponent<RouteComponentProps & UserState, AppState> {
  state = {
    // 因为MapContex用于MapGL的内部，所以可以保证运行时map一定不是undefined
    /** mapbox实例 */
    map: undefined as unknown as mapboxgl.Map,
  };

  /** 添加返回导览页的路由 */
  addReturnHomeRoute = (routers: ExtendRouterConfig[]) => {
    return [
      ...routers,
      RETURN_HOME_ROUTE_CONFIG,
    ];
  }

  /** 获取当前系统的第一个路由 */
  getSystemFirstRoute = (routerList: ExtendRouterConfig[], parentPath: string): string => {
    if (!Array.isArray(routerList)) {
      throw TypeError(`this param(routerList) is array type, but get a ${typeof routerList}`);
    }
    if (isEmpty(routerList)) return "";
    const firstRoute = routerList[0];
    const currentPathname = `${parentPath}${firstRoute.key}`;
    if (!isEmpty(firstRoute.routes)) return this.getSystemFirstRoute(firstRoute.routes, currentPathname);
    return currentPathname;
  }

  /**
   * 种植资源模块
   *
   * @param {RouteComponentProps} { match }
   * @memberof App
   */
  renderGermplasRoute = ({ match }: RouteComponentProps) => {
    const { location: { pathname }, userInfo } = this.props;
    const routerConfigWithPermission = getRouteWithPermission(GERMPLAS_ROUTER_CONFIG, match.path, userInfo);
    const currentRouteInfo = getRouteInfoFromPath(routerConfigWithPermission, match.path, pathname);

    return (
      <AppRootWithStyle
        navAutoHide={this.isBigScreen()}
        logoConfig={{
          logo: <NavIcon>
            <div className="top">种质资源平台</div>
            <div className="bottom">黄河流域西北地区</div>
          </NavIcon>,
          miniLogo: null,
        }}
        avatarConfig={{
          isLogin: true,
          userName: this.props.userInfo.username,
          avatar: <Icon type="user" />,
          onClick: () => null,
          onLogout: this.props.logout,
        }}
        navConfig={{
          routeOnClick: noop,
          routes: this.addReturnHomeRoute(routerConfigWithPermission),
          selected: currentRouteInfo ? currentRouteInfo.key : "",
        }}
        colorPalette={colorPalette}
      >
        <Switch>
          {getRouter(match.path, routerConfigWithPermission)}
          <Redirect exact to={this.getSystemFirstRoute(routerConfigWithPermission, match.path)} />
        </Switch>
      </AppRootWithStyle>
    );
  }

  /**
   * 种植推广
   *
   * @param {RouteComponentProps} { match }
   * @memberof App
   */
  renderExtensionRoute = ({ match }: RouteComponentProps) => {
    const { location: { pathname }, userInfo } = this.props;
    const routerConfigWithPermission = getRouteWithPermission(EXTENSION_ROUTER_CONFIG, match.path, userInfo);
    const currentRouteInfo = getRouteInfoFromPath(routerConfigWithPermission, match.path, pathname);
    return (
      <AppRootWithStyle
        navAutoHide={this.isBigScreen()}
        logoConfig={{
          logo: <NavIcon>
            <div className="top">黄河流域西北地区</div>
            <div className="bottom">种质推广平台</div>
          </NavIcon>,
          miniLogo: null,
        }}
        avatarConfig={{
          isLogin: true,
          userName: this.props.userInfo.username,
          avatar: <Icon type="user" />,
          onClick: () => null,
          onLogout: this.props.logout,
        }}
        navConfig={{
          routeOnClick: noop,
          routes: this.addReturnHomeRoute(routerConfigWithPermission),
          selected: currentRouteInfo ? currentRouteInfo.key : "",
        }}
        colorPalette={colorPalette}
      >
        <Switch>
            {getRouter(match.path, routerConfigWithPermission)}
            <Redirect exact to={this.getSystemFirstRoute(routerConfigWithPermission, match.path)} />
        </Switch>
        <IframePage />
      </AppRootWithStyle>
    );
  }

  /**
   * 种植繁育
   *
   * @param {RouteComponentProps} { match }
   * @memberof App
   */
  renderBreedingRoute = ({ match }: RouteComponentProps) => {
    const { location: { pathname }, userInfo } = this.props;
    const routerConfigWithPermission = getRouteWithPermission(BREEDING_ROUTER_CONFIG, match.path, userInfo);
    const currentRouteInfo = getRouteInfoFromPath(routerConfigWithPermission, match.path, pathname);
    return (
      <AppRootWithStyle
        navAutoHide={this.isBigScreen()}
        logoConfig={{
          logo: <NavIcon>
            <div className="top">种质资源大数据平台</div>
            <div className="bottom">黄河流域西北五省</div>
          </NavIcon>,
          miniLogo: null,
        }}
        avatarConfig={{
          isLogin: true,
          userName: this.props.userInfo.username,
          avatar: <Icon type="user" />,
          onClick: () => null,
          onLogout: this.props.logout,
        }}
        navConfig={{
          routeOnClick: noop,
          routes: this.addReturnHomeRoute(routerConfigWithPermission),
          selected: currentRouteInfo ? currentRouteInfo.key : "",
        }}
        colorPalette={colorPalette}
      >
        <Switch>
          {getRouter(match.path, routerConfigWithPermission)}
          <Route path={`${match.path}/rq-code`} component={RqCode} />
          <Redirect exact to={this.getSystemFirstRoute(routerConfigWithPermission, match.path)} />
        </Switch>
      </AppRootWithStyle>
    );
  }

  /**
   * 判断是否是大屏
   * @memberof App
   */
  isBigScreen = () => {
    const { location: { pathname } } = this.props;
    return pathname.indexOf(GERMPLAS_ROUTER_CONFIG[0].key as string) !== -1;
  }

  render() {
    // @ts-ignore
    const login = <Login />;
    return (
      <RootStyle>
        <SyUrlContextProvider>
          <MapboxProvider>
            <Switch>
              <Route path="/source-info/:sourceId" component={SourceInfo} />
              <Route path="/tracing-source/:massifId" component={TracingSource}/>
              {this.props.isLogin ? (
                <Switch>
                  <Route path="/menu" component={Home} />
                  <Route path={GERMPLAS_PATH} render={this.renderGermplasRoute} />
                  <Route path={EXTENSION_PATH} render={this.renderExtensionRoute} />
                  <Route path={BREEDING_PATH} render={this.renderBreedingRoute} />
                  <Redirect to="/menu" />
                </Switch>
              ) : login}
            </Switch>
          </MapboxProvider>
        </SyUrlContextProvider>
      </RootStyle>
    );
  }
}

export default hot(module)(withRouter(withContext(UserContext)(App)));
