import styled from "styled-components";
import cardIcon from "./icon.png";

export const SourceInfoWrap = styled.div`
  width: 100%;

  .panel {
    width: 100%;
    padding: 8px 0;
    background-color: #f3f5f6;
  }

  .ant-tabs-nav-container:not(.ant-tabs-nav-container-scrolling) .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
`;

export const Card = styled.div`
  background-color: white;
  width: 100%;
  padding: 16px 8px 24px 8px;

  .title {
    height: 16px;
    margin: 0 0 0 8px;
    opacity: 0.9;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: #001617;
    margin-bottom: 16px;

    &::before {
      width: 16px;
      height: 16px;
      content: "";
      display: inline-block;
      background: url(${cardIcon}) no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
    }
  }

  .ctn {
    width: 100%;
  }

  .item {
    width: 100%;
    margin-bottom: 12px;
    line-height: 1;

    >span:first-child {
      opacity: 0.45;
      font-size: 14px;
      line-height: 1;
      color: #001617;
    }

    >span:nth-child(2) {
      opacity: 0.9;
      font-size: 14px;
      line-height: 1;
      color: rgba(0, 22, 23, 0.9);
    }
  }
`;
