import React from "react";
import styled from "styled-components";
import { Icon } from "@gago/frame";
import { Slider as AntdSlider } from "antd";
import { SliderValue, SliderProps as AntdSliderProps } from "antd/lib/slider";
import { ColorProps, Color, ICSS, ICSSProps } from "@gago/frame/es/interface";

import { GlobalColorPalette } from "../../color-palette";

type SliderProps = {
  /** 不显示底部线条 */
  hiddenBottomLine?: true;
  /** 配置信息 */
  config?: AntdSliderProps;
  /** icon 图标 */
  icon?: string;
  /** 标题 */
  title?: string;
  /** 单位 */
  unit?: string;
  /** 监听切换 */
  onChange(value: SliderValue): void;
} & ColorProps<GlobalColorPalette> &
  ICSSProps;

interface SliderState {}

/**
 * 滑动输入条
 * @author luyaxiang
 * @date 2019.6.19
 * @export
 * @class Slider
 * @extends {React.PureComponent<SliderProps, SliderState>}
 */
export class Slider extends React.PureComponent<SliderProps, SliderState>
  implements Color, ICSS {
  border = styled.div<{ hiddenBottomLine?: true }>`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: ${(p) =>
        p.hiddenBottomLine ? "transparent" : "rgba(0, 21, 23, 0.09)"};
      left: 10px;
      right: 10px;
    }

    .slider-title {
      font-family: PingFangSC;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: rgba(0, 21, 23, 0.45);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .slider-title-left {
        .slider-title-left-title {
          margin-left: 4px;
        }
      }

      .slider-title-right {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: rgba(0, 21, 23, 0.9);
        max-width: 240px;
      }
    }

    .ant-slider {
      margin: 10px 0;
    }
  `;

  handleChange = (value: SliderValue) => {
    this.props.onChange(value);
  };

  render() {
    const { config, icon, title, unit, hiddenBottomLine } = this.props;
    const max = config && config.max ? config.max : 100;
    const min = config && config.min ? config.min : 0;
    return (
      <this.border hiddenBottomLine={hiddenBottomLine}>
        <div className="slider-title">
          <div className="slider-title-left">
            {icon ? <Icon children={icon} /> : null}
            {title ? (
              <span
                title={
                  title === "土壤质量评分"
                    ? "0-25 其它 25-50 风沙土 50-75 盐土  75-100 潮土、新积土、冲积土"
                    : ""
                }
                className="slider-title-left-title"
              >
                {title}
              </span>
            ) : null}
          </div>
          <div className="slider-title-right">
            {/* <span>{title === "土壤类型" ? "" : `${min}-${max}`} {unit ? unit : null}</span> */}
            <span>
              {`${min}-${max}`} {unit ? unit : null}
            </span>
          </div>
        </div>
        <AntdSlider
          range
          defaultValue={[30, 60]}
          {...config}
          onChange={this.handleChange}
        />
      </this.border>
    );
  }
}
