import React from "react";
import { BaseMapProps,BaseMap } from "../../containers/base-map";
import { ICSSProps, ClassificationLegend3, GradientLegend1,globalColorPalette1,ClassificationLegend } from "@gago/frame";
import { Region } from "../../models/common";
import { BaMengMap } from "../../containers/bameng-map";
import { ClassificationLayer, GradientLayer,ClassificationLegendProps } from "@gago-react-gl/gago-react-gl";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import moment from "moment";
import { CountyLayer } from "../../src-anxi/containers/county-layer";
import { TownLayer } from "../../src-anxi/containers/town-layer";
import { HighTown } from "../../src-anxi/containers/high-town";
import { MAP_IDS } from "../../src-anxi/containers/config";
import { LandMarker } from "../../containers/land-marker";
import { SamplePoint } from '../../models/land-evalute';
import { colorPalette1 } from '../../src-anxi/color-palette'
// import { BaseMap } from "../../src-anxi/containers/base-map";
import  { MapMask } from './map-mask'
import { Range,CropType } from './index';
import {BaMengBigscreenMap, baMengMapConfig } from '../../containers/bameng-bigscreen-map'


interface P extends Partial<BaseMapProps>, ICSSProps {
  /** 高亮的地区 */
 region: Region;
 /** 切换高亮的地区 */
 changeRegion(region: Region): void;
 /**作物种类 */
 crop:CropType;
 sampleList:SamplePoint[];
 range:Range;
 sampleId:number;
 element:string;
 bbox:[number,number,number,number]

 changeSample(params:number,countyName:string, townName:string):void;
//  date:string;
//  timeData:string[];
}

interface S {
  /** map */
  map?: mapboxgl.Map;
}

/**
 * map
 * 
 */
export default class Map extends React.PureComponent<P, S>{

  state: S = {
    map: undefined,
  };

  onLoadMap = (map: mapboxgl.Map) => {
    this.setState({ map })
  }

  

  /** 获取茶园所在乡镇信息 */
  getActiveTownCode = () => {
    // const { teaGardensDetail } = this.state;
    // const { location: { search } } = this.props;
    // const { teaId } = qs.parse(search, { parseNumbers: true });
    // const activeTeaGardenDetail = teaGardensDetail.find(item => item.id === teaId);
    // return activeTeaGardenDetail ? activeTeaGardenDetail.code : null;
    return null;
  }
  /** 获取当前选中的茶园id */
  getActiveTeaGardenId = () => {
    // const { location: { search } } = this.props;
    const { sampleList } = this.props;
    // const { teaId } = qs.parse(search, { parseNumbers: true });
    // const activeTeaGardenDetail = teaGardensDetail.find(item => item.id === teaId);
    // return activeTeaGardenDetail ? activeTeaGardenDetail.id : null;
    return null
  }

  getSampleInfo = (id:number) => {

  }


  /** 渲染茶园marker */
  renderLandMark = () => {
    const { sampleList, sampleId } = this.props;
    // const activeTeaGardenId = this.getActiveTeaGardenId();
    return sampleList.map(item => (
      <LandMarker
        key={item.id}
        active={sampleId  === item.id}
        soilNumber = {item.soilNumber}
        villageName = {item.villageName}
        coordinates={[item.lon, item.lat] }
        colorPalette={colorPalette1}
        // name={item.name}
        // location={item.location}
        onClick={this.props.changeSample.bind(this, item.id,item.countyName,item.townName)}
      />
    ));
  }
  
  judgeElementName = (element:string) => {
    switch (element) {
      case 'bd': return { name:'factor_b',date:'20201106',insideSorts:{ c0: [1, 125], c1: [125, 135], c2: [135, 145], c3: [145, 155], c4: [155, 200] }};break;
      case 'an': return  {name:'factor_n',date:'20201103',insideSorts:{ c0: [1, 65], c1: [65, 80], c2: [80, 100], c3: [100, 120], c4: [120, 500] }};break;
      case 'ap': return {name:'factor_p',date:'20201105',insideSorts:{ c0: [100, 1500], c1: [1500, 3000], c2: [3000, 6000], c3: [6000, 9000], c4: [9000, 40000] }};break;
      case 'ak': return  {name:'factor_k',date:'20201104',insideSorts:{ c0: [1000, 7000], c1: [7000, 10000], c2: [10000, 12500], c3: [12500, 15500], c4: [15500, 300000] }};break;
      case 'ph': return  {name:'factor_ph_1',date:'20201107',insideSorts:{ c0: [700, 750], c1: [750, 800], c2: [800, 850], c3: [850, 900], c4: [900, 1000] }};break;
      case 'som': return {name:'factor_organic_1',date:'20201108',insideSorts:{ c0: [0.1, 1000], c1: [1000, 1500], c2: [1500, 2000], c3: [2000, 2500], c4: [2500, 10000] }} ;break;
    }
  }

  render() {
    const { region, crop, range ,element, changeRegion, } = this.props;
    // const formatTime = moment(new Date().toDateString()).format("YYYYMMDD"); 
    // const realTime = timeData.find(item => moment(item).format('YYYY') === date);
    // console.log(timeData,date,'mapTimdatee')
    const elementInfo = this.judgeElementName(element)
    console.log(elementInfo?.name, 11111111, elementInfo)
    return (
      <>
        <BaseMap
          
        >
          {/* <CountyLayer
          fillOption={{
            paint: {
              "fill-opacity": 0.12,
              "fill-color": "#34d0c8",
            },
          }}
          lineOption={{
            paint: {
              "line-opacity": 0.4,
              "line-color": "rgb(0, 22, 23)",
            },
          }}
        />
        <TownLayer
          lineOption={{
            paint: {
              "line-opacity": 0.2,
              "line-color": "rgb(0, 22, 23)",
              "line-dasharray": [2, 2],
            },
          }}
        />
        <MapMask
          colorPalette={colorPalette1}
          range ={range}
          cropType={crop}
        />
        <HighTown townId={this.getActiveTownCode()} />
        {this.renderLandMark()}
        {} */}
        <BaMengBigscreenMap
            
            region={region}
            changeRegion={changeRegion}
            bbox={this.props.bbox}
          />
        {this.renderLandMark()}
        {/* <GradientLayer
          id="ndvi_glass8"
          url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${realTime}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
          paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        >
          { colors => (
            <GradientLegend1
              {...{ ...colors }}
              style={{ position: "absolute", bottom: "16px", right: "416px" }}
              names={["220kg/亩以下", "220-240kg/亩", "240-260kg/亩", "260-280kg/亩","280kg/亩以上"]}
              colorPalette={globalColorPalette1}
            />
          )}
          </GradientLayer> */}
          <ClassificationLayer<{
            c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
          }>
          key={elementInfo?.name}
          id={elementInfo?.name as string}
          url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${elementInfo?.name}/${elementInfo?.date}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
          paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${elementInfo?.name}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
          insideSorts={elementInfo?.insideSorts as unknown as any}
          insideActiveSorts={"all"}
          before={baMengMapConfig.county.line}
          >
            { colors => (
              <ClassificationLegend
                title=""
                {...{ ...colors }}
                style={{ position: "absolute", bottom: "16px", right: "16px" }}
                names={['极低','低','中','高','极高']}
                colorPalette={globalColorPalette1}
              />
            )}
          </ClassificationLayer>

        </BaseMap>
      </>
    );
  }
  
}
