import React from "react";
import styled from "styled-components";

import {MAP_IDS} from "../config/map-config";
// tslint:disable-next-line: ter-max-len
import {LoadImages, Layer, SymbolLayer, Source, GeoJSONSource, LayerEvent, MapEvent, FillLayer, LineLayer} from "@gago-react-gl/gago-react-gl";

import {isUndefined, round, uniqueId, omit, identity, memoize} from "lodash";


import bg from "./type-mappoint.png";
import {PlantAll} from "../../models/germplasm-resource";

const MapWrap = styled.div`
  .disaster-legend {
    padding: 4px 4px;
    border-radius: 4px;
    border: solid 0.5px rgba(0, 187, 255, 0.45);
    background: RGBA(12, 35, 61, 0.8);

    .title {
      color: #1cb4eb;
      padding: 4px 0px;
    }

    .list {
      color: #1cb4eb;

      .colorWrap .listWrap > div {
        margin-bottom: 4px;
      }
    }
  }

  .disaster-select {
    position: absolute;
    top: 10px;
    left: 16px;
    height: 92px;

    .tab-box .big-screen-switch-button {
      width: 70px;
    }
  }

  .flag-select {
    position: absolute;
    top: 100px;
    right: 16px;
    height: 92px;

    .tab-box .big-screen-switch-button {
      width: 70px;
    }
  }
`;

// tsline:enable: variable-name

interface MapPointData {
    /** 其他 */
    [key: string]: any;

    /** 中心点经纬度 */
    center: number[];
}

interface PointMarkerProps {
    /** 使用点击 默认 hover */
    useClick?: boolean;
    /** symbol 数据集合, geojsonData 和 mapPointData 必传一个 */
    mapPointData?: MapPointData[];
    /**直接传入 geojson 数据, geojsonData 和 mapPointData 必传一个 */
    geojsonData?: any;
    /** tooltip 头部 名称 */
    tooltipTitle?: string;
    /** tooltip 数组 */
    tooltipArr?: {
        /** 类型名称 */
        name: string;
        /** 类型名称 对应的 键 */
        key: string;
        /** 类型名称 对应的 值的 单位 */
        suffix?: string;
    }[];
    /** 图层 id 确保唯一 */
    id?: string;
    /** 图层icon  图片不可以是 svg, mapbox 不支持*/
    icon?: string;
    /** 多种icon 渲染, 优先级 高于单个 icon */
    iconObj?: {
        /** 根据数据源中的哪个 propertie  渲染*/
        propertie: string;
        /** icon数组, 在有根据类别去渲染时 */
        iconArr?: {
            /** 类别，用来区分 渲染的icon */
            category: string;
            /** 图层icon  图片不可以是 svg, mapbox 不支持 */
            icon: string;
        }[];
    };
    /** symbol 配置 */
    symbolOption: Partial<SymbolLayer>;
    /** 激活的tooltip */
    activeTooltip?: {
        /** 其他 */
        [key: string]: string | number | number[];
        /** 中心点经纬度 */
        center: number[];
    };

    /** 点击icon事件 */
    handleIconClick?(data: any): void;

    /** map实例 */
    map?: mapboxgl.Map;
    /** 是否显示icon图标 default: true */
    visible?: boolean;
    maxZoom?: number;
    iconSize?: number;
}

interface PointMarkerState {
    /** 龙头企业集合 */
    activePointData: MapPointData | null;
}

/**
 * 渲染 抽稀 symbol
 * @author maoshengtao
 * @export
 * @class PointMarker
 */

export class PointMarker extends React.PureComponent<PointMarkerProps, PointMarkerState> {
    static defaultProps = {
        id: `${uniqueId("point_marker")}`,
        symbolOption: {},
        visible: true,
        maxZoom: 7,
        iconSize: 0.65
    };

    state: PointMarkerState = {
        activePointData: null,
    };

    /** 触发 tooltip 展示信息 */
    handleMarkerMouseEnter = (e: any) => {
        const {features} = e;
        const {properties, geometry} = features[0];
        const {handleIconClick} = this.props;
        handleIconClick && handleIconClick({...properties, center: geometry.coordinates});
        // this.setState({
        //   activePointData: { ...properties, center: geometry.coordinates },
        // });
    }

    /** 触发 tooltip 去除展示信息 */
    handleMarkerMouseOut = (e: any) => {
        this.setState({
            activePointData: null,
        });
    }

    componentWillReceiveProps(props: PointMarkerProps) {
        if (props.activeTooltip && props.activeTooltip !== this.state.activePointData) {
            this.setState({activePointData: props.activeTooltip});
        }
    }

    render() {
        return (
            <MapWrap>
                {this.renderPoint()}
            </MapWrap>
        );
    }

    /** render 位点 icon */
    renderPoint = () => {
        const {iconObj, geojsonData} = this.props;
        return (
            <>
                {geojsonData ? this.renderSource() : this.renderPointSource()}
                {iconObj ? this.renderIconArrPointPointMarks() : this.renderPointPointMarks()}
                {this.renderPointLayerEvent()}
                {this.renderCusorEvent()}
                {/* {this.renderPointPopupInfo()} */}
            </>
        );
    }

    /**获取 layerid */
    getLayerId = () => {
        const {id} = this.props;
        return id ? `${id}-image` : "point_distribution-image";
    }

    renderSource = () => {
        const {geojsonData, id} = this.props;
        return (
            <Source<GeoJSONSource>
                id={`${id || "point_distribution"}`}
                type="geojson"
                data={geojsonData}
            />
        );
    }

    handleSourceData = (mapPointData: MapPointData[]) => {
        const {id, iconObj} = this.props;
        let controlString = false;
        if (iconObj && iconObj.propertie) {
            controlString = true;
        }
        return mapPointData.map(item => {
            let props = item;
            if (controlString && iconObj) {
                props = {
                    ...item,
                    [iconObj.propertie]: `${item[iconObj.propertie]}`,
                };
            }
            const {center, ...properties} = props;
            return {
                properties,
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: center,
                },
            };
        });
    }

    memoHandleSourceData = memoize(this.handleSourceData)

    renderPointSource() {
        const {mapPointData, id, maxZoom} = this.props;
        if (isUndefined(mapPointData)) return null;
        const features: any = this.memoHandleSourceData(mapPointData);
        return (
            <Source<GeoJSONSource>
                id={`${id || "point_distribution"}`}
                type="geojson"
                cluster
                clusterMaxZoom={maxZoom}
                clusterRadius={10}
                data={{
                    features,
                    type: "FeatureCollection",
                }}
            />
        );
    }

    renderPointPointMarks() {
        const {mapPointData, geojsonData, id, icon, symbolOption, visible, iconSize} = this.props;
        if (isUndefined(mapPointData) && isUndefined(geojsonData)) return null;
        const back = icon ? icon : bg;
        const loadImage = {
            [`pointImg${id}`]: back,
        };
        return (
            <LoadImages key={`point_img_${id}`} {...loadImage}>
                <Layer<SymbolLayer>
                    key={`${id}_point_img`}
                    type="symbol"
                    id={`${this.getLayerId()}`}
                    source={`${id || "point_distribution"}`}
                    // before={MAP_IDS.zIndex4}
                    layout={{
                        "icon-image": `pointImg${id}`,
                        "icon-rotation-alignment": "map",
                        "icon-size": iconSize,
                        "visibility": visible ? "visible" : "none",
                        ...symbolOption.layout,
                    }}
                    {...omit(symbolOption, "layout")}
                />
            </LoadImages>
        );
    }

    renderIconArrPointPointMarks() {
        const {mapPointData, geojsonData, id, iconObj, symbolOption, visible, iconSize} = this.props;
        if ((!mapPointData && !geojsonData) || !iconObj || !iconObj.iconArr || iconObj.iconArr.length === 0) return null;
        const {iconArr, propertie} = iconObj;
        const loadImage = iconArr.reduce((pre, old) => {
            return {...pre, [old.category]: old.icon};
        }, {});
        const imgArr = iconArr.map((item) => {
            return [item.category, item.category];
        });
        return (
            <LoadImages key={`${id}_img`} {...loadImage}>
                <Layer<SymbolLayer>
                    key={`${id}`}
                    type="symbol"
                    id={`${this.getLayerId()}`}
                    source={`${id || "point_distribution"}`}
                    // before={MAP_IDS.zIndex4}
                    layout={{
                        "icon-image": {
                            type: "categorical",
                            property: `${propertie || "category"}`,
                            stops: [...imgArr],
                        },
                        "icon-rotation-alignment": "map",
                        "visibility": visible ? "visible" : "none",
                        "icon-size": iconSize,
                        ...symbolOption.layout,
                    }}
                    {...omit(symbolOption, "layout")}
                />
            </LoadImages>
        );
    }

    renderPointLayerEvent() {
        const {useClick} = this.props;
        if (useClick) {
            return (
                <LayerEvent
                    layer={`${this.getLayerId()}`}
                    type="click"
                    onChange={this.handleMarkerMouseEnter}
                />);
        }

        return (
            <>
                <LayerEvent
                    layer={`${this.getLayerId()}`}
                    type="mouseenter"
                    onChange={this.handleMarkerMouseEnter}
                />
                <LayerEvent
                    layer={`${this.getLayerId()}`}
                    type="mouseleave"
                    onChange={this.handleMarkerMouseOut}
                />
            </>
        );
    }

    mouseEnter = () => {
        const {map} = this.props;
        if (!map) return;
        map.getCanvas().style.cursor = "pointer";
    }

    mouseLeave = () => {
        const {map} = this.props;
        if (!map) return;
        map.getCanvas().style.cursor = "";
    }

    renderCusorEvent() {
        return (
            <>
                <LayerEvent
                    layer={`${this.getLayerId()}`}
                    type="mouseenter"
                    onChange={this.mouseEnter}
                />
                <LayerEvent
                    layer={`${this.getLayerId()}`}
                    type="mouseleave"
                    onChange={this.mouseLeave}
                />
            </>
        );
    }
}
