import React, { useEffect, useState, useReducer } from "react";
import moment from "moment";
import { HeaderBox } from "../../containers/header-box";
import { GermplasmBreedBody } from "./style";
import Map from "./map";
import {
  Icon,
  ITable,
  SideBar4,
  Timeline8,
  StyledAntdSelect,
} from "@gago/frame";
import { chartStyle, colorPalette } from "../../color-palette";
import { noop, round } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { AppRouteNav } from "@gago/lib/components/app-route-nav";
import { ScreenCard } from "@gago/lib/components/screen-card";
import { ScrollTable } from "../../containers/scroll-table";
import { useProvider } from "../../utils/use-provider";
import ExtensioinBigscreenProvider from "../../network/providers/extension-bigscreen.provider";
import { BaseRequest } from "../../models/extension-bigscreen";

import { LineChart } from "@gago/lib/components/line-chart";
import { tuple } from "@gago/frame/es/utils/type";
import { Carousel, Select, Modal } from "antd";
import { daysInfuture } from "../../utils/example-data";
import { scalePage } from "../../decorator/scale-page";
import { PieChart } from "@gago/lib/components/pie-chart-gago";
import { Card4 } from "../../containers/card4";
import cardBg from "./img/card-bg@2x.png";
import { CarouselCard } from "../../containers/carousel-card";
import { Card5 } from "../../containers/card5";
const { Option } = Select;

const typeList: { label: string; value: string }[] = [
  { label: "温度", value: "1" },
  { label: "湿度", value: "2" },
  { label: "降水", value: "3" },
  { label: "风速", value: "4" },
];

export const cardTypes = tuple(
  "推广全国统计",
  "销售渠道分析",
  "企业年推广种质数量",
  "天赋河套APP",
  "农事记录",
  "产品溯源",
  "LOT智能监测"
);

/** render left1 */
const RenderLeft1 = () => {
  const state = useProvider(
    [],
    ExtensioinBigscreenProvider.getExpandStatistics.bind(
      ExtensioinBigscreenProvider
    )
  );
  if (!state) {
    return null;
  }
  return (
    <div style={{ height: 200 }}>
      <PieChart
        chartData={state}
        chartStyle={chartStyle}
        colorPalette={{
          ...colorPalette,
          subColor: [
            "#00fdff",
            "#2970ff",
            "#ffb300",
            "#8643e3",
            "#ff5275",
            "#00aaff",
            "#70e2ff",
            ...colorPalette.subColor,
          ],
        }}
      />
    </div>
  );
};

/** render left2 */
const RenderLeft2 = (params: BaseRequest) => {
  const state = useProvider(
    [params],
    ExtensioinBigscreenProvider.getExpandAnalysis.bind(
      ExtensioinBigscreenProvider
    )
  );
  if (!state) {
    return null;
  }
  return (
    <div style={{ height: 200 }}>
      <PieChart
        chartData={{
          title: "",
          subtitle: "",
          description: "",
          defaultValue: [0, 0, 0],
          range: [[], [], []],
          head: ["", "", ""],
          data: [
            ["", "线下展会", 5529],
            ["", "直营", 3493],
            ["", "电商", 6297],
            ["", "其他", 8774],
          ],
          suffix: ["", "", "万元"],
          prefix: ["", "", ""],
        }}
        piechartRatio={["0%", "100%"]}
        chartStyle={chartStyle}
        colorPalette={{
          ...colorPalette,
          subColor: [
            "#ffb300",
            "#00fdff",
            "#2970ff",
            "#ff5275",
            "#00aaff",
            "#8643e3",
            "#70e2ff",
            ...colorPalette.subColor,
          ],
        }}
      />
    </div>
  );
};

/** render left3 */
const RenderLeft3 = (params: BaseRequest) => {
  const state = useProvider(
    [params],
    ExtensioinBigscreenProvider.getExpandPlant.bind(ExtensioinBigscreenProvider)
  );
  if (!state) {
    return null;
  }
  return (
    <AxisChart
      isBigScreen
      basicConfigOption={{
        // seriesType: "line",
        loopShowTooltip: true,
        loopShowTooltipTime: 5000,
      }}
      chartData={state}
      colorPalette={{
        ...colorPalette,
        subColor: ["#e77300", "#13d7c8", ...colorPalette.subColor],
      }}
    />
  );
};

/** render right1 */
const RenderRight1 = (params: BaseRequest) => {
  const state = useProvider(
    [params],
    ExtensioinBigscreenProvider.getExpandAppStatistics.bind(
      ExtensioinBigscreenProvider
    )
  );
  if (!state) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        height: "100%",
      }}
    >
      {[
        {
          title: "使用人数",
          value: round(state.user || 0, 2),
          suffix: "人",
          bg: "bg-01",
        },
        {
          title: "记录地块数",
          value: round(state.plot || 0, 2),
          suffix: "块",
          bg: "bg-02",
        },
        {
          title: "农田记事数",
          value: round(state.farmwork || 0, 2),
          suffix: "条",
          bg: "bg-03",
        },
      ].map((item) => {
        return (
          <Card4
            key={item.title}
            title={item.title}
            value={item.value}
            suffix={item.suffix}
            colorPalette={colorPalette}
            bgIcon={item.bg}
            style={{
              height: 109,
              width: 138,
            }}
          />
        );
      })}
    </div>
  );
};

/** render right2 */
const RenderRight2 = (params: BaseRequest) => {
  const state = useProvider(
    [params],
    ExtensioinBigscreenProvider.getExpandFarmwork.bind(
      ExtensioinBigscreenProvider
    )
  );
  if (!state) {
    return null;
  }
  return (
    <div style={{ height: 220, width: "100%" }}>
      <ScrollTable
        scrollTime={200}
        style={{ height: "100%", width: "100%" }}
        tableData={state}
      />
    </div>
  );
};

/** render right3 */
const RenderRight3 = (params: BaseRequest & { handleClick( url:string): void}) => {
  const {handleClick, ...rest } = params;
  const state = useProvider(
    [rest],
    ExtensioinBigscreenProvider.getExpandProductTraceability.bind(
      ExtensioinBigscreenProvider
    )
  );
  if (!state) {
    return null;
  }
  // const javaUrl = new URL(window.config.api_sy_v1).host;
  return (
    <div
      style={{
        height: 120,
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Card4
        key={"溯源产品数量"}
        title={"溯源产品数量"}
        value={round(state.count || 0, 2)}
        suffix={"块"}
        colorPalette={colorPalette}
        bgIcon={"bg-04"}
        style={{
          height: 130,
          width: 249,
          backgroundImage: `url(${cardBg})`,
        }}
      />
      <CarouselCard
        key={`${state.list}`}
        handleQrcodeClick={handleClick}
        data={
          state.list &&
          state.list.map((item, ind) => ({
            // link: `${javaUrl}/h5/index.html?traceabilitycode=${item.traceabilityCode}`,
            link: `${window.location.origin}/tracing-source/${item.id}`,
            name:  item.productName,
          }))
        }
      />
    </div>
  );
};

/** render right4 */
const RenderRight4 = (params: BaseRequest) => {
  const state = useProvider(
    [params],
    ExtensioinBigscreenProvider.getExpandLot.bind(ExtensioinBigscreenProvider)
  );
  if (!state) {
    return null;
  }
  return (
    <div
      style={{
        height: 180,
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
      }}
    >
      {[
        {
          title: "监控设备",
          value: round(state.device || 0, 2),
          bg: "bg-01",
        },
        {
          title: "气象站",
          value: round(state.monitoring || 0, 2),
          bg: "bg-02",
        },
        {
          title: "传感器",
          value: round(state.weather || 0, 2),
          bg: "bg-03",
        },
        {
          title: "控制设备",
          value: round(state.sensor || 0, 2),
          bg: "bg-04",
        },
      ].map((item) => {
        return (
          <Card5
            key={item.title}
            title={item.title}
            value={item.value}
            colorPalette={colorPalette}
            bgIcon={item.bg}
          />
        );
      })}
    </div>
  );
};

interface P {}

interface S {
  /** 当前选择的公司数据id */
  companyId: string;
  /**溯源url */
  syurl: string;
}

class ExtensionBigscreen extends React.PureComponent<P, S> {
  state: S = {
    companyId: "4",
    syurl: "",
  };

  /** handle 公司名称变化 */
  handleCompanyIdChange = (companyId: string) => {
    this.setState({ companyId });
  };

  /**二维码点击事件 */
  handleQrcodeClick = (url: string) => {
    console.log(url, "syurl")
    this.setState({ syurl: url });
  }
  render() {
    const { companyId, syurl } = this.state;
    return (
      <GermplasmBreedBody colorPalette={colorPalette}>
        <div className="header-box">
          <div className="header-name">种质推广溯源一张图</div>
        </div>
        <div className="body-content">
          <div className="left-aside">
            <ScreenCard
              className="chart-box"
              titleClassName={"title-style-1"}
              colorPalette={colorPalette}
              title={cardTypes[0]}
            >
              <RenderLeft1 />
            </ScreenCard>
            <ScreenCard
              className="chart-box"
              titleClassName={"title-style-1"}
              colorPalette={colorPalette}
              title={cardTypes[1]}
            >
              <RenderLeft2 id={companyId} />
            </ScreenCard>
            <ScreenCard
              className="chart-box"
              titleClassName={"title-style-1"}
              colorPalette={colorPalette}
              title={cardTypes[2]}
            >
              <RenderLeft3 id={companyId} />
            </ScreenCard>
          </div>
          <div className="middle-content">
            <Map handleCompanyIdChange={this.handleCompanyIdChange} />
          </div>
          <div className="left-aside">
            <ScreenCard
              className="chart-box"
              titleClassName={"title-style-1"}
              colorPalette={colorPalette}
              title={cardTypes[3]}
            >
              <RenderRight1 id={companyId} />
            </ScreenCard>
            <ScreenCard
              className="chart-box"
              titleClassName={"title-style-1"}
              colorPalette={colorPalette}
              title={cardTypes[4]}
            >
              <RenderRight2 id={companyId} />
            </ScreenCard>
            <ScreenCard
              className="chart-box"
              titleClassName={"title-style-1"}
              colorPalette={colorPalette}
              title={cardTypes[5]}
            >
              <RenderRight3 id={companyId} handleClick={this.handleQrcodeClick}/>
            </ScreenCard>
            <ScreenCard
              className="chart-box"
              titleClassName={"title-style-1"}
              colorPalette={colorPalette}
              title={cardTypes[6]}
            >
              <RenderRight4 id={companyId} />
            </ScreenCard>
          </div>
        </div>
        <Modal
          visible={!!syurl}
          closeIcon={(<div></div>)}
          // bodyStyle={{padding: 0}}
          style={{ height: "750px", padding: 0 }}
          maskClosable={true}
          onCancel={() => { this.setState({syurl: ""})}}
          footer={null}
          width={375}
        >
          <iframe frameBorder="none" src={syurl} style={{width: "100%", height: "750px"}}/>
        </Modal>
      </GermplasmBreedBody>
    );
  }
}

export default scalePage(1920, 1080)(ExtensionBigscreen);
