// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import {
  Modal,
  Button,
  Steps,
  Select,
  message,
  Spin,
  Cascader,
  Popconfirm,
} from "antd";
import { ICSSProps } from "@gago/frame";
import { ModalProps } from "antd/lib/modal";
import styled, { createGlobalStyle } from "styled-components";
import { GenerateCategory } from "./generate-category";
import { FormComponentProps } from "antd/lib/form";
import { BaseInfo } from "../../models/germplasm-database";
import { cloneDeep, get, noop, omit, partial } from "lodash";
import { BASELIST } from "./const";
import { ComponyList } from "../../models/dictionary-base";
import * as OperationInterface from "../../models/dictionary-operation";

const Wrap = styled.div`
  height: 662px;
  overflow-y: auto;
  overflow-x: hidden;
  input:-webkit-autofill {
    /* 选择历史记录的文字颜色*/
    -webkit-text-fill-color: #333 !important;
  }
`;

const GloabalStyle = createGlobalStyle`
.operatioin-overlay{
  .ant-modal{
    width: 786px!important;
    overflow-x: hidden;
  }
  .ant-modal-title{
    font-size: 20px;
  }
  .ant-form-item label{
    color: rgb(0,22,23, 0.95);
    font-size: 20px;
  }
  .ant-input[disabled] {
    border: none;
    color: rgb(0,22,23, 0.7);
    background: transparent;
    font-size: 20px;
    height: auto;
  }
  .ant-steps-item-title {
    font-size: 20px!important;
  }
  .ant-col{
    height: 55px;
  }
}
`;
type P = ICSSProps &
  ModalProps & {
    companyList?: ComponyList[];
    /** 新增 */
    onAdd?(prop: OperationInterface.ExpandAddRequest): void;
    /** 更新 */
    onEdit?(prop: OperationInterface.ExpandUpdateRequest): void;
    /** 弹窗类型 default add 选 add, edit,detail*/
    type?: "add" | "edit" | "detail";
    /** 编辑及详情的数据 */
    oldData?: OperationInterface.ExpandList["list"][number];
  };

interface S {
  /** 当前第几步骤 */
  step: number;
  /** 表单映射 */
  formMap: {
    [key: string]: FormComponentProps["form"];
  };
  /** 获取列表1 */
  list1?: BaseInfo[];
  /** 是否为loading */
  loading?: boolean;
}

/**
 * 编辑 弹窗
 * @author maoshengtao
 */
export class OperationOverlay extends React.Component<P, S> {
  isEdit: boolean;
  isDeital: boolean;
  constructor(props: P) {
    super(props);
    const { companyList, type, oldData } = this.props;

    this.isEdit = type === "edit";
    this.isDeital = type === "detail";
    const rawList = cloneDeep(BASELIST);
    if (this.isEdit || this.isDeital) {
      rawList.forEach((item) => {
        if (
          oldData?.plantInformation &&
          oldData?.plantInformation.length > 0 &&
          item.name.includes("企业推广种质面积")
        ) {
          item.list = oldData?.plantInformation.map((i) => ({
            name: `${i.year}`,
            eName: `${i.year}`,
            type: "input",
            unit: null,
            selectData: [],
            required: true,
            value: i.value,
          }));
        }

        item.list.forEach((elm) => {
          if (oldData && elm.eName in oldData) {
            elm.value =
              oldData[
                elm.eName as keyof OperationInterface.ExpandList["list"][number]
              ];
          }
        });
      });
    }

    rawList[0].list[0].selectData = companyList
      ? companyList.map((item) => item.name)
      : [];

    this.state = {
      step: 0,
      formMap: {},
      list1: rawList,
    };
  }

  /** 点击ok */
  handleOk = (e: any) => {
    const { formMap, list1 } = this.state;
    if (!list1 || (list1 && list1.length === 0)) return false;
    let result = true;
    /** 表单返回数据 */
    let tempObj: any = {};
    list1.forEach((item) => {
      formMap[item.name] &&
        formMap[item.name].validateFieldsAndScroll((err, v) => {
          if (err) {
            result = false;
          }
          tempObj[item.name] = v;
        });
    });
    if (!result) return false;
    let resultObj: OperationInterface.ExpandAddRequest = {} as OperationInterface.ExpandAddRequest;
    Object.keys(tempObj).forEach((item: any) => {
      if (item.includes("企业推广种质面积") && tempObj[item]) {
        resultObj.plantInformation = Object.keys(tempObj[item]).map((elm) => ({
          year: elm,
          value: tempObj[item][elm],
        }));
        return;
      }
      resultObj = { ...resultObj, ...tempObj[item] };
    });

    const { companyList } = this.props;
    const companyOption= companyList && companyList.find(item => item.name === resultObj["companyName"])
    resultObj = {...resultObj, companyId: companyOption?.id }

    const { onAdd, onEdit, oldData } = this.props;
    if (this.isEdit && onEdit && oldData) {
      onEdit({ ...resultObj, id: oldData.id});
      return;
    }
    onAdd && onAdd(resultObj);
  };

  /** 点击重置 */
  handleReset = (e: any) => {
    const { formMap, list1 } = this.state;
    if (!list1 || list1.length === 0) return;
    list1.forEach((item) => {
      formMap[item.name] && formMap[item.name].resetFields();
    });
    return;
  };

  render() {
    const { onCancel } = this.props;
    const { loading } = this.state;
    return (
      <>
        <Modal
          footer={this.renderModalFooter()}
          maskClosable={false}
          wrapClassName={"operatioin-overlay"}
          onCancel={onCancel}
          {...this.props}
        >
          <Wrap>
            <>
              {loading && (
                <div
                  style={{
                    width: "100%",
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spin />
                </div>
              )}
              {this.renderGenerate1()}
            </>
          </Wrap>
          <GloabalStyle />
        </Modal>
      </>
    );
  }

  /** render modal footer */
  renderModalFooter = () => {
    const { onCancel } = this.props;
    if (this.isDeital) {
      return <Button onClick={onCancel}>{"关闭"}</Button>;
    }

    if (this.isEdit) {
      return (
        <>
           <Button onClick={onCancel}>{"关闭"}</Button>
          <Button onClick={this.handleReset}>{"重置"}</Button>
          <Button type={"primary"} onClick={this.handleOk}>
            {"更新"}
          </Button>
        </>
      );
    }

    return (
      <>
        <Button onClick={onCancel}>{"关闭"}</Button>
        <Button onClick={this.handleReset}>{"重置"}</Button>
        <Button type={"primary"} onClick={this.handleOk}>
          {"提交"}
        </Button>
      </>
    );
  };

  /** load form */
  onLoadForm1 = (name: string, form: FormComponentProps["form"]) => {
    this.setState((preState) => ({
      formMap: { ...preState.formMap, [name]: form },
    }));
  };

  handleAddYear = () => {
    const { list1 } = this.state;
    if (!list1) return;
    const rawData = cloneDeep(list1);
    rawData.forEach((data) => {
      if (data.name.includes("企业推广种质面积")) {
        const lastData = data.list.slice(-1)[0];
        const lastYear = Number(lastData.name);

        if (isNaN(lastYear)) return;
        const currentYear = lastYear + 1;
        data.list.push({
          name: `${currentYear}`,
          eName: `${currentYear}`,
          type: "input",
          unit: null,
          selectData: [],
          required: false,
        });
      }
    });
    this.setState({ list1: rawData });
  };

  handleDel = () => {
    const { list1 } = this.state;
    if (!list1) return;
    const rawData = cloneDeep(list1);
    rawData.forEach((data) => {
      if (data.name.includes("企业推广种质面积")) {
        const lastData = data.list.slice(-1)[0];
        if (lastData && lastData.required === false) {
          data.list = data.list.slice(0, -1);
        }
      }
    });
    this.setState({ list1: rawData });
  };

  /** render form 1 */
  renderGenerate1 = () => {
    const { list1 } = this.state;
    const { type } = this.props;
    return (
      <>
        {list1 &&
          list1.map((item) => {
            return (
              <GenerateCategory
                type={type}
                disabled={this.isDeital}
                data={item}
                key={item.name}
                title={item.name}
                onLoad={this.onLoadForm1.bind(this, item.name)}
                rightCoponent={
                  !this.isDeital && item.name.includes("企业推广种质面积") && (
                    <>
                      <Button
                        style={{ marginRight: 4 }}
                        onClick={this.handleDel}
                      >
                        删除新增
                      </Button>
                      <Button type="primary" onClick={this.handleAddYear}>
                        新增
                      </Button>
                    </>
                  )
                }
              />
            );
          })}
      </>
    );
  };
}
