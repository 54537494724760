import React from "react";
import "rc-table/assets/index.css";
import styled from "styled-components";
import thBg from "./imgs/line.png";
import { Chart, ChartProps, ITable } from "@gago/frame";

const ScrollTableHeadWrap = styled.div`
  .scroll_head{
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: PingFangSC;
    color: rgb(101, 251, 255);
    font-size: 14px;
    font-weight: 500;
    padding: 2px 0;
    position: relative;
    margin-top: 1px;
    background-color: rgba(18, 53, 69, 0.65);;
    .item_col{
      /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; */
      white-space: pre-wrap;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin: 0 24px;
      /* width: 33%; */
    }
    &:after{
      content: "";
      background-image: url("${thBg}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      /* background-image: radial-gradient(circle at 50% 0, #adfcff, rgba(92, 222, 255, 0.76) 24%, rgba(92, 201, 255, 0) 100%); */
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0px;
    }
  }
`;

interface ScrollTableHeadProps {
  /** 头部标题的数组 */
  headData: string[];
}

/**
 * 自动滚动表格头部
 * @author wudajian
 * @export
 * @class AutoScroll
 * @extends {React.Component<Props>}
 */
// tslint:disable-next-line: max-classes-per-file
export class ScrollTableHead extends React.PureComponent<ScrollTableHeadProps> {
  static defaultProps = {
    headData: ["申报时间", "申报地点", "申报条件", "申报时机", "申报地点"],
  };
  unitWrap =(param:string) =>{
    if(param.includes('(') && param.includes(')')){
      let strArr = param.split('(')
      return <div><div>{strArr[0]}</div><div>({strArr[1]}</div></div>
    }else{
      return param
    }
  }
  render() {
    const { headData } = this.props;
    return (
      <ScrollTableHeadWrap>
        <div className="scroll_head">
          {headData.map((item, index) => (
            <div className="item_col" key={index}>
              {this.unitWrap(item)}
            </div>
          ))}
        </div>
      </ScrollTableHeadWrap>
    );
  }
}
