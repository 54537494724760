import React, { Component } from "react";
import { HomeBody } from "./style";
import { colorPalette } from "../../color-palette";
import { LottieAnimate } from "@gago/frame";
import zzfyAnimation from "./animation/zzzy-data.json";
import zztgAnimation from "./animation/zztg-data.json";
import zzpyAnimation from "./animation/zzpy-data.json";
import { RouteComponentProps, withRouter, matchPath } from "react-router-dom";
import { GERMPLAS_PATH, EXTENSION_PATH, BREEDING_PATH } from "../../layouts1/app/route-config";
import { scalePage } from "../../decorator/scale-page";
import { withContext } from "../../contexts";
import user, { UserContext, UserState } from "../../contexts/user";
import { message } from "antd";
import { isNull } from "lodash";
interface RouterList {
  /** 图片 */
  img: string;
  /** 路由 */
  url: string;
  /** 偏移 */
  bottom: string;
}
const routeList1: RouterList[] = [
  { img: "/static/images/zzzy-01.png", url: `${GERMPLAS_PATH}/germplasm-database`, bottom: "40px" },
  { img: "/static/images/zzzy-02.png", url: `${GERMPLAS_PATH}/breeding/overview`, bottom: "22px" },
  { img: "/static/images/zzzy-03.png", url: `${GERMPLAS_PATH}/bigscreen?mapType=2`, bottom: "0px" },
  { img: "/static/images/zzzy-04.png", url: `${GERMPLAS_PATH}/germplasm-test-report`, bottom: "22px" },
  { img: "/static/images/zzzy-05.png", url: `${GERMPLAS_PATH}/expert-database`, bottom: "40px" },
];
const routeList2: RouterList[] = [
  { img: "/static/images/zzfy-zzfyfb.png", url: `${BREEDING_PATH}/breeding-distribution`, bottom: "40px" },
  { img: "/static/images/zzfy-zzzsjc.png", url: `${BREEDING_PATH}/growth-monitoring`, bottom: "22px" },
  { img: "/static/images/zzfy-zzclyg.png", url: `${BREEDING_PATH}/production-forecast`, bottom: "0px" },
  { img: "/static/images/zzfy-qxybbj.png", url: `${BREEDING_PATH}/meteorological-service`, bottom: "0px" },
  { img: "/static/images/zzfy-bhyj.png", url: `${BREEDING_PATH}/disease-analysis`, bottom: "22px" },
  { img: "/static/images/zzfy-tdzljc.png", url: `${BREEDING_PATH}/land-type/land-evaluate`, bottom: "40px" },
];
const routeList3: RouterList[] = [
  { img: "/static/images/zztg-cpgl.png", url: `${EXTENSION_PATH}/production-manage`, bottom: "40px" },
  { img: "/static/images/zztg-dkgl.png", url: `${EXTENSION_PATH}/distribution/land-distribution`, bottom: "22px" },
  { img: "/static/images/zztg-scjh.png", url: `${EXTENSION_PATH}/production-plan`, bottom: "0px" },
  { img: "/static/images/zztg-sclt.png", url: `${EXTENSION_PATH}/market-circulation`, bottom: "0px" },
  { img: "/static/images/zztg-nsgl.png", url: `${EXTENSION_PATH}/farming-management/farming-record`, bottom: "22px" },
  { img: "/static/images/zztg-sygl.png", url: `${EXTENSION_PATH}/tracing-management`, bottom: "40px" },
];
interface Hstate {
  /** 当前的事件 */
  activeNum: number;
  /** 设置当前子集路由函数 */
  childRoute: RouterList[];
}
/**
 * 系统导航首页
 * @author 马俊峰
 * @date 2020-08-19
 * @export
 * @class Home
 * @extends {Component}
 */
class Home extends Component<RouteComponentProps & UserState, Hstate> {
  private arrList = [
    { animation: zzfyAnimation, url: GERMPLAS_PATH },
    { animation: zztgAnimation, url: EXTENSION_PATH },
    { animation: zzpyAnimation, url: BREEDING_PATH },
  ];

  state: Hstate = {
    activeNum: 0,
    childRoute: routeList1,
  };
  render() {
    const { activeNum, childRoute } = this.state;
    return(
      <HomeBody colorPalette={colorPalette}>
        <div className="header-box">
          {/* <div className="header-name">黄河流域西北地区种质资源平台</div> */}
        </div>
        <div className="home-content">
          <div className="canvas-border">
            <div className="animate-bg" />
            <div className="canvas-animate" style={this.animationBorder(activeNum)}>
              {this.arrList.map((item, index) => (
                <div className={`animate${index + 1}`} key={index} style={this.animationStyle(index, activeNum)}>
                  <LottieAnimate renderer="canvas" animationData={item.animation} style={{ height: "100%", width: "100%" }} />
                  <div className="float-layer" onClick={this.mainRouterClick.bind(this, item.url)} />
                </div>
              ))}
            </div>
            <div className="animate-under" />
            <div className="left-icon" onClick={this.animateUpClick} />
            <div className="right-icon" onClick={this.animateDownClick} />
            <div className="child-router">
              {childRoute.map((item, index) => (
                <div className="cr-item" key={index} style={{ marginBottom: item.bottom }}>
                  <img src={item.img} alt="" className="cri-img" onClick={this.mainRouterClick.bind(this, item.url)} />
                </div>
              ))}
            </div>
          </div>
          <div className="map-bg" />
        </div>
      </HomeBody>
    );
  }
  /** 主路由点击事件 */
  mainRouterClick = (url: string) => {
    const { userInfo } = this.props;
    const { web } = userInfo.rolesInfo;
    /** 如果是admin用户，可以看到所有界面 */
    let hasPerssion = web && web.some((pathname) => {
      const pathnameItem = matchPath(url, { path: pathname, strict: false });
      return !isNull(pathnameItem);
    });
    if (url === GERMPLAS_PATH || url === EXTENSION_PATH || url === BREEDING_PATH) {
      hasPerssion = true;
    }

    if (url.indexOf("bigscreen")) hasPerssion = true;
    if (userInfo.role === "admin" || hasPerssion) {
      this.props.history.push(url);
    } else {
      message.info("暂无权限");
    }
  }
  /** 动画下行事件 */
  animateDownClick = () => {
    const { activeNum } = this.state;
    this.setState({ activeNum: activeNum + 1, childRoute: [] }, () => { this.animateChildrenRouter(this.state.activeNum); });
  }
  /** 动画上行 */
  animateUpClick = () => {
    const { activeNum } = this.state;
    this.setState({ activeNum: activeNum - 1, childRoute: [] }, () => { this.animateChildrenRouter(this.state.activeNum); });
  }
  /** 父级盒子旋转 */
  animationBorder = (ser: number) => {
    return { transform: `translateY(-300px)rotateX(78deg)rotateZ(${ser * 120}deg)` };
  }
  /** 旋转样式返回函数 */
  // tslint:disable:cyclomatic-complexity early-exit
  animationStyle = (index: number, ser: number) => {
    const animation1 = { transform: `rotateZ(${-ser * 120}deg)rotateX(-78deg)scale(1.5)`, zIndex: 99 };
    const animation2 = { transform: `rotateZ(${-ser * 120}deg)rotateX(-78deg)scale(1)` };
    const animation3 = { transform: `rotateZ(${-ser * 120}deg)rotateX(-78deg)scale(1)` };
    if (index === 0) {
      switch (ser % 3) {
        case 0: return animation1;
        case 1: return animation2;
        case 2: return animation3;
        case -1: return animation2;
        case -2: return animation3;
        default: return animation1;
      }
    }
    if (index === 1) {
      switch (ser % 3) {
        case 0: return animation2;
        case 1: return animation3;
        case 2: return animation1;
        case -1: return animation1;
        case -2: return animation2;
        default: return animation2;
      }
    }
    if (index === 2) {
      switch (ser % 3) {
        case 0: return animation3;
        case 1: return animation1;
        case 2: return animation2;
        case -1: return animation3;
        case -2: return animation1;
        default: return animation3;
      }
    }
  }
  /** 子集路由选择函数 */
  childRouteSelect = (ser: number): RouterList[] => {
    switch (ser % 3) {
      case 0: return routeList1;
      case 1: return routeList2;
      case 2: return routeList3;
      case -1: return routeList3;
      case -2: return routeList2;
      default: return routeList1;
    }
  }
  /** 旋转展示子集路由 */
  animateChildrenRouter = (ser: number) => {
    const arrlist = this.childRouteSelect(ser);
    setTimeout(() => {
      this.setState({ childRoute: arrlist });
    }, 500);

  }
}

export default scalePage(1920, 1080)(withRouter(withContext(UserContext)(Home)));
