import React, { Component, PureComponent } from "react";
import { RouteConfig, Icon } from "@gago/frame";
import { Link } from "react-router-dom";
import { Source, RasterSource, RasterLayer, GeoJSONSource, Layer, FillLayer } from "@gago-react-gl/gago-react-gl";
import { uniqueId } from "lodash";
import { GeojsonType } from "../../models/germplasm-resource";
// tslint:disable:jsx-no-multiline-js ter-max-len
export const routesConfig: RouteConfig[] = [
  {
    key: "/system/home",
    icon: <Icon>desktop</Icon>,
    text: <Link to="/system/home">导览首页</Link>,
    routes: [],
  },
  {
    key: "/system/germplas-resource",
    icon: <Icon >desktop-chart</Icon>,
    text: <Link to="/system/germplas-resource">种质资源平台</Link>,
    routes: [],
  },
  {
    key: "/system/germplas-nav/germplasm-database",
    icon: <Icon >leaf-2</Icon>,
    text: <Link to="/system/germplas-nav/germplasm-database">种质资源数据库</Link>,
    routes: [],
  },
  {
    key: "/system/germplas-nav/commodity-germplasm",
    icon: <Icon >leaf-2</Icon>,
    text: <Link to="/system/germplas-nav/commodity-germplasm">商品种质管理</Link>,
    routes: [],
  },
  {
    key: "/system/germplas-nav/germplasm-test-report",
    icon: <Icon >leaf-2</Icon>,
    text: <Link to="/system/germplas-nav/germplasm-test-report">种质测试报告管理</Link>,
    routes: [],
  },
  {
    key: "/system/germplas-nav/molecular-breeding-report",
    icon: <Icon >leaf-2</Icon>,
    text: <Link to="/system/germplas-nav/molecular-breeding-report">分子育种报告管理</Link>,
    routes: [],
  },
  {
    key: "/system/germplas-nav/expert-database",
    icon: <Icon >leaf-2</Icon>,
    text: <Link to="/system/germplas-nav/expert-database">专家知识库管理</Link>,
    routes: [],
  },
  {
    key: "/system/germplas-nav/breeding",
    icon: <Icon >desktop-chart</Icon>,
    text: "育种适宜区管理",
    routes: [
      {
        key: "/system/germplas-nav/overview",
        icon: null,
        text: <Link to="/system/germplas-nav/overview">适宜性总览</Link>,
        routes: [],
      },
      {
        key: "/system/germplas-nav/map-analysis",
        icon: null,
        text: <Link to="/system/germplas-nav/map-analysis">适宜性智能分析地图</Link>,
        routes: [],
      },
    ],
  },
];
interface Hprops {
  geojsonType: GeojsonType;
  /** 是否显示 visible default: true */
  visible?: boolean;
}
/**
 * 巴盟卫星地图底图
 * @author 马俊峰
 * @date 2020-09-03
 * @export
 * @class BamengBaseMapLayer
 * @extends {PureComponent}
 */
export class HuangHeHightFillMapLayer extends PureComponent<Hprops> {
  /** 唯一id */
  uniqueId = uniqueId("district_map_source_");
  render() {
    const { geojsonType, visible } = this.props;
    if (!geojsonType) {
      return null;
    }
    const resouced: any = {
      type: "FeatureCollection",
      crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      features: [
        { type: "Feature", properties: { }, geometry: geojsonType },
      ],
    };
    return (
      <>
        <Source<GeoJSONSource> key={this.uniqueId} id={this.uniqueId} type="geojson" data={resouced} />
        <Layer<FillLayer>
          type="fill"
          source={this.uniqueId}
          id={`${this.uniqueId}_layer`}
          layout={{
            "visibility": visible ? "visible" : "none",
          }}
          paint={{
            "fill-color": "rgba(255, 0, 0, 0.2)",
            "fill-outline-color": "#ff7a7a",
          }}
        />
      </>
    );
  }
}
