import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Form, Input, Select } from "antd";
import { ModalProps } from "antd/lib/modal";
import { FormComponentProps } from "antd/lib/form";

import UserManageStore from "../user-manage.store";
import UserManageService from "../user-manage.service";
import FormSelect from "@gago/lib/components/form-select";
import { Partial } from "../../../utils/type";

export interface FormData {
  userId?: number;
  displayName: string;
  telephone: string;
  departmentId: number;
  dataAuthorityId: number;
  role: string;
  roleId: number;
  password: string;
}

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

interface Props extends ModalProps, Partial<FormComponentProps> {
  data: FormData;
  submitForm(values: FormData): void;
  edit: boolean;
}

// @ts-ignore
@Form.create()
@observer
export class UserModalComponent extends Component<Props> {

  // private renderDepartmentOptions() {
  //   return UserManageStore.departments.map(({ departmentId, departmentName }) => (
  //     <Select.Option key={departmentId} value={departmentId}>{departmentName}</Select.Option>
  //   ));
  // }

  private renderRoleDropOptions() {
    return UserManageStore.roleTree.map(item => (
      <Select.Option key={item.roleId} value={item.roleId}>{item.role}</Select.Option>
    ));
  }

  renderRoleOptions() {
    return UserManageStore.roleList.map((item) => (
      <Select.Option key={item.key} value={item.key}>{item.name}</Select.Option>
    ));
  }

  private submitForm = () => {
    const { submitForm, form, data } = this.props;
    if (!form) return;
    form.validateFields((err: any) => {
      // tslint:disable-next-line:early-exit
      if (!err) {
        form.validateFields((error: any, values: FormData) => {
          if (error) {
            return;
          }
          const password: string | number = form.getFieldValue("password");
          if (password !== undefined) {
            // @ts-ignore
            UserManageService.editPassword(this.props.data.userId, password);
          }
          submitForm({ ...data, ...values });
        });
        form.resetFields();
      }
    });
  }

  /**
   * 返回
   * @private
   * @param {*} e
   * @memberof UserModalComponent
   */
  private clickCancel = (e: any) => {
    const { onCancel, form } = this.props;
    onCancel && onCancel(e);
    // @ts-ignore
    form.resetFields();
  }

  render() {
    const { title, visible, confirmLoading, data, edit } = this.props;
    // @ts-ignore
    const { getFieldDecorator } = this.props.form;
    const departmentData = UserManageStore.departments.map(item => ({ key: item.departmentId, value: item.departmentName }));
    // tslint:disable jsx-no-multiline-js
    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={this.clickCancel}
        onOk={this.submitForm}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Form.Item label="用户姓名" {...formItemLayout}>
            {getFieldDecorator("displayName", {
              initialValue: data.displayName,
              rules: [{ required: true, message: "用户名不能为空!" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="密码" {...formItemLayout}>
            {getFieldDecorator("password", {
              initialValue: data.password,
              rules: [
                { min: 6, message: "长度最少6位!" },
                { max: 12, message: "长度最多12位" },
                { pattern: /^[a-zA-Z0-9]{6,12}$/, message: "密码必须为数字或字母!" },
              ],
            })(<Input placeholder="默认密码123456" />)}
          </Form.Item>
          <Form.Item label="所属角色" {...formItemLayout}>
            {getFieldDecorator("roleId", {
              initialValue: data.roleId,
              rules: [{ required: true, message: "必须选择一个角色!" }],
            })(<Select>{this.renderRoleDropOptions()}</Select>)}
          </Form.Item>
          <Form.Item label="联系方式" {...formItemLayout}>
            {getFieldDecorator("telephone", {
              initialValue: data.telephone,
              rules: [{ required: true, message: "联系方式不能为空!" }],
            })(<Input disabled={edit} />)}
          </Form.Item>
          <Form.Item label="所属部门" {...formItemLayout}>
            {getFieldDecorator("departmentId", {
              initialValue: data.departmentId,
              rules: [{ required: true, message: "必须选择一个部门!" }],
            })(<FormSelect data={departmentData} />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
