import React, { Component } from "react";
import { TracingSourceBody, growthTypeList, chart2N1D } from "./style";
import { colorPalette, pieChartColor, chartStyle2 } from "../../color-palette";
import { RouteComponentProps } from "react-router-dom";
import TracingSourceProvider, { handleWeatherHistory } from "../../network/providers/tracing-source.provider";
import { message, Popover, Anchor } from "antd";
import { CompanyLandsType, CompanyLandsArchivesType, CompanyLandsOperationType, WeatherHistoryType } from "../../models/tracing-source";
import { McLineChart } from "@gago/lib/components";
interface Tstate {
  /** 生长环境类型 */
  growthType: string;
  /** 地块信息数据 */
  massifDetail: CompanyLandsType | null;
  /** 获取档案记录数据 */
  massifArchives: CompanyLandsArchivesType | null;
  /** 获取公司地块操作记录 */
  massifOperation: CompanyLandsOperationType[];
  /** 气象历史数据 */
  weatherHistory: WeatherHistoryType[];
}
/**
 * 溯源码追溯页面
 * @author 马俊峰
 * @date 2020-08-18
 * @export
 * @class TracingSource
 * @extends {Component}
 */
// tslint:disable:completed-docs ter-max-len cyclomatic-complexity jsx-no-multiline-js jsx-no-lambda
export class TracingSource extends Component<RouteComponentProps<{massifId: string}>, Tstate> {
  state: Tstate = {
    growthType: "空气温度",
    massifDetail: null,
    massifArchives: null,
    massifOperation: [],
    weatherHistory: [],
  };
  componentDidMount() {
    const { match: { params: { massifId } } } = this.props;
    this.loadData(massifId);
  }
  /** 获取地块数据详情 */
  loadData = async (id: string) => {
    const param = { id };
    const massifDetail = await TracingSourceProvider.getMassifDetali(id).catch(err => { message.info("未查询到地块信息！", 1); return null; });
    const massifArchives = await TracingSourceProvider.getMassifArchives(param).catch(err => { message.info("未查询到档案记录！", 1); return null; });
    const massifOperation = await TracingSourceProvider.getMassifOperation(param).catch(err => { message.info("未查询到地块操作记录！", 1); return [] as CompanyLandsOperationType[]; });
    const weatherHistory = await TracingSourceProvider.getWeatherHistory(param).catch(err => { message.info("未查询到气象数据！", 1); return [] as WeatherHistoryType[]; });
    this.setState({ massifDetail, massifArchives, massifOperation, weatherHistory });
  }
  render() {
    const{ growthType, massifDetail, massifArchives, massifOperation, weatherHistory } = this.state;
    return(
      <TracingSourceBody colorPalette={colorPalette}>
        <div className="ts-head">{massifDetail && massifDetail.company || "---"}</div>
        <div className="border-box" id="qiyejianjie">
          <div className="bb-title">
            <span className="bbt-value">企业简介</span>
          </div>
          <div className="info-content">
            <div className="info-detail">{massifDetail && massifDetail.companyInfo || "---"}</div>
            <div className="info-detail-shadow" />
          </div>
        </div>
        <div className="border-box" id="chandidangan">
          <div className="bb-title">
            <span className="bbt-value">产地档案</span>
          </div>
          <div className="place-origin">
            <span className="po-label">地块位置</span>
            <div className="massif-detail">
              <div className="label-item">
                <span className="ml-laebl">基地名称：</span>
                <span className="ml-value">{massifDetail && massifDetail.baseName || "---"}</span>
              </div>
              <div className="label-item">
                <span className="ml-laebl">地块编号：</span>
                <span className="ml-value">{massifDetail && massifDetail.landCode || "---"}</span>
              </div>
              <div className="label-item">
                <span className="ml-laebl">种植作物：</span>
                <span className="ml-value">{massifDetail && massifDetail.crop || "---"}</span>
              </div>
              <div className="label-item">
                <span className="ml-laebl">地块面积：</span>
                <span className="ml-value">{massifDetail && massifDetail.area || "0"}亩</span>
              </div>
              <div className="label-item">
                <span className="ml-laebl">所属乡镇：</span>
                <span className="ml-value">{massifDetail && massifDetail.name || "---"}亩</span>
              </div>
              <div className="massif-img">
                <img src={massifDetail && `${window.config.publisher}/api/v1${massifDetail.imageUrl}` || "/static/images/无图片@2x.png"} alt="" className="ms-url"/>
              </div>
            </div>
          </div>
          <div className="place-origin">
            <span className="po-label">长势监测</span>
            <div className="massif-detail">
              <div className="massif-img">
                <img src={massifArchives && massifArchives.ndviImages && massifArchives.ndviImages || "/static/images/无图片@2x.png"} alt="" className="ms-url"/>
              </div>
            </div>
          </div>
          <div className="place-origin">
            <span className="po-label">生长环境</span>
            <div className="growth-chart">
              <div className="growth-type">
                {growthTypeList.map((item, index) => (
                  <span
                    className={`growth-select ${growthType === item ? "gs-active" : ""}`}
                    key={index}
                    onClick={this.growthTypeClick.bind(this, item)}
                  >
                    {item}
                  </span>
                ))}
              </div>
              <div className="gc-chart-box">
                {weatherHistory.length > 0 && <McLineChart
                  chartData={handleWeatherHistory(weatherHistory, growthType)}
                  showSmooth
                  showSymbol={false}
                  chartStyle={chartStyle2}
                  ySplitNumber={3}
                  colorPalette={{ ...colorPalette, subColor: ["#62cc77"] }}
                />}
              </div>
            </div>
          </div>
        </div>
        <div className="tracing-play-box">
          <span className="tpb-icon" />
          <span className="tpb-value">天赋河套种质原产地溯源</span>
          <span className="tpb-icon tpb-icon-r" />
        </div>
        <div className="border-box mt-0" id="shengchandangan">
          <div className="bb-title">
            <span className="bbt-value">生产档案</span>
          </div>
          <div className="archives-box">
            <div className="archives-detail">
              <div className="ad-title">种植档案</div>
              <div className="planting-list">
                {massifOperation.length > 0 && massifOperation.map((item, index) => (
                  <div className="plant-item" key={index}>
                    <div className="info-border">
                      <div className="left-ib">
                        <span className={`pi-icon ${this.selectArchiveType(item.operation)}`} />
                        <span className="pi-type">{item.operation}</span>
                      </div>
                      <span className="pi-date">{item.startTime} ~ {item.endTime}</span>
                    </div>
                    <div className="info-img">
                      <img src={item.img && `${item.img}` || "/static/images/无图片@2x.png"} alt="" className="ii-url"/>
                    </div>
                  </div>
                ))}
              </div>
              <div className="ad-title mt-30">采收档案</div>
              <div className="recovery-box">
                <div className="rb-item">
                  <span className="rbi-left">采收批次</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.harvestBatch || "---"}</span>
                </div>
                <div className="rb-item">
                  <span className="rbi-left">采收负责人</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.harvestHead || "---"}</span>
                </div>
                <div className="rb-item">
                  <span className="rbi-left">采收时间</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.harvestTime || "---"}</span>
                </div>
              </div>
              <div className="ad-title">仓储档案</div>
              <div className="recovery-box">
                <div className="rb-item">
                  <span className="rbi-left">仓库名称</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.warehouseName || "---"}</span>
                </div>
                <div className="rb-item">
                  <span className="rbi-left">地区</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.region || "---"}</span>
                </div>
                <div className="rb-item">
                  <span className="rbi-left">责任人</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.responsible || "---"}</span>
                </div>
              </div>
              <div className="warehouse-box">
                <div className="ware-table">
                  <span className="dt-date">时间</span>
                  <span className="dt-tem">温度</span>
                  <span className="dt-tem">湿度</span>
                </div>
                {(massifArchives && massifArchives.record.length > 0) ? massifArchives.record.map((item, index) => (
                  <div className="ware-value" key={index}>
                    <span className="dt-date">{item.time}</span>
                    <span className="dt-tem">{item.tmp}℃</span>
                    <span className="dt-tem">{item.humidity}%</span>
                  </div>
                )) : <div className="ware-value">
                  <span className="dt-date">---</span>
                  <span className="dt-tem">---</span>
                  <span className="dt-tem">---</span>
                </div>}
              </div>
              <div className="ad-title mt-30">包装档案</div>
              <div className="recovery-box">
                <div className="rb-item">
                  <span className="rbi-left">包装批次</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.packBatch || "---"}</span>
                </div>
                <div className="rb-item">
                  <span className="rbi-left">包装规格</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.packSpec || "---"}</span>
                </div>
                <div className="rb-item">
                  <span className="rbi-left">包装人</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.packPeople || "---"}</span>
                </div>
                <div className="rb-item">
                  <span className="rbi-left">包装时间</span>
                  <span className="rbi-line" />
                  <span className="rbi-right">{massifArchives && massifArchives.packTime || "---"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-box" id="pinzhirenzheng">
          <div className="bb-title">
            <span className="bbt-value">品质保证</span>
          </div>
          <div className="certificate-box">
            <div className="certificate-list">
              {(massifArchives && massifArchives.images.length > 0) ? massifArchives.images.map((item, index) => (
                <img
                  key={index}
                  src={`${window.config.publisher}/api/v1${item}`}
                  alt=""
                  className="cl-url"
                />
              )) : <img src="/static/images/无图片@2x.png" alt="" className="cl-url"/>}
            </div>
          </div>
        </div>
        <div className="menu-anchor" id="tracing_source_body">
          <Popover
            content={this.menuAnchorRender()}
            title={false}
            trigger="click"
            overlayClassName="popover-box"
            placement="top"
            getPopupContainer={() => document.getElementById("tracing_source_body") as HTMLElement}
          >
            <span className="menu-icon" />
          </Popover>
        </div>
      </TracingSourceBody>
    );
  }
  /** 某点导航返回dom */
  menuAnchorRender = () => {
    return (
      <div className="anchor-box">
        <Anchor affix={false}>
          <Anchor.Link href="#qiyejianjie" title="企业简介" />
          <Anchor.Link href="#chandidangan" title="产地档案" />
          <Anchor.Link href="#shengchandangan" title="生产档案" />
          <Anchor.Link href="#pinzhirenzheng" title="品质认证" />
        </Anchor>
      </div>
    );
  }
  /** 选择档案类型 */
  selectArchiveType = (type: string) => {
    switch (type) {
      case "播种": return "bozhong";
      case "浇水": return "jiaoshui";
      case "施肥": return "shifei";
      case "打药": return "dayao";
      case "除草": return "chucao";
      case "收获": return "shougou";
      default: return "bozhong";
    }
  }
  /** 生长环境类型选择事件 */
  growthTypeClick = (type: string) => {
    this.setState({ growthType: type });
  }
}
