// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Cascader,
  TreeSelect,
} from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import DictionaryProvider from "../../network/providers/dictionary-base.provider";
import * as DictionaryBaseInterface from "../../models/dictionary-base";
import { CascaderProps } from "antd/lib/cascader";
import { TreeNode } from "antd/lib/tree-select";
import { get } from "lodash";

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

type P = FormComponentProps & {
  /** 搜索 */
  onTopSearch(data: any): void;
  /** load form */
  onLoad(form: FormComponentProps["form"]): void;
};

interface S {
  /** 地区列表 */
  regionList?: DictionaryBaseInterface.RegionTree[];
  /** 企业名称 */
  companyList?: DictionaryBaseInterface.ComponyList[];
  /** 基地类型 */
  baseTypeList?: DictionaryBaseInterface.ParkBaseType[];
  /** 地块列表 */
  baseLandListAll?: DictionaryBaseInterface.BaseLandAll[];
}

/**
 * 头部 高级搜索
 * @author maoshengtao
 */
class AdvancedSearchForm extends React.Component<P, S> {
  state: S = {};

  componentDidMount() {
    const { onLoad, form } = this.props;
    onLoad(form);
    this.init();
  }

  init = () => {
    this.getRegionList();
    this.getCompanyList();
    this.getBaseType();
    this.getBaseListAll();
  };

  /** get 地区列表 */
  getRegionList = async () => {
    const regionList = await DictionaryProvider.getRegionTree();
    this.setState({ regionList });
  };

  /** get 企业名称 */
  getCompanyList = async () => {
    const companyList = await DictionaryProvider.getCompanyList();
    this.setState({ companyList });
  };

  /** get 基地类型 */
  getBaseType = async () => {
    const baseTypeList = await DictionaryProvider.getParkBaseType();
    this.setState({ baseTypeList });
  };

  /** get 地块列表 */
  getBaseListAll = async () => {
    const baseLandListAll = await DictionaryProvider.getBaseLandListAll();
    this.setState({ baseLandListAll });
  };

  /** search */
  handleSearch = (e: any) => {
    e.preventDefault();
    const { form, onTopSearch } = this.props;
    form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (err) {
        return;
      }
      const regionOption = this.handleLandListValue(values.地区);
      const topSearch: DictionaryBaseInterface.BaseLandListRequest = {
        provinceCode: regionOption.province.code,
        cityCode: regionOption.city.code,
        company: values.企业名称,
        state: values.地块状态,
        baseName: values.地块列表,
        type: (values.基地类型 && values.基地类型.length !== 0 && values.基地类型[0] === "一区多园N基地") ? undefined : JSON.stringify(values.基地类型),
      }
      onTopSearch(topSearch);
    });
  };

  /** reset */
  handleReset = () => {
    this.props.form.resetFields();
  };

  /** handle item */
  handleItem = (
    regionItem: DictionaryBaseInterface.RegionTree
  ): CascaderProps["options"][number] => {
    if (regionItem.children && regionItem.children.length > 0) {
      return {
        value: JSON.stringify(regionItem),
        label: regionItem.name,
        children: regionItem.children.map(this.handleItem),
      };
    }
    return {
      value: JSON.stringify(regionItem),
      label: regionItem.name,
      children: undefined,
    };
  };

  /** handle region list */
  handleRegionTree = (regionTree: DictionaryBaseInterface.RegionTree[]) => {
    return regionTree.map((elm) => {
      return this.handleItem(elm);
    });
  };

  /** 处理单个基地类型 */
  handleParkTypeItem = (
    parkType: DictionaryBaseInterface.ParkBaseType
  ): TreeNode => {
    if (parkType.children && parkType.children.length > 0) {
      return {
        key: parkType.value,
        value: parkType.value,
        title: parkType.key,
        children: parkType.children.map(this.handleParkTypeItem),
      };
    }
    return {
      key: parkType.value,
      value: parkType.value,
      title: parkType.key,
      children: undefined,
    };
  };

  /** 处理基地类型 */
  handleParkType = (parkTypes: DictionaryBaseInterface.ParkBaseType[]) => {
    return parkTypes.map((elm) => {
      return this.handleParkTypeItem(elm);
    });
  };

  /** render  地区*/
  renderSelect1 = () => {
    const { getFieldDecorator } = this.props.form;
    const { regionList } = this.state;
    if (!regionList) {
      return <Cascader disabled placeholder="请选择所在地区" />;
    }
    return getFieldDecorator(`地区`, {
    })(
      <Cascader
        allowClear
        changeOnSelect
        options={this.handleRegionTree(regionList)}
        placeholder="请选择所在地区"
      />
    );
  };

  /** render  企业名称*/
  renderSelect2 = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { companyList } = this.state;

    if (!companyList) {
      return <Select placeholder="请选择" disabled></Select>;
    }

    return getFieldDecorator(`企业名称`, {
    })(
      <Select placeholder="请选择" allowClear>
        {companyList.map((item, ind) => {
          return (
            <Option key={ind} value={item.name}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
  };

  /** render 基地类型 */
  renderSelect3 = () => {
    const { getFieldDecorator } = this.props.form;
    const { baseTypeList } = this.state;
    if (!baseTypeList) {
      return <Select placeholder="请选择" disabled></Select>;
    }
    return getFieldDecorator(`基地类型`, {
    })(
      <TreeSelect
        showSearch={false}
        treeData={this.handleParkType(baseTypeList)}
        treeCheckable={true}
        showCheckedStrategy={SHOW_PARENT}
        searchPlaceholder={"请选择"}
      />
    );
  };

  /** 处理地块列表的value */
  handleLandListValue = (regionArr: string[]) => {
    let result = {
      province: {} as DictionaryBaseInterface.RegionTree,
      city: {} as DictionaryBaseInterface.RegionTree,
    };
    if (regionArr && regionArr.length > 0) {
      const provinceOption = regionArr[0] && JSON.parse(regionArr[0]);
      const cityOption = regionArr[1] && JSON.parse(regionArr[1]);
      if (provinceOption && provinceOption.name) {
        result.province = provinceOption;
      }
      if (cityOption && cityOption.name) {
        result.city = cityOption;
      }
    }
    return result;
  }

  /** render 地块列表 */
  renderSelect4 = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { baseLandListAll } = this.state;
    if (!baseLandListAll) {
      return <Select placeholder="请选择" disabled></Select>;
    }
    /** 筛选地区 */
    const regionArr = getFieldValue("地区");
    let realList = baseLandListAll;
    const regionOption = this.handleLandListValue(regionArr);
    if (regionOption.province && regionOption.province.name) {
      realList = baseLandListAll.filter((item) => item.provinceName === regionOption.province.name);
    }
    if (regionOption.city && regionOption.city.name) {
      realList = baseLandListAll.filter((item) => item.cityName === regionOption.city.name);
    }

    return getFieldDecorator(`地块列表`, {
    })(
      <Select
        placeholder="请选择(可搜索)"
        showSearch
        allowClear
        filterOption={(s: string, o: any) => o.props.children.includes(s)}
      >
        {realList.map((item, ind) => {
          return (
            <Option key={item.id} value={item.baseName}>
              {item.baseName}
            </Option>
          );
        })}
      </Select>
    );
  };

  /** render 地块状态 */
  renderSelect5 = () => {
    const { getFieldDecorator } = this.props.form;

    return getFieldDecorator(`地块状态`, {
    })(
      <Select placeholder="请选择" allowClear>
        <Option value={0}>{"下架"}</Option>
        <Option value={1}>{"上架"}</Option>
      </Select>
    );
  };

  render() {
    return (
      <>
        <WrapForm onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={`地区`}>{this.renderSelect1()}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={`企业名称`}>{this.renderSelect2()}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={`基地类型`}>{this.renderSelect3()}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={`地块列表`}>{this.renderSelect4()}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={`地块状态`}>{this.renderSelect5()}</Form.Item>
            </Col>

            <Col span={8} style={{ textAlign: "right" }}>
              <Button onClick={this.handleReset}>重置</Button>
              <Button
                style={{ marginLeft: 8 }}
                type="primary"
                htmlType="submit"
              >
                搜索
              </Button>
            </Col>
          </Row>
        </WrapForm>
      </>
    );
  }
}

export const LeftModule1 = Form.create<P>({ name: "advanced_search" })(
  AdvancedSearchForm
);
