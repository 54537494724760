import React, { Component, PureComponent } from "react";
import { RouteConfig, Icon } from "@gago/frame";
import { Link } from "react-router-dom";
import { Source, GeoJSONSource, Layer, LineLayer, FillLayer, SymbolLayer, VectorSource } from "@gago-react-gl/gago-react-gl";
import { MapState } from "../../contexts/map";
import EchartLayer from "../../echarts-layer";
import { PopularizeMapType } from "../../models/extension-screen";
import line3d from "./line-3d.png";
// tslint:disable:jsx-no-multiline-js
export const routesConfig: RouteConfig[] = [
  {
    key: "system/home",
    icon: <Icon>desktop</Icon>,
    text: <Link to="/system/home">导览首页</Link>,
    routes: [],
  },
  {
    key: "system/extension-screen",
    icon: <Icon >desktop-chart</Icon>,
    text: <Link to="/system/extension-screen">种质推广溯源一张图</Link>,
    routes: [],
  },
  {
    key: "system/extension-nav/distribution",
    icon: <Icon >plots</Icon>,
    text: "地块管理",
    routes: [
      {
        key: "system/extension-nav/land-distribution",
        icon: null,
        text: <Link to="/system/extension-nav/land-distribution">地块分布</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/growth-monitoring",
        icon: null,
        text: <Link to="/system/growth-monitoring">长势监测</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/meteorological",
        icon: null,
        text: <Link to="/system/extension-nav/meteorological">气象服务</Link>,
        routes: [],
      },
    ],
  },
  {
    key: "system/extension-nav/production-plan",
    icon: <Icon >agrotechnika</Icon>,
    text: <Link to="/system/extension-nav/production-plan">生产计划</Link>,
    routes: [],
  },
  {
    key: "system/extension-nav/farming-management",
    icon: <Icon >agrotechnika</Icon>,
    text: "农事管理",
    routes: [
      {
        key: "system/extension-nav/farming-record",
        icon: null,
        text: <Link to="/system/extension-nav/farming-record">农事记录</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/recovery",
        icon: null,
        text: <Link to="/system/extension-nav/recovery">采收管理</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/warehouse",
        icon: null,
        text: <Link to="/system/extension-nav/warehouse">仓储管理</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/monitor",
        icon: null,
        text: <Link to="/system/extension-nav/monitor">监测管理</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/packing",
        icon: null,
        text: <Link to="/system/extension-nav/packing">包装管理</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/sale-path",
        icon: null,
        text: <Link to="/system/extension-nav/sale-path">销售路径</Link>,
        routes: [],
      },
    ],
  },
  {
    key: "system/extension-nav/tracing-management",
    icon: <Icon >agrotechnika</Icon>,
    text: <Link to="/system/extension-nav/tracing-management">溯源管理</Link>,
    routes: [],
  },
  {
    key: "system/extension-nav/setting",
    icon: <Icon >agrotechnika</Icon>,
    text: "系统设置",
    routes: [
      {
        key: "system/extension-nav/role-management",
        icon: null,
        text: <Link to="/system/extension-nav/role-management">角色管理</Link>,
        routes: [],
      },
      {
        key: "system/extension-nav/user-management",
        icon: null,
        text: <Link to="/system/extension-nav/user-management">用户管理</Link>,
        routes: [],
      },
    ],
  },
];

/**
 * 国内推广示意图
 * @author 马俊峰
 * @date 2020-08-26
 * @export
 * @class ChinaExtensionMapLayer
 * @extends {PureComponent}
 */
export class ChinaExtensionMapLayer extends PureComponent {
  render() {
    return(
      <>
        {/* <Source<GeoJSONSource>
          id="china_province_line_source"
          type="geojson"
          data="/static/data/province.geojson"
        /> */}
        <Source<VectorSource>
          roundZoom
          id={"china_province_line_source"}
          type="vector"
          // tileSize={512}
          tiles={[`${window.config.publisher}/api/v2/province/map/{z}/{x}/{y}/?token=${window.sessionStorage.getItem("token")}`]}
        />
        <Layer<FillLayer>
          type="fill"
          id="china_province_fill_layer"
          source="china_province_line_source"
          sourceLayer="province"
          paint={{
            "fill-color": "#00ffff",
            "fill-opacity": 0.25,
          }}
        />
        <Layer<LineLayer>
          type="line"
          id="china_province_line_layer"
          source="china_province_line_source"
          sourceLayer="province"
          paint={{
            "line-color": "rgb(0, 229, 255)",
            "line-width": 1.2,
          }}
        />
        <Layer<SymbolLayer>
          type="symbol"
          source="china_province_line_source"
          id="china_province_name_layer"
          sourceLayer="province"
          layout={{
            "text-field": "{name}",
            "text-max-width": 6,
            "text-size": ["interpolate", ["linear"], ["zoom"], 8, 12, 10, 13],
            "text-font": ["Arial Unicode MS Regular"],
          }}
          paint={{ "text-color": "#fff", "text-halo-color": "#000", "text-halo-width": 1 }}
        />

        <Source<GeoJSONSource>
          id="china_province_line_islands_source"
          type="geojson"
          data="/static/data/islands.geojson"
        />
        <Layer<FillLayer>
          type="fill"
          id="china_province_fill_layer_islands"
          source="china_province_line_islands_source"
          paint={{
            "fill-color": "#00ffff",
            "fill-opacity": 0.25,
          }}
        />
        <Layer<LineLayer>
          type="line"
          id="china_province_line_layer_islands"
          source="china_province_line_islands_source"
          paint={{
            "line-color": "rgb(0, 229, 255)",
            "line-width": 1.2,
          }}
        />
        <Layer<SymbolLayer>
          type="symbol"
          source="china_province_line_islands_source"
          id="china_province_name_layer_islands"
          layout={{
            "text-field": "{name}",
            "text-max-width": 6,
            "text-size": ["interpolate", ["linear"], ["zoom"], 8, 12, 10, 13],
            "text-font": ["Arial Unicode MS Regular"],
          }}
          paint={{ "text-color": "#fff", "text-halo-color": "#000", "text-halo-width": 1 }}
        />
        <Source<GeoJSONSource>
          id="china_nine_line_islands_source"
          type="geojson"
          data="/static/data/nine_line.geojson"
        />
        <Layer<FillLayer>
          type="fill"
          id="china_nine_line_fill_layer_islands"
          source="china_nine_line_islands_source"
          paint={{
            "fill-color": "#00ffff",
            "fill-opacity": 0.25,
          }}
        />
        <Layer<LineLayer>
          type="line"
          id="china_nine_line_layer_islands"
          source="china_nine_line_islands_source"
          paint={{
            "line-color": "rgb(0, 229, 255)",
            "line-width": 1.2,
          }}
        />
        <Layer<SymbolLayer>
          type="symbol"
          source="china_nine_line_islands_source"
          id="china_nine_line_layer_islands"
          layout={{
            "text-field": "{name}",
            "text-max-width": 6,
            "text-size": ["interpolate", ["linear"], ["zoom"], 8, 12, 10, 13],
            "text-font": ["Arial Unicode MS Regular"],
          }}
          paint={{ "text-color": "#fff", "text-halo-color": "#000", "text-halo-width": 1 }}
        />

        <Source<GeoJSONSource>
          id="china_nine_line_chiwei_source"
          type="geojson"
          data="/static/data/chiwei.geojson"
        />
        {/* <Layer<FillLayer>
          type="fill"
          id="china_nine_line_fill_layer_islands"
          source="china_nine_line_islands_source"
          paint={{
            "fill-color": "#00ffff",
            "fill-opacity": 0.25,
          }}
        />
        <Layer<LineLayer>
          type="line"
          id="china_nine_line_layer_islands"
          source="china_nine_line_islands_source"
          paint={{
            "line-color": "rgb(0, 229, 255)",
            "line-width": 1.2,
          }}
        /> */}
        <Layer<SymbolLayer>
          type="symbol"
          source="china_nine_line_chiwei_source"
          id="china_chiwei_layer_islands"
          minzoom={0}
          layout={{
            "text-field": "{name}",
            "text-max-width": 6,
            "text-size": ["interpolate", ["linear"], ["zoom"], 8, 10, 10, 10],
            "text-font": ["Arial Unicode MS Regular"],
            "text-allow-overlap": true,
            "text-ignore-placement": false,
            // "text-optional": false,
          }}
          paint={{ "text-color": "#fff", "text-halo-color": "#000", "text-halo-width": 1 }}
        />
      </>
    );
  }
}

type DProps= {
  /** 选择销售类型 */
  mapData: PopularizeMapType[];
} & MapState;
/**
 * 销售路线图
 * @author 马俊峰
 * @date 2020-04-28
 * @export
 * @class DirectionMap
 * @extends {PureComponent<CProps, CState>}
 */
// tslint:disable-next-line:max-classes-per-file
export class DirectionMap extends PureComponent<DProps, DProps> {
  /** echart 图层 */
  private chartLayer = new EchartLayer(this.props.map);

  componentWillUnmount() {
    this.chartLayer.remove();
  }
  /** 更新数据 */
  updateChartData = () => {
    const option = this.getChartOption();
    // @ts-ignore
    this.chartLayer.chart.setOption(option);
    return null;
  }
  render() {
    return(<>{this.updateChartData()}</>);
  }
  /** 地图销售类型转换 */
  saleListDataChange = (data: PopularizeMapType[]) => {
    return data.map(item => ({
      fromName: item.startName,
      toName: item.endName,
      value: Number(item.data),
      coords: [[Number(item.startLon), Number(item.startLat)], [Number(item.endLon), Number(item.endLat)]],
      type: "pigOut",
    }));
  }
  /** chart 图层 配置 */
  getChartOption = () => {
    const { mapData } = this.props;
    const data = this.saleListDataChange(mapData);
    const series = [];
    series.push(
      {
        data,
        name: "销售量",
        coordinateSystem: "GLMap",
        type: "lines",
        zlevel: 2,
        effect: {
          show: true,
          period: 6,
          trailLength: 0,
          symbol: `image://${line3d}`,
          // symbol: "arrow",
          symbolSize: 20,
        },
        tooltip: {
          show: false,
        },
        lineStyle: {
          normal: {
            color: "#ffcc3b",
            width: 2,
            opacity: 0.6,
            curveness: 0.2,
          },
        },
      },
      {
        name: "销售量",
        type: "effectScatter",
        coordinateSystem: "GLMap",
        zlevel: 2,
        rippleEffect: {
          brushType: "stroke",
        },
        label: {
          normal: {
            show: false,
          },
        },
        tooltip: {
          show: false,
        },
        symbolSize: 8,
        itemStyle: {
          normal: { color: "#ffcc3b" },
        },
        data: data.map(item => ({
          symbolSize: Number(item.value) * 2,
          name: item.toName,
          value: item.coords[1],
        })),
      });

    return {
      series,
      GLMap: {
        roam: true,
      },
      coordinateSystem: "GLMap",
      tooltip: {
        trigger: "item",
      },
      geo: {
        map: "GLMap",
        label: {
          emphasis: {
            show: false,
          },
        },
        roam: true,
        itemStyle: {
          normal: {
            areaColor: "#323c48",
            borderColor: "#404a59",
          },
          emphasis: {
            areaColor: "#2a333d",
          },
        },
      },
    };
  }
// tslint:disable:max-file-line-count
}
