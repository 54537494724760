import { BaseInfo } from "../../models/germplasm-database";

// tslint:disable
export const TESTREPORTLIST: BaseInfo[] = [
  {
      name: "测试报告0",
      list: [
          {
              name: "送检单位",
              eName: "inspectDepartment",
              type: "input",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "检测单位",
              eName: "detectionDepartment",
              type: "input",
              unit: null,
              selectData: [],
              required: true
          },
          {
            name: "所在地区",
            eName: "provinceName_cityName",
            type: "cascader",
            unit: null,
            selectData: [],
            required: true
        },
          {
              name: "检测时间",
              eName: "testTime",
              type: "date",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "当前报告",
              eName: "reportUrl",
              message: "请先上传报告",
              type: "upload",
              unit: null,
              selectData: [],
              required: false,
          },
          {
            name: "报告上传",
            eName: "updateFile",
            message: "请先上传报告",
            type: "updateFile",
            unit: null,
            selectData: [],
            required: false
        }
      ]
  }
]

export const BREEDINGREPORTLIST: BaseInfo[] = [
  {
      name: "育种报告0",
      list: [
        
          {
              name: "育种单位",
              eName: "breedingDepartment",
              type: "input",
              unit: null,
              selectData: [],
              required: true
          },
          {
              name: "育种类型",
              eName: "breedingType",
              type: "select",
              unit: null,
              selectData: [
                  "分子标记辅助育种",
                  "遗传修饰育种"
              ],
              required: true
          },
          {
            name: "所在地区",
            eName: "provinceName_cityName",
            type: "cascader",
            unit: null,
            selectData: [],
            required: true
        },
        {
            name: "育种时间",
            eName: "breedingTime",
            type: "date",
            unit: null,
            selectData: [],
            required: true
        },
        {
            name: "当前报告",
            eName: "reportUrl",
            message: "请先上传报告",
            type: "upload",
            unit: null,
            selectData: [],
            required: false,
        },
        {
          name: "报告上传",
          eName: "updateFile",
          message: "请先上传报告",
          type: "updateFile",
          unit: null,
          selectData: [],
          required: false
      }
      ]
  }
]