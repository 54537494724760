import React from "react";
import { UserState, UserContext } from "../../contexts/user";
import { withContext } from "../../contexts";
import { Form, Input, Icon, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { LoginBorder } from "./styled";
// tslint:disable:variable-name
// tslint:disable:jsx-no-multiline-js
// tslint:disable:jsx-no-lambda
// tslint:disable:ter-max-len
// tslint:disable:no-unnecessary-else
/** 默认state */
const defaultState = {
  /** 用户名 */
  username: "admin",
  /** 密码 */
  password: "",
  isLoading: false,
};

type LoginProps = {} & FormComponentProps & UserState;

/**
 * 登陆页
 *
 * @author 张卓诚
 * @date 2018-12-24
 * @class Page1
 * @extends {(React.Component<PropsType<typeof mstp, typeof mdtp> & LoadingState & CropState>)}
 */
class LoginFrom extends React.Component<LoginProps, typeof defaultState> {
  /** 定义 */
  state = defaultState;
  /** 用户名 */
  updateUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        username: event.target.value,
      },
    );
  }
  /** 密码 */
  updatePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        password: event.target.value,
      },
    );
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { isLoading } = this.state;
    return (
      <LoginBorder>
        <div className="lt-title">黄河流域西北地区种质资源平台</div>
        <Form className="form" onSubmit={this.loginProject}>
          <div className="login-title">用户登录：</div>
          <div className="login-box">
            <Form.Item>
              {getFieldDecorator("username", {
                rules: [{ required: true, message: "账号不能为空!" }],
                // initialValue: "forecast",
              })(<Input prefix={<Icon type="user" />} placeholder="账号" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "密码不能为空!" },
                  // { pattern: /^[a-zA-Z0-9_.]+$/, message: "不允许特殊字符！" },
                  // { validator: this.specialCheck, message: "不允许输入汉字！" },
                ],
              })(<Input type="password" prefix={<Icon type="lock" />} placeholder="密码" />)}
            </Form.Item>
            <Button type="primary" htmlType="submit" className="cover-ant-button" loading={isLoading} >登录</Button>
          </div>
        </Form>
      </LoginBorder>
    );
  }
  /** 登录 */
  private loginProject = (event: any) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const { username, password } = values;
        this.props.login(username, password);
      }
      this.setState({ isLoading: false });
    });
  }
  /** 密码输入特殊校验 */
  specialCheck = (rules: any, value: string, callback: any) => {
    try {
      const rex = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (rex.test(value)) { throw new Error("不允许输入汉字！"); } else { callback(); }
    } catch (err) {
      callback(err);
    }
  }
}
const Login: any = Form.create()(LoginFrom);
export default withContext(UserContext)(Login);
