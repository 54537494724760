import React from "react";
import moment from "moment";
import { HeaderBox } from "../../containers/header-box";
import { PageBody } from "./styled";
import { colorPalette, chartStyle, pieChartColor, projectColorPalette  } from "../../color-palette";
import Map from "./map";
import { EllipseRadioSelect, LocationSelect, WeatherChart } from "@gago/lib/components";
import { Card1 as Card } from "@gago/frame";
import { ITable, SideBar4 } from "@gago/frame";
import { noop } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { Table, Avatar, Divider } from "antd";
import DroughtWarning from "./img/droughtWarning.png";
import { getWaringIcon } from "./getWeatherInfo";
import { WarningCard } from "../../containers/warning-card";

import {
  AgricultureWarning,
  AgricultureWarningRequest,
  AnalysisBaseRequest,
  PhenologicalPeriod,
  WeekForecast,
  AnalysisTmp,
} from "../../models/meteorological-service";
import { stringify } from "query-string";
// 以下为storybook代码
const dataS4N5: ITable<5, 4> = {
  title: "天气预报",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  range: [[], [], [], [], [], [], [], [], [], []],
  head: ["ID", "周几", "日期", "天气", "风向", "最高温", "最低温", "降雨", "风级", "湿度"],
  data: [
    // ["", "星期二", "05/26", "多云", "北风转南风", "3-4级", 26, 16, 0, 67.06],
    // ["", "星期三", "05/27", "多云", "北风转南风", "3-4级", 29, 16, 0, 53.04],
    // ["", "星期四", "05/28", "多云", "南风", "3-4级", 33, 20, 0, 45.28],
    // ["", "星期五", "05/29", "多云转晴", "南风", "3-4级", 34, 19, 0, 51.96],
    // ["", "星期六", "05/30", "多云转小雨", "南风", "3-4级", 31, 18, 0.01, 55.82],
    // ["", "星期日", "05/31", "小雨转晴", "北风转南风", "3-4级转<3级", 29, 19, 0.49, 62.8],
    // ["", "星期一", "06/01", "小雨", "南风转东南风", "<3级转3-4级", 31, 21, 1.1, 52.38],
    // ["", "星期二", "06/02", "阴转中雨", "南风转东南风", "3-4级转<3级", 29, 20, 6.13, 57.05],
    // ["", "星期三", "06/03", "中雨转小雨", "西北风转西风", "4-5级转3-4级", 29, 17, 13.58, 65.26],
    // ["", "星期四", "06/04", "小雨转晴", "北风转南风", "4-5级转<3级", 28, 21, 0.56, 50.41],
    // ["", "星期五", "06/05", "晴转多云", "西南风转南风", "3-4级", 34, 24, 0, 34],
    // ["", "星期六", "06/06", "晴转小雨", "东风", "3-4级", 37, 23, 1, 37.2],
    // ["", "星期日", "06/07", "多云转小雨", "西南风转南风", "4-5级转3-4级", 38, 27, 0.06, 31],
    // ["", "星期一", "06/08", "多云转阴", "南风", "4-5级", 38, 24, 0, 30.3],
  ],
  suffix: ["", "", "", "", "级", "°", "°", "mm", "", "%"],
};

const Columns = [
  {
    title: "序号",
    render: (text: any, record: any, index: number) => `${index + 1}`,
  }, {
    title: "品种",
    dataIndex: "crop",
  }, {
    title: "物侯期",
    dataIndex: "period",
  }];
/** render left1 */
const RenderLeft1 = () => {
  return (
      <AxisChart
        chartData={getDataS2N1("region", ["识别面积", "统计面积"])}
        colorPalette={{ ...colorPalette, subColor: ["#13d7c8", "#e77300", ...colorPalette.subColor] }}
      />
  );
};

interface P {
  data: any[];
  weekForecast: WeekForecast[];
  agricultureWarning: AgricultureWarning[];
  changeWeatherDate(params: string): void;
  selected?: string;
}

interface S {
 /** 高亮的地区 */
  region: Region;
}

export default class MeteorologicalWarning extends React.PureComponent<P, S> {

  state: S = {
    region: {
      code: undefined,
      name: undefined,
      level: "city",
    },
  };

   /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
    this.setState({ region });
  }

  componentWillReceiveProps(nextProps: P) {

    // console.log(nextProps.weekForecast,this.props.weekForecast,'nextProps.chartData')

    // if (JSON.stringify(nextProps.weekForecast) !== JSON.stringify(this.props.weekForecast)) {

      // this.forceUpdate()
    // console.log(nextProps.weekForecast,this.props.weekForecast,'nextProps.weekForecast')

    // }
  }

  renderLeftTable = () => {
    const { data } = this.props;
    if (data && data.length === 0) {
      return null;
    }
    const data1 = [...data,{crop:'辣椒',period:this.determineGrowthStage()}]
    return (
      <Table
        columns={Columns}
        dataSource={data1}
        pagination={false}
      />
    );
  }

  determineGrowthStage = () =>{
    // 将日期字符串转换为 Date 对象
  const currentDate = new Date();

  // 提取月份和日期
  const month = currentDate.getMonth() + 1; // 月份从0开始，所以要加1
  const day = currentDate.getDate();
  // 根据月份和日期判断生育期
  if ((month === 4 && day >= 1 && day <= 20)) {
    return "播种期";
  } else if (month === 4 && day >= 21 && day <= 30 || 
              month === 5 && day >= 1 && day <= 10) {
    return "发芽期";
  } else if (month === 5 && day >= 11 && day <= 31 || 
              month === 6 && day >= 1 && day <= 20) {
    return "幼苗期";
  } else if (month === 6 && day >= 21 && day <= 30 || 
              month === 7 && day >= 1 && day <= 20) {
    return "开花坐果期";
  } else if (month === 7 && day >= 21 && day <= 31 || 
              month === 8 && day >= 1 && day <= 31) {
    return "结果期";
  } else {
    return "未进入作物生长期";
  }


  }

  renderLeftWarning = () => {
    const { agricultureWarning } = this.props;
    if (!agricultureWarning || agricultureWarning.length === 0) {
      return (
        <div style={{ height: "100%", width: "100%", textAlign: "center", lineHeight: "200px", fontWeight: 700 }}>
          今日无预警
        </div>
      );
    }
    return (
      <div className="warning-card">
        {agricultureWarning.map(item => {
          return (
            <>
              <div className="warning-header">
                <Avatar size={24} src={getWaringIcon(item.disaster)}/>
                <div className="warning-title">{item.disaster}预警</div>
              </div>
              <div className="desc-title">造成影响：</div>
              <div className="desc-content">{item.influence}</div>
              <Divider style={{ margin: "8px 0" }}/>
              <div className="desc-title">措施：</div>
              <div className="desc-content">{item.measure}</div>
            </>
          );
        })}
      </div>
    );
  }

  renderLeftForecast = () => {
    const { weekForecast , changeWeatherDate , selected } = this.props;
    if (!weekForecast || weekForecast.length === 0) {
      return null;
    }
    const selectedDate = selected === moment().format("YYYY-MM-DD") ? "今日" : moment(selected).format("MM/DD");
    dataS4N5.data = weekForecast.map((item, index: number) => {
      let chartItem: [string, string, string, string, string, string, number, number, number, number] = ["", "", "", "", "", "", 0, 0, 0, 0];
      const date = item.time.split("/");
      if (index === 0) {
        chartItem = ["", "", "今日",  item.weatherDescription, item.windDirection, item.windSpeed,  item.tmax, item.tmin, item.apcp, item.humidity];
      } else {
        chartItem = ["", "", item.time,  item.weatherDescription, item.windDirection, item.windSpeed,  item.tmax, item.tmin, item.apcp, item.humidity];
      }
      return chartItem;
    });
    console.log(444444, dataS4N5);
    return (
      <>
      <McCard
          title={"气象预报"}
        >
          <div style={{ height: 450 }}>
            <WeatherChart key={JSON.stringify(dataS4N5)} colorPalette={projectColorPalette} chartData={dataS4N5} onChange={changeWeatherDate} selected={selectedDate}/>
          </div>
        </McCard>
      </>
    );
  }

  render() {
    const { region } = this.state;
    return (
      <>
        <McCard
          title={"作物理论生育期"}
        >
          <div style={{  }}>
            {this.renderLeftTable()}
          </div>
        </McCard>
        <McCard
          title={"今日农业风险预警"}
        >
          <div style={{ height: 200 }}>
            {this.renderLeftWarning()}
          </div>
        </McCard>
        {/* <McCard
          title={"气象预报"}
        >
          <div style={{ height: 400 }}>
            <WeatherChart colorPalette={projectColorPalette} chartData={dataS4N5}/>
          </div>
        </McCard> */}
        {this.renderLeftForecast()}
      </>
    );
  }

  renderSidebar = () => {
    return (
      <>
        <McCard
        title={"作物理论生育期"}
       >
         <div style={{ height: 200 }}>
          <RenderLeft1 />
        </div>
        </McCard>
       <McCard
        title={"识别面积与统计面积"}
       >
         <div style={{ height: 200 }}>
          <RenderLeft1 />
        </div>
        </McCard>
      </>
    );
  }

}
