import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Form, Input, Select, Row, Col, Icon, Upload, message } from "antd";
import { ModalProps } from "antd/lib/modal";
import { FormComponentProps } from "antd/lib/form";

import UserManageStore from "../user-manage.store";
import UserManageService from "../user-manage.service";
import FormSelect from "@gago/lib/components/form-select";
import { Partial } from "../../../utils/type";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { ExpertCateInfo } from "../../../models/expert-database";
import { action, observable } from "mobx";
import { WrappedFormUtils } from "antd/lib/form/Form";

export interface Period {
    id: number,
    key: string,
    val: string,
    file: string,
    createTime: string,
    fileList: any[],
}

export interface FormData {
    name: string;//品种名称
    periodList: Period[];//物候期列表
}

const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};

interface Props extends ModalProps, Partial<FormComponentProps> {
    data: FormData;

    submitForm(values: FormData): void;

    edit: boolean;
}

// @ts-ignore
@Form.create()
@observer
export class UserModalComponent extends Component<Props> {

    // private renderDepartmentOptions() {
    //   return UserManageStore.departments.map(({ departmentId, departmentName }) => (
    //     <Select.Option key={departmentId} value={departmentId}>{departmentName}</Select.Option>
    //   ));
    // }

    private renderRoleDropOptions() {
        return UserManageStore.roleTree.map(item => (
            <Select.Option key={item.roleId} value={item.roleId}>{item.role}</Select.Option>
        ));
    }

    renderRoleOptions() {
        return UserManageStore.roleList.map((item) => (
            <Select.Option key={item.key} value={item.key}>{item.name}</Select.Option>
        ));
    }

    private submitForm = () => {
        const {submitForm, form, data} = this.props;
        if (!form) return;
        form.validateFields((err: any) => {
            // tslint:disable-next-line:early-exit
            if (!err) {
                form.validateFields((error: any, values: FormData) => {
                    if (error) {
                        return;
                    }
                    data.periodList.forEach(item => {
                        // @ts-ignore
                        item.val = values[item.key];
                    });
                    data.name = values.name;
                    submitForm({...data});
                });
                form.resetFields();
            }
        });
    };

    handleUploadBefore = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("请选择图片！");
        }
        // const isLt2M = file.size / 1024 /1024 < 2;
        // if (!isLt2M) {
        //   message.error('图片大于2M');
        // }
        return isJpgOrPng;
    };

    /**
     * 返回
     * @private
     * @param {*} e
     * @memberof UserModalComponent
     */
    private clickCancel = (e: any) => {
        const {onCancel, form} = this.props;
        onCancel && onCancel(e);
        // @ts-ignore
        form.resetFields();
    };

    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await this.handleGetBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    // 生成预览图片
    handleGetBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    uploadChange = (res: UploadChangeParam<UploadFile<any>>, key: string) => {
        const {data} = this.props;
        data.periodList.forEach(item => {
            if (item.key === key) {
                item.fileList = [...res.fileList];
                if (res.file.status === "done") {
                    item.file = res?.file?.response?.url || "";
                }
            }
        });
        this.setState({fileList: [...res.fileList]});

    };

    render() {
        const {title, visible, confirmLoading, data, edit} = this.props;
        const token = window.sessionStorage.getItem("RouYiToken") as string;
        const header = {
            "Authorization": token
        };
        const {getFieldDecorator} = this.props.form as WrappedFormUtils<any>;
        // @ts-ignore
        const departmentData = UserManageStore.departments.map(item => ({
            key: item.departmentId,
            value: item.departmentName
        }));

        // tslint:disable jsx-no-multiline-js
        return (
            <Modal
                width={"60%"}
                title={title}
                visible={visible}
                onCancel={this.clickCancel}
                onOk={this.submitForm}
                confirmLoading={confirmLoading}
            >
                <Form style={{margin: "10px"}}>

                    <Form.Item label="品种名称" {...formItemLayout}>

                        {getFieldDecorator("name", {
                            initialValue: data.name,
                        })(<Input placeholder={"请输入品种名称"}/>)}
                    </Form.Item>
                    <Row style={{textAlign: "center", margin: "20px 0", fontSize: "16px"}}>
                        <Col span={8}>名称</Col>
                        <Col span={8}>值</Col>
                        <Col span={8}>照片</Col>
                    </Row>

                    {
                        data.periodList.map((item, index) => {
                            return (
                                <Row style={{textAlign: "center"}} type="flex" align="middle">
                                    <Col span={8}>{item.key}</Col>
                                    <Col span={8}>
                                        <Form.Item label="" {...formItemLayout}>
                                            {getFieldDecorator(item.key, {
                                                initialValue: item.val,
                                            })(<Input placeholder={"请输入"}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Upload
                                            action={`${window.config.publisher2}/common/upload`}
                                            headers={header}
                                            onPreview={this.handlePreview}
                                            listType="picture-card"
                                            onChange={(list) => this.uploadChange(list, item.key)}
                                            fileList={item.fileList}
                                            beforeUpload={this.handleUploadBefore}
                                        >

                                            {item.fileList && item.fileList?.length >= 1 ? null : (
                                                <Icon type="plus"></Icon>)}

                                        </Upload>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </Form>
            </Modal>
        );
    }
}
