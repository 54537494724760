import React, { Component } from "react";
import queryString from "query-string";
import {
  baseDistrictPark,
  BigScreenBody,
  chart2N1D,
  germplasResourceData,
  MarkerIcom,
} from "./style";
import { chartStyle, colorPalette, pieChartColor } from "../../color-palette";
import {
  AppRouteNav,
  CropContrastCard,
  CropLineCardClick,
  CropRatioCardClick,
  McBarChart,
  MCCard,
  ScreenCard,
} from "@gago/lib/components";
import { Icon, ITable } from "@gago/frame";
import { BaseLayer, MapGL, MapPosition } from "@gago-react-gl/gago-react-gl";
import { bmMapDefault } from "../../containers/map-layer";
import { HuangHeHightFillMapLayer, routesConfig } from "./map-layer";
import { Input, message, Popover, Select, Tooltip, Tree } from "antd";
import { PaginationConfig } from "antd/lib/table";
import { HuangHeMapLayer } from "../../containers/huanghe-may-layer";
import GermplasmResourceProvider from "../../network/providers/germplasm-resource.provider";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import ExpertDataBaseProvider from "../../network/providers/expert-database.provider";
import {
  ExpertCateInfo,
  ExpertEditRequest,
  ExpertListType,
} from "../../models/expert-database";
import { CropCategory } from "../../models/germplasm-database";
import {
  NorthwestRegionParkBaseQuery,
  NorthwestRegionParkNumber,
  NorthwestRegionPointInfo,
  NorthwestRegionPointInfoTypeBase,
  RegionTree1,
  ClassifySummary,
  PlantingSummary,
  PlantList,
  IntegratedQuery,
  IntegratedRequestType,
  RegionSummary,
  PlantAll,
} from "../../models/germplasm-resource";
import { RouteComponentProps } from "react-router-dom";
import { LeftCard } from "./left-card";
import Map, { Region } from "./map";
import { scalePage } from "../../decorator/scale-page";
const { TreeNode } = Tree;

// tslint:disable:jsx-no-multiline-js ter-max-len jsx-no-lambda jsx-alignment
type Gprops = {} & RouteComponentProps;
interface Gstate {
  /** 县镇的code */
  townCode: string;
  /** 园区基地数量统计 */
  northwestPark: NorthwestRegionParkNumber[];
  /** 地图放大层级 */
  zoomNum: number;
  /** 地图展示中心点 */
  lonLatCenter: [number, number];
  /** 控制左侧图表的隐藏 */
  leftVisiable: boolean;
  /** 控制右侧的图标的隐藏 */
  rightVisiable: boolean;
  /** 选择园区基地范围类型 */
  pointKeys: string[];
  /** 选择一区多园类型 */
  fillKeys: string[];
  /** 地图上标类型选择 */
  mapBaseType: string;
  /** marker标记点详情数据 */
  markerDetailData: NorthwestRegionPointInfo | null;
  /** 园区基地范围及信息查询 */
  northwestBaseList: NorthwestRegionParkBaseQuery[];
  /** 种质资源数据查询 */
  northwestPointInfo: NorthwestRegionPointInfo[];
  /** 获取种植面积 */
  northwestPlantingArea: ITable<2, 1> | null;
  /** 种质资源数据类型列表查询 */
  pointInfoType: NorthwestRegionPointInfoTypeBase[];
  /** 园区基地类型列表查询！ */
  parkBaseType: NorthwestRegionPointInfoTypeBase[];
  /** 一区多园N基地图标点击事件 */
  markerParkBaseData: NorthwestRegionParkBaseQuery | null;
  // 省市信息列表
  areaList: RegionTree1[];
  // 作物种类列表
  cropCategory: CropCategory[];
  // 种质分类对应数量
  classifySummary: ClassifySummary[];
  // 专家特长
  expertSpecialty: ExpertCateInfo[];
  // 专家列表
  expertListInfo: ExpertListType;
  // 专家特长
  specialty: string;
  // table 分页
  size?: number;
  page?: number;
  // 选择的省份 第一个是省份第二个是市区['江苏省'， ‘南京市’]
  areaName: string[];
  // 行政区域编码
  areaCode: string[];
  // 是否是特色种质 0:否 1:是
  isTs?: number;
  // 1:种质 2:一区多园 3:特色种植 4: 专家
  type: number;
  // 种植园类型
  parkType: string;
  // 基地类型
  baseType: string;
  // 核心区
  district: string;
  // 种质类型/专家专业类型
  category: string;
  // 查询明细列表
  plantList: PlantList;
  // 综合查询统计数据
  integratedData: IntegratedQuery[];
  /** 高亮的地区 */
  region: Region;
  /** 地区汇总气泡 */
  regionSummary?: RegionSummary[];
  /** 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 */
  plantAll?: PlantAll[];
  /** 显示类型 */
  mapMarkerType: "statistics" | "locus";
  /** 获取黄河西北5省种质汇总 */
  plantingSummary?: PlantingSummary;
  // 查询明细用户输入框输入的值
  selectName: string;
  /** 是否更改为了坐标气泡 */
  isChangeLocus?: boolean;
  bbox: [number, number, number, number];
  mapZoom: number;
  /** 专家数据 */
  expertDataMsg?: any;
  /** 专家信息弹窗隐藏 */
  expertVisiable: boolean;
  // center:[number,number]
}
/**
 * 种质资源一张图
 * @author 马俊峰
 * @date 2020-11-12
 * @export
 * @class GermplasmResource
 * @extends {Component<Gprops, Gstate>}
 */
class GermplasmResource extends Component<Gprops, Gstate> {
  /** 地图交互图层事件 */
  baseMapStatus = true;
  constructor(props: Gprops) {
    super(props);
    const { location: { search } } = this.props;
    const parseSearch = queryString.parse(search, { parseNumbers: true });
    this.state = {
      townCode: "",
      northwestPark: [],
      leftVisiable: true,
      rightVisiable: false,
      pointKeys: [],
      fillKeys: [],
      zoomNum: 5.2,
      lonLatCenter: [102.1, 37.485],
      mapBaseType: "",
      markerDetailData: null,
      northwestBaseList: [],
      northwestPointInfo: [],
      northwestPlantingArea: null,
      pointInfoType: [],
      parkBaseType: [],
      markerParkBaseData: null,
      areaList: [],
      // 公共字段
      areaName: [],
      // 种质所需字段
      cropCategory: [],
      classifySummary: [],
      // 专家知识库所需字段
      expertSpecialty: [],
      expertListInfo: {
        count: 0,
        list: [],
      },
      specialty: "",
      // table 分页
      size: 10,
      page: 1,
      region: {
        code: undefined,
        name: undefined,
        level: "province",
      },
      mapMarkerType: "statistics",
      // 1:种质 2:一区多园 3:特色种植 4: 专家
      type: parseSearch.mapType as number || 1,
      areaCode: [],
      parkType: "all",
      baseType: "all",
      category: "all",
      plantList: {
        list: [],
        count: 0,
      },
      integratedData: [],
      district: "all",
      selectName: "",
      bbox: [
        84.19590345408773,
        30.959602475919084,
        120.0040965459171,
        43.486709602471706,
      ],
      mapZoom: 5.2,
      expertDataMsg: null,
      expertVisiable: false,
      // center:[]
    };
  }

  /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
    const { areaList } = this.state;
    const tempRegion = this.state.region;
    let area: RegionTree1 | undefined = {} as RegionTree1;
    let areaName: string[] = [];
    let areaCode: string[] = [];
    const list: any[] = [];

    area = areaList?.find((item) => {
      if (item.code === region.code) {
        areaName = [item.name];
        areaCode = [region.code];
        return true;
      }
      return item?.children?.find((city) => {
        if (city.code === region.code) {
            areaName = [item.name, city.name];
            areaCode = [item.code, city.code];
            return true;
          }
      });

    });
    if (region.code === undefined && region.name === undefined) {
      areaName = [""];
      areaCode = [""];
    }

    this.setState({ region, areaCode, areaName }, () => {
      const { region } = this.state;
      if (tempRegion.level !== region.level) {
        this.getRegionSummary();
      }

      if (tempRegion.code !== region.code) {
        this.getPlantingSummary();
        this.getIntegratedData();
      }
    });
  }

  /** 初始化一次加载 */
  initLoadData = async () => {
    const northwestPark = await GermplasmResourceProvider.getNorthwestPark().catch(
      (err) => {
        message.info("园区基地数量统计信息！", 1);
        return [];
      },
    );
    const pointInfoType = await GermplasmResourceProvider.getNorthwestPointInfoType().catch(
      (err) => {
        message.info("种质资源数据类型列表查询！", 1);
        return [];
      },
    );
    const parkBaseType = await GermplasmResourceProvider.getNorthwestParkBaseType().catch(
      (err) => {
        message.info("园区基地类型列表查询！", 1);
        return [];
      },
    );
    this.setState({ northwestPark, pointInfoType, parkBaseType });
  }

  /** get 获取黄河西北5省种质汇总 */
  getPlantingSummary = async () => {
    const { region } = this.state;
    const plantingSummary = await GermplasmResourceProvider.getPlantingSummary({
      code: region.code,
      name: region.name,
    });
    this.setState({ plantingSummary });
  }

  /** 园区基地范围及信息查询 */
  getNorthwestBaseList = async (type: string) => {
    const typeKey = type.split("_");
    const param: any = {};
    param[typeKey[0]] = typeKey[1];
    if (typeKey.length > 3) {
      param[typeKey[2]] = typeKey[3];
    }
    const northwestBaseList = await GermplasmResourceProvider.getNorthwestBaseList(
      param,
    ).catch((err) => {
      message.info("园区基地范围及信息查询！", 1);
      return [];
    });
    this.setState({ northwestBaseList });
  }
  /** 获取种植面积 */
  getNorthwestPlantingArea = async (type: string) => {
    const typeKey = type.split("_");
    const param: any = {};
    param[typeKey[0]] = typeKey[1];
    if (typeKey.length > 3) {
      param[typeKey[2]] = typeKey[3];
    }
    const northwestPlantingArea = await GermplasmResourceProvider.getNorthwestPlantingArea(
      param,
    ).catch((err) => {
      message.info("获取种植面积！", 1);
      return null;
    });
    this.setState({ northwestPlantingArea });
  }
  /** 种质资源数据查询 */
  getNorthwestPointInfo = async (type: string) => {
    const typeKey = type.split("_");
    const param: any = {};
    param[typeKey[0]] = typeKey[1];
    const northwestPointInfo = await GermplasmResourceProvider.getNorthwestPointInfo(
      param,
    ).catch((err) => {
      message.info("种质资源数据查询！", 1);
      return [];
    });
    this.setState({ northwestPointInfo });
  }
  // 省市地区查询
  getAreaList = async () => {
    const areaList = await GermplasmResourceProvider.getRegionTree();
    console.log(333333, areaList);
    const areaAll: RegionTree1 = {
      name: "全部",
      value: -1,
      id: -1,
      // children:[],
      code: "-1",
      parentName: "",
      parentCode: "",
      bbox: [0, 0, 0, 0],
      xmax: 0,
      ymax: 0,
      xmin: 0,
      ymin: 0,
      lon: "",
      lat: "",
    };
    areaList.forEach((item) => {
      if (item.children && item.children.length !== 0) {
        item.children.unshift(areaAll);
      }
    });
    areaList.unshift(areaAll);
    this.setState({ areaList });
  }
  // 获取作物种类
  getCropCategory = async () => {
    const cropCategory = await GermplasmDatabaseProvider.getCropCategory();
    this.setState({ cropCategory });
  }
  // 获取商品种质对应数量排名
  getClassifySummary = async () => {
    const classifySummary = await GermplasmResourceProvider.getClassifySummary();
    this.setState({ classifySummary });
  }

  // 获取专家特长
  getExpertSpecialty = async () => {
    const expertSpecialty = await ExpertDataBaseProvider.asyncExpertCateInfo({
      type: "planting_expert_specialty",
    });
    this.setState({ expertSpecialty });
  }

  //  获取专家列表
  getExpertList = async () => {
    const { areaName, specialty, page, size } = this.state;
    const provinceName = areaName[0] || "";
    const expertListInfo = await ExpertDataBaseProvider.getExpertList({
      type: "",
      provinceName,
      specialty,
      page,
      size,
    });
    this.setState({ expertListInfo });
  }

  /** 获取地区汇总 气泡 */
  getRegionSummary = async (regionType?: string) => {
    const {
      type,
      region,
      isTs,
      parkType,
      baseType,
      category,
      district,
    } = this.state;
    const regionSummary = await GermplasmResourceProvider.getRegionSummary({
      type,
      dataType: region.level || regionType || "province",
      isTs,
      parkType: parkType || undefined,
      baseType: baseType || undefined,
      category: category || undefined,
      district: district || undefined,
    });
    this.setState({ regionSummary });
  }

  /** 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 */
  getPlantAll = async () => {
    const { type, isTs, parkType, baseType, category, district } = this.state;
    const plantAll = await GermplasmResourceProvider.getPlantAll({
      type,
      isTs,
      parkType: parkType || undefined,
      baseType: baseType || undefined,
      category: category || undefined,
      district: district || undefined,
    });
    this.setState({ plantAll });
  }

  // 获取综合查询数据
  getIntegratedData = async () => {
    const {
      areaName,
      areaCode,
      type,
      parkType,
      baseType,
      category,
      district,
      isTs,
    } = this.state;

    /** 改变地图气泡值 */
    // this.getRegionSummary();
    // this.getPlantAll();

    const code = areaCode[1] || areaCode[0] || "";
    const name = areaName[1] || areaName[0] || "";
    const integratedData = await GermplasmResourceProvider.getIntegratedQuery({
      isTs,
      code,
      name,
      type,
      parkType,
      baseType,
      category,
      district,
    });
    this.setState({ integratedData });
  }

  // 查询明细列表
  getPlantList = async () => {
    const {
      areaName,
      areaCode,
      type,
      parkType,
      baseType,
      category,
      isTs,
      size,
      page,
      selectName,
      district,
    } = this.state;
    const code = areaCode[1] || areaCode[0] || "";
    const name = areaName[1] || areaName[0] || "";

    const plantList = await GermplasmResourceProvider.getPlantList({
      code,
      name,
      type,
      parkType,
      baseType,
      category,
      page,
      isTs,
      selectName,
      district,
      size: 10,
    });
    this.setState({ plantList });
  }

  handleSearchDetailSelf = (selectName: string) => {
    this.setState(
      {
        selectName,
      },
      () => {
        this.getPlantList();
      },
    );
  }

  // 改变综合查询请求参数
  /**处理用户查询的参数 */
  handleIntegratedRequest = (params: IntegratedRequestType) => {
    const { type, areaList } = this.state;
    let { areaName } = params;
    let areaCode: string[] = [];
    // let bbox:[number,number,number,number] = [84.19590345408773, 30.959602475919084, 120.0040965459171, 43.486709602471706]
    let lonLatCenter: [number, number] = [102.1, 37.485];
    let mapZoom = 5.2;
    const area = areaList.find((item) => item.name === areaName[0]);
    areaCode.push(area?.code as string);
    areaName = areaName.filter((item: string) => item !== "全部");
    // let selectedArea = areaName.filter((item:any) => item !== '全部');
    if (areaName.length === 0) {
      areaCode = [];
      areaName = [];
    } else if (areaName.length === 1) {
      // let city = area?.children?.find(item => item.name === areaName[0]);
      // areaCode.push(city?.bbox)
      // bbox = area?.bbox as [number,number,number,number];
      mapZoom = 7;
      lonLatCenter = [Number(area?.lon), Number(area?.lat)] as [number, number];
    } else if (areaName.length === 2) {
      const city = area?.children?.find((item) => item.name === areaName[1]);
      areaCode.push(city?.code as string);
      // bbox = city?.bbox as [number,number,number,number];
      lonLatCenter = [Number(city?.lon), Number(city?.lat)] as [number, number];
      mapZoom = 8;
    }
    console.log(lonLatCenter, "lonlatCenter");
    if (areaCode && areaCode.length > 0 && areaName && areaName.length > 0) {
      this.changeRegion({
        code: areaCode[areaCode.length - 1],
        name:
          areaName[areaName.length - 1] === "全部"
            ? areaName[areaName.length - 2]
            : areaName[areaName.length - 1],
        level: areaCode.length === 2 ? "county" : "city",
      });
    }
    if (
      areaCode &&
      areaCode.length === 0 &&
      areaName &&
      areaName.length === 0
    ) {
      this.changeRegion({
        code: undefined,
        name: undefined,
        level: "province",
      });
    }

    if (type === 1) {
      const { cropCate } = params;
      const category: string = cropCate?.includes(-1)
        ? "all"
        : (cropCate?.join(",") as string);
      this.setState(
        {
          isTs: 0,
          category,
          areaCode,
          areaName,
          lonLatCenter,
          mapZoom,
        },
        () => {
          this.getIntegratedData();
          this.getPlantList();
        },
      );
    } else if (type === 2) {
      const { parkType } = params;
      let changeObj: any = { isTs: 0, areaName, areaCode };
      if (parkType?.includes("all")) {
        return this.setState(
          {
            district: "all",
            baseType: "all",
            parkType: "all",
            ...changeObj,
            lonLatCenter,
            mapZoom,
          },
          () => {
            this.getIntegratedData();
            this.getPlantList();
          },
        );
      }
      const park: any[] = [],
        base: any[] = [],
        district: any[] = [];
      parkType?.forEach((item) => {
        const typeArray = item.split("_");
        const realType = typeArray[typeArray.length - 1];
        if (realType === "park") {
          park.push(item);
        } else if (realType === "base") {
          base.push(item);
        } else if (realType === "district") {
          district.push(item);
        }
      });
      const parkString = park.includes("all_baseType_park")
        ? "all"
        : park.join(",");
      const districtString = district.length !== 0 ? "all" : "";
      const baseString = base.includes("all_baseType_base")
        ? "all"
        : base.join(",");
      changeObj = {
        ...changeObj,
        parkType: parkString,
        baseType: baseString,
        district: districtString,
        lonLatCenter,
        mapZoom,
      };
      this.setState(changeObj, () => {
        this.getIntegratedData();
        this.getPlantList();
      });
    } else if (type === 3) {
      const { cropCate } = params;
      const category: string = cropCate?.includes(-1)
        ? "all"
        : (cropCate?.join(",") as string);
      this.setState(
        {
          isTs: 1,
          category,
          areaCode,
          areaName,
          lonLatCenter,
          mapZoom,
        },
        () => {
          this.getIntegratedData();
          this.getPlantList();
        },
      );
    } else if (type === 4) {
      const { expertSpecialty } = params;
      const category: string = expertSpecialty?.includes("全部")
        ? "all"
        : (expertSpecialty?.join(",") as string);
      this.setState(
        {
          isTs: 0,
          category,
          areaCode,
          areaName,
          lonLatCenter,
          mapZoom,
        },
        () => {
          this.getIntegratedData();
          this.getPlantList();
        },
      );
    }
  }

  // 改变type
  handleChangeType = (index: number) => {
    this.setState(
      {
        type: index + 1,
        category: "all",
        district: "all",
        baseType: "all",
        parkType: "all",
        integratedData: [],
        areaName: [],
        page: 1,
      },
      () => {
        this.getRegionSummary();
        this.getPlantAll();
        this.getIntegratedData();
      },
    );
  }

  // 处理分页
  handlePagination = (pagination: PaginationConfig) => {
    const { pageSize, current } = pagination;
    this.setState(
      {
        size: pageSize,
        page: current,
      },
      () => {
        this.getPlantList();
      },
    );
  }
  /** state重置函数 */
  resetState = () => {
    this.setState({
      zoomNum: 5.2,
      lonLatCenter: [102.1, 37.485],
      rightVisiable: false,
      pointKeys: [],
      fillKeys: [],
      mapBaseType: "",
      markerDetailData: null,
      northwestBaseList: [],
      northwestPointInfo: [],
      northwestPlantingArea: null,
      markerParkBaseData: null,
    });
  }
  async componentDidMount() {
    this.getSignList();

    this.initLoadData();

    this.getAreaList();
    this.getCropCategory();

    this.getExpertSpecialty();
    // this.getIntegratedData();

    // this.getPlantingSummary();
    this.getRegionSummary();
    this.getPlantAll();

    // await this.getPlantList();
  }

  /** 是否为建设中 */
  isBuilding = (name?: string) => {
    if (!name || name.includes("内蒙古") || name.includes("巴彦淖尔")) {
      return false;
    }
    return true;
  }

  /** handle 页面跳转 */
  handlePageJump = (url: string) => {
    this.props.history.push(url);
  }
  handlePageJumpHref = () => {
    location.href = "http://tfht.group3yes.com/iportal/apps/mapdashboard/v2/index.html?id=1966732232&action=view";
  }

  /** 初始化获取数据接口状态 */
  getSignList = async () => {
    const res = await GermplasmResourceProvider.getSignList();
    if (res && res.rows && res?.rows[0].systemStatus === "on") {
      this.getSignAllData();
    } else {
      this.getPlantingSummary();
      this.getIntegratedData();
    }
  }
  /** 初始化获取数据接口状态 */
  getSignAllData = async () => {
    const data: any = await GermplasmResourceProvider.getSignAllData();
    console.log("lbdata", data);
    this.setState({
      plantingSummary: data.summary,
      integratedData: data.integratedQuery,
    });
  }
  // tslint:disable:cyclomatic-complexity
  render() {
    const {
      region,
      zoomNum,
      pointKeys,
      fillKeys,
      lonLatCenter,
      northwestPark,
      leftVisiable,
      rightVisiable,
      mapBaseType,
      bbox,
      northwestPlantingArea,
      pointInfoType,
      parkBaseType,
      markerParkBaseData,
      areaList,
      cropCategory,
      plantingSummary = {} as PlantingSummary,
      regionSummary,
      plantAll,
      expertSpecialty,
      areaName,
      type,
      mapMarkerType,
      plantList,
      integratedData,
      parkType,
      baseType,
      district,
      category,
      mapZoom,
      expertDataMsg,
      expertVisiable,
    } = this.state;
    return (
      <BigScreenBody colorPalette={colorPalette} id="big_screen_body">
        <div className="header-box">
          {/* <div className="header-name">黄河流域西北地区种质资源平台</div> */}
        </div>
        <div className="body-content">
          <div className="mc-chart-box">
            <CropRatioCardClick
              title="黄河流域西北种质资源登记数量"
              className="w-296"
              data={plantingSummary.plantCount || "0"}
              suffix="份"
              bgIcon="bg-01"
              colorPalette={colorPalette}
            />
            <div className="line-card-box">
              <CropLineCardClick
                title="入库种质资源"
                data={plantingSummary.putInventoryCount || "0"}
                suffix="份"
                colorPalette={colorPalette}
                onClick={this.handlePageJump.bind(
                  this,
                  "/germplas-nav/germplasm-database",
                )}
              />
              <CropLineCardClick
                title="地区特色种质"
                data={plantingSummary.tsPlantCount || "0"}
                className="mt-8"
                suffix="种"
                colorPalette={colorPalette}
                onClick={this.handlePageJump.bind(
                  this,
                  "/germplas-nav/germplasm-database",
                )}
              />
            </div>
            <CropRatioCardClick
              title="种质身份证"
              className="w-132"
              data={plantingSummary.plantIdentity || "0"}
              suffix="份"
              colorPalette={colorPalette}
            />
            <CropRatioCardClick
              title="专家智库"
              className="w-132"
              data={plantingSummary.plantExpertCount || "0"}
              suffix="人"
              colorPalette={colorPalette}
              onClick={this.handlePageJump.bind(
                this,
                "/germplas-nav/expert-database",
              )}
            />
            <CropRatioCardClick
              title="种质测试报告"
              className="w-132"
              data={plantingSummary.plantTestCount || "0"}
              suffix="份"
              colorPalette={colorPalette}
              onClick={this.handlePageJump.bind(
                this,
                "/germplas-nav/germplasm-test-report",
              )}
            />
            <CropRatioCardClick
              title="分子育种报告"
              className="w-132"
              data={plantingSummary.plantBreedingCount || "0"}
              suffix="份"
              colorPalette={colorPalette}
              onClick={this.handlePageJumpHref}
            />
            <CropRatioCardClick
              title="核心区"
              className="w-270"
              // data={northwestPark.find((item) => item.baseType === "district")?.count || "0"}
              data={
                this.isBuilding(region.name)
                  ? "建设中"
                  : plantingSummary.plantCoreAreaCount || "0"
              }
              suffix={this.isBuilding(region.name) ? "" : "核心"}
              bgIcon="bg-02"
              colorPalette={colorPalette}
              active={mapBaseType === "district"}
              onClick={() => {
                this.headCardTypeSelect2("district");
              }}
            />
            <div className="line-card-box w-162">
              <CropLineCardClick
                title="专项产业园"
                // data={northwestPark.find((item) => item.baseType === "park")?.count || "0"}
                data={plantingSummary.idustrialParkCount || "0"}
                suffix="个"
                colorPalette={colorPalette}
                active={mapBaseType === "park"}
                onClick={() => {
                  this.headCardTypeSelect2("park");
                }}
              />
              <CropLineCardClick
                title="良种繁育基地"
                // data={northwestPark.find((item) => item.baseType === "base")?.count || "0"}
                data={plantingSummary.baseCount || "0"}
                className="mt-8"
                suffix="个"
                colorPalette={colorPalette}
                active={mapBaseType === "base"}
                onClick={() => {
                  this.headCardTypeSelect2("base");
                }}
              />
            </div>
            <CropRatioCardClick
              title="AI决策"
              className="w-216"
              data={
                this.isBuilding(region.name)
                  ? "建设中"
                  : plantingSummary.aiArea || "0"
              }
              suffix={this.isBuilding(region.name) ? "" : "亩"}
              bgIcon="bg-03"
              colorPalette={colorPalette}
              onClick={this.aiDecisionClick}
            />
          </div>
          <ScreenCard className="mc-map-layer" colorPalette={colorPalette}>
            <Map
              // bbox={bbox}
              mapZoom={mapZoom}
              lonLatCenter={lonLatCenter}
              customMapDefault={bmMapDefault}
              onClick={this.mapClickLayer}
              onChangeRegion={this.changeRegion}
              region={region}
              regionSummary={regionSummary}
              plantAll={plantAll}
              type={type}
              getRegionSummary={this.getRegionSummary}
              getPlantAll={this.getPlantAll}
              mapMarkerType={mapMarkerType}
              handleMapMarkerTypeChange={this.handleMapMarkerTypeChange}
            >
              <MapPosition
                zoom={zoomNum}
                center={[102.1, 37.485]}
                moveMethod="flyTo"
              />
              {/* {this.renderFillMarkerLayer()}
              {this.renderTypeMarkerIcon()} */}
              {this.renderMapMarkerTypeSelect()}
            </Map>
            {
              expertVisiable && expertDataMsg && (<div className="ExpertInformation">
              <div className="ExpertTitle">
                <span>专家信息</span>
                <span className="closeBtn" onClick={this.expertClose}></span>
              </div>
              <div className="ExpertCont">
                <div className="ExpertImg">
                  <img src={expertDataMsg.headUrl}></img>
                  <div className="baseInfo">
                    <div className="name">{expertDataMsg.name}</div>
                    <div className="infoList">
                      <div>
                      <span className="infoTit">从业年限:</span>
                      <span className="infoCont">{expertDataMsg.workingSeniority}年</span>
                      </div>
                      <div>
                      <span className="infoTit">专家类型:</span>
                      <span className="infoCont">{expertDataMsg.type}</span>
                      </div>
                      <div>
                      <span className="infoTit">研究领域:</span>
                      <span className="infoCont">{expertDataMsg.specialty}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ExpertCont1">
                  <div className="baseInfo1">
                    <span className="infoTit">性别:</span>
                    <span>{expertDataMsg.sex === 1 ? "男" : "女"}</span>
                  </div>
                  <div className="baseInfo1">
                    <span className="infoTit">民族:</span>
                    <span>{expertDataMsg.nationality}</span>
                  </div>
                  <div className="baseInfo1">
                    <span className="infoTit">职务/职位:</span>
                    <span>{expertDataMsg.position}</span>
                  </div>
                  <div className="baseInfo1">
                    <span className="infoTit">职称:</span>
                    <span>{expertDataMsg.positionalTitle}</span>
                  </div>
                  <div className="baseInfo2">
                    <span className="infoTit">现任单位:</span>
                    <span>{expertDataMsg.departmentName}</span>
                  </div>
                </div>
                <div className="ExpertCont2">
                  <div className="contTit">获奖情况:</div>
                  <div className="contBody">{expertDataMsg.prizes}</div>
                </div>
                <div className="ExpertCont2">
                  <div className="contTit">其他情况:</div>
                  <div className="contBody">{expertDataMsg.presentation}</div>
                </div>
              </div>
            </div>)
            }

            {/* <div className="left-search" style={{ height: leftVisiable ? "calc(100% - 32px)" : "48px" }}>
              <div className="ls-title">
                <div className="lst-left">
                  <span className="lstl-icon" />
                  <span className="lstl-value">综合查询</span>
                </div>
                <span className="lst-right" onClick={this.leftVisiableClick}>
                  <Icon>{leftVisiable ? "double-down-single" : "double-up-single"}</Icon>
                </span>
              </div>
              <div className="bottom-line" />
              <div className="search-box">
                <Input.Search
                  placeholder="请输入"
                  onSearch={this.baseNameSearch}
                />
              </div>
              <div className="list-border">
                <div className="point-info">
                  <Tree.DirectoryTree
                    defaultExpandAll
                    showIcon={false}
                    selectedKeys={pointKeys}
                    // switcherIcon={<Icon>layer</Icon>}
                    onSelect={this.onSelectPointCheck}
                  >
                    {this.renderTreeNodes(pointInfoType)}
                  </Tree.DirectoryTree>
                </div>
                <div className="point-fill">
                  <Tree.DirectoryTree
                    defaultExpandAll
                    showIcon={false}
                    selectedKeys={fillKeys}
                    // switcherIcon={<Icon>layer</Icon>}
                    onSelect={this.onSelectFillCheck}
                  >
                    {this.renderTreeNodes(parkBaseType)}
                  </Tree.DirectoryTree>
                </div>
              </div>
            </div> */}
            <LeftCard
              areaList={areaList}
              cropCategory={cropCategory}
              handleChangeType={this.handleChangeType}
              areaName={areaName}
              parkBaseType={parkBaseType}
              expertSpecialty={expertSpecialty}
              integratedData={integratedData}
              plantList={plantList}
              handlePagination={this.handlePagination}
              getPlantList={this.getPlantList}
              handleIntegratedRequest={this.handleIntegratedRequest}
              handleSearchDetailSelf={this.handleSearchDetailSelf}
              type={this.state.type}
              category={category}
              parkType={parkType}
              baseType={baseType}
              district={district}
              region={region}
              handleChangeLocus={this.handleChangeLocus}
              handleMapMarkerTypeChange={this.handleMapMarkerTypeChange}
              tableClick={this.tableClick}
              size={this.state.size}
            />
            {/* <div className="right-chart" style={{ bottom: rightVisiable ? "8px" : "-545px" }}>
              <div className="float-icon" onClick={this.rightVisiableClick}>
                <Icon>{rightVisiable ? "double-down-single" : "double-up-single"}</Icon>
              </div>
              <div className="chart-border">
                <MCCard colorPalette={colorPalette} title="作物种植面积" className="chart-mt-12">
                  <div className="h-200">
                    {northwestPlantingArea && <McBarChart
                      chartData={northwestPlantingArea}
                      chartStyle={chartStyle}
                      ySplitNumber={3}
                      barWidth={8}
                      colorPalette={pieChartColor}
                    />}
                  </div>
                </MCCard>
                {markerParkBaseData?.video && <MCCard colorPalette={colorPalette} title={markerParkBaseData.baseName || "基地产业园宣传视频"} className="chart-mt-12">
                  <div className="h-200">
                    <video className="vider-border" controls muted autoPlay>
                      <source src={markerParkBaseData.video} type="video/mp4" />
                      您的浏览器不支持 video 标签。
                    </video>
                  </div>
                </MCCard>}
              </div>
            </div> */}
          </ScreenCard>
        </div>
      </BigScreenBody>
    );
  }
  tableClick = (records: any) => {
    console.log("records", records);
    const { plantList } = this.state;
    console.log("plantList", plantList);
    const user = plantList?.list.find(item => item.name === records[0]);
    console.log("user", user);
    GermplasmResourceProvider.getExpertDetail(user?.id).then(res => {
      console.log(res);
      this.setState({
        expertDataMsg: res,
        expertVisiable: true,
      });
    });

  }
  expertClose = () => {
    this.setState({
      expertVisiable: false,
    });
  }
  handleChangeLocus = (isChangeLocus: boolean) => {
    this.setState({ isChangeLocus });
  }

  changeMarkerType = (value: "statistics" | "locus") => {
    if (this.state.mapMarkerType === value) {
      return;
    }
    this.setState({ mapMarkerType: value });
  }

  /** 地图气泡类型 变化 */
  handleMapMarkerTypeChange = (value: "statistics" | "locus", needFixLocus?: boolean) => {
    const { isChangeLocus } = this.state;
    if (isChangeLocus) {
      return;
    }
    this.changeMarkerType(value);
    if (value === "locus" && needFixLocus) {
      this.handleChangeLocus(true);
    }
  }

  handleSelectChange = (value: "statistics" | "locus") => {
    this.changeMarkerType(value);
    if (value === "locus") {
      this.handleChangeLocus(true);
    } else {
      this.handleChangeLocus(false);
    }
  }

  /** render 地图气泡类型 */
  renderMapMarkerTypeSelect = () => {
    const { mapMarkerType } = this.state;
    return (
      <Select
        value={mapMarkerType || "statistics"}
        dropdownClassName="common-select-menu-style"
        style={{ width: 180, position: "absolute", top: 16, right: 16 }}
        onSelect={this.handleSelectChange}
        // className="mengcao-select-multiple"
      >
        {[
          {
            name: "统计气泡",
            type: "statistics",
          },
          {
            name: "坐标气泡",
            type: "locus",
          },
        ].map((item, index) => (
          <Select.Option value={item.type} key={index}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  }

  /** 传入路由点击回调函数 */
  routeOnClickEvent = (key: any) => {
    // this.setState({ selected: key });
  }
  /** 地图地图点击事件 */
  mapClickLayer = () => {
    window.setTimeout(() => {
      if (!this.baseMapStatus) {
        this.baseMapStatus = true;
        return;
      }
      this.setState({ townCode: "" });
    }, 300);
  }
  /** 黄河流域西北五省地图 */
  bamengMapLayerClick = (properties: any, geometry: any) => {
    this.baseMapStatus = false;
    const { countyCode, name } = properties;
    this.setState({ townCode: countyCode });
  }
  /** 地图数据类型渲染类型选择 */
  headCardTypeSelect = (type: string) => {
    if (this.state.mapBaseType === type) {
      this.setState({
        mapBaseType: "",
        northwestBaseList: [],
        markerDetailData: null,
      });
      return;
    }
    this.resetState();
    this.setState({ mapBaseType: type }, () => {
      this.getNorthwestBaseList(`baseType_${type}`);
    });
  }

  /** 12.28版本 地图数据类型渲染类型选择 */
  headCardTypeSelect2 = (type: string) => {
    if (this.state.mapBaseType === type) {
      this.handleMapMarkerTypeChange("statistics");
      this.setState(
        {
          mapBaseType: "",
          district: "all",
          parkType: "all",
          category: "all",
          baseType: "all",
        },
        () => {
          this.getRegionSummary();
          this.getPlantAll();
        },
      );
      return;
    }
    // this.resetState();
    this.handleMapMarkerTypeChange("locus");
    if (type === "district") {
      this.setState(
        { type: 2, district: "all", parkType: "", category: "", baseType: "" },
        () => this.getIntegratedData(),
      );
    }
    if (type === "park") {
      this.setState({
        type: 2,
        parkType: "all",
        district: "",
        category: "",
        baseType: "",
      });
    }
    if (type === "base") {
      this.setState({
        type: 2,
        parkType: "",
        district: "",
        category: "",
        baseType: "all",
      });
    }
    this.setState({ mapBaseType: type }, () => {
      this.getRegionSummary();
      this.getPlantAll();
    });
  }

  /** 地图数据类型渲染图层选择 */
  renderFillMarkerLayer = () => {
    const { northwestBaseList, markerParkBaseData } = this.state;
    return (
      <>
        {northwestBaseList.map((item, index) => (
          <HuangHeHightFillMapLayer key={index} geojsonType={item.geojson} />
        ))}
        {northwestBaseList.map((item, index) => (
          <MarkerIcom
            coordinates={item.center}
            key={index}
            colorPalette={colorPalette}
          >
            <div className="maker-box">
              <div
                className={`icon-box`}
                onClick={this.markerParkBaseClick.bind(this, item)}
              />
            </div>
          </MarkerIcom>
        ))}
        {markerParkBaseData && (
          <MarkerIcom
            coordinates={markerParkBaseData.center}
            colorPalette={colorPalette}
          >
            <div className="type-marker">
              {this.markerParkBaseDom(markerParkBaseData)}
              <div className="icon-marker" />
            </div>
          </MarkerIcom>
        )}
      </>
    );
  }
  /** 基地名称搜索 */
  baseNameSearch = (value: string) => {};
  /** 筛选指定类型marker dom列表 */
  renderTypeMarkerIcon = () => {
    const { markerDetailData, northwestPointInfo } = this.state;
    return (
      <>
        {northwestPointInfo.map((item, index) => (
          <MarkerIcom
            coordinates={item.center}
            key={index}
            colorPalette={colorPalette}
          >
            <div className="maker-box">
              <div
                className={`icon-box`}
                onClick={this.markerIconClick.bind(this, item)}
              />
            </div>
          </MarkerIcom>
        ))}
        {markerDetailData && (
          <MarkerIcom
            coordinates={markerDetailData.center}
            colorPalette={colorPalette}
          >
            <div className="type-marker">
              {this.typeMarkerIconDom(markerDetailData)}
              <div className="icon-marker" />
            </div>
          </MarkerIcom>
        )}
      </>
    );
  }
  /** 返回接口列表 */
  renderTreeNodes = (data: any) => {
    return data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.key}
            key={item.value}
            dataRef={item}
            icon={false}
            checkable={false}
            selectable={false}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.value} title={item.key} isLeaf icon={false} />;
    });
  }
  /** 选择过滤类型事件，定位点 */
  onSelectPointCheck = (selectedKeys: any) => {
    this.resetState();
    this.setState({ pointKeys: selectedKeys });
    this.getNorthwestPointInfo(selectedKeys[0]);
  }
  /** 选择过滤类型事件 区域面渲染 */
  onSelectFillCheck = (selectedKeys: any) => {
    this.resetState();
    this.setState({ fillKeys: selectedKeys });
    this.getNorthwestBaseList(selectedKeys[0]);
  }
  /** 左侧悬框展示状态事件 */
  leftVisiableClick = () => {
    this.setState({ leftVisiable: !this.state.leftVisiable });
  }
  /** 右侧悬框展示状态事件 */
  rightVisiableClick = () => {
    this.setState({ rightVisiable: !this.state.rightVisiable });
  }
  /** 地图标记点点击事件 */
  markerIconClick = (data: NorthwestRegionPointInfo) => {
    this.setState({
      markerDetailData: data,
      lonLatCenter: data.center,
      zoomNum: 14,
    });
  }
  /** 生成类型图标实例dom */
  typeMarkerIconDom = (data: NorthwestRegionPointInfo) => {
    return (
      <div className="prise-info-box">
        <div className="ls-title">
          <div className="lst-left">
            <span className="lstl-icon" />
            <span className="lstl-value">{data.planttype || "---"}</span>
          </div>
          <span className="lst-right" onClick={this.typeMarkerDetailClick}>
            <Icon>plus</Icon>
          </span>
        </div>
        <div className="bottom-line" />
        <div className="pib-border">
          <div className="title-tool">
            <span className="tool-name">名称：</span>
            <span className="tool-value">{data.plantname}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">科、属：</span>
            <span className="tool-value">{`${data.family || "---"} 、${
              data.category
            }`}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">采集编号：</span>
            <span className="tool-value">{data.num || "---"}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">采集地：</span>
            <span className="tool-value">{data.place || "---"}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">地形：</span>
            <span className="tool-value">{data.terrain || "---"}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">坡向：</span>
            <span className="tool-value">{data.exposure || "---"}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">土壤质地：</span>
            <span className="tool-value">{data.soilTexture || "---"}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">描述：</span>
            <span className="tool-value">{data.describe || "---"}</span>
          </div>
        </div>
      </div>
    );
  }
  /** 土地类型点击清除事件 */
  typeMarkerDetailClick = () => {
    this.setState({
      markerDetailData: null,
      lonLatCenter: [102.1, 37.485],
      zoomNum: 5.2,
    });
  }
  /** 一区多园N基地图标点击事件 */
  markerParkBaseClick = (data: NorthwestRegionParkBaseQuery) => {
    this.setState({
      markerParkBaseData: data,
      lonLatCenter: data.center,
      zoomNum: 14,
      rightVisiable: true,
    });
    this.getNorthwestPlantingArea(data.param);
  }
  /** 一区多园N基地图标图标实例dom */
  markerParkBaseDom = (data: NorthwestRegionParkBaseQuery) => {
    return (
      <div className="prise-info-box">
        <div className="ls-title">
          <div className="lst-left">
            <span className="lstl-icon" />
            <span className="lstl-value">{data.baseName || "---"}</span>
          </div>
          <span className="lst-right" onClick={this.markerParkBaseCancelClick}>
            <Icon>plus</Icon>
          </span>
        </div>
        <div className="bottom-line" />
        <div className="pib-border">
          <div className="title-tool">
            <span className="tool-name">公司名称：</span>
            <span className="tool-value">{data.company}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">省、市：</span>
            <span className="tool-value">{`${data.provinceName || "---"} 、${
              data.cityName
            }`}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">类型描述：</span>
            <span className="tool-value">{data.type || "---"}</span>
          </div>
          <div className="title-tool">
            <span className="tool-name">公司简介：</span>
            <span className="tool-value">{data.info || "---"}</span>
          </div>
          {data.baseName === "研究院小麦基地" && (
            <div className="title-tool">
              <span className="tool-name">基地现状：</span>
              <span className="tool-value">
                <a
                  style={{
                    color: "yellow",
                    fontWeight: 500,
                    textDecoration: "underline",
                  }}
                  target="_blank"
                  href="http://tfht.mengcao.com/tracing-source/f0d55517fa4f414fa566450b5eb7d980"
                >
                  点击这里查看基地现状
                </a>
              </span>
            </div>
          )}
          {data.image && (
            <div className="info-img">
              <img src={data.image} alt="" className="img-url" />
            </div>
          )}
        </div>
      </div>
    );
  }
  /** 一区多园N基地图标点击清除事件 */
  markerParkBaseCancelClick = () => {
    this.setState({
      markerParkBaseData: null,
      lonLatCenter: [102.1, 37.485],
      zoomNum: 5.2,
      rightVisiable: false,
    });
  }
  /** ai 决策面积事件跳转 */
  aiDecisionClick = () => {
    this.props.history.push("/germplas-nav/breeding/overview");
  }
  // tslint:disable-next-line:max-file-line-count
}

// @ts-ignore
export default scalePage(1920, 1080)(GermplasmResource);
