import React from "react";
import { Tabs, Avatar } from "antd";
import { SourceInfoWrap, Card } from "./style";
import { scalePage } from "../../decorator/scale-page";
import { CropCategory, BaseInfo, RegionTree, ResourctFindOne } from "../../models/germplasm-database";
import { TestReport } from "../../models/germplasm-test-report";
import { BreedingReport } from "../../models/molecular-breeding-report";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import GermplasmTestReportProvider from "../../network/providers/germplasm-test-report.provider";
import MolecularBreedingProvider from "../../network/providers/molecular-breeding-report.provider";
import { TESTREPORTLIST, BREEDINGREPORTLIST } from "./const";
import { createGlobalStyle } from "styled-components";
import { cloneDeep, get, omit } from "lodash";
import { TopCategory} from "./top-category";
import { GenerateCategory } from "./generate-category";
import { RouteComponentProps, withRouter } from "react-router-dom";

const { TabPane } = Tabs;

const GloabalStyle = createGlobalStyle`
.source-info{
  .ant-form-item label{
    color: rgb(0,22,23, 0.45);
  }
  .ant-input[disabled] {
    /* border: none; */
    color: rgb(0,22,23, 0.7);
    /* background: transparent; */
  }
  
}
`;

type P = RouteComponentProps & {
  /** 种质编号 */
  accessionNumber: string;
};

interface S {
   /** 获取列表1 */
   list1?: BaseInfo[];
   /** 获取列表2 */
   list2: BaseInfo[];
   /** 获取列表3 */
   list3: BaseInfo[];
   /** 是否为loading */
   loading?: boolean;
   /** 种质编号 */
   accessionNumber: string;
   /** 行政区划数 */
   regionTree?: RegionTree[];
   /** 获取单个种质资源信息 */
   resourctFindOne?: ResourctFindOne;
}

/**
 * 种质信息
 *
 * @export
 * @class SourceInfo
 * @extends {React.PureComponent}
 */
class SourceInfo extends React.PureComponent<P, S> {
  constructor(props: P) {
      super(props);
      const { accessionNumber, match = {} } = this.props;
      this.state = {
        list2: TESTREPORTLIST,
        list3: BREEDINGREPORTLIST,
        accessionNumber: accessionNumber || get(match, "params.sourceId", ""),
      };
      if (!window.sessionStorage.getItem("token")) {
        const tempSearch = new URLSearchParams(window.location.search);
        const token = tempSearch.get("token");
        window.sessionStorage.setItem("token", token || "");
      }
  }
  

  componentDidMount() {
    console.log(1111, this.props)
    this.getResourctFindOne();
    this.getOneResourceTestReport();
    this.getOneResourceBredingReport();
  }

  /** 获取单个种质资源信息 */
  getResourctFindOne = async () => {
    const { accessionNumber } = this.state;
    this.setState({ loading: true });
    const resourctFindOne = await GermplasmDatabaseProvider.getResourctFindOne(accessionNumber);
    this.setState({ resourctFindOne, list1: resourctFindOne.information, loading: false });
  }

  /** 获取单个种质 测试报告数据 */
  getOneResourceTestReport = async () => {
    const { accessionNumber } = this.state
    const { list2 } = this.state;
    const tempData = await GermplasmTestReportProvider.getTestReport(accessionNumber);
    const templist2 = tempData && tempData.map((item, ind) => {
        const templist = cloneDeep(list2[0].list);
        Object.keys(item).forEach((i) => {
          templist.map((elm) => {
            if(elm.eName === i) {
              elm.value = item[i as keyof TestReport];
            }
          })
        })
        return {
          name:  `测试报告${ind}`,
          list: templist,
        }
    })
    this.setState({ list2: templist2 })
  }

  /** 获取单个种质 育种报告数据 */
  getOneResourceBredingReport = async () => {
    const { accessionNumber } = this.state
    const tempData = await MolecularBreedingProvider.getBreedingReport(accessionNumber);
    const { list3 } = this.state;
    const templist3 = tempData && tempData.map((item, ind) => {
      const templist = cloneDeep(list3[0].list);
      Object.keys(item).forEach((i) => {
        templist.map((elm) => {
          if(elm.eName === i) {
            elm.value = item[i as keyof BreedingReport];
          }
        })
        })
        return {
          name:  `育种报告${ind}`,
          list: templist,
        }
    })
    this.setState({ list3: templist3 })
  }

  render() {
    return (
      <SourceInfoWrap className="source-info">
        <Tabs
          defaultActiveKey="1"
          size="small"
          tabBarGutter={0}
          tabBarStyle={{ width: "100%" }}
        >
          <TabPane tab="种质基本表" key="1" className="panel">
            {this.renderTopCategory()}
            {this.renderGenerate1()}
          </TabPane>
          <TabPane tab="种质测试报告" key="2">
            {this.renderGenerate2()}
          </TabPane>
          <TabPane tab="分子育种报告" key="3">
            {this.renderGenerate3()}
          </TabPane>
        </Tabs>
        <GloabalStyle/>
      </SourceInfoWrap>
    );
  }

  renderTopCategory = () => {
    const { resourctFindOne } = this.state;
    return (
      <>
        {resourctFindOne && <TopCategory topCategory={omit(resourctFindOne, "information")} />}
      </>
    );
  }

  /** render form 1 */
  renderGenerate1 = () => {
    const { list1 } = this.state;
    if (!list1) {
      return null;
    }
    return (
      <>
        {list1 && list1.map((item) => {
          return (
            <GenerateCategory
              data={item}
              key={item.name}
              title={item.name}
              colNum={24}
            />
          );
        })}
      </>
    );
  }

  /** render form 1 */
  renderGenerate2 = () => {
    const { list2 } = this.state;
    if (!list2) {
      return null;
    }
    return (
      <>
        {list2 && list2.map((item, ind) => {
          return (
            <GenerateCategory
              data={item}
              key={`测试报告${ind}`}
              title={"报告明细"}
              colNum={24}
            />
          );
        })}
      </>
    );
  }

  /** render form 3 */
  renderGenerate3 = () => {
    const { list3 } = this.state;
    if (!list3) {
      return null;
    }
    return (
      <>
        {list3 && list3.map((item, ind) => {
          return (
            <GenerateCategory
              data={item}
              key={`${ind}`}
              title={"报告明细"}
              colNum={24}
            />
          );
        })}
      </>
    );
  }

}

// export default withRouter(scalePage<P>(375)(SourceInfo));
export default withRouter(SourceInfo);

