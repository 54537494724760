// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Button, Cascader, Form, message, Modal } from "antd";
import { PageBody } from "./styled";
import { Card1 as Card, ITable, RadarChart } from "@gago/frame";

import { HeaderBox } from "../../containers/header-box";
import { Card1 } from "../../containers/card1";
import { LeftModule1 } from "./left-module1";
import { LeftModule2 } from "./left-module2";

import blue from "./img/blue.png";
import green from "./img/green.png";
import yellow from "./img/yellow.png";
import { chartStyle3 as chartStyle, colorPalette } from "../../color-palette";
import { getDataS1N1, getDataS2N1 } from "../../utils/example-data";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { CropPercentChart1 } from "@gago/lib/components/crop-percent-chart-1";
import { AddOverlay } from "../../containers/add-overlay";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import ModalForm from "@gago/lib/components/template-modal-form";

// tslint:disable-next-line: ter-max-len
import {
  importResource,
  CropCategory,
  RegionSummary,
  ResourceList,
  ResourceSummary,
  ResourceTrend,
  CategorySummary,
  Upload,
  ResourceListRequest,
  ResourctFindOne,
  RegionTree,
  TemplateRequest,
} from "../../models/germplasm-database";
import { cloneDeep, round, sumBy } from "lodash";
import { RankingList } from "@gago/lib/components/ranking-list";
import { PaginationConfig } from "antd/lib/table";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment";
const noop = () => {};
const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", ""],
  data: [],
  suffix: ["", "", "", "份"],
  prefix: ["", "", "", ""],
};

const dataS1N1: ITable<1, 1> = {
  title: "",
  subtitle: "总种类",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", ""],
  data: [],
  suffix: ["", "", "类"],
  prefix: ["", "", ""],
};

interface S {
  /** 对话框是否可见 */
  visible: boolean;
  /** 种质资源库汇总 */
  resourceSummary?: ResourceSummary;
  /** 种质资源入库趋势 */
  resourceTrend?: ResourceTrend[];
  /** 种质类型数量统计 */
  categorySummary?: CategorySummary[];
  /** 黄河流域各省份入库种质资源统计 */
  regionSummary?: RegionSummary[];
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 种质数据列表 */
  resourceList?: ResourceList;
  /** 顶部数据搜索 */
  topSearch?: any;
  /** 单个种质数据 */
  resourctFindOne?: ResourctFindOne;
  /** 当前页数 */
  pagination?: PaginationConfig;
  /** 导入导出弹框是否可见 */
  isShow: boolean;
  /** 上传文件的url地址 */
  uploadUrl: string;
  /** 导入导出下载模板请求所需参数 */
  requestKeys: string[];
  /** 选择的数据行数 */
  selectRowKeysCopy?: string[];
  /** openType ---> 下载模板：download 导入：import 导出：exoprt */
  openType: string;
  /** 手动上传url */
  handleUploadUrl: string;
  /** 顶部卡片类型 */
  cardType?: number;
  /** 顶部搜索表单 */
  topForm?: FormComponentProps["form"];
  /** 批量删除的ids */
  ids: number[];
}

/**
 * 种质资源数据库
 * @author maoshengtao
 */
export class GermplasmDatabase extends React.PureComponent<{}, S> {
  state: S = {
    visible: false,
    topSearch: {},
    pagination: {},
    isShow: false,
    requestKeys: ["oneLevelId", "twoLevelId"],
    uploadUrl: "",
    openType: "download",
    handleUploadUrl: "",
    selectRowKeysCopy: [],
    ids: [],
  };

  componentDidMount() {
    this.getCropCategory();
    this.getStorageYears();
    this.getResourceSummary();
    this.getResourceTrend();
    this.getCategorySummary();
    this.getRegionSummary();
  }

  /** top search */
  onTopSearch = (topSearch: any) => {
    /** clear serach */
    this.handleCardClick(0);
    /* get list */
    const { pagination } = this.state;
    this.setState(
      {
        topSearch,
        pagination: {
          ...pagination,
          current: 1,
        },
      },
      () => {
        this.getResourceList({
          oneLevel: topSearch.作物分类,
          twoLevel: topSearch.二类名称,
          year: topSearch.入库年份,
          accessionNumber: topSearch.种质编号,
          genebankNumber: topSearch.天赋河套种质库编号,
          accessionName: topSearch.种质名称,
          departmentName: topSearch.选育单位,
          isTs: topSearch.是否为区域特色种质,
          size: 10,
          // page: pagination && pagination.current || 1,
        });
      },
    );
  }

  /** get 作物分类 */
  getCropCategory = async () => {
    const cropCategory = await GermplasmDatabaseProvider.getCropCategory();
    /** 第一次加载列表项 */
    if (cropCategory && cropCategory.length > 0) {
      this.setState({
        topSearch: {
          oneLevel: cropCategory[0].name,
          twoLevel:
            cropCategory[0].children && cropCategory[0].children[0].name,
        },
      });
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    this.setState({ cropCategory });
  }

  /** get 入库年份 */
  getStorageYears = async () => {
    const storageYears = await GermplasmDatabaseProvider.getStorageYears();
    this.setState({ storageYears });
  }

  /** get 种质数据列表 */
  getResourceList = async (req: ResourceListRequest) => {
    const params: ResourceListRequest = {
      ...req,
      oneLevel: req.oneLevel === "全部" ? undefined : req.oneLevel,
      twoLevel: req.twoLevel === "全部" ? undefined : req.twoLevel,
    };
    GermplasmDatabaseProvider.getResourceList(params)
      .then((resourceList) => {
        this.setState({ resourceList });
      })
      .catch((err) => {
        message.error("获取列表失败");
      });
  }

  /** get 种质资源库汇总 */
  getResourceSummary = async () => {
    const resourceSummary = await GermplasmDatabaseProvider.getResourceSummary();
    this.setState({ resourceSummary });
  }

  /** get 种质资源入库趋势 */
  getResourceTrend = async () => {
    const resourceTrend = await GermplasmDatabaseProvider.getResourceTrend();
    this.setState({ resourceTrend });
  }

  /** get 种质类型数量统计 */
  getCategorySummary = async () => {
    const categorySummary = await GermplasmDatabaseProvider.getCategorySummary();
    this.setState({ categorySummary });
  }

  /** get 黄河流域各省份入库种质资源统计 */
  getRegionSummary = async () => {
    const regionSummary = await GermplasmDatabaseProvider.getRegionSummary();
    this.setState({ regionSummary });
  }

  /** 获取单个种质资源信息 */
  getResourctFindOne = async (accessionNumber: string) => {
    const resourctFindOne = await GermplasmDatabaseProvider.getResourctFindOne(
      accessionNumber,
    );
    this.setState({ resourctFindOne });
  }

  /** 删除种质资源数据 */
  resourceDel = async (id: string) => {
    GermplasmDatabaseProvider.resourceDel(id)
      .then((data) => {
        if (data && data.code === 200) {
          message.success("删除成功");
          const { topSearch, pagination } = this.state;
          /** get list  */
          this.getResourceList({
            oneLevel: topSearch.作物分类,
            twoLevel: topSearch.二类名称,
            year: topSearch.入库年份,
            accessionNumber: topSearch.种质编号,
            genebankNumber: topSearch.天赋河套种质库编号,
            accessionName: topSearch.种质名称,
            departmentName: topSearch.选育单位,
            isTs: topSearch.是否为区域特色种质,
            size: 10,
            page: pagination?.current || 1,
          });
          /** get summary */
          this.getResourceSummary();
        } else {
          message.error("删除失败");
        }
      })
      .catch((err) => {
        message.error("删除失败");
      });
  }

  /** 处理分页编号请求 */
  handlePagination = (pagination: PaginationConfig) => {
    if (pagination && pagination.current) {
      const { topSearch, cardType } = this.state;
      this.setState({ pagination });
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.入库年份,
        accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        departmentName: topSearch.选育单位,
        isTs: cardType === 3 ? 1 : topSearch.是否为区域特色种质,
        size: 10,
        page: pagination.current || 1,
        isExistGenebankNumber: cardType === 2 ? 1 : undefined,
      });
    }
  }

  /** 获取表单的selectRowKeys */
  handleSelectRowKeys = (params: string[], record: any) => {
    const data: number[] = [];
    record.map((item: any) => {
      data.push(item.id);
    });
    this.setState({
      selectRowKeysCopy: params,
      ids: data,
    });
  }

  /** 处理导入导出弹框是否开启 */
  handleModalShow = (type: string) => {
    this.setState({
      isShow: true,
      openType: type,
    });
  }

  /** 关闭导入导出弹框 */
  handleCancelModal = () => {
    this.setState({
      isShow: false,
      handleUploadUrl: "",
    });
  }

  /** 处理导出操作 */
  handleExport = () => {
    const { selectRowKeysCopy, resourceList } = this.state;
    const ids: string[] = [];
    console.log(selectRowKeysCopy, "//---");
    const token = window.sessionStorage.getItem("token") as string;
    if (selectRowKeysCopy && selectRowKeysCopy.length === 0) {
      return message.info("您还没有选择导出的数据！");
    }
    {
      selectRowKeysCopy &&
        selectRowKeysCopy.forEach((item: string) => {
          if (
            resourceList &&
            resourceList.list &&
            resourceList.list.length > 0
          ) {
            const ind: any = resourceList.list.findIndex(
              (resource) => resource.accessionNumber === item,
            );
            ids.push(resourceList.list[ind].id);
          }
        });
      const idString: string = ids.join(",");
      // window.location.href =`http://mc-bameng.fm-dev.gagogroup.cn:30549/api/v1/plant/resource/export?ids=${idString}&token=${token}`
      GermplasmDatabaseProvider.download(
        `/plant/resource/export?ids=${idString}`,
      );
    }
  }

  /** 处理导入确定 */
  handleImport = async (params: importResource) => {
    await GermplasmDatabaseProvider.importGermplasmDatabase(params)
      .then((data) => {
        if (data.code === 200) {
          message.success("导入成功，请稍后刷新查看最新数据");
        }
      })
      .catch((err) => {
        // message.error("操作失败");
      });

    this.handleCancelModal();
  }

  /** 下载模板/导入/导出 点击确定的函数 */
  handleOk = (params: any) => {
    const { openType } = this.state;
    if (openType === "Download") {
      return this.handleDownload(params);
    }
    if (openType === "Import") {
      return this.handleImport(params);
    }
  }

  render() {
    const {
      resourceList,
      cropCategory,
      storageYears,
      uploadUrl,
      requestKeys,
      pagination,
    } = this.state;

    return (
      <HeaderBox names={["种质资源数据库"]}>
        <PageBody>
          <div className="page-left">
            <div className="top-card-list">{this.renderTopCardList()}</div>
            <div className="page-left-bottom">
              <div className="search-module">
                <LeftModule1
                  onTopSearch={this.onTopSearch}
                  cropCategory={cropCategory}
                  storageYears={storageYears}
                  onLoad={this.topFormLoad}
                />
              </div>
              {this.renderButtonList()}
              <div className="table-module">
                <LeftModule2
                  resourceList={resourceList}
                  resourceDel={this.resourceDel}
                  handleSelectRowKeys={this.handleSelectRowKeys}
                  handlePagination={this.handlePagination}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
          <div className="page-right">
            {this.renderRightCard1()}
            {this.renderRightCard2()}
            {this.renderRightCard3()}
          </div>
          {this.renderAddOverlay()}
          <ModalForm
            visible={this.state.isShow}
            cropCategory={cropCategory}
            uploadUrl={uploadUrl}
            requestKeys={requestKeys}
            onOk={this.handleOk}
            onCancel={this.handleCancelModal}
            isUseCropCate={false}
            handleUploadUrl={this.state.handleUploadUrl}
          />
        </PageBody>
      </HeaderBox>
    );
  }

  /** 打开模板下载弹窗 */
  handleDownload = async (params: TemplateRequest) => {
    // const data = await GermplasmDatabaseProvider.getTemplate(params);
    // window.location.href = data;
    GermplasmDatabaseProvider.download(
      `/plant/resource/template?oneLevelId=${params.oneLevelId}&twoLevelId=${params.twoLevelId}`,
    );

    this.handleCancelModal();
  }
  /** handle overlay open */
  handleAddClick = () => {
    this.setState({ visible: true });
  }

  /** handle overlay close*/
  handleAddCancle = (isAdd?: string | number) => {
    this.setState({ visible: false });
    if (isAdd) {
      const { topSearch, pagination } = this.state;
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.入库年份,
        accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        departmentName: topSearch.选育单位,
        isTs: topSearch.是否为区域特色种质,
        size: 10,
        page: pagination?.current || 1,
      });
      this.getResourceSummary();
    }
  }

  renderAddOverlay = () => {
    const { visible, cropCategory, storageYears } = this.state;
    return (
      <AddOverlay
        key={`${visible}`}
        visible={visible}
        handleCancel={this.handleAddCancle}
        title={"新增种质资源"}
        cropCategory={cropCategory}
        storageYears={storageYears}
      />
    );
  }

  renderRightCard1 = () => {
    const { resourceTrend } = this.state;
    if (!resourceTrend) {
      return null;
    }
    const chartData = cloneDeep(dataS2N1);
    chartData.data = resourceTrend.map((item) => {
      return [
        `${item.year}`,
        "种质入库趋势",
        `${item.year}`,
        round(item.count, 2),
      ];
    });
    chartData.data = chartData.data.sort(
      (a, b) => moment(a[2]).unix() - moment(b[2]).unix(),
    );
    return (
      <Card
        title={
          ((
            <>
              <span
                style={{
                  display: "inline-block",
                  width: 4,
                  height: 14,
                  background: "#00adad",
                  marginRight: 6,
                }}
              />
              <span>{"种质入库趋势"}</span>
            </>
          ) as unknown) as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
          <AxisChart
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#f78011", ...colorPalette.subColor],
            }}
            basicConfigOption={{
              seriesType: "line",
              lineSmooth: true,
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard2 = () => {
    const { categorySummary } = this.state;
    if (!categorySummary) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = categorySummary.map((item) => {
      return [`${item.category}`, `${item.category}`, round(item.count, 2)];
    });
    chartData.title = `${sumBy(chartData.data, (item) => item[2])}`;
    return (
      <Card
        title={
          ((
            <>
              <span
                style={{
                  display: "inline-block",
                  width: 4,
                  height: 14,
                  background: "#00adad",
                  marginRight: 6,
                }}
              />
              <span>{"种质类型数量统计"}</span>
            </>
          ) as unknown) as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ minHeight: 200, display: "flex", alignItems: "center" }}>
          <CropPercentChart1
            theme="horizontal"
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: [
                "#2970ff",
                "#00aaff",
                "#70e2ff",
                "#ffb300",
                "#ff5275",
                ...colorPalette.subColor,
              ],
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard3 = () => {
    const { regionSummary } = this.state;
    if (!regionSummary) {
      return null;
    }
    const chartData = cloneDeep(dataS2N1);
    chartData.data = regionSummary.map((item) => {
      return [
        `${item.region}`,
        "黄河流域入库种质资源统计",
        `${item.region}`,
        round(item.count, 2),
      ];
    });
    return (
      <Card
        title={
          ((
            <>
              <span
                style={{
                  display: "inline-block",
                  width: 4,
                  height: 14,
                  background: "#00adad",
                  marginRight: 6,
                }}
              />
              <span>{"黄河流域各省入库种质资源统计"}</span>
            </>
          ) as unknown) as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
          <AxisChart
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#1eb0bb", ...colorPalette.subColor],
            }}
          />
        </div>
      </Card>
    );
  }

  renderButtonList = () => {
    const { ids } = this.state;
    return (
      <div className="button-list">
        <div className="button-list-left">
          <Button style={{ margin: "0px 10px" }} onClick={this.handleAddClick}>
            新增
          </Button>
          <Button
            type="primary"
            onClick={() => this.handleModalShow("Download")}
          >
            下载导入模板
          </Button>
          <Button type="primary" disabled={ids.length === 0} style={{ margin: "0px 10px" }} onClick={this.resourceDelIds}>批量删除</Button>
        </div>
        <div className="button-list-right">
          <Button
            icon="download"
            onClick={() => {
              this.handleModalShow("Import");
              this.setState({ handleUploadUrl: "/resource/upload" });
            }}
          >
            导入
          </Button>
          <Button
            icon="export"
            onClick={this.handleExport}
            style={{ margin: "0px 10px" }}
          >
            导出
          </Button>
        </div>
      </div>
    );
  }

  /** 批量删除数据 */
  resourceDelIds = () => {
    const { ids } = this.state;
    console.log(ids, "***");
    GermplasmDatabaseProvider.resourceDelIds(ids.join(","))
      .then((data) => {
        if (data && data.code === 200) {
          message.success("删除成功");
          const { topSearch, pagination } = this.state;
          /** get list  */
          this.getResourceList({
            oneLevel: topSearch.作物分类,
            twoLevel: topSearch.二类名称,
            year: topSearch.入库年份,
            accessionNumber: topSearch.种质编号,
            genebankNumber: topSearch.天赋河套种质库编号,
            accessionName: topSearch.种质名称,
            departmentName: topSearch.选育单位,
            isTs: topSearch.是否为区域特色种质,
            size: 10,
            page: pagination?.current || 1,
          });
          /** get summary */
          this.getResourceSummary();
        } else {
          message.error("删除失败");
        }
      })
      .catch((err) => {
        message.error("删除失败");
      });
  }

  /** top form load */
  topFormLoad = (form: FormComponentProps["form"]) => {
    this.setState({ topForm: form });
  }

  /** handle clear top search */
  handleClearTopSearch = () => {
    const { topSearch, topForm } = this.state;
    this.setState({ topSearch: {} });
    topForm && topForm.resetFields();
  }

  /** handle top Card click */
  // tslint:disable-next-line:cyclomatic-complexity
  handleCardClick = (n: number) => {
    if (n === this.state.cardType) {
      return;
    }

    const { cropCategory } = this.state;
    if (n === 1 && cropCategory) {
      this.handleClearTopSearch();
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    if (n === 2 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据天赋河套编号是否为空筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        isExistGenebankNumber: 1,
      });
    }

    if (n === 3 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据是否为区域特色筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        isTs: 1,
      });
    }

    this.setState({ cardType: n });
  }

  /** render 顶部卡片列表 */
  renderTopCardList = () => {
    const { resourceSummary, cardType } = this.state;
    if (!resourceSummary) {
      return null;
    }
    return (
      <>
        {[
          {
            title: (resourceSummary && resourceSummary.germplasmCount) || 0,
            suffix: "(条)",
            description: "入库编目种质资源数据",
            img: green,
            type: 1,
          },
          {
            title: (resourceSummary && resourceSummary.inventoryCount) || 0,
            suffix: "(份)",
            description: "入库保存种质资源数量",
            img: yellow,
            type: 2,
          },
          {
            title: (resourceSummary && resourceSummary.featureCount) || 0,
            suffix: "(种份)",
            description: "区域特色种质资源数量",
            img: blue,
            type: 3,
          },
        ].map((item) => {
          return (
            <Card1
              active={cardType === item.type}
              key={item.title}
              title={item.title}
              suffix={item.suffix}
              description={item.description}
              img={item.img}
              onClick={this.handleCardClick.bind(this, item.type)}
            />
          );
        })}
      </>
    );
  }
}
