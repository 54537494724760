import React from "react";
import moment from 'moment';
import { HeaderBox } from "../../containers/header-box";
import { PageBody, CardContentBorder, WrapForm } from "./styled";
import { colorPalette, chartStyle, pieChartColor, } from "../../color-palette";

import Map from "./map";
import { EllipseRadioSelect, LocationSelect } from '@gago/lib/components';
import { GenerateCategory } from '../../containers/detail-overlay/generate-category';
import { Switch } from '../../src-anxi/components/switch';
import { Card1 } from '../../src-anxi/components/card1';
import { Slider } from '../../src-anxi/components/slider';
import { colorPalette1 } from '../../src-anxi/color-palette'
import { 
  RegionTree,
  SamplePoint,
  LandReport,
  InfoItem
} from "../../models/land-evalute";
import LandEvaluateProvider from '../../network/providers/land-evaluate';
// import { Card1 as Card } from "@gago/frame";
import { ITable, SideBar4, Timeline7, Card1 as Card, RadarChart} from "@gago/frame";
import { noop, cloneDeep, round } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { Table, Select, Cascader, Rate, Form, Row, Col, Input} from 'antd';
import { SliderValue } from "antd/lib/slider";
import { tuple } from '../../utils'

import { CascaderOptionType} from 'antd/lib/cascader';
import { daysInfuture } from '../../utils/example-data';
import { mapDataList, mapElementList , dataS1N1, columns, maskConfig, SliderList, sliderList, ElementKey, elementMap, elementList } from './landEvaluate-const'


const { Option } = Select;

const cropTypes = tuple('all','sunflower','corn','wheat','other')
export type CropType = (typeof cropTypes)[number];

/** key对应的值 */
export type Range = {
  [Key in SliderList]: SliderValue;
};
interface P {
 
}

interface S {
  region:Region;
  // 作物类型
  crop:CropType;
  code:string;
  elementType:'organic_matter' | 'land_element';
  // 巴彦淖尔市地区列表
  
  areaList:RegionTree[];
  // 选择的区域
  selectedArea:string[];
  range:Range;
  // 施肥建议
  fertilize:string[];
  // 样点id
  sampleId:number;
  // 样点列表
  sampleList:SamplePoint[];
  landReport: LandReport;
  information:{name:string;list:InfoItem[]}[];
  element:string;
  bbox:[number,number,number,number]
}
const rangeList: Range = {
  cornTemperature: [0, 1],
  temperature: [0, 1],
  rain: [0, 1],
  river: [0, 1],
  traffic: [0, 1],
};
sliderList.map((sub: SliderList, index: number) => {
  rangeList[sub] = maskConfig[sub].sliderConfig.defaultValue;
});
export default class LandEvalute extends React.PureComponent<P, S> {
  
  state: S = {
    region: {
      code: '150800000',
      name: undefined,
      level: "city",
    },
    code:'150800000',
    crop:cropTypes[0],
    elementType:'organic_matter',
    // 巴彦淖尔市地区列表
    areaList:[],
    // 选择的地区
    selectedArea:['-1'],
    range:rangeList,
    fertilize:[],
    sampleId:1,
    sampleList:[],
    landReport:{
      allScore:0,
      ratelist:{
        bd:0,
        ph:0,
        som:0,
        an:0,
        ap:0,
        ak:0,
        texture:0
        // low:0,
        // high:0,
        // star:0
      },
      indicatorlist:[]
    },
    information:[],
    element:'ph',
    bbox:[104.94727433007114,39.68476362731326,113.00003951135619,42.98148907004972]
  }

  async componentDidMount () {
    this.getAreaList();
    await this.getSamplePoint();
    await this.initData();
    
  }
  /**获取巴彦淖尔行政区域 */
  getAreaList = async () => {
    const areaList = await LandEvaluateProvider.getRegionTree();
    const areaAll:RegionTree = {
      name:'全部',
      value: -1,
      id:-1,
      // children:[],
      code:'-1',
      parentName:'',
      parentCode:'',
      bbox:[0,0,0,0],
      xmax:0,
      xmin:0,
      ymin:0,
      ymax:0
    }
    areaList.forEach(item => {
      if (item.children && item.children.length !== 0) {
        item.children.unshift(areaAll)
        item.children.forEach(town => {
          town.children = undefined
        })
      }
    })
    areaList.unshift(areaAll)
    this.setState({areaList})
  } 
  /**获取施肥措施 */
  getFertilizeMesuare = async () => {
    const { sampleId, crop  } = this.state;
    const data = await LandEvaluateProvider.getFertilizeMesuare({crop,id:sampleId.toString()});
    this.setState({fertilize:data})
  } 
  changeSampleId = (id:number,county:string, town:string) => {
    console.log(id,'sampleId')
    const { areaList } = this.state;
    let area:RegionTree  | undefined= {} as RegionTree ;
    let areaCode:string[] = [];
    let list:any[] = []
    
    
     area =  areaList?.find(item => {
      if (item.name === county ) {

        const targetTown =   item?.children?.find(townItem => {
          return townItem.name === town
        })
        if (targetTown) {
          areaCode = [item.code, targetTown.code]
        } else {
          areaCode = [item.code,'-1']
        }
        return true
      } else {
        return false;
      }
    }) 
    
    if (!area) {
      areaCode = ['-1']
    }
    this.setState({sampleId:id, selectedArea:areaCode},()=> this.initData())
  }
  /** 获取样点信息 */
  getSamplePoint = async () => {
    const {  crop, code } = this.state;
    const sampleList = await LandEvaluateProvider.getSamplePoints({code:code as string,crop});
    const sampleId = sampleList && sampleList.length > 0 ? sampleList[0].id : 1
    this.setState({sampleList,sampleId})
  }

  getSampleDetail = async () => {
    const  { sampleId } = this.state;
    const  { information } = await LandEvaluateProvider.getSampleDetail(sampleId)
    this.setState({information})
  }
    /** 获取土壤评分 */
    getLandRate = async () => {
      const { region:{ code } , crop, sampleId } = this.state;
      const landReport = await LandEvaluateProvider.getLandRate({id:sampleId});
      this.setState({landReport})
    }
  
   /** 切换高亮的地区 */
   changeRegion = (region: Region) => {
      // this.setState({ region },() => {
      //   this.initData()
      // });
  }
  

  /**初始化数据 */
  initData = async () => {
    await this.getLandRate();
    await this.getFertilizeMesuare();
    await this.getSampleDetail();
     
  }  
  // 切换气象服务类型
  onChangeElementType = (value:string) => {
    this.setState({
      elementType:value as 'organic_matter' | 'land_element'
    },()=> {
      // this.initData();
    })
  }

  //  切换作物类型
  onChangeCropType = ( value:string) => {
    
    // this.setState({
    //   crop:value
    // }, () => {
    //   this.initData();
    // })
  }

  onChangeDisastertype = (value:string) => {
    // this.setState({disasterType:value})
  }

  // getDurationTime = (afterTime:number) => {
  //   const { year } = this.state;
  //   const endTime = year + afterTime - 1;
  //   const timeString = `${endTime}-12-31` ;
  //   return timeString;
  // }
  // 时间轴改变时间
  handleChangeDate = (date:string) => {

    // console.log(date,'cahngedate')
    // this.setState({date},() => this.initData())
  }
  /** slider组件拖动切换 */
  handleSliderChange = (index: number, value: SliderValue) => {
    const nextRange: Range = { ...this.state.range };
    nextRange[sliderList[index]] = value;
    this.setState({
      range: nextRange,
    });
  }
  /** 监听切换 */
  handleCropSwitch = (value: string, index: number) => {
    this.setState({
      crop:cropTypes[index]
    });
  }

  handleChangeArea = (value:string[]) => {
    // 因为要使地图高亮region.code并不等于真实选择的地区的code,所以要额外定义code
    console.log(value,'area')
    const { areaList} = this.state;
    // let { region:region1 } = this.state;
    let code = '', regionCode = '',bbox:[number,number,number,number] =[104.94727433007114,39.68476362731326,113.00003951135619,42.98148907004972];
    const len = value && value.length;
    let level = 'city' 
    if (len === 1 && value.includes('-1')) {
      code = '150800000';
      // level = 'county'
      regionCode = '150800000'
    } else if (len === 2 && value.includes('-1')) {
      console.log('value',value)
      code = value[0]
      regionCode = value[0]
      level = 'village'
    } else {
      code = value[1];
      level = 'village'
      regionCode = value[0]
    }
    const area = areaList.find(item => {
      if (item.code === code) {
        return true
      } else {
        return item.children?.find(town => 
          town.code === code
        )
      }
    })
    bbox = !!area ? area.bbox : bbox;
    let region1:Region = {
      code:regionCode,
      level:level as "city" | "county" | "village",
      name:area?.name || undefined
    }
    console.log(bbox,code,area,'123456')
    this.setState({region:region1,bbox,code, selectedArea:value},async ()=> {
      await this.getSamplePoint()
      // await this.initData()
    })
  }

  handleChangeCrop = (value:CropType) => {
    console.log(value,'crop')
    this.setState({crop:value},async()=> {
     await this.getSamplePoint()
    //  await this.initData()
    })
  }
  handleChangeElement = (value:string) => {
    console.log(value,'element')
    this.setState({element:value})
  }

  

  render () {
    // const { region,year, timeDate, date, disasterType, crop, startDate } = this.state;
    // console.log(date,'render')
    // const { sampleList } = this.state;
    const { crop, region, sampleList, range, sampleId, element, bbox } = this.state;
    return (
      <HeaderBox
        names={["土壤适应性评价"]}
        noPadding
      >
        <PageBody>
            <div className="left-container">
                <div className="left-top-container">
                  {/* <Card1
                    title="基于土壤成分的巴盟地区适宜性分布图"
                    colorPalette={colorPalette1}
                    style={{ margin:0,position:'relative',height:'100%'}}
                    rightComponent={(
                    <div>
                      <Switch
                        colorPalette={colorPalette1}
                        data={mapDataList}
                        onSwitch={this.handleCropSwitch}
                        style={{
                          marginRight: 15,
                        }}
                      />
                    </div>
                    )}
                  > */}
                    <div className="cardTitle">基于土壤成分的巴盟地区适宜性分布图</div>
                    {/* <CardContentBorder> */}
                      {/* {showMap ? <SuitabilityEvaluationMap crop={this.state.cropType}/> : null} */}
                      <Map
                        style={{height:'100%',position:'relative'}}
                        bbox = {bbox}
                        range={range}
                        sampleList={sampleList}
                        sampleId = { sampleId }
                        region = { region }
                        changeRegion = {this.changeRegion}
                        changeSample = {this.changeSampleId}
                        crop = { crop }
                        element = {element}
                      />
                      
                      
                    {/* </CardContentBorder> */}
                  {/* </Card1> */}
                  <div className="selectWrap">
                        {this.renderAreaSelect()}
                        {this.renderCropSelect()}
                        {this.renderElementSelect()}
                      </div>
                </div>
                <div className="left-bottom-container">
                    {this.renderFertilizeMesuare()}
                    
                </div>
            </div>
            
            <div className="right-container">
                {/* {this.renderSidebar()} */}
                {this.renderLandInfo()}


            </div>
            <div className="middle-container">
              <Card
              style={{ backgroundColor: '#fff', height: "100%", marginBottom: 0}}
                title={
                  <>
                  <span
                    style={{
                      display: "inline-block",
                      width: 4,
                      height: 14,
                      background: "#00adad",
                      marginRight: 6,
                    }}
                  />
                  <span>{"土壤报告"}</span>
                  </> as unknown as string
                }
                colorPalette={colorPalette}
              >
                {this.renderLandRate()}
              </Card>
            </div>
        </PageBody>
      </HeaderBox>
    );
  }
  // renderDisasterWarning = () => {
  //   const { disasterType } = this.state;
  //   return (
  //     <>
  //       <div className="weather-forecast">
  //         {diasterInfo.map(item => {
  //           return(
  //             <>
  //               <div className= {disasterType === item.type ? 'weather-forecast-item-active' : 'weather-forecast-item'}  onClick = {() => this.onChangeDisastertype(item.type)}>
  //                   <span className="forecast-item-content">
  //                     <img src={disasterType === item.type ? item.activeIcon: item.icon} alt="" className="forecast-item-icon"/>
  //                      {item.name}
  //                   </span>
  //               </div>
  //             </>
  //           )
  //         })}
  //       </div>
  //     </>
  //   )
  // }
  renderAreaSelect = () => {
    const { areaList } = this.state;
    if ( !areaList || areaList.length === 0) return null;
    return (
      <>
        <div className="areaSelect-wrap">
          <div className="areaSelectPrefix">地区：</div>
          <Cascader onChange={this.handleChangeArea} value={this.state.selectedArea} allowClear={false} fieldNames={{label:'name',value:'code',children:'children'}}  options={areaList as unknown as CascaderOptionType[]}/>
        </div>
      </>
    )
  }

  renderLandInfo = () => {
    const { information } = this.state;
    return (
      <>
          <Card
          style={{ backgroundColor: '#fff', overflowY: 'auto', height: "100%", marginBottom: 0,paddingRight:0}}
            title={
              <>
                <span
                  style={{
                    display: "flex",
                    width: 4,
                    height: 14,
                    background: "#00adad",
                    marginRight: 6,
                  }}
                  />
                  <span>{"采样点土壤数据明细"}</span>
                  </> as unknown as string
                }
                colorPalette={colorPalette}
              >
                
                {
                  !!information && information.map(info => {
                    return (
                          <div style={{height:'100%',overflowY:'auto',width: "calc(100% - 16px"}}>
                            <WrapForm>
                              <Row gutter={24}>
                                  {!!info.list && info.list.map(item => {
                                    return (
                                    <Col span={24}>
                                      <Form.Item label={item.name}>
                                          <Input value={item.value} disabled/>
                                      </Form.Item>
                                    </Col>

                                    )
                                  })}
                              </Row> 
                            </WrapForm>
                          </div>
                    )
                  })
                }
              
                
                
            </Card>
      </>
    )
  }

  // 土壤评分
  renderLandRate = () => {
    const { landReport:{ ratelist, indicatorlist, allScore} } = this.state;
    const { ...rest } = ratelist;
    const radarArr = Object.entries(rest);
    const chartData = cloneDeep(dataS1N1);
    chartData.data = radarArr.map((item) => {
      return [`${elementMap[item[0] as ElementKey] }`, `${elementMap[item[0] as ElementKey]}`, round(item[1], 2)];
    });
    const h = window.document.documentElement.offsetHeight - 545;

    return (
      <>
        <div className="rate-wrap">
          <div className="grade">地块综合评分：<span style={{color:'#00adad'}}>{allScore}分</span></div>
          <div className="rate">地块星级：<Rate disabled value={Math.round(allScore/7)}/></div>
        </div>

          <div style={{ height: "303px",position:'relative' }}>
          <RadarChart
            theme="light"
            colorPalette={colorPalette}
            chartData={chartData}
            
          />
        </div>

        
        
        <Table
          columns={columns}
          dataSource = {indicatorlist}
          scroll={{ x: "100%", y:`${h < 0 ? 100 : h}px` }}
          pagination={false}
          // onChange={handlePagination}
          // onRow = {(record:PlantListItem)=>{
          //   return {
          //     onClick:() =>  {
          //       if (isCoor([record.lon, record.lat])) {
          //         window._map && window._map.flyTo({ center: [record.lon, record.lat], zoom: 14 });
          //       }
          //     }
          //   }

          // }}
          />
      </>
    )
  }

  // renderRadarChart = () => {
  //   const chartData = cloneDeep(dataS1N1);
  //   chartData.data = provinceExperts.map((item) => {
  //     return [`${item.provinceName}`, `${item.provinceName}`, round(item.count, 2)];
  //   });
  //   return (
  //     <>
  //        <div style={{ height: 200 }}>
  //         <RadarChart
  //           theme="light"
  //           colorPalette={colorPalette}
  //           chartData={chartData}
  //         />
  //       </div>
  //     </>
  //   )
  // }

  renderTable = () => {
    return (
      <>
         <Table
          columns={columns}
          // dataSource = {data}
          scroll={{ x: "100%", y: '240px' }}
          // onChange={handlePagination}
          // onRow = {(record:PlantListItem)=>{
          //   return {
          //     onClick:() =>  {
          //       if (isCoor([record.lon, record.lat])) {
          //         window._map && window._map.flyTo({ center: [record.lon, record.lat], zoom: 14 });
          //       }
          //     }
          //   }

          // }}
          />
      </>
    )
  }
  
  renderElementSelect = () => {
    return (
      <>
        <div className="areaSelect-wrap">
          <div className="areaSelectPrefix">元素：</div>
          <Select
            value = {this.state.element}
            onChange= {this.handleChangeElement}
          >
            {elementList.map(item => {
              return (
                <Option key={item.label} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>
        </div>
      </>
    )
    
  }

  renderCropSelect = () => {
    return (
      <>
        <div className="areaSelect-wrap">
          <div className="areaSelectPrefix">作物：</div>
          <Select
            value={this.state.crop}
            onChange = {this.handleChangeCrop}
          >
            {mapDataList.map(item => {
              return (
                <Option key={item.label} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>
        </div>
      </>
    )
    
  }

  renderOrganicMatterSliders = () => {
    const { crop, range, elementType } = this.state;
    // const sliderList:string[] = ['']
    return sliderList.map((sub: SliderList, index: number) => {
      const currentMaskConfig = maskConfig[sub];
      const { sliderConfig: value, mapConfig } = currentMaskConfig;
      if (crop === "corn" && sub === "temperature") return null;
      if (crop !== "corn" && sub === "cornTemperature") return null;
      return (
        <Slider
          key={mapConfig.id}
          icon={value.icon}
          title={value.title}
          unit={value.unit}
          colorPalette={colorPalette1}
          onChange={this.handleSliderChange.bind(this, index)}
          config={{
            step: value.step ? value.step : 1,
            max: value.max ? value.max : 100,
            min: value.min ? value.min : 0,
            defaultValue: range[sub],
          }}
          hiddenBottomLine={index === 3 ? true : undefined}
        />
      );
    });
  }

  renderLandElementSliders = () => {

  }

  renderFertilizeMesuare = () => {
    const { fertilize } = this.state;
    return (
      <>
        <Card
            style={{backgroundColor:'#fff',overflowY:'auto',height:"100%",marginBottom:0}}
            title={
              <>
                <span
                  style={{
                    display: "inline-block",
                    width: 4,
                    height: 14,
                    background: "#00adad",
                    marginRight: 6,
                  }}
                  />
                  <span>{"施肥建议"}</span>
                  </> as unknown as string
                }
                colorPalette={colorPalette}
              >
                <div className="measureBox">{fertilize.map((item,index:number)=> {
                  return (
                    <>
                      <p>{index + 1}、{item}</p>
                    </>
                  )
                })}</div>
              </Card>
      </>
    )
  }
  /** render sider bar*/
  renderSidebar = () => {
    // const { forecastType, phenologicalList, weekForecast, agricultureWarning,tmpList, apcpList, sumtmpList, date } = this.state;
    return (
      <SideBar4
        className="breeding-distribute-side"
        
        colorPalette={colorPalette}
        sizeOnChange={noop}
        theme="light"
        style={{ width: '334px' }}
        title= {<EllipseRadioSelect colorPalette = {colorPalette} data={mapElementList} className="meteorologicalSelect" backSelectChange={this.onChangeElementType}/>}
      >
         {/* {this.renderOrganicMatterSliders()} */}
        {/* {this.renderFertilizeMesuare()} */}
      </SideBar4>
    );
  }

 }