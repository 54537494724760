
/**
 * 用户检查失败（token错误）的类
 * @author luyaxiang
 * @date 2019.9.20
 * @class AuthFailEmiter
 */
class AuthFailEmiter {
  /** 失败 */
  fail() {
  }
}
export default new AuthFailEmiter();
