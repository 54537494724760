import React, {Component} from "react";
import {observable, action, transaction} from "mobx";
import {observer} from "mobx-react";
import {
    Button,
    Table,
    Popconfirm,
    Input,
    Radio,
    Select,
    message,
    Drawer,
    Descriptions,
    Empty,
    Modal,
    Icon
} from "antd";
import {ColumnProps} from "antd/lib/table";

const {Option} = Select;
import {GermplasmDataModalComponent, GermplasmData} from "./germplasm-modal";
import styles from "../table.module.scss";
import {UploadFile} from "antd/lib/upload/interface";
import MolecularProvider from "../../../network/providers/molecular.provider";

const {Search} = Input;
const pagination = {size: "small", showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ["5", "10", "20"]};

interface S {
    /** 预览文件链接*/
    previewFile?: string;
    /** 文件 */
    filelist?: UploadFile<any>[];
    /** 更新文件预览 */
    updatePreviewFile?: UploadFile<any>;
    toolDataList?: GermplasmData[];
}


// tslint:disable:jsx-no-lambda
@observer
export class TableComponent extends Component<S> {
    state: S = {
        filelist: [],
        toolDataList: [],
    };
    @observable toolData: GermplasmData = {
        // @ts-ignore
        clmc: null,//样本名称
        // @ts-ignore
        cllx: null,//原始序列数据
        // @ts-ignore
        cjid: null,//质控序列数据
        // @ts-ignore
        ycnd: null,//描述
        // @ts-ignore
        ly: null,//分析
        // @ts-ignore
        remarks: null,//备注
    };
    @observable title = "";
    @observable visible = false;
    @observable visibleDetail = false;
    @observable confirmLoading = false;
    @observable isEdit = false;
    // tslint:disable no-unused-variable
    private columns: Array<ColumnProps<GermplasmData>> = [
        {
            title: "序号",
            key: "index",
            fixed: "left",
            width: 60,
            className: styles.tableColumn60,
            // @ts-ignore
            render: (text, record, index) => index + 1,
        },
        {
            title: "材料名称",
            key: "clmc",
            dataIndex: "clmc",
            width: 120,
            className: styles.tableColumn120,
        },
        {
            title: "材料类型",
            width: 100,
            key: "cllx",
            dataIndex: "cllx",
            className: styles.tableColumn120,
        },
        {
            title: "采集编号",
            width: 100,
            key: "cjid",
            dataIndex: "cjid",
            className: styles.tableColumn120,
        },
        {
            title: "育成年代",
            width: 100,
            key: "ycnd",
            dataIndex: "ycnd",
            className: styles.tableColumn120,
        },
        {
            title: "来源",
            width: 100,
            key: "ly",
            dataIndex: "ly",
            className: styles.tableColumn120,
        },
        {
            title: "备注",
            width: 100,
            key: "remarks",
            dataIndex: "remarks",
            className: styles.tableColumn120,
        },
        {
            title: "创建时间",
            width: 100,
            key: "createDate",
            dataIndex: "createDate",
            className: styles.tableColumn160,
        },
        {
            title: "操作",
            width: 100,
            fixed: "right",
            key: "action",
            className: styles.tableColumnAction,
            // @ts-ignore
            render: (text, record) => {
                return (
                    <>
                        {/*<Popconfirm title="确认要删除此条数据吗？" onConfirm={this.deleteInfoById(record)}>*/}
                        {/*    <span className={styles.tableAction}>删除</span>*/}
                        {/*</Popconfirm>*/}
                        {/*<span className={styles.tableAction}>表型</span>*/}
                        {/*<span className={styles.tableAction}>指纹图谱</span>*/}
                        {/*<span className={styles.tableAction}>基因型</span>*/}
                        {/*<span className={styles.tableAction} onClick={() => this.editInfo(record)}>编辑</span>*/}
                    </>
                );
            },
        },
    ];

    /** get 种质数据列表 */
    getResourceList = async () => {
        let resourceList = [{
            clmc: "骆福林10号",
            cllx: "国外引进品种",
            cjid: 601,
            ycnd: "80s",
            ly: "国外",
            remarks: "",
        }, {
            clmc: "游戏麦",
            cllx: "中国育成品种品种",
            cjid: 602,
            ycnd: "90s",
            ly: "甘肃",
            remarks: "",

        },]
        // MolecularProvider.getFzyzGjkList({}).then((resourceList) => {
        this.setState({toolDataList: resourceList});
        // }).catch((err) => {
        //     message.error("获取列表失败");
        // });
    }

    componentDidMount() {
        this.getResourceList()
    }

    @action.bound
    private deleteInfoById({id}: any) {
        return () => {
            // MolecularProvider.delFzyzGjk(id)
            //     .then(res => {
            //         message.success("操作成功");
            //         this.getResourceList()
            //     })
            //     .catch((err) => {
            //         console.error(err);
            //     })
            //     .finally(() => {
            //     });
        };
    }

    @action.bound
    private editInfo(record: any) {
        this.toolData = record
        transaction(() => {
            this.title = "编辑";
            this.visible = true;
            this.isEdit = true;
        });
    }

    @action.bound
    private showDetail(record: any) {

        transaction(() => {
            this.visibleDetail = true;
        });
    }

    @action.bound
    private createInfo() {
        this.toolData = {
            // @ts-ignore
            clmc: null,//样本名称
            // @ts-ignore
            cllx: null,//原始序列数据
            // @ts-ignore
            cjid: null,//质控序列数据
            // @ts-ignore
            ycnd: null,//描述
            // @ts-ignore
            ly: null,//分析
            // @ts-ignore
            remarks: null,//备注
        }
        transaction(() => {
            this.title = "新增";
            this.visible = true;
            this.isEdit = false;
        });
    }

    @action.bound
    private cancelModal() {
        this.title = "";
        this.visible = false;
    }

    @action.bound
    private setConfirmLoading(value: boolean) {
        this.confirmLoading = value;
    }

    @action.bound
    private exportData() {
        // if (UserManageStore.varietyName) {
        //     CropProvider.exportCropKV(UserManageStore.varietyName).then(res => {
        //         console.log(res);
        //         CropProvider.download(res);
        //     });
        // }
    }

    @action.bound
    private submitForm = async (data: GermplasmData) => {
        this.setConfirmLoading(true);
        // if (this.isEdit) {
        //     MolecularProvider.editFzyzGjk(data)
        //         .then(res => {
        //             message.success("操作成功");
        //             this.getResourceList()
        //         })
        //         .catch((err) => {
        //             console.error(err);
        //         })
        //         .finally(() => {
        //             this.setConfirmLoading(false);
        //             this.cancelModal();
        //         });
        // } else {
        //     MolecularProvider.addFzyzGjk(data)
        //         .then(res => {
        //             message.success("操作成功");
        //             this.getResourceList()
        //         })
        //         .catch((err) => {
        //             console.error(err);
        //         })
        //         .finally(() => {
        //             this.setConfirmLoading(false);
        //             this.cancelModal();
        //         });
        // }
    };

    handleSearchUsers = (value: string) => {
    };

    /** preview ok */
    handleOk = (file: string) => {
        this.setState({
            previewFile: file,
        });
    };

    /** preivew cancle */
    handleCancel = () => {
        this.setState({
            previewFile: undefined,
        });
    };

    /** render upload preview */
    renderPreview = () => {
        const {previewFile} = this.state;
        if (!previewFile) {
            return null;
        }
        let child = <Empty description="不支持预览"/>;
        if (/.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(previewFile)) {
            child = <img src={"http://zhongzhi.admin.grassict.cn" + previewFile} style={{maxWidth: "100%"}}/>;
        }
        if (/pdf$/.test(previewFile)) {
            child = <iframe src={previewFile} width="100%" height={660}/>;
        }
        if (previewFile && /(doc|docx|ppt|pptx|xls|xlsx)$/.test(previewFile)) {
            child = <iframe width="660px" height="660px" src={`https://view.officeapps.live.com/op/view.aspx?src=${previewFile}`}></iframe>;
        }
        return (
            <Modal
                wrapClassName={"control-width-660"}
                title={`预览`}
                visible={!!this.state.previewFile}
                footer={<Button type={"primary"} onClick={this.handleCancel}>关闭</Button>}
                onCancel={this.handleCancel}
            >
                <div style={{display: "flex", justifyContent: "center"}}>{child}</div>
            </Modal>
        );
    };

    render() {
        const {toolDataList} = this.state
        // @ts-ignore
        return (
            <div className={styles.container}>
                {/*<div className={styles.varietySearch}>*/}
                {/*    <div>*/}
                {/*        <Button*/}
                {/*            type="primary"*/}
                {/*            icon="plus"*/}
                {/*            className={styles.createButton}*/}
                {/*            onClick={this.createInfo}>新建</Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Table
                    className={styles.table}
                    // @ts-ignore
                    rowClassName={styles.tableRow}
                    rowKey="id"
                    dataSource={toolDataList}
                    columns={this.columns}
                    scroll={{x: 1000}}
                    pagination={pagination}
                />
                <GermplasmDataModalComponent
                    title={this.title}
                    visible={this.visible}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.toolData}
                    submitForm={this.submitForm}
                    edit={this.isEdit}
                />
                {this.renderPreview()}
            </div>
        );
    }
}
