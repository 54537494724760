import React from "react";
import { colorPalette } from "../../color-palette";
import { Card1, CardProps } from "@gago/frame";
import {Icon} from 'antd';
import styled from 'styled-components';
const AnalyseWrapper = styled.div`
  margin:auto;
  
  width:100%;
  height:100%;
  .analyse-container {
    width:368px;
    height:64px;
    display:flex;
    overflow:hidden;
    background-color:#f1f5f5;
    /* margin:auto; */
    margin-bottom:8px;

    .analyse-item {
      display:flex;
      flex:1;
      flex-direction:column;
      justify-content:center;
      align-items:center;

      .analyse-item-title  {
        /* width: 72px; */
        /* height: 12px; */
        /* margin: 0 59px 7px 0; */
        font-family: PingFangSC;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: rgba(0, 21, 23, 0.45);
        text-align:center;
      }
      .analyse-item-count {
        margin-top:8px;
        color: #1980b4;
        border-right:1px dotted rgba(0, 21, 23, 0.45);
        width:100%;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:center;

        .analyse-item-num {
          font-family: PingFangSC;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #1980b4;
        }
        .analyse-item-unit{
          line-height:1;
        }
      }
    }
    .analyse-item:last-child > .analyse-item-count{
      border-right:none;
    }
  }
`
// const data = [
//   {
//     title:'今日',
//     // trendIcon:<Icon type="arrow-up" />,
//     num:-20,
//     unit:'℃'
//   },
//   {
//     title:'十日最高气温',
//     // trendIcon:<Icon type="arrow-up" />,
//     num:39,
//     unit:'℃'
//   },{
//     title:'十年平均对比',
//     trend:'asc',
//     num:12,
//     unit:'%'
//   }
// ]
interface P {
  data?:{title:string;trend?:number;num:number;unit:string}[];
}
// item.trend === 'asc' ? 'arrow-up' : item.trend === 'desc' ? 'arrow-down' :'arrow-equl'
export class WeatherAnalyse extends React.PureComponent<P> {
  render() {
    const {data} = this.props;

    return (
      <AnalyseWrapper>
        <div className="analyse-container">
        {data && data.map(item => {
          return (
            <>
              <div className="analyse-item">
                <div className="analyse-item-title">{item.title}</div>
                <div className="analyse-item-count">
                {item.trend !== undefined  && <span className="trendTcon"><Icon type={ item.trend === 1 ? 'arrow-up' : item.trend === -1 ? 'arrow-down' : 'minus'}/></span> }
                <span className="analyse-item-num">{item.num}</span>
                <span className="analyse-item-unit">{item.unit}</span>
                </div>
              </div>
            </>
          )
        })}
      </div>
      </AnalyseWrapper>
      
    
    );
  }
}