import React, {Component} from "react";
import {BigScreenBody,} from "./style";
import {colorPalette} from "../../color-palette";
import Map, {Region} from "./map";
import {bmMapDefault} from "../../containers/map-layer";
import {MapPosition} from "@gago-react-gl/gago-react-gl";
import {LeftCard} from "../germplasm-resource/left-card";
import {ScreenCard} from "@gago/lib/components";
import {IntegratedQuery, PlantAll, PlantingSummary, PlantList, RegionSummary, RegionTree1} from "../../models/germplasm-resource";
import {RecordPoints} from "../../models/crop";
import GermplasmResourceProvider from "../../network/providers/germplasm-resource.provider";
import queryString from "query-string";
import {Select} from "antd";
import CropProvider from "../../network/providers/crop.provider";

interface Bprops {
}

interface Bstate {
    /** 县镇的code */
    townCode: string;
    /** 点位数据 */
    pointList: [];
    mapZoom: number;
    zoomNum: number;
    /** 地图展示中心点 */
    lonLatCenter: [number, number];
    /** 高亮的地区 */
    region: Region;
    /** 地区汇总气泡 */
    regionSummary?: RegionSummary[];
    // 省市信息列表
    areaList: RegionTree1[];
    /** 获取黄河西北5省种质汇总 */
    plantingSummary?: PlantingSummary;
    //
    areaName: string[];
    // 行政区域编码
    areaCode: string[];
    // 是否是特色种质 0:否 1:是
    isTs?: number;
    // 1:种质 2:一区多园 3:特色种植 4: 专家
    type: number;
    // 种植园类型
    parkType: string;
    // 基地类型
    baseType: string;
    // 核心区
    district: string;
    // 种质类型/专家专业类型
    category: string;
    // 查询明细列表
    plantList: PlantList;
    // 综合查询统计数据
    integratedData: IntegratedQuery[];
    /** 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 */
    plantAll?: PlantAll[];
    /** 显示类型 */
    mapMarkerType: "statistics" | "locus";
    /** 是否更改为了坐标气泡 */
    isChangeLocus?: boolean;
    recordPoints?: RecordPoints[]
}

/**
 * 种质培育一张图
 * @author 马俊峰
 * @date 2020-08-26
 * @export
 * @class BigScreen
 * @extends {Component<Bprops, Bstate>}
 */
export class Crop extends Component<Bprops, Bstate> {
    /** 地图交互图层事件 */
    baseMapStatus = true;
    state: Bstate = {
        townCode: "",
        pointList: [],
        zoomNum: 5.2,
        mapZoom: 5.2,
        lonLatCenter: [102.1, 37.485],
        areaList: [],
        region: {
            code: undefined,
            name: undefined,
            level: "province",
        },
        areaName: [],
        areaCode: [],
        // 1:种质 2:一区多园 3:特色种植 4: 专家
        type: 1,
        parkType: "all",
        baseType: "all",
        district: '',
        category: "all",
        plantList: {
            list: [],
            count: 0,
        },
        integratedData: [],
        mapMarkerType: "statistics",
    };
    /** 初始化一次加载 */
    initLoadData = async () => {
        this.getRecordPoints()
    }

    async componentDidMount() {
        await this.initLoadData();
    }

    // tslint:disable:cyclomatic-complexity
    render() {
        const { mapZoom, lonLatCenter, region, regionSummary, recordPoints, plantAll, type, mapMarkerType, zoomNum} = this.state;
        return (
            <BigScreenBody colorPalette={colorPalette} id="big_screen_body">
                <div className="header-box">
                    <div className="header-name">农作物田间表型数据库</div>
                </div>
                <ScreenCard className="mc-map-layer" colorPalette={colorPalette}>
                    <Map
                        // bbox={bbox}
                        mapZoom={mapZoom}
                        lonLatCenter={lonLatCenter}
                        customMapDefault={bmMapDefault}
                        onClick={this.mapClickLayer}
                        onChangeRegion={this.changeRegion}
                        region={region}
                        regionSummary={regionSummary}
                        recordPoints={recordPoints}
                        plantAll={plantAll}
                        type={type}
                        getRegionSummary={this.getRegionSummary}
                        getPlantAll={this.getPlantAll}
                        mapMarkerType={mapMarkerType}
                        handleMapMarkerTypeChange={this.handleMapMarkerTypeChange}
                    >
                        <MapPosition
                            zoom={zoomNum}
                            center={[102.1, 37.485]}
                            moveMethod="flyTo"
                        />
                    </Map>
                </ScreenCard>
            </BigScreenBody>
        );
    }


    /** 地图地图点击事件 */
    mapClickLayer = () => {
        window.setTimeout(() => {
            if (!this.baseMapStatus) {
                this.baseMapStatus = true;
                return;
            }
            this.setState({townCode: ""});
        }, 300);
    }

    /** 切换高亮的地区 */
    changeRegion = (region: Region) => {
        const {areaList} = this.state;
        const tempRegion = this.state.region;
        let area: RegionTree1 | undefined = {} as RegionTree1;
        let areaName: string[] = [];
        let areaCode: string[] = [];
        const list: any[] = [];

        area = areaList?.find((item) => {
            if (item.code === region.code) {
                areaName = [item.name];
                areaCode = [region.code];
                return true;
            }
            return item?.children?.find((city) => {
                if (city.code === region.code) {
                    areaName = [item.name, city.name];
                    areaCode = [item.code, city.code];
                    return true;
                }
            });

        });
        if (region.code === undefined && region.name === undefined) {
            areaName = [""];
            areaCode = [""];
        }

        this.setState({region, areaCode, areaName}, () => {
            const {region} = this.state;
            if (tempRegion.level !== region.level) {
                this.getRegionSummary();
            }
            //
            // if (tempRegion.code !== region.code) {
            //     this.getPlantingSummary();
            //     this.getIntegratedData();
            // }
        });
    }
    getRecordPoints = async () => {
        const recordPoints = await CropProvider.getRecordPoints()
        this.setState({recordPoints});
    }
    /** 获取地区汇总 气泡 */
    getRegionSummary = async (regionType?: string) => {
        const {
            type,
            region,
            isTs,
            parkType,
            baseType,
            category,
            district,
        } = this.state;
        const regionSummary = await GermplasmResourceProvider.getRegionSummary({
            type,
            dataType: region.level || regionType || "province",
            isTs,
            parkType: parkType || undefined,
            baseType: baseType || undefined,
            category: category || undefined,
            district: district || undefined,
        });
        this.setState({regionSummary});
    }
    /** 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 */
    getPlantAll = async () => {
        const {type, isTs, parkType, baseType, category, district} = this.state;
        const plantAll = await GermplasmResourceProvider.getPlantAll({
            type,
            isTs,
            parkType: parkType || undefined,
            baseType: baseType || undefined,
            category: category || undefined,
            district: district || undefined,
        });
        this.setState({plantAll});
    }
    /** 地图气泡类型 变化 */
    handleMapMarkerTypeChange = (value: "statistics" | "locus", needFixLocus?: boolean) => {
        const {isChangeLocus} = this.state;
        if (isChangeLocus) {
            return;
        }
        this.changeMarkerType(value);
        if (value === "locus" && needFixLocus) {
            this.handleChangeLocus(true);
        }
    }

    changeMarkerType = (value: "statistics" | "locus") => {
        if (this.state.mapMarkerType === value) {
            return;
        }
        this.setState({mapMarkerType: value});
    }
    handleChangeLocus = (isChangeLocus: boolean) => {
        this.setState({isChangeLocus});
    }

}
