import styled from "styled-components";

// tslint:disable-next-line:variable-name
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  .page-left{
    height: 100%;
    border-radius: 4px;
    
    .page-left-bottom{
      width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      /* height: calc(100% - 100px); */
      height: 100%;
      .search-module{
        margin: 24px 0px;
      }
      .button-list{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
      }
      .table-module{
        height: calc(100% - 256px);
      }
      .ant-table-pagination.ant-pagination{
        margin: 16px;
      }
    }
  }
  
`;