import styled from "styled-components";
import { Form } from 'antd';
// tslint:disable-next-line:variable-name
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  /* display: flex; */
  position: relative;
  /* justify-content:space-between; */
  padding:16px;
  background-color:#f1f5f5;

  .ant-form-item label{
    color: rgb(0,22,23, 0.95);
    font-size: 14px;
  }
  .ant-input[disabled] {
    border: none;
    color: rgb(0,22,23, 0.7);
    background: transparent;
    font-size: 14px;
    
  }

  .mapSelect {
    position:absolute;
    z-index:99;
    top:8px;
    left:8px;
  }
  .disasterSelect {
    position:absolute;
    z-index:99;
    top:8px;
    right:408px;
  }
  .weatherTimeLine {
    position:absolute;
    left:0;
    bottom:16px;
    width:50%;
  }
  .meteLocatonSelect {
    position:absolute;
    z-index:99;
    top:8px;
    right:408px;
  }
  .meteorologicalSelect {
    background-color:transparent;
    box-shadow:none;
    width:264px;
  }


  .left-container {
    float:left;
    height:100%;
    width:calc(50% - 16px);
    display:flex;
    /* flex:1.75; */
    flex-direction:column;
    justify-content:space-between;

    .left-top-container {
      min-height:480px;
      height:calc(50% - 16px);
      width:100%;
      padding:16px;
      margin-bottom: 16px;
      position:relative;
      background-color:#fff;

      .cardTitle {
        height:32px;
        line-height:32px;
      }
      
      .selectWrap {
        position:absolute;
        width:100%;
        top:56px;
        display:flex;
        justify-content:space-between;
        padding:16px;

        .ant-cascader-picker {
          width:50%;
        }

        .areaSelect-wrap {
        flex:1;
        display:flex;
        align-items:center;
        justify-content:center;
        .areaSelectPrefix {
          line-height:1;
          font-size:14px;
          color:#001617;
          opacity:0.45;
        }
        .ant-select {
          width:50%;
        }
      }
    }

  }

    .left-bottom-container {
      height:calc(50% - 16px);
      width:100%;
    }
  }

  .middle-container {
    /* flex:1; */
    width:25%;
    height:100%;
    display:flex;
    flex-direction:column;
    margin:auto;
    float:left;
    margin-left: 16px;
    overflow-y:auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display:none;
    }
    /* padding:16px 16px 0; */
  }
  
  .right-container {
    /* flex:1; */
    width:calc(25% - 16px);
    height:100%;
    float:right;
  }
  
  .breeding-distribute-side {
    /* width:400px; */
    /* padding:0; */
    .ant-table-body {
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display:none;
      }
    }
    .sidebar-header-box {
        background-color: #00adad;
    }
    .sidebar-content {
      .sidebar-display {
        /* padding:0; */
      }
    }
  }
  .rate-wrap {
    padding:12px;
    border-radius: 6px;
    background-color: rgba(0, 173, 173, 0.09);
    width:100%;
    height:64px;
    

    .grade {
      font-size:12px;
      line-height:1;
      margin-bottom:2px;
    }

    .rate {
      font-size:12px;
      line-height:1;
    }
  }
  .measureBox {
    padding:16px;
    /* height:326px; */
    background-color: #f7fafa;
    overflow-y:auto;
  }
  
  
`;

export const CardContentBorder = styled.div`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height:100%;
  width:100%;
`;
export const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  border-radius: 4px;
  background-color: rgba(241, 245, 245, 0.45);
  .ant-form-item {
    display: flex;
    margin-bottom: 12px;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;