import cold from './img/cold.png';
import coldActive from './img/low_tempActive.png';
import temperature from './img/temperature.png';
import temperatureActive from './img/high_tempActive.png';
import rain from './img/rain.png';
import rainActive from './img/rainstormActive.png';
import drought from './img/drought.png';
import droughtActive from './img/droughtActive.png';
import droughtWarning from './img/droughtWarning.png';
import coldWarning from './img/coldWarning.png';
import rainWarning from './img/rainWarning.png';
import temperatureWarning from './img/temperatureWarning.png';

export const diasterInfo = [
  {
    type:'high_temp',
    icon:temperature,
    activeIcon:temperatureActive,
    name:'高温预警'
  },{
    type:'low_temp',
    icon:cold,
    activeIcon:coldActive,
    name:'低温预警'
  },{
    type:'rainstorm',
    icon:rain,
    activeIcon:rainActive,
    name:'暴雨预警'
  },{
    type:'drought',
    icon:drought,
    activeIcon:droughtActive,
    name:'干旱预警'
  }
]
export const diasterLevel = [
  {
    type:'dry',
    levelInfo:[
      {
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },
    ]
    
  },{
    type:'cold',
    levelInfo:[
      {
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },
    ]
  },{
    type:'rain',
    levelInfo:[
      {
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },
    ]
  },{
    type:'drought',
    levelInfo:[
      {
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },{
        levelColor:'',
        levelName:''
      },
    ]
  }
]

export const getWaringIcon = (disaster:string) => {
  switch(disaster) {
    case '干旱':return droughtWarning;break;
    case '低温':return coldWarning;break;
    case '暴雨':return rainWarning;break;
    case '高温':return temperatureWarning;break;
  }
} 