// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select, DatePicker, Upload, Icon, Modal, Empty } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import bgicon from "./img/bg.png";
import { BaseInfo } from "../../models/germplasm-database";
import Cascader, { CascaderProps } from "antd/lib/cascader";
import { regionCascaderOtption } from "../region-cascader-option";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { get, uniqueId } from "lodash";
import moment from "moment";

const { Option } = Select;



const Wrap = styled.div`
  width: 100%;
  margin-top: 16px;
  .info{
    display: flex;
    align-items: center;
    font-family: PingFangSC;
    font-size: 20px;
    font-weight: 500;
    color: #001617;
    margin-bottom: 6px;
    .info-icon{
      width: 16px;
      height: 16px;
      background-image: url(${bgicon});
      background-size: 100% 100%;
      margin-right: 8px;
    }
    .right-component{
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

const WrapForm = styled(Form)`
  padding-top: 10px!important;
  border-radius: 4px;
  background-color: rgba(241, 245, 245, 0.45);
  .ant-form-item {
    display: flex;
    margin-bottom: 2px;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

type P = FormComponentProps & {
  /** title */
  title: React.ReactNode;
  /** 一行几列 一行 24等分 默认 一列为6 即 一行4个*/
  colNum?: number;
  /** onload   */
  onLoad?(formInstance: FormComponentProps["form"]): void;
  /** data */
  data?: BaseInfo;
  /** 是否有行政区划下拉 */
  useRegionCascader?: boolean;
  /** 右侧组件 */
  rightCoponent?: React.ReactNode;
  /** disabled all*/
  disabled?: boolean;
  /** 是否为预览模式的多张图片上传 */
  useMultiplePreview?: boolean;
  /** 当前的弹窗类型 */
  type?: "add" | "edit" | "detail";
};

interface S {
  /** 行政级联选择option */
  cascaderOption?: CascaderProps["options"];
  /** 文件 */
  filelist?: UploadFile<any>[];
  /** 预览文件*/
  previewFile?: string;
  /** 更新文件预览 */
  updatePreviewFile?: UploadFile<any>;
}

/**
 * 生成 输入 或 下拉 form表单
 * @author maoshengtao
 */
class AdvanceSearch extends React.Component<P, S> {
  state: S = {
    filelist: [],
  }

  componentDidMount() {
    const { form, onLoad } = this.props;
    if (form && onLoad) {
      onLoad(form);
    }
    
  }

  /** search */
  handleSearch = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
    });
  }

  /** reset */
  handleReset = () => {
    this.props.form.resetFields();
  }

  /** upload change */
  uploadChange = (data: UploadChangeParam<UploadFile<any>>) => {
    this.setState({ filelist: [...data.fileList] });
  }

  /** 生成 form item */
  getFields = () => {
    const { getFieldDecorator } = this.props.form;
    const { colNum, data, useMultiplePreview } = this.props;
    if (!data || data && data.list && data.list.length === 0) {
      return null;
    }
    return data.list.map((item) => {
      let initValue = item.value;
      if (item.type === "date") {
        initValue = item.value && moment(item.value) as unknown as string;
      }
      const isUpload = item.type === "upload" && this.props.useMultiplePreview;
      return (
        <Col span={isUpload ? 24 : colNum || 12} style={{ height: isUpload ? 120 : "auto" }} key={item.eName}>
          <Form.Item label={`${item.name}${item.unit ? `(${item.unit})` : ""}`}>
            {getFieldDecorator(item.eName, {
              initialValue: initValue,
              rules: [
                {
                  required: item.required,
                  message: item.message || "该项为必填!",
                },
              ],
            })(this.renderItemType(item))}
          </Form.Item>
        </Col>
      );
    })
  }

  /** render form item 类型 */
  renderItemType = (elm: BaseInfo["list"][number]) => {
    const { disabled, type } = this.props;

    if (disabled) {
      return (
        <Input
          placeholder="-"
          disabled={disabled}
        />
      );
    }

    if (elm.type === "select") {
      return (
          <Select
            disabled={disabled || (type === "edit" && elm.name === "企业名称")}
            placeholder="请选择"
            allowClear
          >
            {elm.selectData && elm.selectData.map((item) => {
              return <Option key={item} value={item}>{item}</Option>
            })}
          </Select>
      );
    }
    if (elm.type === "date") {
      return (
        <DatePicker 
          allowClear
          disabled={disabled}
        />
      );
    }

    if (elm.type === "cascader" && this.props.useRegionCascader) {
      return (
        <Cascader
          allowClear
          disabled={disabled}
          options={this.state.cascaderOption}
          placeholder="请选择所在地区"
        />
      );
    }

    if(elm.type === "upload" && this.props.useMultiplePreview) {
      return this.renderMutibleUpload(elm);
    }

    if (elm.type === "upload") {
      // const token = window.sessionStorage.getItem("token") as string;
      // const { filelist } = this.state;
      return (
        <Button onClick={this.handleOk.bind(this, `${elm.value || ""}`)}>
          <Icon type="eye" /> 预览当前报告
        </Button>
      );
    }

    if (elm.type === "updateFile") {
      const token = window.sessionStorage.getItem("token") as string;
      return (
        <Upload
          disabled={disabled}
          action={`${window.config.publisher}/api/v1/plant/file/upload`}
          headers={{ token }}
          onPreview={this.handlePreviewOk}
        >
          <Button>
            <Icon type="upload" /> 上传
          </Button>
        </Upload>
      );
    }

    return (
      <Input
        placeholder="请输入"
        disabled={disabled}
      />
    );
  }

  renderMutibleUpload = (elm: BaseInfo["list"][number]) => {
    const { filelist } = this.state;
    const { disabled } = this.props;
    const token = window.sessionStorage.getItem("token") as string;
    
    return(
      <Upload
          disabled={disabled}
          action={`${window.config.publisher}/api/v1/plant/file/upload`}
          headers={{ token }}
          listType="picture-card"
          fileList={filelist}
          onPreview={this.handlePreviewOk}
          onChange={this.uploadChange}
          multiple
        >
          <Icon type="plus"  style={{ fontSize: 48 }} /> 
        </Upload>
    );
  }

  /**update preview ok */
  handlePreviewOk = (file: UploadFile<any>) => {
    this.setState({
      updatePreviewFile: file,
    });
  }

  /**update preivew cancle */
  handlePreviweCancel = () => {
    this.setState({
      updatePreviewFile: undefined,
    });
  }

  /** render upload preview */
  renderUpdatePreview = () => {
    const { updatePreviewFile } = this.state;
    if (!updatePreviewFile || updatePreviewFile && !updatePreviewFile.type) {
      return null;
    }
    let child = <Empty description="不支持预览"/>;
    const url = updatePreviewFile.url || get(updatePreviewFile, "response.data.data[0].file", undefined)
    if (/(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(updatePreviewFile.type) && url) {
      child = <img src={url} alt={updatePreviewFile.name} style={{ maxWidth: "100%" }}/>;
    }
    if (/pdf$/.test(updatePreviewFile.type) && url) {
      child = <iframe src={url} width="100%" height={660} />;
    }
    return (
      <Modal
        title={`预览 ${updatePreviewFile.name}`}
        visible={!!this.state.updatePreviewFile}
        footer={<Button type={"primary"} onClick={this.handlePreviweCancel} >关闭</Button>}
        onCancel={this.handlePreviweCancel}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {child}
        </div>
      </Modal>
    );
  }

  /** preview ok */
  handleOk = (file: string) => {
    this.setState({
      previewFile: file,
    });
  }

  /** preivew cancle */
  handleCancel = () => {
    this.setState({
      previewFile: undefined,
    });
  }

   /** render upload preview */
   renderPreview = () => {
    const { previewFile } = this.state;
    if (!previewFile) {
      return null;
    }
    let child = <Empty description="不支持预览"/>;
    if (/.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(previewFile)) {
      child = <img src={previewFile} style={{ maxWidth: "100%" }}/>;
    }
    if (/.pdf$/.test(previewFile)) {
      child = <iframe src={previewFile} width="100%" height={660} />;
    }
    return (
      <Modal
        title={`预览`}
        visible={!!this.state.previewFile}
        footer={<Button type={"primary"} onClick={this.handleCancel} >关闭</Button>}
        onCancel={this.handleCancel}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {child}
        </div>
      </Modal>
    );
  }

  render() {
    const { title, rightCoponent } = this.props;
    return (
      <Wrap>
        <div className="info">
          <span className="info-icon" />
          <span>{title}</span>
          <div className="right-component">
            {rightCoponent}
          </div>
        </div>
        {this.renderPreview()}
        {this.renderUpdatePreview()}
        <WrapForm onSubmit={this.handleSearch}>
          <Row gutter={24}>
            {this.getFields()}
          </Row>
        </WrapForm>
      </Wrap>
    );
  }
}

export const GenerateCategory = Form.create<P>({ name: "advanced_search" })(AdvanceSearch);
