type Option = {
  label:string;
  value:string;
}

export const yearSelect = [
  {
    label:2019,
    value:2019
  },{
    label:2020,
    value:2020
  },{
    label:2021,
    value:2021
  },
]
export const cropSelect = [
  {
    label:'全部',
    value:'全部'
  }, {
    label:'玉米',
    value:'玉米'
  },{
    label:'小麦',
    value:'小麦'
  },{
    label:'葵花',
    value:'葵花'
  },{
    label:'其他',
    value:'其他'
  }
]
export const soilSelect = [
  {
    label:'草甸土',
    value:'草甸土'
  }, {
    label:'风沙土',
    value:'风沙土'
  },{
    label:'灌淤土',
    value:'灌淤土'
  },{
    label:'灰漠土',
    value:'灰漠土'
  },{
    label:'栗钙土',
    value:'栗钙土'
  }
]

export const secondSoilSelect = [
  {
    label:'草甸灌淤土',
    value:'草甸灌淤土'
  }, {
    label:'草甸盐土',
    value:'草甸盐土'
  },{
    label:'盐化灌淤土',
    value:'盐化灌淤土'
  },{
    label:'灌淤土',
    value:'灌淤土'
  },{
    label:'淡栗钙土',
    value:'淡栗钙土'
  }
]
export const soilGenus = [
  {
    label:'红泥',
    value:'红泥'
  }, {
    label:'灌淤沙土',
    value:'灌淤沙土'
  },{
    label:'硫酸盐土',
    value:'硫酸盐土'
  },{
    label:'盐沙土',
    value:'盐沙土'
  },{
    label:'盐沫尔土',
    value:'盐沫尔土'
  }
]

const soilSpecials = [ 
  {
    label:'漏沙红泥',
    value:'漏沙红泥'
  }, {
    label:'壤体沙土',
    value:'壤体沙土'
  },{
    label:'通体红泥',
    value:'通体红泥'
  },
]

export const Columns = [
  {
    title:'样点ID',
    dataIndex:'id',
    ellipsis: true,
  },{
    title:'统一编号',
    dataIndex:'soilNumber',
    ellipsis: true,
  }, {
    title:'区县名称',
    dataIndex:'countyName',
    ellipsis: true,
  }, {
    title:'乡镇名称',
    dataIndex:'townName',
    ellipsis: true,
  }, {
    title:'村名称',
    dataIndex:'villageName',
    ellipsis: true,
  }, {
    title:'采样年份',
    dataIndex:'year',
    ellipsis: true,
  }, {
    title:'经度',
    dataIndex:'lon',
    ellipsis: true,
  }, {
    title:'纬度',
    dataIndex:'lat',
    ellipsis: true,
  }, {
    title:'主要作物',
    dataIndex:'cropName',
    ellipsis: true,
  }, {
    title:'土类',
    dataIndex:'soilGroup',
    ellipsis: true,
  }, {
    title:'亚类',
    dataIndex:'subclasses',
    ellipsis: true,
  }, {
    title:'土属',
    dataIndex:'soilGenus',
    ellipsis: true,
  }, {
    title:'土种',
    dataIndex:'soilSpecies',
    ellipsis: true,
  }
]