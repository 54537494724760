import React from "react";
import "rc-table/assets/index.css";
import styled from "styled-components";
import thBg from "./table-topbar@2x.png";
import { Chart, ChartProps, ITable, Nodata1 } from "@gago/frame";

const ScrollTableContentWrap = styled.div`
  .scroll_item.even{
    /* background-color: rgba(0,187,255,0.3); */
  }
  .scroll_item{
    width: 100%;
    min-height: 32px;
    /* border-bottom: dotted 1px rgba(0, 170, 255, 0.25); */
    height:36px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(224, 254, 255);
    border-bottom: 1px solid #e8e8e8;
    background-color: rgba(0, 42, 66, 0.4);
    font-size: 14px;
    padding: 2px 0;
    &:hover{
      background-color: rgba(0, 170, 255, 0.75);
      cursor: pointer;
    }
    .item_col{
      flex: 1;
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 2px;
    }
  }
`;

type ScrollTableContentProps = {handleClick(records: any): void} & ChartProps<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, 0>;
const tableChartData: ITable<4, 0> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0, 0],
  range: [[], [], [], [], []],
  head: ["", "", "", "", ""],
  data: [
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
    ["", "XX区", "0905 12:00", "0905 12:00", "0905 12:00"],
  ],
};
/**
 * 自动滚动表格内容
 * @author wudajian
 * @export
 * @class AutoScroll
 * @extends {React.Component<Props>}
 */
export class ScrollTableContent extends React.PureComponent<ScrollTableContentProps> implements Chart<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, 0> {
  static defaultProps = {
    chartData: tableChartData,
  };

  render() {
    const { chartData: { data },handleClick } = this.props;
    if (!data.length) {
      return (
        <div style={{ textAlign: "center", lineHeight: "110px" }}>无数据</div>
      );
    }
    return (
      <ScrollTableContentWrap>
        {data.map((items, index) => (
          <div key={index} className={`scroll_item ${index % 2 === 0 && "even"}`} onClick={() => this.props.handleClick(items)}> 
            {items.map((item, num) => {
              return <div className="item_col" key={num}>{item}</div>;
            })}
          </div>
        ))}
      </ScrollTableContentWrap>
    );
  }
}
