import styled from "styled-components";
import { ColorProps, GlobalColorPalette, ITable } from "@gago/frame";
import archivesTitle from "./images/archives-title.svg";
import bozhong from "./images/播种.svg";
import chucao from "./images/除草.svg";
import dayao from "./images/打药.svg";
import jiaoshui from "./images/浇水.svg";
import shifei from "./images/施肥.svg";
import shougou from "./images/收购.svg";
import menuIcon from "./images/menu.svg";
import playLeft from "./images/play-left.svg";
import playRight from "./images/play-right.svg";

// tslint:disable:variable-name max-file-line-count
export const TracingSourceBody = styled.div<ColorProps<GlobalColorPalette>>`
  height: 100%;
  width: 100%;
  background-color: #f4f7f8;
  color: rgba(18, 43, 69, 0.8);
  .ts-head{
    height: 0.4rem;
    width: 100%;
    line-height: 0.4rem;
    text-align: center;
    font-size: 0.18rem;
    font-weight: 500;
    color: #122b45;
    background-color: #fff;
  }
  .border-box{
    margin-top: 0.08rem;
    padding: 0.16rem;
    padding-left: 0.05rem;
    background-color: #fff;
    .bb-title{
      padding-top: 4px;
      .bbt-value{
        display: block;
        line-height: 0.32rem;
        padding-left: 0.23rem;
        font-size: 0.16rem;
        font-weight: 500;
        width: 1.05rem;
        color: #fff;
        background-color: #009688;
        position: relative;
        box-shadow: -0.05rem -0.04rem 0 #e0f2f1;
        &::before{
          content: "";
          position: absolute;
          top: 0.14rem;
          left: 0.1rem;
          height: 0.04rem;
          width: 0.04rem;
          background-color: #fff;
        }
      }
    }
    .info-content{
      margin: 0.3rem 0.1rem 0.1rem;
      position: relative;
      box-sizing: content-box;
      .info-detail{
        border-radius: 0.12rem;
        border: solid 0.02rem #009688;
        padding: 0.24rem 0.1rem 0.16rem 0.2rem;
        font-size: 0.14rem;
        line-height: 1.7;
        color: rgba(3, 39, 35, 0.8);
        position: relative;
        z-index: 12;
      }
      .info-detail-shadow{
        position: absolute;
        z-index: 0;
        top: 0.07rem;
        left: 0.1rem;
        height: 100%;
        width: 100%;
        border-radius: 0.12rem;
        background-color: #ebf5f4;
      }
    }
  }
  .tracing-play-box{
    height: 0.4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7f8;
    .tpb-icon{
      height: 0.12rem;
      width: 0.18rem;
      background-image: url("${playLeft}");
      background-repeat: no-repeat;
      background-size: contain;
    }
    .tpb-icon-r{
      background-image: url("${playRight}");
    }
    .tpb-value{
      font-size: 0.16rem;
      font-weight: 500;
      line-height: 1;
      color: #032723;
      margin: 0 0.08rem;
    }
  }
  .mt-0{
    margin-top: 0;
  }
  .place-origin{
    padding-left: 0.09rem;
    padding-top: 0.24rem;
    .po-label{
      font-size: 0.16rem;
      font-weight: 500;
      color: rgba(3, 39, 35, 0.8);
      line-height: 1;
      position: relative;
      padding-left: 0.16rem;
      &::before{
        content: "";
        position: absolute;
        top: 0.01rem;
        left: 0rem;
        height: 0.14rem;
        width: 0.03rem;
        border-radius: 0.03rem;
        background-color: #009688;
      }
    }
    .massif-detail{
      margin-top: 0.16rem;
      .label-item{
        height: 0.22rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .ml-laebl{
          font-size: 0.14rem;
          color: rgba(18, 43, 69, 0.45);
        }
        .ml-value{
          font-size: 0.14rem;
          margin-left: 0.03rem;
          color: rgba(18, 43, 69, 0.8);
        }
      }
      .massif-img{
        margin-top: 0.08rem;
        height: 2.16rem;
        padding: 0.06rem;
        border-radius: 0.12rem;
        background-color: #e0f2f1;
        .ms-url{
          display: block;
          height: 100%;
          width: 100%;
          border-radius: 0.06rem;
          overflow: hidden;
        }
      }
    }
    .growth-chart{
      margin-top: 0.16rem;
      .growth-type{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .growth-select{
          padding: 0.04rem 0.12rem;
          border-radius: 0.04rem;
          box-shadow: 0 0.02rem 0.06rem 0 #fff;
          background-color: #fff;
          font-size: 0.14rem;
          line-height: 1;
          color: #799fb3;
          margin: 0 0.1rem;
          flex: 1 1 auto;
          position: relative;
          &::after{
            content: "";
            position: absolute;
            top: 0.05rem;
            right: -0.1rem;
            height: 0.12rem;
            width: 0;
            border-right: 0.01rem dashed #e1e6eb;
          }
          &:first-of-type{
            margin-left: 0;
          }
          &:last-of-type{
            margin-right: 0;
            &::after{
              display: none;
            }
          }
        }
        .gs-active{
          color: #ffffff;
          box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 173, 156, 0.4);
          background-color: #009688;
        }
      }
      .gc-chart-box{
        margin-top: 0.1rem;
        height: 2.2rem;
        border-radius: 0.12rem;
        box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 103, 91, 0.2);
        background-color: #ffffff;
        padding: 0.1rem;
      }
    }
  }
  .archives-box{
    margin-top: 0.24rem;
    padding-left: 0.15rem;
    padding-right: 0.04rem;
    .archives-detail{
      padding: 0.25rem 0.16rem;
      padding-bottom: 0;
      border-radius: 0.12rem;
      border: solid 4px #009688;
      background-color: #f7fafc;
      box-shadow: 0.1rem -0.08rem 0 rgba(0, 150, 136, 0.12);
      .ad-title{
        height: 0.24rem;
        line-height: 0.24rem;
        text-align: center;
        width: 100%;
        background-image: url("${archivesTitle}");
        background-repeat: no-repeat;
        background-size: contain;
        font-size: 0.16rem;
        font-weight: 500;
        color: rgba(3, 39, 35, 0.8);
      }
      .planting-list{
        margin-top: 0.3rem;
        .plant-item{
          margin-top: 0.3rem;
          .info-border{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.2rem;
            .left-ib{
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
          .info-img{
            margin-top: 0.12rem;
            width: 100%;
            height: 1.7rem;
            border-radius: 0.08rem;
            /* background-color: #d8d8d8; */
            overflow: hidden;
            .ii-url{
              display: block;
              height: 100%;
              width: 100%;
            }
          }
          .pi-date{
            font-size: 0.14rem;
            line-height: 1;
            color: rgba(3, 39, 35, 0.35);
          }
          .pi-icon{
            height: 0.2rem;
            width: 0.2rem;
            display: block;
            background-image: url("${bozhong}");
            background-repeat: no-repeat;
            background-size: contain;
          }
          .bozhong{
            background-image: url("${bozhong}");
          }
          .jiaoshui{
            background-image: url("${jiaoshui}");
          }
          .shifei{
            background-image: url("${shifei}");
          }
          .dayao{
            background-image: url("${dayao}");
          }
          .chucao{
            background-image: url("${chucao}");
          }
          .shougou{
            background-image: url("${shougou}");
          }
          .pi-type{
            margin-left: 0.08rem;
            font-size: 0.16rem;
            line-height: 1;
            font-weight: 600;
            color: rgba(3, 39, 35, 0.8);
          }
        }
      }
      .mt-30{
        margin-top: 0.3rem;
      }
      .recovery-box{
        padding: 0.3rem 0;
        .rb-item{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0.22rem;
          &:last-of-type{
            margin-bottom: 0rem;
          }
          .rbi-left{
            width: 43%;
            font-size: 0.14rem;
            line-height: 1;
            text-align: right;
            color: #8eacbb;
          }
          .rbi-line{
            width: 0.03rem;
            height: 0.12rem;
            background-color: #e1e6eb;
            margin: 0 0.14rem;
          }
          .rbi-right{
            width: 43%;
            font-size: 0.16rem;
            line-height: 1;
            text-align: left;
            color: rgba(3, 39, 35, 0.8);
          }
        }
      }
      .warehouse-box{
        padding: 0 0.16rem;
        border-radius: 0.12rem;
        background-color: #ffffff;
        .ware-table{
          height: 0.4rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 0.01rem solid #b4cdd6;
          .dt-date{
            width: 1.36rem;
            flex: 1 1 auto;
            font-size: 0.14rem;
            line-height: 1;
            color: #8eacbb;
            text-align: center;
          }
          .dt-tem{
            width: 0.68rem;
            flex: 1 1 auto;
            font-size: 0.14rem;
            line-height: 1;
            color: #8eacbb;
            text-align: center;
          }
        }
        .ware-value{
          height: 0.4rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 0.01rem solid #e0f2f1;
          &:last-of-type{
            border-bottom: none;
          }
          .dt-date{
            width: 1.36rem;
            flex: 1 1 auto;
            font-size: 0.14rem;
            line-height: 1;
            color: #536d7a;
            text-align: center;
          }
          .dt-tem{
            width: 0.68rem;
            flex: 1 1 auto;
            font-size: 0.14rem;
            line-height: 1;
            color: #536d7a;
            text-align: center;
          }
        }
      }
    }
  }
  .certificate-box{
    margin-top: 0.22rem;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0.08rem 0;
    margin-left: 0.09rem;
    margin-bottom: 0.1rem;
    .certificate-list{
      width: max-content;
      .cl-url{
        height: 1.4rem;
        width: 1.4rem;
        margin-right: 0.08rem;
        padding: 0.08rem;
        background-color: #fff;
        box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 103, 91, 0.2);
        border-radius: 0.08rem;
        &:last-of-type{
          margin-right: 0rem;
        }
      }
    }
  }
  .menu-anchor{
    position: fixed;
    z-index: 99;
    right: 0.16rem;
    bottom: 1.6rem;
    height: 0.48rem;
    width: 0.48rem;
    box-shadow: 0 0 0.12rem 0.02rem rgba(0, 103, 91, 0.16);
    background-color: rgba(255, 255, 255, 0.9);
    border: solid 0.02rem rgba(0, 150, 136, 0.1);
    border-radius: 0.24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-icon{
      height: 0.24rem;
      width: 0.24rem;
      background-image: url("${menuIcon}");
      background-repeat: no-repeat;
      background-size: contain;
    }
    .popover-box{
      z-index: 99;
      .ant-popover-arrow{
        display: none;
      }
      .ant-popover-inner-content{
        padding: 0.2rem;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 0.08rem;
        box-shadow: 0 0.02rem 0.12rem 0 rgba(0, 103, 91, 0.16);
        background-color: rgba(255, 255, 255, 0.95);
        .anchor-box{
          margin-left: 0;
          padding-left: 0;
          .ant-anchor-ink{
            display: none;
          }
          .ant-anchor-link{
            padding: 0;
            line-height: 1;
            font-size: 0.16rem;
            font-weight: 500;
            color: rgba(3, 39, 35, 0.68);
            margin-bottom: 0.2rem;
            &:last-of-type{
              margin-bottom: 0;
            }
            a{
              &:hover{
                color: #009688;
              }
            }
          }
          .ant-anchor-link-active{
            .ant-anchor-link-title{
              color: #009688;
            }
          }
        }
      }
    }
  }
`;
export const growthTypeList: string[] = ["空气温度", "空气湿度", "风速", "降雨量"];

export const chart2N1D: ITable<2, 1> = {
  title: "",
  subtitle: "",
  head: ["ID", "类型", "年份", "单位"],
  range: [[], [], [], []],
  prefix: ["", "", "", ""],
  suffix: ["", "", "", "℃"],
  defaultValue: [undefined, undefined, undefined, undefined],
  data: [
    ["", "温度", "04-16", 18],
    ["", "温度", "05-16", 28],
    ["", "温度", "06-16", 15],
    ["", "温度", "07-16", 30],
    ["", "温度", "08-16", 28],
    ["", "温度", "09-16", 38],
    ["", "温度", "10-16", 48],
  ],
};
