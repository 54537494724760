import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
// tslint:disable-next-line: ter-max-len
import { ExportReport,ExportPath,TestReport, ReportAddRequrest, ReportList, ReportSummary, ReportUpateRequrest, TestReportDistribute, TestReportTrend, TestReportTypeDistribute, ReportListRequest } from "../../models/germplasm-test-report";
import { ITable } from "@gago/frame";
// tslint:disable:ter-max-len

/**
 * 种质测试报告，数据服务层
 * @author maoshengtao
 */
export default class GermplasmTestReportProvider extends BaseProvider {
  /** 获取单个种质 测试报告*/
  static async getTestReport(accessionNumber: string) {
    const { data } = await BaseRequestStaticServer.get<ResultType<TestReport[]>>("/plant/test/report/all", {
      params: {
        accessionNumber,
      }
    });
    return data;
  }

  /** 获取 测试报告汇总 */
  static async getReportSummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ReportSummary>>("/plant/test/report/summary");
    return data;
  }

  /** 获取 测试报告增加趋势 */
  static async getTestReportTrend() {
    const { data } = await BaseRequestStaticServer.get<ResultType<TestReportTrend[]>>("/plant/test/report/change");
    return data;
  }

  /** 获取 测试报告类型分布 */
  static async getTestReportTypeDistribute() {
    const { data } = await BaseRequestStaticServer.get<ResultType<TestReportTypeDistribute[]>>("/plant/test/report/distribution");
    return data;
  }

  /** 获取 测试报告分布 */
  static async getTestReportDistribute() {
    const { data } = await BaseRequestStaticServer.get<ResultType<TestReportDistribute[]>>("/plant/test/report/regionSummary");
    return data;
  }

  /** 增加测试报告 */
  static async addTestReport(params: ReportAddRequrest) {
    const data: ResultType<number> = await BaseRequestStaticServer.post("/plant/test/report/add", params);
    return data;
  }

  /** 测试报告更新 */
  static async updateTestReport(params: ReportUpateRequrest) {
    const data: ResultType<number> = await BaseRequestStaticServer.post("/plant/test/report/update", params);
    return data;
  }

  /** 测试报告删除 */
  static async delTestReport(id: string) {
    const data: ResultType<number> = await BaseRequestStaticServer.post("/plant/test/report/del", {
      id,
    });
    return data;
  }

  /** 获取 测试数据列表 */
  static async getReportList(params: ReportListRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ReportList>>("/plant/test/report/list", {
      params,
    });
    return data;
  }

    /** 获取 导出文件链接 */
    static async getReportPath(params: ExportReport) {
      const { data }   = await BaseRequestStaticServer.get<ResultType<ExportPath>>("/plant/test/report/export", {
        params,
      });
      return data;
    }

    // 下载文件
    
    static async download(url:string) {
      await BaseRequestStaticServer.downloadFile(url,'种质测试报告.zip','application/zip;charset=utf-8')
      
    }
}
