// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select, Tag } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import { ExpertCateInfo, AreaList, SelectedResults } from "../../models/expert-database";
const { Option } = Select;
const { CheckableTag } = Tag;
const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  .ant-form-item {
    display: flex;
    margin-bottom:0;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
  .ant-tag-checkable {
    border-radius: 4px;
    background-color: rgba(0, 22, 23, 0.07);
  }
  .ant-tag-checkable-checked {
    background-color: #00adad;
  }
  
`;

type P = FormComponentProps & {
  /**get 数据列表 */
  // getResourceList(data: ResourceListRequest): void;
  /**专家分类 */
  expertCategory?: ExpertCateInfo[];
  /** 专家类型 */
  expertType?: ExpertCateInfo[];
  
  /** 所在地区 */
  areaList?:AreaList[];
  handleChangeCate(params:string[]):void;
  handleChangeType(params:string[]):void;
  handleChangeProv(params:string[]):void;
  handleSelectedResults(params:string[]):void;
  selectedCate:string[];
  selectedProv:string[];
  selectedType:string[];
  selectedResults:string[];
};

interface S {
  /** 作物分类类型 */
}

/**
 * 高级搜索
 * @author wangchengbing
 */
class ExpertSearchForm extends React.Component<P, S> {
  state: S = {

  };

  
  /** reset */
  handleReset = () => {
    this.props.form.resetFields();
  }

  // 处理专家分类选择数据
  handleChangeCate = (value:any) => {
    console.log(value)
  }

  /** render 专家分类 */
  renderSelect1 = () => {
    const { getFieldDecorator } = this.props.form;
    const { expertCategory } = this.props;

    if (!expertCategory || expertCategory && expertCategory.length === 0) {
      return null;
    }

    return (
      getFieldDecorator(`专家分类`, {
        initialValue: expertCategory[0].name,
      })(
        <Select
          mode="multiple"
          onChange={(value:string[])  => this.props.handleChangeCate(value)}
        >
          {expertCategory.map((item: any) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>,
      )
    );
  }

  /** render 地区 */
  renderSelect2 = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { areaList } = this.props;
    
    if (!areaList || areaList && areaList.length === 0) {
      return null;
    }
    areaList.unshift({
      id: -1 ,
      name: '全部',
      parentId: 0,
      children:  [],
      parentCode:'-1',
      parentName:'',
      lat:'',
      lon:'',
      bbox:[0,0,0,0,],
      xmax:0,
      xmin:0,
      ymax:0,
      ymin:0,
      code:'',
      value:0
    })
    return (
      getFieldDecorator(`地区`, {
        initialValue: areaList[0].name,

      })(
        <Select
          mode="multiple"
          onChange={(value:string[])  => this.props.handleChangeProv(value)}

        >
          {areaList.map((item: any) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>,
      )
    );
  }

  /** render 专家类型 */
  renderSelect3 = () => {
    const { getFieldDecorator } = this.props.form;
    const { expertType } = this.props;

    if (!expertType || expertType && expertType.length === 0) {
      return null;
    }

    return (
      getFieldDecorator(`专家类型`, {
        initialValue: expertType[0].name,
      })(
        <Select
          mode="multiple"
          value={['']}
          onChange={(value:string[])  => this.props.handleChangeType(value)}
        >
          {expertType.map((item: any, ind:number) => {
            return <Option key={ind + item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>,
      )
    );
  }

  handleChange1 = (tag:string, checked:boolean, selectedArray:string[], callback:(params:string[])=>void) => {
    const {  selectedResults, handleSelectedResults } = this.props;
    // 判断这次点击是否是全部
    const isAll = tag === '全部';     
    // 判断是否已经存在全部
    const isExitAll = selectedArray.includes('全部');
    const cate = new Set(selectedArray);
    let realResults:string[] = [];
    let nextSelectedTags:string[] = [];
    // 已经存在全部再次点击全部，全部标签不会消失
    if (isAll && isExitAll) {
      nextSelectedTags = [tag];
      // realResults = []
      realResults = Array.from(new Set(selectedResults.filter(item => !cate.has(item))))
      // 已经存在全部再点击其他标签
    } else if (isExitAll && !isAll) {
      nextSelectedTags = [tag];
      realResults = [...selectedResults,tag]
      // 不存在全部再点击全部b
    } else if (!isExitAll && isAll) {
      nextSelectedTags = [tag];
      realResults = Array.from(new Set(selectedResults.filter(item => !cate.has(item))))
    } else {
      nextSelectedTags = checked ? [...selectedArray, tag] : selectedArray.filter(t => t !== tag);
      realResults = checked ? [...selectedResults,tag] : selectedResults.filter(t => t !== tag);
    }
    // if ( (isAll && isExitAll) || (isExitAll && !isAll) || (!isExitAll && isAll) ) {
    //   nextSelectedTags = [tag];
    //   realResults = Array.from(new Set(selectedResults.filter(item => !cate.has(item))))
    // } else  {
    //   nextSelectedTags = checked ? [...selectedCate, tag] : selectedCate.filter(t => t !== tag)
    //   realResults = checked ? [...selectedResults,tag] : selectedResults.filter(t => t !== tag)
    // }
    // handleChangeCate(nextSelectedTags);
    callback(nextSelectedTags)
    handleSelectedResults(realResults);
  
  }

  // handleChange2 = (tag:string,checked:boolean) => {
  //   const { selectedProv, handleChangeProv } = this.props;
  //   const nextSelectedTags = checked ? [...selectedProv, tag] : selectedProv.filter(t => t !== tag);
  //   handleChangeProv(nextSelectedTags)
  
  // }

  // handleChange3 = (tag:string,checked:boolean) => {
  //   const { selectedType, handleChangeType } = this.props;
  //   const nextSelectedTags = checked ? [...selectedType, tag] : selectedType.filter(t => t !== tag);
  //   handleChangeType(nextSelectedTags)
  
  // }
  // 渲染分类标签
  renderTag1 = () => {
    const { expertCategory, selectedCate, handleChangeCate } = this.props;
    if (!expertCategory || expertCategory.length === 0){
      return null;
    } 
    return (
      <>
        {expertCategory.map(item => {
          return (
            <>

              <CheckableTag
                key={item.id}
                checked={selectedCate.indexOf(item.name) > -1}
                onChange = {checked => this.handleChange1(item.name, checked, selectedCate, handleChangeCate)}
              >
                {item.name}
              </CheckableTag>
            </>
          )
        })}

      </>
    )

  }

    // 渲染分类标签
    renderTag2 = () => {
      const {   areaList , selectedProv, handleChangeProv } = this.props;
      if (!areaList || areaList.length === 0){
        return null;
      } 
      return (
        <>
          {areaList.map(item => {
            return (
              <>
                <CheckableTag
                  key={item.id}
                  checked={selectedProv.indexOf(item.name) > -1}
                  onChange = {checked => this.handleChange1(item.name, checked, selectedProv, handleChangeProv)}
                >
                  {item.name}
                </CheckableTag>
              </>
            )
          })}

        </>
      )

    }

    // 渲染分类标签
    renderTag3 = () => {
      const { expertType, selectedType, handleChangeType } = this.props;
      if (!expertType || expertType.length === 0){
        return null;
      } 
      return (
        <>
          {expertType.map(item => {
            return (
              <>

                <CheckableTag
                  key={item.id}
                  checked={selectedType.indexOf(item.name) > -1}
                  onChange = {checked => this.handleChange1(item.name, checked, selectedType, handleChangeType)}
                >
                  {item.name}
                </CheckableTag>
              </>
            )
          })}

        </>
      )

    }
    
    renderTag4 = () => {
      const { selectedResults, selectedCate, selectedProv,selectedType } = this.props;
      const isAllCate = selectedCate.indexOf('全部') > -1;
      const isAllProv = selectedProv.indexOf('全部') > -1;
      const isAllType = selectedType.indexOf('全部') > -1;
      let selectedResultsCopy = selectedResults.slice()
      if ( isAllCate && isAllProv && isAllType ) {
        selectedResultsCopy = ['全部']
      } 
      return (
        <>
          {
            selectedResultsCopy.map((item:string) => {
                      return (
                        <>
                          <Tag>{item}</Tag>
                        </>
                      )
            })
          }
        </>

      )
    }
    
  render() {
    const { getFieldDecorator } = this.props.form;
    // const { storageYears } = this.props;
    return (
      <>
        <WrapForm
        // onSubmit={this.handleSearch}
        >
          {/* <Row gutter={24}>
            <Col span={8} >
              <Form.Item label={`分类`} >
                {this.renderSelect1()}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`地区`} >
                {this.renderSelect2()}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`专家类型`} >
                {this.renderSelect3()}
              </Form.Item>
            </Col>

          </Row> */}
          <Row>
            <Form.Item label={`分类`} >
              {this.renderTag1()}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label={`地区`} >
              {this.renderTag2()}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label={`专家类型`} >
              {this.renderTag3()}
            </Form.Item>
          </Row>
          <Form.Item label={`已选条件`} >
              {this.renderTag4()}
            </Form.Item>
        </WrapForm>
      </>
    );
  }
}

export const ExpertSearch = Form.create<P>({ name: "experts_search" })(ExpertSearchForm);
