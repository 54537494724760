// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import monment from 'moment'
import { Form, Row, Col, Input, Button, Select, Tag, Cascader, DatePicker, Upload, Icon, Modal, InputNumber, message } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import { CascaderOptionType} from 'antd/lib/cascader';

import { UploadFile } from "antd/lib/upload/interface";
import { UploadChangeParam,RcFile  } from "antd/lib/upload";
import { ExpertCateInfo, AreaList,ExpertEditRequest } from "../../models/expert-database";
import { uniqueId } from "lodash";
import { resolve } from "dns";
// import { is } from 'lodash';
const { Option } = Select;
const { TextArea } = Input;
const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

type P = FormComponentProps & {
  /**get 数据列表 */
  
  /** 所在地区 */
  areaList?: AreaList[];
  // 专家信息
  expertInfo:any;
  // 将form传出去
  onLoad:(params:FormComponentProps['form']) => void;
  // 上传文件
  uploadChange?:(data:UploadChangeParam<UploadFile<any>>) => void;
  // 上传文件列表
  fileList:UploadFile<any>[];
  // 是否从编辑打开
  isFromEdit?:boolean;
  handlePreview?:any
  exportType:ExpertCateInfo[];
  expertCategory:ExpertCateInfo[];

};

interface S {
  /** 作物分类类型 */
  cropType?: string;
}

/**
 * 高级搜索
 * @author wangchengbing
 */
class ExpertsAddForm extends React.Component<P, S> {
  static defaultProps = {
    expertInfo: {},
  }

  state: S = {
   
  };

  // /** search */
  // handleSearch = (e: any) => {
  //   e.preventDefault();
  //   const { form, getResourceList } = this.props;
  //   form.validateFields((err, values) => {
  //     console.log("Received values of form: ", values);
  //     if (err) {
  //       return;
  //     }
  //     getResourceList({
  //       oneLevel: values.作物分类,
  //       twoLevel: values.二类名称,
  //       year: values.入库年份,
  //       number: values.天赋河套编号,
  //       accessionName: values.种质名称,
  //       departmentName: values.选育单位,
  //       size: 10,
  //       page: 1,
  //     });
  //   });
  // }

  renderSelect2 = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { areaList, expertInfo } = this.props;
    console.log(areaList,'areaList')
    const areaListCopy = areaList?.filter(item => item.name !== '全部')
    if (!areaListCopy || areaListCopy && areaListCopy.length === 0) {
      return null;
    }
    
    return (
      getFieldDecorator(`provinceName`, {
        initialValue:[expertInfo.provinceName,expertInfo.cityName]  || [''],
        rules:[{required:true,message:'请选择所在地区'}]

      })(
        // <Select
        // >
        //   {areaListCopy.map((item) => {
        //     return <Option key={item.name} value={item.name}>{item.name}</Option>;
        //   })}
        // </Select>,
        <Cascader filedNames={{label:'name',value:'name',children:'children'}}  options={areaListCopy as unknown as CascaderOptionType[]}></Cascader>
        // <Cascader   allowClear={false} fieldNames={{label:'name',value:'code',children:'children'}}  options={areaListCopy as unknown as CascaderOptionType[]}/>
        // <Cascader   allowClear={false} fieldNames={{label:'name',value:'code',children:'children'}}  options={areaListCopy as unknown as CascaderOptionType[]}/>

      )
    );
  }

  renderTypeSelect = () => {
    const { exportType, expertInfo, form: { getFieldDecorator },  } = this.props;
    if (!exportType || exportType.length === 0) return null;
    const exportTypeCopy = exportType.filter(item => item.name !== '全部')
    return (
      getFieldDecorator(`type`, {
        initialValue: expertInfo.type || '',
        rules:[{required:true,message:'请选择专家类型'}]

      })(
        <Select
        >
          {exportTypeCopy.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>,
      )
    )


  }

  renderCateSelect = () => {
    const { expertCategory,expertInfo, form: { getFieldDecorator },  } = this.props;
    if (!expertCategory || expertCategory.length === 0) return null;
    const expertCategoryCopy = expertCategory.filter(item => item.name !== '全部')

    return (
      getFieldDecorator(`specialty`, {
        initialValue: expertInfo.specialty || '',
        rules:[{required:true,message:'请选择专家分类'}]
      })(
        <Select
        >
          {expertCategoryCopy.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>
      )
    )


  }

  /** reset */
  handleReset = () => {
    this.props.form.resetFields();
  }
  

  componentDidMount() {
    const { form,onLoad, } = this.props;
    
    
    onLoad(form)

  }

  handleGetBase64 =  (file:File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error)
    })
  }

  handleUploadBefore = (file:RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请选择图片！');
    }
    // const isLt2M = file.size / 1024 /1024 < 2;
    // if (!isLt2M) {
    //   message.error('图片大于2M');
    // }
    return isJpgOrPng ;
  }

  
  render() {
    const { getFieldDecorator } = this.props.form;
    const { expertInfo, isFromEdit } = this.props;
    const token = window.sessionStorage.getItem("token") as string;
    
    return (
      <>
        <WrapForm
          {...formItemLayout}
        // onSubmit={this.handleSearch}
        >
          <Row gutter={24}>
            {isFromEdit &&
            <Col span={8}>

              <Form.Item label={`专家编号`} >
              {getFieldDecorator(`id`,{ initialValue: expertInfo.id })(<Input disabled />)}
            </Form.Item>
            </Col>

            }
            
            <Col span={8}>
            <Form.Item label={`专家姓名`} >
                {getFieldDecorator(`name`,{ initialValue: expertInfo.name || '',
                  rules:[{required:true,message:'请输入姓名'}]  })(<Input placeholder="请输入专家姓名" />)}
              </Form.Item>
            
            </Col>
            <Col span={8}>
            <Form.Item label={`专家性别`} >
                {getFieldDecorator(`sex`,{ initialValue: expertInfo.sex || 1})(
                <Select>
                  <Option key="男" value={1}>男</Option>
                  <Option key="女" value={2}>女</Option>
                </Select>)}
              </Form.Item>
            
            </Col>
            <Col span={8}>
            <Form.Item label={`民族`} >
                {getFieldDecorator(`nationality`,{ initialValue: expertInfo.nationality || '', rules:[{required:true,message:'请输入民族'}]})(<Input placeholder="请输入民族" />)}
              </Form.Item>
            
            </Col>
            <Col span={8}>
            <Form.Item label={`工作单位`} >
                {getFieldDecorator(`departmentName`,{ initialValue: expertInfo.departmentName || '', rules:[{required:true,message:'请输入工作单位'}]})(<Input placeholder="请输入工作单位" />)}
              </Form.Item>
            
            </Col>
            <Col span={8}>
            <Form.Item label={`职位`} >
                {getFieldDecorator(`position`,{ initialValue: expertInfo.position || '', rules:[{required:true,message:'请输入职位'}] })(<Input placeholder="请输入职位" />)}
              </Form.Item>
            
            </Col>
            <Col span={8}>
            
            <Form.Item label={`职称`} >
                {getFieldDecorator(`positionalTitle`,{ initialValue: expertInfo.positionalTitle || '', rules:[{required:true,message:'请输入职称'}]})(<Input placeholder="请输入专家职称" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item label={`专家分类`} >
              {/* { getFieldDecorator(`specialty`,{ initialValue: expertInfo.specialty || '', rules:[{required:true,message:'请输入特长'}]})(<Input placeholder="请输入专家特长" />)} */}
              {this.renderCateSelect()}
              </Form.Item>
            
            </Col>
            <Col span={8}>
            
            <Form.Item label={`专家类型`} >
                {/* {getFieldDecorator(`type`,{ initialValue: expertInfo.type || '', rules:[{required:true,message:'请输入专家类型'}]})(<Input placeholder="请输入专家类型" />)} */}
                {this.renderTypeSelect()}
              </Form.Item>
            </Col>
            <Col span={8}>
            
            <Form.Item label={`工作年限`} >
                {getFieldDecorator(`workingSeniority`,{ initialValue: expertInfo.workingSeniority || '', rules:[{required:true,message:'请输入工作年限'}]})(<InputNumber style={{width:'100%'}} placeholder="请输入专家工作年限" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
            
            <Form.Item label={`归属地区`} >
                {/* {getFieldDecorator(`provinceName`,{ initialValue: expertInfo.provinceName || []})(<Cascader filedNames={{label:'name',value:'id',children:'children'}}  options={this.props.areaList} />)} */}
                {this.renderSelect2()}
              </Form.Item>
            </Col>

            <Col span={8}>
            <Form.Item label={`专家获奖`} >
                {getFieldDecorator(`prizes`,{ initialValue: expertInfo.prizes || '', rules:[{message:'请输入专家获奖情况'}]})(<Input placeholder="请输入所获奖项" />)}
              </Form.Item>
            
            </Col>
            <Col span={8}>
            <Form.Item label={`出生日期`} >
                {getFieldDecorator(`birthDate`,{ initialValue:monment(expertInfo.birthDate) || monment()})(<DatePicker />)}
              </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item label={`专家照片`} >
                {/* {getFieldDecorator(`专家年龄`)(<Input placeholder="请输入专家年龄" />)} */}
                <Upload 
                  action={`${window.config.publisher}/api/v1/plant/file/upload`}
                  headers={{ token }}
                  onPreview={this.props.handlePreview}
                  listType = "picture-card"
                  onChange={this.props.uploadChange}
                  fileList={this.props.fileList}
                  beforeUpload={this.handleUploadBefore}
                  // disabled={!!filelist}
                  // customRequest={GermplasmDatabaseProvider.getUpload}
                  >
                  
                   { this.props.fileList && this.props.fileList?.length >= 1 ? null : (<Icon type='plus'></Icon>)} 
                  
                </Upload>
              </Form.Item>
            
            </Col>
            <Col span={8}>
            <Form.Item label={`专家介绍`} >
                {getFieldDecorator(`presentation`,{ initialValue: expertInfo.presentation || '', rules:[{required:true,message:'请输入专家介绍'}]})(<TextArea style={{height:'100px'}} placeholder="请输入专家介绍" />)}
              </Form.Item>
            
            
            </Col>
          </Row>
          {/* <Row>
            
          
          </Row>
          <Row>
            
          
          </Row> */}
           
            
              
             
              
              
              
              
              {/* <Form.Item label={`年龄`} >
                {getFieldDecorator(`专家年龄`)(<Input placeholder="请输入专家年龄" />)}
              </Form.Item> */}
             
              
              
              
              
              
              
              
        </WrapForm>
      </>
    );
  }
}

export const ExpertAddForm = Form.create<P>({ name: "experts_add_form" })(ExpertsAddForm);
