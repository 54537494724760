import { ResultType, ResultType1, ResultType2 } from "../../models/common";
import { RecordPoints } from "../../models/crop";
import { BaseRequestStaticServerRuoYI, BaseRequestStaticServerRuoYiByToken } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";

/**
 * 数据库
 * @author LeeJon
 * @date 2024
 * @export
 * @class CropProvider
 * @extends {BaseProvider}
 */

export default class CropProvider extends BaseProvider {
    /** 模拟登录接口 */
    static async ruoYiLogin() {
        // @ts-ignore
        const {token} = await BaseRequestStaticServerRuoYI.post<ResultType2<any>>("/login", {
            username: "mctest",
            password: "mc123456",
            mobile: "app"
        });
        sessionStorage.setItem("RouYiToken", token);
    }

    /** 获取品种列表详情 */
    static async getFarmCropVariety(varietyName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmCropVariety/list`, {params: {varietyName}});
        return rows;
    }

    /** 添加品种 */
    static async addFarmCropVariety(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmCropVariety`, postData);
        return data;
    }

    /** 修改品种 */
    static async editFarmCropVariety(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.put<ResultType2<any>, any>(`/farm/farmCropVariety`, postData);
        return data;
    }

    /** 删除品种 */
    static async delFarmCropVariety(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.delete<ResultType2<any>>(`/farm/farmCropVariety/${id}`);
        return code;
    }

    /** 获取基肥记录列表 */
    static async getBaseFertilizerList(breedName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmBaseFertilizer/list`, {params: {breedName}});
        return rows;
    }

    /** 添加基肥记录 */
    static async addBaseFertilizer(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmBaseFertilizer`, postData);
        return data;
    }

    /** 获取基肥记录详情 */
    static async getBaseFertilizerInfo(id: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/farm/farmBaseFertilizer/${id}`, {});
        return data;
    }

    /** 导出基肥记录 */
    static async exportBaseFertilizer(crop: string) {
        // @ts-ignore
        const {msg} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmBaseFertilizer/export?breedName=${crop}`, {});
        return msg;
    }

    /** 更新基肥记录详情 */
    static async updateBaseFertilizer(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.put<ResultType2<any>, any>(`/farm/farmBaseFertilizer`, postData);
        return data;
    }

    /** 删除基肥记录 */
    static async delBaseFertilizer(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.delete<ResultType2<any>>(`/farm/farmBaseFertilizer/${id}`);
        return code;
    }

    /** 获取追肥记录列表 */
    static async getFertilizerUseList(breedName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmFertilizerUse/list`, {params: {breedName}});
        return rows;
    }

    /** 添加肥料记录 */
    static async addFertilizerUse(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmFertilizerUse`, postData);
        return data;
    }

    /** 导出追肥记录 */
    static async exportBaseFertilizerUse(crop: string) {
        // @ts-ignore
        const {msg} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmFertilizerUse/export?breedName=${crop}`, {});
        return msg;
    }

    /** 获取肥料记录详情 */
    static async getFertilizerUseInfo(id: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/farm/farmFertilizerUse/${id}`, {});
        return data;
    }

    /** 更新肥料记录详情 */
    static async updateFertilizerUse(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.put<ResultType2<any>, any>(`/farm/farmFertilizerUse`, postData);
        return data;
    }

    /** 删除追肥记录 */
    static async delFertilizerUse(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.delete<ResultType2<any>>(`/farm/farmFertilizerUse/${id}`);
        return code;
    }

    /** 获取农药记录列表 */
    static async getMedicineUseList(breedName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmMedicineUse/list`, {params: {breedName}});
        return rows;
    }

    /** 添加农药记录 */
    static async addMedicineUse(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmMedicineUse`, postData);
        return data;
    }

    /** 导出农药记录 */
    static async exportMedicineUse(crop: string) {
        // @ts-ignore
        const {msg} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmMedicineUse/export?breedName=${crop}`, {});
        return msg;
    }

    /** 获取农药记录详情 */
    static async getMedicineUseInfo(id: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/farm/farmMedicineUse/${id}`, {});
        return data;
    }

    /** 更新农药记录详情 */
    static async updateMedicineUse(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.put<ResultType2<any>, any>(`/farm/farmMedicineUse`, postData);
        return data;
    }

    /** 删除追肥记录 */
    static async delMedicineUse(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.delete<ResultType2<any>>(`/farm/farmMedicineUse/${id}`);
        return code;
    }

    /** 获取除草记录列表 */
    static async getWeedControlList(breedName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmWeedControl/list`, {params: {breedName}});
        return rows;
    }

    /** 添加除草记录 */
    static async addWeedControl(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmWeedControl`, postData);
        return data;
    }

    /** 导出除草记录 */
    static async exportWeedControl(crop: string) {
        // @ts-ignore
        const {msg} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmWeedControl/export?breedName=${crop}`, {});
        return msg;
    }

    /** 获取除草记录详情 */
    static async getWeedControlInfo(id: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/farm/farmWeedControl/${id}`, {});
        return data;
    }

    /** 更新农药记录详情 */
    static async updateWeedControl(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.put<ResultType2<any>, any>(`/farm/farmWeedControl`, postData);
        return data;
    }

    /** 删除除草记录 */
    static async delWeedControl(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.delete<ResultType2<any>>(`/farm/farmWeedControl/${id}`);
        return code;
    }

    /** 获取生育期记录列表 */
    static async getFertilityRecordList(breedName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmFertilityRecord/list`, {params: {breedName}});
        return rows;
    }

    /** 添加生育期记录 */
    static async addFertilityRecord(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmFertilityRecord`, postData);
        return data;
    }

    /** 获取生育期记录详情 */
    static async getFertilityRecordInfo(id: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/farm/farmFertilityRecord/${id}`, {});
        return data;
    }

    /** 获取浇水记录列表 */
    static async getWaterUseList(breedName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmWaterUse/list`, {params: {breedName}});
        return rows;
    }

    /** 导出浇水记录 */
    static async exportWaterUse(crop: string) {
        // @ts-ignore
        const {msg} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmWaterUse/export?breedName=${crop}`, {});
        return msg;
    }

    /** 添加浇水记录 */
    static async addWaterUse(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmWaterUse`, postData);
        return data;
    }

    /** 获取浇水记录详情 */
    static async getWaterUseInfo(id: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/farm/farmWaterUse/${id}`, {});
        return data;
    }

    /** 更新农药记录详情 */
    static async updateWaterUse(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.put<ResultType2<any>, any>(`/farm/farmWaterUse`, postData);
        return data;
    }

    /** 删除除草记录 */
    static async delWaterUse(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.delete<ResultType2<any>>(`/farm/farmWaterUse/${id}`);
        return code;
    }

    /** 园区基地数量统计点位 */
    static async getRecordPoints() {
        const {data} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<RecordPoints[]>>("/farm/area/recordPoints", {});
        return data;
    }

    /** 获取大详情 */
    static async getCropDetail(breedName: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmCropVariety/cropDetail/${breedName}`, {});
        return rows;
    }

    /** 获取KV列表 */
    static async getCropKVList(crop: string = "") {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/farmCropKV/list`, {params: {crop}});
        return rows;
    }

    /** 添加KV */
    static async addCropKV(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmCropKV`, postData);
        return data;
    }

    /** 导出KV */
    static async exportCropKV(crop: string) {
        // @ts-ignore
        const {msg} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/farm/farmCropKV/export?crop=${crop}`, {});
        return msg;
    }

    /** 编辑KV */
    static async editCropKV(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.put<ResultType2<any>, any>(`/farm/farmCropKV`, postData);
        return data;
    }

    /** 删除KV */
    static async deleteCropKV(id: string) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.delete<ResultType2<any>>(`/farm/farmCropKV/${id}`,);
        return code;
    }

    /** 下载数据 */
    static async download(fileName: string) {
        await BaseRequestStaticServerRuoYiByToken.downloadFile("/common/download?fileName=" + fileName, fileName);

    }

    /** 获取省 */
    static async getProvince() {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/area/list?level=1`, {params: {pageSize: 999}});
        return rows;
    }

    /** 获取市 */
    static async getCity(id: any) {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/farm/area/list?level=2&pid=${id}`, {params: {pageSize: 999}});
        return rows;
    }
}
