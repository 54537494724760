import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface RouteHashProps {
  /** hash 值  */
  hash: string;
  /**带入嵌入页面的类型 node | java*/
  search?: string;
}

/**
 * 动态改变路由的hash
 *
 * @class RouteHash1
 * @extends {(React.PureComponent<RouteComponentProps & RouteHashProps>)}
 */
class RouteHash extends React.PureComponent<RouteComponentProps & RouteHashProps> {
  constructor(props: RouteComponentProps & RouteHashProps) {
    super(props);
    const { history, hash, location, search } = props;
    history.push({ ...location, hash, search });
  }

  render() {
    return null;
  }
}

export default withRouter(RouteHash);
