// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Button, message, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { PageBody } from "./styled";

import { HeaderBox } from "../../containers/header-box";
import { LeftModule2 } from "./left-module2";
// tslint:disable-next-line: ter-max-len

import DictionaryProvider from "../../network/providers/dictionary-base.provider";
import * as DictionaryBaseInterface from "../../models/dictionary-base";
import OperationProvider from "../../network/providers/dictionary-operation.provider";
import * as OperationInterface from "../../models/dictionary-operation";
import { OperationOverlay } from "../../containers/operation-overlay";
import { PaginationConfig } from "antd/lib/table";


const { Option } = Select;

interface S {
  /** 对话框是否可见 */
  visible: boolean;
  /** 顶部数据搜索 id*/
  companyId?: string;
  /** 当前页数 */
  pagination?: PaginationConfig;
  // 选择的数据行数
  selectRowKeysCopy: string[];
  /** 地块列表 */
  expandList?: OperationInterface.ExpandList;
  /** 企业名称 */
  companyList?: DictionaryBaseInterface.ComponyList[];
}

/**
 * 数据字典 —— 经营管理
 * @author maoshengtao
 */
export class DictionaryOperationManagement extends React.PureComponent<{}, S> {
  state: S = {
    visible: false,
    selectRowKeysCopy: [],
  };

  componentDidMount() {
    this.getExpandList();
    this.getCompanyList();
  }

  /** get 获取溯源大屏数据字典数据 */
  getExpandList = async () => {
    const { companyId, pagination } = this.state;
    const expandList = await OperationProvider.getExpandList({
      companyId,
      page: pagination?.current || 1,
      size: pagination?.pageSize || 10,
    });
    this.setState({ expandList });
  };

  /** get 企业名称 */
  getCompanyList = async () => {
    const companyList = await DictionaryProvider.getCompanyList();
    this.setState({ companyList });
  };

  /** 溯源大屏数据字典数据新增 */
  expandAdd = async (params: OperationInterface.ExpandAddRequest) => {
    OperationProvider.expandAdd(params).then(() => {
      message.success("新增成功")
      this.onCancel();
      this.getExpandList();
    })
  };

  /** 溯源大屏数据字典数据删除 */
  expandDel = async (params: OperationInterface.ExpandDelRequest) => {
    OperationProvider.expandDel(params).then(() => {
      message.success("删除成功")
      this.getExpandList();
    })
  };


  // 获取表单的selectRowKeys
  handleSelectRowKeys = (params: string[]) => {
    this.setState({
      selectRowKeysCopy: params,
    });
  };

  /** 处理分页编号请求 */
  handlePagination = (pagination: PaginationConfig) => {
    if (pagination && pagination.current) {
      this.setState({ pagination }, () => {
        this.getExpandList();
      });
    }
  };

  /** 顶部搜索 */
  handleCompanySelect = (value: string) => {
    this.setState({ companyId: value });
  };

  /** 新增经营管理模块 */
  handleAdd = () => {
    this.setState({ visible: true });
  };
  /** 关闭新增弹窗 */
  onCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { pagination, expandList, companyList, visible } = this.state;
    return (
      <HeaderBox names={["经营管理"]}>
        <PageBody>
            <div className="page-box">
              <div className="search-module">
                <div className="search-left">
                  企业名称：
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: 260 }}
                    onChange={this.handleCompanySelect}
                  >
                    {companyList &&
                      companyList.map((item, ind) => {
                        return (
                          <Option
                            key={ind}
                            value={item.id}
                            title={item.name}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                  <Button
                    style={{ marginLeft: 8 }}
                    onClick={this.getExpandList}
                  >
                    搜索
                  </Button>
                </div>
                <div>
                  <Button type="primary" onClick={this.handleAdd}>
                    新增
                  </Button>
                  {companyList && (
                    <OperationOverlay
                      key={"add"}
                      companyList={companyList}
                      visible={visible}
                      title={"新增企业经营数据"}
                      onCancel={this.onCancel}
                      onAdd={this.expandAdd}
                      destroyOnClose
                    />
                  )}
                </div>
              </div>
              <div className="table-module">
                {expandList && <LeftModule2
                  handleSelectRowKeys={this.handleSelectRowKeys}
                  handlePagination={this.handlePagination}
                  pagination={pagination}
                  baseList={expandList}
                  expandDel={this.expandDel}
                  companyList={companyList}
                  getExpandList={this.getExpandList}
                />}
                
              </div>
            </div>
        </PageBody>
      </HeaderBox>
    );
  }
}
