import React, {useMemo} from "react";
import styled from "styled-components";
import {ICSSProps, Icon, MarkLegend2} from "@gago/frame";
import {
    Source,
    GeoJSONSource,
    Layer,
    LineLayer,
    SymbolLayer,
    FillLayer,
    mapDefault,
    MapGLProps,
    LayerEvents,
    MapEvent,
} from "@gago-react-gl/gago-react-gl";
import {BaseMap, BaseMapProps} from "../../containers/base-map";
import {BaseLayer} from "@gago-react-gl/gago-react-gl";
import {bbox as turfBbox, center as turfCenter} from "@turf/turf";
import {debounce, get, isEqual, memoize} from "lodash";
import {MapHighLayer} from "../../containers/map-high-layer";
import {PlantAll, RegionSummary} from "../../models/germplasm-resource";
import {MapCircleLayer} from "../../containers/map-circle-layer";
import {MarkerIcom} from "./style";
import {colorPalette} from "../../color-palette";
import {Popover, Select, Button} from "antd";
import {HuangHeHightFillMapLayer} from "./map-layer";
import {DetailPointOverlay} from "./detail-point-overlay";

import {MCCard} from "@gago/lib/components/mc-card";
import {getLegendIcon, getLegendIconList} from "./getImg";
import {isCoor} from "../../utils";
import {scaleStyle} from "../../decorator/scale-container";
import {PointMarker} from "../../containers/point-marker";
import topPng from "./images/top.png";
import {RecordPoints} from "../../models/crop";

export interface Region {
    /** code */
    code: string | undefined;
    /** name */
    name: string | undefined;
    /** level */
    level: "province" | "city" | "county" | undefined;
}

export const mapType = {
    province: {
        minzoom: 0,
        maxzoom: 6.5,
    },
    city: {
        minzoom: 6.5,
        maxzoom: 8,
    },
    county: {
        minzoom: 8,
        maxzoom: 17,
    },
};

interface P extends Partial<BaseMapProps>, ICSSProps {
    /** 当前高亮的地区 */
    region: Region;

    /** 切换高亮的地区 */
    onChangeRegion(region: Region): void;

    /** 地区汇总气泡 */
    regionSummary?: RegionSummary[];
    /** 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 */
    plantAll?: PlantAll[];
    // 1:种质 2:一区多园 3:特色种植 4: 专家
    type: number;

    /** 获取统计坐标气泡 */
    getRegionSummary(): void;

    /** 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 */
    getPlantAll(): void;

    /** 显示类型 */
    mapMarkerType: string;

    /** 处理气泡状态切换 */
    handleMapMarkerTypeChange(type: "statistics" | "locus"): void;

    bbox?: [number, number, number, number];
    lonLatCenter?: [number, number];
    mapZoom: number;
    recordPoints?: RecordPoints[]
}

interface S {
    /** 市级code */
    cityCode: string;
    /** 县级code */
    countyCode: string;
    /** 地图 */
    map: mapboxgl.Map | undefined;
    /** active icon detail */
    activeIconDetail?: RecordPoints;
    /** 种质编号 */
    detailNumber?: RecordPoints;
    /** 控制右侧的图标的隐藏 */
    rightVisiable?: boolean;
}

/**
 *  黄河流域西北地区种质资源平台 地图
 * @author maoshengtao
 */

export default class Map extends React.PureComponent<P, S> {
    /** icon 点击 */
    iconClick: boolean;

    constructor(props: P) {
        super(props);
        this.state = {
            cityCode: "",
            countyCode: "",
            map: undefined,

        };
        this.iconClick = false;
    }

    onLoadMap = (map: mapboxgl.Map) => {
        this.setState({map});
    };

    componentWillReceiveProps(nextProps: P) {
        // this.context.map.fitBounds(nextProps.bbox);

        if (
            JSON.stringify(nextProps?.lonLatCenter) !==
            JSON.stringify(this.props?.lonLatCenter) &&
            this.state.map &&
            nextProps.lonLatCenter
        ) {
            // this.state.map.fitBounds(nextProps.bbox);
            // const zoomNum = nextProps?.region?.code === undefined ? 5.2 : 8;
            this.state.map.flyTo({
                center: nextProps.lonLatCenter,
                zoom: nextProps.mapZoom,
            });
        }
    }

    componentDidUpdate(preProps: P) {
        if (this.props.type !== preProps.type) {
            const {map} = this.state;
            map && map.flyTo({center: [102.1, 37.485], zoom: 5.2});
            this.setState({activeIconDetail: undefined, rightVisiable: false});
            this.props.onChangeRegion({
                code: undefined,
                name: undefined,
                level: undefined,
            });
        }
    }

    render() {
        const {children, onClick, customMapDefault} = this.props;
        return (
            <>
                <BaseMap onClick={onClick} customMapDefault={customMapDefault} onLoad={this.onLoadMap}>
                    <BaseLayer id="tiandi_base_layer" type="TianDiTu_Satellite_Map" layerOption={{before: "huanghe_liuyu_germplasm_layer"}}/>
                    {this.renderSource()}
                    {this.renderLayer()}
                    {/*{this.renderHighLayer()}*/}
                    {/*{this.renderMapEvent()}*/}
                    {/*{this.renderMapCircle()}*/}
                    {this.renderPointMarker()}
                    {this.renderDetailOverlay()}
                    {this.renderMarkerLegend()}
                    {children}
                </BaseMap>
            </>
        );
    }

    t: any = undefined;
    legend: any = undefined;

    renderMarkerLegend = () => {
        let icons = getLegendIcon();
        this.legend = (
            <MarkLegend2
                style={{
                    position: "absolute",
                    bottom: "16px",
                    right: "16px",
                    left: "auto",
                    transform: "translate3d(0px, 0px, 0px)",
                    zIndex: 99,
                }}
                colorPalette={colorPalette}
                icons={icons.map((item) => {
                    return (
                        <div
                            key={item.name}
                            style={{
                                width: 16,
                                height: 16,
                                background: `url(${item.icon}) 0 0 / 100% 100%`,
                            }}
                        />
                    );
                })}
                names={icons.map((elm) => elm.name)}
            />
        );
        return this.legend;
    };

    /** handle icon click  */

    handleIconClick = (data: RecordPoints) => {
        if (!data) {
            return;
        }
        this.iconClick = true;
        const {map} = this.state;
        map && map.flyTo({center: [Number(data.lon), Number(data.lat)], zoom: 18});
        this.setState({activeIconDetail: data});
    };

    /** handle icon cancel  */

    handleIconCancel = (e: any) => {
        e.stopPropagation();
        this.iconClick = true;
        const {map} = this.state;
        map && map.flyTo({center: [102.1, 37.485], zoom: 5.2});
        this.setState({activeIconDetail: undefined, rightVisiable: false});
    };

    /** 获取pointMarker 对应的icon列表 */
    getIconList = (data: RecordPoints[]) => {
        let info = getLegendIcon()
        const iconArr = info.map((item) => {
            return {
                icon: item.icon,
                category: item.name,
            };
        });
        return {
            iconArr,
            propertie: "iconType",
        }

    };

    memoGetIconList = memoize(this.getIconList);


    /** 处理 并获得 坐标气泡数据 */
    handlePointData = (data: RecordPoints[]) => {
        let datar = data.map((elm) => ({
            ...elm,
            center: [Number(elm.lon), Number(elm.lat)],
            iconType: elm.type,
        }))
        return datar;

    }

    memoHandlePointData = memoize(this.handlePointData);

    /** render 点位 坐标气泡*/
    renderPointMarker = () => {
        const {recordPoints} = this.props;
        const {activeIconDetail, map} = this.state;
        if (!recordPoints) {
            return null;
        }
        recordPoints.map((item, index) => {
            let type = 0
            switch (item.type) {
                case "基肥记录":
                    type = 1;
                    break;
                case "追肥记录":
                    type = 2;
                    break;
                case "用药记录":
                    type = 3;
                    break;
                case "除草记录":
                    type = 4;
                    break;
                case "生育期记录":
                    type = 5;
                    break;
                case "浇水记录":
                    type = 6;
                    break;
            }
            item.typeNumber = type
        })
        return (
            <>
                <PointMarker
                    visible={true}
                    iconObj={this.memoGetIconList(recordPoints)}
                    useClick
                    mapPointData={this.memoHandlePointData(recordPoints)}
                    map={map}
                    handleIconClick={this.handleIconClick}
                    maxZoom={1}
                    iconSize={0.25}
                />
                {activeIconDetail && (
                    <MarkerIcom coordinates={[Number(activeIconDetail.lon), Number(activeIconDetail.lat)]} colorPalette={colorPalette}>
                        <div className="type-marker type-markerlb">
                            {this.renderRecordPointOverlay(activeIconDetail)}
                            <div className="icon-marker"/>
                        </div>
                    </MarkerIcom>
                )}
            </>
        );
    };

    handleStop = (e: any) => {
        e.stopPropagation();
        this.iconClick = true;
    };

    /** render 专家 弹窗 */
    renderExpertOverlay = (data: PlantAll) => {
        const {type} = this.props;
        if (type !== 4) {
            return null;
        }
        return (
            <div className="prise-info-box" onClick={this.handleStop}>
                <div className="ls-title">
                    <div className="lst-left">
                        <span className="lstl-icon"/>
                        <span className="lstl-value">{data.name || "---"}</span>
                    </div>
                    <span className="lst-right" onClick={this.handleIconCancel}>
            <Icon>plus</Icon>
          </span>
                </div>
                <div className="bottom-line"/>
                <div className="pib-border">
                    <div className="title-tool">
                        <span className="tool-name">专家名称：</span>
                        <span className="tool-value">{data.name || "---"}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">职位：</span>
                        <span className="tool-value">{`${data.position || "---"}`}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">职称：</span>
                        <span className="tool-value">{data.positionalTitle || "---"}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">特长：</span>
                        <span className="tool-value">{data.specialty || "---"}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">工作单位：</span>
                        <span className="tool-value">{data.departmentName || "---"}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">工作年限：</span>
                        <span className="tool-value">{data.workingSeniority || "---"}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">归属地：</span>
                        <span className="tool-value">{data.provinceName || "---"}</span>
                    </div>
                </div>
            </div>
        );
    };
    goLink = () => {
        location.href = "http://tfht.group3yes.com/iportal/apps/mapdashboard/v2/index.html?id=933663867&action=view&mode=pc"
    }
    /** 一区多园N基地图标图标实例dom */
    renderMarkerParkBaseDom = (data: PlantAll) => {
        const {type} = this.props;
        if (type !== 2) {
            return null;
        }
        return (
            <div className="prise-info-box" onClick={this.handleStop}>
                <div className="ls-title">
                    <div className="lst-left">
                        <span className="lstl-icon"/>
                        <span className="lstl-value">{data.baseName || "---"}</span>
                    </div>
                    <span className="lst-right" onClick={this.handleIconCancel}>
            <Icon>plus</Icon>
          </span>
                </div>
                <div className="bottom-line"/>
                <div className="pib-border">
                    <div className="title-tool">
                        <span className="tool-name">公司名称：</span>
                        <span className="tool-value">{data.company}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">省、市：</span>
                        <span className="tool-value">{`${data.provinceName || "---"} 、${
                            data.cityName
                        }`}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">类型描述：</span>
                        <span className="tool-value">{data.type || "---"}</span>
                        {data.isPepper === "是" && <span className="tool-btn"><Button type="primary" onClick={() => this.goLink()}>辣椒产业数据库</Button></span>}
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">公司简介：</span>
                        <span className="tool-value" title={data.info || ""}>
              {data.info || "---"}
            </span>
                    </div>
                    {data.baseName === "研究院小麦基地" && (
                        <div className="title-tool">
                            <span className="tool-name">基地现状：</span>
                            <span className="tool-value">
                <a
                    style={{
                        color: "yellow",
                        fontWeight: 500,
                        textDecoration: "underline",
                    }}
                    target="_blank"
                    href={`/tracing-source/f0d55517fa4f414fa566450b5eb7d980?token=${window.sessionStorage.getItem(
                        "token"
                    )}`}
                >
                  点击这里查看基地现状
                </a>
              </span>
                        </div>
                    )}
                    {data.image && (
                        <div className="info-img">
                            <img src={data.image} alt="" className="img-url"/>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    /** 右侧悬框展示状态事件 */
    rightVisiableClick = () => {
        this.setState({rightVisiable: !this.state.rightVisiable});
    };


    /** handle 弹窗 open */
    handleDetailClick = (detailNumber: RecordPoints) => {
        this.setState({detailNumber});
    };

    /** handle 弹窗 close*/
    handleDetailCancle = () => {
        this.setState({detailNumber: undefined});
    };

    renderDetailOverlay = () => {
        const {detailNumber} = this.state;
        if (!detailNumber) {
            return null;
        }
        return (
            <DetailPointOverlay
                visible={!!detailNumber}
                accessData={detailNumber}
                onCancel={this.handleDetailCancle}
                title={detailNumber.type}
            />
        );
    };

    /** render 种质 弹窗 */
    renderRecordPointOverlay = (data: RecordPoints) => {
        const {type} = this.props;
        if (type === 2 || type === 4 || !data) {
            return null;
        }
        // const pic = Array.isArray(get(data, "pic", undefined)) ? data.pic : [] as string[];
        return (
            <div className="prise-info-box" onClick={this.handleStop}>
                <div className="ls-title">
                    <div className="lst-left">
                        <span className="lstl-icon"/>
                        <span className="lstl-value">{data.type || "---"}</span>
                    </div>
                    <span className="lst-right" onClick={this.handleIconCancel}><Icon>plus</Icon></span>
                </div>
                <div className="bottom-line"/>
                <div className="pib-border">
                    <div className="title-tool">
                        <span className="tool-name">名称：</span>
                        <span className="tool-value">{data.type}</span>
                    </div>
                    <div className="title-tool">
                        <span className="tool-name">查看更多：</span>
                        <span className="tool-value">
                            <span style={{color: "yellow", fontWeight: 500, textDecoration: "underline",}} onClick={this.handleDetailClick.bind(this, data)}>点击这里查看详情</span>
                         </span>
                    </div>
                </div>
            </div>
        );
    };

    /** render 圆形marker */
    renderMapCircle = () => {
        const {regionSummary, mapMarkerType, type} = this.props;
        if (!regionSummary || mapMarkerType !== "statistics") {
            return null;
        }
        let unit = "份";
        if (type === 2) {
            unit = "个";
        }
        if (type === 3) {
            unit = "种";
        }
        if (type === 4) {
            unit = "人";
        }
        return (
            <MapCircleLayer
                mapMarker={regionSummary.map((item) => ({
                    region: item.name,
                    center: [item.lon, item.lat],
                    value: item.count,
                }))}
                unit={unit}
            />
        );
    };

    /** 延时处理地图事件 */
    handleSetTimeLayerClick = (e: any) => {
        e.preventDefault();
        const xp = e.features[0];
        setTimeout(() => {
            this.handlDebounceLayerClick(xp);
        }, 300);
    };

    /** 点击任意layer图层 */
        // tslint:disable-next-line: no-any
    handleLayerClick = (xp: any) => {
        if (!xp || this.iconClick) {
            this.iconClick = false;
            return;
        }
        const {map} = this.state;
        if (!map) {
            return;
        }
        const {onChangeRegion, handleMapMarkerTypeChange} = this.props;

        const zoom = map.getZoom();
        const tempCenter = turfCenter(xp);
        const tempBBox = turfBbox(xp);

        const {
            properties: {proName, proCode, cityName, cityCode},
        } = xp;

        let target: Region = {code: undefined, name: undefined, level: undefined};
        if (zoom < mapType.province.maxzoom) {
            target = {
                code: proCode,
                name: proName,
                level: "city",
            };
            if (get(tempCenter, "geometry.coordinates", false)) {
                map.flyTo({
                    center: tempCenter.geometry.coordinates as [number, number],
                    zoom: mapType.province.maxzoom,
                });
                handleMapMarkerTypeChange("statistics");
            }
        }
        if (zoom >= mapType.city.minzoom) {
            target = {
                code: cityCode,
                name: cityName,
                level: "county",
            };
            if (tempBBox) {
                // map.fitBounds(turfBbox(e.features[0]) as mapboxgl.LngLatBoundsLike);
                map.flyTo({
                    center: tempCenter.geometry.coordinates as [number, number],
                    zoom: mapType.city.maxzoom,
                });
                handleMapMarkerTypeChange("locus");
            }
        }
        onChangeRegion && onChangeRegion(target);
    };

    handlDebounceLayerClick = debounce(this.handleLayerClick, 300);

    /** 点击地图有效区域以外 */
        // tslint:disable-next-line: no-any
    private handleOutMapClick = (e: any) => {
        if (e.defaultPrevented) return;
        // this.setState({ cityCode: "" });
        setTimeout(() => {
            this.props.onChangeRegion &&
            this.props.onChangeRegion({
                code: undefined,
                name: undefined,
                level: undefined,
            });
        }, 300);
    };

    /** 监听地图的滚动事件 */
        // tslint:disable: cyclomatic-complexity
    handleZoom = (e: any) => {
        const {map} = this.state;
        const {onChangeRegion, region, handleMapMarkerTypeChange} = this.props;
        if (!map) {
            return;
        }
        const zoom = map.getZoom();
        if (zoom < mapType.province.maxzoom) {
            onChangeRegion({...region, level: "province"});
            handleMapMarkerTypeChange("statistics");
        }
        if (zoom >= mapType.city.minzoom && zoom < mapType.city.maxzoom) {
            onChangeRegion({...region, level: "city"});
            handleMapMarkerTypeChange("statistics");
        }
        if (zoom >= mapType.county.minzoom) {
            onChangeRegion({...region, level: "county"});
            handleMapMarkerTypeChange("locus");
        }
    };

    /** 滚动节流 */
    handleZoomDebounce = debounce(this.handleZoom, 1000);

    renderMapEvent() {
        return (
            <>
                <LayerEvents
                    layer={"huanghe_shengjie_fill_layer"}
                    onClick={this.handleSetTimeLayerClick}
                />
                <LayerEvents
                    layer={"huanghe_city_fill_layer"}
                    onClick={this.handleSetTimeLayerClick}
                />
                <MapEvent type="click" onChange={this.handleOutMapClick}/>
                <MapEvent type={"zoom"} onChange={this.handleZoomDebounce}/>
            </>
        );
    }

    renderHighLayer() {
        const {
            region: {code},
        } = this.props;
        return (
            <>
                <MapHighLayer
                    lineOption={{
                        id: "province-high-layer",
                        source: "huanghe_shengjie_base_source",
                    }}
                    code={code}
                />
                <MapHighLayer
                    filterName="cityName"
                    filterCode="cityCode"
                    lineOption={{
                        id: "city-high-layer",
                        source: "huanghe_city_base_source",
                    }}
                    code={code}
                />
            </>
        );
    }

    renderLayer = () => {
        return (
            <>
                <Layer<LineLayer>
                    type="line"
                    source="huanghe_city_base_source"
                    id="huanghe_city_base_source_line_layer"
                    paint={{"line-color": "#ffbb00", "line-width": 0.8}}
                    {...mapType.city}
                />
                <Layer<FillLayer>
                    type="fill"
                    source="huanghe_city_base_source"
                    id="huanghe_city_fill_layer"
                    paint={{"fill-color": "transparent"}}
                    minzoom={mapType.city.minzoom}
                />
                <Layer<LineLayer>
                    type="line"
                    source="huanghe_shengjie_base_source"
                    id="huanghe_shengjie_line_layer"
                    paint={{"line-color": "#3CFFF0", "line-width": 1.5}}
                />
                <Layer<FillLayer>
                    type="fill"
                    source="huanghe_shengjie_base_source"
                    id="huanghe_shengjie_fill_layer"
                    paint={{"fill-color": "transparent"}}
                />
                <Layer<FillLayer>
                    type="fill"
                    source="huangheliuyu_base_source"
                    id="huangheliuyu_base_fill_color"
                    paint={{"fill-color": "rgba(0, 221, 255, 0.2)"}}
                />
                <Layer<LineLayer>
                    type="line"
                    source="huanghe_base_source"
                    id="huanghe_base_line_layer"
                    paint={{"line-color": "#0066ff", "line-width": 2.5}}
                />
                <Layer<SymbolLayer>
                    type="symbol"
                    source="huanghe_shengjie_base_source"
                    id="huanghe_shengjie_base_name_layer"
                    {...mapType.province}
                    layout={{
                        "text-field": "{proName}",
                        "text-max-width": 6,
                        "text-size": ["interpolate", ["linear"], ["zoom"], 8, 16, 10, 24],
                        "text-font": ["Arial Unicode MS Regular"],
                    }}
                    paint={{
                        "text-color": "#fff",
                        "text-halo-color": "#000",
                        "text-halo-width": 1,
                    }}
                />
                <Layer<SymbolLayer>
                    type="symbol"
                    source="huanghe_city_base_source"
                    id="huanghe_city_base_source_name_layer"
                    minzoom={mapType.city.minzoom}
                    layout={{
                        "text-field": "{cityName}",
                        "text-max-width": 6,
                        "text-size": ["interpolate", ["linear"], ["zoom"], 8, 12, 10, 16],
                        "text-font": ["Arial Unicode MS Regular"],
                    }}
                    paint={{
                        "text-color": "#fff",
                        "text-halo-color": "#000",
                        "text-halo-width": 1,
                    }}
                />
            </>
        );
    };

    renderSource = () => {
        return (
            <>
                <Source<GeoJSONSource>
                    id="huanghe_base_source"
                    type="geojson"
                    data="/static/data/黄河.geojson"
                />
                <Source<GeoJSONSource>
                    id="huangheliuyu_base_source"
                    type="geojson"
                    data="/static/data/黄河流域.geojson"
                />
                <Source<GeoJSONSource>
                    id="huanghe_shengjie_base_source"
                    type="geojson"
                    data="/static/data/省界.geojson"
                />
                <Source<GeoJSONSource>
                    id="huanghe_city_base_source"
                    type="geojson"
                    data="/static/data/huanghe-city.geojson"
                />
            </>
        );
    };
}
