import React from "react";
import { Table } from "antd";
import styled from "styled-components";
import { ColorProps, Color } from "@gago/frame/es/interface";

import { GlobalColorPalette } from "../../color-palette";
import Provider from "../../network/providers/suitability-evaluation.provider";
import { EvaluationResults, EvaluationResultsOrder, EvaluationResultsRequest } from "../../models/suitability-evaluation";
import { numberFix } from "../../util";
import scrollStyle from "../../components/scrollbar-style";

export const FilterWrapper = styled.div`
width: 100%;
height: 100%;

.table-render-data-area {
  position: relative;
  margin-right: 20px;

  &:before {
    content: "";
    width: 0;
    height: 8px;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    border-right: 1px solid rgba(0, 21, 23, 0.15);
    position: absolute;
  }
}

.table-render-data-proportion {

}
`;
/** 分隔符 */
const separate = "&";
/** 分页的配置 */
const paginationConfig = {
  size: "small",
  showSizeChanger: true,
  showQuickJumper: true,
  pageSizeOptions: ["5", "10", "20"],
  total: 10,
  defaultPageSize: 10,
};

/** 列表配置 */
const columnsConfig = [
  {
    name: "序号",
    key: "count",
    /** 是否显示排序按钮 */
    showOrder: false,
    /** 是否显示div处理 */
    showRender: false,
    flex: 1,
  },
  {
    name: "乡镇",
    key: "name",
    showOrder: false,
    showRender: false,
    flex: 2,
  },
  {
    name: "极适宜面积及比例",
    key: "high",
    showOrder: true,
    showRender: true,
    flex: 3,
  },
  {
    name: "较适宜面积及比例",
    key: "neutral",
    showOrder: true,
    showRender: true,
    flex: 3,
  },
  {
    name: "次适宜面积及比例",
    key: "common",
    showOrder: true,
    showRender: true,
    flex: 3,
  },
  {
    name: "不适应性面积及比例",
    key: "not",
    showOrder: true,
    showRender: true,
    flex: 3,
  },
];

type SuitabilityEvaluationTableProps = {
  /** 作物类型 */
  crop: string;
} & ColorProps<GlobalColorPalette>;
interface SuitabilityEvaluationTableState {
  /** 列表详情 */
  list: EvaluationResults[] | null;
  /** 排序方式 */
  sortedInfo: any;
  /** 当前页数 */
  current: number;
  /** 一页多少条数据 */
  pageSize: number;
  /** 总共有多少条数据 */
  totalSize: number;
  /** 排序的值 */
  orderKey: string | null;
  /** 排序规则 */
  orderBy: EvaluationResultsOrder | null;
}

/**
 * 数字茶园-适宜性评价:下方table列表
 * @author luyaxiang
 * @date 2019.6.18
 * @export
 * @class SuitabilityEvaluationTable
 * @extends {React.PureComponent<SuitabilityEvaluationTableProps, SuitabilityEvaluationTableState>}
 */
export class SuitabilityEvaluationTable extends React.PureComponent<SuitabilityEvaluationTableProps, SuitabilityEvaluationTableState>
  implements Color {

  state: SuitabilityEvaluationTableState = {
    sortedInfo: null,
    list: null,
    current: 1,
    pageSize: paginationConfig.defaultPageSize,
    totalSize: paginationConfig.defaultPageSize,
    orderKey: null,
    orderBy: null,
  };

  border = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    .ant-table-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .ant-spin-nested-loading {
        width: 100%;
        height: 100%;

        .ant-spin-container {
          width: 100%;
          height: 100%;

          .ant-table {
            width: 100%;
            height: calc(100% - 40px);

            .ant-table-content {
              width: 100%;
              height: 100%;

              .ant-table-scroll {
                width: 100%;
                height: 100%;

                .ant-table-body {
                  ${scrollStyle}
                  height: calc(100% - 57px);
                  max-height: none !important;
                }
              }
            }
          }
        }
      }

      .ant-table-thead {
        background-color: rgba(0, 21, 23, 0.02);
        border-top: 2px solid rgba(0, 21, 23, 0.25);

        .ant-table-column-has-filters, .ant-table-align-center {
          text-align: center;

          span {
            font-family: PingFangSC;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: rgba(0, 21, 23, 0.9);

            .ant-table-column-sorter {
              color: rgba(0, 21, 23, 0.25);
              margin-bottom: 2px;

              .ant-table-column-sorter-down {
                margin: 0;
              }
            }
          }
        }
      }

      .ant-table-tbody {
        height: 200px;
        font-family: PingFangSC;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: rgba(0, 21, 23, 0.7);

        .ant-table-column-has-filters {
        }
      }
    }
  `;


  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: SuitabilityEvaluationTableProps) {
    const { crop } = this.props;
    if (crop !== prevProps.crop) {
      this.getData(1);
    }
  }

  async getData(currentPage?: number) {
    const { crop } = this.props;
    const { current, pageSize, orderBy, orderKey } = this.state;
    const request: EvaluationResultsRequest = {
      page: currentPage || current,
      size: pageSize,
      crop: crop,
    };
    if (orderBy !== null && orderKey !== null) {
      // @ts-ignore
      request[orderKey] = orderBy;
    }
    const result = await Provider.getEvaluationResults(request);
    if (result === null) {
      return;
    }
    this.setState({
      list: result.list,
      totalSize: result.pagination.totalSize,
      current: currentPage || current,
    });
  }

  /** 监听变化 */
  handleChange = (pagination: any, filters: any, sorter: any) => {
    let orderBy: EvaluationResultsOrder | null = null;
    if (sorter.order === "ascend") {
      orderBy = "ASC";
    } else if (sorter.order === "descend") {
      orderBy = "DESC";
    }
    let orderKey = null;
    if (sorter.columnKey === "name") { // 乡镇
      orderKey = "regionName";
    } else if (sorter.columnKey === "high") { // 高适应性面积及比例
      orderKey = "best";
    } else if (sorter.columnKey === "neutral") { // 中适应性面积及比例
      orderKey = "better";
    } else if (sorter.columnKey === "common") { // 一般适应性面积及比例
      orderKey = "secondary";
    } else if (sorter.columnKey === "not") { // 不适应性面积及比例
      orderKey = "unsuitable";
    }
    this.setState({
      orderKey,
      orderBy,
      sortedInfo: sorter,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }, () => {
      this.getData();
    });
  }

  /** 获取列表配置 */
  getTableData = () => {
    const { list } = this.state;
    if (list === null) {
      return [];
    }
    return list.map((subList: EvaluationResults, index: number) => {
      return {
        key: `${index}`,
        count: index + 1,
        name: subList.name,
        high: `${subList.highSuitabilityArea}${separate}${subList.highSuitabilityProportion}`,
        neutral: `${subList.neutralSuitabilityArea}${separate}${subList.neutralSuitabilityProportion}`,
        common: `${subList.commonSuitabilityArea}${separate}${subList.commonSuitabilityProportion}`,
        not: `${subList.notSuitabilityArea}${separate}${subList.notSuitabilityProportion}`,
      };
    });
  }

  /** 渲染数据：面积及占比 */
  renderData = (area: string | number, proportion: string | number) => {
    return (
      <FilterWrapper>
        <span className="table-render-data-area">{numberFix(Number(area) / 10000, 2)}万亩</span>
        <span className="table-render-data-proportion">{numberFix(Number(proportion) * 100, 2)}%</span>
      </FilterWrapper>
    );
  }

  /** 获取columns信息 */
  getColumns = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const totelFlex = columnsConfig.reduce<number>((total, value) => {
      return total + value.flex;
    }, 0);
    return columnsConfig.map((value) => {
      const singleColumn: any = {
        width: `${(value.flex / totelFlex) * 100}%`,
        title: value.name,
        dataIndex: value.key,
        key: value.key,
        align: "center" as "left" | "right" | "center" | undefined,
      };
      if (value.showOrder) {
        singleColumn.sorter = true;
        singleColumn.sortOrder = (sortedInfo.columnKey === value.key && sortedInfo.order);
      }
      if (value.showRender) {
        singleColumn.render = (data: string) => {
          const dataSplit = data.split(separate);
          return this.renderData(dataSplit[0], dataSplit[1]);
        };
      }
      return singleColumn;
    });
  }

  render() {
    const { totalSize } = this.state;
    return (
      <this.border>
        <Table
          scroll={{ x: true, y: 100 }}
          columns={this.getColumns()}
          dataSource={this.getTableData()}
          onChange={this.handleChange}
          pagination={{ ...paginationConfig, total: totalSize }}
        />
      </this.border>
    );
  }
}
