// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import { CropCategory, ResourceList, ResourceListRequest } from "../../models/germplasm-database";
const { Option } = Select;
const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

type P = FormComponentProps & {
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 搜索 */
  onTopSearch(data: any): void;
  /** load form */
  onLoad(form: FormComponentProps['form'] ): void;
};

interface S {
  /** 作物分类类型 */
  cropType?: string;
}

/**
 * 高级搜索
 * @author maoshengtao
 */
class AdvancedSearchForm extends React.Component<P, S> {
  state: S = {

  };

  componentDidMount() {
    const { onLoad, form } = this.props;
    onLoad(form);
  }

  /** search */
  handleSearch = (e: any) => {
    e.preventDefault();
    const { form, onTopSearch } = this.props;
    form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (err) {
        return;
      }
      onTopSearch(values);
    });
  }

  /** reset */
  handleReset = () => {
    this.props.form.resetFields();
  }

  /** render 作物分类 */
  renderSelect1 = () => {
    const { getFieldDecorator } = this.props.form;
    const { cropCategory } = this.props;
    if (!cropCategory || cropCategory && cropCategory.length === 0) {
      return null;
    }

    return (
      getFieldDecorator(`作物分类`, {
        initialValue: cropCategory[0].name,
      })(
        <Select
        >
          {cropCategory.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>
      )
    );
  }

  /** render 二类名称 */
  renderSelect2 = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { cropCategory } = this.props;
    if (!cropCategory || cropCategory && cropCategory.length === 0) {
      return null;
    }
    const selectdata = cropCategory.find((item, index) => item.name === getFieldValue("作物分类")) || cropCategory[0];
    if (!selectdata.children || selectdata && selectdata.children.length === 0) {
      return (<Select key={"无值"} placeholder="无二级分类" disabled />);
    }
    return (
      getFieldDecorator(`二类名称`, {
        initialValue: selectdata.children[0].name,
      })(
        <Select
        >
          {selectdata.children.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>
      )
    );
  }

  /** render 入库年份 */
  renderSelect3 = () => {
    const { getFieldDecorator } = this.props.form;
    const { storageYears } = this.props;
    if (!storageYears || storageYears && storageYears.length === 0) {
      return null;
    }

    return (
      getFieldDecorator(`入库年份`, {
        // initialValue: storageYears[0],
      })(
        <Select
          placeholder={"请选择"}
        >
          {storageYears.map((item) => {
            return <Option key={item} value={item}>{item}</Option>;
          })}
        </Select>
      )
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { storageYears } = this.props;
    return (
      <>
        <WrapForm onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col span={8} >
              <Form.Item label={`种质编号`} >
                {getFieldDecorator(`种质编号`)(<Input placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`天赋河套种质库编号`} >
                {getFieldDecorator(`天赋河套种质库编号`)(<Input placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`作物分类`} >
                {this.renderSelect1()}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`二类名称`} >
                {this.renderSelect2()}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`种质名称`} >
              {getFieldDecorator(`种质名称`)(<Input placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`是否为区域特色种质`} >
                {getFieldDecorator(`是否为区域特色种质`, {
                })(
                  <Select
                    placeholder="请选择"
                  >
                    <Option key={"是"} value={1}>{"是"}</Option>
                    <Option key={"是"} value={0}>{"否"}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`选育单位`} >
              {getFieldDecorator(`选育单位`)(<Input placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`入库年份`} >
                {this.renderSelect3()}
              </Form.Item>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button onClick={this.handleReset}>
                重置
              </Button>
              <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">
                搜索
              </Button>
            </Col>
          </Row>
        </WrapForm>
      </>
    );
  }
}

export const LeftModule1 = Form.create<P>({ name: "advanced_search" })(AdvancedSearchForm);