// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Button, Cascader, Form, message, Modal, Upload } from "antd";
import { PageBody } from "./styled";
import { Card1 as Card, ITable, RadarChart } from "@gago/frame";

import { HeaderBox } from "../../containers/header-box";
import { Card1 } from "../../containers/card1";
import { LeftModule1 } from "./left-module1";
import { LeftModule2 } from "./left-module2";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadChangeParam, RcFile  } from "antd/lib/upload";

// import blue from "./img/blue.png";
// import green from "./img/green.png";
// import yellow from "./img/yellow.png";
import { chartStyle3 as chartStyle, colorPalette } from "../../color-palette";
import { getDataS1N1, getDataS2N1 } from "../../utils/example-data";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { CropPercentChart1 } from "@gago/lib/components/crop-percent-chart-1";
import { AddSample} from "../../containers/add-sample";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import LandDatabaseProvider from "../../network/providers/land-database.provider";
import ModalForm from "@gago/lib/components/template-modal-form"
import { Region } from "../../models/common";
import { 
  RegionTree,
  SamplePoint,
  LandReport,
  InfoItem
} from "../../models/land-evalute";
import LandEvaluateProvider from "../../network/providers/land-evaluate";
// tslint:disable-next-line: ter-max-len
import { importResource, CropCategory, RegionSummary, ResourceList, ResourceSummary, ResourceTrend, CategorySummary, ResourceListRequest, ResourctFindOne,  TemplateRequest} from "../../models/germplasm-database";
import { SampleList, SampleListRequest } from "../../models/land-database";
import { cloneDeep, round, sumBy } from "lodash";
import { RankingList } from "@gago/lib/components/ranking-list";
import { PaginationConfig } from "antd/lib/table";
import { FormComponentProps } from "antd/lib/form";
const noop = () => {};
const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", ""],
  data: [],
  suffix: ["", "", "", "份"],
  prefix: ["", "", "", ""],
};

const dataS1N1: ITable<1, 1> = {
  title: "",
  subtitle: "总种类",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", ""],
  data: [],
  suffix: ["", "", "类"],
  prefix: ["", "", ""],
};
type Query = { value:string}
interface S {
  /** 对话框是否可见 */
  visible: boolean;
  /** 种质资源库汇总 */
  resourceSummary?: ResourceSummary;
  /** 种质资源入库趋势 */
  resourceTrend?: ResourceTrend[];
  /** 种质类型数量统计 */
  categorySummary?: CategorySummary[];
  /** 黄河流域各省份入库种质资源统计 */
  regionSummary?: RegionSummary[];
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 种质数据列表 */
  resourceList?: ResourceList;
  /** 顶部数据搜索 */
  topSearch?: any;
  /** 单个种质数据 */
  resourctFindOne?: ResourctFindOne;
  /** 当前页数 */
  pagination?: PaginationConfig;
  // 导入导出弹框是否可见
  isShow:boolean;
  // 上传文件的url地址
  uploadUrl:string;
  // 导入导出下载模板请求所需参数
  requestKeys:string[];
  // 选择的数据行数
  selectRowKeysCopy?:number[];
  // openType ---> 下载模板：download 导入：import 导出：exoprt
  openType:string;
  //  手动上传url
  handleUploadUrl:string;
  /** 顶部卡片类型 */
  cardType?: number;
  /** 顶部搜索表单 */
  topForm?: FormComponentProps["form"];
  /**样点列表 */
  sampleList:SampleList;
  /**列表请求 */
  listRequest: SampleListRequest;
  /**土类 */
  soilGroup?:Query[];
  // 亚土类
  subclasses?:Query[];
  /**土属 */
  soilGenus?:Query[];
  /** 土种*/
  soilSpecies?:Query[]
  /** 作物种类*/
  cropName?:Query[];
  areaList:RegionTree[];
}

/**
 * 种质资源数据库
 * @author maoshengtao
 */
export class LandDatabase extends React.PureComponent<{}, S> {

  state: S = {
    visible: false,
    topSearch: {},
    pagination: {},
    isShow: false,
    requestKeys:["oneLevelId","twoLevelId"],
    uploadUrl:"",
    openType:"download",
    handleUploadUrl:"",
    selectRowKeysCopy:[],
    sampleList:{
      list:[],
      count:0
    },
    listRequest:{
      
      size:10,
      page:0,
    },
    areaList:[]
  };

  componentDidMount() {
    // this.getCropCategory();
    // this.getStorageYears();
    // this.getResourceSummary();
    // this.getResourceTrend();
    // this.getCategorySummary();
    // this.getRegionSummary();
    // 
    this.getAreaList();
    this.getQueryList()
    this.getSampleList();
  }

  /**判断是否是全部 */
  _exitAll = (value:string) => {
    if (value === "全部") {
      return undefined;
    } 
    return value;
  }
  /** top search */
  onTopSearch = (topSearch: any) => {
    /** clear serach */
    // this.handleCardClick(0);
    /* get list */
 
    const { pagination, listRequest } = this.state;
    const { areaList, soilGroup, subclasses, soilGenus, soilSpecies, cropName,...rest} = topSearch;
    let countyName,townName,villageName;
    if (areaList )  {
      const isExitAll = areaList.includes("全部")
      if (isExitAll && areaList.length == 1) {
        countyName = undefined;
      } else if (isExitAll && areaList.length == 2 || !isExitAll && areaList.length == 1) {
        countyName = areaList[0]; 
      } else if (isExitAll && areaList.length == 3 || isExitAll && areaList.length == 2) {
        countyName = areaList[0];
        townName = areaList[1]
      } else if (!isExitAll && areaList.length == 3){
        countyName = areaList[0];
        townName = areaList[1]
        villageName = areaList[2]
      }
    }
    const requestParams:SampleListRequest = {
      ...listRequest,
      soilGenus:this._exitAll(soilGenus),
      soilGroup:this._exitAll(soilGroup),
      soilSpecies:this._exitAll(soilSpecies),
      cropName:this._exitAll(cropName),
      subclasses:this._exitAll(subclasses),
      ...rest,
      countyName,
      townName,
      villageName,
      page:0,
    }
    console.log(topSearch,"topSearch")
    this.setState({ listRequest:requestParams,pagination:{...pagination,current:0} }, () => {
      this.getSampleList();
    });
  }
  getAreaList = async () => {
    const areaList = await LandEvaluateProvider.getRegionTree();
    const areaAll:RegionTree = {
      name:"全部",
      value: -1,
      id:-1,
      // children:[],
      code:"-1",
      parentName:"",
      parentCode:"",
      bbox:[0,0,0,0],
      xmax:0,
      xmin:0,
      ymin:0,
      ymax:0
    }
    areaList.forEach(item => {
      if (item.children && item.children.length !== 0) {
        item.children.unshift(areaAll)
        item.children.forEach(town => {
          town.children?.unshift(areaAll)
        })
      }
    })
    areaList.unshift(areaAll)
    this.setState({areaList})
  } 
  /** get 作物分类 */
  getCropCategory = async () => {
    const cropCategory = await GermplasmDatabaseProvider.getCropCategory();
    /** 第一次加载列表项 */
    if (cropCategory && cropCategory.length > 0) {
      this.setState({
        topSearch: {
          oneLevel: cropCategory[0].name,
          twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        }
       })
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    this.setState({ cropCategory });
  }
  /**获取样点列表 */
  getSampleList = async () => {
    const { listRequest } = this.state;
    const sampleList =  await LandDatabaseProvider.getSampleList(listRequest)
    this.setState({sampleList})
  }
  /** get 入库年份 */
  getStorageYears = async () => {
    const storageYears = await GermplasmDatabaseProvider.getStorageYears();
    this.setState({ storageYears });
  }

  /** get 种质数据列表 */
  getResourceList = async (req: ResourceListRequest) => {
    const params: ResourceListRequest = {
      ...req,
      oneLevel: req.oneLevel === "全部" ? undefined : req.oneLevel,
      twoLevel: req.twoLevel === "全部" ? undefined : req.twoLevel,
    }
    GermplasmDatabaseProvider.getResourceList(params).then((resourceList) => {
      this.setState({ resourceList });
    }).catch((err) => {
      message.error("获取列表失败");
    });
  }

  /** get 种质资源库汇总 */
  getResourceSummary = async () => {
    const resourceSummary = await GermplasmDatabaseProvider.getResourceSummary();
    this.setState({ resourceSummary });
  }

  /** get 种质资源入库趋势 */
  getResourceTrend = async () => {
    const resourceTrend = await GermplasmDatabaseProvider.getResourceTrend();
    this.setState({ resourceTrend });
  }

  /** get 种质类型数量统计 */
  getCategorySummary = async () => {
    const categorySummary = await GermplasmDatabaseProvider.getCategorySummary();
    this.setState({ categorySummary });
  }

  /** get 黄河流域各省份入库种质资源统计 */
  getRegionSummary = async () => {
    const regionSummary = await GermplasmDatabaseProvider.getRegionSummary();
    this.setState({ regionSummary });
  }

  /** 获取单个种质资源信息 */
  getResourctFindOne = async (accessionNumber: string) => {
    const resourctFindOne = await GermplasmDatabaseProvider.getResourctFindOne(accessionNumber);
    this.setState({ resourctFindOne });
  }
  /**获取查询条件 */
  getQueryList= async () => {
    const soilGroup = await LandDatabaseProvider.getQueryList({type:"soilGroup"});
    const subclasses = await LandDatabaseProvider.getQueryList({type:"subclasses"});
    const soilGenus = await LandDatabaseProvider.getQueryList({type:"soilGenus"});
    const soilSpecies = await LandDatabaseProvider.getQueryList({type:"soilSpecies"});
    const cropName = await LandDatabaseProvider.getQueryList({type:"cropName"});
    this.setState({soilGenus,soilGroup,soilSpecies,subclasses, cropName})
    console.log(soilGroup,"soilGroup")
  }

  /** 删除种质资源数据 */
  resourceDel = async (id: number) => {
    LandDatabaseProvider.delSample(id).then((data) => {
      if (data && data.code === 200) {
        message.success("删除成功");
        const { topSearch, pagination } = this.state;
        /** get list  */
        this.getSampleList();
      } else {
        message.error("删除失败");
      }
    }).catch((err) => {
      message.error("删除失败");
    });
  }

  /** 处理分页编号请求 */
  handlePagination = (pagination: PaginationConfig) => {
    if (pagination && pagination.current) {
      const { topSearch,listRequest } = this.state;
      this.setState({ listRequest:{...listRequest,page:pagination.current,size:pagination.pageSize || 10}, pagination },()=> this.getSampleList());
    }
  }

  // 获取表单的selectRowKeys
  handleSelectRowKeys = (params:number[]) => {
    console.log(params,"parmasss")
    this.setState({
      selectRowKeysCopy:params
    })
  }

  // 处理导入导出弹框是否开启
  handleModalShow = (type:string)  => {
    this.setState({
      isShow:true,
      openType:type
    })
  }

  // 关闭导入导出弹框
  handleCancelModal = () => {
    this.setState({
      isShow:false,
      handleUploadUrl: ""
    })
  }


  // 处理导出操作
  handleExport = () => {
    const { selectRowKeysCopy, resourceList,sampleList  } = this.state;
    let ids:number[] = [];
    console.log(selectRowKeysCopy,"//---")
    const token = window.sessionStorage.getItem("token") as string;
    if (selectRowKeysCopy && selectRowKeysCopy.length === 0) {
      return  message.info("您还没有选择导出的数据！");
    } else {
      selectRowKeysCopy && selectRowKeysCopy.forEach((item:number) => {
        if (sampleList &&  sampleList.list && sampleList.list.length > 0) {
          // const ind:any = resourceList.list.findIndex(resource => resource.accessionNumber == item)
          ids.push(sampleList.list[item as any].id)
        }
      })
      const idString:string = ids.join(",");
      // window.location.href =`http://mc-bameng.fm-dev.gagogroup.cn:30549/api/v1/plant/resource/export?ids=${idString}&token=${token}`
      LandDatabaseProvider.download(`/plant/soil/export?ids=${idString}`);
    }
  }

  // 处理导入确定
  handleImport = async (params:importResource) => {
    await GermplasmDatabaseProvider.importGermplasmDatabase(params).then(data => {
      if (data.code == 200) {
        message.success("导入成功，请稍后刷新查看最新数据");
      }
    }).catch(err => {
      message.error("操作失败");
    })
    
    this.handleCancelModal();
  
  }

  // 下载模板/导入/导出 点击确定的函数
  handleOk = (params:any) => {
    const {openType } = this.state;
    if ( openType === "Download") {
      return this.handleDownload();
    } else if (openType === "Import") {
      return this.handleImport(params);
    } 
  }

  handleUploadExport = (data:UploadChangeParam<UploadFile<any>>) => {
    console.log("times" )
    if (data.file.status === "done" && data.file.response.data.code == 200) {
      message.success("文件上传中，请稍后刷新页面查看最新数据")
    } else if (data.file.status === "done" && data.file.response.data.code != 200){
      message.error("操作失败")
    }
  }
  render() {
    const {  resourceList, cropCategory, storageYears, uploadUrl, requestKeys, pagination, sampleList, soilGenus, soilGroup, soilSpecies, subclasses, cropName, areaList } = this.state;
    return(
      <HeaderBox
        names={["土地数据管理"]}
      >
        <PageBody>

          <div className="page-left">
            {/* <div className="top-card-list">
              {this.renderTopCardList()}
            </div> */}
            <div className="page-left-bottom">
              <div className="search-module">
                <LeftModule1
                  areaList={areaList}
                  soilGenus={soilGenus}
                  soilSpecies={soilSpecies}
                  soilGroup={soilGroup}
                  subclasses={subclasses}
                  cropName={cropName}
                  onTopSearch={this.onTopSearch}
                  cropCategory={cropCategory}
                  storageYears={storageYears}
                  onLoad={this.topFormLoad}
                />
              </div>
              {this.renderButtonList()}
              <div className="table-module">
                <LeftModule2
                  
                  resourceList={sampleList}
                  resourceDel={this.resourceDel}
                  handleSelectRowKeys={this.handleSelectRowKeys}
                  handlePagination={this.handlePagination}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
          {/* <div className="page-right">
            {this.renderRightCard1()}
            {this.renderRightCard2()}
            {this.renderRightCard3()}
          </div> */}
          {this.renderAddOverlay()}
          {/* <ModalForm
            visible={this.state.isShow}
            cropCategory={cropCategory}
            uploadUrl={uploadUrl}
            requestKeys={requestKeys}
            onOk={this.handleOk}
            onCancl={this.handleCancelModal}
            isUseCropCate={false}
            handleUploadUrl={this.state.handleUploadUrl}
          /> */}
        </PageBody>
      </HeaderBox>
    );
  }

  // 打开模板下载弹窗
  handleDownload = async () => {
    const data = await LandDatabaseProvider.downloadModule();
    window.location.href = data;
    this.handleCancelModal();
  }
  /** handle overlay open */
  handleAddClick = () => {
    this.setState({ visible: true });
  }

  /** handle overlay close*/
  handleAddCancle = (isAdd?: string | number) => {
    this.setState({ visible: false });
    if (isAdd) {
      const { topSearch, pagination } = this.state;
      this.getSampleList();
      // this.getResourceList({
      //   oneLevel: topSearch.作物分类,
      //   twoLevel: topSearch.二类名称,
      //   year: topSearch.入库年份,
      //   accessionNumber: topSearch.种质编号,
      //   genebankNumber: topSearch.天赋河套种质库编号,
      //   accessionName: topSearch.种质名称,
      //   departmentName: topSearch.选育单位,
      //   isTs: topSearch.是否为区域特色种质,
      //   size: 10,
      //   page: pagination?.current || 1,
      // });
      // this.getResourceSummary();
    }
  }

  renderAddOverlay = () => {
    const { visible, cropCategory, storageYears } = this.state;
    return (
      <AddSample
        key={`${visible}`}
        visible={visible}
        handleCancel={this.handleAddCancle}
        title={"新增样点数据"}
        // cropCategory={cropCategory}
        // storageYears={storageYears}
      />
    );
  }

  renderRightCard1 = () => {
    const { resourceTrend } = this.state;
    if (!resourceTrend) {
      return null;
    }
    const chartData = cloneDeep(dataS2N1);
    chartData.data = resourceTrend.map((item) => {
      return [`${item.year}`, "种质入库趋势", `${item.year}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"种质入库趋势"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
        <AxisChart
          chartData={chartData}
          colorPalette={{ ...colorPalette, subColor: ["#f78011", ...colorPalette.subColor] }}
          basicConfigOption={{
            seriesType: "line",
            lineSmooth: true,
          }}
        />
        </div>
      </Card>
    );
  }

  renderRightCard2 = () => {
    const { categorySummary } = this.state;
    if (!categorySummary) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = categorySummary.map((item) => {
      return [`${item.category}`, `${item.category}`, round(item.count, 2)];
    });
    chartData.title = `${sumBy(chartData.data, (item) => item[2])}`;
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"种质类型数量统计"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
          <CropPercentChart1
            theme="horizontal"
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#2970ff", "#00aaff", "#70e2ff", "#ffb300", "#ff5275", ...colorPalette.subColor],
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard3 = () => {
    const { regionSummary } = this.state;
    if (!regionSummary) {
      return null;
    }
    const chartData = cloneDeep(dataS2N1);
    chartData.data = regionSummary.map((item) => {
      return [`${item.region}`, "黄河流域入库种质资源统计", `${item.region}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"黄河流域各省入库种质资源统计"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
        <AxisChart
          chartData={chartData}
          colorPalette={{ ...colorPalette, subColor: ["#1eb0bb", ...colorPalette.subColor] }}
        />
        </div>
      </Card>
    );
  }

  renderButtonList = () => {
    const token = window.sessionStorage.getItem("token") as string;
    return (
      <div className="button-list">
        <div className="button-list-left">
          <Button style={{ margin: "0px 10px" }} onClick={this.handleAddClick}>
              新增
          </Button>
          <Button type="primary" onClick={ this.handleDownload}>
              下载导入模板
          </Button>
        </div>
        <div className="button-list-right">

            <Upload
              name = "excel"
              action={`${window.config.publisher}/api/v1/plant/soil/upload`}
              headers={{ token }}
              showUploadList={false}

              onChange={ this.handleUploadExport}
            >
            <Button
              icon="download"
            >
                导入
            </Button>
          </Upload>
          <Button
            icon="export"
            onClick={this.handleExport}
            style={{ margin: "0px 10px" }}
          >
              导出
          </Button>
          </div>

        </div>
     
    );
  }

  /** top form load */
  topFormLoad = (form: FormComponentProps["form"]) => {
    this.setState({ topForm: form });
  }

  /** handle clear top search */
  handleClearTopSearch = () => {
    const { topSearch, topForm } = this.state;
    this.setState({ topSearch: {} });
    topForm && topForm.resetFields();
  }

  /** handle top Card click */
  handleCardClick = (n: number) => {
    if (n === this.state.cardType) {
      return;
    }

    const { cropCategory } = this.state;
    if (n === 1 && cropCategory) {
      this.handleClearTopSearch();
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    if (n === 2 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据天赋河套编号是否为空筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        isExistGenebankNumber: 1,
      });
    }

    if (n === 3 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据是否为区域特色筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        isTs: 1,
      });
    }

    this.setState({ cardType: n });
  }

  /** render 顶部卡片列表 */
  // renderTopCardList = () => {
  //   const { resourceSummary, cardType } = this.state;
  //   if (!resourceSummary) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       {
  //         [
  //           {
  //             title: resourceSummary && resourceSummary.germplasmCount || 0,
  //             suffix: "(条)",
  //             description: "入库编目种质资源数据",
  //             img: green,
  //             type: 1,
  //           },
  //           {
  //             title: resourceSummary && resourceSummary.inventoryCount || 0,
  //             suffix: "(份)",
  //             description: "入库保存种质资源数量",
  //             img: yellow,
  //             type: 2,
  //           },
  //           {
  //             title: resourceSummary && resourceSummary.featureCount || 0,
  //             suffix: "(种份)",
  //             description: "区域特色种质资源数量",
  //             img: blue,
  //             type: 3,
  //           },
  //         ].map((item) => {
  //           return (
  //             <Card1
  //               active={cardType === item.type}
  //               key={item.title}
  //               title={item.title}
  //               suffix={item.suffix}
  //               description={item.description}
  //               img={item.img}
  //               onClick={this.handleCardClick.bind(this, item.type)}
  //             />
  //           );
  //         })
  //       }
  //     </>
  //   );
  // }

}
