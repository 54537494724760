import React from "react";
import { ColorProps, ICSS, ICSSProps, Color } from "@gago/frame/es/interface";
import { GlobalColorPalette } from "../../color-palette";
import styled from "styled-components";
import pointer from "./img/pointer.svg";

export type ScaleListType = string | number;

export interface AxisDiagramOutProps {
  /** 标题 */
  title: string;
  /** 单位 */
  unit: string;
  /**
   * 值的范围
   * range[0] 对应最小值
   * range[1] 对应最大值
   * */
  range: [number, number];
  /**
   * 适宜的范围值
   * suitableRange[0] 适宜的最小值
   * suitableRange[1] 适宜的最大值
   */
  suitableRange?: [number, number];
  /** 刻度数组 */
  scaleList: ScaleListType[];
  /** 当前实际值 */
  value: number;
}

export type AxisDiagramProps = AxisDiagramOutProps & ColorProps<GlobalColorPalette> & ICSSProps;

interface AxisDiagramState {}

/**
 * 展示适度范围的数轴图
 * @author luyaxiang
 * @date 2019.6.3
 * @class AxisDiagram
 * @extends {React.Component<AxisDiagramProps, AxisDiagramState>}
 * @example
 * <AxisDiagram
 *  colorPalette={colorPalette}
 *  title="坡向"
 *  unit="单位"
 *  scaleList={[0, 5, 10, 15, 20]}
 *  range={[0, 20]}
 *  suitableRange={[5, 17.5]}
 *  value={5}
 *  />
 */
export class AxisDiagram extends React.Component<AxisDiagramProps, AxisDiagramState> implements ICSS, Color {
  static defaultProps = {
    title: undefined,
    unit: undefined,
    range: [0, 1],
    suitableRange: undefined,
    style: {},
    scaleList: undefined,
    value: undefined,
  };

  border = styled.div<{ colorPalette: GlobalColorPalette }>`
    width: 100%;
    height: 100px;
    padding: 0 8px;
    background-color: rgba(0, 21, 23, 0.02);

    .axis_diagram_header {
      width: 100%;
      position: relative;
      display: flex;
      height: 30px;
      align-items: center;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 10px;
        background-color: transparent;
        border-radius: 4px;
      }

      .axis_diagram_header_title {
        font-size: 16px;
        color: rgba(0, 20, 23, 0.9);
      }

      .axis_diagram_header_unit {
        font-size: 14px;
        color: rgba(0, 20, 23, 0.45);
        margin-left: 4px;
      }
    }

    .axis_diagram_content {
      width: 100%;
      height: 12px;
      overflow: visible;
      border-bottom: solid 1px ${p => p.colorPalette.blackColor[5]};
      display: flex;
      align-items: flex-end;
      padding-bottom: 2px;
      position: relative;

      .axis_diagram_content_scale_border {
        flex: 1;
        display: flex;
        align-items: flex-end;
      }

      .axis_diagram_content_suitable {
        position: absolute;
        height: 10px;
        background-color: rgba(255, 222, 0, 0.25);
        bottom: 0;
        left: 0;
        right: 0;
      }

      .axis_diagram_content_value {
        position: absolute;
        bottom: 0;
        left: 50%;
        bottom: 2px;
        width: 1px;
        height: 11px;
        border-radius: 1px;
        background-color: #e63256;

        .axis_diagram_content_value_triangle {
          position: absolute;
          top: -15px;
          right: 50%;
          background-color: #e63256;
          text-align: left;
        }
        .axis_diagram_content_value_triangle:before,
        .axis_diagram_content_value_triangle:after {
          content: '';
          position: absolute;
          background-color: inherit;
        }
        .axis_diagram_content_value_triangle,
        .axis_diagram_content_value_triangle:before,
        .axis_diagram_content_value_triangle:after {
          width:  6px;
          height: 6px;
          border-top-right-radius: 30%;
        }

        .axis_diagram_content_value_triangle {
          transform: rotate(240deg) skewX(-30deg) scale(1,.866) translateX(-60%);
        }
        .axis_diagram_content_value_triangle:before {
          transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
        }
        .axis_diagram_content_value_triangle:after {
          transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
        }
      }
    }

    .axis_diagram_footer {
      width: 100%;
      display: flex;
      color: rgba(0, 20, 23, 0.7);
      font-size: 14px;
      position: relative;

      .axis_diagram_footer_content {
        flex: 1;

        span {
          transform: translateX(-50%);
          display: inline-block;
        }
      }
    }
  `;

  /** 单个范围内刻度 */
  renderOneGroupScale = () => {
    return Array(10).fill(null).map((value: null, index: number) => {
      const style: React.CSSProperties = {
        width: 0,
        border: 0,
        height: 2,
        borderLeft: "1px rgba(0, 20, 23, 0.45) solid",
        flex: 1,
      };
      if ((index % 5) === 0) {
        style.height = 5;
      }
      return (
        <div key={`axis_diagram_content_scale${index}`} style={style}/>
      );
    });
  }

  /** 渲染刻度线 */
  renderScale = () => {
    const { scaleList } = this.props;
    if (scaleList === undefined) {
      return this.renderOneGroupScale();
    }
    return scaleList.map((value: ScaleListType, index: number) => {
      const style: React.CSSProperties = {
        width: 0,
        border: 0,
        height: 5,
        borderLeft: "1px rgba(0, 20, 23, 0.45) solid",
      };
      if (index === (scaleList.length - 1)) {
        return <div key={`axis_diagram_content_scale_border${index}`} style={style}/>;
      }
      return (
        <div
          key={`axis_diagram_content_scale_border${index}`}
          className="axis_diagram_content_scale_border"
        >
          {this.renderOneGroupScale()}
        </div>
      );
    });
  }

  /** 渲染适宜的范围值对应的区域 */
  renderSuitableRange = () => {
    const { range, suitableRange } = this.props;
    if (suitableRange === undefined) {
      return null;
    }
    const d = Math.abs(range[1] - range[0]);
    const left = (suitableRange[0] - range[0]) / d * 100;
    const right = (range[1] - suitableRange[1]) / d * 100;
    const style: React.CSSProperties = {
      left: `${left < 0 ? 0 : left}%`,
      right: `${right > 100 ? 100 : right}%`,
    };
    return (
      <div
        style={style}
        className="axis_diagram_content_suitable"
      />
    );
  }

  /** 渲染当值对应的刻度 */
  renderValueScale = () => {
    const { value, range } = this.props;
    if (value === undefined) {
      return null;
    }
    const d = Math.abs(range[1] - range[0]);
    const left = (value - range[0]) / d * 100;
    return (
      <img src={pointer} style={{ left: `${left < 0 ? 0 : left}%`, position: "absolute", transform: "translateX(-50%)" }}/>);
  }

  /** 渲染刻度值 */
  renderScaleList = () => {
    const { scaleList } = this.props;
    if (scaleList === undefined) {
      return null;
    }
    return scaleList.map((value: ScaleListType, index: number) => {
      const style: React.CSSProperties = {};
      const spanStyle: React.CSSProperties = {};
      if (index === (scaleList.length - 1)) {
        style.flex = "none";
        style.position = "absolute";
        style.right = 0;
        style.transform = "translateX(0%)";
        spanStyle.transform = "none";
      }
      if (index === 0) {
        spanStyle.transform = "none";
      }
      return (
        <div
          className="axis_diagram_footer_content"
          key={`axis_diagram_footer_content${value}${index}`}
          style={style}
        >
          <span style={spanStyle}>{value}</span>
        </div>);
    });
  }

  render() {
    const { title, unit, range, suitableRange, colorPalette, style } = this.props;
    return (
      <this.border colorPalette={colorPalette} style={style}>
        <div className="axis_diagram_header">
          <span className="axis_diagram_header_title">{title}</span>
          <span className="axis_diagram_header_unit">{unit ? `(${unit})` : ""}</span>
        </div>
        <div className="axis_diagram_content">
          {this.renderScale()}
          {this.renderSuitableRange()}
          {this.renderValueScale()}
        </div>
        <div className="axis_diagram_footer">{this.renderScaleList()}</div>
      </this.border>);
  }
}
