import React, {Component} from "react";
import {observer} from "mobx-react";
import {Modal, Form, Input, Select, Row, Col, Icon, Upload, message} from "antd";
import {ModalProps} from "antd/lib/modal";
import {FormComponentProps} from "antd/lib/form";

import FormSelect from "@gago/lib/components/form-select";
import {Partial} from "../../../utils/type";
import {RcFile, UploadChangeParam} from "antd/lib/upload";
import {UploadFile} from "antd/lib/upload/interface";
import {ExpertCateInfo} from "../../../models/expert-database";
import {action, observable} from "mobx";
import {WrappedFormUtils} from "antd/lib/form/Form";

export interface FingerprintData {
    id?: number;
    pzmc: string;//描述
    pcid: string;//品种编号
    cjid: string;//采集id
    tpdz: string;//图片地址
    zt: string;//附件
    tpcl: string;//图片处理json
    dnaxl: string;//dna序列
    remarks: string;//备注
    createDate?: string;
    updateDate?: string;
}

const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};

interface Props extends ModalProps, Partial<FormComponentProps> {
    data: FingerprintData;

    submitForm(values: FingerprintData): void;

    edit: boolean;
}

// @ts-ignore
@Form.create()
@observer
export class FingerprintDataModalComponent extends Component<Props> {


    private submitForm = () => {
        const {submitForm, form, data} = this.props;
        if (!form) return;
        form.validateFields((err: any) => {
            if (!err) {
                form.validateFields((error: any, values: FingerprintData) => {
                    if (error) {
                        return;
                    }
                    if (data.id) {
                        values.id = data.id
                    }
                    submitForm({...values});
                });
                form.resetFields();
            }
        });
    };

    handleUploadBefore = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("请选择图片！");
        }
        // const isLt2M = file.size / 1024 /1024 < 2;
        // if (!isLt2M) {
        //   message.error('图片大于2M');
        // }
        return isJpgOrPng;
    };

    /**
     * 返回
     * @private
     * @param {*} e
     * @memberof UserModalComponent
     */
    private clickCancel = (e: any) => {
        const {onCancel, form} = this.props;
        onCancel && onCancel(e);
        // @ts-ignore
        form.resetFields();
    };

    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await this.handleGetBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    // 生成预览图片
    handleGetBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    uploadChange = (res: UploadChangeParam<UploadFile<any>>, key: string) => {

        this.setState({fileList: [...res.fileList]});

    };

    render() {
        const {title, visible, confirmLoading, data, edit} = this.props;
        const token = window.sessionStorage.getItem("RouYiToken") as string;
        const header = {
            "Authorization": token
        };
        const {getFieldDecorator} = this.props.form as WrappedFormUtils<any>;
        // @ts-ignore
        // const departmentData = UserManageStore.departments.map(item => ({
        //     key: item.departmentId,
        //     value: item.departmentName
        // }));

        // tslint:disable jsx-no-multiline-js
        return (
            <Modal
                width={"60%"}
                title={title}
                visible={visible}
                onCancel={this.clickCancel}
                onOk={this.submitForm}
                confirmLoading={confirmLoading}
            >
                <Form style={{margin: "10px"}}>
                    <Form.Item label="品种名称" {...formItemLayout}>
                        {getFieldDecorator("pzmc", {initialValue: data.pzmc,})(<Input placeholder={"请输入品种名称"}/>)}
                    </Form.Item>
                    <Form.Item label="品种编号" {...formItemLayout}>
                        {getFieldDecorator("pcid", {initialValue: data.pcid,})(<Input placeholder={"请输入品种名称"}/>)}
                    </Form.Item>
                    <Form.Item label="采集" {...formItemLayout}>
                        {getFieldDecorator("cjid", {initialValue: data.cjid,})(<Input placeholder={"请输入采集"}/>)}
                    </Form.Item>
                    <Form.Item label="图片地址" {...formItemLayout}>
                        {getFieldDecorator("tpdz", {initialValue: data.tpdz,})(<Input placeholder={"请输入图片地址"}/>)}
                    </Form.Item>
                    <Form.Item label="状态" {...formItemLayout}>
                        {getFieldDecorator("zt", {initialValue: data.zt,})(<Input placeholder={"请输入工具链接"}/>)}
                    </Form.Item>
                    <Form.Item label="图片处理json" {...formItemLayout}>
                        {getFieldDecorator("tpcl", {initialValue: data.tpcl,})(<Input placeholder={"请输入图片处理json"}/>)}
                    </Form.Item>
                    <Form.Item label="dna序列" {...formItemLayout}>
                        {getFieldDecorator("dnaxl", {initialValue: data.dnaxl,})(<Input placeholder={"请输入dna序列"}/>)}
                    </Form.Item>
                    <Form.Item label="备注" {...formItemLayout}>
                        {getFieldDecorator("remarks", {initialValue: data.remarks,})(<Input placeholder={"请输入备注"}/>)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
