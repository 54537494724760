import styled from "styled-components";

// tslint:disable-next-line:variable-name
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  display:flex;
  .page-left-container {
    flex:1;
    .button-list {
      display:flex;
      justify-content:space-between;
      width:100%;
      margin-bottom:16px;
      .button-list-right {
        display:flex;
      }

    }
    .table-module{
      height: calc(100% - 256px);
    }
    .ant-table-pagination.ant-pagination{
      margin: 16px;
    }
  }

  .page-right-container {
    width:344px;
    overflow-y:auto;
    padding-left:16px;
  }
`;
