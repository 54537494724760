import React, { PureComponent } from "react";
import styled from "styled-components";

import { TableComponent } from "./table/table";
import { TreeComponent } from "./tree/tree";
import UserManageService from "./user-manage.service";
import { observer } from "mobx-react";

// tslint:disable:variable-name
const ContainerWithStyle = styled.div`
  flex: auto;
  display: flex;
  justify-content: center;
  padding: 16px;
`;

@observer
export class UserManage extends PureComponent {

  componentDidMount() {
    UserManageService.getUserAndDepartmentList();
  }

  render() {
    return (
      <ContainerWithStyle>
        <TreeComponent />
        <TableComponent />
      </ContainerWithStyle>
    );
  }
}
