import { ITable } from "@gago/frame";
import { round, flatten, sumBy } from "lodash";
import moment from "moment";

export const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", ""],
  data: [],
  suffix: ["", "", "", ""],
  prefix: ["", "", "", ""],
};

/**
 * 获取指定范围的随机值
 * start: 开始值
 * end: 结束值
 * precision：有效数字位数
 * */
export const getRangeRandom = (start: number = 0, end: number = 1, precision: number = 2) => {
  if (isNaN(start) || isNaN(end)) {
    return Math.random();
  }
  return round(Math.random() * Math.abs(start - end) + Math.min(start, end), precision);
};

/**
 * 获取ITable<2, 1>的样例数据
 *
 * @export
 * @param {("region" | "month")} type
 * @param {string[]} series
 * @param {string} [head="数量："]
 * @param {string} [suffix="万头"]
 * @returns {ITable<2, 1>}
 */
export function getDataS2N1(
  type: "region" | "month",
  series: string[],
  head: string = "单位：",
  suffix: string = "万头",
  random: { start: number, end: number, precision: number } = { start: 0, end: 100, precision: 0 },
): ITable<2, 1> {

  if (type === "region") {
    const region = ["内蒙", "宁夏", "青海", "甘肃", "陕西"];
    const data = flatten(series.map(i1 =>
      region.map<ITable<2, 1>["data"][0]>(i2 => ["", i1, i2, getRangeRandom(random.start, random.end, random.precision)]),
    ));

    return { ...dataS2N1, data, head: ["", "", "", head], suffix: ["", "", ":", suffix] };
  }

  if (type === "month") {
    const month = Number(moment().subtract(1, "month").format("M"));
    const data = flatten(series.map(i1 =>
      Array(month  < 6 ? 6 : month).fill(0).map<ITable<2, 1>["data"][0]>((i2, j2) => ["", i1, `${j2 + 1}月`, getRangeRandom(random.start, random.end, random.precision)]),
    ));

    return { ...dataS2N1, data, head: ["", "", "", head], suffix: ["", "", ":", suffix] };
  }

  return dataS2N1;
}

interface DataS2N12Param {
  /** 类型 */
  type?: string;
  /** 有哪些legend */
  legend: string[];
  /** 头 */
  head: string;
  /** 单位 */
  suffix: string;
  /** 自定义x轴,优先级低于type */
  xAxis?: string[];
}

/**
 * 获取ITable<2, 1>的样例数据
 *
 * @export
 */
export function getDataS2N12(params: DataS2N12Param = {
  legend: [],
  head: "数量",
  suffix: "万头",
}): ITable<2, 1> {
  const { type, head, legend, suffix, xAxis } = params;
  if (type === "region") {
    const region = ["南京市", "无锡市", "徐州市", "常州市", "苏州市", "南通市", "连云港市", "淮安市", "盐城市", "扬州市", "镇江市", "泰州市", "宿迁市"];
    const data = flatten(legend.map(i1 =>
      region.map<ITable<2, 1>["data"][0]>(i2 => ["", i1, i2, round(Math.random() * 100, 2)]),
    ));

    return { ...dataS2N1, data, head: ["", "", "", head], suffix: ["", "", ":", suffix] };
  }

  if (type === "month") {
    const month = Number(moment().subtract(1, "month").format("M"));
    const data = flatten(legend.map(i1 =>
      Array(month).fill(0).map<ITable<2, 1>["data"][0]>((i2, j2) => ["", i1, `${j2 + 1}月`, round(Math.random() * 100, 2)]),
    ));

    return { ...dataS2N1, data, head: ["", "", "", head], suffix: ["", "", ":", suffix] };
  }

  if (xAxis) {
    const data = flatten(legend.map(i1 =>
      xAxis.map<ITable<2, 1>["data"][0]>((i2) => ["", i1, i2, round(Math.random() * 100, 2)]),
    ));

    return { ...dataS2N1, data, head: ["", "", "", head], suffix: ["", "", ":", suffix] };
  }

  return dataS2N1;
}
// console.log(getDataS2N1("region", ["type1", "type1"], "数量：", "万头"));

/** 获取ITable<1, 1>的样例数据 */
export const dataS1N1: ITable<1, 1> = {
  title: "10000",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", ""],
  data: [],
  suffix: ["", "", ""],
  prefix: ["", "", ""],
};

/**
 * 获取ITable<1, 1>的样例数据
 *
 * @export
 * @param {string[]} names
 * @param {string} [subtitle="总数量(万头)"]
 * @param {string} [suffix="万头"]
 * @returns {ITable<1, 1>}
 */
export function getDataS1N1(names: string[], subtitle: string = "总数量(万头)", suffix: string = "万头"): ITable<1, 1> {
  const obj: ITable<1, 1> = {
    ...dataS1N1,
    subtitle,
    data: names.map(i => ["", i, round(Math.random() * 100, 0)]),
    suffix: ["", "", suffix],
  };
  obj.title = `${sumBy(obj.data, (i) => i[2])}`;
  return obj;
}

// tslint:disable-next-line: max-file-line-count
/**
 * 计算给定时间在内的未来七天时间数组
 * 输入：2019-08-09
 * 输出：["2019-08-09", "2019-08-10", "2019-08-11", "2019-08-12", "2019-08-13", "2019-08-14", "2019-08-15"]
 * @author yangyongheng
 * @date 2019-08-09
 * @export
 * @param {(string | moment.Moment | Date)} [date=moment()]
 * @param {number} [step=7]
 * @param {string} [formatter="YYYY-MM-DD"]
 * @returns {string[]}
 */
export function daysInfuture(date: string | moment.Moment | Date = moment(), step: number = 7, formatter: string = "YYYY-MM-DD"): string[] {
  if (!moment(date).isValid()) {
    return Array(step).fill("").map((item: string, index: number) => moment().add(index, "days").format(formatter));
  }
  return Array(step).fill("").map((item: string, index: number) => moment(date).add(index, "days").format(formatter));
}