// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select, DatePicker, Upload, Icon, Modal, Empty } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import bgicon from "./img/bg.png";
import { BaseInfo } from "../../models/land-database";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { get, uniqueId } from "lodash";

const { Option } = Select;



const Wrap = styled.div`
  width: 100%;
  margin-top: 16px;
  .info{
    display: flex;
    align-items: center;
    font-family: PingFangSC;
    /* font-size: 20px; */
    font-weight: 500;
    color: #001617;
    margin-bottom: 6px;
    .info-icon{
      width: 16px;
      height: 16px;
      background-image: url(${bgicon});
      background-size: 100% 100%;
      margin-right: 8px;
    }
    .right-component{
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  border-radius: 4px;
  background-color: rgba(241, 245, 245, 0.45);
  .ant-form-item {
    display: flex;
    margin-bottom: 12px;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

type P = {
  /** title */
  title: React.ReactNode;
  /** 一行几列 一行 24等分 默认 一列为6 即 一行4个*/
  colNum?: number;
  /** data */
  data?: BaseInfo;
  /** 右侧 头部*/
  rightComponent?: React.ReactNode;
  /** 是否为预览模式的多张图片上传 */
  useMultiplePreview?: boolean;
};

interface S {
  /** 预览文件链接*/
  previewFile?: string;
  /** 文件 */
  filelist?: UploadFile<any>[];
  /** 更新文件预览 */
  updatePreviewFile?: UploadFile<any>;
}

/**
 * 生成 表单
 * @author maoshengtao
 */
class GenerateCategory extends React.Component<P, S> {
  state: S = {
    filelist: [],
  }

  componentDidMount() {
    const { data, useMultiplePreview } = this.props;
    const { filelist } = this.state;
    // if (data && data.list) {
    //   data.list.forEach((item) => {
    //     if (item.type === "upload" && useMultiplePreview && filelist && filelist.length === 0) {
    //       if (item.value && item.value.length > 0) {
    //         this.setState({ filelist: item.value.map((u: string) => ({
    //           uid: uniqueId("img"),
    //           name: "图片",
    //           type: "image/png",
    //           status: 'done',
    //           url: u,
    //         })) })
    //       }
    //     }
    //   })
    // }
  }

  /** 生成 form item */
  getFields = () => {
    const { colNum, data } = this.props;
    if (!data || data && data.list && data.list.length === 0) {
      return null;
    }
    return data.list.map((item) => {
      return (
        <Col span={colNum || 6} key={item.eName}>
          <Form.Item label={`${item.name}${item.unit ? `(${item.unit})` : ""}`}>
            {this.renderItemType(item)}
          </Form.Item>
        </Col>
      );
    })
  }

  /** render form item 类型 */
  renderItemType = (item: BaseInfo["list"][number]) => {
    if (item.type === "upload" && this.props.useMultiplePreview) {
      return this.renderMutibleUpload();
   }
    if (item.type === "upload") {
       return (<Button onClick={this.handleOk.bind(this, `${item.value || ""}`)}>
                <Icon type="eye" /> 预览
              </Button>);
    }
    return <Input value={item.value || "——"} disabled />;

}

/** upload change */
uploadChange = (data: UploadChangeParam<UploadFile<any>>) => {
  this.setState({ filelist: [...data.fileList] });
}

renderMutibleUpload = () => {
  const { filelist } = this.state;
  const token = window.sessionStorage.getItem("token") as string;
  return(
    <Upload
        action={`${window.config.publisher}/api/v1/plant/file/upload`}
        headers={{ token }}
        listType="picture-card"
        fileList={filelist}
        onPreview={this.handlePreviewOk}
        onChange={this.uploadChange}
        // multiple
      >
        {null}
        {/* <Icon type="plus"  style={{ fontSize: 48 }} />  */}
      </Upload>
  );
}

/**update preview ok */
handlePreviewOk = (file: UploadFile<any>) => {
  this.setState({
    updatePreviewFile: file,
  });
}

/**update preivew cancle */
handlePreviweCancel = () => {
  this.setState({
    updatePreviewFile: undefined,
  });
}

/** render upload preview */
renderUpdatePreview = () => {
  const { updatePreviewFile } = this.state;
  if (!updatePreviewFile || updatePreviewFile && !updatePreviewFile.type) {
    return null;
  }
  let child = <Empty description="不支持预览"/>;
  const url = updatePreviewFile.url || get(updatePreviewFile, "response.data.data[0].file", undefined)
  if (/(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(updatePreviewFile.type) && url) {
    child = <img src={url} alt={updatePreviewFile.name} style={{ maxWidth: "100%" }}/>;
  }
  if (/pdf$/.test(updatePreviewFile.type) && url) {
    child = <iframe src={url} width="100%" height={660} />;
  }
  return (
    <Modal
      title={`预览 ${updatePreviewFile.name}`}
      visible={!!this.state.updatePreviewFile}
      footer={<Button type={"primary"} onClick={this.handlePreviweCancel} >关闭</Button>}
      onCancel={this.handlePreviweCancel}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        {child}
      </div>
    </Modal>
  );
}

  /** preview ok */
  handleOk = (file: string) => {
    this.setState({
      previewFile: file,
    });
  }

  /** preivew cancle */
  handleCancel = () => {
    this.setState({
      previewFile: undefined,
    });
  }

  /** render upload preview */
  renderPreview = () => {
    const { previewFile } = this.state;
    if (!previewFile) {
      return null;
    }
    let child = <Empty description="不支持预览"/>;
    if (/.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(previewFile)) {
      child = <img src={previewFile} style={{ maxWidth: "100%" }}/>;
    }
    if (/pdf$/.test(previewFile)) {
      child = <iframe src={previewFile} width="100%" height={660} />;
    }
    return (
      <Modal
        title={`预览`}
        visible={!!this.state.previewFile}
        footer={<Button type={"primary"} onClick={this.handleCancel} >关闭</Button>}
        onCancel={this.handleCancel}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {child}
        </div>
      </Modal>
    );
  }

  render() {
    const { title, rightComponent } = this.props;
    return (
      <Wrap>
        <div className="info">
          <span className="info-icon" />
          <span>{title}</span>
          <div className="right-component">
            {rightComponent}
          </div>
        </div>
        {this.renderPreview()}
        {this.renderUpdatePreview()}
        <WrapForm>
          <Row gutter={24}>
            {this.getFields()}
          </Row>
        </WrapForm>
      </Wrap>
    );
  }
}

export { 
  GenerateCategory,
}
