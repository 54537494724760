import { ITable } from "@gago/frame";
import { ResultType, TimelineType, ResultType1 } from "../../models/common";
import {
  BaseRequest,
  BreedSummary,
  BreedGrowth,
  YieldEstimate,
  WeatherWarning,
  DiseaseWarning,
  SoilMonitor,
} from "../../models/breeding-bigscreen";
import BaseRequestStaticServer2, { BaseRequestStaticServer3, BaseRequestStaticServer, BaseRequestStaticServer1 } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";
import { flatten, omit, round, sortBy } from "lodash";
import { AreaDistribution, AreaSummary } from "../../models/breeding-distribute";

/**
 * 繁育大屏
 * @author maoshengtao
 */
export default class BreedingBigscreenProvider extends BaseProvider {
  // tslint:disable-next-line:completed-docs
  static newsever: any = BaseRequestStaticServer2;
  // tslint:disable-next-line:completed-docs
  constructor(param: string) {
    super();
    if (param === "off") {
      BreedingBigscreenProvider.newsever = BaseRequestStaticServer2;
    }
    if (param === "on") {
      BreedingBigscreenProvider.newsever = new BaseRequestStaticServer3();
    }
  }

  /** 长势概况时间轴 */
  static async growthTime() {
    const row = await new BaseRequestStaticServer3().get<ResultType<string[]>>(`/plant/breed/yearList`);
    return row.data;
  }

  /** 请求publish 时间序列 */
  static async getNewPublishTimeline(type?: string) {
    // @ts-ignore
    const row = await BreedingBigscreenProvider.newsever.get<ResultType1<string[]>>(`/system/tbl_seed_breed_time/list?crop=${type}`);
    return row.rows;
  }

  /** 资源概况 */
  static async getBreedSummary(params: BaseRequest) {
    // @ts-ignore
    const { data } = await BreedingBigscreenProvider.newsever.get<ResultType<BreedSummary>>("/plant/breed/summary", { params });
    return data.data;
  }

  /** 长势分析 */
  static async getBreedGrowth(params: BaseRequest) {
    // @ts-ignore
    const { data } = await BreedingBigscreenProvider.newsever.get<ResultType<BreedGrowth[]>>("/plant/breed/growth", { params });
    const dataS2N1: ITable<2, 1> = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["", "", "", ""],
      data: [],
      suffix: ["", "", "", ""],
      prefix: ["", "", "", ""],
    };
    dataS2N1.data = data.data.map((item: any, index: number) => {
      const type = item.type === "average" ? "平均" : "历史";
      return [`${index}`, type, item.date, Number(item.value)];
    });
    return dataS2N1;
  }

  /** 产量预估 */
  static async getYieldEstimate(params: BaseRequest) {
    // @ts-ignore
    const { data } = await BreedingBigscreenProvider.newsever.get<ResultType<YieldEstimate[]>>("/plant/breed/yieldEstimate", { params });

    const chartData: ITable<1, 3> = {
      title: "风险预测",
      subtitle: "",
      description: "",
      range: [[], [], [], [], []],
      head: ["ID", "市县", "总产", "单产", "面积"],
      defaultValue: [0, 0, 0, 0, 0],
      suffix: ["", "", "万吨", "公斤/亩", "亩"],
      data: [],
    };
     // @ts-ignore
    chartData.data = data.data.map<ITable<1, 3>["data"][0]>((item: any) => [item.code, item.name, round(item.totalYield, 2),  round(item.perYield, 2), round(item.area, 2)]);
    return chartData;
  }

  /** 气象预报预警 */
  static async getWeatherWarning(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<WeatherWarning>>("/plant/breed/weatherWarning", { params });
    const chartData: ITable<2, 1> = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["", "", "", ""],
      data: [],
      suffix: ["", "", "", "℃"],
      prefix: ["", "", "", ""],
    };

    let type = "温度";
    if (params) {
      if (params.type === "1" && chartData.suffix) {
        chartData.suffix[3] = "℃";
      }
      if (params.type === "2" && chartData.suffix) {
        type = "湿度";
        chartData.suffix[3] = "%";
      }
      if (params.type === "3" && chartData.suffix) {
        type = "降水";
        chartData.suffix[3] = "mm";
      }
      if (params.type === "4" && chartData.suffix) {
        type = "风速";
        chartData.suffix[3] = "m/s";
      }
    }
    chartData.data = data.list.map((item, index) => {
      if (params && params.type === "1") {
        type = item.type === "high" ? "最高温" : "最低温";
      }

      return [`${index}`, type, item.date, item.value];
    });

    return {
      ...omit(data, "list"),
      chartData,
    };
  }

  /** 病害预警 */
  static async getDiseaseWarning(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<DiseaseWarning[]>>("/plant/breed/diseaseWarning", { params });
    const dataS2N1: ITable<2, 1> = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["", "", "", "单位"],
      data: [],
      suffix: ["", "", "", "%"],
      prefix: ["", "", "", ""],
    };
    dataS2N1.data = flatten(data.map((item, index) => {
      return [
        [`${index}`, "轻度", item.name, item.one * 100],
        [`${index}`, "轻中", item.name, item.two * 100],
        [`${index}`, "中度", item.name, item.three * 100],
        [`${index}`, "中重", item.name, item.four * 100],
        [`${index}`, "重度", item.name, item.five * 100],
      ];
    }));

    // chartData.data = data.map(item => ["", item.type, item.name, item.value])

    return dataS2N1;
  }

  /** 土壤监测 */
  static async getSoilMonitor(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<SoilMonitor[]>>("/plant/breed/soilMonitor", { params });

    const tableData: ITable<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9> = {
      title: "table表格",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0, 0],
      range: [[], [], [], [], []],
      head: ["地区", "PH值", "有机质", "全氮", "有效磷", "速效钾", "土壤容重"],
      data: [],
      suffix: ["", "", "", "", ""],
    };
    tableData.data = data.map((item) => {
      return [item.name || "——", item.ph || "——", item.organic || "——", item.tn || "——", item.ap || "——", item.ak || "——", item.bd || "——"];
    });

    return tableData;
  }

  /** 面积汇总 */
  static async getAreaSummary(params: BaseRequest) {
    // @ts-ignore
    const { data } = await BreedingBigscreenProvider.newsever.get<ResultType<AreaSummary[]>>("/plant/breed/areaSummary", { params });
    const dataS2N1: ITable<2, 1> = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["", "", "", ""],
      data: [],
      suffix: ["", "", "", "万亩"],
      prefix: ["", "", "", ""],
    };
    const tempData = data.data.filter((item: any) => (item.type === "recognition" && item.crop !== "草地"));
    dataS2N1.data = tempData.map((item: any, index: number) => {
      const type = item.type === "recognition" ? "识别面积" : "统计面积";
      return [`${index}`, type, item.crop, round(parseFloat(item.value), 2)];
    });
    {params.isDataManage === "off" && dataS2N1.data.push(["", "识别面积", "辣椒", Number(tempData.filter((item: any) => item.crop === "小麦")[0].value)]); }
    console.log("***data", tempData, dataS2N1);
    return dataS2N1;
  }

  /** 各地区种质面积分布 */
  static async getAreaDistribution(params: BaseRequest) {
    // @ts-ignore
    const { data } = await BreedingBigscreenProvider.newsever.get<ResultType<AreaDistribution[]>>("/plant/breed/areaDistribution", { params });
    const newData = data.data.filter((el: any) => el.value !== "NaN");
    if (newData.length < 1) return undefined;
    const sortData = sortBy(newData, item => -item.value);
    const dataS2N1: ITable<2, 1> = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["", "", "", ""],
      data: [],
      suffix: ["", "", "", "万亩"],
      prefix: ["", "", "", ""],
    };
    dataS2N1.data = sortData.map((item, index) => {
      return [`${index}`, `种质面积分布`, item.name, round(parseFloat(item.value), 2)];
    });
    return dataS2N1;
  }
}

// export default new BreedingBigscreenProvider();
