import { range } from "lodash";
import { AxisDiagramOutProps } from "../../components/axis-diagram";

export type AxisDiagramOutPropsWithLabel = AxisDiagramOutProps & {
  /** 对应的右侧图表的值,用于匹配 */
  label: string;
};


export const getActualValue = (range: [number, number], percentage: number) => {
  return (Math.abs(range[1] - range[0]) * percentage) + Math.min(...range);
};


export const wheatConfig: AxisDiagramOutPropsWithLabel[] = [
  {
    title: "土壤ph",
    label: "土壤ph",
    unit: "",
    range: [5, 10],
    suitableRange: [6.5, 7.5],
    value: 1,
    scaleList: range(5, 10, 1),
  },
  {
    title: "土壤类型",
    label: "土壤类型",
    unit: "",
    range: [0, 100],
    suitableRange: [66, 100],
    value: 50,
    scaleList: ["其它", "风沙土", "盐土", "潮土、新积土、冲积土"],
  },
  {
    title: "年平均温度",
    label: "年平均温度",
    unit: "℃",
    range: [4, 16],
    suitableRange: [10, 10.23],
    value: 30,
    scaleList: range(4, 16, 2),
  },
  {
    title: "≥0℃积温",
    label: "≥0℃积温",
    unit: "℃ d",
    range: [0, 5000],
    suitableRange: [4100, 4292.57],
    value: 900,
    scaleList: range(0, 5000, 1000),
  },
  {
    title: "海拔高度",
    label: "海拔高度",
    unit: "m",
    range: [0, 2500],
    suitableRange: [0, 1000],
    value: 900,
    scaleList: [0, 500, 1000, 1500, 2000, 2500],
  },
  {
    title: "河流距离",
    label: "河流距离",
    unit: "km",
    range: [0, 5],
    suitableRange: [0, 2],
    value: 1,
    scaleList: range(0, 5, 1),
  },
  {
    title: "年累计降水量",
    label: "年累计降水量",
    unit: "mm",
    range: [0, 400],
    suitableRange: [250, 332],
    value: 300,
    scaleList: range(0, 400, 50),
  },
  {
    title: "最冷月均温度",
    label: "最冷月均温",
    unit: "℃",
    range: [2, 10],
    suitableRange: [8, 9.09],
    value: 30,
    scaleList: range(2, 10, 2),
  },
  {
    title: "交通距离",
    label: "交通距离",
    unit: "km",
    range: [0, 5],
    suitableRange: [0, 2],
    value: 1,
    scaleList: range(0, 5, 1),
  },

];

export const sunflowConfig: AxisDiagramOutPropsWithLabel[] = [
  {
    title: "土壤类型",
    label: "土壤类型",
    unit: "",
    range: [0, 100],
    suitableRange: [66, 100],
    value: 50,
    scaleList: ["其它", "风沙土", "盐土", "潮土、新积土、冲积土"],
  },
  {
    title: "土壤ph",
    label: "土壤ph",
    unit: "",
    range: [5, 10],
    suitableRange: [6.5, 7],
    value: 1,
    scaleList: range(5, 10, 1),
  },
  {
    title: "年平均温度",
    label: "年平均温度",
    unit: "mm",
    range: [4, 16],
    suitableRange: [10, 10.23],
    value: 30,
    scaleList: range(4, 16, 2),
  },
  {
    title: "≥10℃积温",
    label: "≥10℃积温",
    unit: "℃ d",
    range: [0, 4500],
    suitableRange: [3800, 4292.57],
    value: 900,
    scaleList: range(0, 4500, 900),
  },
  {
    title: "海拔高度",
    label: "海拔高度",
    unit: "m",
    range: [0, 2500],
    suitableRange: [0, 1000],
    value: 900,
    scaleList: [0, 500, 1000, 1500, 2000, 2500],
  },
  {
    title: "河流距离",
    label: "河流距离",
    unit: "km",
    range: [0, 5],
    suitableRange: [0, 2],
    value: 1,
    scaleList: range(0, 5, 1),
  },
  {
    title: "8月累计降水量",
    label: "8月累计降水量",
    unit: "mm",
    range: [0, 55],
    suitableRange: [35, 45],
    value: 30,
    scaleList: range(0, 55, 5),
  },
  {
    title: "交通距离",
    label: "交通距离",
    unit: "km",
    range: [0, 5],
    suitableRange: [0, 2],
    value: 1,
    scaleList: range(0, 5, 1),
  },
  {
    title: "5月最低气温",
    label: "5月最低气温",
    unit: "mm",
    range: [4, 16],
    suitableRange: [10, 13],
    value: 30,
    scaleList: range(4, 16, 2),
  },
  
];

export const cornConfig: AxisDiagramOutPropsWithLabel[] = [
  {
    title: "土壤类型",
    label: "土壤类型",
    unit: "",
    range: [0, 100],
    suitableRange: [66, 100],
    value: 50,
    scaleList: ["其它", "风沙土", "盐土", "潮土、新积土、冲积土"],
  },
  {
    title: "≥10℃积温",
    label: "≥10℃积温",
    unit: "℃ d",
    range: [0, 4500],
    suitableRange: [3800, 4292.57],
    value: 900,
    scaleList: range(0, 4500, 900),
  },
  {
    title: "年累计降水量",
    label: "年累计降水量",
    unit: "mm",
    range: [0, 400],
    suitableRange: [250, 332],
    value: 300,
    scaleList: range(0, 400, 50),
  },
  {
    title: "土壤ph",
    label: "土壤ph",
    unit: "",
    range: [5, 10],
    suitableRange: [6.5, 7],
    value: 1,
    scaleList: range(5, 10, 1),
  },
 
  {
    title: "河流距离",
    label: "河流距离",
    unit: "km",
    range: [0, 5],
    suitableRange: [0, 2],
    value: 1,
    scaleList: range(0, 5, 1),
  },
  {
    title: "海拔高度",
    label: "海拔高度",
    unit: "m",
    range: [0, 2500],
    suitableRange: [0, 1000],
    value: 900,
    scaleList: [0, 500, 1000, 1500, 2000, 2500],
  },
  {
    title: "5月最低气温",
    label: "5月最低气温",
    unit: "℃",
    range: [4, 16],
    suitableRange: [10, 13],
    value: 30,
    scaleList: range(4, 16, 2),
  },
  {
    title: "8月最高气温",
    label: "8月最高气温",
    unit: "℃",
    range: [24, 32],
    suitableRange: [30, 32],
    value: 30,
    scaleList: range(24, 32, 2),
  },
  {
    title: "交通距离",
    label: "交通距离",
    unit: "km",
    range: [0, 5],
    suitableRange: [0, 2],
    value: 1,
    scaleList: range(0, 5, 1),
  },
];

