import React, {PureComponent} from "react";
import styled from "styled-components";
import {TableComponent} from "./table/informationTable";
import {observer} from "mobx-react";
import {HeaderBox} from "../../containers/header-box";
const ContainerWithStyle = styled.div`
    flex: auto;
    display: flex;
    justify-content: center;
    padding: 16px;
    height: calc(100vh - 100px);
`;

@observer
export class Information extends PureComponent {
    render() {
        return (
            <HeaderBox names={["多组学关联与功能基因挖掘 信息库"]}>
                <ContainerWithStyle>
                    <TableComponent/>
                </ContainerWithStyle>
            </HeaderBox>
        );
    }
}
