/**
 * 判断是否为可用经纬度
 */
export const isCoor = (coor: [number, number]) => {
  if (!coor || !coor[0] || !coor[1] || coor[1] > 90 || coor[1] < -90 || coor[0] < -180 || coor[0] > 180) {
    return undefined;
  }
  return coor;
}

export const tuple = <T extends string[]>(...args: T) => args;

