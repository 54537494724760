import React from "react";
import { BaseMap, BaseMapProps } from "../../containers/base-map";
import { ClassificationLegend, ICSSProps, Timeline7, Timeline8, GradientLegend1 } from "@gago/frame";
import { Region } from "../../models/common";
import { BaMengMap, baMengMapConfig } from "../../containers/bameng-map";
import CommonProvider from "../../network/providers/common.provider";
import { BreedingTimeline } from "../../containers/breeding-timeline";
import { colorPalette } from "../../color-palette";
import { noop } from "lodash";
import { switchQiePianName } from "./style";
import moment from "moment";
import { GradientLayer, ClassificationLayer } from "@gago-react-gl/gago-react-gl";
function getPublisherUrls(url: string) {
  return ["g00", "g01", "g02", "g03", "g04", "g05", "g06", "g07", "g08", "g09"]
    .map(s => url.replace("api.gagogroup.cn", `${s}.gagogroup.cn`));
}
interface P extends Partial<BaseMapProps>, ICSSProps {
  /** 高亮的地区 */
  region: Region;
 /** 切换高亮的地区 */
  changeRegion(region: Region): void;
  /** 当前选择的作物类型 */
  cropType: string;
  /** 时间数组 */
  timeline: string[];
  /**选中的时间 */
  selectedDate: string;
  /** 当前选择的作物类型名称 */
  cropName?: string;
  /** 当前是否使用数管域名 */
  isDataManage: "on" | "off" | null;
}

interface S {
  /** map */
  map?: mapboxgl.Map;
  // /** 时间数组 */
  // timeline: string[];
  // /**选中的时间 */
  // selectedDate: string;
}

/**
 * map
 *
 */
export default class Map extends React.PureComponent<P, S> {

  state: S = {
    map: undefined,
    // selectedDate: "",
    // timeline: [],
  };

  onLoadMap = (map: mapboxgl.Map) => {
    this.setState({ map });
  }

  componentDidMount() {
    // this.getBreedTime();
  }

  // componentWillReceiveProps(nextProps: P) {
  //   console.log(this.props.cropType, nextProps.cropType, "change------->");
  //   if (this.props.cropType !== nextProps.cropType) {
  //     this.getBreedTime(nextProps.cropType);
  //   }
  // }

  /** get timeline */
  getTimeLine = async () => {
    const timeline = await CommonProvider.getPublishTimeline("crop_class");
    // this.setState({ timeline });
  }

  render() {
    const { region, changeRegion } = this.props;
    console.log("********");
    return (
      <>
        <BaseMap
          onLoad={this.onLoadMap}
        >
          <BaMengMap
            region={region}
            changeRegion={changeRegion}
          />
          {/* {this.renderMapLayer()} */}
          {this.renderLercLayer()}
          {/* <ClassificationLayer<{
            c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
          }>
            id="crop_sunflower"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_sunflower/20210803/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_sunflower?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 5] }}
            insideActiveSorts={"all"}
            // before={baMengMapConfig.city.line}
          >
            { colors => (
              <ClassificationLegend
                {...{ ...colors }}
                style={{ position: "absolute", bottom: "16px", right: "16px" }}
                names={["类别0", "类别1", "类别2", "类别30"]}
                colorPalette={colorPalette}
              />
            )}
          </ClassificationLayer> */}
        </BaseMap>
      </>
    );
  }

  renderMapLayer = () => {
    const { cropType } = this.props;
    switch (cropType) {
      case "sunflower": return this.renderSunflower();
      case "corn": return this.renderCornLayer();
      case "wheat": return this.renderWheatLayer();
      case "grassland": return this.renderGrassLandLayer();
      default:
    }
  }

  /** render 向日葵 */
  renderSunflower = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210112/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }

  /** render 玉米 */
  renderCornLayer = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210106/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }

  /** render 小麦 */
  renderWheatLayer = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210107/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }

  /** render 草地 */
  renderGrassLandLayer = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210113/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }
  /**渲染lerc图层 */
  renderLercLayer = () => {
    const { timeline, selectedDate, cropType, cropName, isDataManage } = this.props;
    if (cropType === "chilli") return null;
    if (timeline.length === 0) return null;
    const target = timeline.find(item => moment(item).years() === Number(selectedDate));
    console.log(target, cropName, cropType, "selecte------<date");
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id={`${cropType}_${selectedDate}`}
        key={`${cropType}_${selectedDate}`}
        url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_${switchQiePianName(cropType)}/${isDataManage === "on" ? this.matchDate(cropType) : moment(target).format("YYYYMMDD")}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        // paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_${switchQiePianName(cropType)}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        insideColors={{
          c0: cropName === "辣椒" ? "red" : "#f06f51",
          c1: "#fdd55a",
          c2: "#59cb74",
          c3: "#899cf0",
        }}
        sourceMaxzoom={14}
        sourceMinzoom={0}
      />
    );
  }

  /** 匹配作物lerc特定日期 */
  matchDate = (type: string) => {
    if (type === "corn") {
      return "20200802";
    }
    if (type === "sunflower") {
      return "20200803";
    }
    if (type === "wheat") {
      return "20200801";
    }
  }
}
