/**
 * GLMap component extension
 */
import echarts from "echarts";
import GLMapModel from "./GLMapModel";
import GLMapView from "./GLMapView";
import GLMapCoordSys from './GLMapCoordSys';

export default function () {
  echarts.registerCoordinateSystem(
    'GLMap', GLMapCoordSys()
  );
  GLMapModel();
  GLMapView();

  // Action
  echarts.registerAction({
    type: 'GLMapRoam',
    event: 'GLMapRoam',
    update: 'updateLayout'
  }, function (payload, ecModel) {})

  return {
    version: '1.0.0'
  }
}

