import React, { PureComponent } from "react";
import { Modal, Form, Input } from "antd";
import { ModalProps } from "antd/lib/modal";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { observer } from "mobx-react";

export interface FormData {
  departmentId: number | string;
  name: string;
}

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

interface Props extends ModalProps, Partial<FormComponentProps> {
  data: FormData;
  submitForm(values: FormData): void;
}


@observer
// @ts-ignore
@Form.create()
export class DepartmentModalComponent extends PureComponent<Props> {

  private submitForm = () => {
    const { submitForm, form, data } = this.props;
    if (!form) return;
    form.validateFields((error: any, values: FormData) => {
      if (error) {
        return;
      }
      submitForm({ ...data, ...values });
    });
  }

  render() {
    const { title, visible, onCancel, confirmLoading, data } = this.props;
    const { getFieldDecorator } = this.props.form as  WrappedFormUtils<any>;
    // tslint:disable jsx-no-multiline-js
    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={onCancel}
        onOk={this.submitForm}
        confirmLoading={confirmLoading}
        destroyOnClose
      >
        <Form>
          <Form.Item label="部门名称" {...formItemLayout}>
            {getFieldDecorator("name", {
              initialValue: data.name,
              rules: [{ required: true, message: "部门名称不能为空!" }],
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
