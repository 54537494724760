import moment from "moment";
import { TimelineType, Phenological, PhenologicalRequest, ResultType } from "../../models/common";
import { BaseRequestStaticServer, BaseRequestStaticServer1 } from "../servers/base-request.server";
import { PublisherStaticServer } from "../servers/publisher.server";
import { BaseProvider } from "./base.provider";

/**
 * 通用的 请求
 */
export default class CommonProvider extends BaseProvider {
  /** 物候期缓存 */
  static phenologicalData: Phenological[] | undefined;

  /** 请求publish 时间序列 */
  static async getPublishTimeline(type: string) {
    const { times } = await PublisherStaticServer.get<TimelineType<string[]>>(`/publisher/v2/times/${type}`);
    return times.sort((a, b) => Number(a) - Number(b)).map<string>(item => moment(item, "YYYYMMDD").format("YYYY-MM-DD"));
  }

  /** get 物候期时间 */
  static async getPhenological() {
    const { data } = await BaseRequestStaticServer.get<ResultType<Phenological[]>>("/plant/breed/cropPeriod");
    return data;
  }

}
