import React, { useEffect, useState, useReducer } from "react";
import moment from "moment";
import { HeaderBox } from "../../containers/header-box";
import { GermplasmBreedBody } from "./style";
import Map from "./map";
import { Icon, ITable, SideBar4, Timeline8, StyledAntdSelect } from "@gago/frame";
import { chartStyle, colorPalette } from "../../color-palette";
import { noop, round } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { AppRouteNav } from "@gago/lib/components/app-route-nav";
import { ScreenCard } from "@gago/lib/components/screen-card";
import { routesConfig } from "./route-config";
import { BreedingTimeline } from "../../containers/breeding-timeline";
import { BubbleChart, McBarChart, RadioSelect } from "@gago/lib/components";
import { RadioSelect2 } from "@gago/lib/components/radio-select-2";
import { Card2 } from "../../containers/card2";
import { Card3 } from "../../containers/card3";
import { WarningCard } from "../../containers/warning-card";
import { ScrollTable } from "../../containers/scroll-table";
import { BaseRequest, WeatherWarning, BaseDiseaseRequest } from "../../models/breeding-bigscreen";
import { useProvider } from "../../utils/use-provider";
import BreedingBigscreenProvider from "../../network/providers/breeding-bigscreen.provider";
import GrowthMonitoringProvider from "../../network/providers/growth-monitoring";
import CommonProvider from "../../network/providers/common.provider";

import { LineChart } from "@gago/lib/components/line-chart";
import { tuple } from "@gago/frame/es/utils/type";
import { Carousel, Select } from "antd";
import { daysInfuture } from "../../utils/example-data";
import { scalePage } from "../../decorator/scale-page";
import { switchQiePianName } from "../breeding-distribute/style";
import UserProvider from "../../network/providers/user.provider";
const { Option } = Select;
const mapDataList: { label: string; value: string }[] = [
  { label: "玉米", value: "corn" },
  { label: "向日葵", value: "sunflower" },
  { label: "小麦", value: "wheat" },
  { label: "辣椒", value: "chilli" },
];

const typeList: { label: string; value: string }[] = [
  { label: "温度", value: "1" },
  { label: "湿度", value: "2" },
  { label: "降水", value: "3" },
  { label: "风速", value: "4" },
];

const DisasterType: { label: string; value: string }[] = [
  { label: "高温", value: "high_temp" },
  { label: "低温", value: "low_temp" },
  { label: "暴雨", value: "rainstorm" },
  { label: "干旱", value: "drought" },
];

export const elementList = [
  {
    label: "容重", value: "bd",
  }, {
    label: "全氮", value: "an",
  }, {
    label: "PH", value: "ph",
  }, {
    label: "有机质", value: "som",
  }, {
    label: "有效磷", value: "ap",
  }, {
    label: "速效钾", value: "ak",
  }];
export const cardTypes = tuple("资产概况", "长势概况", "产量预估", "气象预报预警", "病害预警", "土壤质量监测");

/** render left1 */
const RenderLeft1 = (params: BaseRequest) => {
  const state = useProvider(
    [{ ...params, year: params.year ? (params.year?.length > 4 ? params.year?.slice(0, 4) : params.year) : "2021" }],
    BreedingBigscreenProvider.getBreedSummary.bind(BreedingBigscreenProvider));
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        height: "100%",
      }}
    >
      {
        [
          {
            title: "总面积",
            value: round(state?.tatol || 0, 2),
            suffix: "万亩",
            bg: "bg-08",
          },
          {
            title: "耕地总面积",
            value: round(state?.plough || 0, 2),
            suffix: "万亩",
            bg: "bg-09",
          },
        ].map((item) => {
          return (
            <Card2
              key={item.title}
              title={item.title}
              value={item.value}
              suffix={item.suffix}
              colorPalette={colorPalette}
              bgIcon={item.bg}
              style={{
                height: 107,
                width: 210,
              }}
            />
          );
        })
      }
      {
        [
          {
            title: "玉米",
            value: round(state?.corn || 0, 2),
            suffix: "万亩",
            icon: "icon-1",
          },
          {
            title: "小麦",
            value: round(state?.wheat || 0, 2),
            suffix: "万亩",
            icon: "icon-2",
          },
          {
            title: "向日葵",
            value: round(state?.sunflower || 0, 2),
            suffix: "万亩",
            icon: "icon-3",
          },
          {
            title: "辣椒",
            value: round((params.isDataManage === "on" ? state?.chilli : state?.wheat) || 0, 2),
            suffix: "万亩",
            icon: "icon-4",
          },
        ].map((item) => {
          return (
            <Card3
              key={item.title}
              title={item.title}
              value={item.value}
              suffix={item.suffix}
              colorPalette={colorPalette}
              icon={item.icon}
              style={{
                height: 60,
                width: 210,
              }}
            />
          );
        })
      }
    </div>
  );
};

/** render left2 */
const RenderLeft2 = (params: BaseRequest) => {
  const state = useProvider([params], BreedingBigscreenProvider.getBreedGrowth.bind(BreedingBigscreenProvider));
  if (!state) {
    return null;
  }
  return (
    <div style={{ position: "absolute", width: "100%", height: "85%", paddingLeft: 20 }}>
      <AxisChart
        isBigScreen
        basicConfigOption={{
          seriesType: "line",
          assignValueAxisName: "较旺",
          showValueAxis: false,
          gridLeftOffset: 30,
        }}
        chartData={state}
        // chartData={getDataS2N1("month", ["历史长势", "平均长势"], "")}
        colorPalette={{ ...colorPalette, subColor: ["#00fdff", "#e77300", ...colorPalette.subColor] }}
      />
      <span
        style={{
          color: "#009fb8",
          position: "absolute",
          fontSize: 12,
          bottom: 24,
          left: 10,
        }}
      >
        较弱
      </span>
    </div>
  );
};

/** 左侧第三个*/
const RenderLeft3 = (params: BaseRequest) => {
  const state = useProvider(
    [{ ...params, year: params.year ? (params.year?.length > 4 ? params.year?.slice(0, 4) : params.year) : "2021" }],
    BreedingBigscreenProvider.getYieldEstimate.bind(BreedingBigscreenProvider));
  if (!state) {
    return null;
  }
  return (
    <BubbleChart
      chartData={state}
      colorPalette={{ ...colorPalette, subColor: ["#00fdff", "#e77300", ...colorPalette.subColor] }}
    />
  );
};

/** 右侧第一个*/
const RenderRight1 = (params: BaseRequest) => {
  const [type, changeType] = useState(typeList[0].value);
  const [state, changeState] = useState<{
    chartData: ITable<2, 1>;
    weatherData: WeatherWarning["weatherData"];
  }>();
  useEffect(() => {
    BreedingBigscreenProvider.getWeatherWarning({
      ...params,
      type,
    }).then((data) => {
      changeState(data);
    });
  }, [JSON.stringify(params), type]);
  console.log(state?.weatherData, "weatherData");
  if (!state || !state.weatherData || state.weatherData.length === 0) {
    return (
      <>
        <div style={{ height: "100px", width: "100%", textAlign: "center", lineHeight: "100px", fontWeight: 700, color: "#c2fffa" }}>
          今日无预警
          </div>
        <RadioSelect2
          style={{ marginTop: 4, marginBottom: 8 }}
          data={typeList}
          colorPalette={colorPalette}
          backSelectChange={changeType}
        />
        <div style={{ height: 182 }} >
          {
            state && state.chartData && (
              // <LineChart
              //   chartData={dataS2N1}
              //   colorPalette={projectColorPalette}
              //   chartStyle={projectChartStyle}
              // />
              <AxisChart
                isBigScreen
                basicConfigOption={{
                  seriesType: "line",
                }}
                chartData={state.chartData}
                colorPalette={{ ...colorPalette, subColor: ["#e77300", "#13d7c8", ...colorPalette.subColor] }}
              />
            )
          }
        </div>
      </>
    );
  }
  return (
    <>

      <div style={{ height: 100 }}>
        <Carousel autoplay>
          {
            state && state.weatherData.map((item, ind) => {
              return (
                <WarningCard
                  key={ind}
                  disaster={item.disaster}
                  influence={item.influence || ""}
                  measure={item.measure || ""}
                />
              );
            })
          }
        </Carousel>
      </div>
      <RadioSelect2
        style={{ marginTop: 4, marginBottom: 8 }}
        data={typeList}
        colorPalette={colorPalette}
        backSelectChange={changeType}
      />
      <div style={{ height: 182 }} >
        {
          state && state.chartData && (
            // <LineChart
            //   chartData={dataS2N1}
            //   colorPalette={projectColorPalette}
            //   chartStyle={projectChartStyle}
            // />
            <AxisChart
              isBigScreen
              basicConfigOption={{
                seriesType: "line",
              }}
              chartData={state.chartData}
              colorPalette={{ ...colorPalette, subColor: ["#e77300", "#13d7c8", ...colorPalette.subColor] }}
            />
          )
        }

      </div>
    </>
  );
};

/** render right2 */
const RenderRight2 = (params: BaseDiseaseRequest) => {
  const state = useProvider([params], BreedingBigscreenProvider.getDiseaseWarning.bind(BreedingBigscreenProvider));
  if (!state) {
    return null;
  }
  console.log(state, "compoent-data", "params----->", params);

  return (
    <McBarChart
      chartData={state}
      chartStyle={chartStyle}
      ySplitNumber={5}
      barGap={-0.4}
      stackOne
      colorPalette={{ ...colorPalette, subColor: ["#49b87d", "#bbe06c", "#ffe066", "#ff925c", "#e03a5c", "#eb4c28"] }}
    />
    // <AxisChart
    //   isBigScreen
    //   basicConfigOption={{
    //     stack: true,
    //     showColorLinear: true,
    //   }}
    //   chartData={state}
    //   // chartData={getDataS2N1("region", ["高风险", "中风险", "低风险"])}
    //   colorPalette={{ ...colorPalette, subColor: ["#49b87d", "#bbe06c", "#ffe066", "#ff925c", "#e03a5c", "#eb4c28", ...colorPalette.subColor] }}
    // />
  );
};

/** render right3 */
const RenderRight3 = (params: BaseRequest) => {
  const state = useProvider([params], BreedingBigscreenProvider.getSoilMonitor.bind(BreedingBigscreenProvider));
  if (!state) {
    return null;
  }
  return (
    <div style={{ height: 200, width: "100%" }}>
      <ScrollTable
        scrollTime={200}
        style={{ height: "100%", width: "100%" }}
        tableData={state}
      />
    </div>
  );
};

interface P {

}

interface S {
  /** 高亮的地区 */
  region: Region;
  /** 当前选择的作物类型 */
  cropType: string;
  /** 当前选择的作物类型名称 */
  cropName?: string;
  /** 气象预警类型切换 */
  warningType: string;
  /** 当前点击的卡片类型 */
  cardType: string;
  /**时间轴时间 */
  timeData: string[];
  /**土壤元素 */
  element: string;
  /**选择的时间 */
  date: string;
  /**病害/气象lerc起报时间 */
  startDate: string;
  /**气象灾害类型 */
  disasterType: string;
  /**病害时间 */
  diseaseTime: string;
  /** 当前是否使用数管域名 */
  isDataManage: "on" | "off" | null;
  /** 数管打开时长势最新时间 */
  newTime?: string;
}

class BreedingBigscreen extends React.PureComponent<P, S> {

  state: S = {
    region: {
      code: undefined,
      name: undefined,
      level: "city",
    },
    cropType: "corn",
    warningType: typeList[0].value,
    cardType: cardTypes[0],
    timeData: [],
    element: "bd",
    date: "",
    startDate: "",
    disasterType: "high_temp",
    diseaseTime: moment().format("YYYY-MM-DD"),
    isDataManage: null,
  };

  async componentDidMount() {
    // this.getBreedTime();
    const params = {
      pageNum: 1,
      pageSize: 10,
      systemCode: "seedBreedMap",
    };
    // const data: any = await UserProvider.getDomain(params).catch(err => {
    //   this.setState({ isDataManage: "off" });
    // });
    // console.log(data, "***");

    // if (data) {
    //   this.setState({ isDataManage: data.rows.length > 0 ? data.rows[0].systemStatus : "off" });
    // }
    // if (this.state.isDataManage) {
    //   BreedingBigscreenProvider.changeSever2(this.state.isDataManage);
    // }
    // BreedingBigscreenProvider.changeSever2("on");
    UserProvider.getDomain(params).then(res => {
      const data: "on" | "off" = res && res.rows && res.rows.length > 0 ? res.rows[0].systemStatus : "off";
      const provider = new BreedingBigscreenProvider(data);
      this.setState({ isDataManage: data }, () => {
        this.initTimeData(cardTypes[0]);
        this.getGrowthTime();
      });
    });
  }

  /** 长势概况数管时间 */
  getGrowthTime = () => {
    const { isDataManage } = this.state;
    if (isDataManage === "off") return;
    BreedingBigscreenProvider.growthTime().then(
      res => {
        console.log("***Res", res);
        if (!res || !res.length) return;
        this.setState({ newTime: res[res.length - 1] });
      },
    ).catch(err => this.setState({ newTime: undefined }));
  }

  // componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
  //   if (prevState.isDataManage !== this.state.isDataManage && this.state.isDataManage) {
  //     BreedingBigscreenProvider.changeSever2(this.state.isDataManage);
  //   }
  // }

  initTimeData = (cardType: string) => {
    // const { cardType } = this.state;
    // 资产概况
    if (cardType === cardTypes[0]) {
      // this.setState({ timeData: [], date: '' });
      this.getDistributeTime();
      // 长势概况
    } else if (cardType === cardTypes[1]) {
      console.log("****cardType-", cardType);

      this.getBreedTime();
      // 产量预估
    } else if (cardType === cardTypes[2]) {
      this.getTimeLine();
      // 气象预报
    } else if (cardType === cardTypes[3]) {
      const timeData = daysInfuture(moment(), 8, "YYYY-MM-DD");
      const startDate = moment().format("YYYYMMDD");
      this.setState({ timeData, startDate, date: startDate });
      // 病害预警
    } else if (cardType === cardTypes[4]) {
      const timeData = daysInfuture(moment(), 8, "YYYY-MM-DD");
      const startDate = moment(new Date()).add(-1, "days").format("YYYYMMDD");
      this.setState({ timeData, startDate, date: moment().format("YYYYMMDD") });
    } else if (cardType === cardTypes[5]) {
      this.setState({ timeData: [], element: "bd" });
    }
    // 土地质量监测
  }

  // 获取作物长势时间轴时间
  judgeCropTimeType = (type: string) => {
    switch (type) {
      case "wheat": return "ndvi8";
      case "corn": return "ndvi_corn8";
      case "sunflower": return "ndvi_sunflower8";
      case "grassland": return "ndvi_glass8";
      default: return "ndvi_corn8";
    }
  }
  getBreedTime = async () => {
    // const timeData = await GrowthMonitoringProvider.getBreedTime();
    const { cropType } = this.state;

    const timeData = await CommonProvider.getPublishTimeline(this.judgeCropTimeType(cropType) as string);
    // const timeDataList = timeData.map(item => moment(item).format('YYYY-MM-DD'))
    const yearList = timeData.map(item => moment(item).years());
    const year = [...Array.from(new Set(yearList))];
    const sortedYear = year.sort((a, b) => a - b);
    console.log([...Array.from(new Set(timeData))], "121231");
    const latestTimeData = timeData.filter(item => moment(item).years() === sortedYear[sortedYear.length - 1]);
    // const dateGroup = time
    console.log(latestTimeData[latestTimeData.length - 1], timeData, "date------->");
    this.setState({ timeData, date: latestTimeData[latestTimeData.length - 1] });
    // this.setState({ timeData: timeDataList, date: timeDataList[0] })
  }

  // 判断产量预估切片请求类型
  judgeCropType = (crop: string) => {
    switch (crop) {
      case "sunflower": return "yield_xrk_2021"; break;
      case "corn": return "yield_maize_2021"; break;
      case "wheat": return "yield"; break;
      case "chilli": return "yield"; break;
      default: return "yield_maize_2021";
    }
  }
  // 获取产量预估timeLine
  getTimeLine = async () => {
    const { cropType, isDataManage } = this.state;
    // this.judgeCropType(cropType) as string
    let timeData: string[] = [];
    if (isDataManage === "on") {
      timeData = await BreedingBigscreenProvider.getNewPublishTimeline(cropType);
    } else {
      timeData = await CommonProvider.getPublishTimeline(this.judgeCropType(cropType) as string);
    }
    // console.log(data,'timeline');
    const date = timeData && timeData.length > 0 ? moment(timeData[0]).format("YYYY") : Number(moment().year() - 1).toString();
    this.setState({ timeData, date });
  }

  /**获取切片时间 */
  getDistributeTime = async (cropName?: string) => {
    const { cropType, isDataManage } = this.state;
    // const crop = cropName ? cropName : cropType;
    const crop = (cropType === "chilli" && isDataManage === "off") ? "wheat" : cropType;
    let timeData: string[] = [];
    if (isDataManage === "on") {
      timeData = await BreedingBigscreenProvider.getNewPublishTimeline(crop);
    } else {
      timeData = await CommonProvider.getPublishTimeline(`crop_${switchQiePianName(crop)}`);
    }
    this.setState({ timeData, date: timeData.length > 0 ? `${moment(timeData[timeData.length - 1]).years()}` : "" });
    // const yearList = timeData.map(item => moment(item).years());
    // const year = [...Array.from(new Set(yearList))];
    // const sortedYear = year.sort(( a, b) => a - b);
    // const latestTimeData = timeData.filter(item => moment(item).years() === sortedYear[sortedYear.length - 1]);
    // this.setState({timeData, date: latestTimeData[latestTimeData.length - 1]})
  }
  // 获取
  /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
    this.setState({ region });
  }

  /** 更改灾害类型 */
  changeDisasterType = (warningType: string) => {
    this.setState({ disasterType: warningType });
  }

  /** 更改卡片类型 */
  onChangeCardType = (cardType?: string) => {
    if (!cardType) {
      this.setState({ cardType: cardTypes[0] }, () => { this.initTimeData(cardTypes[0]); });
      return;
    }

    this.setState({ cardType, cropType: "corn", diseaseTime: moment().format("YYYY-MM-DD"), timeData: [], date: "" },
     () => this.initTimeData(cardType));
  }

  /** 改变时间轴所选时间 */
  handleChangeDate = (value: string) => {
    const { cardType } = this.state;
    if (cardType === cardTypes[4]) {
      this.setState({ date: value, diseaseTime: moment(value, "YYYY-MM月DD日").format("YYYY-MM-DD") });
    } else {
      this.setState({ date: value });
    }
  }
  /** 判断病卡片害请求时间 */
  judgeDeseaseTime = (cardType: string, date: string) => {
    if (cardType !== cardTypes[4]) {
      return moment().format("YYYY-MM-DD");
    }
    if (date && moment(date).isValid()) {
      return moment(date).format("YYYY-MM-DD");
    }  if (date && !moment(date).isValid()) {
      return moment(date, "YYYY-MM月DD日").format("YYYY-MM-DD");
    }
    return moment().format("YYYY-MM-DD");

  }
  // tslint:disable-next-line:cyclomatic-complexity
  render() {
    const { region, cropType, warningType, cardType, element, timeData, date, startDate, disasterType, isDataManage, diseaseTime, cropName, newTime } = this.state;
    // console.log(moment(date).isValid(),date,cardType,cardType === cardTypes[4],666666666)
    // const diseaseTime = this.judgeDeseaseTime(cardType,date)
    // tslint:disable-next-line: ter-max-len
    const deseaseCropType = cropType === "grassland" && sessionStorage.getItem("deseaseCropType") ? sessionStorage.getItem("deseaseCropType") as string : cropType;
    sessionStorage.setItem("deseaseCropType", deseaseCropType);
    return (
      <GermplasmBreedBody colorPalette={colorPalette}>
        <div className="header-box">
          <div className="header-name">种质繁育一张图</div>
        </div>
        <div className="body-content">
          <div className="left-aside">
            <ScreenCard className="chart-box" titleClassName={"title-style-1"} colorPalette={colorPalette} title={cardTypes[0]} active={cardType} onClick={this.onChangeCardType}>
             {isDataManage && <RenderLeft1 code={region.code} year={date} isDataManage={isDataManage}/>}
            </ScreenCard>
            <ScreenCard className="chart-box" titleClassName={"title-style-1"} colorPalette={colorPalette} title={cardTypes[1]} active={cardType} onClick={this.onChangeCardType}>
              {isDataManage && <RenderLeft2 code={region.code} crop={(isDataManage === "off" && cropType === "chilli") ? "wheat" : cropType} year={newTime}/>}
            </ScreenCard>
            <ScreenCard className="chart-box" titleClassName={"title-style-1"} colorPalette={colorPalette} title={cardTypes[2]} active={cardType} onClick={this.onChangeCardType}>
              {isDataManage && <RenderLeft3 code={region.code} crop={(isDataManage === "off" && cropType === "chilli") ? "wheat" : cropType} year={date}/>}
            </ScreenCard>
          </div>
          <div className="middle-content">
            <Map
              region={region}
              changeRegion={this.changeRegion}
              cropType={cropType}
              cardType={cardType}
              warningType={disasterType}
              element={element}
              timeData={timeData}
              startDate={startDate}
              cropName={cropName}
              date={date}
              isDataManage={isDataManage}
            />
            {cardType === cardTypes[5] ? this.renderElementSelect() : this.renderTypeSelect()}
            {/* {timeData.length > 0 && <Timeline7 className="TimeLine"  selected={date}  data={timeData} colorPalette={colorPalette} loop={"all" as "all"} interval={5000} onChange = {this.handleChangeDate}/> } */}
            {cardType === cardTypes[3] &&
              <StyledAntdSelect style={{ position: "absolute", right: "16px", top: "16px", background: "rgba(0,42,66,0.65)", color: "#00e5ff", fontWeight: 500 }}>
                <Select
                  onChange={this.changeDisasterType}
                  value={disasterType}
                >
                  {DisasterType.map(item => {
                    return (
                      <Option key={item.label} value={item.value}>{item.label}</Option>
                    );
                  })}
                </Select>
              </StyledAntdSelect>}
            {/* {timeData.length > 0 && <BreedingTimeline data={timeData} interval={5000} onChange = {this.handleChangeDate}/>}   */}
            {this.renderTimeLine()}
          </div>
          <div className="left-aside">
            <ScreenCard className="chart-box" titleClassName={"title-style-1"} colorPalette={colorPalette} title={cardTypes[3]} active={cardType} onClick={this.onChangeCardType}>
              {<RenderRight1 code={region.code} crop={cropType} type={warningType} />}
            </ScreenCard>
            <ScreenCard className="chart-box" titleClassName={"title-style-1"} colorPalette={colorPalette} title={cardTypes[4]} active={cardType} onClick={this.onChangeCardType}>
              {<RenderRight2 code={region.code} crop={deseaseCropType === "chilli" ? "wheat" : deseaseCropType as string | undefined} date={diseaseTime} />}
            </ScreenCard>
            <ScreenCard className="chart-box" titleClassName={"title-style-1"} colorPalette={colorPalette} title={cardTypes[5]} active={cardType} onClick={this.onChangeCardType}>
              <RenderRight3 code={region.code} crop={cropType} />
            </ScreenCard>
          </div>
        </div>
      </GermplasmBreedBody>
    );
  }

  onTypeSelectChange = (type: string, name?: string) => {
    const { cardType } = this.state;
    this.setState({ cropType: type === "chilli" ? "wheat" : type, cropName: name }, () => {
      this.initTimeData(cardType);
    });
    this.setState({ cropType: type, cropName: name }, () => {
      this.initTimeData(cardType);
    });
  }

  changeProductionTime = (value: string) => {
    this.setState({
      date: "2020",
    });
  }

  onElementSelectChange = (element: string) => {
    this.setState({ element });
  }

  renderTypeSelect = () => {
    const { cropType, cardType } = this.state;
    let selectedList = mapDataList.slice();
    if (cardType === cardTypes[2] || cardType === cardTypes[4]) {
      selectedList = mapDataList.slice(0, -1);
    }
    return (
      <RadioSelect2
        style={{
          position: "absolute",
          top: 16,
          left: 16,

        }}
        value={cropType}
        data={selectedList}
        colorPalette={colorPalette}
        backSelectChange={this.onTypeSelectChange}

      />
    );
  }

  renderElementSelect = () => {
    const { element } = this.state;
    return (
      <RadioSelect2
        style={{
          position: "absolute",
          top: 16,
          left: 16,
        }}
        value={element}
        data={elementList}
        colorPalette={colorPalette}
        backSelectChange={this.onElementSelectChange}
      />
    );
  }
  getName = (crop: string) => {
    switch (crop) {
      case "sunflower": return "向日葵"; break;
      case "corn": return "玉米"; break;
      case "wheat": return "小麦"; break;
      case "grassland": return "草地";
    }
  }
  renderTimeLine = () => {
    const { cardType, timeData, date, cropType } = this.state;

    const dateTime = moment(date).isValid() ? `${moment(date).format("YYYY-MM月DD日")}-` : date;

    if (cardType === cardTypes[2] || cardType === cardTypes[0]) {
      const realTimeLine = timeData.map(item => moment(item).format("YYYY"));
      // console.log(realTimeLine,'realTimeLine')
      // realTimeLine = [...realTimeLine,'2021']
      return (
        <>
          {/* 目前2021要求写死，且并没有数据，如有问题就放开下面这行代码 */}
          {timeData.length > 0 && <Timeline8  hideGroup selected={date}  data={realTimeLine} colorPalette={colorPalette} loop={"all" as "all"} interval={5000} onChange={this.handleChangeDate}/>}
          {/* {timeData.length > 0 && <Timeline8 key={cardType} hideGroup data={realTimeLine} colorPalette={colorPalette} loop={"all" as "all"} interval={5000} />} */}
        </>
      );
    }
    return (
      <>
        {timeData.length > 0 && <BreedingTimeline key={dateTime} selected={dateTime}  crop={this.getName(cropType)} data={timeData} onChange={this.handleChangeDate} />}
      </>
    );
  }
}

export default scalePage(1920, 1080)(BreedingBigscreen);
