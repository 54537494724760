import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
import { CompanyLandsType, CompanyLandsArchivesType, CompanyLandsOperationType, WeatherHistoryType } from "../../models/tracing-source";
import { ITable } from "@gago/frame";

interface IdParam {
  /** 地块id */
  id: string;
}

export default class TracingSourceProvider extends BaseProvider {
  /** 资源概况 */
  static async getMassifDetali(id: string) {
    const { data } = await BaseRequestStaticServer.get<ResultType<CompanyLandsType>>(`/company/land/${id}`);
    return data;
  }
  /** 获取档案记录数据 */
  static async getMassifArchives(params: IdParam) {
    const { data } = await BaseRequestStaticServer.get<ResultType<CompanyLandsArchivesType>>("/company/archives", { params });
    return data;
  }
  /** 获取公司地块操作记录 */
  static async getMassifOperation(params: IdParam) {
    const { data } = await BaseRequestStaticServer.get<ResultType<CompanyLandsOperationType[]>>("/company/operation", { params });
    return data;
  }
  /** 气象历史数据 */
  static async getWeatherHistory(params: IdParam) {
    const { data } = await BaseRequestStaticServer.get<ResultType<WeatherHistoryType[]>>("/weather/history", { params });
    return data;
  }
}
/** 气象数据类型结构处理 */
export function handleWeatherHistory(data: WeatherHistoryType[], type: string): ITable<2, 1> {
  if (type === "降雨量") {
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", `降水`, item.time, item.apcp]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "降水"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "mm"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  if (type === "空气湿度") {
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", `湿度`, item.time, item.humidity]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "湿度"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "%"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  if (type === "风速") {
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", `风速`, item.time, item.windspeed]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "风速"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "m/s"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  const tableData1 = data.map<ITable<2, 1>["data"][0]>(item => ["", `温度`, item.time, item.tmp]);
  return {
    title: "",
    subtitle: "",
    head: ["ID", "类型", "年份", "温度"],
    range: [[], [], [], []],
    prefix: ["", "", "", ""],
    suffix: ["", "", "", "℃"],
    defaultValue: [undefined, undefined, undefined, undefined],
    data: tableData1,
  };
}
