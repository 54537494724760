import React from "react";
import { BaseMap, BaseMapProps } from "../../containers/base-map";
import { ICSSProps, ClassificationLegend, GradientLegend1,globalColorPalette1 } from "@gago/frame";
import { Region } from "../../models/common";
import { BaMengMap, baMengMapConfig } from "../../containers/bameng-map";
import { Source, LercTileSource, Layer, LercTileLayer, MaskLayer } from "@gago-react-gl/gago-react-gl";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import moment from "moment";

const disasterInfoMap:DisasterInfo = {
  drought: {
    title:'干旱预警',
    levels:[ "轻旱", "中旱", "重旱", "特旱"],
    colors:[
      [0, "#fdec7b"],
      [1, "#ffa41c"],
      [2, "#ff700b "],
      [3, "#c42100 "],
      [4, "#c42100"],
      // [5, "#e61c30"],
      // [6, "#e61c30"]
    ]
  },
  rainstorm: {
    title: "暴雨预警",
    levels: [ "暴雨", "大暴雨", "特大暴雨"],
    colors: [
      [0, "#8fbfff"],
      // [1, "#7ab8ff"],
      [1, "#4275ff"],
      [2, "#001ac4"],
      [3, "#001ac4"],
      // [4, "#330099"],
    ]
  },
  low_temp: {
    title:'低温预警',
    levels:["一级", "二级", "三级", "四级","五级"],
    colors:[
      [0, "#ccffff"],
      [1, "#86dffe"],
      [2, "#3798fb"],
      [3, "#073ee4"],
      [4, "#070693"],
      [5, "#070693"],
    ]
  },
  high_temp: {
    title:'高温预警',
    levels:["一级", "二级", "三级", "四级","五级"],
    colors:[
      [0, "#ffffcc"],
      [1, "#ffe566"],
      [2, "#ff8714"],
      [3, "#cc2900"],
      [4, "#650014"],
      [5, "#650014"],
    ]
  }
}
type DisasterInfoItem = {
  title:string;
  levels:string[];
  colors:[number,string][];
}
type DisasterInfo = {
  drought:DisasterInfoItem;
  rainstorm:DisasterInfoItem;
  low_temp:DisasterInfoItem;
  high_temp:DisasterInfoItem;
}
type DisasterKey = keyof DisasterInfo;
interface P extends Partial<BaseMapProps>, ICSSProps {
  /** 高亮的地区 */
 region: Region;
 /** 切换高亮的地区 */
 changeRegion(region: Region): void;
 /**作物种类 */
 crop?:string;
//  时间轴选择的日期
 date:string;
//  灾害类型
 disaterType:string;
//  lerc 起报时间
 startDate:string;
}

interface S {
  /** map */
  map?: mapboxgl.Map;
}

/**
 * map
 * 
 */
export default class Map extends React.PureComponent<P, S>{

  state: S = {
    map: undefined,
  };

  onLoadMap = (map: mapboxgl.Map) => {
    this.setState({ map })
  }
  
  /** 根据作物类型判断，请求时间类型 */
  judgeCropTimeType = (type: string) => {
    switch (type) {
      case "wheat": return "ndvi8";
      case "corn": return "ndvi_corn8";
      case "sunflower": return "ndvi_sunflower8";
      case "grassland": return "ndvi_glass8";
      default: return "ndvi_sunflower8";
    }
  }

  
  

  render() {
    const { region, changeRegion, crop, date } = this.props;
    // const cropName = this.judgeCropTimeType(crop);
    // const dateString = '20201015'
    return (
      <>
        <BaseMap
          onLoad={this.onLoadMap}
        >
          <BaMengMap
            region={region}
            changeRegion={changeRegion}
          />
          {/* {this.renderDisater()} */}
          {this.renderMeteorologicalLayer()}
        </BaseMap>
      </>
    );
  }

  renderDisater = () => {
    const { date, disaterType, crop, startDate } = this.props;
    // if (!timeline) {
    //   return null;
    // }
    const token = window.sessionStorage.getItem("token") as string;
    const cropName = crop === 'grassland' ? 'grass' : crop
    

    const source: LercTileSource = {
      id: disaterType,
      type: "lerc_v2",
      maxzoom: 5,
      headers: {
        token
      },
      tiles: [`${window.config.publisher}/api/v1/plant/breed/weather/${moment().format("YYYYMMDD")}/${cropName}/${disaterType}/${moment(date).format("YYYYMMDD")}/{z}/{x}/{y}.lerc`],
    };

    // const colorValue: [number, string][] = [
    //   [1, "#25b86a"],
    //   [2, "#b5e05a"],
    //   [3, "#ffe066"],
    //   [4, "#ff7d3d"],
    //   [5, "#e61c30"],
    //   [6, "#e61c30"],
    // ];

    const layer: LercTileLayer = {
      id: disaterType,
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[0, disasterInfoMap[disaterType as DisasterKey].colors.length - 1]],
        "raster-domain-min": 0,
        "raster-domain-max": disasterInfoMap[disaterType as DisasterKey].colors.length - 1,
        "raster-colorscale": {
          type: "exponential",  
          stops: disasterInfoMap[disaterType as DisasterKey].colors as any,
        },
        "geojson-mask-source": 'bameng-county-fill',
      },
      type: "lercv2" as "lercv2",
      source: disaterType,
      before: 'z-index-2',
    };
    const colors = disasterInfoMap[disaterType as DisasterKey].colors.map((item, index) => ({ key: `${index}`, color: item[1], show: true })).slice(0, -1);
    return (
      <>
        <Source<LercTileSource> {...source} />
          {/* <GradientLegend1
            title={disasterInfoMap[disaterType as DisasterKey].title}
            colors={disasterInfoMap[disaterType as DisasterKey].colors}
            colorPalette={globalColorPalette1}
            style={{ position: "absolute", bottom: "16px", right: "416px", height: "160px" }}
            names={disasterInfoMap[disaterType as DisasterKey].levels}
            className="disaster-legend"
            include={[[1, disasterInfoMap[disaterType as DisasterKey].colors.length]]}
          /> */}
          <ClassificationLegend
              key={disaterType}
              colors={colors}
              style={{ position: "absolute", bottom: "16px", right: "416px" }}
              names={disasterInfoMap[disaterType as DisasterKey].levels}
              colorPalette={globalColorPalette1}
              className="123"
            />
        <Layer {...layer} />
      </>
    );
  }

  renderMeteorologicalLayer = () => {
    const { date, disaterType, crop } = this.props;
    // if (!timeline) {
    //   return null;
    // }
    const token = window.sessionStorage.getItem("token") as string;
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");
    //const cropName = crop === 'grassland' ? 'grass' : crop
    const cropName = crop === 'grassland' ? 'grass' : crop=== "chilli" ? "wheat" : crop
    const source: LercTileSource = {
      id: disaterType,
      type: "lerc_v2",
      maxzoom: 5,
      headers: {
        token
      },
      tiles: [`${window.config.publisher}/api/v1/plant/breed/weather/${moment().format("YYYYMMDD")}/${cropName}/${disaterType}/${time}/{z}/{x}/{y}.lerc`],
    };

    // const colorValue: [number, string][] = [
    //   [1, "#25b86a"],
    //   [2, "#b5e05a"],
    //   [3, "#ffe066"],
    //   [4, "#ff7d3d"],
    //   [5, "#e61c30"],
    //   [6, "#e61c30"],
    // ];

    const layer: LercTileLayer = {
      id: disaterType,
      paint: {
        "raster-opacity": 1,
        "lerc-include": [[0, disasterInfoMap[disaterType as DisasterKey].colors.length - 0.9]],
        "raster-domain-min": 0,
        "raster-domain-max": disasterInfoMap[disaterType as DisasterKey].colors.length - 0.9,
        "raster-colorscale": {
          type: "interval",
          stops: disasterInfoMap[disaterType as DisasterKey].colors as any,
        },
        "geojson-mask-source": baMengMapConfig.county.fill,
      },
      type: "lercv2" as "lercv2",
      source: disaterType,
      before: baMengMapConfig.county.fill,
    };
    const colors = disasterInfoMap[disaterType as DisasterKey].colors.map((item, index) => ({ key: `${index}`, color: item[1], show: true })).slice(0, -1);
    return (
      <>
        <Source<LercTileSource> {...source} />
          {/* <GradientLegend1
            title={disasterInfoMap[disaterType as DisasterKey].title}
            colors={disasterInfoMap[disaterType as DisasterKey].colors}
            colorPalette={globalColorPalette1}
            style={{ position: "absolute", bottom: "16px", right: "416px", height: "160px" }}
            names={disasterInfoMap[disaterType as DisasterKey].levels}
            className="disaster-legend"
            include={[[1, disasterInfoMap[disaterType as DisasterKey].colors.length]]}
          /> */}
          <ClassificationLegend
              title={disasterInfoMap[disaterType as DisasterKey].title}
              colors={colors}
              style={{ position: "absolute", bottom: "86px", right: "416px" }}
              names={disasterInfoMap[disaterType as DisasterKey].levels}
              colorPalette={globalColorPalette1}
              className="123"
            />
        <Layer {...layer} />
      </>
    );
  }

  /** render 干旱 */
  renderDrought() {
    const { date, disaterType } = this.props;
    // if (!timeline) {
    //   return null;
    // }
    const source: LercTileSource = {
      id: 'drought',
      type: "lerc_v2",
      maxzoom: 5,
      headers: {
        token: "gdc_longrun",
      },
      tiles: [`${window.config.publisher}/api/v1/plant/breed/weather/${moment().format("YYYYMMDD")}/drought/${moment(date).format("YYYYMMDD")}/{z}/{x}/{y}.lerc`],
    };

    const colorValue: [number, string][] = [
      [1, "#25b86a"],
      [2, "#b5e05a"],
      [3, "#ffe066"],
      [4, "#ff7d3d"],
      [5, "#e61c30"],
      [6, "#e61c30"],
    ];

    const layer: LercTileLayer = {
      id: 'drought',
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[1, 6]],
        "raster-domain-min": 1,
        "raster-domain-max": 6,
        "raster-colorscale": {
          type: "exponential",
          stops: colorValue as any,
        },
        "geojson-mask-source": 'mask_layer_id',
      },
      type: "lercv2" as "lercv2",
      source: 'drought',
      before: 'z-index-2',
    };
    // const colors = colorValue.map((item, index) => ({ key: `${index}`, color: item[1], show: true }));
    return (
      <>
        <Source<LercTileSource> {...source} />
          <GradientLegend1
            title="干旱预警"
            colors={colorValue}
            colorPalette={globalColorPalette1}
            style={{ position: "absolute", bottom: "16px", right: "16px", height: "160px" }}
            names={["正常", "轻旱", "中旱", "重旱", "特旱"]}
            className="disaster-legend"
            include={[[1, 6]]}
          />
          {/* <ClassificationLegend2
              colors={colors}
              style={{ position: "absolute", bottom: "16px", right: "16px" }}
              names={["1级", "2级", "3级", "4级", "5级"]}
              colorPalette={globalColo rPalette1}
              className="123"
            /> */}
        <Layer {...layer} />
      </>
    );
  }

  /** render 暴雨 */
  renderRainstorm() {
    const { date, disaterType } = this.props;
    // if (!timeline) {
    //   return null;
    // }
    const source: LercTileSource = {
      id: 'rainstorm',
      type: "lerc_v2",
      maxzoom: 5,
      headers: {
        token: "gdc_longrun",
      },
      tiles: [`${window.config.publisher}/api/v1/plant/breed/weather/${moment().format("YYYYMMDD")}/rainstorm/${moment(date).format("YYYYMMDD")}/{z}/{x}/{y}.lerc`],
    };

    const colorValue: [number, string][] = [
      [1, "#84ffff"],
      [2, "#00b0ff"],
      [3, "#2979ff"],
      // [3, "#2979ff"],
      [4, "#651fff"],
    ];

    const layer: LercTileLayer = {
      id: 'rainstorm',
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[1, 4]],
        "raster-domain-min": 1,
        "raster-domain-max": 4,
        "raster-colorscale": {
          type: "exponential",
          stops: colorValue as any,
        },
        "geojson-mask-source": 'mask_layer_id',
      },
      type: "lercv2" as "lercv2",
      source: 'rainstorm',
      before: 'z-index-2',
    };
    // const colors = colorValue.map((item, index) => ({ key: `${index}`, color: item[1], show: true }));
    return (
      <>
        <Source<LercTileSource> {...source} />
          <GradientLegend1
            title="洪涝预警"
            colors={colorValue}
            colorPalette={globalColorPalette1}
            style={{ position: "absolute", bottom: "16px", right: "16px", width: "100px" }}
            names={["大雨", "暴雨", "大暴雨", "特大暴雨"]}
            className="disaster-legend"
            include={[[1, 4]]}
          />
        <Layer {...layer} />
      </>
    );
  }

  /** render 低温 */
  renderForest() {
    const { date, disaterType } = this.props;
    // const { typeSelect } = this.props;
    // const kind = typeSelect === "tea" ? "chilling_tea" : "chilling_orange";
    // if (!timeline) {
    //   return null;
    // }
    const source: LercTileSource = {
      id: 'forest',
      type: "lerc_v2",
      maxzoom: 5,
      headers: {
        token: "gdc_longrun",
      },
      tiles: [`${window.config.publisher}/api/v1/plant/breed/weather/${moment().format("YYYYMMDD")}/${disaterType}/${moment(date).format("YYYYMMDD")}/{z}/{x}/{y}.lerc`],
    };

    const colorValue: [number, string][] = [
      [1, "#25b86a"],
      [2, "#84ffff"],
      [3, "#0091ea"],
      [4, "#304ffe"],
    ];

    const layer: LercTileLayer = {
      id: 'forest',
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[1, 4]],
        "raster-domain-min": 1,
        "raster-domain-max": 4,
        "raster-colorscale": {
          type: "exponential",
          stops: colorValue as any,
        },
        "geojson-mask-source": 'mask_layer_id',
      },
      type: "lercv2" as "lercv2",
      source: 'forest',
      before: 'z-index-2',
    };
    // const colors = colorValue.map((item, index) => ({ key: `${index}`, color: item[1], show: true }));
    return (
      <>
        <Source<LercTileSource> {...source} />
          <GradientLegend1
            title="低温预警"
            colors={colorValue}
            colorPalette={globalColorPalette1}
            style={{ position: "absolute", bottom: "16px", right: "16px", width: "76px" }}
            names={["正常", "轻度", "中度", "重度"]}
            className="disaster-legend"
            include={[[1, 4]]}
          />
        <Layer {...layer} />
      </>
    );
  }

  /** render 高温*/
  renderHighTemp() {
    const { date, disaterType } = this.props;
    // const { typeSelect } = this.props;
    // const kind = typeSelect === "tea" ? "chilling_tea" : "chilling_orange";
    // if (!timeline) {
    //   return null;
    // }
    const source: LercTileSource = {
      id: 'forest',
      type: "lerc_v2",
      maxzoom: 5,
      headers: {
        token: "gdc_longrun",
      },
      tiles: [`${window.config.publisher}/api/v1/plant/breed/weather/${moment().format("YYYYMMDD")}/${disaterType}/${moment(date).format("YYYYMMDD")}/{z}/{x}/{y}.lerc`],
    };

    const colorValue: [number, string][] = [
      [1, "#25b86a"],
      [2, "#84ffff"],
      [3, "#0091ea"],
      [4, "#304ffe"],
    ];

    const layer: LercTileLayer = {
      id: 'forest',
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[1, 4]],
        "raster-domain-min": 1,
        "raster-domain-max": 4,
        "raster-colorscale": {
          type: "exponential",
          stops: colorValue as any,
        },
        // "geojson-mask-source": 'mask_layer_id',
      },
      type: "lercv2" as "lercv2",
      source: 'forest',
      before: 'z-index-2',
    };
    // const colors = colorValue.map((item, index) => ({ key: `${index}`, color: item[1], show: true }));
    return (
      <>
        <Source<LercTileSource> {...source} />
          <GradientLegend1
            title="高温预警"
            colors={colorValue}
            colorPalette={globalColorPalette1}
            style={{ position: "absolute", bottom: "16px", right: "16px", width: "76px" }}
            names={["正常", "轻度", "中度", "重度"]}
            className="disaster-legend"
            include={[[1, 4]]}
          />
        <Layer {...layer} />
      </>
    );
  }

}