// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Button, Form, Modal, message } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { PageBody } from "./styled";
import { Card1 as Card, ITable, RadarChart } from "@gago/frame";

import { HeaderBox } from "../../containers/header-box";
import { Card1 } from "../../containers/card1";
import { LeftModule1 } from "./left-module1"
import { LeftModule2 } from "./left-module2";

import blue from "./img/blue.png";
import green from "./img/green.png";
import yellow from "./img/yellow.png";
import { chartStyle3 as chartStyle, colorPalette } from "../../color-palette";
import { getDataS1N1, getDataS2N1 } from "../../utils/example-data";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { CropPercentChart1 } from "@gago/lib/components/crop-percent-chart-1";
import { AddOverlay } from "../../containers/add-overlay";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import { AreaList} from "../../models/expert-database";
import ExpertDataBaseProvider from "../../network/providers/expert-database.provider";
// tslint:disable-next-line: ter-max-len
import { CropCategory, RegionSummary, ResourceList, ResourceSummary, ResourceTrend, CategorySummary, Upload, ResourceListRequest } from "../../models/germplasm-database";
import { cloneDeep, conformsTo, round, sumBy } from "lodash";
import { RankingList } from "@gago/lib/components/ranking-list";
import ModalForm from '@gago/lib/components/template-modal-form';
import { BreedingReport, BreedingReportDistribute, BreedingReportList, BreedingReportTrend, BreedingReportTypeDistribute, ReportListRequest, ReportUpateRequrest, ReportSummary, ReportAddRequrest } from "../../models/molecular-breeding-report";
import MolecularBreedingProvider from "../../network/providers/molecular-breeding-report.provider";
import { PaginationConfig } from "antd/lib/table";

const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", ""],
  data: [],
  suffix: ["", "", "", "份"],
  prefix: ["", "", "", ""],
};

const dataS1N1: ITable<1, 1> = {
  title: "",
  subtitle: "总份数",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", ""],
  data: [],
  suffix: ["", "", "份"],
  prefix: ["", "", ""],
};

interface S {
  /** 对话框是否可见 */
  visible: boolean;
  /** 汇总 */
  reportSummary?: ReportSummary;
  /** 趋势 */
  breedingReportTrend?: BreedingReportTrend[];
  /** 类型分布 */
  breedingReportTypeDistribute?: BreedingReportTypeDistribute[];
  /** 各省分布 */
  regionSummary?: BreedingReportDistribute[];
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 种质数据列表 */
  resourceList?: ResourceList;
  // 导入导出弹框是否可见
  isShow:boolean;
  // 省市区域信息
  areaList?:AreaList[]
  // 存储 this.props.form
  formMap?:any;
  /**数据列表 */
  breedingReportList?: BreedingReportList;
  /** 顶部数据搜索 */
  topSearch?: any;
  /** 当前页数 */
  pagination?: PaginationConfig;
  // 上传文件的url地址
  uploadUrl:string;
  // 导入导出下载模板请求所需参数
  requestKeys:string[];
  // 选择的数据行数
  selectRowKeysCopy:string[]
  /** 顶部卡片类型 */
  cardType?: number;
  /** 顶部搜索表单 */
  topForm?: FormComponentProps["form"];
}


const inputArray = [
  {
    label:'种质编号',
    field:'accessionNumber',
    placeholder:'请输入种质编号',
    value:''
  },{
    label:'天赋河套种植库编号',
    field:'genebankNumber',
    placeholder:'请输入天赋河套种植库编号',
    value:'无'
  },{
    label:'育种单位',
    field:'breedingDepartment',
    placeholder:'请输入育种单位',
    value:''
  }
]
const dateInfo = {
  label:'育种时间',
  field:'breedingTime'
}
/**
 * 分析育种报告管理
 * @author maoshengtao
 */
export class MolecularBreedingReport extends React.PureComponent<{}, S> {

  state: S = {
    visible: false,
    isShow:false,
    topSearch: {},
    uploadUrl:'/api/v1/plant/file/upload',
    requestKeys:['accessionNumber',  'breedingDepartment', 'breedingType', 'areaName', 'breedingTime'],
    selectRowKeysCopy:[]
  };

  componentDidMount() {
    this.getCropCategory();
    this.getStorageYears();
    this.getResourceSummary();
    this.getResourceTrend();
    this.getCategorySummary();
    this.getRegionSummary();
    this.getAreaList();
  }

  /** get 作物分类 */
  getCropCategory = async () => {
    const cropCategory = await GermplasmDatabaseProvider.getCropCategory();
    /** 第一次加载列表项 */
    if (cropCategory && cropCategory.length > 0) {
      this.setState({
        topSearch: {
          oneLevel: cropCategory[0].name,
          twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        }
       })
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    this.setState({ cropCategory });
  }

  /**获取省市区地址 */
    getAreaList = async () => {
      const areaList = await ExpertDataBaseProvider.getAreaList() || [];
      this.setState({areaList})
  }

  /** get 入库年份 */
  getStorageYears = async () => {
    const storageYears = await GermplasmDatabaseProvider.getStorageYears() || undefined;
    this.setState({ storageYears });
  }

  /** get 列表 */
  getResourceList = async (req: ReportListRequest) => {
    const params: ReportListRequest = {
      ...req,
      oneLevel: req.oneLevel === "全部" ? undefined : req.oneLevel,
      twoLevel: req.twoLevel === "全部" ? undefined : req.twoLevel,
      isExistReport: 1,
    }
    MolecularBreedingProvider.getReportList(params).then((breedingReportList) => {
      console.log(1111, breedingReportList)
      this.setState({ breedingReportList });
    }).catch((err) => {
      message.error("获取列表失败");
    });
  }

  /** get 种植资源库汇总 */
  getResourceSummary = async () => {
    const reportSummary = await MolecularBreedingProvider.getReportSummary() || undefined;
    this.setState({ reportSummary });
  }

  /** get 种植资源入库趋势 */
  getResourceTrend = async () => {
    const breedingReportTrend = await MolecularBreedingProvider.getBreedingReportTrend() || undefined;
    this.setState({ breedingReportTrend });
  }

  /** get 种植类型数量统计 */
  getCategorySummary = async () => {
    const breedingReportTypeDistribute = await MolecularBreedingProvider.getBreedingReportTypeDistribute() || undefined;
    this.setState({ breedingReportTypeDistribute });
  }

  /** get 黄河流域各省份入库种质资源统计 */
  getRegionSummary = async () => {
    const regionSummary = await MolecularBreedingProvider.getBreedingReportRegionDistribute () || undefined;
    this.setState({ regionSummary });
  }

  // 获取表单的selectRowKeys
  handleSelectRowKeys = (params:string[]) => {
    this.setState({
      selectRowKeysCopy:params
    })
  }

  // 处理导入导出弹框是否开启
  handleModalShow = ()  => {
    this.setState({
      isShow:true
    })
  }

  // 关闭导入导出弹框
  handleCancelModal = () => {
    this.setState({
      isShow:false
    })
  }


  // 处理导出操作
  handleExportData = async () => {
    const { selectRowKeysCopy, breedingReportList } = this.state;
    let ids:string[] = [];
    const token = window.sessionStorage.getItem("token") as string;
    if (selectRowKeysCopy && selectRowKeysCopy.length == 0) {
      message.info('您还没有选择导出的数据！')
    } else {
      const idString:string = selectRowKeysCopy.join(',');
        const data = await MolecularBreedingProvider.getReportPath({accessionNumbers:idString});
        message.info('文件正在压缩')
        if (data.path) {
           MolecularBreedingProvider.download(`/output/${data.path}`);

          // window.location.href =`${window.config.publisher}/api/v1/output/${data.path}?token=${token}`
        }
    }
  } 

  // 处理导入确定
  handleImport = async (params:any) => {
    if (!params.reportUrl) {
      return message.error('请选择导入文件')
    }
    await MolecularBreedingProvider.breedingReportAdd(params).then(data => {
      if (data?.code === 200) {
        this.handleCancelModal();
        message.success('导入成功，请稍后刷新查看最新数据')
      } 
    }).catch(err => {
      message.error('操作失败')
    });
    
  }

  /** top search */
  onTopSearch = (topSearch: any) => {
    const { pagination } = this.state;
    this.handleCardClick(0);
    this.setState({ topSearch }, () => {
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.报告年份,
       accessionNumber: topSearch.种质编号,
      genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        breedingDepartment: topSearch.育种单位,
        size: 10,
        page: pagination && pagination.current || 1,
      });
    });
  }

  /** 删除种质资源数据 */
  resourceDel = async (id: string) => {
    GermplasmDatabaseProvider.resourceDel(id).then((data) => {
      if (data && data.code === 200) {
        message.success("删除成功");
        const { topSearch } = this.state;
        this.getResourceList({
          oneLevel: topSearch.作物分类,
          twoLevel: topSearch.二类名称,
          year: topSearch.入库年份,
         accessionNumber: topSearch.种质编号,
      genebankNumber: topSearch.天赋河套种质库编号,
          accessionName: topSearch.种质名称,
          breedingDepartment: topSearch.育种单位,
          size: 10,
          page: 1,
        });
      } else {
        message.error("删除失败");
      }
    }).catch((err) => {
      message.error("删除失败");
    });
  }

  /** 处理分页编号请求 */
  handlePagination = (pagination: PaginationConfig) => {
    if (pagination && pagination.current) {
      const { topSearch } = this.state;
      this.setState({ pagination });
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.入库年份,
        accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        breedingDepartment: topSearch.育种单位,
        size: 10,
        page: pagination.current,
      });
    }
  }


  render() {
    const { breedingReportList, cropCategory, storageYears, pagination, areaList, requestKeys } = this.state;
    return(
      <HeaderBox
        names={["分子育种报告管理"]}
      >
        <PageBody>

          <div className="page-left">
            <div className="top-card-list">
              {this.renderTopCardList()}
            </div>
            <div className="page-left-bottom">
              <div className="search-module">
                <LeftModule1
                  onTopSearch={this.onTopSearch}
                  cropCategory={cropCategory}
                  storageYears={storageYears}
                  onLoad={this.topFormLoad}
                />
              </div>
              {this.renderButtonList()}
              <div className="table-module">
                <LeftModule2
                  reportList={breedingReportList}
                  resourceDel={this.resourceDel}
                  handleSelectRowKeys={this.handleSelectRowKeys}
                  handlePagination={this.handlePagination}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
          <div className="page-right">
            {this.renderRightCard1()}
            {this.renderRightCard2()}
            {this.renderRightCard3()}
          </div>
          <ModalForm
            visible={this.state.isShow}
            cropCategory={cropCategory}
            dateInfo={dateInfo}
            areaList={areaList}
            uploadUrl={this.state.uploadUrl}
            inputArray={inputArray}
            requestKeys={requestKeys}
            onOk={this.handleImport}
            onCancel={this.handleCancelModal}
          />
        </PageBody>
      </HeaderBox>
    );
  }

  renderRightCard1 = () => {
    const { breedingReportTrend } = this.state;
    if (!breedingReportTrend) {
      return null;
    }
    const chartData = cloneDeep(dataS2N1);
    chartData.data = breedingReportTrend.map((item) => {
      return [`${item.year}`, "种质入库趋势", `${item.year}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"育种报告增加趋势"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
        <AxisChart
          chartData={chartData}
          colorPalette={{ ...colorPalette, subColor: ["#f78011", ...colorPalette.subColor] }}
          basicConfigOption={{
            seriesType: "line",
            lineSmooth: true,
          }}
        />
        </div>
      </Card>
    );
  }

  renderRightCard2 = () => {
    const { breedingReportTypeDistribute } = this.state;
    if (!breedingReportTypeDistribute) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = breedingReportTypeDistribute.map((item) => {
      return [`${item.category}`, `${item.category}`, round(item.count, 2)];
    });
    chartData.title = `${sumBy(chartData.data, (item) => item[2])}`;
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"育种报告类型分布"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ minHeight: 200,  display: "flex", alignItems: "center"  }}>
          <CropPercentChart1
            theme="horizontal"
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#2970ff", "#00aaff", "#70e2ff", "#ffb300", "#ff5275", ...colorPalette.subColor],
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard3 = () => {
    const { regionSummary } = this.state;
    if (!regionSummary || regionSummary && regionSummary.length === 0) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = regionSummary.map((item) => {
      return [`${item.region}`, `${item.region}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"育种报告各省分布"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
          <RadarChart
            theme="light"
            colorPalette={colorPalette}
            chartData={chartData}
          />
        </div>
      </Card>
    );
  }

  renderButtonList = () => {
    return (
      <div className="button-list">
        <div className="button-list-right">
          <Button
            icon="download"
            onClick={this.handleModalShow}
          >
              导入
          </Button>
          <Button
            icon="export"
            onClick={this.handleExportData}
            style={{ margin: "0px 10px" }}
          >
              导出
          </Button>
        </div>
      </div>
    );
  }

  /** top form load */
  topFormLoad = (form: FormComponentProps["form"]) => {
    this.setState({ topForm: form });
  }

  /** handle clear top search */
  handleClearTopSearch = () => {
    const { topForm } = this.state;
    this.setState({ topSearch: {} });
    topForm && topForm.resetFields();
  }

  /** handle top Card click */
  handleCardClick = (n: number) => {
    if (n === this.state.cardType) {
      return;
    }

    const { topForm, cropCategory } = this.state;
    if (n === 1 && cropCategory) {
      this.handleClearTopSearch();
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    if (n === 2 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据是否为 分子标记辅助育种 筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        breedingType: "分子标记辅助育种",
      });
    }

    if (n === 3 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据是否为遗传修饰育种筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        breedingType: "遗传修饰育种",
      });
    }

    this.setState({ cardType: n });
  }

  /** render 顶部卡片列表 */
  renderTopCardList = () => {
    const { reportSummary, cardType } = this.state;
    if (!reportSummary) {
      return null;
    }
    return (
      <>
        {
          [
            {
              title: reportSummary && reportSummary.allCount || 0,
              suffix: "(条)",
              description: "分子育种报告数量",
              img: green,
              type: 1,
            },
            {
              title: reportSummary && reportSummary.fzbjCount || 0,
              suffix: "(份)",
              description: "分子标记辅助育种数量",
              img: yellow,
              type: 2,
            },
            {
              title: reportSummary && reportSummary.ycxsCount || 0,
              suffix: "(份)",
              description: "遗传修饰育种数量",
              img: blue,
              type: 3,
            },
          ].map((item) => {
            return (
              <Card1
                active={cardType === item.type}
                onClick={this.handleCardClick.bind(this, item.type)}
                key={item.title}
                title={item.title}
                suffix={item.suffix}
                description={item.description}
                img={item.img}
              />
            );
          })
        }
      </>
    );
  }

}
