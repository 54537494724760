// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import {
  Modal,
  Button,
  Steps,
  Select,
  message,
  Spin,
  Cascader,
  Popconfirm,
} from "antd";
import { ICSSProps } from "@gago/frame";
import { ModalProps } from "antd/lib/modal";
import styled, { createGlobalStyle } from "styled-components";
import { TopCategory, SearchProps } from "./top-category";
import { GenerateCategory } from "./generate-category";
import { FormComponentProps } from "antd/lib/form";
import {
  CropCategory,
  BaseInfo,
  RegionTree,
  ResourctFindOne,
} from "../../models/germplasm-database";
import { TestReport } from "../../models/germplasm-test-report";
import { BreedingReport } from "../../models/molecular-breeding-report";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import GermplasmTestReportProvider from "../../network/providers/germplasm-test-report.provider";
import MolecularBreedingProvider from "../../network/providers/molecular-breeding-report.provider";
import { TESTREPORTLIST, BREEDINGREPORTLIST } from "./const";

import { cloneDeep, get, omit, partial } from "lodash";

const { Option } = Select;
const { Step } = Steps;
const Wrap = styled.div`
  width: 1408px;
  height: 662px;
  overflow-y: auto;
  input:-webkit-autofill {
    /* 选择历史记录的文字颜色*/
    -webkit-text-fill-color: #333 !important;
  }
`;

const GloabalStyle = createGlobalStyle`
.edit-overlay{
  .ant-modal{
    width: 1440px!important;
  }
  .ant-input[disabled] {
    border: none;
    color: rgb(0,22,23, 0.7);
    background: transparent;
  }
  .ant-col{
    height: 55px;
  }
}
`;
type P = ICSSProps &
  ModalProps & {
    /** 种质编号 */
    accessionNumber: string;
    /** 操作类型 默认 为  base
     * 可选值：
     * base 基础信息
     * commodity 为商品种质
     * test 测试报告
     * breeding 育种报告
     */
    type: "base" | "commodity" | "test" | "breeding";
  };

interface S {
  /** 当前第几步骤 */
  step: number;
  /** 表单映射 */
  formMap: {
    [key: string]: FormComponentProps["form"];
  };
  /** 获取列表1 */
  list1?: BaseInfo[];
  /** 获取列表2 */
  list2: BaseInfo[];
  /** 获取列表3 */
  list3: BaseInfo[];
  /** 是否为loading */
  loading?: boolean;
  /** 种质编号 */
  accessionNumber?: number | string;
  /** 顶部搜索结果 */
  topSearch?: SearchProps;
  /** 行政区划数 */
  regionTree?: RegionTree[];
  /** 获取单个种质资源信息 */
  resourctFindOne?: ResourctFindOne;
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 是否之前有测试报告 */
  noTestReport?: boolean;
  /** 是否之前育种报告 */
  noBreedingReport?: boolean;
}

/**
 * 编辑 弹窗
 * @author maoshengtao
 */
export class EditOverlay extends React.Component<P, S> {
  static defaultProps = {
    type: "base",
  };
  /** 是否是基本信息 */
  isBase: boolean;
  /** 是否市场流通种质 */
  isCommodity: boolean;
  /** 是否是测试报告 */
  isTest: boolean;
  /** 是否是育种报告 */
  isBreeding: boolean;
  constructor(props: P) {
    super(props);
    const { type } = this.props;
    this.isBase = type === "base";
    this.isCommodity = type === "commodity";
    this.isTest = type === "test";
    this.isBreeding = type === "breeding";
    this.state = {
      step: 0,
      formMap: {},
      list2: [],
      list3: [],
    };
  }

  componentDidMount() {
    this.getCropCategory();
    this.getResourctFindOne();
    this.getOneResourceTestReport();
    this.getOneResourceBredingReport();
  }

  /** get 作物分类 */
  getCropCategory = async () => {
    const cropCategory = await GermplasmDatabaseProvider.getCropCategory();
    this.setState({ cropCategory });
  }

  /** 获取单个种质资源信息 */
  getResourctFindOne = async () => {
    const { accessionNumber } = this.props;
    this.setState({ loading: true });
    const resourctFindOne = await GermplasmDatabaseProvider.getResourctFindOne(
      accessionNumber,
    );
    this.setState({
      resourctFindOne,
      list1: resourctFindOne.information,
      loading: false,
    });
  }

  /** 获取单个种质 测试报告数据 */
  getOneResourceTestReport = async () => {
    const { accessionNumber } = this.props;
    const tempData = await GermplasmTestReportProvider.getTestReport(
      accessionNumber,
    );
    const templist2 =
      tempData &&
      tempData.map((item, ind) => {
        const templist = cloneDeep(TESTREPORTLIST[0].list);
        Object.keys(item).forEach((i) => {
          templist.map((elm) => {
            if (elm.eName === "provinceName_cityName") {
              elm.value = [item.provinceName, item.cityName];
            } else if (elm.eName === i) {
              elm.value = item[i as keyof TestReport];
            }
          });
        });
        return {
          name: item.id.toString(),
          list: templist,
        };
      });
    this.setState({
      list2: templist2,
      noTestReport: !templist2 || (templist2 && templist2.length === 0),
    });
  }

  /** 获取单个种质 育种报告数据 */
  getOneResourceBredingReport = async () => {
    const { accessionNumber } = this.props;
    const tempData = await MolecularBreedingProvider.getBreedingReport(
      accessionNumber,
    );
    const templist3 =
      tempData &&
      tempData.map((item, ind) => {
        const templist = cloneDeep(BREEDINGREPORTLIST[0].list);
        Object.keys(item).forEach((i) => {
          templist.map((elm) => {
            if (elm.eName === "provinceName_cityName") {
              elm.value = [item.provinceName, item.cityName];
            } else if (elm.eName === i) {
              elm.value = item[i as keyof BreedingReport];
            }
          });
        });
        return {
          name: item.id.toString(),
          list: templist,
        };
      });
    this.setState({
      list3: templist3,
      noBreedingReport: !templist3 || (templist3 && templist3.length === 0),
    });
  }

  /** 处理返回结果 */
  handleResult = (list: BaseInfo[], tempObj: any) => {
    const rawData = cloneDeep(list);
    rawData.map((elm) => {
      Object.keys(tempObj).map((item) => {
        if (elm.name === item) {
          Object.keys(tempObj[item]).map((i) => {
            elm.list.map((r) => {
              if (r.name === i) {
                r.value = tempObj[item][i];
              }
              if (
                r.type === "upload" &&
                get(tempObj[item][i], "fileList[0]", false)
              ) {
                r.value = tempObj[item][i].fileList.map(
                  (j: any, ind: number) =>
                    get(tempObj[item][i], `fileList[${ind}].url`, false) ||
                    get(
                      tempObj[item][i],
                      `fileList[${ind}].response.data.data[0].file`,
                      "",
                    ),
                );
              }
            });
          });
        }
      });
    });
    return rawData;
  }

  /** 步骤1保存 */
  handleStep1Save = () => {
    const { step, formMap, list1, resourctFindOne } = this.state;
    if (!list1 || list1.length === 0) return false;
    let result = true;
    /** 表单返回数据 */
    const tempObj: any = {};
    list1.map((item) => {
      formMap[item.name] &&
        formMap[item.name].validateFieldsAndScroll((err, v) => {
          if (err) {
            result = false;
          }
          tempObj[item.name] = v;
        });
    });
    const topForm = this.handleTopForm();
    if (!result || !topForm.provinceName || !resourctFindOne) return false;
    console.log(33333, topForm);
    /*** 更新 */
    GermplasmDatabaseProvider.resourceUpdate({
      accessionNumber: resourctFindOne.accessionNumber,
      oneLevel: resourctFindOne.oneLevel,
      twoLevel: resourctFindOne.twoLevel,
      oneLevelId: resourctFindOne.oneLevelId,
      twoLevelId: resourctFindOne.twoLevelId,
      isTs: topForm.isTs,
      isCirculate: topForm.isCirculate,
      information: JSON.stringify(this.handleResult(list1, tempObj)),
      provinceName: topForm.provinceName,
      cityName: topForm.cityName || "",
    })
      .then((res) => {
        if (res) {
          this.setState({ step: 1, accessionNumber: res });
          message.success("更新成功");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return true;
  }

  /** handle 测试报告更新 */
  handleTestSave = (name: string) => {
    const { formMap } = this.state;
    let result = true;
    /** 表单返回数据 */
    let tempObj: any = {};
    formMap[name] &&
      formMap[name].validateFieldsAndScroll((err, v) => {
        if (err) {
          result = false;
        }
        tempObj = v;
      });
    const topForm = this.handleTopForm();
    if (!result || !topForm.provinceName) return false;
    GermplasmTestReportProvider.updateTestReport({
      id: name,
      inspectDepartment: tempObj.送检单位,
      detectionDepartment: tempObj.检测单位,
      provinceName: tempObj.所在地区[0],
      cityName: tempObj.所在地区[1],
      testTime: tempObj.检测时间.format("YYYY-MM-DD"),
      reportUrl:
        tempObj.报告上传 &&
        get(tempObj.报告上传.file, "response.data.data[0].file", undefined),
    })
      .then((res) => {
        message.success("更新成功");
      })
      .catch((err) => {
        if (err.response) {
          message.error(get(err, "response.data.error.message", "更新失败"));
        }
      });
  }

  /** handle 测试报告新增 */
  handleTestAdd = (name: string) => {
    const { formMap, resourctFindOne } = this.state;
    let result = true;
    /** 表单返回数据 */
    let tempObj: any = {};
    formMap[name] &&
      formMap[name].validateFieldsAndScroll((err, v) => {
        if (err) {
          result = false;
        }
        tempObj = v;
      });
    const topForm = this.handleTopForm();
    if (!result || !topForm.provinceName || !resourctFindOne) return false;
    GermplasmTestReportProvider.addTestReport({
      accessionNumber: resourctFindOne?.accessionNumber,
      inspectDepartment: tempObj.送检单位,
      detectionDepartment: tempObj.检测单位,
      provinceName: tempObj.所在地区[0],
      cityName: tempObj.所在地区[1],
      testTime: tempObj.检测时间.format("YYYY-MM-DD"),
      reportUrl:
        tempObj.报告上传 &&
        get(tempObj.报告上传.file, "response.data.data[0].file", undefined),
    })
      .then((res) => {
        message.success("新增成功");
      })
      .catch((err) => {
        if (err.response) {
          message.error(get(err, "response.data.error.message", "新增失败"));
        }
      });
  }

  /** handle 育种报告更新 */
  handleBreedingSave = (name: string) => {
    const { formMap } = this.state;
    let result = true;
    /** 表单返回数据 */
    let tempObj: any = {};
    formMap[name] &&
      formMap[name].validateFieldsAndScroll((err, v) => {
        if (err) {
          result = false;
        }
        tempObj = v;
      });
    const topForm = this.handleTopForm();
    if (!result || !topForm.provinceName) return false;
    MolecularBreedingProvider.breedingReportUpdate({
      id: name,
      breedingDepartment: tempObj.育种单位,
      breedingType: tempObj.育种类型,
      provinceName: tempObj.所在地区[0],
      cityName: tempObj.所在地区[1],
      breedingTime: tempObj.育种时间.format("YYYY-MM-DD"),
      reportUrl:
        tempObj.报告上传 &&
        get(tempObj.报告上传.file, "response.data.data[0].file", undefined),
    })
      .then((res) => {
        message.success("更新成功");
      })
      .catch((err) => {
        if (err.response) {
          message.error(get(err, "response.data.error.message", "更新失败"));
        }
      });
  }

  /** handle 育种报告新增 */
  handleBreedingAdd = (name: string) => {
    const { formMap, resourctFindOne } = this.state;
    let result = true;
    /** 表单返回数据 */
    let tempObj: any = {};
    formMap[name] &&
      formMap[name].validateFieldsAndScroll((err, v) => {
        if (err) {
          result = false;
        }
        tempObj = v;
      });
    const topForm = this.handleTopForm();
    if (!result || !topForm.provinceName || !resourctFindOne) return false;
    MolecularBreedingProvider.breedingReportAdd({
      accessionNumber: resourctFindOne.accessionNumber,
      breedingDepartment: tempObj.育种单位,
      breedingType: tempObj.育种类型,
      provinceName: tempObj.所在地区[0],
      cityName: tempObj.所在地区[1],
      breedingTime: tempObj.育种时间.format("YYYY-MM-DD"),
      reportUrl:
        tempObj.报告上传 &&
        get(tempObj.报告上传.file, "response.data.data[0].file", undefined),
    })
      .then((res) => {
        message.success("新增成功");
      })
      .catch((err) => {
        if (err.response) {
          message.error(get(err, "response.data.error.message", "新增失败"));
        }
      });
  }

  /** handle 测试报告明细列表增加 */
  handleTestReportAdd = () => {
    const { list2 } = this.state;
    const tempTest = cloneDeep(TESTREPORTLIST[0]);
    tempTest.list.forEach((item) => {
      if (item.name === "报告上传") {
        item.required = true;
      }
    });
    tempTest.list = tempTest.list.filter((elm) => elm.name !== "当前报告");
    tempTest.name = `测试报告${list2.length}`;
    this.setState({ list2: [...list2, tempTest] });
  }

  /** handle 测试报告明细列表删除 */
  handleTestReportDel = (name: string, isAdd = false) => {
    const { list2 } = this.state;

    if (isAdd) {
      const filterList = list2.filter((item) => {
        return item.name !== name;
      });
      this.setState({ list2: filterList });
      return;
    }

    GermplasmTestReportProvider.delTestReport(name)
      .then(() => {
        message.success("删除成功");
        const filterList = list2.filter((item) => {
          return item.name !== name;
        });
        this.setState({ list2: filterList });
      })
      .catch(() => {
        message.error("删除失败");
      });
  }

  /** handle 育种报告明细列表增加 */
  handleBreedingReportAdd = () => {
    const { list3 } = this.state;
    const tempTest = cloneDeep(BREEDINGREPORTLIST[0]);
    tempTest.list.forEach((item) => {
      if (item.name === "报告上传") {
        item.required = true;
      }
    });
    tempTest.list = tempTest.list.filter((elm) => elm.name !== "当前报告");
    tempTest.name = `育种报告${list3.length}`;
    this.setState({ list3: [...list3, tempTest] });
  }

  /** handle 育种报告明细列表删除 */
  handleBreedingReportDel = (name: string, isAdd = false) => {
    const { list3 } = this.state;
    if (isAdd) {
      const filterList = list3.filter((item) => {
        return item.name !== name;
      });
      this.setState({ list3: filterList });
      return;
    }

    MolecularBreedingProvider.breedingReportDel(name)
      .then(() => {
        message.success("删除成功");
        const filterList = list3.filter((item) => {
          return item.name !== name;
        });
        this.setState({ list3: filterList });
      })
      .catch(() => {
        message.error("删除失败");
      });
  }

  /** 点击ok */
  // tslint:disable-next-line: cyclomatic-complexity
  handleOk = (e: any) => {
    const { step, formMap } = this.state;
    if (step === 0) {
      this.handleStep1Save();
      return;
    }
    if (step === 1) {
      this.setState({ step: 2 });
      return;
    }

    if (step === 2) {
      const { onCancel } = this.props;
      onCancel && onCancel(e);
    }
  }

  /** 点击重置 */
  handleReset = (e: any) => {
    const { step, formMap, list1, list2, list3 } = this.state;
    formMap.topform && formMap.topform.resetFields();
    if (step === 0) {
      if (!list1 || list1.length === 0) return;
      list1.forEach((item) => {
        formMap[item.name] && formMap[item.name].resetFields();
      });
      return;
    }
    if (step === 1) {
      list2.forEach((item) => {
        formMap[item.name] && formMap[item.name].resetFields();
      });
      return;
    }
    if (step === 2) {
      list3.forEach((item) => {
        formMap[item.name] && formMap[item.name].resetFields();
      });
    }
  }

  render() {
    const { onCancel } = this.props;
    const { loading } = this.state;
    return (
      <>
        <Modal
          footer={this.renderModalFooter()}
          maskClosable={false}
          wrapClassName={"edit-overlay"}
          onCancel={onCancel}
          // confirmLoading={loading}
          {...this.props}
        >
          <Wrap>
            {this.renderTopCategory()}
            {this.renderStep()}
            <>
              {loading && (
                <div
                  style={{
                    width: "100%",
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spin />
                </div>
              )}
              {this.renderGenerate1()}
              {this.renderGenerate2()}
              {this.renderGenerate3()}
            </>
          </Wrap>
          <GloabalStyle />
        </Modal>
      </>
    );
  }

  /** render modal footer */
  renderModalFooter = () => {
    const { step } = this.state;
    const { onCancel } = this.props;
    const text = "更新";
    return (
      <>
        <Button onClick={onCancel}>{"关闭"}</Button>
        <Button onClick={this.handleReset}>{"重置"}</Button>
        {step === 0 && (this.isBase || this.isCommodity) && (
          <Button type={"primary"} onClick={this.handleOk}>
            {text}
          </Button>
        )}
      </>
    );
  }

  /** load form */
  onLoadForm1 = (name: string, form: FormComponentProps["form"]) => {
    this.setState((preState) => ({
      formMap: { ...preState.formMap, [name]: form },
    }));
  }

  /** render form 1 */
  renderGenerate1 = () => {
    const { step, list1 } = this.state;
    if (step !== 0) {
      return null;
    }
    return (
      <>
        {list1 &&
          list1.map((item) => {
            return (
              <GenerateCategory
                useMultiplePreview
                disabled={this.isTest || this.isBreeding}
                data={item}
                key={item.name}
                title={item.name}
                onLoad={this.onLoadForm1.bind(this, item.name)}
              />
            );
          })}
      </>
    );
  }

  renderGenerate2Right = (item: BaseInfo) => {
    const { noTestReport } = this.state;
    if (this.isBase && !noTestReport) {
      return (
        <>
          <Popconfirm
            title="确认删除嘛？"
            okText="确认"
            cancelText="取消"
            onConfirm={this.handleTestReportDel.bind(this, item.name, false)}
          >
            <Button style={{ marginRight: 8 }}>删除</Button>
          </Popconfirm>
          <Button
            type="primary"
            onClick={this.handleTestSave.bind(this, item.name)}
          >
            更新
          </Button>
        </>
      );
    }
    if (this.isTest || (this.isBase && noTestReport)) {
      return (
        <>
          {item.name.includes("测试报告") ? (
            <>
              <Button
                onClick={this.handleTestReportDel.bind(this, item.name, true)}
                style={{ marginRight: 8 }}
              >
                删除
              </Button>
              <Button
                type="primary"
                onClick={this.handleTestAdd.bind(this, item.name)}
              >
                新增
              </Button>
            </>
          ) : (
            <>
              <Popconfirm
                title="确认删除嘛？"
                okText="确认"
                cancelText="取消"
                onConfirm={this.handleTestReportDel.bind(
                  this,
                  item.name,
                  false,
                )}
              >
                <Button style={{ marginRight: 8 }}>删除</Button>
              </Popconfirm>
              <Button
                type="primary"
                onClick={this.handleTestSave.bind(this, item.name)}
              >
                更新
              </Button>
            </>
          )}
        </>
      );
    }
    return null;
  }

  /** render 测试报告表单 */
  renderTestForm = () => {
    const { list2 } = this.state;
    if (!list2 || (list2 && list2.length === 0)) {
      return null;
    }
    return (
      <>
        {list2 &&
          list2.map((item, ind) => {
            return (
              <GenerateCategory
                disabled={this.isBreeding}
                data={item}
                useRegionCascader
                key={`测试报告${ind}`}
                title={"报告明细"}
                colNum={12}
                onLoad={this.onLoadForm1.bind(this, item.name)}
                rightCoponent={this.renderGenerate2Right.call(this, item)}
              />
            );
          })}
      </>
    );
  }

  /** render 测试报告表单button */
  renderTestFormButton = () => {
    const { noTestReport } = this.state;
    return (
      <>
        {this.isTest || (this.isBase && noTestReport) ? (
          <Button
            type={"primary"}
            icon={"plus"}
            style={{ marginTop: 12 }}
            onClick={this.handleTestReportAdd}
          >
            报告明细
          </Button>
        ) : (
          <Button type={"primary"} style={{ marginTop: 12 }}>
            追加测试报告请前往测试报告管理页面
          </Button>
        )}
      </>
    );
  }

  /** render form 1 */
  renderGenerate2 = () => {
    const { step } = this.state;

    if (step !== 1) {
      return null;
    }
    return (
      <>
        {this.renderTestForm()}
        {this.renderTestFormButton()}
      </>
    );
  }

  renderGenerate3Right = (item: BaseInfo) => {
    const { noBreedingReport } = this.state;
    if (this.isBase && !noBreedingReport) {
      return (
        <>
          <Popconfirm
            title="确认删除嘛？"
            okText="确认"
            cancelText="取消"
            onConfirm={this.handleBreedingReportDel.bind(
              this,
              item.name,
              false,
            )}
          >
            <Button style={{ marginRight: 8 }}>删除</Button>
          </Popconfirm>
          <Button
            type="primary"
            onClick={this.handleBreedingSave.bind(this, item.name)}
          >
            更新
          </Button>
        </>
      );
    }
    if (this.isBreeding || (this.isBase && noBreedingReport)) {
      return (
        <>
          {item.name.includes("育种报告") ? (
            <>
              <Button
                onClick={this.handleBreedingReportDel.bind(
                  this,
                  item.name,
                  true,
                )}
                style={{ marginRight: 8 }}
              >
                删除
              </Button>
              <Button
                type="primary"
                onClick={this.handleBreedingAdd.bind(this, item.name)}
              >
                新增
              </Button>
            </>
          ) : (
            <>
              <Popconfirm
                title="确认删除嘛？"
                okText="确认"
                cancelText="取消"
                onConfirm={this.handleBreedingReportDel.bind(
                  this,
                  item.name,
                  false,
                )}
              >
                <Button style={{ marginRight: 8 }}>删除</Button>
              </Popconfirm>
              <Button
                type="primary"
                onClick={this.handleBreedingSave.bind(this, item.name)}
              >
                更新
              </Button>
            </>
          )}
        </>
      );
    }
    return null;
  }

  /** render 育种报告表单 */
  renderBreedingForm = () => {
    const { list3 } = this.state;
    if (!list3 || (list3 && list3.length === 0)) {
      return null;
    }
    return (
      <>
        {list3 &&
          list3.map((item, ind) => {
            return (
              <GenerateCategory
                disabled={this.isTest}
                data={item}
                useRegionCascader
                key={`${ind}`}
                title={"报告明细"}
                colNum={12}
                onLoad={this.onLoadForm1.bind(this, item.name)}
                rightCoponent={this.renderGenerate3Right.call(this, item)}
              />
            );
          })}
      </>
    );
  }

  /** render 育种报告表单button */
  renderBreedingFormButton = () => {
    const { noBreedingReport } = this.state;
    return (
      <>
        {this.isBreeding || (this.isBase && noBreedingReport) ? (
          <Button
            type={"primary"}
            icon={"plus"}
            style={{ marginTop: 12 }}
            onClick={this.handleBreedingReportAdd}
          >
            报告明细
          </Button>
        ) : (
          <Button type={"primary"} style={{ marginTop: 12 }}>
            追加育种报告请前往育种报告管理页面
          </Button>
        )}
      </>
    );
  }
  /** render form 3 */
  renderGenerate3 = () => {
    const { step, list3 } = this.state;
    if (step !== 2) {
      return null;
    }
    return (
      <>
        {this.renderBreedingForm()}
        {this.renderBreedingFormButton()}
      </>
    );
  }

  /** handle step change */
  handleStepChange = (current: number) => {
    this.setState({ step: current });
  }

  /** render 步骤条 */
  renderStep = () => {
    const { step } = this.state;
    return (
      <Steps
        type="navigation"
        size="small"
        current={step}
        onChange={this.handleStepChange}
        style={{
          border: "solid 1px rgba(0, 22, 23, 0.15)",
        }}
      >
        <Step title="种质基本信息" />
        <Step title="种质测试报告" />
        <Step title="分子育种报告" />
      </Steps>
    );
  }

  /** top search */
  baseInfoSearch = async (topSearch: SearchProps) => {
    // this.setState({ topSearch });
  }

  /** handle Top form */
  handleTopForm = () => {
    const { formMap } = this.state;
    let topForm: Partial<Omit<ResourctFindOne, "information">> = {};
    formMap.topform &&
      formMap.topform.validateFields((err, values) => {
        if (err) {
          console.log(err);
          return;
        }
        topForm = {
          oneLevel: values.oneLevel,
          twoLevel: values.twoLevel,
          isTs: values.isTs,
          isCirculate: values.isCirculate,
          provinceName: values.regionTree[0],
          cityName: values.regionTree[1],
        };
      });
    return topForm;
  }

  /** render top 分类 */
  renderTopCategory = () => {
    const { cropCategory, resourctFindOne } = this.state;
    if (!cropCategory) {
      return null;
    }
    return (
      <TopCategory
        cropCategory={cropCategory}
        onSearch={this.baseInfoSearch}
        onLoad={this.onLoadForm1.bind(this, "topform")}
        topCategory={omit(resourctFindOne, "infomation")}
        disableIsCirculate={this.isCommodity}
      />
    );
  }
}
