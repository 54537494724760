import React from "react";
import moment from "moment";
import { BaseMap, BaseMapProps } from "../../containers/base-map";
// import { ICSSProps } from "@gago/frame";
import { ICSSProps, ClassificationLegend, GradientLegend1, globalColorPalette1  } from "@gago/frame";

import { uniqueId } from "lodash";
import { ClassificationLayer, LercTileSource, Source, LercTileLayer, Layer, GradientLayer, BaseLayer } from "@gago-react-gl/gago-react-gl";
import { BaMengMapLayer } from "../../containers/map-layer";
import { Region } from "../../models/common";
import { BaMengBigscreenMap, baMengMapConfig } from "../../containers/bameng-bigscreen-map";
import { cardTypes } from ".";
import { BreedingTimeline } from "../../containers/breeding-timeline";
import { switchQiePianName } from "../breeding-distribute/style";

const disasterInfoMap: DisasterInfo = {
  drought: {
    title: "干旱预警",
    levels: ["轻旱", "中旱", "重旱", "特旱"],
    colors: [
      [0, "#ffeb99"],
      [1, "#ffa15c"],
      [2, "#c21d17"],
      [3, "#5c0018"],
      [4, "#5c0018"],
      // [5, "#e61c30"],
      // [6, "#e61c30"]
    ],
  },
  rainstorm: {
    title: "暴雨预警",
    levels: ["小雨", "大雨", "暴雨", "大暴雨", "特大暴雨"],
    colors: [
      // [0, "#ade4ff"],
      // [1, "#7ab8ff"],
      [1, "#4778fe"],
      [2, "#1723c2"],
      [3, "#330099"],
      [4, "#330099"],
    ],
  },
  low_temp: {
    title: "低温预警",
    levels: ["一级", "二级", "三级", "四级", "五级"],
    colors: [
      [0, "#ccffff"],
      [1, "#86dffe"],
      [2, "#3798fb"],
      [3, "#073ee4"],
      [4, "#070693"],
      [5, "#070693"],
    ],
  },
  high_temp: {
    title: "高温预警",
    levels: ["一级", "二级", "三级", "四级", "五级"],
    colors: [
      [0, "#ffffcc"],
      [1, "#ffe566"],
      [2, "#ff8714"],
      [3, "#cc2900"],
      [4, "#650014"],
      [5, "#650014"],
    ],
  },
};
interface DisasterInfoItem {
  /**标题 */
  title: string;
  /**级别 */
  levels: string[];
  /**配色 */
  colors: [number, string][];
}
interface DisasterInfo {
  /**干旱 */
  drought: DisasterInfoItem;
  /**暴雨 */
  rainstorm: DisasterInfoItem;
  /**低温 */
  low_temp: DisasterInfoItem;
  /**高温 */
  high_temp: DisasterInfoItem;
}
type DisasterKey = keyof DisasterInfo;
interface P extends Partial<BaseMapProps>, ICSSProps {
  /** 高亮的地区 */
  region: Region;
 /** 切换高亮的地区 */
  changeRegion(region: Region): void;
 /** 当前选择的作物类型 */
  cropType: string;
 /** 当前点击的卡片类型 */
  cardType: string;
  /**时间轴选择的时间 */
  date: string;
  /**时间轴的时间 */
  timeData: string[];
  /**灾害类型 */
  warningType: string;
  /**起报时间 */
  startDate: string;
  /**土壤元素 */
  element: string;
  /** 当前选择的作物类型名称 */
  cropName?: string;
  /** 当前是否使用数管域名 */
  isDataManage: "on" | "off" | null;
}

interface S {
  /** map */
  map?: mapboxgl.Map;
}

/**
 * map
 *
 */
export default class Map extends React.PureComponent<P, S> {

  state: S = {
    map: undefined,
  };
  /**初始化地图 */
  onLoadMap = (map: mapboxgl.Map) => {
    this.setState({ map });
  }

  render() {
    const { region, changeRegion } = this.props;
    return (
      <>
        <BaseMap
          onLoad={this.onLoadMap}
        >
          <BaseLayer id="tiandi_base_layer" type="TianDiTu_Satellite_Map"  />
          <BaMengBigscreenMap
            region={region}
            changeRegion={changeRegion}
          />
          {this.renderMapLayer()}
          {/* {this.renderGrowthLayer()} */}
          {/* {this.renderGrowthSunflower()} */}
        </BaseMap>
      </>
    );
  }

  renderTimeline = () => {
    const { cardType, cropType } = this.props;
    if (cardType === cardTypes[0]) {
      return null;
    }
    return (
      <BreedingTimeline/>
    );
  }

  /** render map layer */
  renderMapLayer = () => {
    const { cardType, cropType } = this.props;
    console.log("**cardType", cardType);
    switch (cardType) {
    // case cardTypes[0]: return (() => {
    //   switch (cropType) {
    //     case "sunflower": return this.renderSunflower0();
    //     case "corn": return this.renderCornLayer0();
    //     case "wheat": return this.renderWheatLayer0();
    //     case "grassland": return this.renderGrassLandLayer0();
    //     default: this.renderSunflower0();
    //   }
    // })();
      case cardTypes[0]: return this.renderLercLayer();
      case cardTypes[1]: return this.renderGrowthLayer();
      case cardTypes[2]: return this.renderProductionLayer();
      case cardTypes[3]: return this.renderMeteorologicalLayer();
      case cardTypes[4]: return this.renderDiseaseLayer() ;
      case cardTypes[5]: return this.renderLandElementLayer();
      default: this.renderSunflower0();
    }
  }

  /** render 向日葵 */
  renderSunflower0 = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210112/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }

  /** render 玉米 */
  renderCornLayer0 = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210106/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }

  /** render 小麦 */
  renderWheatLayer0 = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210107/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }

  /** render 草地 */
  renderGrassLandLayer0 = () => {
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id="crop_class"
        url={"http://zhongzhi.grassict.cn/mc-publi/publisher/v2/data/crop_class/20210113/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_class?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.city.line}
      />
    );
  }

  /**渲染长势lerc数据 */
  judgeCropTimeType = (type: string) => {
    switch (type) {
      case "wheat": return "ndvi8";
      case "corn": return "ndvi_corn8";
      case "sunflower": return "ndvi_sunflower8";
      case "grassland": return "ndvi_glass8";
      default: return "ndvi_corn8";
    }
  }
  renderGrowthSunflower = () => {
    const { date } = this.props;
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");

    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
      }>
        id="ndvi_sunflower8"
        key="ndvi_sunflower"
        url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/ndvi_sunflower8/${time}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/ndvi_sunflower8?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1], c4: [4.9, 5.1] }}
        insideActiveSorts={"all"}
      >
        { colors => (
          <ClassificationLegend
            {...{ ...colors }}
            style={{ position: "absolute", bottom: "16px", right: "16px" }}
            names={["类别0", "类别1", "类别2", "类别3", "类别4"]}
            colorPalette={globalColorPalette1}
          />
        )}
      </ClassificationLayer>
    );
  }
  renderGrowthLayer = () => {
    const { region, cropType, date } = this.props;
    if (cropType === "chilli") return null;
    const cropName = this.judgeCropTimeType(cropType);
    // const dateString = '20201015'
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");

    // const dateTime = moment(date).format('YYYYMMDD')
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
      }>
        id={cropName}
        key={cropName + cropName}
        url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${time}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1], c4: [4.9, 5.1] }}
        insideActiveSorts={"all"}
        before={baMengMapConfig.county.line}
        insideColors={{
          c0: "#c6682c",
          c1: "#f6da6b",
          c2: "#72d983",
          c3: "#26ae94",
          c4: "#004f6b",
        }}
        sourceMaxzoom={14}
        sourceMinzoom={0}
      >
        { colors => {
          return (
            <ClassificationLegend
              {...{ ...colors }}
              className="transform-control"
              style={{ position: "absolute", bottom: "86px", right: "16px" }}
              names={["极弱", "较弱", "正常", "较强", "极强"]}
              colorPalette={globalColorPalette1}
            />
          );
        }}
      </ClassificationLayer>
    );
  }

  /**渲染产量预估 */
  // 判断切片请求类型
  judgeCropType = (crop: string) => {
    switch (crop) {
      case "sunflower": return "yield_xrk_2021"; break;
      case "corn": return "yield_maize_2021"; break;
      case "wheat": return "yield"; break;
      case "grassland": return "yield_grass_2021"; break;
      default: return "yield_maize_2021";
    }
  }
  /**判断产量预估级别名称 */
  judgeName = (cropName: string) => {
    switch (cropName) {
      case "yield_xrk_2021": return {
        names: ["160千克/亩以下", "160-190千克/亩", "190-220千克/亩", "220-250千克/亩", "250千克/亩以上"],
        insideSorts: { c0: [13000, 16000], c1: [16000, 19000], c2: [19000, 22000], c3: [22000, 25000], c4: [25000, 28000] }}; break;
      case "yield_maize_2021": return {
        names: ["400千克/亩以下", "400-500千克/亩", "500-600千克/亩", "600-700千克/亩", "700千克/亩以上"],
        insideSorts: { c0: [30000, 40000], c1: [40000, 50000], c2: [50000, 60000], c3: [60000, 70000], c4: [70000, 80000] }}; break;
      case "yield": return {
        names: ["220千克/亩以下", "220-240千克/亩", "240-260千克/亩", "260-280千克/亩", "280千克/亩以上"],
        insideSorts: { c0: [20000, 22000], c1: [22000, 24000], c2: [24000, 26000], c3: [26000, 28000], c4: [28000, 32000] }}; break;
      case "yield_grass_2021": return {
        names: ["38千克/亩以下", "38-46千克/亩", "46-54千克/亩", "54-64千克/亩", "62千克/亩以上"],
        insideSorts: { c0: [3000, 3800], c1: [3800, 4600], c2: [4600, 5400], c3: [5400, 6200], c4: [6200, 7000] }}; break;
      default: return {
        names: ["160千克/亩以下", "160-190千克/亩", "190-220千克/亩", "220-250千克/亩", "250千克/亩以上"],
        insideSorts: { c0: [13000, 16000], c1: [16000, 19000], c2: [19000, 22000], c3: [22000, 25000], c4: [25000, 28000] }};
    }
  }

  renderProductionLayer =  () => {
    const { region, cropType, date , timeData, changeRegion, isDataManage } = this.props;

    if (cropType === "chilli") return null;
    const realTime = timeData?.find(item => moment(item).format("YYYY") === date);
    console.log(timeData, cropType, "mapTimdatee");
    const cropName = this.judgeCropType(cropType);
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
      }>
        id={cropName}
        key={uniqueId(cropName)}
        url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${isDataManage === "on" ? this.matchDate1(cropType) : moment(realTime).format("YYYYMMDD")}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        insideSorts={this.judgeName(cropName).insideSorts as unknown as any}
        insideActiveSorts={"all"}
        before={baMengMapConfig.county.line}

      >
        { colors => (
          <ClassificationLegend
            {...{ ...colors }}
            className="transform-control"
            style={{ position: "absolute", bottom: "86px", right: "16px" }}
            names={this.judgeName(cropName).names}
            colorPalette={globalColorPalette1}
          />
        )}
      </ClassificationLayer>
    );
  }

  /**渲染气象预警 */
  renderMeteorologicalLayer = () => {
    const { date, warningType, cropType } = this.props;
    // if (!timeline) {
    //   return null;
    // }
    const token = window.sessionStorage.getItem("token") as string;
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");
    const cropName = cropType === "grassland" ? "grass" : cropType;

    const source: LercTileSource = {
      id: warningType,
      type: "lerc_v2",
      maxzoom: 5,
      roundZoom: true,
      headers: {
        token,
      },
      // tslint:disable-next-line: ter-max-len
      tiles: [`${window.config.publisher}/api/v1/plant/breed/weather/${moment().format("YYYYMMDD")}/${cropName}/${warningType}/${time}/{z}/{x}/{y}.lerc`],
    };

    // const colorValue: [number, string][] = [
    //   [1, "#25b86a"],
    //   [2, "#b5e05a"],
    //   [3, "#ffe066"],
    //   [4, "#ff7d3d"],
    //   [5, "#e61c30"],
    //   [6, "#e61c30"],
    // ];

    const layer: LercTileLayer = {
      id: warningType,
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[0, disasterInfoMap[warningType as DisasterKey].colors.length - 0.9]],
        "raster-domain-min": 0,
        "raster-domain-max": disasterInfoMap[warningType as DisasterKey].colors.length - 0.9,
        "raster-colorscale": {
          type: "interval",
          // tslint:disable-next-line: no-any
          stops: disasterInfoMap[warningType as DisasterKey].colors as any,
        },
        "geojson-mask-source": baMengMapConfig.county.fill,
      },
      type: "lercv2" as "lercv2",
      source: warningType,
      before: baMengMapConfig.county.line,
    };
    const colors = disasterInfoMap[warningType as DisasterKey]
    .colors.map((item, index) => ({ key: `${index}`, color: item[1], show: true })).slice(0, -1);
    return (
      <>
        <Source<LercTileSource> {...source} />
          <ClassificationLegend
              colors={colors}
              style={{ position: "absolute", bottom: "86px", right: "16px" }}
              names={disasterInfoMap[warningType as DisasterKey].levels}
              colorPalette={globalColorPalette1}
              className="123 transform-control"
          />
        <Layer {...layer} />
      </>
    );
  }

  /**渲染病害 */
  renderDiseaseLayer = () => {
    const { date, cropType, startDate } = this.props;
    // if (!timeline) {
    //   return null;
    // }
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");

    const token = window.sessionStorage.getItem("token") as string;
    const cropName = cropType === "grassland" ? "grass" : cropType;
    console.log(startDate, "startDate");
    const source: LercTileSource = {
      id: cropType,
      type: "lerc_v2",
      maxzoom: 5,
      roundZoom: true,
      headers: {
        token,
      },
      tiles: [`http://zhongzhi.grassict.cn/mc-lerc/api/v1/plant/breed/disease/${moment().format("YYYYMMDD")}/${cropName}/${time}/{z}/{x}/{y}.lerc`],
    };

    const colorValue: [number, string][] = [
      [0, "#49b87d"],
      [1, "#bbe06c"],
      [2, "#ffe066"],
      [3, "#ff925c"],
      [4, "#e03a5c"],
      [5, "#e03a5c"],
      // [6, "#e61c30"],
    ];

    const layer: LercTileLayer = {
      id: cropType,
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[0, 5]],
        "raster-domain-min": 0,
        "raster-domain-max": 5,
        "raster-colorscale": {
          type: "interval",
          stops: colorValue as any,
        },
        "geojson-mask-source": baMengMapConfig.county.fill,
      },
      type: "lercv2" as "lercv2",
      source: cropType,
      before: baMengMapConfig.county.line,
    };
    const colors = colorValue.map((item, index) => ({ key: `${index}`, color: item[1], show: true })).slice(0, -1);
    return (
      <>
        <Source<LercTileSource>  {...source} />
          <ClassificationLegend
              key={cropType}
              colors={colors}
              style={{ position: "absolute", bottom: "86px", right: "16px" }}
              names={["一级", "二级", "三级", "四级", "五级"]}
              colorPalette={globalColorPalette1}
              className="123 transform-control"
            />
        <Layer {...layer} />
      </>
    );
  }

  /**渲染元素 */

  judgeElementName = (element: string) => {
    switch (element) {
      case "bd": return {
        name: "factor_b",
        date: "20201106", insideSorts: { c0: [1, 125], c1: [125, 135], c2: [135, 145], c3: [145, 155], c4: [155, 200] }}; break;
      case "an": return  {
        name: "factor_n",
        date: "20201103", insideSorts: { c0: [1, 65], c1: [65, 80], c2: [80, 100], c3: [100, 120], c4: [120, 500] } }; break;
      case "ap": return {
        name: "factor_p",
        date: "20201105", insideSorts: { c0: [100, 1500], c1: [1500, 3000], c2: [3000, 6000], c3: [6000, 9000], c4: [9000, 40000] }}; break;
      case "ak": return  {
        name: "factor_k",
        date: "20201104",
        insideSorts: { c0: [1000, 7000], c1: [7000, 10000], c2: [10000, 12500], c3: [12500, 15500], c4: [15500, 300000] }}; break;
      case "ph": return  {
        name: "factor_ph_1",
        date: "20201107",
        insideSorts: { c0: [700, 750], c1: [750, 800], c2: [800, 850], c3: [850, 900], c4: [900, 1000] }}; break;
      case "som": return {
        name: "factor_organic_1",
        date: "20201108",
        insideSorts: { c0: [0.1, 1000], c1: [1000, 1500], c2: [1500, 2000], c3: [2000, 2500], c4: [2500, 10000] } } ;
      default: return {
        name: "factor_b",
        date: "20201106",
        insideSorts: { c0: [1, 125], c1: [125, 135], c2: [135, 145], c3: [145, 155], c4: [155, 200] } };
    }
  }
  renderLandElementLayer = () => {
    const { region, cropType, element, changeRegion } = this.props;
    // const formatTime = moment(new Date().toDateString()).format("YYYYMMDD");
    // const realTime = timeData.find(item => moment(item).format('YYYY') === date);
    // console.log(timeData,date,'mapTimdatee')
    const elementInfo = this.judgeElementName(element);
    return (
          <ClassificationLayer<{
            c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
          }>
            key={elementInfo?.name}
            id={elementInfo?.name}
            url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${elementInfo?.name}/${elementInfo?.date}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
            paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${elementInfo?.name}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
            // tslint:disable-next-line: no-any
            insideSorts={elementInfo?.insideSorts as unknown as any}
            insideActiveSorts={"all"}
            before={baMengMapConfig.county.line}
          >
            { colors => (
              <ClassificationLegend
                {...{ ...colors }}
                style={{ position: "absolute", bottom: "16px", right: "16px" }}
                names={["极低", "低", "中", "高", "极高"]}
                colorPalette={globalColorPalette1}
                className="transform-control"
              />
            )}
          </ClassificationLayer>
    );

  }

  /**渲染lerc图层 */
  renderLercLayer = () => {
    const { timeData, date, cropType, cropName, isDataManage } = this.props;
    if (timeData.length === 0) return null;
    // if (cropType === "chilli") return null;
    const type = cropType === "chilli" ? "wheat" : cropType;
    const target = timeData.find(item => moment(item).years() === Number(date));
    console.log("****target", cropType);
    console.log(target, `http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_${switchQiePianName(type)}/${moment(target).format("YYYYMMDD")}`, "selecte------<date");
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3";
      }>
        id={`${cropType}_${date}`}
        key={`${cropType}_${date}`}
        url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_${switchQiePianName(type)}/${isDataManage === "on" ? this.matchDate(type) : moment(target).format("YYYYMMDD")}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        // paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_${switchQiePianName(type)}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1] }}
        insideColors={{
          c0: cropName === "辣椒" ? "red" : "#f06f51",
          c1: "#fdd55a",
          c2: "#59cb74",
          c3: "#899cf0",
        }}
        sourceMaxzoom={14}
        sourceMinzoom={0}
        insideActiveSorts={"all"}
      />
    );
  }

  /** 匹配作物lerc特定日期 */
  matchDate = (type: string) => {
    if (type === "corn") {
      return "20200802";
    }
    if (type === "sunflower") {
      return "20200803";
    }
    if (type === "wheat") {
      return "20200801";
    }
  }

  /** 匹配作物lerc特定日期 */
  matchDate1 = (type: string) => {
    if (type === "corn") {
      return "20201202";
    }
    if (type === "sunflower") {
      return "20201203";
    }
    if (type === "wheat") {
      return "20201201";
    }
  }
// tslint:disable-next-line: max-file-line-count
}
