import styled from "styled-components";

// tslint:disable-next-line:variable-name
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

    .page-box{
      width: 100%;
      background-color: #ffffff;
      height: 100%;
      .search-module{
        margin: 24px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .button-list{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
      }
      .table-module{
        height: calc(100% - 256px);
      }
      .ant-table-pagination.ant-pagination{
        margin: 16px;
      }
    }
  
`;