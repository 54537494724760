import React from "react";
import styled from "styled-components";
import { ICSSProps, ITable } from "@gago/frame";
import { ScrollTableHead } from "@gago/lib/components/scroll-table-head";
import { AutoScroll, AutoScrollProps } from "@gago/lib/components/auto-scroll";
import { ScrollTableContent } from "@gago/lib/components/scroll-table-content";


interface CP extends AutoScrollProps {
  /** 表格数据 */
  tableData: any;
}

const Wrap = styled.div`
  height: 100%;
  position: relative;
`;

/**
 * 滚动 表格
 * @author maoshengtao
 *
 */
export const ScrollTable = (props: CP & ICSSProps) => {
  const { style, className, tableData, ...other } = props;
  return (
    <Wrap style={style} className={className}>
      <ScrollTableHead headData={tableData.head} />
      <div style={{ height: "calc(100% - 40px)" }}>
        <AutoScroll
          {...other}
        >
          <ScrollTableContent chartData={tableData} />
        </AutoScroll>
        <div className="bottom-bg" />
      </div>
    </Wrap>
  );
};
