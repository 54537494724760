import React, { Component } from "react";
import { ExtensionScreenBody, PageBody, MarkerIcom } from "./style";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import { AppRouteNav, ScreenCard, McBarChart, McLineChart, PieChart, CropRatioCard, ColumnarBubble,
  DoubleBarChart } from "@gago/lib/components";
import { Icon, ITable } from "@gago/frame";
import { routesConfig, ChinaExtensionMapLayer, DirectionMap } from "./map-layer";
import { BaseLayer, MapGL, MapPosition, MapEvent } from "@gago-react-gl/gago-react-gl";
import { bmMapDefault } from "../../containers/map-layer";
import { Select, message, Tabs } from "antd";
import ExtensionScreenProvider from "../../network/providers/extension-screen.provider";
import { CompanyList, PopularizeOccupancy, PopularizeMapType } from "../../models/extension-screen";
import { MapContext } from "../../contexts/map";
import IframePage from "../../containers/iframe";
import { serverConfig } from "../../network/server-config";
import { debounce } from "lodash";
import UserProvider from "../../network/providers/user.provider";

// tslint:disable:jsx-no-multiline-js ter-max-len jsx-alignment
interface Gprops {}
interface Gstate {
  /** 县镇的code */
  townCode: string;
  /** 地图mapbox实例 */
  map: mapboxgl.Map;
  /** 公司数据列表 */
  companyList: CompanyList[];
  /** 推广全国统计数据获取 */
  popularizeRatio: ITable<1, 1> | null;
  /** 基地面积汇总 */
  popularizeBasearea: ITable<2, 1> | null;
  /** 服务资源统计 */
  popularizeStation: ITable<1, 2> | null;
  /** 作物占有率数据 */
  popularizeOccupancy: PopularizeOccupancy[];
  /** 品种审定证书 */
  popularizeCertificate: ITable<1, 1> | null;
  /** 销售地图数据 */
  popularizeMap: PopularizeMapType[];
  /** 推广数据排名 */
  popularizeRanking: ITable<1, 1> | null;
  /** 获取农事月度统计 */
  popularizeFarming: ITable<2, 1> | null;
  /** 获取农机服务月度统计 */
  popularizeTech: ITable<2, 1> | null;
  /** 获取二维码月度统计 */
  popularizeQr: ITable<2, 1> | null;
  /** 查询公司的id */
  companyId: string;
  /** 服务资源统计时间选择 */
  stationSelectYear: string;
  /** 服务资源统计时间列表 */
  stationYear: string[];
  /**缩放 */
  zoom: number;
}
/**
 * 种质推广溯源一张图
 * @author 马俊峰
 * @date 2020-08-25
 * @export
 * @class ExtensionScreen
 * @extends {Component<Gprops, Gstate>}
 */
export class MarketCirculation extends Component<Gprops, Gstate> {
  /** 地图交互图层事件 */
  baseMapStatus = true;
  state: Gstate = {
    townCode: "",
    /** mapbox实例 */
    map: undefined as unknown as mapboxgl.Map,
    companyList: [],
    popularizeRatio: null,
    popularizeBasearea: null,
    popularizeStation: null,
    popularizeOccupancy: [],
    popularizeCertificate: null,
    popularizeMap: [],
    popularizeRanking:  null,
    popularizeFarming:  null,
    popularizeTech:  null,
    popularizeQr:  null,
    companyId: "KHZY",
    stationSelectYear: "2019",
    stationYear: ["2019"],
    zoom: 0,
  };
  /** 地图实例加载 */
  mapOnLoad = (map: any) => {
    this.setState({ map: map.target });
    if (map) {
      window._map = map.target;
    }
  }

  handleZoom = (e: any) => {
    const tempZoom = e.target.transform.zoom;
    const { zoom } = this.state;
    console.log("zoom", zoom);
    if (tempZoom <= 4.71) {
      this.setState({ zoom: 4 });
    } else {
      this.setState({ zoom: 5 });

    }
    // if (tempZoom > 4.7) {
    //   this.setState({ zoom: 5 });
    // }
  }

  /** 滚动节流 */
  handleZoomDebounce = debounce(this.handleZoom, 300);

  /** 初始化一次加载 */
  initLoadData = async () => {
    const companyList = await ExtensionScreenProvider.getCompanyList().catch(err => { message.info("未查询到公司列表信息！", 1); return []; });
    const popularizeRatio = await ExtensionScreenProvider.getPopularizeRatio({}).catch(err => { message.info("未查询到推广全国统计信息！", 1); return null; });
    const popularizeBasearea = await ExtensionScreenProvider.getPopularizeBasearea({}).catch(err => { message.info("未查询到基地面积汇总信息！", 1); return null; });
    const popularizeOccupancy = await ExtensionScreenProvider.getPopularizeOccupancy({}).catch(err => { message.info("未查询到作物占有率信息！", 1); return []; });
    const popularizeCertificate = await ExtensionScreenProvider.getPopularizeCertificate({}).catch(err => { message.info("未查询到品种审定证书信息！", 1); return null; });
    this.setState({ companyList, popularizeRatio, popularizeBasearea, popularizeOccupancy, popularizeCertificate });
  }
  /** 公司id获取数据 */
  companyLoadData = async () => {
    const { companyId, stationSelectYear } = this.state;
    const param = { companyId };
    const param1 = { companyId, year: stationSelectYear };
    const params = {
      pageNum: 1,
      pageSize: 10,
      systemCode: "seedPopularizeMap",
    };
    const data: any = await UserProvider.getDomain(params).catch(err => {
      return [];
    });
    new ExtensionScreenProvider(data.rows.length > 0 ? data.rows[0].systemStatus : "off");
    const popularizeMap = await ExtensionScreenProvider.getPopularizeMap(param).catch(err => { message.info("未查询到销售地图信息！", 1); return []; });
    const popularizeRanking = await ExtensionScreenProvider.getPopularizeRanking(param).catch(err => { message.info("未查询到推广数据排名信息！", 1); return null; });
    const popularizeStation = await ExtensionScreenProvider.getPopularizeStation(param1).catch(err => { message.info("未查询到服务资源统计信息！", 1); return null; });
    const popularizeFarming = await ExtensionScreenProvider.getPopularizeFarming(param).catch(err => { message.info("未查询到农事月度统计信息！", 1); return null; });
    const popularizeTech = await ExtensionScreenProvider.getPopularizeTech(param).catch(err => { message.info("未查询到农机服务月度统计信息！", 1); return null; });
    const popularizeQr = await ExtensionScreenProvider.getPopularizeQr(param).catch(err => { message.info("未查询到二维码月度统计信息！", 1); return null; });
    this.setState({ popularizeMap, popularizeRanking, popularizeStation, popularizeFarming, popularizeTech, popularizeQr });
  }
  /** 公司服务资源统计年份获取 */
  getCompanyYear = async () => {
    const { companyId } = this.state;
    const param = { companyId, type: "station" };
    const stationYear = await ExtensionScreenProvider.getCompanyYear(param).catch(err => { message.info("未查询到服务资源统计年份列表！", 1); return ["2019"]; });
    const stationSelectYear = stationYear[0];
    this.setState({ stationYear, stationSelectYear });
  }
  /** 公司服务资源统计 */
  getPopularizeStation = async () => {
    const { companyId, stationSelectYear } = this.state;
    const param = { companyId, year: stationSelectYear };
    const popularizeStation = await ExtensionScreenProvider.getPopularizeStation(param).catch(err => { message.info("未查询到服务资源统计信息！", 1); return null; });
    this.setState({ popularizeStation });
  }
  async componentDidMount() {
    await this.initLoadData();
    await this.getCompanyYear();
    await this.companyLoadData();
  }
  render() {
    const { townCode, companyList, popularizeRatio, popularizeBasearea, popularizeStation, popularizeOccupancy, popularizeCertificate, popularizeMap, popularizeRanking,
            popularizeFarming, popularizeTech, popularizeQr, companyId, stationYear, stationSelectYear, zoom } = this.state;
    return(
      <PageBody>
        <div className="headerBox">
          <div className="header-content">
          </div>
        </div>
        <div className="contentBox">
          <Tabs type="card" >
                <Tabs.TabPane tab="销售路径" key="path">{this.renderMap()}</Tabs.TabPane>
                <Tabs.TabPane tab="销售统计" key="statistics">{this.renderStatistics()}</Tabs.TabPane>
          </Tabs>
        </div>
      </PageBody>
      // <ExtensionScreenBody colorPalette={colorPalette}>
      //   <div className="header-box">
      //     <div className="header-name">种质推广溯源一张图</div>
      //   </div>
      //   <div className="body-content">
      //     <div className="left-aside">
      //       <ScreenCard className="chart-box h-30" colorPalette={colorPalette} title="推广全国统计">
      //         {popularizeRatio && <PieChart
      //           legendShowPercent={false}
      //           chartData={popularizeRatio}
      //           chartStyle={chartStyle}
      //           colorPalette={pieChartColor}
      //         />}
      //       </ScreenCard>
      //       <ScreenCard className="chart-box h-20" colorPalette={colorPalette} title="全国前五省份排名">
      //         {popularizeRanking && <ColumnarBubble
      //           colorful
      //           mode="horizontal"
      //           barWidth={12}
      //           yLabel={false}
      //           chartStyle={chartStyle}
      //           chartData={popularizeRanking}
      //           colorPalette={pieChartColor}
      //         />}
      //       </ScreenCard>
      //       <ScreenCard className="chart-box h-20" colorPalette={colorPalette} title="基地面积汇总">
      //         {popularizeBasearea && <McBarChart
      //           chartData={popularizeBasearea}
      //           chartStyle={chartStyle}
      //           ySplitNumber={3}
      //           colorPalette={pieChartColor}
      //         />}
      //       </ScreenCard>
      //       <ScreenCard className="chart-box h-30" colorPalette={colorPalette} title="品种审定证书">
      //         {popularizeCertificate && <PieChart
      //           legendShowPercent={false}
      //           chartData={popularizeCertificate}
      //           chartStyle={chartStyle}
      //           colorPalette={pieChartColor}
      //         />}
      //       </ScreenCard>
      //     </div>
      //     <div className="middle-content">
      //       <div className="mc-chart-box">
      //         {popularizeOccupancy.map((item, index) => (
      //           <CropRatioCard
      //             key={index}
      //             title={item.cropName}
      //             className="chart-box"
      //             data={item.ratio}
      //             suffix="%"
      //             colorPalette={colorPalette}
      //           />
      //         ))}
      //       </div>
      //       <div className="mc-map-layer">
      //         <Select
      //           value={companyId}
      //           className="common-select-style mc-map-select"
      //           dropdownClassName="common-select-menu-style"
      //           style={{ width: 240 }}
      //           onChange={this.companyIdChange}
      //         >
      //           {companyList.map((item, index) => (
      //             <Select.Option value={item.companyId} key={index}>{item.company}</Select.Option>
      //           ))}
      //         </Select>
      //         <MapGL {...bmMapDefault} onLoad={this.mapOnLoad}>
      //           <MapPosition bbox={[[134.772579, 53.567791], [73.602256, 18.169338]]} moveMethod="jumpTo" />
      //           <ChinaExtensionMapLayer />
      //           <MapContext.Provider value={{ map: this.state.map }}>
      //             <MapContext.Consumer>
      //               {({ map }) => <DirectionMap mapData={popularizeMap} map={map} />}
      //             </MapContext.Consumer>
      //           </MapContext.Provider>
      //         </MapGL>
      //       </div>
      //     </div>
      //     <div className="left-aside">
      //       <ScreenCard className="chart-box" colorPalette={colorPalette} title="服务资源统计" rightDom={
      //         <Select
      //           value={stationSelectYear}
      //           className="common-select-style"
      //           dropdownClassName="common-select-menu-style"
      //           style={{ width: 90 }}
      //           onChange={this.companyYearChange}
      //         >
      //           {stationYear.map((item, index) => (
      //             <Select.Option value={item} key={index}>{item}年</Select.Option>
      //           ))}
      //         </Select>
      //       }>
      //         {popularizeStation && <DoubleBarChart
      //           chartData={popularizeStation}
      //           chartStyle={chartStyle}
      //           barWidth={12}
      //           barGap={0.1}
      //           colorPalette={{ ...colorPalette, subColor: ["#00fdff", "#ff7b2f"] }}
      //         />}
      //       </ScreenCard>
      //       <ScreenCard className="chart-box" colorPalette={colorPalette} title="农事月度统计">
      //         {popularizeFarming && <McLineChart
      //           chartData={popularizeFarming}
      //           showSmooth={false}
      //           showSymbol={false}
      //           chartStyle={chartStyle}
      //           ySplitNumber={3}
      //           colorPalette={pieChartColor}
      //         />}
      //       </ScreenCard>
      //       <ScreenCard className="chart-box" colorPalette={colorPalette} title="农技服务月度统计">
      //         {popularizeTech && <McLineChart
      //           chartData={popularizeTech}
      //           showSmooth={false}
      //           showSymbol={false}
      //           chartStyle={chartStyle}
      //           ySplitNumber={3}
      //           colorPalette={pieChartColor}
      //         />}
      //       </ScreenCard>
      //       <ScreenCard className="chart-box" colorPalette={colorPalette} title="二维码年度统计">
      //         {popularizeQr && <McBarChart
      //           chartData={popularizeQr}
      //           chartStyle={chartStyle}
      //           ySplitNumber={3}
      //           colorPalette={pieChartColor}
      //         />}
      //       </ScreenCard>
      //     </div>
      //   </div>
      // </ExtensionScreenBody>
    );
  }
  renderMap = () => {
    const { popularizeMap, companyId, companyList , zoom } = this.state;

    return (
      <div className="market-circulation-content">
        <Select
          value={companyId}
          className="common-select-style mc-map-select"
          dropdownClassName="common-select-menu-style"
          style={{ width: 240 }}
          onChange={this.companyIdChange}
        >
          {companyList.map((item, index) => (
            <Select.Option value={item.companyId} key={index}>{item.company}</Select.Option>
          ))}
        </Select>
      <MapGL {...bmMapDefault} onLoad={this.mapOnLoad}>
        <MapPosition bbox={[[160.33839876987815, 54.06890220044443], [47.22883532039921, 2.5141474768405203]]} moveMethod="jumpTo" />
        <BaseLayer id="tiandi_base_layer" type="TianDiTu_Satellite_Map"  />
        <ChinaExtensionMapLayer />
        {zoom !== 5 && <MarkerIcom coordinates={[124.558402233, 25.922278915]}>
            <div className="equipment-box">
              <div className="equipment-point">赤尾屿</div>
            </div>
          </MarkerIcom>}
        <MapEvent type={"zoom"} onChange={this.handleZoomDebounce} />
        <MapContext.Provider value={{ map: this.state.map }}>
          <MapContext.Consumer>
            {({ map }) => <DirectionMap mapData={popularizeMap} map={map} />}
          </MapContext.Consumer>
        </MapContext.Provider>
      </MapGL>
      </div>
    );
  }

  renderStatistics = () => {
    return (
      <div style={{ height: "100%", width: "100%" }} >
      <IframePage src={`${serverConfig.api_sy_v1}/data_sale/dataSale/dataSaleStatistics`}/>

      </div>
    );
  }
  /** 传入路由点击回调函数 */
  routeOnClickEvent = (key: any) => {
    console.log("***", key);
    // this.setState({ selected: key });
  }
  /** 公司数据查找事件 */
  companyIdChange = (type: any) => {
    this.setState({ companyId: `${type}` }, async () => {
      await this.getCompanyYear();
      await this.companyLoadData();
    });
  }
  /** 公司数据时间年限处理 */
  companyYearChange = (year: any) => {
    this.setState({ stationSelectYear: year }, this.getPopularizeStation);
  }
}
