import React, { Component } from "react";
import { observable, action, transaction } from "mobx";
import { observer } from "mobx-react";
import { Button, Table, Popconfirm, Radio, message } from "antd";
import { ColumnProps } from "antd/lib/table";

import UserManageStore from "../user-manage.store";

import styles from "./table.module.scss";
import moment from "moment";
import CropProvider from "../../../network/providers/crop.provider";
import { BaseFertilizerFormData, BaseFertilizerModalComponent } from "./base-fertilizer-modal";
import { FertilizerUseFormData, FertilizerUseModalComponent } from "./fertilizer-use-modal";
import { MedicineUseModalComponent, MedicineUseFormData } from "./medicine-use-modal";
import { WaterUseModalComponent, WaterUseFormData } from "./water-use-modal";
import { WeedControlModalComponent, WeedControlFormData } from "./weed-control-modal";

const pagination = {size: "small", showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ["5", "10", "20"]};

// tslint:disable:jsx-no-lambda
@observer
export class TableComponent extends Component {

    @observable title = "";
    @observable visible1 = false;
    @observable visible2 = false;
    @observable visible3 = false;
    @observable visible4 = false;
    @observable visible5 = false;
    @observable confirmLoading = false;
    @observable BaseFertilizerFormData: BaseFertilizerFormData = {
        province: "",
        city: "",
        district: "",
        plotName: "",
        breedName: "",
        methodName: "",
        useDate: "",
        beforePh: "",
        organicContent: "",
        nutrientContent: "",
        rainfall: "",
        airTemp: "",
        airHum: "",
        soilTemp: "",
        soilHum: "",
        accTemp: "",
        afterRead: "",
        detailList: [
            {
                fertilizerName: "",
                amount: "",
                unit: "",
            }
        ]
    };
    @observable FertilizerUseFormData: FertilizerUseFormData = {
        province: "",
        city: "",
        district: "",
        plotName: "",
        breedName: "",
        methodName: "",
        useDate: "",
        beforePh: "",
        organicContent: "",
        nutrientContent: "",
        rainfall: "",
        airTemp: "",
        airHum: "",
        soilTemp: "",
        soilHum: "",
        accTemp: "",
        afterRead: "",
        detailList: [
            {
                fertilizerName: "",
                amount: "",
                unit: "",
            }
        ]
    };
    @observable MedicineUseFormData: MedicineUseFormData = {
        province: "",
        city: "",
        district: "",
        plotName: "",
        breedName: "",
        methodName: "",
        useDate: "",
        beforePh: "",
        organicContent: "",
        nutrientContent: "",
        rainfall: "",
        airTemp: "",
        airHum: "",
        soilTemp: "",
        soilHum: "",
        accTemp: "",
        afterRead: "",
        detailList: [
            {
                medicineName: "",
                kind: "",
                amount: "",
                unit: "",
            }
        ]
    };
    @observable WaterUseFormData: WaterUseFormData = {
        province: "",
        city: "",
        district: "",
        plotName: "",
        breedName: "",
        methodName: "",
        useDate: "",
        beforePh: "",
        rainfall: "",
        airTemp: "",
        airHum: "",
        soilTemp: "",
        soilHum: "",
        accTemp: "",
        afterRead: "",
    };
    @observable WeedControlFormData: WeedControlFormData = {
        province: "",
        city: "",
        district: "",
        plotName: "",
        breedName: "",
        beforePh: "",
        organicContent: "",
        nutrientContent: "",
        rainfall: "",
        airTemp: "",
        airHum: "",
        soilTemp: "",
        soilHum: "",
        accTemp: "",
        afterRead: "",
        detailList: [
            {
                medicineName: "",
                method: "",
                amount: "",
                unit: "",
            }
        ]
    };
    @observable isEdit = false;

    componentWillMount() {
        UserManageStore.resetDepartmentId();
    }

    // tslint:disable no-unused-variable

    getTable(typeName: string) {
        let columns: ColumnProps<any>[] = [
            {
                title: "序号",
                key: "index",
                className: styles.tableColumn60,
                // @ts-ignore
                render: (text, record, index) => index + 1,
            },
            {
                title: "品种名称",
                dataIndex: "breedName",
                className: styles.tableColumn90,
            },
            {
                title: "地块",
                dataIndex: "plotName",
                className: styles.tableColumn90,
            },
            {
                title: "省",
                dataIndex: "province",
                className: styles.tableColumn90,
            },
            {
                title: "市",
                dataIndex: "city",
                className: styles.tableColumn90,
                // render: (text: string) => moment(text).format("YYYY-MM-DD"),
            },
            {
                title: "施肥方法",
                dataIndex: "methodName",
                className: styles.tableColumn90,
            },
            {
                title: "施肥时间",
                dataIndex: "useDate",
                className: styles.tableColumn90,
                render: (text: string) => moment(text).format("YYYY-MM-DD"),
            },
            {
                title: "用肥前土壤ph值",
                dataIndex: "beforePh",
                className: styles.tableColumn90,
            },
            {
                title: "施肥前有机质情况描述",
                dataIndex: "organicContent",
                className: styles.tableColumn160,
            },
            {
                title: "施肥前土壤养分含量",
                dataIndex: "nutrientContent",
                className: styles.tableColumn160,
            },
            {
                title: "操作",
                key: "action",
                className: styles.tableColumn120,
                // @ts-ignore
                render: (text, record) => {
                    return (
                        <>
                            <Popconfirm title="确认要删除此数据吗？" onConfirm={this.deleteBaseFertilizer(record.id)}>
                                <span className={styles.tableAction}>删除</span>
                            </Popconfirm>
                            <span className={styles.tableAction}
                                  onClick={() => this.editBaseFertilizer(record.id)}>编辑</span>
                        </>
                    );
                },
            },
        ];
        switch (typeName) {
            case "病虫害防治":
                columns = [
                    {
                        title: "序号",
                        key: "index",
                        className: styles.tableColumn60,
                        // @ts-ignore
                        render: (text, record, index) => index + 1,
                    },
                    {
                        title: "品种名称",
                        dataIndex: "breedName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "地块",
                        dataIndex: "plotName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "省",
                        dataIndex: "province",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "市",
                        dataIndex: "city",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "用药方式",
                        dataIndex: "methodName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "使用时间",
                        dataIndex: "useDate",
                        className: styles.tableColumn90,
                        render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "用肥前土壤ph值",
                        dataIndex: "beforePh",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "操作",
                        key: "action",
                        className: styles.tableColumn120,
                        // @ts-ignore
                        render: (text, record) => {
                            return (
                                <>
                                    <Popconfirm title="确认要删除此数据吗？"
                                                onConfirm={this.deleteMedicineUse(record.id)}>
                                        <span className={styles.tableAction}>删除</span>
                                    </Popconfirm>
                                    <span className={styles.tableAction}
                                          onClick={() => this.editMedicineUse(record.id)}>编辑</span>
                                </>
                            );
                        },
                    },
                ];
                break;
            case "追肥":
                columns = [
                    {
                        title: "序号",
                        key: "index",
                        className: styles.tableColumn60,
                        // @ts-ignore
                        render: (text, record, index) => index + 1,
                    },
                    {
                        title: "品种名称",
                        dataIndex: "breedName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "地块",
                        dataIndex: "plotName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "省",
                        dataIndex: "province",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "市",
                        dataIndex: "city",
                        className: styles.tableColumn90,
                        // render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "施肥方法",
                        dataIndex: "methodName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "追肥时间",
                        dataIndex: "useDate",
                        className: styles.tableColumn90,
                        render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "用肥前土壤ph值",
                        dataIndex: "beforePh",
                        className: styles.tableColumn90,
                        render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "施肥前有机质情况描述",
                        dataIndex: "organicContent",
                        className: styles.tableColumn160,
                    },
                    {
                        title: "施肥前土壤养分含量",
                        dataIndex: "nutrientContent",
                        className: styles.tableColumn160,
                    },
                    {
                        title: "操作",
                        key: "action",
                        className: styles.tableColumn120,
                        // @ts-ignore
                        render: (text, record) => {
                            return (
                                <>
                                    <Popconfirm title="确认要删除此数据吗？"
                                                onConfirm={this.deleteFertilizerUse(record.id)}>
                                        <span className={styles.tableAction}>删除</span>
                                    </Popconfirm>
                                    <span className={styles.tableAction}
                                          onClick={() => this.editFertilizerUse(record.id)}>编辑</span>
                                </>
                            );
                        },
                    },
                ];
                break;
            case "除草":
                columns = [
                    {
                        title: "序号",
                        key: "index",
                        className: styles.tableColumn60,
                        // @ts-ignore
                        render: (text, record, index) => index + 1,
                    },
                    {
                        title: "品种名称",
                        dataIndex: "breedName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "地块",
                        dataIndex: "plotName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "省",
                        dataIndex: "province",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "市",
                        dataIndex: "city",
                        className: styles.tableColumn90,
                        // render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "除草方法",
                        dataIndex: "method",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "除草时间",
                        dataIndex: "period",
                        className: styles.tableColumn90,
                        render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "用肥前土壤ph值",
                        dataIndex: "beforePh",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "施肥前有机质情况描述",
                        dataIndex: "organicContent",
                        className: styles.tableColumn160,
                    },
                    {
                        title: "施肥前土壤养分含量",
                        dataIndex: "nutrientContent",
                        className: styles.tableColumn160,
                    },
                    {
                        title: "操作",
                        key: "action",
                        className: styles.tableColumn120,
                        // @ts-ignore
                        render: (text, record) => {
                            return (
                                <>
                                    <Popconfirm title="确认要删除此数据吗？"
                                                onConfirm={this.deleteWeedControl(record.id)}>
                                        <span className={styles.tableAction}>删除</span>
                                    </Popconfirm>
                                    <span className={styles.tableAction}
                                          onClick={() => this.editWeedControl(record.id)}>编辑</span>
                                </>
                            );
                        },
                    },
                ];
                break;
            case "浇水":
                columns = [
                    {
                        title: "序号",
                        key: "index",
                        className: styles.tableColumn60,
                        // @ts-ignore
                        render: (text, record, index) => index + 1,
                    },
                    {
                        title: "品种名称",
                        dataIndex: "breedName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "地块",
                        dataIndex: "plotName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "省",
                        dataIndex: "province",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "市",
                        dataIndex: "city",
                        className: styles.tableColumn90,
                        // render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "浇水方法",
                        dataIndex: "methodName",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "浇水时间",
                        dataIndex: "useDate",
                        className: styles.tableColumn90,
                        render: (text: string) => moment(text).format("YYYY-MM-DD"),
                    },
                    {
                        title: "用水前土壤ph值",
                        dataIndex: "beforePh",
                        className: styles.tableColumn90,
                    },
                    {
                        title: "操作",
                        key: "action",
                        className: styles.tableColumn120,
                        // @ts-ignore
                        render: (text, record) => {
                            return (
                                <>
                                    <Popconfirm title="确认要删除此数据吗？" onConfirm={this.deleteWaterUse(record.id)}>
                                        <span className={styles.tableAction}>删除</span>
                                    </Popconfirm>
                                    <span className={styles.tableAction}
                                          onClick={() => this.editWaterUse(record.id)}>编辑</span>
                                </>
                            );
                        },
                    },
                ];
                break;
        }
        return (
            <Table
                className={styles.table}
                // @ts-ignore
                rowClassName={styles.tableRow}
                rowKey="id"
                dataSource={UserManageStore.listData}
                columns={columns}
                pagination={pagination}
            />
        );
    }

    @action.bound
    private editUser(record: any) {
        // transaction(() => {
        //     this.formData.userId = record.userId;
        //     this.formData.roleId = record.roleId;
        //     this.formData.displayName = record.displayName;
        //     this.formData.telephone = record.telephone;
        //     this.formData.departmentId = record.departmentId;
        //     this.formData.dataAuthorityId = record.dataAuthorityId;
        //     this.title = "编辑用户";
        //     this.visible1 = true;
        //     this.isEdit = true;
        // });
    }

    @action.bound
    private createInfo() {
        switch (UserManageStore.typeName) {
            case "病虫害防治":
                transaction(() => {
                    this.title = "添加病虫害防治";
                    this.visible2 = true;
                    this.isEdit = false;
                });
                break;
            case "追肥":
                transaction(() => {
                    this.title = "添加追肥";
                    this.visible3 = true;
                    this.isEdit = false;
                });
                break;
            case "除草":
                transaction(() => {
                    this.title = "添加除草";
                    this.visible4 = true;
                    this.isEdit = false;
                });
                break;
            case "浇水":
                transaction(() => {
                    this.title = "添加浇水";
                    this.visible5 = true;
                    this.isEdit = false;
                });
                break;
            default :
                transaction(() => {
                    this.title = "添加基肥";
                    this.visible1 = true;
                    this.isEdit = false;
                });
                break;
        }

    }

    @action.bound
    private exportInfo() {
        if (UserManageStore.varietyName) {
            switch (UserManageStore.typeName) {
                case "病虫害防治":
                    CropProvider.exportMedicineUse(UserManageStore.varietyName).then(res => {
                        this.downloadInfo(res);
                    });
                    break;
                case "追肥":
                    CropProvider.exportBaseFertilizerUse(UserManageStore.varietyName).then(res => {
                        this.downloadInfo(res);
                    });
                    break;
                case "除草":
                    CropProvider.exportWeedControl(UserManageStore.varietyName).then(res => {
                        this.downloadInfo(res);
                    });
                    break;
                case "浇水":
                    CropProvider.exportWaterUse(UserManageStore.varietyName).then(res => {
                        this.downloadInfo(res);
                    });
                    break;
                default :
                    CropProvider.exportBaseFertilizer(UserManageStore.varietyName).then(res => {
                        this.downloadInfo(res);
                    });
                    break;
            }
        }
    }

    private downloadInfo(filename: string) {
        if (filename) {
            CropProvider.download(filename);
        } else {
            message.success("暂无数据");
        }
    }

    @action.bound
    private cancelModal() {
        this.title = "";
        this.visible1 = false;
        this.visible2 = false;
        this.visible3 = false;
        this.visible4 = false;
        this.visible5 = false;
    }

    @action.bound
    private setConfirmLoading(value: boolean) {
        this.confirmLoading = value;
    }

    @action.bound
    private submitFormBaseFertilizer = async (FormData: BaseFertilizerFormData) => {
        this.setConfirmLoading(true);
        if (!FormData.id) {
            await CropProvider.addBaseFertilizer(FormData);
        } else {
            // @ts-ignore
            await CropProvider.updateBaseFertilizer(FormData);
        }
        await UserManageStore.getDataListActive();
        this.setConfirmLoading(false);
        this.cancelModal();
    };

    @action.bound
    private submitFormFertilizerUse = async (FormData: FertilizerUseFormData) => {
        this.setConfirmLoading(true);
        if (!FormData.id) {
            await CropProvider.addFertilizerUse(FormData);
        } else {
            // @ts-ignore
            await CropProvider.updateFertilizerUse(FormData);
        }
        await UserManageStore.getDataListActive();
        this.setConfirmLoading(false);
        this.cancelModal();
    };

    @action.bound
    private submitFormMedicineUse = async (FormData: MedicineUseFormData) => {
        this.setConfirmLoading(true);
        if (!FormData.id) {
            await CropProvider.addMedicineUse(FormData);
        } else {
            // @ts-ignore
            await CropProvider.updateMedicineUse(FormData);
        }
        await UserManageStore.getDataListActive();
        this.setConfirmLoading(false);
        this.cancelModal();
    };

    @action.bound
    private submitFormWaterUse = async (FormData: WaterUseFormData) => {
        this.setConfirmLoading(true);
        if (!FormData.id) {
            await CropProvider.addWaterUse(FormData);
        } else {
            // @ts-ignore
            await CropProvider.updateWaterUse(FormData);
        }
        await UserManageStore.getDataListActive();
        this.setConfirmLoading(false);
        this.cancelModal();
    };

    @action.bound
    private submitFormWeedControl = async (FormData: WeedControlFormData) => {
        this.setConfirmLoading(true);
        if (!FormData.id) {
            await CropProvider.addWeedControl(FormData);
        } else {
            // @ts-ignore
            await CropProvider.updateWeedControl(FormData);
        }
        await UserManageStore.getDataListActive();
        this.setConfirmLoading(false);
        this.cancelModal();
    };

    //删除基肥
    private deleteBaseFertilizer(id: number) {
        return () => {
            CropProvider.delBaseFertilizer(id).then(() => {
                UserManageStore.getDataListActive();
            });
        };
    }

    //删除用药
    private deleteMedicineUse(id: number) {
        return () => {
            CropProvider.delMedicineUse(id).then(() => {
                UserManageStore.getDataListActive();
            });
        };
    }

    //删除追肥
    private deleteFertilizerUse(id: number) {
        return () => {
            CropProvider.delFertilizerUse(id).then(() => {
                UserManageStore.getDataListActive();
            });
        };
    }

    //删除除草
    private deleteWeedControl(id: number) {
        return () => {
            CropProvider.delWeedControl(id).then(() => {
                UserManageStore.getDataListActive();
            });
        };
    }

    //删除浇水
    private deleteWaterUse(id: number) {
        return () => {
            CropProvider.delWaterUse(id).then(() => {
                UserManageStore.getDataListActive();
            });
        };
    }

    //编辑基肥
    private editBaseFertilizer(id: number) {
        CropProvider.getBaseFertilizerInfo(id).then((res) => {
            transaction(() => {
                this.BaseFertilizerFormData = res;
                this.BaseFertilizerFormData.provinceList = res.province;
                if (res.photo) {
                    this.BaseFertilizerFormData.file = [{url: "https://zhongzhi.grassict.cn" + res.photo, uid: 1}];
                }
                this.title = "编辑基肥";
                this.visible1 = true;
                this.isEdit = true;
            });
        });

    }

    //编辑用药
    private editMedicineUse(id: number) {
        CropProvider.getMedicineUseInfo(id).then((res) => {
            transaction(() => {
                this.MedicineUseFormData = res;
                if (res.photo) {
                    this.MedicineUseFormData.file = [{url: "https://zhongzhi.grassict.cn" + res.photo, uid: 1}];
                }
                this.title = "编辑用药";
                this.visible2 = true;
                this.isEdit = true;
            });
        });
    }

    //编辑追肥
    private editFertilizerUse(id: number) {
        CropProvider.getFertilizerUseInfo(id).then((res) => {
            transaction(() => {
                this.FertilizerUseFormData = res;
                this.title = "编辑追肥";
                if (res.photo) {
                    this.FertilizerUseFormData.file = [{url: "https://zhongzhi.grassict.cn" + res.photo, uid: 1}];
                }
                this.visible3 = true;
                this.isEdit = true;
            });
        });
    }

    //编辑除草
    private editWeedControl(id: number) {
        CropProvider.getWeedControlInfo(id).then((res) => {
            transaction(() => {
                this.WeedControlFormData = res;
                this.title = "编辑除草";
                if (res.photo) {
                    this.WeedControlFormData.file = [{url: "https://zhongzhi.grassict.cn" + res.photo, uid: 1}];
                }
                this.visible4 = true;
                this.isEdit = true;
            });
        });
    }

    //编辑浇水
    private editWaterUse(id: number) {
        CropProvider.getWaterUseInfo(id).then((res) => {
            transaction(() => {
                this.WaterUseFormData = res;
                this.title = "编辑浇水";
                if (res.photo) {
                    this.WaterUseFormData.file = [{url: "https://zhongzhi.grassict.cn" + res.photo, uid: 1}];
                }
                this.visible5 = true;
                this.isEdit = true;
            });
        });
    }

    render() {
        const radioList = [
            {name: "基肥", type: "1"},
            {name: "病虫害防治", type: "2"},
            {name: "追肥", type: "3"},
            {name: "除草", type: "4"},
            {name: "浇水", type: "5"}
        ];

        const yearList = [
            {name: "2020", value: 2020},
            {name: "2021", value: 2021},
            {name: "2022", value: 2022},
            {name: "2023", value: 2023},
            {name: "2024", value: 2024}
        ];
        const radioButton = radioList.map((item, index) =>
            <Radio.Button value={item.type}
                          onClick={() => UserManageStore.setTypeName(item.name)}>{item.name}</Radio.Button>);
        // const yearOption = yearList.map((item, index) =>
        //     <Option value={item.value}>{item.name}</Option>);
        // @ts-ignore
        return (
            <div className={styles.container}>
                <div className={styles.varietySearch}>
                    {/*<div>*/}
                    {/*    <Select style={{width: 200}} placeholder={'请选择年份'}>*/}
                    {/*        {yearOption}*/}
                    {/*    </Select>*/}
                    {/*</div>*/}
                    <div>
                        <Button type="primary" icon="plus" className={styles.createUserButton}
                                onClick={this.createInfo}>新建 </Button>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            icon="download"
                            className={styles.createUserButton}
                            onClick={this.exportInfo}
                        >导出
                        </Button>
                    </div>
                </div>
                <div>
                    <Radio.Group defaultValue="1" buttonStyle="solid">
                        {radioButton}
                    </Radio.Group>
                </div>
                {this.getTable(UserManageStore.typeName)}
                <BaseFertilizerModalComponent
                    title={this.title}
                    visible={this.visible1}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.BaseFertilizerFormData}
                    submitForm={this.submitFormBaseFertilizer}
                    edit={this.isEdit}
                />
                <FertilizerUseModalComponent
                    title={this.title}
                    visible={this.visible3}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.FertilizerUseFormData}
                    submitForm={this.submitFormFertilizerUse}
                    edit={this.isEdit}
                />
                <MedicineUseModalComponent
                    title={this.title}
                    visible={this.visible2}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.MedicineUseFormData}
                    submitForm={this.submitFormMedicineUse}
                    edit={this.isEdit}
                />
                <WaterUseModalComponent
                    title={this.title}
                    visible={this.visible5}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.WaterUseFormData}
                    submitForm={this.submitFormWaterUse}
                    edit={this.isEdit}
                />
                <WeedControlModalComponent
                    title={this.title}
                    visible={this.visible4}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.WeedControlFormData}
                    submitForm={this.submitFormWeedControl}
                    edit={this.isEdit}
                />
            </div>
        );
    }
}
