// tslint:disable:jsx-no-multiline-js
import React from "react";
import qs from "query-string";
import { has, isEmpty, noop, cloneDeep } from "lodash";
import { ColorProps } from "@gago/frame/es/interface";
import { BaseLayer, Control, MapPosition } from "@gago-react-gl/core";
import { RouteComponentProps, withRouter } from "react-router";
import { SideBar4 } from "@gago/frame/es/all";
import { SliderValue } from "antd/lib/slider";
import styled from "styled-components";

import { withContext } from "../../contexts";
import { Switch } from "../../src-anxi/components/switch";
import { Card1 } from "../../src-anxi/components/card1";
import { BaseMap } from "../../src-anxi/containers/base-map";
import { GlobalColorPalette, colorPalette1 as defaultColorPalette, colorPalette } from "../../src-anxi/color-palette";
import { MAP_IDS } from "../../src-anxi/containers/config";
import { CountyLayer } from "../../src-anxi/containers/county-layer";
import { TownLayer } from "../../src-anxi/containers/town-layer";
import { TeaGardenDetail } from "../../src-anxi/models/garden-big-screen";
import { TeaGardenMark } from "../../src-anxi/containers/tea-garden-mark";
import { HighTown } from "../../src-anxi/containers/high-town";
import { Slider } from "../../src-anxi/components/slider";
import { TeaGardenState, TeaGardenContext } from "../../src-anxi/contexts/tea-garden";
import { MapAnalysisMask } from "./map-analysis-mask";
import { tuple } from "../../src-anxi/util/type";
import { GeoJSONSource, Source, Layer, FillLayer } from "@gago-react-gl/gago-react-gl";
import { handleMaskAndSlider, sliderList1 } from "./map-analysis-const";
import { scalePage } from '../../decorator/scale-page';

const tab = [
  {
    label: "有机质及物理指标",
    value: "0",
  },
  {
    label: "土壤元素",
    value: "1",
  },
];

const cropTypes = tuple("corn", "wheat", "sunflower");
export type CropType = (typeof cropTypes)[number];
const cropTab: { label: string; value: CropType }[] = [
  { label: "玉米", value: cropTypes[0] },
  { label: "小麦", value: cropTypes[1] },
  { label: "向日葵", value: cropTypes[2] },
];

type SuitabilityEvaluationMapAnalysisProps = {
} & RouteComponentProps & ColorProps<GlobalColorPalette> & TeaGardenState;

interface SuitabilityEvaluationMapAnalysisState {
  /** 作物类型 */
  cropType: CropType,
  /** 茶园详情列表信息 */
  teaGardensDetail: TeaGardenDetail[];
  /** 选中的茶园id */
  teaId: string | number;
  /** 所在的乡镇名称 */
  location: string;
  /** 茶庄名称 */
  teaName: string;
  /** 控制范围 */
  range: any;
  /**
   * 当前哪个掩模生效
   * 0: 生态适宜度
   * 1: 生产适宜度
   */
  sliderIndex: number;
  /** sliderList */
  sliderList: any;
  /** maskConfig */
  maskConfig: any;
}

/**
 * 数字茶园-适宜性评价-智能分析地图
 * @author luyaxing
 * @date 2019.6.19
 * @export
 * @class SuitabilityEvaluationMapAnalysis
 * @extends {React.PureComponent<SuitabilityEvaluationMapAnalysisProps, SuitabilityEvaluationMapAnalysisState>}
 */
class SuitabilityEvaluationMapAnalysis extends
React.PureComponent<SuitabilityEvaluationMapAnalysisProps, SuitabilityEvaluationMapAnalysisState> {
  constructor(props: SuitabilityEvaluationMapAnalysisProps) {
    super(props);
    const { search } = props.location;
    const { teaId, teaName, location } = qs.parse(search);
    
    const rangeList: {
      [key: string] : number[],
    } = {};
    const { maskConfig, sliderList} = handleMaskAndSlider() as any;
    sliderList.map((sub: any, index: number) => {
      rangeList[sub] =  maskConfig[sub].sliderConfig.defaultValue;
    });
    this.state = {
      sliderList,
      maskConfig,
      cropType: cropTypes[0],
      /** 茶园信息 */
      teaGardensDetail: [],
      teaName: (typeof teaName === "string") ? teaName : "",
      location: (typeof location === "string") ? location : "",
      teaId: ((typeof teaId === "string") || (typeof teaId === "number")) ? teaId : "",
      range: rangeList,
      sliderIndex: 0,
    };
  }

  static defaultProps = {
    colorPalette: defaultColorPalette,
  };

  componentDidMount() {
    this.initData();
  }

  /** 初始化茶园数据状态 */
  initData = async () => {
    const activeInfo = this.getActiveTeaGarden();
    /** 初始化加载茶园信息 */
    const teaGardensDetail = await this.getTeaGardensDetail();
    /** 加载时如果已经初始化active tea garden */
    if (!has(activeInfo, "teaId") && !isEmpty(teaGardensDetail)) {
      const initActiveTeaId = teaGardensDetail[0].id;
      this.updateActiveTeaGarden(initActiveTeaId);
    }
  }

  /** 获取茶园列表 */
  getTeaGardensDetail = async () => {
    const teaGardensDetail = await this.props.getTeaGardenList(); 
    this.setState({ teaGardensDetail });
    return teaGardensDetail;
  }

  /** 更新url */
  updateActiveTeaGarden = (teaId: number) => {
    const { teaGardensDetail } = this.state;
    const { history, location: { search } } = this.props;
    const currentTeaGardenDetail = teaGardensDetail.find(item => item.id === teaId);
    if (currentTeaGardenDetail) {
      const { location } = currentTeaGardenDetail;
      const prevSearch = qs.parse(search, { parseNumbers: true });
      const nextSearch = qs.stringify({ ...prevSearch, teaId, location });
      history.replace({ search: nextSearch });
    }
  }

  getActiveTeaGarden = () => {
    const { location: { search } } = this.props;
    return qs.parse(search, { parseNumbers: true });
  }

  /** 获取当前选中的茶园id */
  getActiveTeaGardenId = () => {
    const { location: { search } } = this.props;
    const { teaGardensDetail } = this.state;
    const { teaId } = qs.parse(search, { parseNumbers: true });
    const activeTeaGardenDetail = teaGardensDetail.find(item => item.id === teaId);
    return activeTeaGardenDetail ? activeTeaGardenDetail.id : null;
  }
  
  /** 监听切换 */
  handleCropSwitch = (value: string, index: number) => {
    const { cropType } = this.state;
    let rangeList: {
      [key: string]: number[],
    } = {};
    const { maskConfig: mC, sliderList: sL } = handleMaskAndSlider(cropType) as any;
    sL.map((sub: any, index: number) => {
      rangeList[sub] = mC[sub].sliderConfig.defaultValue;
    });
    this.setState({
      cropType: cropTypes[index],
      range: rangeList,
      sliderList: sL,
      maskConfig: mC,
    });
  }


  /** 监听切换 */
  handleSwitch = (value: string, index: number) => {
    this.setState({
      sliderIndex: index,
    });
  }

  /** 渲染茶园marker */
  renderTeaGardenMark = () => {
    const { teaGardensDetail } = this.state;
    const { colorPalette } = this.props;
    const activeTeaGardenId = this.getActiveTeaGardenId();
    return teaGardensDetail.map(item => (
      <TeaGardenMark
        key={item.id}
        active={activeTeaGardenId === item.id}
        coordinates={item.lonlat}
        colorPalette={colorPalette}
        name={item.name}
        location={item.location}
        onClick={this.updateActiveTeaGarden.bind(this, item.id)}
      />
    ));
  }

  /** 获取茶园所在乡镇信息 */
  getActiveTownCode = () => {
    const { teaGardensDetail } = this.state;
    const { location: { search } } = this.props;
    const { teaId } = qs.parse(search, { parseNumbers: true });
    const activeTeaGardenDetail = teaGardensDetail.find(item => item.id === teaId);
    return activeTeaGardenDetail ? activeTeaGardenDetail.code : null;
  }

  /** 渲染行政区划 */
  renderDecorateLayer = () => {
    return (
      <>
        <CountyLayer
          fillOption={{
            paint: {
              "fill-opacity": 0.12,
              "fill-color": "#34d0c8",
            },
          }}
          lineOption={{
            paint: {
              "line-opacity": 0.91,
              "line-color": "#ffffff",
            },
          }}
        />
        <TownLayer
          lineOption={{
            paint: {
              "line-opacity": 0.7,
              "line-color": "#ffffff",
            },
          }}
          symbolOption={{
            paint: {
              "text-color": "rgba(0,0,0,0)",
              "text-halo-color": "rgba(0,0,0,0)",
            },
          }}
        />
      </>
    );
  }

  /** slider组件拖动切换 */
  handleSliderChange = (index: number, value: SliderValue) => {
    const { cropType, sliderList } = this.state;
    const nextRange: any = { ...this.state.range };
    nextRange[sliderList[index]] = value;

    this.setState({
      range: nextRange,
    });
  }

  /** 渲染卡片内容 */
  renderSliderContent = () => {
    const { range, cropType, sliderIndex, sliderList, maskConfig } = this.state;
    const { colorPalette } = this.props;
    if (sliderIndex === 0) {

    return sliderList.map((sub: any, index: number) => {
      if (index > 2) {
        return null;
      }
      const currentMaskConfig = maskConfig[sub];
      const { sliderConfig: value, mapConfig } = currentMaskConfig;
      // if (cropType === "corn" && sub === "temperature") return null;
      // if (cropType !== "corn" && sub === "cornTemperature") return null;

      return (
        <Slider
          key={mapConfig.id}
          icon={value.icon}
          title={value.title}
          unit={value.unit}
          colorPalette={colorPalette}
          onChange={this.handleSliderChange.bind(this, index)}
          config={{
            step: value.step ? value.step : 0.01,
            max: value.max ? value.max : 100,
            min: value.min ? value.min : 0,
            defaultValue: range[sub],
          }}
          hiddenBottomLine={index === 3 ? true : undefined}
        />
      );
    });
  }
  if (sliderIndex === 1) {

    return sliderList.map((sub: any, index: number) => {
      if (index <= 2) {
        return null;
      }
      const currentMaskConfig = maskConfig[sub];
      const { sliderConfig: value, mapConfig } = currentMaskConfig;
      // if (cropType === "corn" && sub === "temperature") return null;
      // if (cropType !== "corn" && sub === "cornTemperature") return null;
      return (
        <Slider
          key={mapConfig.id}
          icon={value.icon}
          title={value.title}
          unit={value.unit}
          colorPalette={colorPalette}
          onChange={this.handleSliderChange.bind(this, index)}
          config={{
            step: value.step ? value.step : 0.01,
            max: value.max ? value.max : 100,
            min: value.min ? value.min : 0,
            defaultValue: range[sub],
          }}
          hiddenBottomLine={index === (sliderList.length - 1) ? true : undefined}
        />
      );
    });
  }
}

  sliderBorder = styled.div`
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
  `;

  render() {
    const { colorPalette } = this.props;
    const { range, sliderIndex } = this.state;
    const tempCard1ColorPalette = cloneDeep(colorPalette);
    tempCard1ColorPalette.whiteColor[8] = "#f7fafa";
    tempCard1ColorPalette.shadow[0] = "none";
    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <BaseMap showLngLat>
        <Control
          type="Scale"
          position="bottom-left"
        />
        <Control
          type="Navigation"
          position="bottom-left"
        />
        <BaseLayer
          type="TianDiTu_Satellite_Map"
          id={MAP_IDS.baseMap}
          layerOption={{ before: MAP_IDS.zIndex0 }}
        />
        <BaseLayer
          type="TianDiTu_Satellite_Annotion"
          id={MAP_IDS.baseAnnotionMap}
          layerOption={{ before: MAP_IDS.zIndex3 }}
        />
        <HighTown townId={this.getActiveTownCode()} />
        {this.renderDecorateLayer()}
        {/* {this.renderTeaGardenMark()} */}
        {/* {this.renderTypeSwitch()} */}
        <MapAnalysisMask
          colorPalette={colorPalette}
          range={range}
          sliderIndex={sliderIndex}
          cropType={this.state.cropType}
        />
        <SideBar4
          className="tabWrap"
          colorPalette={colorPalette}
          sizeOnChange={noop}
          theme="light"
          style={{ width: 400 }}
          title={
            <Switch
              whiteHighlight
              colorPalette={colorPalette}
              onSwitch={this.handleSwitch}
              data={tab}
            />}
        >
          <Card1
            hiddenTitle
            colorPalette={tempCard1ColorPalette}
          >
            <this.sliderBorder>
              {this.renderSliderContent()}
            </this.sliderBorder>
          </Card1>
        </SideBar4>
        {this.props.children}
      </BaseMap>
      </div>
    );
  }

  renderTypeSwitch() {
    return (
      <Switch
        colorPalette={colorPalette}
        data={cropTab}
        onSwitch={this.handleCropSwitch}
        style={{ position: "absolute", top: 8, left: 8, background: "white" }}
      />
    )
  }
}

export default withRouter(withContext(TeaGardenContext)(SuitabilityEvaluationMapAnalysis));
// export default scalePage(1920, 1080)(withRouter(withContext(TeaGardenContext)(SuitabilityEvaluationMapAnalysis)));

