import React from "react";
import styled from "styled-components";
import QRCode from "qrcode.react";
import { ICSSProps } from "@gago/frame";

import bg from "./card-bg@2x.png";
import { Icon } from "antd";

const Wrap = styled.div`
  width: 171px;
  height: 130px;
  display: flex;
  align-items: center;
  background-image: url(${bg});
  background-size: 100% 100%;
  justify-content: space-around;
  .left-btn,
  .right-btn {
    margin-bottom: 30px;
    width: 24px;
    height: 24px;
    color: #65fbff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #65fbff;
    background-color: rgba(0, 253, 255, 0.15);
    cursor: pointer;
  }
  .center-content {
    width: 91px;
    overflow-x: hidden;
    .scroll-box {
      display: flex;
      /* transition: 0.3s ease; */
    }
    .carousel-box {
      width: 91px;
      .carousel-content {
        width: 91px;
        height: 91px;
        margin-bottom: 12px;
      }
      .carousel-bottom {
        width: 91px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #65fbff;
        font-size: 14px;
        text-align: center;
      }
    }
  }
`;

interface P extends ICSSProps {
  /** 数据集合 */
  data: {
    /** 链接 */
    link: string;
    /** 名称 */
    name: string;
  }[];
  /**二维码点击事件 */
  handleQrcodeClick?(url: string): void;
}

interface S {
  /** 当前是哪个卡片 */
  currentInd: number;
  /** 是否使用 transition */
  useTranstioin: boolean;
}

export class CarouselCard extends React.PureComponent<P, S> {
  static defaultProps = {
    data: [
      {
        link: "1",
        name: "玉米种质基地",
      },
      {
        link: "2",
        name: "草莓种质基地",
      },
      {
        link: "3",
        name: "戳种质基地",
      },
    ],
  };
  maxLen: number;
  scroll: HTMLDivElement | null | undefined;

  constructor(props: P) {
    super(props);
    const { data } = this.props;
    this.state = {
      currentInd: 0,
      useTranstioin: true,
    };
    this.maxLen = data.length;
  }

  handleLeftClick = () => {
    const { currentInd } = this.state;
    if (currentInd > 0) {
      this.setState({ currentInd: currentInd - 1 });
    } else {
      this.setState({ currentInd: this.maxLen - 1});
    }
  };

  handleRightClick = () => {
    const { currentInd } = this.state;
    if (currentInd < this.maxLen - 1) {
      this.setState({ currentInd: currentInd + 1 });
    } else {
      this.setState({ currentInd: 0 });
    }
  };
  /**点击二维码事件 */
  handleQrCodeClick = (url: string) => {
    this.props.handleQrcodeClick && this.props.handleQrcodeClick(url);
  }

  render() {
    const { data } = this.props;
    const { currentInd, useTranstioin } = this.state;
    return (
      <Wrap>
        <div className="left-btn" onClick={this.handleLeftClick}>
          <Icon type="left" />
        </div>
        <div className="center-content">
          <div
            className="scroll-box"
            ref={(el) => this.scroll = el}
            style={{
              width: (this.maxLen) * 91,
              transform: `translateX(${currentInd * -91}px)`,
            }}
          >
            {data && data.map((item) => {
                return (
                  <div className="carousel-box" key={item.name} onClick={() => this.handleQrCodeClick(item.link)}>
                    <div className="carousel-content">
                      <QRCode
                        value={item.link}
                        size={91}
                        fgColor="#000000"
                        level="M"
                      />
                    </div>
                    <div className="carousel-bottom" title={item.name}>{item.name}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="right-btn" onClick={this.handleRightClick}>
          <Icon type="right" />
        </div>
      </Wrap>
    );
  }
}
