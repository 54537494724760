import { ResultType } from "../../models/common";
import * as DictionaryInterface from "../../models/dictionary-base";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";

/**
 * 数据字典 基地管理
 * @author maoshengtao
 */
export default class DictionaryBase extends BaseProvider {

  /** 获取行政区划 */
  static async getRegionTree() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DictionaryInterface.RegionTree[]>>("/plant/region/tree");
    return data;
  }

  /** 公司数据列表 */
  static async getCompanyList() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DictionaryInterface.ComponyList[]>>("/plant/expand/company", { });
    return data;
  }

  /** 园区基地类型列表查询 */
  static async getParkBaseType() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DictionaryInterface.ParkBaseType[]>>("/northwest/park/base/type", { });
    return data;
  }

  /** 下拉 基地列表查询 */
  static async getBaseLandListAll() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DictionaryInterface.BaseLandAll[]>>("/baseland/all", { });
    return data;
  }

  /** 基地详情获取 */
  static async getBaseLandDetail(id: string) {
    const { data } = await BaseRequestStaticServer.get<ResultType<DictionaryInterface.BaseLandDetail>>(`/baseland/one?id=${id}`);
    return data;
  }

  /** 表格 基地列表获取 */
  static async getBaseLandList(params: DictionaryInterface.BaseLandListRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<DictionaryInterface.BaseLandList>>("/baseland/list", {
      params,
    });
    return data;
  }

  /** 基地信息更新 */
  static async getBaseLandUpdate(params: DictionaryInterface.BaseLandUpdateRequest) {
    const { data } = await BaseRequestStaticServer.post("/baseland/update", { 
      ...params,
    });
    return data;
  }
}
