import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
import axios from 'axios'
// tslint:disable-next-line: ter-max-len
import { BaseExpertInfo, exportDataRequest, cateRequest, ExpertCateInfo, delType, AreaList, ExpertRequestList, ExpertListType, ExpertEditRequest, DistributionProvince, DistributionWorkingSeniority, DistributionSpecialty, DistributionProvinceRequest, ExpertInterestRequestList } from "../../models/expert-database";
import { ITable } from "@gago/frame";
// tslint:disable:ter-max-len

/**
 * 专家知识库模块
 * @author wangchengbing
 */
export default class ExpertDataBaseProvider extends BaseProvider {
  /** 获取专家分类/类型 */
  static async asyncExpertCateInfo(params:cateRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpertCateInfo[]>>(`/codelist/type`,{
      params
    });
    data.unshift({
      id:-1,
      code:null,
      name:'全部',
      value:null,
      parentId:null,
      type:'',
      remarks:null
    })
    return data;
  }

  /** 获取 地区区域数据 */
  static async getAreaList() {
    const { data } = await BaseRequestStaticServer.get<ResultType<AreaList[]>>("/plant/region/tree");
    
    return data;
  }

  /** 获取 地区专家列表数据 */
  static async getExpertList(params:ExpertRequestList) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpertListType>>("/plant/expert/list",{
      params
    });
    return data;
  }

  /** 获取 专家从业年限人员分布数据 */
  static async getDistributionWorkingSeniority() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DistributionWorkingSeniority[]>>("/plant/expert/distribution/workingSeniority");
    return data;
  }

  /** 获取 专家省份分布数据 */
  static async getDistributionProvince() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DistributionProvince[]>>("/plant/expert/distribution/provinceName");
    return data;
  }

  /** 获取 专家特长分布数据 */
  static async getDistributionSpecialty() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DistributionSpecialty[]>>("/plant/expert/distribution/specialty");
    return data;
  }

    /** 删除专家数据 */
    static async deleteExpert(params:delType) {
      const data:ResultType<number> = await BaseRequestStaticServer.post("/plant/expert/del",{
        ...params
      });
      return data;
    }

    /** 新增专家数据 */
    static async addExpert(params:BaseExpertInfo) {
      console.log('add',params)
      const  data:ResultType<number>  = await BaseRequestStaticServer.post("/plant/expert/add",
        params
      );
      return data;
    }

    /** 更新专家数据 */
    static async updateExpert(params:ExpertEditRequest) {
      console.log('update',params)
      const  data:ResultType<number>  = await BaseRequestStaticServer.post("/plant/expert/update",
        params
      );
      return data;
    }

    /** 获取感兴趣专家数据 */
    static async getInterestedExpert(params:ExpertInterestRequestList) {
      const { data }  = await BaseRequestStaticServer.get<ResultType<ExpertEditRequest[]>>("/plant/expert/specialty/provinceName",{
        params
      });
      return data;
    }

    /** 下载数据 */
    static async downloadModule() {
      const  { data } = await BaseRequestStaticServer.get<ResultType<string>>("/plant/expert/templateUrl");
      return data;
    }

    /** 导出 专家信息数据 */
    static async exportData(params:exportDataRequest) {
      const  data  = await BaseRequestStaticServer.get<ResultType<any>>("/plant/expert/export",{
        params
      });
      return data;
    }
    
      // 下载文件
    
    static async download(url:string) {
        await BaseRequestStaticServer.downloadFile(url,'专家数据.xlsx');
    }
// 下载模板
    static async getTemplate(url:string) {
      // await BaseRequestStaticServer.downloadFile(url,'专家模板.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',url);
      const token = window.sessionStorage.getItem('token') as string;
      axios({
        url,
        method:'get',
        headers:{token},
        responseType:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
  }
}
