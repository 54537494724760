import styled from "styled-components";
import { ColorProps, GlobalColorPalette, ITable } from "@gago/frame";
import { RiskIndex } from "../../models/germplasm-breed";
import { Marker } from "@gago-react-gl/gago-react-gl";

// tslint:disable-next-line:variable-name
export const ExtensionScreenBody = styled.div<ColorProps<GlobalColorPalette>>`
  height: 100%;
  width: 100%;
  background-color: #09131f;
  background-image: url("/static/images/big-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .header-box{
    height: 80px;
    width: 100%;
    background-image: url("/static/images/top-title.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 3;
    .header-name{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 3px;
      color: #fff;
    }
  }
  .body-content{
    height: calc(100% - 80px);
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    .left-aside{
      flex: 1 0 auto;
      width: 460px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .chart-box{
        flex: 1 1 auto;
        height: 25%;
        margin-top: 12px;
        &:first-of-type{
          margin-top: 0px;
        }
      }
      .h-30{
        height: 30%;
      }
      .h-20{
        height: 20%;
      }
    }
    .middle-content{
      flex: 1 0 auto;
      width: 930px;
      margin: 0 12px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .mc-map-layer{
        padding-top: 16px;
        height: 610px;
        flex: 1 2 auto;
        position: relative;
        .react-mapbox--container{
          .mapboxgl-canvas{
            outline: none;
            overflow: hidden;
          }
        }
        .mc-map-select{
          position: absolute;
          top: 16px;
          right: 0;
          z-index: 99;
          font-size: 14px;
          .ant-select-selection--single{
            height: 32px;
          }
          .ant-select-selection__rendered{
            line-height: 30px;
          }
          .ant-select-arrow{
            font-size: 14px;
          }
        }
      }
      .mc-chart-box{
        height: 108px;
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-start;
        .chart-box{
          flex: 1 1 auto;
          margin-right: 8px;
          &:last-of-type{
            margin-right: 0px;
          }
        }
      }
    }
  }
`;


export const PageBody = styled.div`
  background-color:#f1f5f5;
  /* position:relative; */
  height:100%;
  /* z-index:-2; */
  .headerBox {
    background-color:#00adad;
    width:100%;
    position:absolute;
    height:160px;
    /* border-radius:4px; */
    z-index:1;

    .header-content {
      height:100px;
      padding-top:36px;
      .selectLabel {
        margin-left:40px;

      }
      .mc-map-select {
        /* margin-left:40px; */
      }
    
    }
    
  }
  .contentBox {
    /* padding: 0 15px 15px 15px; */
    border-radius:10px;
    top:30px;
    position:relative;
    height:calc(100% - 18px);
    z-index:2;

    .mc-map-select{
      position: absolute;
      top: 16px;
      right: 0;
      z-index: 99;
      font-size: 14px;
      .ant-select-selection--single{
        height: 32px;
      }
      .ant-select-selection__rendered{
        line-height: 30px;
      }
      .ant-select-arrow{
        font-size: 14px;
      }
    }
    .ant-tabs {
      height:100%;
      border-radius:10px;
      .ant-tabs-tab {
        border-radius:8px 8px 0 0 !important;

      }
      .ant-tabs-tab-active {
        background: rgb(139, 195, 74)!important;
        border:none!important;
        color:#fff!important;
      }
      .ant-tabs-bar {
        margin:0;
      }
      .ant-tabs-content {
        height:calc(100% - 40px);
        /* padding-top:16px; */
        background-color:#fff;
        border-radius:0 0 10px 10px;

        .ant-tabs-tabpane-active {
          height:100%;
          position:relative;

          .market-circulation-content {
            background:#fff;
            width:100%;
            height:100%;
            /* height:calc(100% - 40px); */
            /* position:relative; */

  }
        }
      }
    }
  }
  /* .content {
    background:#fff;
    width:100%;


  } */
`

export const MarkerIcom = styled(Marker)`
  pointer-events: auto;
  transition: zIndex 0.3s ease-out;
  z-index: 1;
  position: absolute !important;
  left: 0;
  right: 0;
  .equipment-box{
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    position: absolute;
  }
  .equipment-point{
    pointer-events: all;
    position: absolute;
    top: -10px;
    left: 10px;
    color: #f2f2f2;
    width: 40px;
    text-align: center;
    font-size:10px;
    font-family: Arial Unicode MS Regular;
    text-shadow: 1px 1px #000;
  }
`;
