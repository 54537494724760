import {observable, action, computed, reaction} from 'mobx';

import CropProvider from "../../network/providers/crop.provider";

export interface CityList {
    name: string;
    id: number;
}


const getDataList = async (varietyName: string, typeName: string) => {
    if (varietyName) {
        switch (typeName) {
            case "病虫害防治":
                return await CropProvider.getMedicineUseList(varietyName).then(res => {
                    return res
                })
                break;
            case "追肥":
                return await CropProvider.getFertilizerUseList(varietyName).then(res => {
                    return res
                })
                break;
            case "除草":
                return await CropProvider.getWeedControlList(varietyName).then(res => {
                    return res
                })
                break;
            case "浇水":
                return await CropProvider.getWaterUseList(varietyName).then(res => {
                    return res
                })
                break;
            default :
                return await CropProvider.getBaseFertilizerList(varietyName).then(res => {
                    return res
                })
                break;
        }
    } else {
        return new Promise(resolve => {
            resolve([])
        })
    }
}


class UserManageStore {
    // @ts-ignore
    @observable varietyName: string = '';//品种名称
    @observable typeName: string = '';//类型名称
    @observable.ref listData: any[] = [];
    @observable.ref provinceList: CityList[] = [];
    @observable.ref cityList: CityList[] = [
        {id: 0, name: ''}
    ];

    // @computed get filterUsers() {
    //     if (this.currentDepartmentId === null) {
    //         return this.users;
    //     }
    //     const childIds = getDepartments(this.departments, this.currentDepartmentId);
    //     return this.users.filter(({departmentId}) => childIds.includes(departmentId));
    // }
    //
    // @computed get roleList() {
    //     return this.users.map(item => ({key: item.role, name: item.role}));
    // }

    constructor() {
        this.getProvinceActive()
    }


    @action
    setVarietyName(name: string) {
        this.varietyName = name;
        getDataList(this.varietyName, this.typeName).then(res => {
            this.listData = res
        })
    }

    @action
    setTypeName(name: string) {
        this.typeName = name;
        getDataList(this.varietyName, this.typeName).then(res => {
            this.listData = res
        })
    }

    @action
    getDataListActive() {
        getDataList(this.varietyName, this.typeName).then(res => {
            this.listData = res
        })
    }

    @action
    getProvinceActive() {
        CropProvider.getProvince().then(res => {
            if (res) {
                this.provinceList = res.map((item: { id: any; name: any; }) => {
                    return {id: item.id, name: item.name};
                })
            }
        })
    }

    @action
    getCityActive(id: number) {
        CropProvider.getCity(id).then(res => {
            if (res) {
                this.cityList = res.map((item: { id: any; name: any; }) => {
                    return {id: item.id, name: item.name};
                })
            }
        })
    }


    @action
    resetDepartmentId() {
        // @ts-ignore
        this.currentDepartmentId = null;
    }
}

export default new UserManageStore();
