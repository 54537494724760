import React, { PureComponent } from "react";
import { uniqueId } from "lodash";
import { Source, GeoJSONSource, Layer, LineLayer, SymbolLayer, FillLayer, mapDefault, MapGLProps } from "@gago-react-gl/gago-react-gl";
import { LayerClickEvent } from "@gago-react-gl/advance-layer/types/components/interfaces";
// tslint:disable:jsx-no-multiline-js
// tslint:disable:max-classes-per-file
// tslint:disable:triple-equals

/** 项目使用的mapDefault，统一去除地图事件 */
export const bmMapDefault: MapGLProps = {
  ...mapDefault, doubleClickZoom: false,
  reuseUniqKey: "reuseUniqKey",
};

interface BMProps {
  /** 地图图层id */
  id?: string;
  /** 传入高亮的县镇code */
  townCode?: string;
  /** 在背景图层前加载图层 */
  beforeLayer?: string | string[];
   /** 在背景图层前加载图层 */
  cancelHighlig?: boolean;
  /** 选择乡镇的回调函数 */
  callbackLayer?(properties: any, geometry?: any): void;
}

/**
 * 阿旗项目地图基础图层数据
 * @author 马俊峰
 * @date 2019-12-03
 * @export
 * @class ArQiMapLayer
 * @extends {PureComponent<AQProps>}
 */
export class BaMengMapLayer extends PureComponent<BMProps> {
  /** 默认传入的Props值 */
  static defaultProps = {
    townCode: "",
  };
  render() {
    const { id, townCode, beforeLayer } = this.props;
    const layerId = id || uniqueId("arqi_map_layer");
    return (
      <>
        <Source<GeoJSONSource>
          id="shandong_base_source"
          type="geojson"
          data="/static/data/city.geojson"
        />
        <Source<GeoJSONSource>
          id="shandong_town_base_source"
          type="geojson"
          data="/static/data/county.geojson"
        />
        <Layer<LineLayer>
          type="line"
          source="shandong_base_source"
          id="shandong_base_outline_1"
          paint={{ "line-color": "#45f3ff", "line-width": 0.5, "line-translate": [0, 16] }}
        />
        <Layer<LineLayer>
          type="line"
          source="shandong_base_source"
          id="shandong_base_outline_2"
          paint={{ "line-color": "#45f3ff", "line-width": 0.8, "line-translate": [0, 8] }}
        />
        <Layer<FillLayer>
          type="fill"
          source="shandong_town_base_source"
          id="town_base_under_color"
          before={beforeLayer}
          paint={{ "fill-color": "rgba(0, 221, 255, 0.2)" }}
          onClick={this.baMengLayerClick}
        />
        <Layer<LineLayer>
          type="line"
          source="shandong_town_base_source"
          id={layerId}
          paint={{ "line-color": "#000", "line-width": 2 }}
        />
        <Layer<LineLayer>
          type="line"
          source="shandong_town_base_source"
          id={`${layerId}_base`}
          paint={{ "line-color": "#45f3ff", "line-width": 1 }}
        />
        <Layer<LineLayer>
          type="line"
          source="shandong_base_source"
          id="shandong_base_outline"
          paint={{ "line-color": "#45f3ff", "line-width": 3 }}
        />
        <Layer<LineLayer>
          type="line"
          source="shandong_town_base_source"
          id="shandong_town_hight_line"
          paint={{ "line-color": "#ffcc3b", "line-width": 2.5 }}
          filter={["==", ["get", "countyCode"], townCode]}
        />
        <Layer<SymbolLayer>
          type="symbol"
          source="shandong_town_base_source"
          id="shandong_town_name_layer"
          layout={{
            "text-field": "{name}",
            "text-max-width": 6,
            "text-size": ["interpolate", ["linear"], ["zoom"], 8, 12, 10, 13],
            "text-font": ["Arial Unicode MS Regular"],
          }}
          paint={{ "text-color": "#fff", "text-halo-color": "#000", "text-halo-width": 1 }}
        />
      </>
    );
  }
  /** 地图图层点击事件 */
  private baMengLayerClick = (event: LayerClickEvent) => {
    const { properties, geometry } = event.features[0];
    this.props.callbackLayer && this.props.callbackLayer(properties, geometry);
  }
}
