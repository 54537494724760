import { ITable } from "@gago/frame";
import { ResultType, ResultType1 } from "../../models/common";
import { NorthwestRegionParkBaseQuery, NorthwestRegionParkNumber, NorthwestRegionPlantingArea,
  NorthwestRegionPointInfo,
  NorthwestRegionPointInfoType,
  ClassifySummary,
  NorthwestRegionPointInfoTypeBase,
  RegionTree1,
  CommonQueryRequest,
  IntegratedQuery,
  RegionSummary,
  PlantAll,
  PlantList,
  PlantListRequest,
  PlantingSummary,
  PlantingSummaryRequest,
  ExpertDetail,
} from "../../models/germplasm-resource";
import { BaseRequestStaticServer,BaseRequestStaticServer1 } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";
import { memoize } from "lodash";

function treeValueStatus(data: NorthwestRegionPointInfoType): NorthwestRegionPointInfoTypeBase[] {
  const arrlist: NorthwestRegionPointInfoTypeBase[] = [];
  arrlist.push({ key: "按区域查询", value: "按区域查询", children: data.region });
  arrlist.push({ key: "按种质类型", value: "按种质类型", children: data.germp });
  return [{ key: "种质资源", value: "种质资源", children: arrlist }];
}
interface BaseParam {
  /** 按园 区 基地类型 base 基地 district 区 park 园 */
  baseType?: string;
  /** wheat 小麦 vegetables 蔬菜 grass 草业 flower 红花 astra 黄芪 corn 玉米 sunflower 向日葵 */
  type?: string;
}
interface PointParams {
  /** 按区域查询 */
  code?: string;
  /** 按种质类型查询 */
  type?: string;
}

interface SummaryDataType {
    [key: string]: RegionSummary[];
}

interface PointMarkerType{
    [key: string]: PlantAll[];
}

const summaryRegionData: SummaryDataType = {} as SummaryDataType;
const pointMarkerSummary: PointMarkerType = {} as PointMarkerType;
/**
 * 种质资源一张图
 * @author 马俊峰
 * @date 2020-11-15
 * @export
 * @class GermplasmResourceProvider
 * @extends {BaseProvider}
 */

export default class GermplasmResourceProvider extends BaseProvider {

  /** 园区基地数量统计 */
  static async getNorthwestPark() {
    const { data } = await BaseRequestStaticServer.get<ResultType<NorthwestRegionParkNumber[]>>("/northwest/park/num", { });
    return data; 
  }
  /** 种质资源数据类型列表查询 */
  static async getNorthwestPointInfoType() {
    const { data } = await BaseRequestStaticServer.get<ResultType<NorthwestRegionPointInfoType>>("/northwest/point/info/type", { });
    return treeValueStatus(data);
  }
  /** 园区基地类型列表查询 */
  static async getNorthwestParkBaseType() {
    const { data } = await BaseRequestStaticServer.get<ResultType<NorthwestRegionPointInfoTypeBase[]>>("/northwest/park/base/type", { });
    return data;
  }
  /** 园区基地范围及信息查询 */
  static async getNorthwestBaseList(params: BaseParam) {
    const { data } = await BaseRequestStaticServer.get<ResultType<NorthwestRegionParkBaseQuery[]>>("/northwest/park/base/list", { params });
    return data;
  }
  /** 获取种植面积 */
  static async getNorthwestPlantingArea(params: BaseParam): Promise<ITable<2, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<NorthwestRegionPlantingArea[]>>("/northwest/planting/area", { params });
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", item.cropType, `${item.year}年`, item.area]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "面积"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "亩"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 种质资源数据查询 */
  static async getNorthwestPointInfo(params: PointParams) {
    const { data } = await BaseRequestStaticServer.get<ResultType<NorthwestRegionPointInfo[]>>("/northwest/point/info", { params });
    return data;
  }


  /** 
   * 第二个版本 12.28 
   * 
   * 
  */

  /** 获取行政区划 */
  static async getRegionTree() {
    const { data } = await BaseRequestStaticServer.get<ResultType<RegionTree1[]>>("/plant/region/tree");
    return data;
  }

  /**获取商品种质对应数量 */
  static async getClassifySummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ClassifySummary[]>>("/plant/resource/commodity/classifySummary");
    return data;
  }

  /** get 综合统计-查询明细 */
  static async getPlantList(params: PlantListRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<PlantList>>("/northwest/plant/list", {
      params,
    });
    return data;
  }

  /** get 综合查询-统计数据 */
  static async getIntegratedQuery(params: CommonQueryRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<IntegratedQuery[]>>("/northwest/plant/integratedQuery", {
      params,
    });
    return data;
  }

  /** get 获取地区汇总 气泡 原接口 */
  static async getRegionSummaryRaw(params: CommonQueryRequest) {
    if (params.dataType === "county") {
      return;
    }
    if (summaryRegionData[JSON.stringify(params)]) {
      return summaryRegionData[JSON.stringify(params)];
    }
    const { data } = await BaseRequestStaticServer.get<ResultType<RegionSummary[]>>("/northwest/plant/region/summary", {
      params,
    });
    if (params && !summaryRegionData[JSON.stringify(params)] && data) {
      summaryRegionData[JSON.stringify(params)] = data;
    }
    return data;
  }

  /** get 获取地区汇总 气泡 缓存接口 */
  static getRegionSummary = memoize(GermplasmResourceProvider.getRegionSummaryRaw);

  /** get 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 原接口 */
  static async getPlantAllRaw(params: CommonQueryRequest) {
    if (pointMarkerSummary[JSON.stringify(params)]) {
      return pointMarkerSummary[JSON.stringify(params)];
    }
    const { data } = await BaseRequestStaticServer.get<ResultType<PlantAll[]>>("/northwest/plant/all", {
      params,
    });
    if (params && !pointMarkerSummary[JSON.stringify(params)] && data) {
      pointMarkerSummary[JSON.stringify(params)] = data;
    }
    return data;
  }

  /** get 种植/ 一区多园 /特色种植 /专家 气泡坐标分布 缓存接口*/
  static getPlantAll = memoize(GermplasmResourceProvider.getPlantAllRaw);

  /** get 通过专家数据ID获取专家详情 */
  static async getExpertDetail(id: any) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpertDetail>>(`/plant/expert/id?id=${id}`);
    return data;
  }

  /** get 获取黄河西北5省种质汇总 */
  static async getPlantingSummary(params: PlantingSummaryRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<PlantingSummary>>("/northwest/plant/summary", {
      params,
    });
    return data;
  }
  /** 初始化获取数据状态 */
  static async getSignList() {
    const res = await BaseRequestStaticServer1.get<ResultType1<any>>("/system/tbl_system_config/list?systemCode=germplasm_resource")
    console.log('lbres',res)
    return res;
  }
  /** 初始化获取数据接口 */
  static async getSignAllData() {
    const { data } = await BaseRequestStaticServer1.get<ResultType<PlantingSummary>>("/system/tbl_data_source/getDate")
    console.log('lbdata',data)
    return data;
  }
    

}
