/**
 * @component RoleManage
 * @description 角色管理
 * @time 2019/02/18
 * @author yangyongheng
 */
import * as React from "react";
import { Table, message, Button, Icon, Modal, Popconfirm, Form, Input, Radio, Row, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";

import { getRouteInfoFromPath } from "../../layouts1/app";
import {
  GERMPLAS_PATH, EXTENSION_PATH, BREEDING_PATH,
  GERMPLAS_ROUTER_CONFIG, EXTENSION_ROUTER_CONFIG,
  BREEDING_ROUTER_CONFIG, ExtendRouterConfig,
} from "../../layouts1/app/route-config";
import DeviceStore from "./role-manage.store";
import DeviceService, { isSuccess } from "./role-manage.service";
import styles from "./role-manage.module.scss";
import { ITree, RoleCheckParent, RoleCheckBox } from "./tree";

// tslint:disable:variable-name jsx-no-lambda jsx-no-multiline-js
const RadioGroup = Radio.Group;
const FormItem = Form.Item;

interface Role {
  roleId: number;
  role: string;
  web: string;
  update: string;
}

interface RoleForm {
  roleId: number;
  role: string;
  web: string[];
  update: string;
}

/**
 * 格式化routerList: ExtendRouterConfig[] 为构建权限选择树所需要的格式
 * @author ZQ.xia
 * @param routerList
 * @param parentPath
 */
const formatRouter = (routerList: ExtendRouterConfig[], parentPath: string): RoleCheckParent[] => {
  if (!Array.isArray(routerList)) {
    throw TypeError(`this param(routerList) is array type, but get a ${typeof routerList}`);
  }

  return routerList.reduce((accu, currentRouter) => {
    if (!currentRouter.permission) {
      return accu;
    }

    const currentPath = `${parentPath}${currentRouter.key}`;
    const currentRouterKeys = { key: currentPath, title: currentRouter.title as string, disabled: false };
    if (!isEmpty(currentRouter.routes)) {
      const children = formatRouter(currentRouter.routes, currentPath);
      if (children.length) Object.assign(currentRouterKeys, { children: formatRouter(currentRouter.routes, currentPath) });
    }
    if (currentRouter.permission) {
      return [...accu, currentRouterKeys];
    }
    return accu;
  }, [] as RoleCheckParent[]);
};

/** 页面路由 app */
const routerKeysApp: RoleCheckBox[] = [
  { key: "地块管理", title: "地块管理", disabled: false },
  { key: "农田记事", title: "农田记事", disabled: false },
  { key: "气象服务", title: "气象服务", disabled: false },
];

const dataAuthority = [
  { key: "1", value: "全部数据" },
  { key: "2", value: "所在部门及子部门数据" },
  // { key: "3", value: "仅自己数据" },
];

const renderDataAuthority = (text: string) => {
  const target = dataAuthority.find(item => item.key === text);
  return target ? target.value : "";
};

type Props = FormComponentProps & {
  form: any;
};

// @ts-ignore
@Form.create()
@observer
export class RoleManage extends React.Component<Props> {
  componentWillMount() {
    // 请求列表数据
    this.getEquipments();
  }

  // 获取设备状态列表数据
  public getEquipments = async () => {
    try {
      DeviceService.getRoleData("")
        .then((result: any) => {
          DeviceStore.setTableData(result);
        });
    } catch (err) {
      message.error("获取设备状态列表失败");
    }
  }

  // 点击新增角色
  private clickAddDevice = () => {
    DeviceStore.setIsNew(true);
    DeviceStore.setAddEditDeviceModel(true);
  }

  // 点击修改角色
  private clickEditDevice = (record: Role) => {
    // 点击此条
    DeviceStore.setClickCur(record);
    DeviceStore.setIsNew(false);
    DeviceStore.setAddEditDeviceModel(true);
  }

  // 新增修改角色
  private addEditDevice = (isAdd: boolean) => {
    this.props.form.validateFields((err: any) => {
      // tslint:disable-next-line:early-exit
      if (!err) {
        const result = this.props.form.getFieldsValue();
        this.updateRole(isAdd, result);
      }
    });
  }

  // 删除角色
  private deleteRole = (roleNumber: any) => {
    DeviceService.deleteRole(roleNumber)
      .then((result) => this.operationSuccessful(result));
  }

  /**
   * @description 更新数据
   * @param {boolean} isAdd true: 新增数据; false: 更新数据
   * @param {RoleForm} param
   * @memberof RoleManage
   */
  private updateRole(isAdd: boolean, param: RoleForm) {
    if (isAdd) {
      const { roleId, ...paramNoRoleId } = param;
      DeviceService.addRole(paramNoRoleId)
        .then((result) => this.operationSuccessful(result));
    } else {
      DeviceService.editRole(param)
        .then((result) => this.operationSuccessful(result));
    }
  }

  private operationSuccessful(result: {message: string; code: number}) {
    if (result.code === isSuccess) {
      this.successAndReload(result.message);
      this.resetFromAndModal();
    } else {
      this.errorAndReload(result.message);
    }
  }

  /** 操作过后显示操作结果并重新加载数据 */
  private successAndReload(text: string) {
    this.getEquipments();
    message.success(text);
  }
  /** 操作过后显示操作结果并重新加载数据 */
  private errorAndReload(text: string) {
    this.getEquipments();
    message.error(text);
  }

  private resetFromAndModal() {
    DeviceStore.setAddEditDeviceModel(false);
    this.props.form.resetFields();
  }

  intersection(target: string[], initial: string[]) {
    const newData = target.concat(initial);
    const heavyData = Array.from(new Set(newData));

    return newData.length !== heavyData.length;
  }

  getRouterKeysWeb = (): RoleCheckBox[] => {
    // const tempMarketRoutes = EXTENSION_ROUTER_CONFIG.filter((item) => item.title !== "系统设置")

    return [
      { key: GERMPLAS_PATH, title: "种质资源", disabled: false, children: formatRouter(GERMPLAS_ROUTER_CONFIG, GERMPLAS_PATH) },
      { key: EXTENSION_PATH, title: "种质推广", disabled: false, children: formatRouter(EXTENSION_ROUTER_CONFIG, EXTENSION_PATH) },
      { key: BREEDING_PATH, title: "种质繁育", disabled: false, children: formatRouter(BREEDING_ROUTER_CONFIG, BREEDING_PATH) },
    ];
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "序号",
        dataIndex: "index",
      },
      {
        title: "角色",
        dataIndex: "role",
      },
      {
        title: "功能权限（web）",
        dataIndex: "web",
        render: this.renderWebPermissionNames,
        width: 800,
      },
      {
        title: "数据权限",
        dataIndex: "dataAuthority",
        render: renderDataAuthority,
      },
      {
        title: "更新时间",
        dataIndex: "update",
      },
      {
        title: "操作",
        key: "action",
        width: 120,
        // @ts-ignore
        render: (text, record) => {
          return (
            <>
              <Popconfirm title="确认要删除此用户吗？" onConfirm={() => this.deleteRole(record.roleId)}>
                <a className={styles.addOperation}>删除</a>
              </Popconfirm>
              <a onClick={() => this.clickEditDevice(record)}>编辑</a>
            </>
          );
        },
      },
    ] as any;

    return (
      <div className={styles.deviceWrapper}>
        <div>
          <h1>角色管理</h1>
        </div>
        <div className={styles.addDevice} onClick={this.clickAddDevice}>
        <Button type="primary">
        <Icon type="plus" />新增角色
        </Button>
        </div>
        <div className={styles.deviceBody}>
          <Table
            pagination={{ size: "small", showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ["5", "10", "20"] }}
            columns={columns}
            dataSource={DeviceStore.tableData}
            rowKey="seriesNumber"
            size="middle"
          />
        </div>
        <Modal
          destroyOnClose
          title={DeviceStore.isNew ? "新增角色" : "编辑角色"}
          visible={DeviceStore.addEditDeviceModel}
          onOk={() => this.addEditDevice(DeviceStore.isNew ? true : false)}
          okText={"确定"}
          cancelText={"取消"}
          onCancel={() => DeviceStore.setAddEditDeviceModel(false)}
          width={560}
        >
          <Form>
            <FormItem
              label="角色名称"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 19 }}
              key="role"
            >
            {getFieldDecorator("role", {
              initialValue: DeviceStore.isNew ? undefined : DeviceStore.editRoleInfo.role,
              rules: [{ required: true, message: "角色不能为空!" }],
            })(<Input />)}
            </FormItem>
            <FormItem
              label="数据权限"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 19 }}
              key="dataPermissions"
            >
            {getFieldDecorator("dataAuthorityId", {
              initialValue: DeviceStore.isNew ? "2" : DeviceStore.editRoleInfo.dataAuthority,
              rules: [{ required: true }],
            })(<RadioGroup>
                <Radio value={"1"}>全部数据</Radio>
                <Radio value={"2"}>所在部门及子部门数据</Radio>
                {/* <Radio value={"3"}>仅自己数据</Radio> */}
              </RadioGroup>,
            )}
            </FormItem>
            <Row type="flex" justify="space-between" style={{ marginLeft: 14, marginBottom: -24 }}>
              {this.renderWebPermission()}
            </Row>
            <FormItem
              label=""
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 19 }}
              key="roleId"
            >
            {getFieldDecorator("roleId", {
              initialValue: DeviceStore.isNew ? undefined : DeviceStore.editRoleInfo.roleId,
            })(<Input hidden />)}
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }

  renderPermission = (title: string, key: string, data: any, defaultValue?: string[]) => {
    const { getFieldDecorator } = this.props.form;
    return (
      <FormItem label={title} key={key} >
        {getFieldDecorator(key, {
          initialValue: defaultValue,
        })(<ITree data={data} />)}
      </FormItem>
    );
  }

  renderWebPermission = () => {
    const routerKeysWeb = this.getRouterKeysWeb();
    return (
      <Col span={6}>
        {this.renderPermission("功能权限（web）", "web", routerKeysWeb,
          DeviceStore.isNew ? undefined : DeviceStore.editRoleInfo.web)}
      </Col>
    );
  }

  renderWebPermissionNames = (text: string) => {
    const webPaths = text.split(",");
    // 种植资源的Routes
    const gardenRoutes: any[] = [];
    // 种植推广的Routes
    const marketRoutes: any[] = [];
    // 数字繁育的Routes
    const policyRoutes: any[] = [];

    webPaths.forEach((pathname: string) => {
      const gardenRoute = getRouteInfoFromPath(GERMPLAS_ROUTER_CONFIG, GERMPLAS_PATH, pathname);
      if (gardenRoute) gardenRoutes.push(gardenRoute);
      const marketRoute = getRouteInfoFromPath(EXTENSION_ROUTER_CONFIG, EXTENSION_PATH, pathname);
      if (marketRoute) marketRoutes.push(marketRoute);
      const policyRoute = getRouteInfoFromPath(BREEDING_ROUTER_CONFIG, BREEDING_PATH, pathname);
      if (policyRoute) policyRoutes.push(policyRoute);
    });
    return (
      <div style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
        <div>{isEmpty(gardenRoutes) ? "" : `种植资源：(${gardenRoutes.map((item: any) => item.title)})`}</div>
        <div>{isEmpty(marketRoutes) ? "" : `种植推广：(${marketRoutes.map((item: any) => item.title)}) `}</div>
        <div>{isEmpty(policyRoutes) ? "" : `种植繁育：(${policyRoutes.map((item: any) => item.title)}) `}</div>
      </div>
    );
  }
}
