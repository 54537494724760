// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import styled from "styled-components";
import { Breadcrumb } from "antd";

import { ICSSProps } from "@gago/frame";
import bg from "./bg.png";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  .mc-header{
    width: 100%;
    height: 56px;
    background-image: url('${bg}');
    background-size: 101% 112%;
    background-position: center;
    padding-left: 16px;
    display: flex;
    align-items: center;
    .mc-text{
      font-family: PingFangSC;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 22, 23, 0.9);
    }
  }
  .mc-body{
    height: calc(100% - 56px);
    padding: 16px 16px 2px;
  }
  .mc-body.no-padding{
    padding: 0px;
  }
`;

type P = ICSSProps & {
  /** 名称数组 */
  names: string[];
  /** 是否使用padding */
  noPadding?: boolean;
};

/**
 * 系统 头部
 * @author maoshengtao
 */
export class HeaderBox extends React.PureComponent<P> {
  render() {
    const { style, className, children, names, noPadding } = this.props;
    return (
      <Wrap style={style} className={className}>
        <div className="mc-header">
          <Breadcrumb separator="-">
            {names && names.map((item) => {
              return <Breadcrumb.Item key={item} ><span className="mc-text">{names}</span></Breadcrumb.Item>;
            })}
          </Breadcrumb>
        </div>
        <div className={`mc-body ${noPadding ? "no-padding" : ""}`} >
          {children}
        </div>
      </Wrap>
    );
  }
}
