import React, {Component} from "react";
import {observable, action, transaction} from "mobx";
import {observer} from "mobx-react";
import {Tree, Icon, Popconfirm, message, List, Input, Button} from "antd";

import {VarietyModalComponent, VarietyFormData} from "./variety-modal";
import UserManageStore from "../user-manage.store";
import styles from "./tree.module.scss";
import CropProvider from "../../../network/providers/crop.provider";

const {Search} = Input;
const {Item: ListItem} = List;

@observer
export class TreeComponent extends Component {

    @observable title = "";
    @observable visibleVariety = false;
    @observable confirmLoading = false;
    @observable dataList: any[] = []

    @observable varietyFormData: VarietyFormData = {
        id: 0,
        varietyName: "",
    };

    @action.bound
    private setConfirmLoading(value: boolean) {
        this.confirmLoading = value;
    }

    @action.bound
    private cancelModal() {
        this.title = "";
        this.visibleVariety = false;
    }

    //提交品种名称
    private submitVarietyForm = async (VarietyFormData: VarietyFormData) => {
        this.setConfirmLoading(true);
        if (VarietyFormData.id) {
            await CropProvider.editFarmCropVariety(VarietyFormData).then((res) => {
                message.success("修改成功");
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                this.setConfirmLoading(false);
                this.getVarietyDataList()
                this.cancelModal();
            });
        } else {
            await CropProvider.addFarmCropVariety(VarietyFormData).then((res) => {
                message.success("提交成功");
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                this.setConfirmLoading(false);
                this.getVarietyDataList()
                this.cancelModal();
            });
        }
    };

    //点击事件
    @action.bound
    private listClick() {
        console.log(111);
    }

    //显示添加品种
    @action.bound
    private showVarietyPanel() {
        this.varietyFormData = {
            id: 0,
            varietyName: "",
        }
        this.visibleVariety = true;
    }

    //搜索品种
    @action.bound
    private searchVariety(value: string) {
        this.getVarietyDataList(value)
    }

    //获取数据列表
    getVarietyDataList(varietyName = '') {
        CropProvider.getFarmCropVariety(varietyName).then(res => {
            this.dataList = res
        })
    }

    //删除品种
    deleteVarietyData(id: any) {
        return () => {
            CropProvider.delFarmCropVariety(id).then(res => {
                this.getVarietyDataList()
            })
        }
    }

    //编辑品种
    editVarietyData(item: any) {
        transaction(() => {
            this.varietyFormData = item;
            this.title = "编辑品种";
            this.visibleVariety = true;
        });
    }

    //初始化数据
    async componentDidMount() {
        await this.getVarietyDataList()
    }

    render() {
        return (
            <div className={styles.container}>
                <Button type="primary" block onClick={() => this.showVarietyPanel()}>新增品种</Button>
                <Search placeholder="请输入品种名称" onSearch={this.searchVariety} style={{margin: "10px 0"}}/>
                <div className={styles.leftIframe}>
                    <List dataSource={this.dataList} renderItem={item =>
                        <ListItem className={styles.list}>
                            <div className={styles.title} onClick={() => UserManageStore.setVarietyName(item.varietyName)}>{item.varietyName}</div>
                            <div>
                                <span className={styles.tableAction} onClick={() => this.editVarietyData(item)}>编辑</span>
                                <Popconfirm title="确认要删除该品种吗？" onConfirm={this.deleteVarietyData(item.id)}>
                                    <span className={styles.tableAction}>删除</span>
                                </Popconfirm>
                            </div>
                        </ListItem>}>
                    </List>
                </div>
                <VarietyModalComponent
                    title={this.title}
                    visible={this.visibleVariety}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.varietyFormData}
                    submitForm={this.submitVarietyForm}
                />
            </div>
        );
    }
}
