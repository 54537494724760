// tslint:disable:jsx-no-multiline-js
import React from "react";
import { ColorProps } from "@gago/frame/es/interface";
import { Source, LercTileSource, Layer, LercTileLayer, LercOverlayLayer, ClassificationLayer } from "@gago-react-gl/gago-react-gl";

import { MAP_IDS } from "../../src-anxi/containers/config";
import { mapAnalysis, mapToken, Config, handleMaskAndSlider } from "./map-analysis-const";
import { GlobalColorPalette } from "../../src-anxi/color-palette";
import { CropType } from "./index";
import { ClassificationLegend } from "@gago/frame/es/legends/classification-legend";
import { get } from "lodash";

type MapAnalysisMaskProps = {
  /** 控制范围 */
  range: any;
  /** 作物类型 */
  cropType: CropType;
  /** 当前选择的tab */
  sliderIndex: number;
} & ColorProps<GlobalColorPalette>;

/**
 * 数字茶园-适宜性评价-智能分析地图：掩模
 * @author luyaxiang
 * @date 2019.6.20
 * @export
 * @class MapAnalysisMask
 * @extends {React.PureComponent<MapAnalysisMaskProps>}
 */
export class MapAnalysisMask extends React.PureComponent<MapAnalysisMaskProps> {
  /** 渲染掩模 */
  renderMaskLayer = (maskConfig: Config) => {
    const { mapConfig } = maskConfig;
    
    const source: LercTileSource = {
      id: mapConfig.id,
      type: "lerc_v2",
      maxzoom: 14,
      minzoom: 0,
      headers: {
        token: mapToken,
      },
      tiles: [mapConfig.tiles],
    };

    const layer: LercTileLayer = {
      type: "lercv2",
      id: mapConfig.id,
      source: mapConfig.id,
      // @ts-ignore
      paint: {
        "raster-opacity": 0,
        "raster-colorscale": {
          type: "exponential",
          stops: [],
        },
      },
    };
    return (
      <>
        <Source<LercTileSource> {...source} key={mapConfig.id} />
        <Layer {...layer} key={mapConfig.id}/>
      </>
    )
  }

  renderMaskLayers = () => {
    const { sliderIndex } = this.props;
    const { maskConfig, sliderList } = handleMaskAndSlider(this.props.cropType) as any;
    return sliderList.map((item: any, index: number) => {
      if (sliderIndex === 0 && index > 2) {
        return null;
      }
      if (sliderIndex === 1 && index <= 2) {
        return null;
      }
      return this.renderMaskLayer(maskConfig[item]);
    });
  }

  getMaskConfig = () => {
    const { range, cropType, sliderIndex } = this.props;
    let lercMaskIndex = 0;
    const { maskConfig, sliderList} = handleMaskAndSlider(cropType) as any;
    const maskConfigs: any[] = sliderList.map((item: any, index: number) => {
      if (sliderIndex === 0 && index > 2) {
        return {};
      }
      if (sliderIndex === 1 && index <= 2) {
        return {};
      }
      console.log(range[sliderList[index]].map((item: number)=> item * 100), sliderList[index],'sssssss')
  
      return {
        [`lerc-mask-source-${++lercMaskIndex}`]: maskConfig[sliderList[index]].mapConfig.id,
        [`lerc-mask-include-${lercMaskIndex}`]: [range[sliderList[index]].map((item: number)=> item * 100)],
      };
    });
    return maskConfigs.reduce((arrc, item, index) => {
      return {...arrc, ...item};
    }, {} as any)
  }

  /** 获取layer配置 */
  getAnalysisLayer = () => {
    const analysisLayer: LercOverlayLayer = {
      type: "lerc-overlay",
      id: `${mapAnalysis.id}-${this.props.cropType}`,
      paint: {
        "raster-opacity": 1,
        "raster-domain-max": 501,
        "raster-domain-min": 0,
        "geojson-mask-source": MAP_IDS.countyFillLayerId,
        "raster-colorscale": {
          type: "interval",
          // tslint:disable-next-line: ter-max-len
          stops:[[0,"rgba(0,0,0,0)"],[99,"rgba(252, 102, 1, 1)"],[101,"rgba(0,0,0,0)"],[199,"rgba(250, 174, 20, 1)"],[299,"rgba(250, 250, 11, 1)"],[399,"rgba(207, 240, 11, 1)"],[499,"rgba(166, 246, 0, 1)"],[501,"rgba(166, 246, 0, 1)"],[501,"rgba(0,0,0,0)"]],
          // stops:[[100,"rgba(252, 102, 1, 1)"],[200,"rgba(250, 174, 20, 1)"],[299,"rgba(250, 250, 11, 1)"],[399,"rgba(207, 240, 11, 1)"],[499,"rgba(166, 246, 0, 1)"],[501,"rgba(166, 246, 0, 1)"]],
          min: 0,
          max:501
        },
        ...this.getMaskConfig(),
      },
      source: `${mapAnalysis.id}-${this.props.cropType}`,
    };
    return analysisLayer;
  }
  /**切片配置 */
  getCurrentTypeSource = (): LercTileSource => {
    const { cropType } = this.props;
    return {
      id: `${mapAnalysis.id}-${this.props.cropType}`,
      type: "lerc_v2",
      maxzoom: 14,
      minzoom: 0,
      headers: {
        token: mapToken,
      },
      tiles: [mapAnalysis[`${cropType}_tiles`]],
    };
  }
  render() {
    // console.log(this.getCurrentTypeSource(),this.getAnalysisLayer())

    const { colorPalette } = this.props;
    const colors: any[] = [
      {
        color: "rgba(252, 102, 1, 1)",
        key: "c0",
        show: true,
      },
      {
        color: "rgba(250, 174, 20, 1)",
        key: "c1",
        show: true,
      },
      {
        color: "rgba(250, 250, 11, 1)",
        key: "c2",
        show: true,
      },
      {
        color: "rgba(207, 240, 11, 1)",
        key: "c3",
        show: true,
      },
      {
        color: "rgba(166, 246, 0, 1)",
        key: "c4",
        show: true,
      },
    ];
    return (
      <>
        {this.renderMaskLayers()}
        <Source<LercTileSource>  {...this.getCurrentTypeSource()} />
        <Layer {...this.getAnalysisLayer()} before={MAP_IDS.townLineLayerId} />
        <ClassificationLegend
          colors={colors}
          style={{ position: "absolute", bottom: "16px", right: "16px" }}
          names={["不适宜", "次适宜", "较适宜", "适宜", "极适宜"]}
          colorPalette={colorPalette}
        />
        
      </>
    );
  }
}
