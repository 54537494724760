import React from "react";
import { BaseMap, BaseMapProps } from "../../containers/base-map";
import { ICSSProps, ClassificationLegend, GradientLegend1, globalColorPalette1 } from "@gago/frame";
import { Region } from "../../models/common";
import { BaMengMap } from "../../containers/bameng-map";
import { ClassificationLayer, GradientLayer } from "@gago-react-gl/gago-react-gl";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import moment from "moment";

interface P extends Partial<BaseMapProps>, ICSSProps {
  /** 高亮的地区 */
  region: Region;
 /** 切换高亮的地区 */
  changeRegion(region: Region): void;
 /**作物种类 */
  crop: string;
  date: string;
}

interface S {
  /** map */
  map?: mapboxgl.Map;
}

/**
 * map
 *
 */
export default class Map extends React.PureComponent<P, S> {

  state: S = {
    map: undefined,
  };

  onLoadMap = (map: mapboxgl.Map) => {
    this.setState({ map });
  }
  /** 根据作物类型判断，请求时间类型 */
  judgeCropTimeType = (type: string) => {
    switch (type) {
      case "wheat": return "ndvi8";
      case "corn": return "ndvi_corn8";
      case "sunflower": return "ndvi_sunflower8";
      // case "grassland": return "ndvi_glass8";
      case "grassland": return "ndvi8";
      default: return "ndvi_sunflower8";
    }
  }

  render() {
    const { region, changeRegion, crop, date } = this.props;
    const cropName = this.judgeCropTimeType(crop);
    // const dateString = '20201015'
    const dateTime = moment(date).format("YYYYMMDD");
    return (
      <>
        <BaseMap
          onLoad={this.onLoadMap}
        >
          <BaMengMap
            region={region}
            changeRegion={changeRegion}
          />
          {/* <GradientLayer
            id="ndvi_glass8"
            url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${dateTime}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
            paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
          >
            { colors => (
              <GradientLegend1
                {...{ ...colors }}
                style={{ position: "absolute", bottom: "16px", right: "416px" }}
                names={["极弱", "较弱", "正常", "较强","极强"]}
                colorPalette={globalColorPalette1}
              />
            )}
            </GradientLayer> */}
            <ClassificationLayer<{
              c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
            }>
              id={cropName}
              // key={cropName}
              url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${dateTime}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
              paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
              insideSorts={{ c0: [0.9, 1.1], c1: [1.9, 2.1], c2: [2.9, 3.1], c3: [3.9, 4.1], c4: [4.9, 5.1] }}
              insideActiveSorts={"all"}
              before="bameng-county-line"
            >
              { colors => (
                <ClassificationLegend
                  {...{ ...colors }}
                  style={{ position: "absolute", bottom: "16px", right: "416px" }}
                  names={["极弱", "较弱", "正常", "较强", "极强"]}
                  colorPalette={globalColorPalette1}
                />
              )}
            </ClassificationLayer>
        </BaseMap>
      </>
    );
  }

}
