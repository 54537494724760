import React, { PureComponent } from "react";
import {
  ICSSProps,
  ColorProps,
  GlobalColorPalette,
  ICSS,
  Color,
} from "@gago/frame";
import styled from "styled-components";
import numberBg from "./bg@2x.png";
import bg01 from "./bg-01.svg";
import bg02 from "./bg-02.svg";
import bg03 from "./bg-03.svg";
import bg04 from "./bg-04.svg";
import bg01Shadow from "./bg-01-shadow.svg";
import bg02Shadow from "./bg-02-shadow.svg";
import bg03Shadow from "./bg-03-shadow.svg";
import bg04Shadow from "./bg-04-shadow.svg";

// tslint:disable:variable-name ter-max-len completed-docs jsx-no-multiline-js
const CropAreaCardBody = styled.div<{ colorPalette: GlobalColorPalette }>`
  width: 210px;
  height: 86px;
  padding: 16px 12px;
  background-image: url(${numberBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  position: relative;
  align-items: center;
  .left{
    width: 60px;
    height: 60px;
    background-image: url(${bg01Shadow});
    background-size: 100% 100%;
    margin-right: 10px;
    &.bg-01{
      background-image: url(${bg01Shadow});
    }
    &.bg-02{
      background-image: url(${bg02Shadow});
    }
    &.bg-03{
      background-image: url(${bg03Shadow});
    }
    &.bg-04{
      background-image: url(${bg04Shadow});
    }
  }
  .left-icon{
    width: 48px;
    height: 48px;
    background-image: url(${bg01});
    background-size: 100% 100%;
    position: absolute;
    left: 20px;
    top: 23px;
    &.bg-01{
      background-image: url(${bg01});
    }
    &.bg-02{
      background-image: url(${bg02});
    }
    &.bg-03{
      background-image: url(${bg03});
    }
    &.bg-04{
      background-image: url(${bg04});
    }
  }
  .right{
    .right-value{
      font-family: BebasNeue;
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #e0feff;
    }
    .right-title{
      font-family: PingFangSC;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #65fbff;
    }
  }
`;
type Cprops = {
  /** 标题 */
  title: string;
  /** 数量 */
  value: number | string;
  /** 单位 */
  suffix?: string;
  /** 背景图标 */
  bgIcon?: string;
} & ICSSProps &
  ColorProps<GlobalColorPalette>;

/**
 * 第 5 种样式的card
 * @author maoshengtao
 */
export class Card5 extends PureComponent<Cprops> implements ICSS, Color {
  render() {
    const {
      colorPalette,
      style,
      className,
      title,
      value,
      suffix,
      bgIcon,
    } = this.props;
    return (
      <CropAreaCardBody
        colorPalette={colorPalette}
        style={style}
        className={className}
      >
        <div className={`left ${bgIcon}`}></div>
        <div className={`left-icon ${bgIcon}`} />
        <div className="right">
          <div className="right-value">{value}</div>
          <div className="right-title" >{title}</div>
        </div>
      </CropAreaCardBody>
    );
  }
}
