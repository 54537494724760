// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import {Modal, Button, Steps, Select, message, Spin, Cascader} from "antd";
import {ICSSProps} from "@gago/frame";
import {ModalProps} from "antd/lib/modal";
import styled, {createGlobalStyle} from "styled-components";
import {GenerateCategory} from "./generate-category";
import {FormComponentProps} from "antd/lib/form";
import CropProvider from "../../network/providers/crop.provider";
import {RecordPoints} from "../../models/crop";

const Wrap = styled.div`
    width: 1336px;
    height: 662px;
    overflow-y: auto;

    input:-webkit-autofill {
        /* 选择历史记录的文字颜色*/
        -webkit-text-fill-color: #333 !important;
    }
`;

const GloabalStyle = createGlobalStyle`
    .detail-overlay {
        .ant-modal-title {
            font-size: 20px;
        }

        .ant-form-item label {
            color: rgb(0, 22, 23, 0.95);
            font-size: 20px;
        }

        .ant-input[disabled] {
            border: none;
            color: rgb(0, 22, 23, 0.7);
            background: transparent;
            font-size: 20px;
            height: auto;
        }

        .ant-modal {
            width: 1368px !important;
        }

        .ant-steps-item-title {
            font-size: 20px !important;
        }
    }
`;

type P = ICSSProps & ModalProps & {
    /** 种质编号 */
    accessData: RecordPoints;
    /** wrap classname */
    wrapClassName?: string;
};

interface S {
    /** 表单映射 */
    formMap: {
        [key: string]: FormComponentProps["form"];
    };
    /** 是否为loading */
    loading?: boolean;
    /** 获取单个种质资源信息 */
    listData?: any,

}

/**
 * 详情 弹窗
 * @author maoshengtao
 */
export class DetailPointOverlay extends React.Component<P, S> {
    state: S = {
        formMap: {},
    };

    componentDidMount() {
        this.getPointInfo();
    }

    /** 获取单个种质资源信息 */
    getPointInfo = async () => {
        const {accessData} = this.props;
        this.setState({loading: true});
        let listData = {}
        switch (accessData.type) {
            case "基肥记录":
                listData = await CropProvider.getBaseFertilizerInfo(accessData.id);
                break;
            case "追肥记录":
                listData = await CropProvider.getFertilizerUseInfo(accessData.id);
                break;
            case "用药记录":
                listData = await CropProvider.getMedicineUseInfo(accessData.id);
                break;
            case "除草记录":
                listData = await CropProvider.getWeedControlInfo(accessData.id);
                break;
            case "生育期记录":
                listData = await CropProvider.getFertilityRecordInfo(accessData.id);
                break;
            case "浇水记录":
                listData = await CropProvider.getWaterUseInfo(accessData.id);
                break;
        }
        this.setState({listData: listData, loading: false});
    }


    render() {
        const {onCancel, wrapClassName, accessData, ...other} = this.props;
        const {listData, loading} = this.state;
        return (
            <>
                <Modal
                    footer={this.renderModalFooter()}
                    wrapClassName={`detail-overlay ${wrapClassName || ""}`}
                    onCancel={onCancel}
                    {...other}
                >
                    <Wrap>
                        <>
                            {loading && (
                                <div style={{width: "100%", height: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Spin/>
                                </div>)
                            }
                            <GenerateCategory
                                useMultiplePreview
                                data={listData}
                                typeNum={accessData.typeNumber}
                                key={'详情'}
                                title={'详情'}
                            />
                        </>
                    </Wrap>
                </Modal>
                <GloabalStyle/>
            </>
        );
    }

    /** render modal footer */
    renderModalFooter = () => {
        const {onCancel} = this.props;
        return (
            <>
                <Button onClick={onCancel}>{"关闭"}</Button>
            </>
        );
    }

}
