import React from "react";

/**
 * @author wudajian
 * @description iframe 嵌套页面 传入要嵌套的url
 * @returns JSX.Element
 */
export default function Iframe(props: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>) {
  return (
    <iframe src="https://www.baidu.com/" width="100%" height="100%" frameBorder={0} {...props} />
  );
}
