import React from "react";
import moment from "moment";
import { HeaderBox } from "../../containers/header-box";
import { PageBody } from "./styled";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import Map from "./map";
import { EllipseRadioSelect, LocationSelect } from "@gago/lib/components";
import {
  AgricultureWarning,
  AgricultureWarningRequest,
  AnalysisBaseRequest,
  PhenologicalPeriod,
  WeekForecast,
  AnalysisTmp,
  BaseAnalysis,
} from "../../models/meteorological-service";
import MeteorologicalServiceProvider from "../../network/providers/meteorological-service";
// import { Card1 as Card } from "@gago/frame";
import { ITable, SideBar4, Timeline7 } from "@gago/frame";
import { noop, cloneDeep } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { Table } from "antd";
import MeteorologicalHistory from "./meteorological-history";
import MeteorologicalWarning from "./meteorological-warning";
import { diasterInfo, diasterLevel } from "./getWeatherInfo";
import { daysInfuture } from "../../utils/example-data";

export const mapDataList: {label: string; value: string}[] = [
  { label: "向日葵", value: "sunflower" },
  { label: "玉米", value: "corn" },
  { label: "小麦", value: "wheat" },
  { label: "辣椒", value: "chilli" },
];
const mapMeteorologicalList: {label: string; value: string}[] = [
  { label: "气象预警", value: "meteorological_warning" },
  { label: "历史气象", value: "meteorological_history" },
];

interface P {

}

interface S {
  /** 高亮的地区 */
  region: Region;
  /**气象预警/历史气象 */
  forecastType: "meteorological_warning" | "meteorological_history";
  /** 向日葵|玉米|小麦|草地*/
  crop: string;
  // 理论物候期
  phenologicalList: PhenologicalPeriod[];
  // 农业风险预警
  agricultureWarning: AgricultureWarning[];
  // 未来七天天气预报
  weekForecast: WeekForecast[];
  // lon:string;
  // lat:string;
  // 年份
  year: number;
  // 累计降雨
  apcpList: BaseAnalysis;
  // 有效积温列表
  sumtmpList: BaseAnalysis;
  // 温度分析列表
  tmpList: AnalysisTmp;
  // 时间轴所需数据
  timeDate: string[];
  // 选择的日期
  date: string;
  // 灾害类型
  disasterType: string;
  // lerc 起报时间
  startDate: string;
}

export default class MeteorologicalService extends React.PureComponent<P, S> {

  state: S = {
    // 地图region
    region: {
      code: "150800000",
      name: undefined,
      level: "city",
      latitude: "40.6288193598815",
      longitude: "106.102294545675",
    },
    forecastType: "meteorological_warning",
    crop: "sunflower",
    phenologicalList: [],
    agricultureWarning: [],
    weekForecast: [],
    // lat:'40.6288193598815',
    // lon:'106.102294545675',
    year: new Date().getFullYear(),
    // 积温列表
    sumtmpList: {
      name: "",
      arrow: false,
      current: 0,
      ratio: 0,
      history: 0,
      currentList: [],
      historyList: [],
    },
    // 累计降雨
    apcpList: {
      name: "",
      arrow: false,
      current: 0,
      ratio: 0,
      history: 0,
      currentList: [],
      historyList: [],
    },
    // 温度分析
    tmpList: {
      name: "",
      currentMax: "0",
      currentMin: "0",
      historyMax: "0",
      historyMin: "0",
      ratioMax: 0,
      ratioMin: 0,
      currentMaxMinList: [],
      historyMaxMinList: [],
      arrowMax: false,
      arrowMin: false,
    },
    // 时间轴数据
    timeDate: daysInfuture(moment(), 8, "YYYY-MM-DD"),
    // 选择的时间
    date: moment().format("YYYY-MM-DD") ,
    // 灾害类型
    disasterType: "high_temp",
    startDate: moment().format("YYYYMMDD"),

  };

  componentDidMount() {
    this.initData();
  }
  /**获取七天天气预报 */
  getWeekForecast = async () => {
    const { region: { code } } = this.state;
    const weekForecast = await MeteorologicalServiceProvider.getWeekForecast({ code: code as string });
    console.log(333333333, weekForecast);
    this.setState({ weekForecast });
  }
  /**获取今日气象预警*/
  getAgricultureWarning = async () => {
    const { region: { code }, crop } = this.state;
    const crop1 = crop === "chilli" ? "wheat" : crop
    const realCode = code === undefined ? "150800000" : code;
    const agricultureWarning = await MeteorologicalServiceProvider.getAgricultureWarning({ crop:crop1, code: realCode as string });
    // console.log('data',data)
    this.setState({ agricultureWarning });
  }
  /**获取温度分析*/
  getAnalysisTmp = async () => {
    const { year, region: { code, longitude, latitude } } = this.state;
    const startTime = `${year}-01-01`;
    const endTime = `${year}-12-31`;
    const realCode = code === undefined ? "150800000" : code;

    const data = await MeteorologicalServiceProvider.getAnalysisTmp({ code: realCode as string, lon: longitude as string, lat: latitude as string, startTime, endTime });
    console.log("tmp", data);
    this.setState({
      tmpList: data,
    });
  }
  /**获取物候期列表*/
  getPhenologicalPeriod = async () => {
    const { region: { code } } = this.state;
    const realCode = code === undefined ? "150800000" : code;

    const data = await MeteorologicalServiceProvider.getPhenologicalPeriod({ code: realCode as string });
    console.log("data", data);
    const newData = data.filter(el => el.crop !== "草地");
    this.setState({
      phenologicalList: newData,
    });
  }

  /**获取累计降水列表*/
  getAnalysisApcp = async () => {
    const { region: { code, longitude, latitude }, year } = this.state;
    const startTime = `${year}-01-01`;
    const endTime = `${year}-12-31`;
    const realCode = code === undefined ? "150800000" : code;

    const data = await MeteorologicalServiceProvider.getAnalysisApcp({ code: realCode as string, lon: longitude as string , lat: latitude as string, startTime, endTime });
    console.log("apcp", data);
    this.setState({
      apcpList: data,
    });
  }
    /**获取积温列表*/
  getAnalysisSumTmp = async () => {
    const { region: { code, longitude, latitude }, year } = this.state;
    const startTime = `${year}-01-01`;
    const endTime = `${year}-12-31`;
    const realCode = code === undefined ? "150800000" : code;

    const data = await MeteorologicalServiceProvider.getAnalysisSumTmp({ code: realCode as string, lon: longitude as string , lat: latitude as string, startTime, endTime });
    console.log("sumtmp", data);
    this.setState({
      sumtmpList: data,
    });
  }

    // 获取起报时间
  getStartDate = async () => {
    const data = await MeteorologicalServiceProvider.getStartDate();
    console.log(data, "dailyDate");
    const startDate = moment(data[data.length - 1]).format("YYYYMMDD");
    this.setState({ startDate });
  }
   /** 切换高亮的地区 */
  changeRegion = (region: Region) => {

    this.setState({ region }, () => {
      this.initData();
    });
  }
  ChangeWeatherDate = (value: string) => {
    const year = moment().year();
    const date = value === "今日" ? moment().format("YYYY-MM-DD") :  moment(`${year}/${value}`).format("YYYY-MM-DD");
    this.setState({ date });
  }

  /**初始化数据 */
  initData = async () => {
    const { forecastType } = this.state;
    if (forecastType === "meteorological_warning") {
      this.getWeekForecast();
      this.getPhenologicalPeriod();
      this.getAgricultureWarning();
    } else {
      this.getAnalysisApcp();
      this.getAnalysisSumTmp();
      this.getAnalysisTmp();
    }

  }
  // 切换气象服务类型
  onChangeForecastType = (value: string) => {
    this.setState({
      forecastType: value as "meteorological_warning" | "meteorological_history",
    }, () => {
      this.initData();
    });
  }

  //  切换作物类型
  onChangeCropType = (value: string) => {

    this.setState({
      crop: value,
    }, () => {
      this.initData();
    });
  }

  onChangeDisastertype = (value: string) => {
    this.setState({ disasterType: value });
  }

  // getDurationTime = (afterTime:number) => {
  //   const { year } = this.state;
  //   const endTime = year + afterTime - 1;
  //   const timeString = `${endTime}-12-31` ;
  //   return timeString;
  // }
  // 时间轴改变时间
  handleChangeDate = (date: string) => {

    console.log(date, "cahngedate");
    this.setState({ date }, () => this.initData());
  }

  render() {
    const { region, year, timeDate, date, disasterType, crop, startDate } = this.state;
    console.log(date, "render");
    const crop1 = crop === "chilli" ? "wheat" : crop
    return (
      <HeaderBox
        names={["气象预报预警"]}
        noPadding
      >
        <PageBody>
          <Map
            region={region}
            changeRegion={this.changeRegion}
            disaterType={disasterType}
            date={date}
            crop={crop1}
            startDate={startDate}
          />
          {this.renderSidebar()}
          <EllipseRadioSelect data={mapDataList} className="mapSelect" colorPalette={colorPalette} backSelectChange={this.onChangeCropType}/>
          {/* <EllipseRadioSelect data = {disasterList} className="disasterSelect" colorPalette= {colorPalette} backSelectChange={this.onChangeDisasterype}/> */}
          {/* <LocationSelect className='meteLocatonSelect'/> */}
          {this.renderDisasterWarning()}
          {/* {this.renderDisasterLevel()} */}
          <div className="timeline-box">
            <Timeline7 className="weatherTimeLine"  selected={date} data={timeDate} colorPalette={colorPalette} loop={"all" as "all"} interval={5000} onChange={this.handleChangeDate}/>
          </div>
        </PageBody>
      </HeaderBox>
    );
  }
  renderDisasterWarning = () => {
    const { disasterType } = this.state;
    return (
      <>
        <div className="weather-forecast">
          {diasterInfo.map(item => {
            return(
              <>
                <div className={disasterType === item.type ? "weather-forecast-item-active" : "weather-forecast-item"}  onClick={() => this.onChangeDisastertype(item.type)}>
                    <span className="forecast-item-content">
                      <img src={disasterType === item.type ? item.activeIcon : item.icon} alt="" className="forecast-item-icon"/>
                       {item.name}
                    </span>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }

  /** render sider bar*/
  renderSidebar = () => {
    const { forecastType, phenologicalList, weekForecast, agricultureWarning, tmpList, apcpList, sumtmpList, date } = this.state;
    return (
      <SideBar4
        className="breeding-distribute-side"

        colorPalette={colorPalette}
        sizeOnChange={noop}
        theme="light"
        style={{ width: 400, zIndex: 999 }}
        title={<EllipseRadioSelect colorPalette={colorPalette} data={mapMeteorologicalList} className="meteorologicalSelect" backSelectChange={this.onChangeForecastType}/>}
      >
         { forecastType === "meteorological_warning"
          ?  <MeteorologicalWarning data={phenologicalList} weekForecast={weekForecast} agricultureWarning={agricultureWarning} changeWeatherDate={this.ChangeWeatherDate} selected={date}/>
          :  <MeteorologicalHistory tmpList={tmpList} apcpList={apcpList} sumtmpList={sumtmpList} />}

      </SideBar4>
    );
  }

}
