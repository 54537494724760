import React from "react";
import { TeaGardenDetail } from "../models/garden-big-screen";
import { withRouter, RouteComponentProps } from "react-router-dom";
import TeaGardenInfoProvider from "../network/providers/tea-garden-info.provider";

/** 默认值 */
const defaultValue = {
  /** 茶园列表信息 */
  teaGardenList: [] as TeaGardenDetail[],
  /** 获取茶园列表信息 */
  async getTeaGardenList(needRoot?: boolean) { return [] as TeaGardenDetail[]; },
  /** 重新通过接口，获取茶园列表信息 */
  async reGetTeaGardenList(needRoot?: boolean) { return [] as TeaGardenDetail[]; },
};

// tslint:disable-next-line:variable-name
export const TeaGardenContext = React.createContext(defaultValue);

export type TeaGardenState = typeof defaultValue;

/**
 * 存储茶园详情
 * @author luyaxiang
 * @date 2019.6.21
 * @class TeaGardenContextDefaultProvider
 * @extends {React.Component<RouteComponentProps, TeaGardenState>}
 */
class TeaGardenContextDefaultProvider extends React.Component<RouteComponentProps, TeaGardenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ...defaultValue, ...{
        getTeaGardenList: this.getTeaGardenList,
        reGetTeaGardenList: this.reGetTeaGardenList,
      },
    };
  }

  /** 获取茶园列表信息 */
  getTeaGardenList = async (needRoot?: boolean) => {
    if (this.state.teaGardenList.length === 0) {
      const teaGardensDetail = await TeaGardenInfoProvider.getTeaGardensDetail(needRoot);
      this.setState({
        teaGardenList: teaGardensDetail,
      });
      return teaGardensDetail;
    }
    return this.state.teaGardenList;
  }

  /** 重新通过接口，获取茶园列表信息 */
  reGetTeaGardenList = async (needRoot?: boolean) => {
    const teaGardensDetail = await TeaGardenInfoProvider.getTeaGardensDetail(needRoot);
    this.setState({
      teaGardenList: teaGardensDetail,
    });
    return teaGardensDetail;
  }

  render() {
    return (
      <TeaGardenContext.Provider value={this.state} >
        {this.props.children}
      </TeaGardenContext.Provider>
    );
  }
}

export default withRouter(TeaGardenContextDefaultProvider);
