import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
// tslint:disable-next-line: ter-max-len
import { ExportReport,ExportPath,BreedingReport, BreedingReportDistribute, BreedingReportList, BreedingReportTrend, BreedingReportTypeDistribute, ReportListRequest, ReportUpateRequrest, ReportSummary, ReportAddRequrest } from "../../models/molecular-breeding-report";
import { ITable } from "@gago/frame";
// tslint:disable:ter-max-len

/**
 * 种质育种报告，数据服务层
 * @author maoshengtao
 */
export default class MolecularBreedingProvider extends BaseProvider {
  /** 获取单个种质 育种报告 */
  static async getBreedingReport(accessionNumber: string) {
    const { data } = await BaseRequestStaticServer.get<ResultType<BreedingReport[]>>("/plant/breeding/report/all", {
      params: {
        accessionNumber,
      }
    });
    return data;
  }

  /** 获取 育种报告汇总 */
  static async getReportSummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ReportSummary>>("/plant/breeding/report/summary");
    return data;
  }

  /** 获取 育种报告增加趋势 */
  static async getBreedingReportTrend() {
    const { data } = await BaseRequestStaticServer.get<ResultType<BreedingReportTrend[]>>("/plant/breeding/report/change");
    return data;
  }

  /** 获取 育种类型分布 */
  static async getBreedingReportTypeDistribute() {
    const { data } = await BaseRequestStaticServer.get<ResultType<BreedingReportTypeDistribute[]>>("/plant/breeding/report/distribution");
    return data;
  }

  /** 获取 育种地区分布 */
  static async getBreedingReportRegionDistribute() {
    const { data } = await BaseRequestStaticServer.get<ResultType<BreedingReportDistribute[]>>("/plant/breeding/report/regionSummary");
    return data;
  }

  /** 育种报告删除 */
  static async breedingReportDel(id: string) {
    const data: ResultType<number> = await BaseRequestStaticServer.post("/plant/breeding/report/del", {
      id,
    });
    return data;
  }

  /** 增加 育种报告 */
  static async breedingReportAdd(params: ReportAddRequrest) {
    const data: ResultType<number> = await BaseRequestStaticServer.post("/plant/breeding/report/add", params);
    return data;
  }

  /** 更新 育种报告 */
  static async breedingReportUpdate(params: ReportUpateRequrest) {
    const data: ResultType<number> = await BaseRequestStaticServer.post("/plant/breeding/report/update", params);
    return data;
  }

  /** 获取 育种数据列表 */
  static async getReportList(params: ReportListRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<BreedingReportList>>("/plant/breeding/report/list", {
      params,
    });
    return data;
  }

  /** 获取 导出文件链接 */
  static async getReportPath(params:ExportReport) {
    const { data }   = await BaseRequestStaticServer.get<ResultType<ExportPath>>("/plant/breeding/report/export", {
      params
    });
    return data;
  }

      // 下载文件
    
      static async download(url:string) {
        await BaseRequestStaticServer.downloadFile(url,'育种报告.zip','application/zip;charset=utf-8')
        
      }
}
