import styled from "styled-components";
import { ColorProps, GlobalColorPalette, ITable } from "@gago/frame";
import { RiskIndex } from "../../models/germplasm-breed";
import { Marker } from "@gago-react-gl/gago-react-gl";

// tslint:disable-next-line:variable-name
export const GermplasmBreedBody = styled.div<ColorProps<GlobalColorPalette>>`
  height: 100%;
  width: 100%;
  background-color: #09131f;
  background-image: url("/static/images/extend-bigscreen-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .TimeLine {
  }
  .header-box{
    height: 80px;
    width: 100%;
    background-image: url("/static/images/top-title.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 3;
    .header-name{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      letter-spacing: 3px;
      color: #fff;
      text-shadow: 0 5px 6px rgba(0, 0, 0, 0.5);
      font-family: PingFangSC;
      font-size: 36px;
      font-weight: 500;
    }
  }
  .body-content{
    height: calc(100% - 80px);
    width: 100%;
    padding: 16px;
    padding-top: 0px;
    display: flex;
    justify-content: flex-start;
    .left-aside{
      flex: 1 0 auto;
      width: 460px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .chart-box{
        flex: 1 1 auto;
        margin-top: 12px;
        &:first-of-type{
          margin-top: 0px;
        }
      }
    }
    .middle-content{
      flex: 1 0 auto;
      width: 930px;
      margin: 0 12px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      position: relative;
    }
  }
`;

export const MarkerIcom = styled(Marker)`
  pointer-events: auto;
  transition: zIndex 0.3s ease-out;
  z-index: 1;
  position: absolute !important;
  left: 0;
  right: 0;
  .equipment-box{
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    position: absolute;
  }
  .equipment-point{
    pointer-events: all;
    position: absolute;
    top: -10px;
    left: 10px;
    color: #f2f2f2;
    width: 40px;
    text-align: center;
    font-size:10px;
    font-family: Arial Unicode MS Regular;
    text-shadow: 1px 1px #000;
  }
`;
