import React, { Component } from "react";
import { observable, action, transaction } from "mobx";
import { observer } from "mobx-react";
import {
    Button,
    Table,
    Popconfirm,
    Input,
    Radio,
    Select,
    message,
    Drawer,
    Descriptions,
    Empty,
    Modal,
    Icon
} from "antd";
import { ColumnProps } from "antd/lib/table";

const {Option} = Select;
import { UserModalComponent, FormData } from "./user-modal";
import UserManageStore from "../user-manage.store";
import UserManageService, { UserInfo } from "../user-manage.service";

import styles from "./table.module.scss";
import moment from "moment";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { width } from "@gago/frame/es/siders/side-bar/config";
import CropProvider from "../../../network/providers/crop.provider";
import ExpertDataBaseProvider from "../../../network/providers/expert-database.provider";

const {Search} = Input;
const pagination = {size: "small", showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ["5", "10", "20"]};

interface S {
    /** 预览文件链接*/
    previewFile?: string;
    /** 文件 */
    filelist?: UploadFile<any>[];
    /** 更新文件预览 */
    updatePreviewFile?: UploadFile<any>;
}

// tslint:disable:jsx-no-lambda
@observer
export class TableComponent extends Component<S> {
    state: S = {
        filelist: [],
    };
    @observable title = "";
    @observable visible = false;
    @observable visibleDetail = false;
    @observable confirmLoading = false;
    private formData: FormData = {
        name: "",
        periodList: [
            {key: "播种期", val: "", createTime: "", file: "", id: 0, fileList: []},
            {key: "定植期", val: "", createTime: "", file: "", id: 0, fileList: []},
            {key: "开花期", val: "", createTime: "", file: "", id: 0, fileList: []},
            {key: "座果期", val: "", createTime: "", file: "", id: 0, fileList: []},
            {key: "始收期", val: "", createTime: "", file: "", id: 0, fileList: []},
            {key: "末收期", val: "", createTime: "", file: "", id: 0, fileList: []},
            {key: "品种植株性状调查", val: "", createTime: "", id: 0, file: "", fileList: []},
            {key: "品种果实性状调查", val: "", createTime: "", id: 0, file: "", fileList: []},
            {key: "品种抗性调查", val: "", createTime: "", id: 0, file: "", fileList: []},
            {key: "品种产量", val: "", createTime: "", id: 0, file: "", fileList: []},
        ],
    };
    @observable isEdit = false;

    componentWillMount() {
        UserManageStore.resetDepartmentId();
    }

    // tslint:disable no-unused-variable
    private columns: Array<ColumnProps<UserInfo>> = [
        {
            title: "序号",
            key: "index",
            fixed: "left",
            width: 50,
            className: styles.tableColumnIndex,
            // @ts-ignore
            render: (text, record, index) => index + 1,
        },
        {
            title: "品种名称",
            dataIndex: "name",
            width: 120,
            render: (text, record) => {
                return (
                    <>
                        <span className={styles.tableAction} onClick={() => this.showDetail(record)}>{text}</span>
                    </>
                );
            },
        },
        {
            title: "播种期",
            dataIndex: "播种期",
            width: 100,
            className: styles.tableColumnPhone,
        },
        {
            title: "定植期",
            dataIndex: "定植期",
            width: 100,
            className: styles.tableColumnTime,
        },
        {
            title: "开花期",
            width: 100,
            dataIndex: "开花期",
            className: styles.tableColumnPhone,
        },
        {
            title: "座果期",
            width: 100,
            dataIndex: "座果期",
            className: styles.tableColumnPhone,
        },
        {
            title: "始收期",
            width: 100,
            dataIndex: "始收期",
            className: styles.tableColumnPhone,
        },
        {
            title: "末收期",
            width: 100,
            dataIndex: "末收期",
            className: styles.tableColumnPhone,
        },
        {
            title: "品种植株性状调查",
            width: 100,
            dataIndex: "品种植株性状调查",
            className: styles.tableColumnPhone,
        },
        {
            title: "品种果实性状调查",
            width: 100,
            dataIndex: "品种果实性状调查",
            className: styles.tableColumnPhone,
        },
        {
            title: "品种抗性调查",
            width: 100,
            dataIndex: "品种抗性调查",
            className: styles.tableColumnPhone,
        },
        {
            title: "品种产量",
            width: 100,
            dataIndex: "品种产量",
            className: styles.tableColumnPhone,
        },
        {
            title: "操作",
            width: 100,
            fixed: "right",
            key: "action",
            className: styles.tableColumnAction,
            // @ts-ignore
            render: (text, record) => {
                return (
                    <>
                        <Popconfirm title="确认要删除此用户吗？" onConfirm={this.deleteUserById(record)}>
                            <span className={styles.tableAction}>删除</span>
                        </Popconfirm>
                        <span className={styles.tableAction} onClick={() => this.editUser(record)}>编辑</span>
                    </>
                );
            },
        },
    ];

    @action.bound
    private deleteUserById({id}: any) {
        return () => {
            console.log(id);
            CropProvider.deleteCropKV(id)
                .then(res => {
                    message.success("操作成功");
                    UserManageStore.setVarietyName(UserManageStore.varietyName);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                });
        };
    }

    @action.bound
    private editUser(record: any) {

        transaction(() => {
            this.formData.periodList.forEach((item, index) => {
                item.val = record[item.key];
                item.id = record.id.split(",")[index];
            });
            this.formData.name = UserManageStore.varietyName;
            this.title = "编辑";
            this.visible = true;
            this.isEdit = true;
        });
    }

    @action.bound
    private showDetail(record: any) {
        console.log(record);
        console.log(UserManageStore.listData);
        transaction(() => {
            this.visibleDetail = true;
        });
    }

    @action.bound
    private createUser() {
        CropProvider.getCropKVList().then(res => {
        });

        transaction(() => {
            this.formData.periodList.forEach(item => {
                item.val = "";
                item.id = 0;
            });
            this.title = "新增";
            this.visible = true;
            this.isEdit = false;
        });
    }

    @action.bound
    private cancelModal() {
        this.title = "";
        this.visible = false;
    }

    @action.bound
    private setConfirmLoading(value: boolean) {
        this.confirmLoading = value;
    }

    @action.bound
    private exportData() {
        if (UserManageStore.varietyName) {
            CropProvider.exportCropKV(UserManageStore.varietyName).then(res => {
                console.log(res);
                CropProvider.download(res);
            });
        }
    }

    @action.bound
    private submitForm = async ({name, periodList}: FormData) => {
        this.setConfirmLoading(true);

        if (name) {
            let year = new Date().getFullYear();
            const data = periodList.slice().map(item => {
                return {
                    crop: name,
                    year: year,
                    ...item
                };
            });

            if (this.isEdit) {
                CropProvider.editCropKV(data)
                    .then(res => {
                        message.success("操作成功");
                        UserManageStore.setVarietyName(UserManageStore.varietyName);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.setConfirmLoading(false);
                        this.cancelModal();
                    });
            } else {
                CropProvider.addCropKV(data)
                    .then(res => {
                        message.success("操作成功");
                        UserManageStore.setVarietyName(UserManageStore.varietyName);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.setConfirmLoading(false);
                        this.cancelModal();
                    });
            }
        }
    };

    handleSearchUsers = (value: string) => {
        UserManageService.getAllUsers(value);
    };

    /** preview ok */
    handleOk = (file: string) => {
        this.setState({
            previewFile: file,
        });
    };

    /** preivew cancle */
    handleCancel = () => {
        this.setState({
            previewFile: undefined,
        });
    };

    /** render upload preview */
    renderPreview = () => {
        const {previewFile} = this.state;
        if (!previewFile) {
            return null;
        }
        let child = <Empty description="不支持预览"/>;
        if (/.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(previewFile)) {
            child = <img src={"http://zhongzhi.admin.grassict.cn" + previewFile} style={{maxWidth: "100%"}}/>;
        }
        if (/pdf$/.test(previewFile)) {
            child = <iframe src={previewFile} width="100%" height={660}/>;
        }
        if (previewFile && /(doc|docx|ppt|pptx|xls|xlsx)$/.test(previewFile)) {
            child = <iframe width="660px" height="660px"
                            src={`https://view.officeapps.live.com/op/view.aspx?src=${previewFile}`}></iframe>;
        }
        return (
            <Modal
                wrapClassName={"control-width-660"}
                title={`预览`}
                visible={!!this.state.previewFile}
                footer={<Button type={"primary"} onClick={this.handleCancel}>关闭</Button>}
                onCancel={this.handleCancel}
            >
                <div style={{display: "flex", justifyContent: "center"}}>
                    {child}
                </div>
            </Modal>
        );
    };

    render() {

        const yearList = [
            {name: "2020", value: 2020},
            {name: "2021", value: 2021},
            {name: "2022", value: 2022},
            {name: "2023", value: 2023},
            {name: "2024", value: 2024}
        ];

        const yearOption = yearList.map((item, index) =>
            <Option value={item.value}>{item.name}</Option>);
        return (
            <div className={styles.container}>
                <div className={styles.varietySearch}>
                    <div>
                        <Select style={{width: 200}} placeholder={"请选择年份"}>
                            {yearOption}
                        </Select>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            icon="plus"
                            className={styles.createUserButton}
                            onClick={this.createUser}
                        >新建
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            icon="download"
                            className={styles.createUserButton}
                            onClick={this.exportData}
                        >导出
                        </Button>
                    </div>
                </div>
                <Table
                    className={styles.table}
                    // @ts-ignore
                    rowClassName={styles.tableRow}
                    rowKey="userId"
                    dataSource={UserManageStore.tableData}
                    columns={this.columns}
                    scroll={{x: 1000}}
                    pagination={pagination}
                />
                <UserModalComponent
                    title={this.title}
                    visible={this.visible}
                    confirmLoading={this.confirmLoading}
                    onCancel={this.cancelModal}
                    data={this.formData}
                    submitForm={this.submitForm}
                    edit={this.isEdit}
                />
                {this.renderPreview()}
                <Drawer
                    title="查看详情"
                    placement="right"
                    width={"50%"}
                    closable={false}
                    onClose={() => {
                        this.visibleDetail = false;
                    }}
                    visible={this.visibleDetail}
                >
                    {
                        UserManageStore.listData.map((item, index) => {
                            return (
                                <Descriptions column={3} title={item.key} bordered>
                                    <Descriptions.Item label="记录时间">{item.createTime}</Descriptions.Item>
                                    <Descriptions.Item label="记录人">{item.registerName}</Descriptions.Item>
                                    <Descriptions.Item label="年份">{item.year}</Descriptions.Item>
                                    <Descriptions.Item label="图片">
                                        <Button onClick={this.handleOk.bind(this, `${item.file || ""}`)}> <Icon
                                            type="eye"/> 预览</Button>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="记录值">{item.val}</Descriptions.Item>
                                </Descriptions>
                            );
                        })
                    }
                </Drawer>
            </div>
        );
    }
}
