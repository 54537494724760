import * as React from "react";
import styled from "styled-components";
import {
  Card,
  CardProps,
  Color,
  ColorProps,
  RightSlot,
  ICSS,
  ICSSProps,
  Theme,
  Icon,
  syntheticColors,
} from "@gago/frame";
import posed from "react-pose";
import tinycolor from "tinycolor2";
import { GlobalColorPalette } from "../../color-palette";

interface ColorContextProps {
  colorPalette: GlobalColorPalette;
  themeString: "light" | "dark";
}

export type Card1Props = ColorProps<GlobalColorPalette> &
  (RightSlot | {}) &
  CardProps &
  ICSSProps & {
    /**
     * 主题
     * @default light
     *
     * @type {("light" | "dark")}
     */
    theme: "light" | "dark";
    /** 副标题 */
    subTitle?: string;
    /** 不显示title */
    hiddenTitle?: true;
  };

export interface CardStates {
  expand: boolean;
}

// tslint:disable-next-line:variable-name
const RootContainer = styled.div<ColorContextProps>`
  &:hover {
    box-shadow: ${(props: ColorContextProps) => props.colorPalette.shadow[0]};
  }
  transition: all 0.3s;
  margin-bottom: 12px;
  padding: 0 16px 0 16px;
  border-radius: 8px;
  background-color: ${(props: ColorContextProps) => props.colorPalette.whiteColor[8]};;
`;
// tslint:disable-next-line:variable-name
const TitleContainer = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 15px 0;
`;
// tslint:disable-next-line:variable-name
const TitleText = styled.span<ColorContextProps>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props: ColorContextProps) =>
  props.themeString === "light" ? props.colorPalette.blackColor[7] : props.colorPalette.whiteColor[8]};

  .sub-title {
    font-size: 12px;
    color: rgba(0, 21, 23, 0.45);
    margin-left: 6px;
  }
`;
// tslint:disable-next-line:variable-name
const Content = styled.div<CardStates>`
  position: relative;
  overflow: hidden;
`;

/** 用于避免在动画过程中内容被压缩 */
// tslint:disable-next-line:variable-name
const ContentInner = styled.div<CardStates>`
  position: relative;
  min-height: ${(props: any) => (props.expand ? "50px" : "0")};
  width: 100%;
  height: 100%;
`;

// tslint:disable-next-line:variable-name
const RightContainer = styled.div<ColorContextProps>`
  color: ${(props: ColorContextProps) =>
    props.themeString === "light" ? props.colorPalette.mainBlackTransparentColor[5] : props.colorPalette.whiteColor[4]};
  display: inline-flex;
  height: 16px;
  line-height: 16px;
  align-items: center;
`;

// tslint:disable-next-line:variable-name
const LeftContainer = styled.div`
  display: inline-flex;
  height: 16px;
  line-height: 16px;
`;

// tslint:disable-next-line:variable-name
const ContentWithAnimation = posed(Content)({
  /**
   * Mo9后缀是一个随机字符，仅仅是为了避免和业务中的动画状态重名
   * 重名会导致父动画变化触发子元素的动画
   * @see https://popmotion.io/pose/learn/animating-children/
   */
  card1ExpandMo9: {
    height: "calc(100% - 62px)",
    applyAtEnd: { overflow: "initial" },
    transition: {
      duration: 300,
      // delay: stage2,
      ease: "easeInOut",
    },
  },
  card1UnExpandMo9: {
    height: 0,
    applyAtStart: { overflow: "hidden" },
    transition: {
      duration: 300,
      // delay: stage2,
      ease: "easeInOut",
    },
  },
});
// tslint:disable-next-line:variable-name
const BottomPadding = styled.div``;
// tslint:disable-next-line:variable-name
const BottomPaddingWithAnimation = posed(BottomPadding)({
  /**
   * Mo9后缀是一个随机字符，仅仅是为了避免和业务中的动画状态重名
   * 重名会导致父动画变化触发子元素的动画
   * @see https://popmotion.io/pose/learn/animating-children/
   */
  paddingExpandMo9: {
    height: "16px",
    transition: {
      duration: 300,
      // delay: stage2,
      ease: "easeInOut",
    },
  },
  paddingUnExpandMo9: {
    height: "0px",
    transition: {
      duration: 300,
      // delay: stage2,
      ease: "easeInOut",
    },
  },
});

// tslint:disable:jsx-no-multiline-js
export class Card1 extends React.PureComponent<Card1Props, CardStates>
  implements Color, Card, ICSS, Theme {
  static defaultProps = {
    theme: "light",
  };
  state: CardStates = {
    expand:
      typeof this.props.defaultExpand === "undefined"
        ? true
        : this.props.defaultExpand,
  };
  render() {
    const rightComponent = (this.props as RightSlot).rightComponent;
    const { title, subTitle, children, showExpandButton, hiddenTitle } = this.props;
    const expand = this.getExpand_();
    return (
      <RootContainer
        colorPalette={this.props.colorPalette}
        themeString={this.props.theme}
        style={this.props.style}
        className={this.props.className}
      >
        <TitleContainer style={hiddenTitle ? { padding: 0 } : {}}>
          <LeftContainer>
            <TitleText
              colorPalette={this.props.colorPalette}
              themeString={this.props.theme}
            >
              {title}
              {subTitle ? <div className="sub-title">{subTitle}</div> : null}
            </TitleText>
          </LeftContainer>
          <RightContainer
            colorPalette={this.props.colorPalette}
            themeString={this.props.theme}
          >
            {rightComponent}
            {showExpandButton ? (
              <span>
                <Icon
                  style={{ height: 16, width: 16, cursor: "pointer" }}
                  onClick={this.handleExpand_}
                >
                  {expand ? "up" : "down"}
                </Icon>
              </span>
            ) : null}
          </RightContainer>
        </TitleContainer>
        <ContentWithAnimation
          expand={expand}
          pose={expand ? "card1ExpandMo9" : "card1UnExpandMo9"}
        >
          <ContentInner expand={expand}>{children}</ContentInner>
        </ContentWithAnimation>
        <BottomPaddingWithAnimation
          pose={expand ? "paddingExpandMo9" : "paddingUnExpandMo9"}
        />
      </RootContainer>
    );
  }
  private handleExpand_ = () => {
    const { onExpandChange } = this.props;
    const expand = this.getExpand_();
    const nextExpand = !expand;
    if (onExpandChange) {
      onExpandChange(nextExpand);
    } else {
      this.setState({ expand: nextExpand });
    }
  }
  private getExpand_ = () => {
    const { expand } = this.props;
    const { expand: innerExpand } = this.state;
    if (typeof expand !== "undefined") {
      return expand;
    }
    return innerExpand;

  }
}
