import { ITable } from "@gago/frame";
import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer, BaseRequestStaticServer3, BaseRequestStaticServer4 } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
import { PopularizeRatio, PopularizeRanking, PopularizeBaseArea, PopularizeOccupancy,
  PopularizeCertificate, PopularizeFarmingTechQr, PopularizeMapType, CompanyList, PopularizeStation } from "../../models/extension-screen";

interface CodeParam {
  /** 行政编码 */
  code?: string;
  /** 作物类型 */
  crop?: string;
  /** 作物时间 */
  date?: string;
  /** 公司id */
  companyId?: string;
  /** 年份 */
  year?: string;
  /** 获取年份类型 农事: farming 农技: tech 服务站及人员: station */
  type?: string;
}

/**
 * 种质推广溯源一张图， 数据服务层
 * @author 马俊峰
 * @date 2020-08-31
 * @export
 * @class ExtensionScreenProvider
 * @extends {BaseProvider}
 */
export default class ExtensionScreenProvider extends BaseProvider {
  /** 静态资源sever */
  static newsever: any = BaseRequestStaticServer;
  constructor(param: string) {
    super();
    if (param === "off") {
      ExtensionScreenProvider.newsever = BaseRequestStaticServer;
    }
    if (param === "on") {
      ExtensionScreenProvider.newsever = new BaseRequestStaticServer4();
    }
  }
  /** 销售地图数据 */
  static async getPopularizeMap(params: CodeParam) {
    // @ts-ignore
    const { data } = await ExtensionScreenProvider.newsever.get<ResultType<PopularizeMapType[]>>("/popularize/map", { params });
    return data;
  }
  /** 公司数据列表 */
  static async getCompanyList() {
    const { data } = await BaseRequestStaticServer.get<ResultType<CompanyList[]>>("/company/list", { });
    return data;
  }
  /** 获取农事及农机及服务站人员年份列表 */
  static async getCompanyYear(params: CodeParam) {
    const { data } = await BaseRequestStaticServer.get<ResultType<string[]>>("/popularize/farmingtech/year", { params });
    return data;
  }
  /** 推广全国统计数据获取 */
  static async getPopularizeRatio(params: CodeParam): Promise<ITable<1, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeRatio[]>>("/popularize/ratio", { params });
    const tableData: ITable<1, 1>["data"] = [];
    let allNumber = 0;
    for (const iterator of data) {
      tableData.push(["", iterator.cropName, iterator.popularize]);
      allNumber += iterator.popularize;
    }
    return {
      title: `${allNumber}`,
      subtitle: "总面积(万亩)",
      head: ["ID", "名称", "面积"],
      range: [[], [], []],
      prefix: ["", "", ""],
      suffix: ["", "", "万亩"],
      defaultValue: [undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 推广数据排名 */
  static async getPopularizeRanking(params: CodeParam): Promise<ITable<1, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeRanking[]>>("/popularize/ranking", { params });
    const tableData = data.map<ITable<1, 1>["data"][0]>(item => ["", item.name, item.popularize]);
    return {
      title: "",
      subtitle: "总面积(万亩)",
      head: ["ID", "类型", "单位"],
      range: [[], [], []],
      prefix: ["", "", ""],
      suffix: ["", "", "万亩"],
      defaultValue: [undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 基地面积汇总 */
  static async getPopularizeBasearea(params: CodeParam): Promise<ITable<2, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeBaseArea[]>>("/popularize/basearea", { params });
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", "作物", item.cropName, item.baseArea]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "类型", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "万亩"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 服务资源统计 */
  static async getPopularizeStation(params: CodeParam): Promise<ITable<1, 2>> {
    // tslint:disable:ter-max-len
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeStation[]>>("/popularize/station", { params });
    const tableData = data.map<ITable<1, 2>["data"][0]>(item => ["", `${item.month}月`, item.station, item.personnel]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "月份", "服务站", "服务人员"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "个", "人"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 作物占有率数据 */
  static async getPopularizeOccupancy(params: CodeParam) {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeOccupancy[]>>("/popularize/occupancy", { params });
    return data;
  }
  /** 品种审定证书 */
  static async getPopularizeCertificate(params: CodeParam): Promise<ITable<1, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeCertificate[]>>("/popularize/certificate", { params });
    const tableData: ITable<1, 1>["data"] = [];
    let allNumber = 0;
    for (const iterator of data) {
      tableData.push(["", iterator.cropName, iterator.certificate]);
      allNumber += iterator.certificate;
    }
    return {
      title: `${allNumber}`,
      subtitle: "总计(份)",
      head: ["ID", "名称", "单位"],
      range: [[], [], []],
      prefix: ["", "", ""],
      suffix: ["", "", "份"],
      defaultValue: [undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 获取农事月度统计 */
  static async getPopularizeFarming(params: CodeParam): Promise<ITable<2, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeFarmingTechQr[]>>("/popularize/farmingtech",
    { params: { ...params, type: "farming" } });
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", `${item.year}`, `${item.month}月`, item.value]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "次"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 获取农机服务月度统计 */
  static async getPopularizeTech(params: CodeParam): Promise<ITable<2, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeFarmingTechQr[]>>("/popularize/farmingtech",
    { params: { ...params, type: "tech" } });
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", `${item.year}`, `${item.month}月`, item.value]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "次"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
  /** 获取二维码月度统计 */
  static async getPopularizeQr(params: CodeParam): Promise<ITable<2, 1>> {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeFarmingTechQr[]>>("/popularize/farmingtech",
    { params: { ...params, type: "qr" } });
    const tableData = data.map<ITable<2, 1>["data"][0]>(item => ["", "总计", `${item.year}`, item.value]);
    return {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "次"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: tableData,
    };
  }
}
