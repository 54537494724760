import icon1 from "./images/icon1.png";
import icon2 from "./images/icon2.png";
import icon3 from "./images/icon3.png";
import icon4 from "./images/icon4.png";
import icon5 from "./images/icon5.png";
import icon6 from "./images/icon6.png";

const base = [
    {
        name: "基肥记录",
        icon: icon1,
    },
    {
        name: "追肥记录",
        icon: icon2,
    },
    {
        name: "用药记录",
        icon: icon3,
    },
    {
        name: "除草记录",
        icon: icon4,
    },
    {
        name: "生育期记录",
        icon: icon5,
    },
    {
        name: "浇水记录",
        icon: icon6,
    },
];
const base1 = ['', icon1, icon2, icon3, icon4, icon5, icon6];
/**
 *
 * 获取 图例 icon
 *
 */

export const getLegendIcon = () => {
    return base;
}

export const getLegendIconList = (num: any) => {
    return base1[num] ? base1[num] : '';
}
