import styled from "styled-components";

// tslint:disable-next-line:variable-name
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  .page-left{
    flex: 1;
    height: 100%;
    border-radius: 4px;
    .top-card-list{
      display: flex;
      justify-content: space-around;
      margin-bottom: 16px;
    }
    .page-left-bottom{
      width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      height: calc(100% - 100px);
      .button-list{
        display:flex;
        justify-content:space-between;
        width:100%;
        margin-bottom:16px;
        .button-list-right {
          display:flex;
        }
      }
      .table-module{
        height: calc(100% - 256px);
      }
      .ant-table-pagination.ant-pagination{
        margin: 16px;
      }
    }
  }
  .page-right{
    width: 344px;
    height: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    margin-left: 16px;
    overflow-y: auto;
  }
`;