import styled from "styled-components";
import {ColorProps, GlobalColorPalette, ITable} from "@gago/frame";
import {Marker} from "@gago-react-gl/gago-react-gl";
import legendBg from "./images/legend-bg.png";
import icon1 from "./images/icon1.png";
import icon2 from "./images/icon2.png";
import icon3 from "./images/icon3.png";
import icon4 from "./images/icon4.png";
import icon5 from "./images/icon5.png";
import icon6 from "./images/icon6.png";

// tslint:disable:variable-name
export const BigScreenBody = styled.div<ColorProps<GlobalColorPalette>>`
  height: 100%;
  width: 100%;
  background-color: #09131f;
  background-image: url("/static/images/big-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .header-box {
    height: 80px;
    width: 100%;
    background-image: url("/static/images/top-title.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 3;

    .header-name {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 3px;
      color: #fff;
    }
  }

  .mc-map-layer {
    height: calc(100% - 80px);
    width: calc(100% - 10px);
    padding-top: 16px;
    flex: 1 2 auto;
    position: relative;

    .legned-box {
      position: absolute;
      bottom: -2px;
      right: 0px;
      z-index: 99;
      height: 250px;
      width: 266px;
      background-image: url("${legendBg}");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .middle-content {
    flex: 1 0 auto;
    width: 930px;
    margin: 0 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .mc-chart-box {
      height: 108px;
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-start;

      .chart-box {
        width: 16%;
        flex: 1 1 auto;
        margin-right: 12px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
  }
}
`;

// tslint:disable-next-line:one-variable-per-declaration
export const MarkerIcom = styled(Marker)<{ colorPalette: GlobalColorPalette }>`
  pointer-events: none;
  transition: zIndex 0.3s ease-out;
  z-index: 1;

  &:hover {
    z-index: 5;
  }


  .icon-box {
    height: 32px;
    width: 32px;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }


  .base-icon1 {
    background-image: url("${icon1}");
  }
  .base-icon2 {
    background-image: url("${icon2}");
  }
  .base-icon3 {
    background-image: url("${icon3}");
  }
  .base-icon4 {
    background-image: url("${icon4}");
  }
  .base-icon5 {
    background-image: url("${icon5}");
  }
  .base-icon6 {
    background-image: url("${icon6}");
  }

  .type-marker{
    position: absolute;
    pointer-events: auto;
    top: -1px;
    left: 0px;
    .icon-marker{
      height: 1px;
      width: 1px;
      user-select: none;
      background-color: transparent;
    }
    .prise-info-box{
      position: absolute;
      left: 16px;
      bottom: 1px;
      z-index: 99;
      padding: 0 16px;
      min-width: 200px;
      width: 400px;
      border: solid 1px rgba(0, 229, 255, 0.45);
      background-color: rgba(0, 42, 66, 0.8);
      transform: translateY(50%);
      .ls-title{
        // height: 46px;
        padding: 4px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .lst-left{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .lstl-icon{
            height: 14px;
            width: 14px;
            background-image: url("/static/images/title-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .lstl-value{
            margin-left: 8px;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.4;
            color: rgb(236, 255, 253);
          }
        }
        .lst-right{
          cursor: pointer;
          font-size: 14px;
          color: #65fbff;
          .gago-frame-jiage{
            transform: rotate(45deg);
          }
        }
      }
      .pib-border{
        padding: 16px 12px;
        .title-tool{
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          line-height: 1.2;
          margin-bottom: 16px;
          .tool-name{
            width: 83px;
            font-size: 18px;
            font-weight: 500;
            white-space: nowrap;
            color: rgb(27, 208, 213);
          }
          .tool-value{
            font-size: 18px;
            color: rgb(224, 254, 255);
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
          .tool-btn{

            margin-left:5px;
          }
        }
        .preview-img{
          max-width: 142px;
          max-height: 142px;
          overflow: hidden;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .info-img{
          width: 100%;
          max-width: 100%;
          .img-url{
            width: 100%;
            max-width: 100%;
            display: block;
          }
        }
      }
    }
  
`;

/** 原生种质库类型 */
// tslint:disable-next-line:completed-docs
export const germplasmBank: { label: string; value: string }[] = [
    {label: "一期", value: "一期"},
    {label: "二期", value: "二期"},
    {label: "三期", value: "三期"},
];

export const baseList = [
    {
        Campony: "三瑞农业科技股份有限公司",
        Lon: 108.191054,
        Lat: 41.04504,
        area: "2562.854245",
        townCode: "150821100000",
        baseName: "八里桥葵博园",
        CompanyId: "SRNK",
    },
    {
        Campony: "内蒙古兆丰河套面业有限公司",
        Lon: 107.171868,
        Lat: 40.875104,
        area: "97.080935",
        townCode: "150826100000",
        baseName: "研究院小麦基地4",
        CompanyId: "ZFMY",
    },
    {
        Campony: "内蒙古巴彦淖尔市科河种业有限公司",
        Lon: 107.509388,
        Lat: 40.816521,
        area: "64.797936",
        townCode: "150802201000",
        baseName: "玉米育种基地1",
        CompanyId: "KHZY",
    },
    {
        Campony: "内蒙古鲜农农牧业科技有限公司",
        Lon: 107.585064,
        Lat: 40.828735,
        area: "1798.622117",
        townCode: "150802201000",
        baseName: "联丰村基地",
        CompanyId: "XNNY",
    },
    {
        Campony: "内蒙古天衡制药有限公司",
        Lon: 109.182725,
        Lat: 41.01693,
        area: "1529.730382",
        townCode: "150823105000",
        baseName: "天衡仿野生蒙中药材科技示范园区",
        CompanyId: "THZY",
    },
    {
        Campony: "内蒙古天赋河套种质科技发展有限公司",
        Lon: 107.488123,
        Lat: 40.835445,
        area: "171860.0803",
        townCode: "150802201000",
        baseName: "天赋河套总部",
        CompanyId: "TFHT",
    },
    {
        Campony: "内蒙古蒙草生态环境(集团)股份有限公司",
        Lon: 107.443669,
        Lat: 40.819054,
        area: "265.815684",
        townCode: "150802201000",
        baseName: "内蒙古蒙草生态环境(集团)股份有限公司",
        CompanyId: "MCST",
    },
];
