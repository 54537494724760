import { ITable } from "@gago/frame";
import { ResultType } from "../../models/common";
import { 
  BaseRequest,
  GrowthAnalysis,
  GrowthCurv,
  GrowthSummary,
  Phenophase
} from "../../models/growth-monitoring";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";


/**
 * 繁育气候
 * @author wangchengbing
 * @date 2021-1-11
 * @export
 * @class 
 * @extends {BaseProvider}
 */
export default class GrowthMonitoringProvider extends BaseProvider {
  /** 长势分析 */
  static async getGrowthAnalysis(params:BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<GrowthAnalysis>>("/plant/breed/growth/analysis", { params });
    return data; 
  }
  /** 作物长势汇总 */
  static async getGrowthSummary(params:BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<GrowthSummary[]>>("/plant/breed/growth/summary", { params });
    return data;
  }
  /** 作物长势曲线 */
  static async getGrowthCurve(params:BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<GrowthCurv[]>>("/plant/breed/growth/curve", { params});
    return data;
  }
  
  /** 作物理论物候期 */
  static async getPhenophase() {
    const { data } = await BaseRequestStaticServer.get<ResultType<Phenophase[]>>("/plant/breed/cropPeriod", { });
    return data;
  }

  /** 作物理论物候期 */
  static async getBreedTime() {
    const { data } = await BaseRequestStaticServer.get<ResultType<string[]>>("/plant/breed/time", { });
    return data;
  }

}
