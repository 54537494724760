import React, { createContext, FunctionComponent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { serverConfig } from "../../network/server-config";



const defaultUrl = {
  src: "#",
  mainUrl: "",
};

export const SyUrlContext = createContext(defaultUrl);

const SyUrlContextProvider: FunctionComponent<RouteComponentProps> = (props) => {
  const { location: { hash, search } } = props;
  const mainUrl = search.slice(1) === "node" ? serverConfig.api_land_v1 : serverConfig.api_sy_v1;
  return (
    <SyUrlContext.Provider value={{ mainUrl, src: hash.slice(1) }}>
      {props.children}
    </SyUrlContext.Provider>
  );
};

export default withRouter(SyUrlContextProvider);

/**
 * @author wudajian
 * @description iframe 嵌套页面 传入要嵌套的url
 * @returns JSX.Element
 */
export function IframePage(props: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>) {
  return (
    <SyUrlContext.Consumer>
      {({ src, mainUrl }) => {
        const isFrame = src !== "";
        return <iframe
          style={{ display: isFrame ? "block" : "none" }}
          src={`${mainUrl}${src || "/bus_product/busProduct/list"}`}
          width="100%"
          height="100%"
          frameBorder={0}
          {...props}
        />;
      }}
    </SyUrlContext.Consumer>
  );
}
