// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select, Cascader } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import { CropCategory, ResourceList, ResourceListRequest } from "../../models/germplasm-database";
import { soilGenus, soilSelect, secondSoilSelect,yearSelect, cropSelect } from './const';
import { Region } from "../../models/common";
import { CascaderOptionType} from 'antd/lib/cascader';
import { 
  RegionTree,
  SamplePoint,
  LandReport,
  InfoItem
} from "../../models/land-evalute";
const { Option } = Select;
const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

type P = FormComponentProps & {
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 搜索 */
  onTopSearch(data: any): void;
  /** load form */
  onLoad(form: FormComponentProps['form'] ): void;
  soilGroup?:{value:string}[]
  subclasses?:{value:string}[]
  soilGenus?:{value:string}[]
  soilSpecies?:{value:string}[]
  cropName?:{value:string}[];
  areaList:RegionTree[]
};

interface S {
  /** 作物分类类型 */
  cropType?: string;
}

/**
 * 高级搜索
 * @author maoshengtao
 */
class AdvancedSearchForm extends React.Component<P, S> {
  state: S = {

  };

  componentDidMount() {
    const { onLoad, form } = this.props;
    onLoad(form);
  }

  /** search */
  handleSearch = (e: any) => {
    e.preventDefault();
    const { form, onTopSearch } = this.props;
    form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (err) {
        return;
      }
      onTopSearch(values);
    });
  }

  /** reset */
  handleReset = () => {
    this.props.form.resetFields();
  }

  /** render 作物分类 */
  renderSelect1 = () => {
    const { getFieldDecorator } = this.props.form;
    const { cropCategory } = this.props;
    if (!cropCategory || cropCategory && cropCategory.length === 0) {
      return null;
    }

    return (
      getFieldDecorator(`作物分类`, {
        initialValue: cropCategory[0].name,
      })(
        <Select
        >
          {cropCategory.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>
      )
    );
  }

  /** render 二类名称 */
  renderSelect2 = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { cropCategory } = this.props;
    if (!cropCategory || cropCategory && cropCategory.length === 0) {
      return null;
    }
    const selectdata = cropCategory.find((item, index) => item.name === getFieldValue("作物分类")) || cropCategory[0];
    if (!selectdata.children || selectdata && selectdata.children.length === 0) {
      return (<Select key={"无值"} placeholder="无二级分类" disabled />);
    }
    return (
      getFieldDecorator(`二类名称`, {
        initialValue: selectdata.children[0].name,
      })(
        <Select
        >
          {selectdata.children.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>
      )
    );
  }

  /** render 入库年份 */
  renderSelect3 = () => {
    const { getFieldDecorator } = this.props.form;
    const { storageYears } = this.props;
    if (!storageYears || storageYears && storageYears.length === 0) {
      return null;
    }

    return (
      getFieldDecorator(`入库年份`, {
        // initialValue: storageYears[0],
      })(
        <Select
          placeholder={"请选择"}
        >
          {storageYears.map((item) => {
            return <Option key={item} value={item}>{item}</Option>;
          })}
        </Select>
      )
    );
  }

  renderYear = () => {
    if (!yearSelect || yearSelect.length === 0) return null;
    const { form:{ getFieldDecorator } } = this.props;

    return (
      getFieldDecorator('year')(
        <Select
          placeholder={"请选择"}
        >
          {yearSelect.map((item) => {
            return <Option key={item.label} value={item.value}>{item.label}</Option>;
          })}
        </Select>
      )
    )
    
  }

  renderCrop = () => {
    const { cropName } = this.props;
    if (!cropName || cropName.length === 0) return null;
    const { form:{ getFieldDecorator } } = this.props;

    return (
      getFieldDecorator('cropName')(
        <Select
          placeholder={"请选择"}
        >
          {cropName.map((item) => {
            return <Option key={item.value} value={item.value}>{item.value}</Option>;
          })}
        </Select>
      )
    )
    
  }
  renderSelect = (key:string,params?:{value:string}[],) => {
    // const { soilGenus } = this.props;
    if (!params || params.length === 0) return null;
    const { form:{ getFieldDecorator } } = this.props;

    return (
      getFieldDecorator(`${key}`)(
        <Select
          placeholder={"请选择"}
        >
          {params.map((item) => {
            return <Option key={item.value} value={item.value}>{item.value}</Option>;
          })}
        </Select>
      )
    )
    
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { storageYears,soilGenus, soilGroup, soilSpecies, subclasses,areaList } = this.props;
    return (
      <>
        <WrapForm onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col span={8} >
              <Form.Item label={`年份`} >
                {this.renderYear()}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`统一编号`} >
                {getFieldDecorator(`soilNumber`)(<Input placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`地区`} >
                {getFieldDecorator(`areaList`)(<Cascader allowClear={false} fieldNames={{label:'name',value:'name',children:'children'}}  options={areaList as unknown as CascaderOptionType[]} />)}
              </Form.Item>
            </Col>
           
            <Col span={8} >
              <Form.Item label={`作物`} >
                {this.renderCrop()}
              </Form.Item>
            </Col>
            
            <Col span={8} >
              <Form.Item label={`土类`} >
              {/* {getFieldDecorator(`选育单位`)(<Input placeholder="请输入" />)} */}
              {this.renderSelect('soilGroup',soilGroup,)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`亚土类`} >
              {this.renderSelect('subclasses',subclasses,)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`土属`} >
              {this.renderSelect('soilGenus',soilGenus,)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`土种`} >
              {this.renderSelect('soilSpecies',soilSpecies,)}
              </Form.Item>
            </Col>
            {/* <Col span={8} >
              <Form.Item label={`选育单位`} >
              {getFieldDecorator(`选育单位`)(<Input placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label={`入库年份`} >
                {this.renderSelect3()}
              </Form.Item>
            </Col> */}
            <Col span={8} style={{ textAlign: "right" }}>
              {/* <Button onClick={this.handleReset}>
                重置
              </Button> */}
              <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">
                搜索
              </Button>
            </Col>
          </Row>
        </WrapForm>
      </>
    );
  }
}

export const LeftModule1 = Form.create<P>({ name: "advanced_search" })(AdvancedSearchForm);