export const serverConfig = {
  api_v1: window.config.publisher, // 测试环境api
  /** 瓦片地址 */
  api_tile: "https://wthqiepian.gagogroup.cn/AX_Data",
  /** xin le 瓦片地址 */
  api_tile_xl: "http://qiepian.gagogroup.cn/xinle_class",
  /** publish地址 */
  api_publish_v2: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2",
  /** api 平台地址 */
  api_v4: "http://api.gagogroup.cn/api/v4",
  /** appToken */
  appToken: "cd61f0fc3660646bf84723cc62be7b587e"
    + "d477223e142e692537cc3065e00f9577545892fd"
    + "0562dcbb7911839489259a02e748659e1ff56827"
    + "a1eba3bf5baf4fa047ea9fbfc3db740b66c7f237"
    + "0715e00297a16e590da60a6e1f2bf69e4495c997"
    + "74790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92",
  /** 系统页面请求token */
  apiToken: sessionStorage.getItem("token") ? sessionStorage.getItem("token") : "empty_token",
};

/** 设置token */
export const updateServerConfigApiToken = (token: string) => {
  serverConfig.apiToken = token;
};
