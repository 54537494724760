import React, {Component} from "react";
import {observer} from "mobx-react";
import {Modal, Form, Input, Select, Row, Col, Icon, Upload, message} from "antd";
import {ModalProps} from "antd/lib/modal";
import {FormComponentProps} from "antd/lib/form";

import FormSelect from "@gago/lib/components/form-select";
import {Partial} from "../../../utils/type";
import {RcFile, UploadChangeParam} from "antd/lib/upload";
import {UploadFile} from "antd/lib/upload/interface";
import {ExpertCateInfo} from "../../../models/expert-database";
import {action, observable} from "mobx";
import {WrappedFormUtils} from "antd/lib/form/Form";

export interface GermplasmData {
    id?: number;
    clmc: string;//名称
    cllx: string;//描述
    cjid: string;//附件
    ycnd: string;//工具链接
    ly: string;//工具链接
    remarks: string;//备注
    createDate?: string;
    updateDate?: string;
}

const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};

interface Props extends ModalProps, Partial<FormComponentProps> {
    data: GermplasmData;

    submitForm(values: GermplasmData): void;

    edit: boolean;
}

// @ts-ignore
@Form.create()
@observer
export class GermplasmDataModalComponent extends Component<Props> {


    private submitForm = () => {
        const {submitForm, form, data} = this.props;
        if (!form) return;
        form.validateFields((err: any) => {
            if (!err) {
                form.validateFields((error: any, values: GermplasmData) => {
                    if (error) {
                        return;
                    }
                    if (data.id) {
                        values.id = data.id
                    }
                    submitForm({...values});
                });
                form.resetFields();
            }
        });
    };

    handleUploadBefore = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("请选择图片！");
        }
        // const isLt2M = file.size / 1024 /1024 < 2;
        // if (!isLt2M) {
        //   message.error('图片大于2M');
        // }
        return isJpgOrPng;
    };

    /**
     * 返回
     * @private
     * @param {*} e
     * @memberof UserModalComponent
     */
    private clickCancel = (e: any) => {
        const {onCancel, form} = this.props;
        onCancel && onCancel(e);
        // @ts-ignore
        form.resetFields();
    };

    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await this.handleGetBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    // 生成预览图片
    handleGetBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    uploadChange = (res: UploadChangeParam<UploadFile<any>>, key: string) => {

        this.setState({fileList: [...res.fileList]});

    };

    render() {
        const {title, visible, confirmLoading, data, edit} = this.props;
        const token = window.sessionStorage.getItem("RouYiToken") as string;
        const header = {
            "Authorization": token
        };
        const {getFieldDecorator} = this.props.form as WrappedFormUtils<any>;
        // @ts-ignore
        // const departmentData = UserManageStore.departments.map(item => ({
        //     key: item.departmentId,
        //     value: item.departmentName
        // }));

        // tslint:disable jsx-no-multiline-js
        return (
            <Modal
                width={"60%"}
                title={title}
                visible={visible}
                onCancel={this.clickCancel}
                onOk={this.submitForm}
                confirmLoading={confirmLoading}
            >
                <Form style={{margin: "10px"}}>
                    {/*<Form.Item label="名称" {...formItemLayout}>*/}
                    {/*    {getFieldDecorator("mc", {initialValue: data.mc,})(<Input placeholder={"请输入名称"}/>)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="描述" {...formItemLayout}>*/}
                    {/*    {getFieldDecorator("ms", {initialValue: data.ms,})(<Input placeholder={"请输入描述"}/>)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="附件" {...formItemLayout}>*/}
                    {/*    {getFieldDecorator("fj", {initialValue: data.fj,})(<Input placeholder={"请输入附件"}/>)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="工具链接" {...formItemLayout}>*/}
                    {/*    {getFieldDecorator("gjlj", {initialValue: data.gjlj,})(<Input placeholder={"请输入工具链接"}/>)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="备注" {...formItemLayout}>*/}
                    {/*    {getFieldDecorator("remarks", {initialValue: data.remarks,})(<Input placeholder={"请输入备注"}/>)}*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        );
    }
}
