import React, {Component} from "react";
import {observer} from "mobx-react";
import {Modal, Form, Input, Select, Row, Col, DatePicker, Icon, Button, Upload, message} from "antd";
import {ModalProps} from "antd/lib/modal";
import {FormComponentProps} from "antd/lib/form";

import UserManageStore from "../user-manage.store";
import FormSelect from "@gago/lib/components/form-select";
import {Partial} from "../../../utils/type";
import monment from "moment";
import {observable} from "mobx";
import {RcFile, UploadChangeParam} from "antd/lib/upload";
import {UploadFile} from "antd/lib/upload/interface";

export interface BaseFertilizerFormData {
    id?: number;
    provinceList?: any[];
    province: string;
    city: string;
    district: string;
    plotName: string;
    breedName: string;
    methodName: string;
    useDate: string;
    beforePh: string;
    organicContent: string;
    nutrientContent: string;
    rainfall: string;
    airTemp: string;
    airHum: string;
    soilTemp: string;
    soilHum: string;
    accTemp: string;
    afterRead: string;
    detailList: detailListData[],
    photo?: string;
    file?: any[];
}

interface detailListData {
    fertilizerName: string,
    amount: string,
    unit: string,
}

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};

interface Props extends ModalProps, Partial<FormComponentProps> {
    data: BaseFertilizerFormData;

    submitForm(values: BaseFertilizerFormData): void;

    edit: boolean;
}

// @ts-ignore
@Form.create()
@observer
export class BaseFertilizerModalComponent extends Component<Props> {

    private renderProvinceOptions() {

        return UserManageStore.provinceList.map(item => (
            <Select.Option key={item.id}>{item.name}</Select.Option>
        ));
    }

    private renderCityOptions() {
        return UserManageStore.cityList.map(item => (
            <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
        ));
    }


    private getCity(item: any) {
        const {form} = this.props;
        form?.setFieldsValue({city: '', province: item.label})
        UserManageStore.getCityActive(item.key)
    }

    private submitForm = () => {
        const {submitForm, form, data} = this.props;
        if (!form) return;

        form.validateFields((error: any, values: BaseFertilizerFormData) => {
            if (error) {
                return;
            }
            submitForm({...data, ...values});
        });
        form.resetFields();
    }

    /**
     * 返回
     * @privates
     * @param {*} e
     * @memberof UserModalComponent
     */
    private clickCancel = (e: any) => {
        const {onCancel, form} = this.props;
        onCancel && onCancel(e);
        // @ts-ignore
        form.resetFields();
    }
    @observable detailList = this.props.data;

    addItem() {
        const {form} = this.props;
        let detailList = form?.getFieldValue('detailList');
        detailList?.push({fertilizerName: "", amount: "", unit: ""});
        form?.setFieldsValue({detailList: detailList});
    }

    removeItem(index: any) {
        const {form} = this.props;
        // @ts-ignore
        const detailList = form.getFieldValue('detailList');
        if (detailList && detailList.length === 1) {
            return;
        }
        // @ts-ignore
        form.setFieldsValue({
            detailList: detailList.filter((key: any, ind: any) => ind !== index)
        });
    }

    formItem() {
        const {data} = this.props
        // @ts-ignore
        const {getFieldDecorator, getFieldValue} = this.props.form;
        getFieldDecorator('detailList', {initialValue: data.detailList || []});
        let detailList = getFieldValue('detailList');
        return (detailList.map((item: any, index: any) => {
                return (
                    <>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label="基肥类型" {...formItemLayout}>
                                    {getFieldDecorator(`['detailList'][${index}].fertilizerName`, {initialValue: item.fertilizerName})(<Input placeholder={"请输入基肥类型"}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="用量" {...formItemLayout}> {getFieldDecorator(`['detailList'][${index}].amount`, {initialValue: item.amount})(<Input placeholder={"请输入用量"}/>)}</Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="单位" {...formItemLayout}> {getFieldDecorator(`['detailList'][${index}].unit`, {initialValue: item.unit})(<Input placeholder={"请输入单位"}/>)}</Form.Item>
                            </Col>
                            <Col span={4}>
                                {detailList.length > 1 ? (
                                    <Icon
                                        className="dynamic-delete-button"
                                        type="minus-circle-o"
                                        onClick={() => this.removeItem(index)}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </>
                )
            })
        )
    }

    handleUploadBefore = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("请选择图片！");
        }
        // const isLt2M = file.size / 1024 /1024 < 2;
        // if (!isLt2M) {
        //   message.error('图片大于2M');
        // }
        return isJpgOrPng;
    };
    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await this.handleGetBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };
    // 生成预览图片
    handleGetBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    uploadChange = (res: UploadChangeParam<UploadFile<any>>,) => {
        const {data} = this.props;
        data.file = [...res.fileList];
        if (res.file.status === "done") {
            data.photo = res?.file?.response?.url || "";
        }
        this.setState({file: [...res.fileList]});

    };

    render() {
        const {title, visible, confirmLoading, data, edit} = this.props;
        // @ts-ignore
        const {getFieldDecorator} = this.props.form;
        const token = window.sessionStorage.getItem("RouYiToken") as string;
        const header = {
            "Authorization": token
        };
        // tslint:disable jsx-no-multiline-js
        return (
            <Modal
                title={title}
                visible={visible}
                onCancel={this.clickCancel}
                onOk={this.submitForm}
                confirmLoading={confirmLoading}
                width={"80%"}
            >
                <Form>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="省" {...formItemLayout}>
                                {getFieldDecorator("provinceList", {
                                    initialValue: data.provinceList,
                                })(<Select placeholder={"请选择省"} labelInValue={true} onSelect={this.getCity.bind(this)}>{this.renderProvinceOptions()}</Select>)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item style={{display: "none"}} label="省"> {getFieldDecorator("province", {initialValue: data.province,})(<Input/>)}</Form.Item>
                            <Form.Item label="市" {...formItemLayout}>
                                {getFieldDecorator("city", {
                                    initialValue: data.city,
                                })(<Select placeholder={"请选择市"}>{this.renderCityOptions()}</Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="园区" {...formItemLayout}> {getFieldDecorator("district", {initialValue: data.district})(<Input placeholder={"请输入园区"}/>)}</Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="地块" {...formItemLayout}> {getFieldDecorator("plotName", {initialValue: data.plotName})(<Input placeholder={"请输入地块"}/>)}</Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="品种" {...formItemLayout}> {getFieldDecorator("breedName", {initialValue: data.breedName})(<Input placeholder={"请输入品种"}/>)}</Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="施肥方法" {...formItemLayout}> {getFieldDecorator("methodName", {initialValue: data.methodName})(<Input placeholder={"请输入施肥方法"}/>)}</Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="施肥时间" {...formItemLayout}> {getFieldDecorator("useDate", {initialValue: data.useDate ? monment(data.useDate) : monment()})(<DatePicker/>)}</Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="用肥前土壤ph值" {...formItemLayout}> {getFieldDecorator("beforePh", {initialValue: data.beforePh})(<Input placeholder={"用肥前土壤ph值"}/>)}</Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="施肥前有机质情况描述" {...formItemLayout}> {getFieldDecorator("organicContent", {initialValue: data.organicContent})(<Input.TextArea
                                value={data.organicContent}
                                placeholder="请输入施肥前有机质情况描述"
                                autoSize={{minRows: 3, maxRows: 5}}
                            />)}</Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="施肥前土壤养分含量" {...formItemLayout}> {getFieldDecorator("nutrientContent", {initialValue: data.nutrientContent})(<Input.TextArea
                                value={data.organicContent}
                                placeholder="请输入施肥前土壤养分含量"
                                autoSize={{minRows: 3, maxRows: 5}}
                            />)}</Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}> <Col span={2}></Col>
                        <Col span={5}>
                            <Upload
                                action={`${window.config.publisher2}/common/upload`}
                                headers={header}
                                onPreview={this.handlePreview}
                                listType="picture-card"
                                onChange={(list) => this.uploadChange(list)}
                                fileList={data.file}
                                beforeUpload={this.handleUploadBefore}
                            >
                                {data.file && data.file?.length >= 1 ? null : (<Icon type="plus"></Icon>)}
                            </Upload>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={2}></Col>
                        <Col span={5}>
                            <Form.Item>
                                <Button type="primary" onClick={this.addItem.bind(this)}><Icon type="plus"/>添加基肥</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.formItem()}
                </Form>
            </Modal>
        );
    }
}
