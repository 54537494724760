import { ICSSProps, TimelineSimpleProps } from "@gago/frame";
import { Timeline7 } from "@gago/lib/components/timeline-7";
import { omit } from "lodash";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { colorPalette } from "../../color-palette";
import { Phenological, PhenologicalRequest } from "../../models/common";
import CommonProvider from "../../network/providers/common.provider";
import bg from "./bg.png";

const Wrap = styled.div`
  position: absolute;
  width: 90%;
  left: 50%;
  transform: translate3d(0px, 0px, 0px) translateX(-50%) ;
  z-index: 99;
  bottom: 16px;
  display: flex;
  .time-notice{
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('${bg}');
    background-size: 100% 100%;
    .notice-text{
      font-family: PingFangSC;
      font-size: 14px;
      font-weight: 500;
      color: #ffc83b;
    }
    .notice-time{
      font-family: PingFangSC;
      font-size: 11px;
      color: #ffc83b;
    }
  }
`;

interface P extends TimelineSimpleProps, Partial<PhenologicalRequest>, ICSSProps {
  /** wrap className */
  wrapClassName?: string;
  /** wrap style */
  wrapStyle?: React.CSSProperties;
}

interface S {
  /** 物候期时间数据 */
  phenologicalData?: Phenological[];
  /** 当前时间 */
  currentTime?: string;
}

/**
 * 种质繁育时间轴
 * @author maoshengtao
 */

export class BreedingTimeline extends React.PureComponent<P, S> {

  static defaultProps = {
    data: [
      "2021-04-12",
      "2021-05-12",
      "2021-06-12",
      "2021-07-12",
      "2021-08-12",
      "2021-09-12",
      "2021-10-12",
      "2021-11-12",
    ],
  };

  constructor(props: P) {
    super(props);
    const{ data } = this.props;
    this.state = {
      currentTime: data && `${data[0]}-`,
      phenologicalData: undefined,
    };
  }

  componentDidMount() {
    this.getPhenological();
  }

  /** get 物候期 */
  getPhenological = async () => {
    const phenologicalData = await CommonProvider.getPhenological();
    this.setState({ phenologicalData });
  }
  /** handle 物候期时间 */
  handlePhenologicalTime(params: PhenologicalRequest) {
    const { phenologicalData } = this.state;
    if (!phenologicalData) {
      return {} as Phenological;
    }
    const req: PhenologicalRequest = {
      code: params.code || 150800000000,
      crop: params.crop || "向日葵",
      time: moment(params.time).format(`2021-MM-DD`),
    };

    const filterArr = phenologicalData.filter((item) => {
      return item.CODE === req.code && item.CROP === req.crop;
    });
    const res = filterArr.find((item) => {
      if (moment(req.time).unix() >= moment(`2021-${item.START}`).unix() && moment(req.time).unix() <= moment(`2021-${item.END}`).unix()) {
        return true;
      }
      return false;
    });
    return res || {} as Phenological;
  }

  /** handle 时间轴数据 */
  handleTimeline = (timeline: string[]) => {
    const { code, crop } = this.props;
    return timeline.map((item) => {
      return `${moment(item).format("YYYY-MM月DD日")}-${this.handlePhenologicalTime({ time: item, code, crop }).PHE || "" }`;
    });
  }

  /** 时间轴变化 */
  onChange = (time: string) => {
    this.setState({ currentTime: time });
    console.log("****change", time);

    this.props.onChange && this.props.onChange(time);
  }

  render() {
    const { data, code, crop, wrapClassName, wrapStyle, onChange, ...other } = this.props;
    if (!data) {
      return null;
    }
    const { currentTime } = this.state;
    const time = currentTime && moment(currentTime, "YYYY-MM月DD日").format("YYYY-MM-DD");
    const notice = time && this.handlePhenologicalTime({ time, code, crop });
    console.log("****ddd", this.handleTimeline(data), notice);

    return (
      <Wrap
        className={wrapClassName}
        style={wrapStyle}
      >
        {
          notice && notice.PHE && (
            <div className="time-notice">
              <div className="notice-text">{notice.PHE}</div>
              <div className="notice-time">{`${moment(notice.START).format("MM月DD日")}-${moment(notice.END).format("MM月DD日")}`}</div>
            </div>
          )
        }
        <Timeline7
          style={{
            flex: 1,
          }}
          colorPalette={colorPalette}
          loop={"all" as "all"}
          interval={5000}
          data={this.handleTimeline(data)}
          onChange={this.onChange}
          {...omit(other, "onChange")}
        />
      </Wrap>
    );
  }
}
