import React from "react";
import { HeaderBox } from "../../containers/header-box";
import { PageBody } from "./styled";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import CommonProvider from "../../network/providers/common.provider";
import Map from "./map";
import { EllipseRadioSelect, LocationSelect, GradualGauge, McBarChart, McLineChart } from "@gago/lib/components";
import {
  AreaDistribution,
  ProductionSummary,
  Phenophase,
} from "../../models/production-forecast";
import ProductionForecastProvider from "../../network/providers/production-forecast";
// import { Card1 as Card } from "@gago/frame";
import { ITable, SideBar4, Timeline8 } from "@gago/frame";
import { noop, sortBy } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { Icon } from "antd";
import { WeatherAnalyse } from "../../containers/weather-analyse";
import { BreedingTimeline } from "../../containers/breeding-timeline";
import UserProvider from "../../network/providers/user.provider";
import BreedingBigscreenProvider from "../../network/providers/breeding-bigscreen.provider";
import moment from "moment";

export const mapDataList: {label: string; value: string}[] = [
  { label: "向日葵", value: "sunflower" },
  { label: "玉米", value: "corn" },
  { label: "小麦", value: "wheat" },
  // { label: "草地", value: "grassland" },
];

interface P {

}

interface S {
  /** 高亮的地区 */
  region: Region;
  /**气象预警/历史气象 */
  forecastType: "meteorological_warning" | "meteorological_history";
  /** 向日葵|玉米|小麦|草地*/
  crop: string;
  // 产量汇总
  productionSummary: ProductionSummary;
  // 各地区产量汇总
  areaDistribution: AreaDistribution[];
  //
  // year:number;
  // phenophaseList:Phenophase[];
  // 选择的年份
  date: string;
  // 获取的切片时间
  timeData: string[];
  /** 当前是否使用数管域名 */
  isDataManage: "on" | "off" | null;
}

export default class GrowthMonitoring extends React.PureComponent<P, S> {

  state: S = {
    region: {
      code: "150800000",
      name: undefined,
      level: "city",
    },
    forecastType: "meteorological_warning",
    crop: "sunflower",
    areaDistribution: [],
    productionSummary: {
      statisticsOverallProduction: 0,
      statisticsYieldPerUnit: 0,
      recognitionOverallProduction: 0,
      recognitionYieldPerUnit: 0,
    },
    // year:new Date().getFullYear(),
    // phenophaseList:[],
    date: "2021",
    timeData: [],
    isDataManage: null,

  };

  componentDidMount() {
    const { crop } = this.state;
    const params = {
      pageNum: 1,
      pageSize: 10,
      systemCode: "seedBreedDistribution",
    };
    UserProvider.getDomain(params).then(res => {

      const data: "on" | "off" = res && res.rows && res.rows.length > 0 ? res.rows[0].systemStatus : "off";
      const provider = new BreedingBigscreenProvider(data);
      this.setState({ isDataManage: data }, () => {
        this.getTimeLine(crop);
      });
    });
    this.getAreaDistribution();
    // this.getProductionSummary();
  }
  /** 获取各地产量分布 */
  getAreaDistribution = async () => {
    const { crop,  region: { code }, date } = this.state;
    const areaDistribution = await ProductionForecastProvider.getAreaDistribution({ crop, year: date, code: code as string });
    this.setState({ areaDistribution });
  }

  // 获取作物长势汇总
  getProductionSummary = async () => {
    const { crop,  region: { code }, date } = this.state;
    const productionSummary = await ProductionForecastProvider.getProductionSummary({ crop, year: date, code: code as string });
    this.setState({ productionSummary });
  }
  // 获取timeLine
  getTimeLine = async (crop: string) => {
    const { isDataManage } = this.state;
    // let timeData: string[] = [];
    // if (isDataManage === "on") {
    //   timeData = await BreedingBigscreenProvider.getNewPublishTimeline(crop);
    // } else {
    // 不用数管时间轴
    const timeData = await CommonProvider.getPublishTimeline(this.judgeCropType(crop) as string);
    // }
    // console.log(data,'timeline');
    const date = timeData && timeData.length > 0 ? moment(timeData[timeData.length - 1]).format("YYYY") : Number(moment().year()).toString();
    this.setState({ timeData, date });
  }

  // 判断切片请求类型
  judgeCropType = (crop: string) => {
    switch (crop) {
      case "sunflower": return "yield_xrk_2021"; break;
      case "corn": return "yield_maize_2021"; break;
      case "wheat": return "yield"; break;
      case "grassland": return "yield_grass_2021"; break;
      default: return "yield_maize_2021";
    }
  }
  initData = () => {
    this.getProductionSummary();
    this.getAreaDistribution();
  }
  //  切换作物类型
  onChangeCropType = (value: string) => {
    this.getTimeLine(value);
    this.setState({
      crop: value,
    }, () => {
      this.initData();
    });
  }
  /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
    this.setState({ region }, () => this.initData());
  }

  getCropName = (crop: string) => {
    const targetCrop = mapDataList.find(item => item.value === crop);
    if (!targetCrop) {return "向日葵"; }
    return targetCrop.label;
  }

  changeDate = (date: string) => {
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");
    // const year = date && moment(date, "YYYY-MM月DD日").year();
    this.setState({ date: time });
  }

  handleChangeDate = (date: any) => {
    // const date = moment(value).format
    console.log(typeof(date), "dateTyoe");
    this.setState({ date }, () => this.initData());
  }

  render() {
    const { region, timeData, crop, region: { code }, date, isDataManage } = this.state;
    const realTimeLine = timeData.map(item => moment(item).format("YYYY"));
    // realTimeLine = [...realTimeLine,'2021']
    return (
      <HeaderBox
        names={["种质产量预估"]}
        noPadding
      >
        <PageBody>
          <Map changeRegion={this.changeRegion} region={region} cropName={this.judgeCropType(crop) as string}  date={date} timeData={timeData} isDataManage={isDataManage}/>
          {this.renderSidebar()}
          <EllipseRadioSelect data={mapDataList} className="mapSelect" colorPalette={colorPalette} backSelectChange={this.onChangeCropType}/>

          {timeData.length > 0 && <Timeline8 className="growthTimeLine"  hideGroup selected={date}  data={realTimeLine} colorPalette={colorPalette} loop={"all" as "all"} interval={5000} onChange={this.handleChangeDate}/>}
          {/* {timeData.length > 0 && <Timeline8 className="growthTimeLine"  hideGroup  data={realTimeLine} colorPalette={colorPalette} loop={"all" as "all"} interval={5000} /> } */}
        </PageBody>
      </HeaderBox>
    );
  }

  renderProductionCard = () => {
    const { productionSummary } = this.state;
    const recognizeList = [
      {
        title: "识别单产",
        num: Number(productionSummary.recognitionYieldPerUnit.toFixed(2)) ,
        unit: "千克/亩",
      },
      {
        title: "识别总产",
        num: Number(productionSummary.recognitionOverallProduction.toFixed(2)) ,
        unit: "万吨",
      }];
    const statisticsList = [{
      title: "统计单产",
      num: Number(productionSummary.statisticsYieldPerUnit.toFixed(2)),
      unit: "千克/亩",
    },                      {
      title: "统计总产",
      num: Number(productionSummary.statisticsOverallProduction.toFixed(2)) ,
      unit: "万吨",
    }];
    return (
      <>
        <McCard
          title="产量分析"
        >
          <WeatherAnalyse data={recognizeList}/>
          <WeatherAnalyse data={statisticsList}/>

        </McCard>
      </>
    );
  }

  renderAreaDistribution = () => {
    const { areaDistribution } = this.state;
    if (!areaDistribution || areaDistribution.length === 0) {
      return null;
    }
    const sortData = sortBy(areaDistribution, item => -item.value);

    const chartData: ITable<2, 1> = {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "万吨"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: sortData.map<ITable<2, 1>["data"][0]>(item => ["", `全年产量`, item.name, item.value]),
    };
    return (
      <>
        <McCard
          title="各地区产量分析"
        >
          <div className=""style={{ height: "350px" }}  >
            <AxisChart
              basicConfigOption={{
                showCategoryAxisLine: false,
                showValueAxis: false,
                barWidth: 8,
                barBorderRadius: 7,
                showBarTopRect: false,
                showSeriesValueLabel: true,
                assignBarColor: true,
              }}
              theme={"horizontal"}
              chartData={chartData}
              colorPalette={{
                ...colorPalette,
                subColor: ["#1df0ff", "#00c0ee"],
                assignColor: [
                ["#1df0ff", "#00c0ee"],
                ] ,
              }}
            />
          </div>

        </McCard>
      </>
    );
  }

  renderDisasterLevel = () => {
    return (
      <>
        <div className="levelWrapper">
          <p className="disasterName">旱情</p>

          <div className="level-container">
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#438df8" }}></span>
              <span className="levelName">正常</span>
            </div>
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#ffc802" }}></span>
              <span className="levelName">轻度</span>
            </div>
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#f07a26" }}></span>
              <span className="levelName">中度</span>
            </div>
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#e9364e" }}></span>
              <span className="levelName">严重</span>
            </div>
          </div>

        </div>
      </>
    );
  }
  getName = (crop: string) => {
    switch (crop) {
      case "sunflower": return "向日葵"; break;
      case "corn": return "玉米"; break;
      case "wheat": return "小麦"; break;
      case "grassland": return "草地";
    }
  }
  /** render sider bar*/
  renderSidebar = () => {
    const { forecastType } = this.state;
    return (
      <SideBar4
        className="breeding-distribute-side"

        colorPalette={colorPalette}
        sizeOnChange={noop}
        theme="light"
        style={{ width: 400, zIndex: 999 }}
        title="产量预估"
        // title= {<EllipseRadioSelect colorPalette = {colorPalette} data={mapMeteorologicalList} className="meteorologicalSelect" backSelectChange={this.onChangeForecastType}/>}
      >

        {this.renderProductionCard()}
        {this.renderAreaDistribution()}
      </SideBar4>
    );
  }

}
