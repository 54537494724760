import { BaseInfo } from "../../models/germplasm-database";

// tslint:disable
export const BASELIST: BaseInfo[] = [
  {
    name: "基本信息",
    list: [
      {
        name: "企业名称",
        eName: "companyName",
        type: "select",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "销售区域",
        eName: "district",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "当年交易量",
        eName: "volume",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "当年交易量涨跌",
        eName: "volumeGrowthRate",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "当年交易额",
        eName: "gmv",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "当年交易额涨跌",
        eName: "gmvGrowthRate",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "总客户数量",
        eName: "client",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "新增农户比",
        eName: "clientGrowthRate",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
    ],
  },
  {
    name: "销售渠道(万元)",
    list: [
      {
        name: "线下展会",
        eName: "offlineExhibit",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "直营",
        eName: "directSelling",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "电商",
        eName: "eCommerce",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "其他",
        eName: "other",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
    ],
  },
  {
    name: "企业推广种质面积(万亩)",
    list: [
      {
        name: "2016",
        eName: "2016",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "2017",
        eName: "2017",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "2018",
        eName: "2018",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "2019",
        eName: "2019",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "2020",
        eName: "2020",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
    ],
  },
  {
    name: "天赋河套APP",
    list: [
      {
        name: "使用人数",
        eName: "userCount",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "记录地块数",
        eName: "plotCount",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "农田记事数",
        eName: "farmworkCount",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
    ],
  },
  {
    name: "LOT智能监测",
    list: [
      {
        name: "监控设备",
        eName: "monitoring",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "气象站",
        eName: "weather",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "传感器",
        eName: "sensor",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
      {
        name: "控制设备",
        eName: "device",
        type: "input",
        unit: null,
        selectData: [],
        required: true,
      },
    ],
  },
];
