import { globalColorPalette1, getTransparentColors } from "@gago/frame/es/colors/default";
import { GlobalColorPalette, ColorLevels } from "@gago/frame";

/** 自定义颜色
 * @author James Zhang
 */
export const colorPalette: GlobalColorPalette = {
  ...globalColorPalette1,
  mainColor: [
    "#e0fffc",
    "#c2fffa",
    "#00fdff",
    "#00cae0",
    "#009fb8",
    "#00ddff",
    "#0a85cc",
    "#0f6299",
    "#0f3e66",
    "#0e2947",
  ],
};

export const pieChartColor = { ...colorPalette, subColor: ["#00fdff", "#1d9bff", "#ffd164", "#ff7b2f", "#ff51a2", "#009fb8", "#7433ff"] };
/** 图表配置颜色 */
export const chartStyle = {
  /** 主要文字颜色，包含类目轴, 主轴的label  */
  mainTextColor: "#00cae0",
  /** 主要文字大小，包含类目轴 */
  mainTextSize: 11,
  /** 类目轴颜色, 主轴的轴线 */
  mainAxisColor: "#00cae0",
  /** 次要文字颜色，包含值轴，图例； 副轴的label */
  secondaryTextColor: "#009fb8",
  /** 次要文字大小，包含值轴，图例 */
  secondaryTextSize: 12,
  /** 值轴颜色, 副轴的轴线 */
  secondaryAxisColor: "rgba(176, 225, 255, 0)",
  /** 分割线颜色 */
  splitLineColor: "rgba(0, 221, 255, 0.2)",
  /** 提示主要文字颜色 */
  tooltipMainTextColor: "#00cae0",
  /** 提示框次要文字颜色 */
  tooltipSecondaryTextColor: "#009fb8",
};

/** 图表配置颜色 */
export const chartStyle2 = {
  /** 主要文字颜色，包含类目轴, 主轴的label  */
  mainTextColor: "#009688",
  /** 主要文字大小，包含类目轴 */
  mainTextSize: 12,
  /** 类目轴颜色, 主轴的轴线 */
  mainAxisColor: "#009688",
  /** 次要文字颜色，包含值轴，图例； 副轴的label */
  secondaryTextColor: "#009688",
  /** 次要文字大小，包含值轴，图例 */
  secondaryTextSize: 12,
  /** 值轴颜色, 副轴的轴线 */
  secondaryAxisColor: "rgba(176, 225, 255, 0)",
  /** 分割线颜色 */
  splitLineColor: "rgba(0, 150, 136, 0.2)",
  /** 提示主要文字颜色 */
  tooltipMainTextColor: "#009688",
  /** 提示框次要文字颜色 */
  tooltipSecondaryTextColor: "#009688",
};

/** 图表配置颜色 */
export const chartStyle3 = {
  /** 主要文字颜色，包含类目轴, 主轴的label  */
  mainTextColor: "#333",
  /** 主要文字大小，包含类目轴 */
  mainTextSize: 12,
  /** 类目轴颜色, 主轴的轴线 */
  mainAxisColor: "#333",
  /** 次要文字颜色，包含值轴，图例； 副轴的label */
  secondaryTextColor: "#333",
  /** 次要文字大小，包含值轴，图例 */
  secondaryTextSize: 12,
  /** 值轴颜色, 副轴的轴线 */
  secondaryAxisColor: "rgba(176, 225, 255, 0)",
  /** 分割线颜色 */
  splitLineColor: "rgba(0, 150, 136, 0.2)",
  /** 提示主要文字颜色 */
  tooltipMainTextColor: "#fff",
  /** 提示框次要文字颜色 */
  tooltipSecondaryTextColor: "#fff",
};

export const projectColorPalette: GlobalColorPalette = {
  ...globalColorPalette1,
  mainColor: ["#e9f7ff", "#c4ebff", "#82e0ff", "#60b9df", "#3f95bf", "#0da6ff", "#17517e", "#153b5b", "#122b45", "#0f1f30"],
  mainBlackTransparentColor: getTransparentColors("#02182e"),
  secondaryColor: [...getTransparentColors("#e87040"), "#ffaf54"] as ColorLevels,
  subColor: ["#0d4cba", "#1f9af9", "#6ae6ff", "#ffe7a0", "#ffab56", "#e87040", "#b53c1b", "#8643e3", "#e64279"],
};
