import React from "react";
import Form, { FormComponentProps } from "antd/lib/form";
import styled, { createGlobalStyle } from "styled-components";
import {
  Button,
  Input,
  Modal,
  Spin,
  Select,
  TreeSelect,
  Upload,
  Icon,
  Empty,
  message,
} from "antd";
import { ICSSProps } from "@gago/frame";
import { ModalProps } from "antd/lib/modal";
import { BaseLandDetail, ParkBaseType, BaseLandUpdateRequest } from "../../models/dictionary-base";
import DictionaryProvider from "../../network/providers/dictionary-base.provider";
import { TreeNode } from "antd/lib/tree-select";
import { get, uniqueId } from "lodash";
import { BamengUpload } from "./bameng-upload";

const { Option } = Select;
const { SHOW_CHILD } = TreeSelect;
const Wrap = styled.div`
  /* width: 600px; */
  max-height: 662px;
  overflow-y: auto;
  input:-webkit-autofill {
    /* 选择历史记录的文字颜色*/
    -webkit-text-fill-color: #333 !important;
  }
`;

const GloabalStyle = createGlobalStyle`
.dictionary-base-overlay{
  .mengcao-row{
    display: flex!important;
    margin-bottom: 12px;
  }
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
  .ant-modal-title{
    font-size: 20px;
  }
  .ant-form-item label{
    color: rgb(0,22,23, 0.95);
    font-size: 20px;
  }
  .ant-input[disabled] {
    border: none;
    color: rgb(0,22,23, 0.7);
    background: transparent;
    font-size: 20px;
    height: auto;
  }
  .ant-select-disabled .ant-select-selection{
    border: none;
    background: transparent;
    color: rgb(0,22,23, 0.7);
    font-size: 20px;
    .ant-select-arrow{
      display: none;
    }
  }
}
`;

type P = FormComponentProps &
  ICSSProps & {
    /** model option */
    modelOption?: ModalProps;
    /** 取消 关闭 */
    handleCancel?: (str?: string | number) => void;
    /** 数据id */
    dataId: string;
    /** 是否禁用 */
    disabled?: boolean;
  };

interface S {
  /** 是否为loading */
  loading?: boolean;
  /** 单个地块详情 */
  baselandDetail?: BaseLandDetail;
  /** 基地类型 */
  baseTypeList?: ParkBaseType[];
}

/**
 * 数据字典 编辑弹窗
 * @author maoshengtao
 */
class Overlay extends React.PureComponent<P, S> {
  state: S = {
    loading: false,
  };

  async componentDidMount() {
    await this.getBaseLandDetail();
    this.getBaseType();
  }

  /** get 基地详情获取 */
  getBaseLandDetail = async () => {
    const { dataId } = this.props;
    this.setState({ loading: true });
    const baselandDetail = await DictionaryProvider.getBaseLandDetail(dataId);
    this.setState({ baselandDetail, loading: false });
  };

  /** get 基地类型 */
  getBaseType = async () => {
    const baseTypeList = await DictionaryProvider.getParkBaseType();
    this.setState({ baseTypeList });
  };

   /** 基地 详情更新 */
  getBaseLandUpdate = async (params: BaseLandUpdateRequest) => {
     DictionaryProvider.getBaseLandUpdate(params).then(() => {
       message.success("更新成功")
     })
  };

  /** 处理单个基地类型 */
  handleParkTypeItem = (parkType: ParkBaseType): TreeNode => {
    if (parkType.children && parkType.children.length > 0) {
      return {
        key: parkType.value,
        value: JSON.stringify(parkType),
        title: parkType.key,
        selectable: false,
        children: parkType.children.map(this.handleParkTypeItem),
      };
    }
    return {
      key: parkType.value,
      value: JSON.stringify(parkType),
      title: parkType.key,
      children: undefined,
    };
  };

  /** 处理基地类型 */
  handleParkType = (parkTypes: ParkBaseType[]) => {
    return parkTypes.map((elm) => {
      return this.handleParkTypeItem(elm);
    });
  };

  /** 处理初始值 */
  handleFindInitValue(data: TreeNode[], current: string): any {
    let result;
    if (!data) {
      // return; 中断执行
      return undefined;
    }
    for (let i in data) {
      let item = data[i];
      if (item.title === current) {
        result = item;
        break;
      } else if (
        item.children &&
        Array.isArray(item.children) &&
        item.children.length > 0
      ) {
        result = this.handleFindInitValue(item.children as TreeNode[], current);
      }
    }
    return result;
  }

  /** render 基地类型 */
  renderSelect = () => {
    const { getFieldDecorator } = this.props.form;
    const { disabled } = this.props;
    const { baseTypeList, baselandDetail } = this.state;
    if (!baseTypeList) {
      return <Select placeholder="请选择" disabled></Select>;
    }
    const list = this.handleParkType(baseTypeList);
    const init = baselandDetail && this.handleFindInitValue(list, baselandDetail.type)
    return getFieldDecorator(`type`, {
      initialValue: init && init.value,
      rules: [
        {
          required: true,
          message: "基地类型为必填",
        },
      ],
    })(
      <TreeSelect
        disabled={disabled}
        style={{ width: "100%" }}
        showSearch={false}
        treeData={list}
        treeCheckable={false}
        showCheckedStrategy={SHOW_CHILD}
        searchPlaceholder={"请选择"}
      />
    );
  };

  normFile = (e: any) => {
    console.log("Upload img:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  normFile2 = (e: any) => {
    console.log("Upload video:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  /** render 基地图片 */
  renderImg = () => {
    const { getFieldDecorator } = this.props.form;
    const { baselandDetail } = this.state;
    const { disabled } = this.props;
    return getFieldDecorator(`image`, {
      valuePropName: "fileList",
      getValueFromEvent: this.normFile,
      initialValue:
        baselandDetail && baselandDetail.image
          ? [
              {
                uid: "img",
                name: "当前图片",
                type: "image/png",
                url: baselandDetail && [baselandDetail.image],
                status: "done",
                size: 10,
              },
            ]
          : undefined,
      rules: [
        {
          required: true,
          message: "该项为必填",
        },
      ],
    })(<BamengUpload key={"img"} disabled={disabled} />);
  };

  /** render 基地视频 */
  renderVideo = () => {
    const { getFieldDecorator } = this.props.form;
    const { baselandDetail } = this.state;
    const { disabled } = this.props;
    return getFieldDecorator(`video`, {
      valuePropName: "fileList",
      getValueFromEvent: this.normFile,
      initialValue:
        baselandDetail && baselandDetail.video
          ? [
              {
                uid: "video",
                name: "当前视频",
                type: "video/mp4",
                url: [baselandDetail.video],
                status: "done",
                size: 10,
              },
            ]
          : undefined,
      rules: [
        {
          required: true,
          message: "该项为必填",
        },
      ],
    })(<BamengUpload key={"video"} disabled={disabled} isVideo/>);
  };

  render() {
    const { loading, baselandDetail } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { disabled } = this.props;
    return (
      <Modal
        footer={this.renderModalFooter()}
        wrapClassName={"dictionary-base-overlay"}
        maskClosable={false}
        onCancel={this.handleCancel}
        // confirmLoading={loading}
        {...this.props.modelOption}
      >
        <Wrap>
          {loading || !baselandDetail ? (
            <div
              style={{
                width: "100%",
                height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              <Form.Item label={`地块名称`} className="mengcao-row">
                {getFieldDecorator(`baseName`, {
                  initialValue: baselandDetail.baseName || "—",
                  rules: [
                    {
                      required: true,
                      message: "地块名称为必填",
                    },
                  ],
                })(<Input placeholder="请输入地块名称" disabled />)}
              </Form.Item>
              <Form.Item label={`地区`} className="mengcao-row">
                {getFieldDecorator(`region`, {
                  initialValue: `${baselandDetail.provinceName || "-"}${
                    baselandDetail.cityName ? `/${baselandDetail.cityName}` : ""
                  }`,
                  rules: [
                    {
                      required: true,
                      message: "地块名称为必填",
                    },
                  ],
                })(<Input placeholder="请输入地区名称" disabled />)}
              </Form.Item>
              <Form.Item label={`企业名称`} className="mengcao-row">
                {getFieldDecorator(`company`, {
                  initialValue: baselandDetail.company || "—",
                  rules: [
                    {
                      required: true,
                      message: "企业名称为必填",
                    },
                  ],
                })(<Input placeholder="请输入企业名称" disabled />)}
              </Form.Item>
              <Form.Item label={`地块长势曲线`} className="mengcao-row">
                {getFieldDecorator(`productionState`, {
                  initialValue:
                    baselandDetail.productionState === 0
                      ? "生产中"
                      : !baselandDetail.productionState
                      ? "-"
                      : "已生产",
                  rules: [
                    {
                      required: true,
                      message: "该项为必填",
                    },
                  ],
                })(<Input placeholder="请输入企业名称" disabled />)}
              </Form.Item>
              <Form.Item label={`基地类型`} className="mengcao-row">
                {this.renderSelect()}
              </Form.Item>
              <Form.Item label={`公司简介`} className="mengcao-row">
                {getFieldDecorator(`info`, {
                  initialValue: baselandDetail.info,
                  rules: [
                    {
                      required: true,
                      message: "该项为必填",
                    },
                    {
                      max: 100,
                      message: "只能输入100字以内",
                    },
                  ],
                })(<Input.TextArea placeholder="请输入100字以内" disabled={disabled}/>)}
              </Form.Item>
              <Form.Item label={`基地照片`} className="mengcao-row">
                {this.renderImg()}
              </Form.Item>
              <Form.Item label={`基地视频`} className="mengcao-row">
                {this.renderVideo()}
              </Form.Item>
              <Form.Item label={`地块状态`} className="mengcao-row">
                {getFieldDecorator(`state`, {
                  initialValue: baselandDetail.state,
                  rules: [
                    {
                      required: true,
                      message: "该项为必填",
                    },
                  ],
                })(
                  <Select placeholder="请选择" style={{ width: 200 }} disabled={disabled}>
                    <Option value={0}>{"下架"}</Option>
                    <Option value={1}>{"上架"}</Option>
                  </Select>
                )}
              </Form.Item>
            </>
          )}
        </Wrap>
        <GloabalStyle />
      </Modal>
    );
  }

  /** handle onCancel */
  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel && handleCancel();
  };

  /** 点击ok */
  handleOk = () => {
    const { form, handleCancel } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return;
      }
      const option = JSON.parse(values.type);
      const { baselandDetail } = this.state;
      this.getBaseLandUpdate({
        id: baselandDetail ? baselandDetail.id : 1,
        type: option.key,
        updateType: option.value,
        info: values.info,
        state: values.state,
        image: get(values, "image[0].url[0]", undefined) || get(values, "image[0].response.data.data[0].file", undefined),
        video: get(values, "video[0].url[0]", undefined) || get(values, "video[0].response.data.data[0].file", undefined),
      }).then(() => {
        handleCancel && handleCancel();
      })
    });
  };

  /** render modal footer */
  renderModalFooter = () => {
    const { disabled } = this.props;
    if (disabled) {
      return <Button onClick={this.handleCancel}>{"关闭"}</Button>
    }
    return (
      <>
        <Button onClick={this.handleCancel}>{"取消"}</Button>
        <Button type={"primary"} onClick={this.handleOk}>
          {"保存"}
        </Button>
      </>
    );
  };
}

export const DictionaryBaseOverlay = Form.create<P>({
  name: "dictionaryBaseOverlay",
})(Overlay);
