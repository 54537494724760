import { User } from "../../models/user";
import { BaseProvider } from "./base.provider";
import { Md5 } from "@gago/solar-terms";
import { BaseRequestServer, BaseRequestServer1, BaseRequestStaticServer, BaseRequestStaticServer1 } from "../servers/base-request.server";
import { ResultType, ResultType1 } from "../../models/common";

interface UserListType {
  /** 用户名 */
  username: string;
  /** 密码 */
  password: string;
}
// tslint:disable:no-collapsible-if
/** 检查用户名密码的正确性 */
function judgeUserName(username: string, password: string, userList: UserListType[]) {
  for (const item of userList) {
    if (username === item.username && password === item.password) {
      return true;
    }
  }
  return false;
}
const usersList: UserListType[] = window.config.users;
/**
 * 用户授权接口
 *
 * @author 张卓诚
 * @date 2018-12-29
 * @export
 * @class UserProvider
 * @extends {BaseProvider<User>}
 */
export class UserProvider extends BaseProvider<User> {
  /** 服务器1 */
  server = new BaseRequestServer();
  /** 服务器2 */
  server1 = new BaseRequestServer1();
  /** 用户名列表数据 */
  /**
   * 登陆
   *
   * @author 张卓诚
   * @date 2018-12-29
   * @param {string} 用户名
   * @param {string} 密码明文
   * @returns
   * @memberof UserProvider
   */
  async login(username: string, password: string) {
    const data = { username, password };
    const result = await this.server.post<any, any>("/author/login", data);
    sessionStorage.setItem("name", username);
    sessionStorage.setItem("password", password);
    sessionStorage.setItem("token", result.token);
    return result;
  }

  /** 获取当前是否应用数管接口域名 */
  async getDomain(params: any) {
    const data = await BaseRequestStaticServer1.get<ResultType1<any[]>>(`/system/tbl_system_config/list`, { params });
    return data;
  }

  /**
   * 模拟登陆
   *
   * @author 张卓诚
   * @date 2018-12-29
   * @param {string} username
   * @param {string} password
   * @returns
   * @memberof UserProvider
   */
  async mockLogin(username: string, password: string) {
    return { name: username };
  }

  /**
   * 模拟检测登陆状态
   *
   * @author 张卓诚
   * @date 2018-12-29
   * @param {string} username
   * @param {string} password
   * @returns
   * @memberof UserProvider
   */
  async checkLogin() {
    const username = window.sessionStorage.getItem("name");
    const passw = window.sessionStorage.getItem("password");
    const token = window.sessionStorage.getItem("token");
    if (username && passw && token) {
      return { name: username };
    }
    throw new Error(("未检测出登录"));
  }

  /**
   * 登出
   *
   * @author 张卓诚
   * @date 2018-12-29
   * @returns
   * @memberof UserProvider
   */
  async logout() {
    window.sessionStorage.removeItem("name");
    window.sessionStorage.removeItem("password");
    window.sessionStorage.removeItem("token");
    return {};
  }
}

export default new UserProvider();
