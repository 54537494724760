// tslint:disable: variable-name naming-convention jsx-no-multiline-js

import React from "react";
import { ICSSProps } from "@gago/frame";
import styled from "styled-components";
import { Card } from "antd";
import { CardProps } from "antd/lib/card";

const WrapCard = styled(Card)`
.ant-card-body{
  padding: 10px 16px;
}
.ant-card-meta-avatar {
    padding-top: 8px;
    padding-right: 8px;
}
.ant-card-meta-detail > div:not(:last-child){
  margin-bottom: 0px;
}
.title{
  font-family: BebasNeue;
  font-size: 28px;
  color: rgba(0, 22, 23, 0.9);
  .suffix{
    font-family: PingFangSC;
    font-size: 14px;
    color: rgba(0, 22, 23, 0.9);
  }
  .description{
    font-family: PingFangSC;
    font-size: 14px;
    color: rgba(0, 22, 23, 0.45);
  }
}
`;


interface P {
  /** titile */
  title: React.ReactNode;
  /** 后缀 */
  suffix?: string;
  /** description  */
  description: React.ReactNode;
  /** img */
  img: string;
  /** 是否是激活态 */
  active?: boolean;
}

const { Meta } = Card;
/**
 * 通用卡片 1
 * @author maoshengtao
 */
export const Card1 = (props: P & ICSSProps & CardProps) => {
  const { title, description, img, suffix, active, ...other } = props;
  return (
    <WrapCard
      hoverable
      style={{
        width: 258,
        height: 80,
        borderRadius: 4,
        boxShadow: "0 2px 6px 0 #ccd8d9",
        border: "none",
        background: active ? "rgba(30,186,181, 0.5)" : "#fff",
      }}
      {...other}
    >
      <Meta
        avatar={
        <div
          style={{
            width: 48,
            height: 48,
            backgroundSize: "100% 100%",
            backgroundImage: `url(${img})` }}
        />
        }
        title={
          <span className="title">
           {title}
           {suffix && <span className="suffix">{suffix}</span>}
          </span>
        }
        description={
          <span className="description">
            {description}
          </span>
        }
      />
    </WrapCard>
  );
}