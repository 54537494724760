import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./layouts1/app";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store";
import UserContextDefaultProvider from "./contexts/user";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import zh_CN from "antd/es/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import TeaGardenContextDefaultProvider from "./src-anxi/contexts/tea-garden";
moment.locale("zh-cn");

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <UserContextDefaultProvider>
              <ConfigProvider locale={zh_CN}>
                <TeaGardenContextDefaultProvider>
                  <App />
                </TeaGardenContextDefaultProvider>
              </ConfigProvider>
            </UserContextDefaultProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
