
// tslint:disable:variable-name
/**
 * 数字茶园-适宜性评价:样式
 * @author luyaxiang
 * @date 2019.6.17
 * @export
 * @class SuitabilityEvaluation
 * @extends {React.PureComponent<SuitabilityEvaluationProps, SuitabilityEvaluationState>}
 */

import styled from "styled-components";
import fullScreen from "./img/full-screen.svg";

/** card之间的间距 */
export const marginSize = 16;

const paddingSize = 24;

export const Border = styled.div`
  width: 100%;
  height: 100%;
  background: #f2f5f5;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${paddingSize}px;

  .suitability-evaluation-top, .suitability-evaluation-bottom {
    width: 100%;
    display: flex;
    position: relative;
  }

  .suitability-evaluation-top {
    flex: 0 0 500px;

    .suitability-evaluation-top-right {
      flex: 5;
      display: flex;
      position: relative;
      flex-direction: column;
    }
  }

  .suitability-evaluation-bottom {
    flex: 1;
  }
`;

export const CardContentBorder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ToMap = styled.div`
  width: 24px;
  height: 24px;
  margin-top: 6px;
  position: absolute;
  top: 4px;
  right: 4px;
  background-image: url(${fullScreen});
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 9;
`;
