// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Modal, Button, Steps, Select, message, Spin, Cascader } from "antd";
import { ICSSProps } from "@gago/frame";
import { ModalProps } from "antd/lib/modal";
import styled, { createGlobalStyle } from "styled-components";
import { TopCategory, SearchProps } from "./top-category";
import { GenerateCategory } from "./generate-category";
import { FormComponentProps } from "antd/lib/form";
import {  BaseInfo } from "../../models/land-database";
import { TestReport } from "../../models/germplasm-test-report";
import { BreedingReport } from "../../models/molecular-breeding-report";
import LandDataBaseProvider from "../../network/providers/land-database.provider";
import GermplasmTestReportProvider from "../../network/providers/germplasm-test-report.provider";
import MolecularBreedingProvider from "../../network/providers/molecular-breeding-report.provider";
import { TESTREPORTLIST, BREEDINGREPORTLIST } from "./const";


import { cloneDeep, get } from "lodash";

const { Option } = Select;
const { Step } = Steps;
const Wrap = styled.div`
  width: 1408px;
  height: 662px;
  overflow-y: auto;
  input:-webkit-autofill {
    /* 选择历史记录的文字颜色*/
    -webkit-text-fill-color: #333!important;
  }
`;

const GloabalStyle = createGlobalStyle`
  .ant-modal{
    width: 1440px!important;
  }
`;

type P = ICSSProps & ModalProps & {
  /** 作物分类 */
  // cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 取消 关闭 */
  handleCancel?: (str?: string | number) => void;
};

interface S {
  /** 当前第几步骤 */
  step: number;
  /** 表单映射 */
  formMap: {
    [key: string]: FormComponentProps["form"];
  };
  /** 获取列表1 */
  list1?: BaseInfo[];
  /** 获取列表2 */
  // list2: BaseInfo[];
  /** 获取列表3 */
  // list3: BaseInfo[];
  /** 是否为loading */
  loading?: boolean;
  /** 种质编号 */
  accessionNumber?: number | string;
  /** 顶部搜索结果 */
  topSearch?: SearchProps;
  /** 行政区划数 */
  // regionTree?: RegionTree[];
}

/**
 * 新增 编辑 弹窗
 * @author maoshengtao
 */
export class AddSample extends React.Component<P, S> {
  state: S = {
    step: 0,
    formMap: {},
    // list2: TESTREPORTLIST,
    // list3: BREEDINGREPORTLIST,
  };

  componentDidMount() {
    this.baseInfoSearch();
  }
   /** get 基础信息 */
  baseInfoSearch = async () => {
    // if (!topSearch.tableName) {
    //   message.info("暂无该类信息");
    //   return;
    // }
    // this.setState({ topSearch, loading: true });
    // const list1 = await GermplasmDatabaseProvider.getBaseInfo(topSearch.tableName);
    const list1 = await LandDataBaseProvider.getSampleInfoKey();
    this.setState({ list1, loading: false });
  }

   /** get 获取测试报告数据 */
  // getTestReport = async () => {
  //   this.setState({ loading: true });
  //   const list1 = await GermplasmDatabaseProvider.getBaseInfo(“);
  //   this.setState({ list1, loading: false });
  // }

  /** 处理返回结果 */
  handleResult = (list: BaseInfo[], tempObj: any) => {
    const rawData = cloneDeep(list);
    rawData.map((elm) => {
      Object.keys(tempObj).map((item) => {
        if (elm.name === item) {
          Object.keys(tempObj[item]).map((i) => {
            elm.list.map((r) => {
              if (r.name === i) {
                r.value = tempObj[item][i];
              }
              if (r.type === "upload" && get(tempObj[item][i], "fileList[0]", false)) {
                r.value = tempObj[item][i].fileList.map((j: any, ind: number) => get(tempObj[item][i], `fileList[${ind}].response.data.data[0].file`, ""))
              }
            });
          });
        }
      });
    });
    return rawData;
  }

  /** 步骤1保存 */
  handleStep1Save = () => {
    const { step, formMap, list1, topSearch } = this.state;
    if (!list1 || list1.length === 0) return false;
    let result = true;
    /** 表单返回数据 */
    const tempObj: any = [];
    list1.map((item) => {
      formMap[item.name] && formMap[item.name].validateFieldsAndScroll((err, v) => {
        if (err) {
          result = false
        }
        tempObj[item.name] = v;
      });
    });
    
    if (!result ) return false;
    /*** 新增 */
    LandDataBaseProvider.addSample({
      
      information:JSON.stringify(this.handleResult(list1, tempObj)),
      
    }).then((res) => {
      if (res) {
        // this.setState({ step: 1, accessionNumber: res });
        message.success("保存成功");
      }
    }).catch((err) => {
      console.error(err);
    })
    return true;
  }

  /** 步骤2保存 */
  // handleStep2Save = () => {
  //   const { step, formMap, accessionNumber, list2 } = this.state;
  //   let result = true;

  //   /** 表单返回数据 */
  //   const tempObj: any = [];
  //   list2.map((item) => {
  //     formMap[item.name] && formMap[item.name].validateFieldsAndScroll((err, v) => {
  //       if (err) {
  //         result = false
  //       }
  //       tempObj[item.name] = v;
  //     });
  //   });
  //   if (!result || !accessionNumber) return false;

  //   /** 保存 */
  //   const fetchArr = Object.values(tempObj).map((elm: any) => {
  //     return (
  //       GermplasmTestReportProvider.addTestReport({
  //         accessionNumber: accessionNumber.toString(),
  //         breedingDepartment: elm.送检单位,
  //         detectionDepartment: elm.检测单位,
  //         provinceName: elm.所在地区[0],
  //         cityName: elm.所在地区[1],
  //         testTime: elm.检测时间.format("YYYY-MM-DD"),
  //         reportUrl: get(elm.报告上传.file, "response.data.data[0].file", undefined),
  //       })
  //     );
  //   });
  //   Promise.all(fetchArr).then((res) => {
  //     if (res) {
  //       this.setState({ step: 2 });
  //       message.success("保存成功");
  //     }
  //   }).catch((err) => {
  //     if (err.response) {
  //       message.error(get(err, "response.data.error.message", "保存失败"));
  //     }
  //   });
  //   return true;
  // }

  /** 步骤3保存 */
  // handleStep3Save = () => {
  //   const { step, formMap, accessionNumber, list3 } = this.state;
  //   let result = true;

  //   /** 表单返回数据 */
  //   const tempObj: any = [];
  //   list3.map((item) => {
  //     formMap[item.name] && formMap[item.name].validateFieldsAndScroll((err, v) => {
  //       if (err) {
  //         result = false
  //       }
  //       tempObj[item.name] = v;
  //     });
  //   });
  //   if (!result || !accessionNumber) return false;

  //   /** 保存 */
  //   const fetchArr = Object.values(tempObj).map((elm: any) => {
  //     return (
  //       MolecularBreedingProvider.breedingReportAdd({
  //         accessionNumber: accessionNumber.toString(),
  //         breedingDepartment: elm.育种单位,
  //         breedingType: elm.育种类型,
  //         provinceName: elm.所在地区[0],
  //         cityName: elm.所在地区[1],
  //         breedingTime: elm.育种时间.format("YYYY-MM-DD"),
  //         reportUrl: get(elm.报告上传.file, "response.data.data[0].file", undefined),
  //       })
  //     );
  //   });
  //   Promise.all(fetchArr).then((res) => {
  //     if (res) {
  //       this.setState({ step: 2 });
  //       this.handleCancel();
  //       message.success("保存成功");
  //     }
  //   }).catch((err) => {
  //     if (err.response) {
  //       message.error(get(err, "response.data.error.message", "保存失败"));
  //     }
  //   });
  //   return true;
  // }

  /** handle 测试报告明细列表增加 */
  // handleTestReportAdd = () => {
  //   const { list2 } = this.state;
  //   const tempTest = cloneDeep(TESTREPORTLIST[0]);
  //   tempTest.name = `测试报告${list2.length}`;
  //   // this.setState({ list2: [...list2, tempTest] });
  // }

  /** handle 测试报告明细列表删除 */
  // handleTestReportDel = (name: string) => {
  //   const { list2 } = this.state;
  //   const filterList = list2.filter((item) => {
  //     return item.name !== name;
  //   });
  //   this.setState({ list2: filterList });
  // }

  /** handle 育种报告明细列表增加 */
  // handleBreedingReportAdd = () => {
  //   const { list3 } = this.state;
  //   const tempTest = cloneDeep(BREEDINGREPORTLIST[0]);
  //   tempTest.name = `育种报告${list3.length}`;
  //   // this.setState({ list3: [...list3, tempTest] });
  // }

  /** handle 育种报告明细列表删除 */
  // handleBreedingReportDel = (name: string) => {
  //   const { list3 } = this.state;
  //   const filterList = list3.filter((item) => {
  //     return item.name !== name;
  //   });
  //   this.setState({ list3: filterList });
  // }

  /** 点击ok */
  // tslint:disable-next-line: cyclomatic-complexity
  handleOk = (e: any) => {
    const { step, formMap } = this.state;
    this.handleStep1Save();

    // if (step === 0) {
    //   this.handleStep1Save();
    //   return;
    // }
    // if (step === 1) {
    //   this.handleStep2Save();
    //   return;
    // }

    // if (step === 2) {
    //   this.handleStep3Save();
    //   // const { onOk } = this.props;
    //   // onOk && onOk(e);
    // }
  }

  /** 点击重置 */
  handleReset = (e: any) => {

    const { step, formMap, list1, } = this.state;
    if (step === 0) {
      if (!list1 || list1.length === 0) return;
      list1.forEach((item) => {
        formMap[item.name] && formMap[item.name].resetFields();
      });
      return;
    }
    // if (step === 1) {
    //   list2.forEach((item) => {
    //     formMap[item.name] && formMap[item.name].resetFields();
    //   });
    //   return;
    // }
    // if (step === 2) {
    //   list3.forEach((item) => {
    //     formMap[item.name] && formMap[item.name].resetFields();
    //   });
    // }
  }

  render() {
    const { loading } = this.state;
    return (
        <>
        <Modal
          footer={this.renderModalFooter()}
          maskClosable={false}
          onCancel={this.handleCancel}
          // confirmLoading={loading}
          {...this.props}
        >
          <Wrap>
            {/* {this.renderTopCategory()} */}
            {/* {this.renderStep()} */}
            <>
              {loading && (
                <div style={{ width: "100%", height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Spin/>
                </div>)
              }
              {this.renderGenerate1()}
              {/* {this.renderGenerate2()}
              {this.renderGenerate3()} */}
            </>
          </Wrap>
          <GloabalStyle/>
        </Modal>
      </>
    );
  }

  /** handle onCancel */
  handleCancel = () => {
    const { accessionNumber } = this.state;
    const { handleCancel } = this.props;
    handleCancel && handleCancel(1);
  }

  /** render modal footer */
  renderModalFooter = () => {
    const { step } = this.state;
    return (
      <>
        <Button onClick={this.handleCancel}>{"取消"}</Button>
        <Button onClick={this.handleReset} >{"重置"}</Button>
        <Button type={"primary"} onClick={this.handleOk} >{"保存"}</Button>
      </>
    );
  }

  /** load form */
  onLoadForm1 = (name: string, form: FormComponentProps["form"]) => {
    this.setState((preState) => ({ formMap: { ...preState.formMap, [name]: form } }));
  }

  /** render form 1 */
  renderGenerate1 = () => {
    const { step, list1 } = this.state;
    if (step !== 0) {
      return null;
    }
    return (
      <>
        {list1 && list1.map((item) => {
          return (
            <GenerateCategory
              useMultiplePreview
              data={item}
              key={item.name}
              title={item.name}
              onLoad={this.onLoadForm1.bind(this, item.name)}
            />
          );
        })}
      </>
    );
  }

  /** render form 1 */
  // renderGenerate2 = () => {
  //   const { step, list2 } = this.state;
  //   if (step !== 1) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       {list2 && list2.map((item, ind) => {
  //         return (
  //           <GenerateCategory
  //             data={item}
  //             useRegionCascader
  //             key={`测试报告${ind}`}
  //             title={"报告明细"}
  //             colNum={12}
  //             onLoad={this.onLoadForm1.bind(this, item.name)}
  //             rightCoponent={
  //               ind !== 0 &&
  //               <Button onClick={this.handleTestReportDel.bind(this, item.name)}>删除</Button>
  //             }
  //           />
  //         );
  //       })}
  //      <Button
  //       type={"primary"}
  //       icon={"plus"}
  //       style={{ marginTop: 12 }}
  //       onClick={this.handleTestReportAdd}
  //      >
  //         报告明细
  //      </Button>
  //     </>
  //   );
  // }

  /** render form 3 */
  // renderGenerate3 = () => {
  //   const { step, list3 } = this.state;
  //   if (step !== 2) {
  //     return null;
  //   }
  //   return (
  //     <>
  //       {list3 && list3.map((item, ind) => {
  //         return (
  //           <GenerateCategory
  //             data={item}
  //             useRegionCascader
  //             key={`${ind}`}
  //             title={"报告明细"}
  //             colNum={12}
  //             onLoad={this.onLoadForm1.bind(this, item.name)}
  //             rightCoponent={
  //               ind !== 0 &&
  //               <Button onClick={this.handleBreedingReportDel.bind(this, item.name)}>删除</Button>
  //             }
  //           />
  //         );
  //       })}
  //      <Button
  //       type={"primary"}
  //       icon={"plus"}
  //       style={{ marginTop: 12 }}
  //       onClick={this.handleBreedingReportAdd}
  //      >
  //         报告明细
  //      </Button>
  //     </>
  //   );
  // }

  /** handle step change */
  // handleStepChange = (current: number) => {
  //   const { accessionNumber } = this.state;
  //   if (!accessionNumber) {
  //     message.warning("请先填写基本信息");
  //     return;
  //   }
  //   this.setState({ step: current });
  // }

  /** render 步骤条 */
  // renderStep = () => {
  //   const { step } = this.state;
  //   return (
  //     <Steps
  //         type="navigation"
  //         size="small"
  //         current={step}
  //         onChange={this.handleStepChange}
  //         style={{
  //           border: "solid 1px rgba(0, 22, 23, 0.15)",
  //         }}
  //       >
  //       <Step title="种质基本信息" />
  //       <Step title="种质测试报告" />
  //       <Step title="分子育种报告" />
  //     </Steps>
  //   );
  // }

  /** render top 分类 */
  // renderTopCategory = () => {
  //   const { cropCategory } = this.props;
  //   return (
  //     <TopCategory
  //       cropCategory={cropCategory}
  //       onSearch={this.baseInfoSearch}
  //     />
  //   );
  // }
}
