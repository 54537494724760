import React, {PureComponent} from "react";
import styled from "styled-components";
import {TableComponent} from "./table/toolTable";
import {observer} from "mobx-react";
import {HeaderBox} from "../../containers/header-box";
// tslint:disable:variable-name
const ContainerWithStyle = styled.div`
    flex: auto;
    display: flex;
    justify-content: center;
    padding: 16px;
    height: calc(100vh - 100px);
`;
@observer
export class Tool extends PureComponent {
    render() {
        return (
            <HeaderBox names={["数据记载档案"]}>
                <ContainerWithStyle>
                    <TableComponent/>
                </ContainerWithStyle>
            </HeaderBox>
        );
    }
}
