import React from "react";
import styled from "styled-components";
import { ICSSProps } from "@gago/frame";
import { MapGL, MapPosition, Marker, MapEvent } from "@gago-react-gl/gago-react-gl";
import { bmMapDefault } from "../../containers/map-layer";
import { ChinaExtensionMapLayer, DirectionMap } from "./market-map";
import { MapContext } from "../../contexts/map";
import ExtensionScreenProvider from "../../network/providers/extension-bigscreen.provider";

import { message, Select } from "antd";
import { PopularizeMapType } from "../../models/extension-screen";
import {
  BaseRequest,
  ExpandComponyList,
} from "../../models/extension-bigscreen";

import { round, debounce } from "lodash";
import { colorPalette } from "../../color-palette";
import { Card6 } from "../../containers/card6";
import selectBg from "./img/select-bg.svg";
import { useProvider } from "../../utils/use-provider";
import { MarkerIcom } from "./style";

/** render center1 */
// tslint:disable-next-line: cyclomatic-complexity
const RenderCenter1 = (params: BaseRequest) => {
  const state = useProvider(
    [params],
    ExtensionScreenProvider.getExpandSales.bind(ExtensionScreenProvider)
  );
  if (!state) {
    return null;
  }
  // @ts-ignore
  const sales = state.district ? state.district.match(/[0-9]+/g) : [];
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        marginTop: 24,
      }}
    >
      {[
        {
          title: "销售区域",
          value: (
            <>
              <span>
                {sales[0] || 0} <span style={{ fontSize: 16 }}>省</span>
              </span>
              {" "}
              <span>
                {sales[1] || 0} <span style={{ fontSize: 16 }}>市</span>
              </span>
            </>
          ),
        },
        {
          title: "当年交易量(吨)",
          value: round(Number(state.volume) || 0, 2),
          change: round(Number(state.volume_growth_rate) || 0, 2),
        },
        {
          title: "当年交易额(万元)",
          value: round(Number(state.gmv) || 0, 2),
          change: round(Number(state.gmv_growth_rate) || 0, 2),
        },
        {
          title: "总客户量",
          value: round(Number(state.client) || 0, 2),
        },
        {
          title: "新增农户比",
          value: (
            <span>
              {round(Number(state.client_growth_rate) || 0, 2)}{" "}
              <span style={{ fontSize: 16 }}>%</span>
            </span>
          ),
        },
      ].map((item) => {
        return (
          <Card6
            key={item.title}
            title={item.title}
            value={item.value}
            change={item.change || undefined}
            colorPalette={colorPalette}
            style={{
              height: 107,
              width: 181,

            }}
          />
        );
      })}
    </div>
  );
};

interface P extends ICSSProps {
  /** handle 公司数据id变化 */
  handleCompanyIdChange(companyId: string): void;
}

interface S {
  /** 地图mapbox实例 */
  map: mapboxgl.Map;
  /** 公司数据列表 */
  companyList: ExpandComponyList[];
  /** 查询公司的id */
  companyId: string;
  /** 销售地图数据 */
  popularizeMap: PopularizeMapType[];
  /**缩放层级 */
  zoom: number;
}

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .extension-select{
    color:  #ffc83b;
    .ant-select-selection{
      background-image: url('${selectBg}');
      background-size: 100% 100%;
      background-color: transparent;
      border-radius: 6px;
      background-origin: border-box;
      border: none;
    }
    .ant-select-arrow{
      color:  #ffc83b;
    }
  }
`;

/**
 * 中国销售路径的地图
 */
export default class Map extends React.PureComponent<P, S> {
  state: S = {
    map: (undefined as unknown) as mapboxgl.Map,
    companyList: [],
    companyId: "4",
    popularizeMap: [],
    zoom: 0,
  };

  async componentDidMount() {
    await this.getComponyList();
    this.getMapData();
  }
  /** 监听地图的滚动事件 */
  // tslint:disable: cyclomatic-complexity
  // tslint:disable-next-line:no-any
  handleZoom = (e: any) => {
    const tempZoom = e.target.transform.zoom;
    const { zoom } = this.state;
    console.log("zoom", zoom);
    if (tempZoom <= 4.71) {
      this.setState({ zoom: 4 });
    } else {
      this.setState({ zoom: 5 });

    }
    // if (tempZoom > 4.7) {
    //   this.setState({ zoom: 5 });
    // }
  }

  /** 滚动节流 */
  handleZoomDebounce = debounce(this.handleZoom, 300);
  /** onload */
  // tslint:disable-next-line:no-any
  onload = (map: any) => {
    this.setState(
      {
        map: map.target,
      },
      () => {
        window._map = map.target;
      }
    );
  }
  /**获取公司列表**/
  getComponyList = async () => {
    const companyList = await ExtensionScreenProvider.getCompanyList().catch(
      (err) => {
        message.info("未查询到公司列表信息！", 1);
        return [];
      }
    );
    this.setState({ companyList });
  };

  /** get map data */
  getMapData = async () => {
    const { companyId: id, companyList } = this.state;
    const companyObject = companyList.find((item) => item.id === id);
    const popularizeMap = await ExtensionScreenProvider.getPopularizeMap({
      companyId: companyObject && companyObject?.companyId,
    }).catch((err) => {
      message.info("未查询到销售地图信息！", 1);
      return [];
    });
    this.setState({ popularizeMap });
  }

  /**比较公司 */
  companyIdChange = (companyId: string) => {
    this.props.handleCompanyIdChange(companyId);
    this.setState({ companyId }, () => {
      this.getMapData();
    });
  };

  render() {
    const { className, style } = this.props;
    const { companyId, companyList, popularizeMap, zoom } = this.state;
    return (
      <Wrap className={className} style={style}>
        <Select
          value={companyId}
          className="extension-select"
          dropdownClassName="extension-select-menu"
          style={{ width: 240, top: 10 }}
          onChange={this.companyIdChange}
        >
          {companyList.map((item, index) => (
            <Select.Option value={item.id} key={index}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        {<RenderCenter1 id={companyId} />}
        <MapGL {...bmMapDefault} onLoad={this.onload}>
          <MapPosition
            bbox={[
              [138.31856570095368, 54.00720495880486],
              [68.86125083326715, -8.641505476355746],
            ]}
            moveMethod="jumpTo"
          />
          <ChinaExtensionMapLayer />
          {zoom !== 5 && <MarkerIcom coordinates={[124.558402233, 25.922278915]}>
            <div className="equipment-box">
              <div className="equipment-point">赤尾屿</div>
            </div>
          </MarkerIcom>}
          <MapContext.Provider value={{ map: this.state.map }}>
            <MapContext.Consumer>
              {({ map }) => (
                <DirectionMap mapData={popularizeMap || []} map={map} />
              )}
            </MapContext.Consumer>
          </MapContext.Provider>
          <MapEvent type={"zoom"} onChange={this.handleZoomDebounce} />

        </MapGL>
      </Wrap>
    );
  }
}
