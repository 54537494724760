import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
import axios from 'axios'
// tslint:disable-next-line: ter-max-len
import { BaseSampleRequestInfo,
  BaseInfo,
  SampleDetail,
  SampleListRequest,
  SamplePoint,
  SampleList,
  SampleUpdate } from "../../models/land-database";
import { ITable } from "@gago/frame";
// tslint:disable:ter-max-len

/**
 * 专家知识库模块
 * @author wangchengbing
 */
export default class LandDataBaseProvider extends BaseProvider {
  /** 获取新增所需字段 */
  static async getSampleInfoKey() {
    const { data } = await BaseRequestStaticServer.get<ResultType<BaseInfo[]>>(`plant/column/tableName?tableName=soil`,{
    });
    return data;
  }

  /** 获取 样点详情 */
  static async getSampleDetail(params:number) {
    const { data } = await BaseRequestStaticServer.get<ResultType<SampleDetail>>(`/plant/soil/samplingPoint/${params}`);
    return data;
  }

  /** 获取 样点数据列表 */
  static async getSampleList(params:SampleListRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<SampleList>>("/plant/soil/samplingPoint",{
      params
    });
    return data;
  }

  /** 获取查询条件*/
  static async getQueryList(params:{type:string}) {
    const { data } = await BaseRequestStaticServer.get<ResultType<{value:string}[]>>("/plant/soil/query",{
      params
    });
    data.unshift({value:'全部'})
    return data;
  }

    /** 删除样点信息 */
    static async delSample(id:number) {
      const data:ResultType<number> = await BaseRequestStaticServer.post("/plant/soil/samplingPoint/del",{
        id
      });
      return data;
    }

    /** 新增样点 */
    static async addSample(params:{information:string}) {
      const  data:ResultType<number>  = await BaseRequestStaticServer.post("/plant/soil/samplingPoint/add",
        params
      );
      return data;
    }

    /** 更新样点 */
    static async updateSample(params:{id:number;information:string;}) {
      const  data:ResultType<number>  = await BaseRequestStaticServer.post("/plant/soil/samplingPoint/update",
        params
      );
      return data;
    }

    

    /** 下载模板数据 */
    static async downloadModule() {
      const  { data } = await BaseRequestStaticServer.get<ResultType<string>>("/plant/soil/template");
      return data;
    }

    /** 导出 样点信息数据 */
    static async exportData(params:{ids:string}) {
      const  data  = await BaseRequestStaticServer.get<ResultType<any>>("/plant/soil/export",{
        params
      });
      return data;
    }
    
      // 下载文件
    
    static async download(url:string) {
        await BaseRequestStaticServer.downloadFile(url,'样点数据.xlsx');
    }

}
