import React from "react";
import { Upload, Modal, Icon, Empty, Button, message } from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/lib/upload/interface";
import { get } from "lodash";

interface P extends UploadProps {
  /** 是否为视频展示 */
  isVideo?: boolean;
}

interface S {
  /** 上传图片预览 */
  updatePreviewFile?: UploadFile<any>;
}

export class BamengUpload extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {};
  }

  renderUpload = () => {
    const { fileList, disabled, isVideo, ...other } = this.props;
    const token = window.sessionStorage.getItem("token") as string;
    return (
      <Upload
        accept={isVideo ? "video/*" : "image/*"}
        action={`${window.config.publisher}/api/v1/plant/file/upload`}
        // listType={"picture-card"}
        headers={{ token }}
        onPreview={this.handlePreviewOk}
        fileList={fileList}
        disabled={disabled}
        {...other}
      >
        <Button disabled={(fileList && fileList.length >= 1) || disabled}>
          <Icon type="upload" /> {isVideo ? "上传视频" : "上传图片"}
        </Button>
      </Upload>
    );
  };

  /**update preview ok */
  handlePreviewOk = (file: UploadFile<any>) => {
    this.setState({
      updatePreviewFile: file,
    });
  };

  /**update preivew cancle */
  handlePreviweCancel = () => {
    this.setState({
      updatePreviewFile: undefined,
    });
  };

  /** render upload preview */
  renderUpdatePreview = () => {
    const { updatePreviewFile } = this.state;
    const { isVideo } = this.props;
    if (!updatePreviewFile || (updatePreviewFile && !updatePreviewFile.type)) {
      return null;
    }
    let child = <Empty description="不支持预览" />;
    const url =
      updatePreviewFile.url ||
      get(updatePreviewFile, "response.data.data[0].file", undefined);
    if (/(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(updatePreviewFile.type) && url) {
      child = (
        <img
          src={url}
          alt={updatePreviewFile.name}
          style={{ maxWidth: "100%" }}
        />
      );
    }
    if (/pdf$/.test(updatePreviewFile.type) && url) {
      child = <iframe src={url} width="100%" height={660} />;
    }

    if (url && isVideo) {
      child = (
        <video src={url} controls={true} style={{ maxWidth: "100%" }}>
          您的浏览器不支持 video 标签。
        </video>
      );
    }

    return (
      <Modal
        title={`预览 ${updatePreviewFile.name}`}
        visible={!!this.state.updatePreviewFile}
        footer={
          <Button type={"primary"} onClick={this.handlePreviweCancel}>
            关闭
          </Button>
        }
        onCancel={this.handlePreviweCancel}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>{child}</div>
      </Modal>
    );
  };

  render() {
    return (
      <>
        {this.renderUpload()}
        {this.renderUpdatePreview()}
      </>
    );
  }
}
