// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select, message, Cascader } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import { ResourctFindOne } from "../../models/germplasm-database";

const { Option } = Select;
const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

type P = {
  /** 头部 */
  topCategory?: Pick<ResourctFindOne, Exclude<keyof ResourctFindOne, "information">>;
}

/**
 * 顶部
 * @author maoshengtao
 */
export class TopCategory extends React.Component<P> {
  constructor(props: P) {
    super(props);
  }


  render() {
    const { topCategory } = this.props;
    if (!topCategory) {
      return null;
    }

    return (
      <>
        <WrapForm >
          <Row gutter={24}>
            <Col span={4} >
              <Form.Item label={`种质分类`} >
                <Input value={topCategory.oneLevel || "——"} disabled />
              </Form.Item>
            </Col>
            <Col span={4} >
              <Form.Item label={`二级分类`} >
                <Input value={topCategory.twoLevel || "——"} disabled />
              </Form.Item>
            </Col>
            <Col span={5} >
              <Form.Item label={`地区选择`} >
                {/* <Input value={`${topCategory.provinceName || ""}${topCategory.cityName ? `/${topCategory.cityName}` : ""}`} disabled /> */}
                {/* <span style={{ fontSize: 20, color: "rgba(0, 22, 27, 0.7)" }} >{`${topCategory.provinceName || ""}${topCategory.cityName ? `/${topCategory.cityName}` : ""}`}</span> */}
                <div style={{ fontSize: 20, color: "rgba(0, 22, 27, 0.7)" }} >{topCategory.provinceName || ""}</div>
                {topCategory.cityName && <div style={{ fontSize: 20, color: "rgba(0, 22, 27, 0.7)" }} >{topCategory.cityName}</div>}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={`是否区域特色种质`} >
                <Input value={topCategory.isTs === 0 ? "否" : "是"} disabled />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={`是否市场流通种质`} >
                <Input value={topCategory.isCirculate === 0 ? "否" : "是"} disabled />
              </Form.Item>
            </Col>
            {/* <Col span={4} style={{ textAlign: "right" }}>
              <Button style={{ marginLeft: 5 }} type="primary">
                确定
              </Button>
            </Col> */}
          </Row>
        </WrapForm>
      </>
    );
  }
}
