import React from "react";
import { HeaderBox } from "../../containers/header-box";
import { PageBody } from "./styled";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import Map from "./map";
import { EllipseRadioSelect, LocationSelect, GradualGauge, McBarChart, McLineChart } from "@gago/lib/components";
import {
  GrowthAnalysis,
  GrowthCurv,
  GrowthSummary,
  Phenophase,
} from "../../models/growth-monitoring";
import GrowthMonitoringProvider from "../../network/providers/growth-monitoring";
import CommonProvider from "../../network/providers/common.provider";
// import { Card1 as Card } from "@gago/frame";
import { ITable, SideBar4, Timeline7 } from "@gago/frame";
import { noop, uniqBy } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { Icon, Tag } from "antd";
import { chart1N2D } from "../germplasm-breed/style";
import { BreedingTimeline } from "../../containers/breeding-timeline";
import moment from "moment";
export const mapDataList: {label: string; value: string}[] = [
  { label: "向日葵", value: "sunflower" },
  { label: "玉米", value: "corn" },
  { label: "小麦", value: "wheat" },
  { label: "辣椒", value: "grassland" },
];

/** render left1 */
const RenderLeft1 = () => {
  return (
      <AxisChart
        chartData={getDataS2N1("region", ["识别面积", "统计面积"])}
        colorPalette={{ ...colorPalette, subColor: ["#13d7c8", "#e77300", ...colorPalette.subColor] }}
      />
  );
};

interface P {

}

interface S {
  /** 高亮的地区 */
  region: Region;
  /**气象预警/历史气象 */
  forecastType: "meteorological_warning" | "meteorological_history";
  /** 向日葵|玉米|小麦|草地*/
  crop: string;
  growthSummary: GrowthSummary[];
  growthCurve: GrowthCurv[];
  growthAnalysis: GrowthAnalysis;
  lon: string;
  lat: string;
  year: number;
  phenophaseList: Phenophase[];
  date: string;
  timeData: string[];
}
type Keys = keyof GrowthSummary;
interface Types {
  one: string;
  two: string;
  three: string;
  four: string;
  five: string;
}
const keyMap: Types = {
  one: " 极弱",
  two: "较弱",
  three: "正常",
  four: "较强",
  five: "极强",
};

export default class GrowthMonitoring extends React.PureComponent<P, S> {

  state: S = {
    region: {
      code: "150800000",
      name: undefined,
      level: "city",
    },
    forecastType: "meteorological_warning",
    crop: "sunflower",
    growthAnalysis: {
      type : 0,
      oldGrowthAnalysis : 0,
      growthAnalysis : 0,
    },
    growthCurve: [],
    growthSummary: [],
    lat: "40.6288193598815",
    lon: "106.102294545675",
    year: new Date().getFullYear(),
    phenophaseList: [],
    date: "20201025",
    timeData: [],
  };

  componentDidMount() {
    const { crop } = this.state;
    // this.initData();
    this.getPhenophsae();
    this.getBreedTime(this.judgeCropTimeType(crop) as string);
  }

  initData = () => {
    this.getGrowthAnalysis();
    this.getGrowthCurve();
    this.getGrowthSummary();
  }
  // 获取作物分析
  getGrowthAnalysis = async () => {
    const { crop, region: { code }, date } = this.state;
    const year = moment(date).year();
    const growthAnalysis = await GrowthMonitoringProvider.getGrowthAnalysis({ crop: crop === "grassland" ? "wheat" : crop, date, code: code as string });
    this.setState({ growthAnalysis });
  }

  // 获取作物长势汇总
  getGrowthSummary = async () => {
    const { crop, year, region: { code }, date } = this.state;
    const growthSummary = await GrowthMonitoringProvider.getGrowthSummary({ crop: crop === "grassland" ? "wheat" : crop, date, code: code as string });
    this.setState({ growthSummary });
  }

  // 获取作物长势曲线
  getGrowthCurve = async () => {
    const { crop,  region: { code }, date } = this.state;
    const year = moment(date).year();

    const growthCurve = await GrowthMonitoringProvider.getGrowthCurve({ crop: crop === "grassland" ? "wheat" : crop, date, code: code as string });
    this.setState({ growthCurve });
  }

  // 获取作物理论物候期
  getPhenophsae = async () => {
    const phenophaseList = await GrowthMonitoringProvider.getPhenophase();

    this.setState({ phenophaseList });
  }

  // 获取时间轴数据
  judgeCropTimeType = (type: string) => {
    switch (type) {
      case "wheat": return "ndvi8";
      case "corn": return "ndvi_corn8";
      case "sunflower": return "ndvi_sunflower8";
      // case "grassland": return "ndvi_glass8";
      case "grassland": return "ndvi8";
      default: return "ndvi_sunflower8";
    }
  }
  getBreedTime = async (crop: string) => {
    const timeData = await CommonProvider.getPublishTimeline(crop);
    const yearList = timeData.map(item => moment(item).years());
    const year = [...Array.from(new Set(yearList))];
    const sortedYear = year.sort((a, b) => a - b);
    const latestTimeData = timeData.filter(item => moment(item).years() === sortedYear[sortedYear.length - 1]);
    console.log("****timedata", timeData);

    this.setState({ timeData, date: latestTimeData[latestTimeData.length - 1] });
  }

  // 处理物候期
  handlePhnophase = () => {
    const { phenophaseList, region: { code }, crop } = this.state;
    const realCode = code === undefined ? "150800000" : code;
    const targetList = phenophaseList.filter(item => item.CODE === Number(realCode + "000") && item.CROP === this.getCropName(crop));
    const yearList: string[] = ["2019", "2020", "2021"];
    let timeData: string[] = [];
    yearList.forEach(year => {
      timeData = [...targetList.map(item => `${year}-${item.START}`), ...timeData];
    });
    this.setState({ timeData: Array.from(new Set(timeData)), date: Array.from(new Set(timeData))[0] });
  }

  //  切换作物类型
  onChangeCropType = (value: string) => {
    const { timeData } = this.state;
    const defaultRegion: Region = {
      code: "150800000",
      name: undefined,
      level: "city",
    };
    this.getBreedTime(this.judgeCropTimeType(value) as string);
    this.setState({
      crop: value,
      region: defaultRegion,
    }, () => {
      // this.initData();
      // this.handlePhnophase();
      this.initData();
    });
  }
  /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
    const { code } = region;

    this.setState({ region }, () => {
        // this.handlePhnophase();
      this.initData();

    });
  }

  getCropName = (crop: string) => {
    const targetCrop = mapDataList.find(item => item.value === (crop === "grassland" ? "wheat" : crop));
    if (!targetCrop) {return "向日葵"; }
    return targetCrop.label;
  }

  changeDate = (date: string) => {
    console.log(date, "---->date");
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");
    const year = date && moment(date, "YYYY-MM月DD日").year();
    this.setState({ date: time }, () => this.initData());
  }
  handleChangeDate = (date: string) => {
    // const date = moment(value).format
    this.setState({ date }, () => this.initData());
  }
  render() {
    const { region, year, timeData, crop, region: { code }, date } = this.state;
    const cropName = this.getCropName(crop);
    const realCode = code === undefined ? "150800000" : code;
    console.log(realCode, "111");
    return (
      <HeaderBox
        names={["种质长势监测"]}
        noPadding
      >
        <PageBody>
          <Map changeRegion={this.changeRegion} region={region} crop={crop} date={date}/>
          {this.renderSidebar()}
          <EllipseRadioSelect
            data={mapDataList}
            className="mapSelect"
            colorPalette={colorPalette}
            backSelectChange={this.onChangeCropType}
          />
         {timeData.length > 0 &&
          <Timeline7
            key={crop}
            className="growthTimeLine"
            selected={moment(date).format("YYYY-MM-DD")}
            data={timeData}
            colorPalette={colorPalette}
            loop={"all" as "all"}
            interval={5000}
            onChange={this.handleChangeDate}/> }
        </PageBody>
      </HeaderBox>
    );
  }

  renderPhenophaseTag = () => {
    const { phenophaseList, date, crop, region: { code } } = this.state;
    if (!phenophaseList || phenophaseList.length === 0) {
      return null;
    }
    const year = moment(date).year() || 2021;
    const realCode = code === undefined ? "150800000" : code;
    const targetList = phenophaseList.filter(item => { return item.CODE === Number(realCode + "000") && item.CROP === this.getCropName(crop); });
    console.log(targetList, date, "targetList");
    const res = targetList.find(item => {
      if (moment(date).unix() >= moment(`${year}-${item.START}`).unix() && moment(date).unix() <= moment(`${year}-${item.END}`).unix()) {
        return true;
      }
      return false;

    });
    const tagList = uniqBy(targetList, "START");
    const tagList1=[{PHE: "播种期"},{PHE: "发芽期"},{PHE: "幼苗期"},{PHE: "开花坐果期"},{PHE: "结果期"}]
    return (
      <>
        <McCard
          title="理论物候期"
        >
         {targetList && crop!=="grassland" && Array.from(new Set(tagList)).map(item => (<Tag color={res?.PHE === item.PHE ? "#108ee9" : ""}  >{item.PHE}</Tag>))}
         {targetList && crop==="grassland" && tagList1.map(item => (<Tag color={res?.PHE === item.PHE ? "#108ee9" : ""}  >{item.PHE}</Tag>))}
        </McCard>
      </>
    );

  }

  renderGrowthAnysis = () => {
    const { growthAnalysis: { growthAnalysis, oldGrowthAnalysis } } = this.state;
    const trend = oldGrowthAnalysis === growthAnalysis ? "equal" : growthAnalysis > oldGrowthAnalysis ? "up" : "down";

    // tslint:disable-next-line: ter-max-len
    const value = oldGrowthAnalysis === 0 && growthAnalysis !== 0 ? 100 : Math.abs(growthAnalysis - oldGrowthAnalysis) / Math.abs(oldGrowthAnalysis) * 100;
    // const type = growthAnalysis > oldGrowthAnalysis ? "up" : "down";
    const chartData: ITable<0, 1> = {
      title: ``,
      subtitle: ``,
      description: "成熟度（%）",
      defaultValue: [0, 0],
      range: [[], [0, 10, 20, 40, 50, 60, 80, 90, 100]],
      head: ["ID", "较上期增长情况"],
      data: [
        ["0", Math.abs(growthAnalysis * 100)],
      ],
      suffix: ["", ""],
    };
    return (
      <>
        <McCard
        title="长势分析"
        >
          <div style={{ height: "170px" }}>
            <GradualGauge
                chartData={chartData}
                colorPalette={{ ...colorPalette, subColor: ["#5a84ef", "#35a3de", "#40c2ad", "#31be59", "#73b500", "#f1e500", "#ffbb00", "#f78011", "#eb4c28"] }}
            />
          </div>
          <div style={{ textAlign: "center", marginTop: -30 }}>
            较上期{trend === "up"
              ? <Icon type="arrow-up" style={{ color: "green" }} />
              : trend === "down"
                ? <Icon type="arrow-down" style={{ color: "red" }} />
                : <></>
              }
              <span style={{ color: trend === "up" ? "green" : trend === "down" ? "red" : "" }}>{trend === "equal" ? "持平" : value.toFixed(1) + "%"}</span>
          </div>
        </McCard>
      </>
    );

  }

  renderGrowthSummary = () => {
    const { growthSummary } = this.state;
    if (!growthSummary || growthSummary.length === 0) {
      return null;
    }

    let dataMap: {name: string; value: number; type: string}[] = [];
    growthSummary.forEach(item => {
      const keyArray: Keys[]  = Object.keys(item) as Keys[];
      const chartDataArray = keyArray.filter(item => item !== "code" && item !== "name").map(level => {
        return {
          name: item.name,
          value: item[level] as number,
          type: keyMap[level as keyof Types],
        };
      });
      dataMap = [...dataMap, ...chartDataArray];
    });
    const chartData: ITable<2, 1> = {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "万亩"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: dataMap.map<ITable<2, 1>["data"][0]>(item => ["", `${item.type}`, item.name, item.value]),
    };
    return (
      <>
      <McCard
          title="作物长势汇总"
        >
          <div style={{ height: "170px", overflowX: "auto" }}>
            <McBarChart

              chartData={chartData}
              chartStyle={chartStyle}
              ySplitNumber={3}
              barGap={-0.4}
              stackOne
              colorPalette={{ ...colorPalette, subColor: ["#c6682c", "#f6da6b", "#72d983", "#26ae94", "#004f6b"] }}
            />
          </div>

        </McCard>

      </>
    );

  }

  renderGrowthCurve = () => {
    const { growthCurve, crop } = this.state;
    if (!growthCurve || growthCurve.length === 0) {
      return null;
    }
    const chartData: ITable<2, 1> = {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", ""],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: growthCurve.map<ITable<2, 1>["data"][0]>(item => ["", `${this.getName(crop)}`, moment(item.date).format("MM/DD"), item.value]),

    };
    return (
       <>
        <McCard
          title="作物长势曲线"
        >
          <div style={{ height: "170px", overflowX: "auto" }}>

          <McLineChart
            hideLabel
            chartData={chartData}
            showSymbol={false}
            chartStyle={chartStyle}
            ySplitNumber={4}
            colorPalette={pieChartColor}
          />
        </div>

        </McCard>

       </>
    );

  }

  renderDisasterLevel = () => {
    return (
      <>
        <div className="levelWrapper">
          <p className="disasterName">旱情</p>

          <div className="level-container">
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#438df8" }}></span>
              <span className="levelName">正常</span>
            </div>
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#ffc802" }}></span>
              <span className="levelName">轻度</span>
            </div>
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#f07a26" }}></span>
              <span className="levelName">中度</span>
            </div>
            <div className="levelItem">
              <span className="levelColor" style={{ backgroundColor: "#e9364e" }}></span>
              <span className="levelName">严重</span>
            </div>
          </div>

        </div>
      </>
    );
  }
  getName = (crop: string) => {
    switch (crop) {
      case "sunflower": return "向日葵"; break;
      case "corn": return "玉米"; break;
      case "wheat": return "小麦"; break;
      case "grassland": return "辣椒";
    }
  }
  /** render sider bar*/
  renderSidebar = () => {
    const { forecastType } = this.state;
    return (
      <SideBar4
        className="breeding-distribute-side"

        colorPalette={colorPalette}
        sizeOnChange={noop}
        theme="light"
        style={{ width: 400, zIndex: 999 }}
        title="作物长势"
        // title= {<EllipseRadioSelect colorPalette = {colorPalette} data={mapMeteorologicalList} className="meteorologicalSelect" backSelectChange={this.onChangeForecastType}/>}
      >
         {/* { forecastType === 'meteorological_warning'
          ?  <MeteorologicalWarning data={ phenologicalList } weekForecast = {weekForecast} agricultureWarning={agricultureWarning}/>
          :  <MeteorologicalHistory tmpList={tmpList} apcpList={apcpList} sumtmpList={sumtmpList} />} */}
        {this.renderPhenophaseTag()}
        {this.renderGrowthAnysis()}
        {this.renderGrowthSummary()}
        {this.renderGrowthCurve()}

      </SideBar4>
    );
  }

}
