import styled from "styled-components";

// tslint:disable-next-line:variable-name
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  .mapSelect {
    position:absolute;
    z-index:99;
    top:8px;
    left:8px;
  }
  .disasterSelect {
    position:absolute;
    z-index:99;
    top:8px;
    right:408px;
  }
  .timeline-box {
    position:absolute;
    left:0 ;
    bottom:16px;
    width: calc(100% - 400px);
    /* display:flex; */
  }
  .weatherTimeLine {
    /* position:absolute; */
    /* left:0; */
    /* bottom:16px; */
    width:70%;
    margin: 0 auto;
    /* transform:translateX(-50%); */
  }
  .meteLocatonSelect {
    position:absolute;
    z-index:99;
    top:8px;
    right:408px;
  }
  .meteorologicalSelect {
    background-color:transparent;
    box-shadow:none;
    width:208px;
  }
  .breeding-distribute-side{
    width:400px;
    padding:0;
    .sidebar-header-box {
        background-color: #00adad;
    }
    .sidebar-content {
      max-height:calc(100vh - 150px);
      .sidebar-display {
        padding:0;
      }
    }
    
    .warning-card {
      padding:8px;
      border-radius: 6px;
      background-color: rgba(0, 22, 23, 0.04);
      width:100%;

      .warning-header {
        display:flex;
        align-items:center;
        .warning-title {
          margin-left:8px;
          font-size:14px;
          color: #001617;
          font-weight: 500;
          line-height:1;
        }
      }

      .desc-title {
        opacity: 0.9;
        font-family: PingFangSC;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #001617;
        margin:8px 0;
      }

      .desc-content {
        opacity: 0.45;
        font-family: PingFangSC;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #001617;
      }

    }
  }

  .weather-forecast {
    width: 106px;
    height: 148px;
    /* margin: 444px 496px 4px 16px; */
    padding: 8px;
    border-radius: 8px;
    background-color: #ffffff;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    position:absolute;
    left:16px;
    top:56px;
    /* bottom:60px; */

    .weather-forecast-item {
      text-align:center;
      height:30px;
      display:flex;
      align-items:center;
      border-radius:40px;
      justify-content:center;
      cursor: pointer;
      .forecast-item-content {
        display:inline-block;
        text-align:center;
        color: #001617;
        line-height:1;
        display:flex;
        align-items:center;
        justify-content:center;


        .forecast-item-icon {
          width:14px;
          height:14px;
          margin-right:4px;
        }
      }
      
    }
    .weather-forecast-item-active {
        cursor: pointer;
        width:100%;
        height:30px;
        color: #fff;
        line-height:1;
        display:flex;
        align-items:center;
        border-radius:40px;
        background-color:#00adad;
        justify-content:center;

        .forecast-item-content {
          display:inline-block;
          text-align:center;
          color: #fff;
          line-height:1;
          display:flex;
          align-items:center;
          justify-content:center;

        .forecast-item-icon {
          width:14px;
          height:14px;
          margin-right:4px;
        }
      }
    }
  }
  .levelWrapper {
    width: 60px;
    height: 139px;
    /* margin: 491px 416px 8px 8px; */
    padding: 19px 12px 0;
    border-radius: 8px;
    background-color: #ffffff;
    position:absolute;
    right:408px;
    bottom:4px;
    .disasterName {
      text-align:center;
      font-family: PingFangSC;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: rgba(0, 21, 23, 0.7);
    }
    .level-container {
      display:flex;
      justify-content:space-between;
      flex-direction:column;
      align-items:center;

      .levelItem {
        text-align:center;
        font-family: PingFangSC;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: rgba(0, 21, 23, 0.7);
        margin-bottom:12px;

        .levelColor {
          width: 4px;
          height: 10px;
          display:inline-block;
          margin-right:8px;
        }

        .levelName {
          font-family: PingFangSC;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: rgba(0, 21, 23, 0.7);
        }
      }
    }
  }
`;
