import styled from "styled-components";
import { ColorProps, GlobalColorPalette } from "@gago/frame";
import animateBg from "./animation/animate-bg.png";
import animateUnder from "./animation/animate-under.svg";
import mapBg from "./animation/map-bg.png";
import leftIcon from "./animation/left-icon.svg";
import rightIcon from "./animation/right-icon.svg";
import topImg from "./top.png";
// tslint:disable-next-line:variable-name
export const HomeBody = styled.div<ColorProps<GlobalColorPalette>>`
  height: 100%;
  width: 100%;
  background-color: #09131f;
  background-image: url("/static/images/big-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .header-box{
    width: 100%;
    height: 110px;
    background-image: url('${topImg}');
    background-size: 100% 100%;
    /* height: 80px;
    background-image: url("/static/images/top-title.svg");
    background-repeat: no-repeat;
    background-size: cover; */
    position: relative;
    z-index: 3;
    .header-name{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 3px;
      color: #fff;
    }
  }
  .home-content{
    height: calc(100% - 160px);
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .canvas-border{
      height: 100%;
      width: 1292px;
      position: relative;
      z-index: 3;
      .canvas-animate{
        height: 1292px;
        width: 1292px;
        transform-origin: center center;
        transform-style: preserve-3d;
        position: relative;
        transform: rotateZ(0deg);
        transition: all 0.48s ease-in-out;
        z-index: 1;
        .animate1{
          height: 284px;
          width: 280px;
          transform: scale(1)rotateZ(0deg);
          position: absolute;
          top: 1150px;
          left: 506px;
          z-index: 10;
          transition: all 0.48s ease-in-out;
        }
        .animate2{
          height: 284px;
          width: 280px;
          transform: scale(0.8)rotateZ(0deg);
          position: absolute;
          top: 171px;
          left: -75px;
          z-index: 10;
          transition: all 0.48s ease-in-out;
        }
        .animate3{
          height: 284px;
          width: 280px;
          transform: scale(0.8)rotateZ(0deg);
          position: absolute;
          top: 171px;
          left: 1080px;
          z-index: 10;
          transition: all 0.48s ease-in-out;
        }
        .float-layer{
          position: absolute;
          /* border: 1px solid red; */
          height: 140px;
          width: 130px;
          top: 64px;
          left: 50%;
          margin-left: -65px;
          cursor: pointer;
        }
      }
      .animate-bg{
        position: absolute;
        top: 118px;
        left: 50%;
        margin-left: -425px;
        height: 450px;
        width: 850px;
        background-image: url("${animateBg}");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
      .animate-under{
        position: absolute;
        top: 240px;
        left: -14px;
        height: 370px;
        width: 1320px;
        background-image: url("${animateUnder}");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
      }
      .left-icon{
        position: absolute;
        left: -88px;
        top: 380px;
        height: 48px;
        width: 48px;
        cursor: pointer;
        z-index: 90;
        background-image: url("${leftIcon}");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .right-icon{
        position: absolute;
        right: -88px;
        top: 380px;
        height: 48px;
        width: 48px;
        cursor: pointer;
        z-index: 90;
        background-image: url("${rightIcon}");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .child-router{
        position: absolute;
        top: 500px;
        left: 50%;
        height: 170px;
        z-index: 90;
        transform: translateX(-50%);
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        .cr-item{
          height: 130px;
          width: 190px;
          .cri-img{
            display: block;
            margin: 0 auto;
            height: 100%;
            max-height: 100%;
            cursor: pointer;
          }
        }
      }
    }
    .map-bg{
      position: absolute;
      bottom: 20px;
      left: 24px;
      height: 430px;
      width: 1872px;
      background-image: url("${mapBg}");
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;
    }
  }
`;
