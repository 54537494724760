// tslint:disable:jsx-no-multiline-js
import React from "react";
import styled from "styled-components";
import { ColorProps, Color, ICSS, ICSSProps } from "@gago/frame/es/interface";

import { GlobalColorPalette } from "../../color-palette";

export interface SwitchData {
  /** 标示 */
  label: string;
  /** 值 */
  value: string;
}

type SwitchProps = {
  /** 传入的值 */
  data: SwitchData[];
  /** 值 */
  value?: string;
  /** 默认值 */
  defaultValue?: string;
  /** 监听切换 */
  onSwitch?(value: string, index: number): void;
  /** 另外一种风格 */
  whiteHighlight?: true;
} & ColorProps<GlobalColorPalette> & ICSSProps;

interface SwitchState {
  /** 选中的值 */
  activeKey: string;
}

/**
 * 切换按钮
 * @author luyaxiang
 * @date 2019.6.18
 * @export
 * @class Switch
 * @extends {React.PureComponent<SwitchProps, SwitchState>}
 * @implements {ICSS}
 * @implements {Color}
 */
export class Switch extends React.PureComponent<SwitchProps, SwitchState> implements ICSS, Color {

  constructor(props: SwitchProps) {
    super(props);
    let tempDefaultValue = props.data[0].value;
    if (props.defaultValue) {
      tempDefaultValue = props.defaultValue;
    }
    if (props.value) {
      tempDefaultValue = props.value;
    }
    this.state = {
      activeKey: tempDefaultValue,
    };
  }

  componentWillReceiveProps(nextProps: SwitchProps) {
    if (nextProps.value && (nextProps.value !== this.props.value)) {
      this.setState({
        activeKey: nextProps.value,
      });
    }
  }

  border = styled.div<{ colorPalette: GlobalColorPalette }>`
    width: auto;
    height: 32px;
    line-height: 1.13;
    border-radius: 20px;
    display: flex;
    border: 1px solid ${this.props.whiteHighlight ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.1)"};
    background-color: ${this.props.whiteHighlight ? "rgb(66, 155, 156)" : "transparent"};
    overflow: hidden;

    div {
      font-size: 14px;
      line-height: 30px;
      color: ${this.props.whiteHighlight ? "rgba(255, 255, 255, 0.85)" : "rgba(0, 20, 23, 0.7)"};
      border: 0;
      cursor: pointer;
      display: inline-block;
      padding: 0 16px;
      white-space: nowrap;
    }

    .switch-active {
      background: ${this.props.whiteHighlight ? "#fff" : "rgba(74, 170, 172, 1)"};
      color: ${this.props.whiteHighlight ? "rgba(0, 21, 23, 0.9)" : "white"};
      border-radius: 20px;
    }
  `;

  handleClick = (index: number) => {
    const { data, value, onSwitch } = this.props;
    if (value) {
      return;
    }
    this.setState({
      activeKey: data[index].value,
    }, () => {
      onSwitch && onSwitch(this.state.activeKey, index);
    });
  }

  render() {
    const { style, colorPalette, data } = this.props;
    const { activeKey } = this.state;
    return (
      <this.border style={style} colorPalette={colorPalette}>
        {
          data.map((val: SwitchData, index: number) => {
            return (
              <div
                key={val.value}
                onClick={this.handleClick.bind(this, index)}
                className={val.value === activeKey ? "switch-active" : ""}
              >
                {val.label}
              </div>
            );
          })
        }
      </this.border>
    );
  }
}
