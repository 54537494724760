import {ResultType, ResultType1, ResultType2} from "../../models/common";
import {RecordPoints} from "../../models/crop";
import {BaseRequestStaticServerRuoYI, BaseRequestStaticServerRuoYiByToken} from "../servers/base-request.server";
import {BaseProvider} from "./base.provider";

/**
 * 数据库
 * @author LeeJon
 * @date 2024
 * @export
 * @class MolecularProvider
 * @extends {BaseProvider}
 */

export default class MolecularProvider extends BaseProvider {
    /** 模拟登录接口 */
    static async ruoYiLogin() {
        // @ts-ignore
        const {token} = await BaseRequestStaticServerRuoYI.post<ResultType2<any>>("/login", {
            username: "mctest",
            password: "mc123456",
            mobile: "app"
        });
        sessionStorage.setItem("RouYiToken", token);
    }

    /** 获取指纹图谱列表详情 */
    static async getFzyzZwtpList(params: any) {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/fzyz/zwtp/list`, {params});
        return rows;
    }

    /** 添加指纹图谱 */
    static async addFzyzZwtp(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/fzyz/zwtp`, postData);
        return data;
    }

    /** 修改指纹图谱 */
    static async editFzyzZwtp(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/fzyz/zwtp/edit`, postData);
        return data;
    }

    /** 删除指纹图谱 */
    static async delFzyzZwtp(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/fzyz/zwtp/delete/${id}`);
        return code;
    }

    /** 查询单条指纹图谱 */
    static async getFzyzZwtpOne(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/fzyz/zwtp/${id}`);
        return code;
    }

    /** 获取工具库列表详情 */
    static async getFzyzGjkList(params: any) {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/fzyz/gjk/list`, {params});
        return rows;
    }

    /** 添加工具库 */
    static async addFzyzGjk(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/fzyz/gjk`, postData);
        return data;
    }

    /** 修改工具库 */
    static async editFzyzGjk(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/fzyz/gjk/edit`, postData);
        return data;
    }

    /** 删除工具库 */
    static async delFzyzGjk(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/fzyz/gjk/delete/${id}`);
        return code;
    }

    /** 查询单条工具库 */
    static async getFzyzGjkOne(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/fzyz/gjk/${id}`);
        return code;
    }

    /** 获取信息库列表详情 */
    static async getFzyzXxkList(params: any) {
        const {rows} = await BaseRequestStaticServerRuoYiByToken.get<ResultType1<any>>(`/fzyz/xxk/list`, {params});
        return rows;
    }

    /** 添加信息库 */
    static async addFzyzXxk(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/fzyz/xxk`, postData);
        return data;
    }

    /** 修改信息库 */
    static async editFzyzXxk(postData: any) {
        const {data} = await BaseRequestStaticServerRuoYiByToken.post<ResultType2<any>, any>(`/fzyz/xxk/edit`, postData);
        return data;
    }

    /** 删除信息库 */
    static async delFzyzXxk(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/fzyz/xxk/delete/${id}`);
        return code;
    }

    /** 查询单条信息库 */
    static async getFzyzXxkOne(id: any) {
        // @ts-ignore
        const {code} = await BaseRequestStaticServerRuoYiByToken.get<ResultType2<any>>(`/fzyz/xxk/${id}`);
        return code;
    }

}
