// tslint:disable:jsx-no-multiline-js
import React from "react";
import { ColorProps } from "@gago/frame/es/interface";
import { Source, LercTileSource, Layer, LercTileLayer, LercOverlayLayer, ClassificationLayer } from "@gago-react-gl/gago-react-gl";

import { MAP_IDS } from "../../containers/config";
import { mapAnalysis, mapToken, Config, handleMaskAndSlider } from "./map-analysis-const";
import { GlobalColorPalette } from "../../color-palette";
import { CropType } from "./index";
import { ClassificationLegend } from "@gago/frame/es/legends/classification-legend";
import { get } from "lodash";

type MapAnalysisMaskProps = {
  /** 控制范围 */
  range: any;
  /** 作物类型 */
  cropType: CropType;
  /** 当前选择的tab */
  sliderIndex: number;
} & ColorProps<GlobalColorPalette>;

/**
 * 数字茶园-适宜性评价-智能分析地图：掩模
 * @author luyaxiang
 * @date 2019.6.20
 * @export
 * @class MapAnalysisMask
 * @extends {React.PureComponent<MapAnalysisMaskProps>}
 */
export class MapAnalysisMask extends React.PureComponent<MapAnalysisMaskProps> {
  /** 渲染掩模 */
  renderMaskLayer = (maskConfig: Config) => {
    const { mapConfig } = maskConfig;
    
    const source: LercTileSource = {
      id: mapConfig.id,
      type: "lerc_v2",
      maxzoom: 14,
      minzoom: 0,
      headers: {
        token: mapToken,
      },
      tiles: [mapConfig.tiles],
    };

    const layer: LercTileLayer = {
      type: "lercv2",
      id: mapConfig.id,
      source: mapConfig.id,
      // @ts-ignore
      paint: {
        "raster-opacity": 0,
        "raster-colorscale": {
          type: "exponential",
          stops: [],
        },
      },
    };
    return (
      <>
        <Source<LercTileSource> {...source} key={mapConfig.id} />
        <Layer {...layer} key={mapConfig.id}/>
      </>
    )
  }

  renderMaskLayers = () => {
    const { sliderIndex } = this.props;
    const { maskConfig, sliderList } = handleMaskAndSlider(this.props.cropType) as any;
    return sliderList.map((item: any, index: number) => {
      if (sliderIndex === 0 && index > 3) {
        return null;
      }
      if (sliderIndex === 1 && index < 4) {
        return null;
      }
      return this.renderMaskLayer(maskConfig[item])
    })
  }

  getMaskConfig = () => {
    const { range, cropType, sliderIndex } = this.props;
    let lercMaskIndex = 0;
    const { maskConfig, sliderList} = handleMaskAndSlider(cropType) as any;
    const maskConfigs: any[] = sliderList.map((item: any, index: number) => {
      if (sliderIndex === 0 && index > 3) {
        return {};
      }
      if (sliderIndex === 1 && index < 4) {
        return {};
      }
  
      return {
        [`lerc-mask-source-${++lercMaskIndex}`]: maskConfig[sliderList[index]].mapConfig.id,
        [`lerc-mask-include-${lercMaskIndex}`]: [range[sliderList[index]]],
      };
    });
    return maskConfigs.reduce((arrc, item, index) => {
      return {...arrc, ...item};
    }, {} as any)
  }

  /** 获取layer配置 */
  getAnalysisLayer = () => {
    const analysisLayer: LercOverlayLayer = {
      type: "lerc-overlay",
      id: `${mapAnalysis.id}-${this.props.cropType}`,
      paint: {
        "raster-opacity": 0.85,
        "raster-domain-max": 100,
        "raster-domain-min": -1,
        "geojson-mask-source": MAP_IDS.countyFillLayerId,
        "raster-colorscale": {
          type: "interval",
          stops: [
            [
              0,
              "rgba(252, 102, 1, 0)"
            ],
            [
              60,
              "rgba(252, 102, 1, 1)"
            ],
            [
              75,
              "rgba(255, 198, 0, 1)"
            ],
            [
              85,
              "rgba(93, 250, 0, 1)"
            ],
            [
              100,
              "rgba(2, 172, 2, 1)"
            ]
          ],
          max: 100,
          min: -1,
        },
        ...this.getMaskConfig(),
      },
      source: `${mapAnalysis.id}-${this.props.cropType}`,
    };
    return analysisLayer;
  }

  getCurrentTypeSource = (): LercTileSource => {
    const { cropType } = this.props;
    return {
      id: `${mapAnalysis.id}-${this.props.cropType}`,
      type: "lerc_v2",
      maxzoom: 14,
      minzoom: 0,
      headers: {
        token: mapToken,
      },
      tiles: [mapAnalysis[`${cropType}_tiles`]],
    };
  }
  render() {
    const { colorPalette } = this.props;
    const colors: any[] = [
      {
        color: "rgba(252, 102, 1, 1)",
        key: "c0",
        show: true,
      },
      {
        color: "rgba(255, 198, 0, 1)",
        key: "c1",
        show: true,
      },
      {
        color: "rgba(93, 250, 0, 1)",
        key: "c2",
        show: true,
      },
      {
        color: "rgba(2, 172, 2, 1)",
        key: "c3",
        show: true,
      },
    ];
    return (
      <>
        {this.renderMaskLayers()}
        <Source<LercTileSource> {...this.getCurrentTypeSource()} />
        <Layer {...this.getAnalysisLayer()} before={MAP_IDS.townLineLayerId} />
        <ClassificationLegend
          colors={colors}
          style={{ position: "absolute", bottom: "16px", right: "16px" }}
          names={["不适宜", "次适宜", "较适宜", "极适宜"]}
          colorPalette={colorPalette}
        />
      </>
    );
  }
}
