import React, { Component, PureComponent } from "react";
import { RouteConfig, Icon } from "@gago/frame";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Source,
  RasterSource,
  RasterLayer,
  GeoJSONSource,
  Layer,
  FillLayer,
} from "@gago-react-gl/gago-react-gl";
import { uniqueId, noop } from "lodash";
import {
  GeojsonType,
  RegionTree1,
  ClassifySummary,
  NorthwestRegionPointInfoTypeBase,
  IntegratedQuery,
  PlantList,
  PlantListItem,
  IntegratedRequestType,
} from "../../models/germplasm-resource";
import { CropCategory } from "../../models/germplasm-database";
import {
  ExpertCateInfo,
  ExpertEditRequest,
} from "../../models/expert-database";
import { cateMap } from "./style";
import {
  Button,
  Select,
  Tabs,
  Cascader,
  Form,
  Table,
  Row,
  Col,
  Input,
  Divider,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { CascaderOptionType } from "antd/lib/cascader";
import { TabsProps } from "antd/lib/tabs";
import { ColumnProps, PaginationConfig } from "antd/lib/table";
import { ExpertDatabase } from "../expert-database/index";
import { Key } from "readline";
import Map, { Region } from "./map";
import { isCoor } from "../../utils";
import { ScrollTable } from "../../containers/scroll-table1";

// tslint:disable:jsx-no-multiline-js ter-max-len
const { Option, OptGroup } = Select;
const { TabPane } = Tabs;
const { Search } = Input;
const WrapForm = styled(Form)`
  /* padding: 16px 16px 0px; */
  flex-direction: column;
  justify-content: center;
  display: flex;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
  .searchBtn::after {
    content: "";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
  }
  .searchBtn {
    float: right;
  }
`;
interface Hprops extends BaseProps {
  // geojsonType: GeojsonType;
  germCate?: any[];
  classifySummary?: ClassifySummary[];
  expertList?: ExpertEditRequest[];
  integradeSummary?: any[];
  // type:number;
  handleChangeType(index: number): void;
  // 统计数据这显示省市区需要的地区名称
  areaName: string[];
  // 综合查询统计数据
  integratedData: IntegratedQuery[];
  // 查询明细列表
  plantList: PlantList;
  // 处理分页
  handlePagination(params: PaginationConfig): void;

  // 获取查询明细列表
  getPlantList(): void;
  // 用户查询明细
  handleSearchDetailSelf(params: string): void;
  /** 处理气泡变化 */
  handleChangeLocus(value: boolean): void;
  /** 改变气泡状态 */
  handleMapMarkerTypeChange(value: "statistics" | "locus"): void;
  /** 滚动表格点击事件 */
  tableClick(recordes: any): void;
}
interface S {
  leftVisiable: boolean;
  key: keyof DataCardTableTypes;
  // 数据展示 当前点击的那个按钮
  curIndex: number;
  integradeVisiable: boolean;

}
interface BaseProps {
  areaList?: RegionTree1[];
  cropCategory?: CropCategory[];
  expertSpecialty?: ExpertCateInfo[];
  type: number;
  // 一区多园基地
  parkBaseType: NorthwestRegionPointInfoTypeBase[];
  handleIntegratedRequest(params: IntegratedRequestType): void;
  // 地图交互需要字段
  parkType: string;
  district: string;
  baseType: string;
  areaName: string[];
  category: string;
  region: Region;
  // table 分页
  size?: number;
  page?: number;
}
type formP = FormComponentProps &
  BaseProps & {
    // cateMap对应的key
    // key:string;
  };
interface formS {
  selectedCrop: number;
  selectedExpert: string;
  crops: number[];
  experts: string[];
  // 所选省市是否存在核心区
  isExitDistrict: boolean;
}
enum SelectType {
  germresource = 1,
  bases,
  areaspecialty,
  expertdatabase,
}
enum DataCardSelectType {
  statistics,
  getDetail,
}
interface SelfFormItem {
  label: string;
  renderFunc(): {} | null | undefined;
}
interface Unit {
  germresource: string;
  bases: string;
  areaspecialty: string;
  expertdatabase: string;
}
interface InfoMap {
  germresource: {
    formItem: SelfFormItem[];
  };
  bases: {
    formItem: SelfFormItem[];
  };
  areaspecialty: {
    formItem: SelfFormItem[];
  };
  expertdatabase: {
    formItem: SelfFormItem[];
  };
}

interface DataCardTableTypes {
  germresource: {
    columns: ColumnProps<any>[];
    data: PlantListItem[];
    count: number;
  };
  bases: {
    columns: ColumnProps<any>[];
    data: PlantListItem[];
    count: number;
  };
  areaspecialty: {
    columns: ColumnProps<any>[];
    data: PlantListItem[];
    count: number;
  };
  expertdatabase: {
    columns: ColumnProps<any>[];
    data: PlantListItem[];
    count: number;
  };
}
interface Placeholder {
  placeholder: string;
}
interface PlaceholderMapTypes {
  germresource: Placeholder;
  bases: Placeholder;
  areaspecialty: Placeholder;
  expertdatabase: Placeholder;
}

interface DataCardChildrenTypes {
  statistics: {
    renderFunc(): {} | null | undefined;
  };
  getDetail: {
    renderFunc(): {} | null | undefined;
  };
}
interface DataCardMapTypes {
  germresource: DataCardChildrenTypes;
  bases: DataCardChildrenTypes;
  areaspecialty: DataCardChildrenTypes;
  expertdatabase: DataCardChildrenTypes;
}

/**
 * 巴盟卫星地图底图
 * @author 马俊峰
 * @date 2020-09-03
 * @export
 * @class BamengBaseMapLayer
 * @extends {PureComponent}
 */
class cateForm extends React.Component<formP, formS> {
  static defaultProps = {
    // key: 'germresource'
  };
  state: formS = {
    selectedCrop: -1,
    selectedExpert: "全部",
    crops: [-1],
    experts: ["全部"],
    isExitDistrict: true,
  };

  // render 区域
  renderArea = () => {
    const {
      areaList,
      form: { getFieldDecorator },
    } = this.props;
    if (!areaList || (areaList && areaList.length === 0)) {
      return null;
    }
    return getFieldDecorator(`areaName`, {
      initialValue: [areaList[0].name],
    })(
        <Cascader
          className={"mengcao-cascader"}
          onChange={this.handleDistrict}
          allowClear={false}
          fieldNames={{ label: "name", value: "name", children: "children" }}
          options={(areaList as unknown) as CascaderOptionType[]}
        />,
      );

  }

  /** render 作物分类 */
  renderCropCate = () => {
    const {
      cropCategory,
      form: { getFieldDecorator },
    } = this.props;
    if (!cropCategory || (cropCategory && cropCategory.length === 0)) {
      return null;
    }
    return getFieldDecorator(`cropCate`, {
      initialValue: [cropCategory[0].id],
      normalize: this.normalizeCrop,
    })(
        <Select
          className={"mengcao-select-multiple"}
          mode="multiple"
          allowClear={false}
          maxTagCount={1}
          dropdownMatchSelectWidth
          onBlur={this.handleSearch}
          // onChange={this.handleSearch}
          // onChange={this.handleChangeSelect}
          // onSelect = {this.changeSelectedCrop}
          // dropdownStyle={{backgroundColor:'#002a42',opacity:0.8,fontSize:'20px',color:'#e0feff',width:'467px'}}
          // dropdownMenuStyle={{backgroundColor:'#002a42',opacity:0.8,fontSize:'20px',color:'#e0feff'}}
        >
          {cropCategory.map((item) => {
            return (
              <Option key={item.name} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>,
      );

  }

  // render 一区多园类型
  renderParkType = () => {
    const {
      parkBaseType,
      form: { getFieldDecorator },
    } = this.props;
    if (!parkBaseType || (parkBaseType && parkBaseType.length === 0)) {
      return null;
    }
    const data = parkBaseType[0].children as NorthwestRegionPointInfoTypeBase[];
    const all: NorthwestRegionPointInfoTypeBase = {
      key: "全部",
      value: "all",
    };
    const allPark: NorthwestRegionPointInfoTypeBase = {
      key: "全部",
      value: "all_baseType_park",
    };
    const allBase: NorthwestRegionPointInfoTypeBase = {
      key: "全部",
      value: "all_baseType_base",
    };
    let park: NorthwestRegionPointInfoTypeBase[] = [allPark],
      base: NorthwestRegionPointInfoTypeBase[] = [allBase],
      district: NorthwestRegionPointInfoTypeBase[] = [];
    data.forEach((item) => {
      if (item.value === "baseType_park" && item.children) {
        park = [...park, ...item.children];
      } else if (item.value === "baseType_district") {
        district.push(item);
      } else if (item.value === "baseType_base" && item.children) {
        base = [...base, ...item.children];
      }
    });
    return getFieldDecorator("parkType", {
      initialValue: ["all"],
      normalize: this.normalizeParkType,
    })(
      <Select
        className={"mengcao-select-multiple"}
        mode="multiple"
        maxTagCount={1}
        allowClear={false}
        onBlur={this.handleSearch}
      >
        <OptGroup label="全部" key="全部">
          <Option key={all.key} value={all.value}>
            {all.key}
          </Option>
        </OptGroup>
        {/* <Option key="全部" value='全部'>全部</Option> */}
        <OptGroup label="核心区" key="核心区">
          <Option
            key={district[0].key}
            value={district[0].value}
            disabled={!this.state.isExitDistrict}
          >
            {district[0].key}
          </Option>
        </OptGroup>
        <OptGroup label="专项产业园" key="专项产业园">
          {park.map((item, index: number) => {
            return (
              <Option key={item.key + index} value={item.value}>
                {item.key}
              </Option>
            );
          })}
        </OptGroup>
        <OptGroup label="良种繁育基地" key="良种繁育基地">
          {base.map((item, index: number) => {
            return (
              <Option key={item.key + index} value={item.value}>
                {item.key}
              </Option>
            );
          })}
        </OptGroup>
      </Select>,
      // <Cascader options={parkBaseType[0].children} fieldNames={{label:'key',value:'value',children:'children'}}/>
    );
  }
  componentWillReceiveProps(nextProps: formP) {
    if (nextProps?.type !== this.props.type) {
      this.props.form.resetFields();
      this.setState({ isExitDistrict: true });
    }
  }

  componentDidUpdate(nextProps: formP) {
    const {
      form: { setFieldsValue },
      type,
      parkType,
      baseType,
      district,
      category,
      region,
      areaList,
    } = this.props;
    const {
      type: nType,
      parkType: nParkType,
      baseType: nBaseType,
      district: nDistrict,
      category: nCategory,
      region: nRegion,
    } = nextProps;
    if (
      type !== nType ||
      parkType !== nParkType ||
      baseType !== nBaseType ||
      district !== nDistrict ||
      category !== nCategory ||
      region !== nRegion
    ) {
      // console.log(region,nRegion,'9090909')
      this.resetFormValue();
    }
  }

  resetFormValue = () => {
    const {
      form: { setFieldsValue },
      type,
      parkType,
      baseType,
      district,
      category,
      region,
      areaList,
    } = this.props;

    let area: RegionTree1 | undefined = {} as RegionTree1;
    let areaName: string[] = [];
    const list: any[] = [];

    area = areaList?.find((item) => {
      if (item.code === region.code) {
        areaName = [item.name, "全部"];
        return true;
      }
      return item?.children?.find((city) => {
        if (city.code === region.code) {
          areaName = [item.name, city.name];
          return true;
        }
      });

    });
    if (region.code === undefined && region.name === undefined) {
      areaName = ["全部"];
    }
    if (!area) {
      areaName = ["全部"];
    }

    // if (region.level === 'province' && region.code === undefined && region.name === undefined) {
    //   areaName = ['全部']
    // } else if (region.level === 'city') {
    //   area = areaList?.find(item => item.code === region.code)
    //   areaName = [area?.name as string,'全部']
    // } else if (region.level === 'county' && region.code){
    //     let list:RegionTree[] = []
    //   if (areaList) {
    //     for (let item of areaList) {
    //       if (item.children && item.children.length !== 0 ) {
    //         list = [...list,...item.children]
    //       }
    //     }
    //   }
    //   area = list.find(item => item.code === region.code)

    //   areaName = [area?.parentName as string, area?.name as string]
    // }

    if (type === 1) {
      let cropCate: number[] = [];
      if (category && category !== "all") {
        cropCate = category.split(",").map(Number);
      } else {
        cropCate = [-1];
      }
      setFieldsValue({
        areaName,
        cropCate,
      });
    } else if (type === 2) {
      let parkBase: string[] = [];
      if (!district && !parkType && !baseType) {
        parkBase = ["all"];
      } else if (
        district === "all" &&
        parkType === "all" &&
        baseType === "all"
      ) {
        parkBase = ["all"];
      } else if (
        district !== "all" &&
        parkType === "all" &&
        baseType === "all"
      ) {
        parkBase = ["all_baseType_base", "all_baseType_park"];
      } else if (
        district === "all" &&
        parkType !== "all" &&
        baseType === "all"
      ) {
        const park = parkType.split(",");
        parkBase = ["baseType_district", ...park, "all_baseType_base"];
      } else if (
        district === "all" &&
        parkType === "all" &&
        baseType !== "all"
      ) {
        const base = baseType.split(",");
        parkBase = ["baseType_district", ...base, "all_baseType_park"];
      } else if (
        district !== "all" &&
        parkType !== "all" &&
        baseType === "all"
      ) {
        const park = parkType.split(",");
        parkBase = [...park, "all_baseType_base"];
      } else if (
        district !== "all" &&
        parkType === "all" &&
        baseType !== "all"
      ) {
        const base = baseType.split(",");
        parkBase = [...base, "all_baseType_park"];
      } else if (
        district === "all" &&
        parkType !== "all" &&
        baseType !== "all"
      ) {
        const park = parkType.split(",");
        const base = baseType.split(",");
        parkBase = ["baseType_district", ...base, ...park];
      } else {
        const park = parkType.split(",");
        const base = baseType.split(",");
        parkBase = [...base, ...park];
      }
      setFieldsValue({
        areaName,
        parkType: parkBase,
      });
    } else if (type === 3) {
      let cropCate: number[] = [];
      if (category && category !== "all") {
        cropCate = category.split(",").map(Number);
      } else {
        cropCate = [-1];
      }
      setFieldsValue({
        areaName,
        cropCate,
      });
    } else if (type === 4) {
      let expertSpecialty: string[] = [];
      if (category && category !== "all") {
        expertSpecialty = category.split(",");
      } else {
        expertSpecialty = ["全部"];
      }
      setFieldsValue({
        areaName,
        expertSpecialty,
      });
    }
  }

  // 处理核心区是否可选
  handleDistrict = (value: string[]) => {
    const { type } = this.props;
    if (type === 2) {
      if (
        (value.length === 1 && value.includes("全部")) ||
        value.join(",") === "内蒙古自治区,全部" ||
        value.join(",") === "内蒙古自治区,巴彦淖尔市"
      ) {
        this.setState(
          {
            isExitDistrict: true,
          },
          () => this.handleSearch(),
        );
      } else {
        this.setState(
          {
            isExitDistrict: false,
          },
          () => this.handleSearch(),
        );
      }
    } else {
      this.setState(
        {
          isExitDistrict: false,
        },
        () => this.handleSearch(),
      );
    }
  }

  normalizeParkType = (value: string[], preValue: string[]) => {
    let park: string[] = [],
      base: string[] = [],
      district: string[] = [],
      targetValue: string[] = [];
    const prePark: string[] = [],
      preBase: string[] = [],
      preDistrict: string[] = [];

    if (preValue.includes("all") && value.includes("all")) {
      targetValue =
        value.length <= 1 ? value : value.filter((item) => item !== "all");
    } else if (value.includes("all") && !preValue.includes("all")) {
      targetValue = ["all"];
    } else {
      value.forEach((item) => {
        const typeArray = item.split("_");
        const realType = typeArray[typeArray.length - 1];
        if (realType === "park") {
          park.push(item);
        } else if (realType === "base") {
          base.push(item);
        } else if (realType === "district") {
          district.push(item);
        }
      });
      preValue.forEach((item) => {
        const typeArray = item.split("_");
        const realType = typeArray[typeArray.length - 1];
        if (realType === "park") {
          prePark.push(item);
        } else if (realType === "base") {
          preBase.push(item);
        } else if (realType === "district") {
          preDistrict.push(item);
        }
      });
      if (
        park.includes("all_baseType_park") &&
        prePark.includes("all_baseType_park")
      ) {
        park =
          park.length <= 1
            ? park
            : park.filter((item) => item !== "all_baseType_park");
      } else if (
        park.includes("all_baseType_park") &&
        !prePark.includes("all_baseType_park")
      ) {
        park = ["all_baseType_park"];
      }
      if (
        base.includes("all_baseType_base") &&
        preBase.includes("all_baseType_base")
      ) {
        base =
          base.length <= 1
            ? base
            : base.filter((item) => item !== "all_baseType_base");
      } else if (
        base.includes("all_baseType_base") &&
        !preBase.includes("all_baseType_base")
      ) {
        base = ["all_baseType_base"];
      }
      targetValue = [...base, ...district, ...park];
    }
    return targetValue;
  }

  normalizeCrop = (value: number[], preValue: number[]) => {
    let targetValue: number[] = [];
    if (preValue.includes(-1) && value.includes(-1)) {
      targetValue =
        value.length <= 1 ? value : value.filter((item) => item !== -1);
    } else if (value.includes(-1) && !preValue.includes(-1)) {
      targetValue = [-1];
    } else {
      targetValue = value;
    }
    return targetValue;
  }

  normalizeExpert = (value: string[], preValue: string[]) => {
    let targetValue: string[] = [];
    if (preValue.includes("全部") && value.includes("全部")) {
      targetValue =
        value.length <= 1 ? value : value.filter((item) => item !== "全部");
    } else if (value.includes("全部") && !preValue.includes("全部")) {
      targetValue = ["全部"];
    } else {
      targetValue = value;
    }
    return targetValue;
  }
  // 处理事情
  // handleChangeSelect = (value:any[],option:any) => {
  //   console.log(value,'onchange')
  //   const {form:{setFieldsValue} } = this.props
  //   let { selectedCrop,crops } = this.state;
  //   const isExitAll = crops.includes(-1);
  //   const isNextAll = value.includes(-1)

  //   crops = [-1]
  //   this.setState({
  //     crops:value
  //   })
  //   setFieldsValue({areaName:['内蒙古自治区','全部']})

  // }

  changeSelectedCrop = (value: number) => {
    this.setState({ selectedCrop: value });
  }
  // 搜索
  handleSearch = () => {
    const {
      type,
      form: { validateFields },
      handleIntegratedRequest,
    } = this.props;
    validateFields((err, values) => {
      handleIntegratedRequest(values);
    });
  }

  // render 专家特长
  renderExpert = () => {
    const {
      expertSpecialty,
      form: { getFieldDecorator },
    } = this.props;
    if (!expertSpecialty || (expertSpecialty && expertSpecialty.length === 0)) {
      return null;
    }
    return getFieldDecorator(`expertSpecialty`, {
      initialValue: [expertSpecialty[0].name],
      normalize: this.normalizeExpert,
    })(
        <Select
          className={"mengcao-select-multiple"}
          mode="multiple"
          maxTagCount={1}
          allowClear={false}
          onBlur={this.handleSearch}
        >
          {expertSpecialty.map((item) => {
            return (
              <Option key={item.name} value={item.name}>
                {item.name}
              </Option>
            );
          })}
        </Select>,
      );

  }

  infoMap = {
    // 种质资源
    germresource: {
      formItem: [
        {
          label: "区域选择",
          renderFunc: this.renderArea.bind(this),
        },
        {
          label: "种质分类",
          renderFunc: this.renderCropCate.bind(this),
        },
      ],
    },
    // 多基地
    bases: {
      formItem: [
        {
          label: "区域选择",
          renderFunc: this.renderArea.bind(this),
        },
        {
          label: "园区类型",
          renderFunc: this.renderParkType.bind(this),
        },
      ],
    },
    // 区域特色
    areaspecialty: {
      formItem: [
        {
          label: "区域选择",
          renderFunc: this.renderArea.bind(this),
        },
        {
          label: "种质分类",
          renderFunc: this.renderCropCate.bind(this),
        },
      ],
    },
    // 专家智库
    expertdatabase: {
      formItem: [
        {
          label: "区域选择",
          renderFunc: this.renderArea.bind(this),
        },
        {
          label: "专家特长",
          renderFunc: this.renderExpert.bind(this),
        },
      ],
    },
  } as InfoMap;

  render() {
    const {
      type,
      areaList,
      form: { getFieldDecorator },
    } = this.props;
    type keys = keyof InfoMap;
    const key = SelectType[type];
    console.log(key, type);

    return (
      <>
        <WrapForm>
          {this.infoMap[key as keys].formItem?.map((item, ind: number) => {
            return (
              <>
                {/* <Row gutter={24}> */}
                {/* <Col span={12}> */}
                <Form.Item key={ind} label={item.label}>
                  {item.renderFunc()}
                </Form.Item>
                {/* </Col> */}
                {/* </Row> */}
              </>
            );
          })}

          {/* <Button className="searchBtn" htmlType="submit">确定</Button> */}
        </WrapForm>
      </>
    );
  }
}

const SearchForm = Form.create<formP>({ name: "cate_form" })(cateForm);

// tslint:disable-next-line:max-classes-per-file
export class LeftCard extends PureComponent<Hprops, S> {
  /** 唯一id */
  defaultProps = {
    type: 1,
  };
  constructor(props: Hprops) {
    super(props);
    this.state = {
      leftVisiable: false,
      key: "germresource",
      // cardIndex:0,
      curIndex: 0,
      integradeVisiable: false,
    };
  }

  DataCardTable = ({
    germresource: {
      columns: [
        {
          title: "种质编号",
          ellipsis: true,
          dataIndex: "accessionNumber",
          key: "accessionNumber",
        },
        {
          title: "种质名称",
          dataIndex: "name",
          key: "name",
          ellipsis: true,
        },
        {
          title: "种质分类",
          ellipsis: true,
          dataIndex: "type",
          key: "type",
        },
        {
          title: "种质来源地",
          ellipsis: true,
          dataIndex: "address",
          key: "address",
        },
      ],
      data: this.props.plantList.list,
      count: this.props.plantList.count,
    },
    bases: {
      columns: [
        {
          title: "基地/产业园名称",
          ellipsis: true,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "基地产业园分类",
          ellipsis: true,
          dataIndex: "type",
          key: "type",
        },
        {
          title: "地区",
          ellipsis: true,
          dataIndex: "address",
          key: "address",
        },
      ],
      data: this.props.plantList.list,
      count: this.props.plantList.count,
    },
    areaspecialty: {
      columns: [
        {
          title: "种质编号",
          ellipsis: true,
          dataIndex: "accessionNumber",
          key: "accessionNumber",
        },
        {
          title: "种质分类",
          ellipsis: true,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "种质名称",
          ellipsis: true,
          dataIndex: "type",
          key: "type",
        },
        {
          ellipsis: true,
          title: "种质来源地",
          dataIndex: "address",
          key: "address",
        },
      ],
      data: this.props.plantList.list,
      count: this.props.plantList.count,
    },
    expertdatabase: {
      columns: [
        {
          ellipsis: true,
          title: "专家姓名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "专家特长",
          ellipsis: true,
          dataIndex: "type",
          key: "type",
        },
        // {
        //   title: "归属地",
        //   ellipsis: true,
        //   dataIndex: "address",
        //   key: "address",
        // },
      ],
      data: this.props.plantList.list,
      count: this.props.plantList.count,
    },
  } as unknown) as DataCardTableTypes;
  // uniqueId = uniqueId("district_map_source_");
  leftVisiableClick = () => {
    const { leftVisiable } = this.state;
    this.setState({
      leftVisiable: !leftVisiable,
    });
  }

  renderItem = (current: any, type: any, originEle: any) => {
    return originEle;
  }

  renderDataCard1 = () => {
    const { integratedData, type } = this.props;
    const unit = {
      germresource: "份",
      bases: "个",
      areaspecialty: "种",
      expertdatabase: "人",
    };
    if (!integratedData || (integratedData && integratedData.length === 0)) {
      return null;
    }
    return (
      <>
        <ul className="summary">
          {integratedData &&
            integratedData.length !== 0 &&
            integratedData.map((item: any, index: number) => {
              if (index === 0) {
                return (
                  <>
                    <li className="summaryItem1">
                      <span className="summaryItemName1">{item.name}:</span>{" "}
                      <span className="summaryItemCount1">
                        {item.count}
                        {unit[SelectType[type] as keyof Unit]}
                      </span>
                    </li>
                    <Divider
                      style={{ opacity: 0.5, minWidth: "40%", width: "60%" }}
                    />
                  </>
                );
              }
              return (
                  <li className="summaryItem">
                    <span className="summaryItemName">{item.name}:</span>{" "}
                    <span className="summaryItemCount">
                      {item.count}
                      {unit[SelectType[type] as keyof Unit]}
                    </span>
                  </li>
              );

            })}
        </ul>
      </>
    );
  }

  renderDataCard2 = () => {
    // const { key } = this.state;
    const { type, handlePagination, integratedData, page, size } = this.props;
    const key: keyof DataCardTableTypes = (SelectType[type
] as unknown) as keyof DataCardTableTypes;
    const data = this.props.plantList.list;
    const columns = this.DataCardTable[key].columns;
    const count = this.props.plantList.count;
    console.log("****count", count);

    const detailVisable = integratedData.length > 1 ? true : false;
    if (!detailVisable) {
      return null;
    }
    const h = window.document.documentElement.offsetHeight - 545;

    return (
      <>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: "100%", y: "240px" }}
          onChange={handlePagination}
          onRow={(record: PlantListItem) => {
            return {
              onClick: () => {
                if (isCoor([record.lon, record.lat])) {
                  window._map &&
                    window._map.flyTo({
                      center: [record.lon, record.lat],
                      zoom: 14,
                    });
                }
              },
            };
          }}
          pagination={{
            showQuickJumper: true,
            onShowSizeChange: noop,
            // showSizeChanger: true,
            total: count ? count : undefined,
            showLessItems: true,
            itemRender: this.renderItem,
            current: page,
            pageSize: size,
            // size:'small'

            // tslint:disable-next-line: jsx-alignment
          }}
        />
      </>
    );
  }

  renderDataCard3 = () => {
    // const { key } = this.state;
    const { type, handlePagination, integratedData } = this.props;
    const key: keyof DataCardTableTypes = (SelectType[type
] as unknown) as keyof DataCardTableTypes;
    const data = this.props.plantList.list;
    const columns = this.DataCardTable[key].columns;
    const count = this.props.plantList.count;
    const detailVisable = integratedData.length > 1 ? true : false;
    if (!detailVisable) {
      return null;
    }
    const h = window.document.documentElement.offsetHeight - 545;
    console.log("lbcolumns", data);
    const heads = columns.map(item => item.title);
    const data1 = data.map(item => [item.name, item.type]);
    const newData = data.map(item => [item.id, item.name, item.type]);
    const tableData: any = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0],
      range: [[], [], []],
      head: [...heads],
      suffix: ["", "", "", "", ""],
      // data:[],
      data: data1,
    };
    return (
      <>
      <div style={{ height: "200px" }}>
      <ScrollTable
        scrollTime={200}
        scrollHeight={"330px"}
        style={{ height: "100%", width: "100%" }}
        tableData={tableData}
        handleClick={this.props.tableClick}
      />
      </div>
      </>
    );
  }

  // tslint:disable-next-line:no-object-literal-type-assertion
  dataCardMap = {
    // 种质资源
    germresource: {
      statistics: {
        renderFunc: this.renderDataCard1.bind(this),
      },
      getDetail: {
        renderFunc: this.renderDataCard2.bind(this),
      },
    },
    // 多基地
    bases: {
      statistics: {
        renderFunc: this.renderDataCard1.bind(this),
      },
      getDetail: {
        renderFunc: this.renderDataCard2.bind(this),
      },
    },
    // 区域特色
    areaspecialty: {
      statistics: {
        renderFunc: this.renderDataCard1.bind(this),
      },
      getDetail: {
        renderFunc: this.renderDataCard2.bind(this),
      },
    },
    // 专家智库
    expertdatabase: {
      statistics: {
        renderFunc: this.renderDataCard1.bind(this),
      },
      getDetail: {
        renderFunc: this.renderDataCard3.bind(this),
      },
    },
  } as DataCardMapTypes;
  placeholderMap = {
    // 种质资源
    germresource: {
      placeholder: "请输入种质编号查询",
    },
    // 多基地
    bases: {
      placeholder: "请输入基地名称查询",
    },
    // 区域特色
    areaspecialty: {
      placeholder: "请输入种质编号查询",
    },
    // 专家智库
    expertdatabase: {
      placeholder: "请输入专家姓名查询",
    },
  } as PlaceholderMapTypes;
  handleChangeActive = (index: number) => {
    const {
      handleChangeType,
      handleChangeLocus,
      handleMapMarkerTypeChange,
    } = this.props;
    this.setState({
      curIndex: 0,
    });
    handleChangeType(index);
    if (index === this.props.type - 1) {
      return;
    }
    handleChangeLocus(false);
    handleMapMarkerTypeChange("statistics");
  }
  handleDataCardActive = (index: number) => {
    const { getPlantList } = this.props;
    if (index) {
      getPlantList();
    }
    this.setState({
      curIndex: index,
    });
  }

  handleDetailSelf = (value: any) => {
    // const {value} = e.target;
    const { handleSearchDetailSelf } = this.props;
    handleSearchDetailSelf(value);
  }

  handleIntegradeVisiable = () => {
    const { integradeVisiable } = this.state;
    this.setState({
      integradeVisiable: !integradeVisiable,
    });
  }
  render() {
    const { leftVisiable, curIndex, integradeVisiable } = this.state;
    const {
      areaList,
      cropCategory,
      integradeSummary,
      type,
      areaName,
      parkBaseType,
      expertSpecialty,
      handleIntegratedRequest,
      parkType,
      baseType,
      district,
      category,
      region,
      integratedData,
    } = this.props;
    const buttonList = [
      "种质资源",
      "一区多园N基地",
      "区域特色种质",
      "专家智库",
    ];
    const dataCardButtonList = ["统计数据", "查询明细"];
    const key = SelectType[type] as keyof DataCardMapTypes;
    const areaInfo: string = areaName.join("") || "";
    // const detailVisable = integratedData.length > 0 && integradeVisiable ? true :false ;
    const detailVisable = integratedData.length > 1 ? true : false;
    return (
      <>
        <div
          className="left-search"
          style={{
            height: "233px",
            display: "flex",
            width: "490px",
            justifyContent: "space-between",
          }}
        >
          <div className="ls-title" style={{ height: "100%", padding: 0 }}>
            <div
              className="lst-left"
              style={{
                flexDirection: "column",
                height: "100%",
                alignItems: "flex-start",
                width: "auto",
              }}
            >
              {buttonList.map((item: string, index: number) => (
                <Button
                  className={
                    type - 1 === index ? "info-btn-active" : "info-btn"
                  }
                  onClick={() => this.handleChangeActive(index)}
                >
                  {item}
                </Button>
              ))}
              {/* <Button className='info-btn'>种质资源</Button>
                  <Button className='info-btn'>一区多园N基地</Button>
                  <Button className='info-btn'>区域特色种质</Button>
                  <Button className='info-btn'>专家智库</Button> */}
            </div>
            {/* <span className="lst-right" onClick={this.leftVisiableClick}>
                  <Icon>{leftVisiable ? "double-down-single" : "double-up-single"}</Icon>
                </span> */}
          </div>
          <div
            className="bottom-line"
            style={{
              width: "1px",
              height: "100%",
              backgroundColor: "rgba(0, 221, 255, 0.35)",
            }}
          />
          <SearchForm
            areaList={areaList}
            cropCategory={cropCategory}
            type={type}
            parkBaseType={parkBaseType}
            expertSpecialty={expertSpecialty}
            category={category}
            baseType={baseType}
            district={district}
            areaName={areaName}
            parkType={parkType}
            handleIntegratedRequest={handleIntegratedRequest}
            region={region}
          />
        </div>

        <div
          className="left-search"
          style={{ top: "249px", height: "504px", width: "490px" }}
        >
          <div className="ls-title">
            <div
              className="lst-left"
              style={{ justifyContent: "space-between", width: "232px" }}
            >
              {dataCardButtonList.map((item: string, index: number) => (
                <Button
                  style={{ width: "auto" }}
                  className={
                    index === curIndex ? "info-btn-active" : "info-btn"
                  }
                  onClick={() => this.handleDataCardActive(index)}
                >
                  {item}
                </Button>
              ))}
              {/* <Button className='info-btn'>统计数据</Button>
                  <Button className='info-btn'>查询明细</Button> */}
            </div>
            <span className="lst-right" onClick={this.handleIntegradeVisiable}>
              {/* <Icon>{integradeVisiable ? "double-down-single" : "double-up-single"}</Icon> */}
            </span>
          </div>
          <div className="bottom-line" />
          {!Boolean(curIndex) && <div className="cateTitle">{areaInfo}</div>}
          {Boolean(curIndex) && detailVisable && (
            <Search
              className="DetailSearch"
              style={{}}
              placeholder={this.placeholderMap[key].placeholder}
              onSearch={this.handleDetailSelf}
            />
          )}
          {this.dataCardMap[key][
            DataCardSelectType[curIndex] as keyof DataCardChildrenTypes
].renderFunc()}

        </div>
      </>
    );
  }
}
