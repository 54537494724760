import { LngLatBoundsLike } from "@gago-react-gl/gago-react-gl";

/** 地图 id 集合 */
export const MAP_IDS = {
  /* 给layer排序的层级 */
  zIndex0: "z-index-0",
  zIndex1: "z-index-1",
  zIndex2: "z-index-2",
  zIndex3: "z-index-3",
  zIndex4: "z-index-4",
};


