import { ITable } from "@gago/frame";
import { ResultType } from "../../models/common";
import { 
  BaseRequest,
  WeekDisease,
  DiseaseWarning,
  DiseaseWarningRequest,
  Phenophase,
  DiseaseMeasure,
  DiseaseMeasureRequest
} from "../../models/disease-analysis";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";


/**
 * 繁育气候
 * @author wangchengbing
 * @date 2021-1-12
 * @export
 * @class 
 * @extends {BaseProvider}
 */
export default class DiseaseAnalysisProvider extends BaseProvider {
  /** 未来七天风险指数  */
  static async getWeekDisease(params:BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<WeekDisease[]>>("/plant/breed/disease/future7", { params });
    return data; 
  }
  /** 各旗县各区风险等级比较*/
  static async getDiseaseWarning(params:DiseaseWarningRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<DiseaseWarning[]>>("/plant/breed/disease/diseaseWarning", { params });
    return data;
  }
   /** 作物理论物候期 */
   static async getPhenophase() {
    const { data } = await BaseRequestStaticServer.get<ResultType<Phenophase[]>>("/plant/breed/cropPeriod", { });
    return data;
  }
    /** 各旗县各区风险措施*/
    static async getDiseaseMeasure(params:DiseaseMeasureRequest) {
      const { data } = await BaseRequestStaticServer.get<ResultType<DiseaseMeasure>>("/plant/breed/disease/measure", { params });
      return data;
    }

    /** 获取lerc数据起报时间*/
    static async getStartDate() {
      const { data } = await BaseRequestStaticServer.get<ResultType<string[]>>("/plant/breed/disease/dailylist", { });
      return data;
    }
}
