export const serverConfig = {
  api_v1: window.config.publisher,
  api_v2: window.config.publisher1,
  api_v3: window.config.publisher2,
  /** 溯源模块地址 */
  api_sy_v1: window.config.api_sy_v1,
  /** 地块模块地址 */
  api_land_v1: window.config.api_land_v1,
  /** 系统页面请求token */
  apiToken: sessionStorage.getItem("token") ? sessionStorage.getItem("token") : "empty_token", publisher2: undefined
};

/** 设置token */
export const updateServerConfigApiToken = (token: string) => {
  serverConfig.apiToken = token;
};
