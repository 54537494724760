import {BaseServer} from "./base.server";
import {AxiosRequestConfig} from "axios";

/**
 * 业务Server，去除了业务中最外层data
 *
 * @author 张卓诚
 * @date 2018-12-14
 * @export
 * @class Business
 * @extends {BaseServer}
 */
export class Business extends BaseServer {

    /**
     * get
     *
     * @author 张卓诚
     * @template T
     * @param {string} url
     * @param {AxiosRequestConfig} [setting]
     * @returns
     * @memberof Business
     */
    public async get<T>(url: string, setting?: AxiosRequestConfig) {
        return super.get<{ data: T }>(url, {...setting, baseURL: this.domian})
            .then(
                res => {
                    return res.data;
                },
            );
    }

    /**
     * get
     *
     * @author 张卓诚
     * @template T
     * @param {string} url
     * @param {AxiosRequestConfig} [setting]
     * @returns
     * @memberof Business
     */
    public async get1<T>(url: string, setting?: AxiosRequestConfig) {
        return super.get<T>(url, {...setting, baseURL: this.domian})
    }

    /**
     * post
     *
     * @author 张卓诚
     * @template T
     * @template P
     * @param {string} url
     * @param {P} data
     * @param {AxiosRequestConfig} [setting]
     * @returns
     * @memberof Business
     */
    public async post<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        return super.post<{ data: T }, P>(url, data, {...setting, baseURL: this.domian})
            .then(
                res => {
                    return res.data;
                },
            );
    }

    public async post1<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        return super.post<{ data: T }, P>(url, data, {...setting, baseURL: this.domian})
            .then(
                res => {
                    return res;
                },
            );
    }

    /**
     * put
     *
     * @author 张卓诚
     * @template T
     * @template P
     * @param {string} url
     * @param {P} data
     * @param {AxiosRequestConfig} [setting]
     * @returns
     * @memberof Business
     */
    public async put<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        return super.put<{ data: T }, P>(url, data, {...setting, baseURL: this.domian})
            .then(
                res => {
                    return res.data;
                },
            );
    }

    public async put1<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        return super.put<{ data: T }, P>(url, data, {...setting, baseURL: this.domian})
            .then(
                res => {
                    return res;
                },
            );
    }

    public async downloadFile<T>(url: string, fileName?: string, contentType?: string, setting?: AxiosRequestConfig) {
        return super.download(url, {...setting, baseURL: this.domian})
            .then(
                res => {
                    // const { data } = res;
                    const blob = new Blob([res], {type: contentType ? contentType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});

                    const href = window.URL.createObjectURL(blob); // 创建下载的链接

                    const aEle = document.createElement("a");
                    aEle.href = href;
                    aEle.download = fileName ? fileName : "测试.xlsx";  // 下载后文件名
                    document.body.appendChild(aEle);
                    aEle.click();     // 点击下载
                    document.body.removeChild(aEle); // 下载完成移除元素
                    window.URL.revokeObjectURL(href); // 释放掉blob对象
                },
            );
    }

    /**
     * delete
     *
     * @author 张卓诚
     * @param {string} url
     * @param {AxiosRequestConfig} [setting]
     * @returns
     * @memberof Business
     */
    public async delete(url: string, setting ?: AxiosRequestConfig) {
        return super.delete(url, {...setting, baseURL: this.domian});
    }
}
