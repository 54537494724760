import React, { Component } from "react";
import QRCode from "qrcode.react";
import { BigScreenBody } from "./style";
import { colorPalette } from "../../color-palette";
import { Input } from "antd";
interface Bstate {
  /** 二维码地址 */
  rqcodeUrl: string;
}
/**
 * 二维码检测
 * @author 马俊峰
 * @date 2020-07-21
 * @class BigScreen
 * @extends {Component}
 */
export class RqCode extends Component<{}, Bstate> {
  state: Bstate = {
    rqcodeUrl: `
    天赋河套编号:ZF001-2020-001,
    种子中文名:巴麦15号,
    采集时间:2019/7/20,
    采集地点:农科院小麦研究所,
    入库时间:2019/8/27,
    采集人:张瑞强,
    所属单位:兆丰农业;
    `,
  };
  render() {
    return(
      <BigScreenBody colorPalette={colorPalette}>
        <div className="right-recode">
          <QRCode
            // value={`http://tfht.mengcao.com/tracing-source/${this.state.rqcodeUrl}`}
            // value={`http://mc-bameng.fm-dev.gagogroup.cn:31652/tracing-source/${this.state.rqcodeUrl}`}
            value={this.state.rqcodeUrl}
            size={400}
            fgColor="#000000"
            level="M"
          />
        </div>
        <div className="left-rqcode">
          <Input.TextArea
            placeholder="输入地块id"
            allowClear
            onPressEnter={this.selectRqcodeAreaClick}
            autoSize={{ minRows: 5 }}
          />
          {/* <Input.Search
            placeholder="输入地块id"
            addonBefore="http://tfht.mengcao.com/tracing-source/"
            onSearch={this.selectRqcodeClick}
            enterButton
          /> */}
        </div>
      </BigScreenBody>
    );
  }
  /** 确定二维码事件 */
  selectRqcodeClick = (value: string) => {
    this.setState({ rqcodeUrl: value });
  }
  /** 区域 */
  selectRqcodeAreaClick = (event: any) => {
    const val = event.target.value;
    console.log("***", val);
    this.setState({ rqcodeUrl: val });
  }
}
