import { tuple } from "../../util/type";
import { SliderValue } from "antd/lib/slider";
import { serverConfig } from '../../network/serverConfig';
import { uniqueId } from "lodash";
/**
 * 数字茶园-适宜性评价-智能分析地图:配置常量
 * @author luyaxing
 * @date 2019.6.20
 */

/** 地图用到的token */
// tslint:disable-next-line:ter-max-len
export const mapToken = serverConfig.appToken;

export const handleMaskAndSlider = (cropType?: "corn" | "wheat" | "sunflower") => {
  if (cropType === "corn") {
    return {
      maskConfig: maskConfig1,
      sliderList: sliderList1,
    }
  }
  if (cropType === "wheat") {
    return {
      maskConfig: maskConfig2,
      sliderList: sliderList2,
    }
  }
  if (cropType === "sunflower") {
    return {
      maskConfig: maskConfig3,
      sliderList: sliderList3,
    }
  }
  return {
    maskConfig: maskConfig1,
    sliderList: sliderList1,
  }
}

/**

/** 玉米list:               
 * 土壤平均值 土壤质量评分 河流距离 海拔高度  ≥10℃积温(℃ d) 8月平均最高温度(℃)  5月平均最低温度(℃) 年累计降水量(mm) */
export const sliderList1 = tuple("soilPh", "soilType", "river", "altitude", "accumulated", "augustT", "mayT", "rain");
export type SliderList1 = (typeof sliderList1)[number];

/** 小麦list:               
 * 土壤平均值 土壤质量评分 河流距离 海拔高度  ≥0℃积温(℃ d)  最冷月均温(℃) 年平均温度(℃)  年累计降水量(mm) */
export const sliderList2 = tuple("soilPh", "soilType", "river", "altitude", "accumulated", "cold", "temperature", "rain");
export type SliderList2 = (typeof sliderList2)[number];

/** 向日葵list:               
 * 土壤平均值 土壤质量评分 河流距离 海拔高度  ≥10℃积温(℃ d) 8月累积降水量  5月平均最低温度(℃) 年平均温度 */
export const sliderList3 = tuple("soilPh", "soilType", "river", "altitude", "accumulated", "augustRain", "mayT", "temperature");
/** slider用到的接口 */
export type SliderList3 = (typeof sliderList3)[number];


export interface SliderConfig {
  /** 图标 */
  icon: string;
  /** 标题 */
  title: string;
  /** 单位 */
  unit: string;
  /** 最大值 */
  max: number;
  /** 最小值 */
  min: number;
  /** 步长 */
  step?: number;
  /** 初始化范围 */
  defaultValue: SliderValue;
}

/** 地图 mask 的配置 */
export interface MapConfig {
  /** source 和 layer 的 id*/
  id: string;
  /** 瓦片路径 */
  [tiles: string]: string;
}

/** 配置 */
export interface Config {
  /** slider配置 */
  sliderConfig: SliderConfig;
  /** 地图配置 */
  mapConfig: MapConfig;
}

/** 玉米config */
export const maskConfig1: {[Key in SliderList1]: Config} = {
  soilPh: {
    sliderConfig: {
      icon: "high-temperature",
      title: "土壤PH值",
      unit: "",
      max: 9.5,
      min: 8,
      defaultValue: [8,9.5],
    },
    mapConfig: {
      id: uniqueId("corn") + "map-analysis-soilPh-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_ph/20201211/{z}/{x}/{y}/lerc",
    },
  },
  soilType: {
    sliderConfig: {
      icon: "high-temperature",
      title: "土壤质量评分",
      unit: "",
      max:100,
      min:0,
      defaultValue: [0,100],
    },
    mapConfig: {
      id: uniqueId("corn") +  "map-analysis-soilType-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_earthclass/20201212/{z}/{x}/{y}/lerc",
    },
  },
  river: {
    sliderConfig: {
      icon: "high-temperature",
      title: "河流距离",
      unit: "km",
      max: 17.98,
      min: 0,
      defaultValue: [0, 17.98],
    },
    mapConfig: {
      id: uniqueId("corn") +  "map-analysis-river-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_earth/20201208/{z}/{x}/{y}/lerc",
    },
  },
  altitude: {
    sliderConfig: {
      icon: "high-temperature",
      title: "海拔高度",
      unit: "m",
      max: 2229,
      min: 843,
      defaultValue: [843, 2229],
    },
    mapConfig: {
      id: uniqueId("corn") +  "map-analysis-altitude-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_attitude/20201209/{z}/{x}/{y}/lerc",
    },
  },
  accumulated: {
    sliderConfig: {
      icon: "high-temperature",
      title: "≥10℃积温",
      unit: "℃ d",
      max: 4292.57,
      min: 2594.4,
      defaultValue: [2594.4, 4292.57],
    },
    mapConfig: {
      id: uniqueId("corn") +  "map-analysis-accumulated-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_32_-9999/20201202/{z}/{x}/{y}/lerc",
    },
  },
  augustT: {
    sliderConfig: {
      icon: "high-temperature",
      title: "8月平均最高温度",
      unit: "℃",
      max: 32.14,
      min: 24.78,
      defaultValue: [24.78, 32.14],
    },
    mapConfig: {
      id: uniqueId("corn") +  "map-analysis-augustT-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_8temp/20201214/{z}/{x}/{y}/lerc",
    },
  },
  mayT: {
    sliderConfig: {
      icon: "high-temperature",
      title: "5月平均最低温度",
      unit: "℃",
      max: 13.01,
      min: 6.75,
      defaultValue: [6.75, 13.01],
    },
    mapConfig: {
      id: uniqueId("corn") +  "map-analysis-mayT-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_temp/20201203/{z}/{x}/{y}/lerc",
    },
  },
  rain: {
    sliderConfig: {
      icon: "high-temperature",
      title: "年累计降水量",
      unit: "mm",
      max: 332,
      min: 104,
      defaultValue: [104, 332],
    },
    mapConfig: {
      id: uniqueId("corn") +  "map-analysis-rain-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_rain/20201210/{z}/{x}/{y}/lerc",
    },
  },
};

/** 小麦config */
export const maskConfig2: {[Key in SliderList2]: Config} = {
  soilPh: {
    sliderConfig: {
      icon: "high-temperature",
      title: "土壤PH值",
      unit: "",
      max: 9.5,
      min: 8,
      defaultValue: [8,9.5],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-soilPh-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_ph/20201211/{z}/{x}/{y}/lerc",
    },
  },
  soilType: {
    sliderConfig: {
      icon: "high-temperature",
      title: "土壤质量评分",
      unit: "",
      max:100,
      min:0,
      defaultValue: [0,100],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-soilType-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_earthclass/20201212/{z}/{x}/{y}/lerc",
    },
  },
  river: {
    sliderConfig: {
      icon: "high-temperature",
      title: "河流距离",
      unit: "km",
      max: 17.98,
      min: 0,
      defaultValue: [0, 17.98],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-river-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_earth/20201208/{z}/{x}/{y}/lerc",
    },
  },
  altitude: {
    sliderConfig: {
      icon: "high-temperature",
      title: "海拔高度",
      unit: "m",
      max: 2229,
      min: 843,
      defaultValue: [843, 2229],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-altitude-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_attitude/20201209/{z}/{x}/{y}/lerc",
    },
  },
  accumulated: {
    sliderConfig: {
      icon: "high-temperature",
      title: "≥0℃积温",
      unit: "℃ d",
      max: 4292.57,
      min: 2594.4,
      defaultValue: [2594.4, 4292.57],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-accumulated-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_32_-9999/20201201/{z}/{x}/{y}/lerc",
    },
  },
  cold: {
    sliderConfig: {
      icon: "high-temperature",
      title: "最冷月均温",
      unit: "℃",
      max: 9.09,
      min: 2.94,
      defaultValue: [2.94, 9.09],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-augustT-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_temp/20201204/{z}/{x}/{y}/lerc",
    },
  },
  temperature: {
    sliderConfig: {
      icon: "high-temperature",
      title: "年平均温度(℃)",
      unit: "℃",
      max: 10.23,
      min: 4.17,
      defaultValue: [4.17, 10.23],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-mayT-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_temp/20201205/{z}/{x}/{y}/lerc",
    },
  },
  rain: {
    sliderConfig: {
      icon: "high-temperature",
      title: "年累计降水量",
      unit: "mm",
      max: 332,
      min: 104,
      defaultValue: [104, 332],
    },
    mapConfig: {
      id: uniqueId("wheat") +  "map-analysis-rain-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_rain/20201210/{z}/{x}/{y}/lerc",
    },
  },
};

/** 向日葵config */
export const maskConfig3: {[Key in SliderList3]: Config} = {
  soilPh: {
    sliderConfig: {
      icon: "high-temperature",
      title: "土壤PH值",
      unit: "",
      max: 9.5,
      min: 8,
      defaultValue: [8,9.5],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-soilPh-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_ph/20201211/{z}/{x}/{y}/lerc",
    },
  },
  soilType: {
    sliderConfig: {
      icon: "high-temperature",
      title: "土壤质量评分",
      unit: "",
      max:100,
      min:0,
      defaultValue: [0,100],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-soilType-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_earthclass/20201212/{z}/{x}/{y}/lerc",
    },
  },
  river: {
    sliderConfig: {
      icon: "high-temperature",
      title: "河流距离",
      unit: "km",
      max: 17.98,
      min: 0,
      defaultValue: [0, 17.98],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-river-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_earth/20201208/{z}/{x}/{y}/lerc",
    },
  },
  altitude: {
    sliderConfig: {
      icon: "high-temperature",
      title: "海拔高度",
      unit: "m",
      max: 2229,
      min: 843,
      defaultValue: [843, 2229],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-altitude-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_attitude/20201209/{z}/{x}/{y}/lerc",
    },
  },
  accumulated: {
    sliderConfig: {
      icon: "high-temperature",
      title: "≥10℃积温",
      unit: "℃ d",
      max: 4292.57,
      min: 2594.4,
      defaultValue: [2594.4, 4292.57],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-accumulated-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_32_-9999/20201202/{z}/{x}/{y}/lerc",
    },
  },
  augustRain: {
    sliderConfig: {
      icon: "high-temperature",
      title: "8月累积降水量",
      unit: "mm",
      max: 55.15,
      min: 14.96,
      defaultValue: [14.96, 55.15],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-augustRain-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_8rain/20201213/{z}/{x}/{y}/lerc",
    },
  },
  mayT: {
    sliderConfig: {
      icon: "high-temperature",
      title: "5月平均最低温度",
      unit: "℃",
      max: 13.01,
      min: 6.75,
      defaultValue: [6.75, 13.01],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-mayT-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_temp/20201203/{z}/{x}/{y}/lerc",
    },
  },
  temperature: {
    sliderConfig: {
      icon: "high-temperature",
      title: "年平均温度",
      unit: "mm",
      max: 10.23,
      min: 4.17,
      defaultValue: [4.17, 10.23],
    },
    mapConfig: {
      id: uniqueId("sunflower") +  "map-analysis-temperature-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_rain/20201205/{z}/{x}/{y}/lerc",
    },
  },
};

export const mapAnalysis: MapConfig = {
  /** source 和 layer 的 id*/
  id: "map-analysis-suitability-id",
  /** 瓦片路径 */
  // corn_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200101/{z}/{x}/{y}/lerc",
  // wheat_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200102/{z}/{x}/{y}/lerc",
  // sunflower_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200103/{z}/{x}/{y}/lerc",
  /** 瓦片路径 */
  corn_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/suitability/20201217/{z}/{x}/{y}/lerc",
  wheat_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/suitability/20201216/{z}/{x}/{y}/lerc",
  sunflower_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/suitability/20201215/{z}/{x}/{y}/lerc",
};
