import React, { PureComponent } from "react";
import { ICSSProps, ColorProps, GlobalColorPalette, ICSS, Color } from "@gago/frame";
import styled from "styled-components";
import numberBg from "./bg@2x.png";
import decline from "./decline.png";
import rise from "./rise.png";


// tslint:disable:variable-name ter-max-len completed-docs jsx-no-multiline-js
const CropAreaCardBody = styled.div<{colorPalette: GlobalColorPalette}>`
  height: 100%;
  width: 100%;
  padding: 16px 12px;
  background-image: url(${numberBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .header-title{
    font-family: PingFangSC;
    font-size: 16px;
    font-weight: 500;
    color: #00e5ff;
  }
  .bottom-line{
    margin-top: 12px;
    height: 1px;
    width: 100%;
    position: relative;
    background-color: rgb(0 221 255 / 15%);
    &::before{
      content: "";
      position: absolute;
      z-index: 3;
      left: 0;
      top: -1px;
      height: 1px;
      width: 5px;
      background-color: rgb(0 221 255 / 65%);
    }
    &::after{
      content: "";
      position: absolute;
      z-index: 3;
      right: 0;
      top: -1px;
      height: 1px;
      width: 5px;
      background-color: rgb(0 221 255 / 65%);
    }
  }
  .crop-value{
    padding: 8px 4px 0px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    .sv-value{
      min-width: 28px;
      height: 28px;
      font-size: 36px;
      line-height: 1;
      font-family: BebasNeue;
      color: #e0feff;
      margin-right: 4px;
    }
    .sv-suffix{
      font-family: BebasNeue;
      font-size: 20px;
      color: #e0feff;
    }
    .sv-bg{
      display: inline-block;
      margin-right: 4px;
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .rise{
      background-image: url(${rise});
    }
    .decline{
      background-image: url(${decline});
    }
  }
`;
type Cprops = {
  /** 标题 */
  title: string;
  /** 数量 */
  value: React.ReactNode;
  /** 改变的大小 */
  change?: number;
 
} & ICSSProps & ColorProps<GlobalColorPalette>;

/**
 * 第 6 种样式的card
 * @author maoshengtao
 */
export class Card6 extends PureComponent<Cprops> implements ICSS, Color {
  render() {
    const { colorPalette, style, className, title, value, change } = this.props;
    const bgIcon = change && change < 0 ? "decline" : "rise";
    return(
      <CropAreaCardBody colorPalette={colorPalette} style={style} className={className}>
        <div className="header-title">{title}</div>
        <div className="bottom-line" />
        <div className="crop-value">
          <span className="sv-value">{value}</span>
          {change !== undefined && (
            <span>
              <span className={`sv-bg ${bgIcon}`} />
              <span className="sv-suffix">{Math.abs(change)}%</span>
            </span>
          )}
        </div>
      </CropAreaCardBody>
    );
  }
}
