import styled from "styled-components";
import { ColorProps, GlobalColorPalette } from "@gago/frame";

// tslint:disable-next-line:variable-name
export const BigScreenBody = styled.div<ColorProps<GlobalColorPalette>>`
  height: 100%;
  width: 100%;
  background-color: #09131f;
  background-image: url("/static/images/big-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .left-rqcode{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-input-group-wrapper{
      width: unset;
    }
  }
  .right-recode{
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
`;
