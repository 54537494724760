import { BaseProvider } from "../../network/providers/base.provider";
import { BaseRequestStaticServer } from "../../network/servers/base-request.server";
// import DeviceStore from './device.store';

export const isSuccess = 200;
export const isError = 400;

class DeviceService extends BaseProvider {
  /** 获取角色 */
  async getRoleData(roleId: number | string) {
    try {
      return BaseRequestStaticServer.get(`/auth/roles?roleId=${roleId}`)
        .then((res: any) => {
          console.log(res)
          return {
            data: res.roles,
            code: res.code,
          };
        });
    } catch (err) {
      throw err;
    }
  }

  /** 新增角色 */
  async addRole(params: any) {
    console.log("params", params)
    try {
      return BaseRequestStaticServer.post(`/auth/roles`, params)
        .then((res: any) => {
          return {
            message: res.message,
            code: res.code,
          };
        }).catch((error: any) => {
          return error.response.data.error;
        });
    } catch (err) {
      throw err;
    }
  }

  /** 更新角色信息 */
  async editRole(params: any) {
    try {
      return BaseRequestStaticServer.put(`/auth/roles/${params.roleId}`, params)
        .then((result: any) => {
          return {
            message: result.message,
            code: result.code,
          };
        });
    } catch (err) {
      throw err;
    }
  }

  /** 删除角色 */
  async deleteRole(roleId: string) {
    try {
      return BaseRequestStaticServer.delete(`/auth/roles/${roleId}`)
        .then(({ data, error }: any) => {
          const finalData = data || error;
          return {
            message: finalData.message,
            code: finalData.code,
          };
        }).catch((error: any) => {
          throw error;
        });
    } catch (err) {
      throw err;
    }
  }
}

export default new DeviceService();
