// tslint:disable: variable-name naming-convention jsx-no-multiline-js jsx-wrap-multiline
import React from "react";
import {
  Divider,
  Modal,
  Popover,
  Table,
  Button,
  Popconfirm,
  message,
} from "antd";
import { divide, isNull, noop } from "lodash";
import styled, { createGlobalStyle } from "styled-components";
import { BaseLandList, ComponyList } from "../../models/dictionary-base";
import { DetailOverlay } from "../../containers/detail-overlay";
import { DictionaryBaseOverlay } from "../../containers/dictionary-base-overlay";
import { PaginationConfig } from "antd/lib/table";
import * as OperationInterface from "../../models/dictionary-operation";
import { OperationOverlay } from "../../containers/operation-overlay";
import OperationProvider from "../../network/providers/dictionary-operation.provider";

const StyleButton = styled.a`
  font-family: PingFangSC;
  font-size: 14px;
  color: #00adad;
`;

interface CloumnType {
  title: string;
  dataIndex?: string;
  children?: CloumnType[];
}

const titleList = [
  {
    title: "销售区域",
    dataIndex: "district",
  },
  {
    title: "当年交易量(吨)",
    dataIndex: "volume",
  },
  {
    title: "当年交易量涨跌",
    dataIndex: "volumeGrowthRate",
  },
  {
    title: "当年交易额(万元)",
    dataIndex: "gmv",
  },
  {
    title: "当年交易量涨跌",
    dataIndex: "gmvGrowthRate",
  },
  {
    title: "总客户数量",
    dataIndex: "client",
  },
  {
    title: "新增农户比",
    dataIndex: "clientGrowthRate",
  },
  {
    title: "销售渠道",
    children: [
      {
        title: "线下展会",
        dataIndex: "offlineExhibit",
      },
      {
        title: "直营",
        dataIndex: "directSelling",
      },
      {
        title: "电商",
        dataIndex: "eCommerce",
      },
      {
        title: "其他",
        dataIndex: "other",
      },
    ],
  },
];

const titleList2 = [
  {
    title: "天赋河套APP",
    children: [
      {
        title: "使用人数",
        dataIndex: "userCount",
      },
      {
        title: "记录地块数",
        dataIndex: "plotCount",
      },
      {
        title: "农田记事数",
        dataIndex: "farmworkCount",
      },
    ],
  },
  {
    title: "LOT智能监测",
    children: [
      {
        title: "监控设备",
        dataIndex: "monitoring",
      },
      {
        title: "气象站",
        dataIndex: "weather",
      },
      {
        title: "传感器",
        dataIndex: "sensor",
      },
      {
        title: "控制设备",
        dataIndex: "device",
      },
    ],
  },
];

interface P {
  /** 表格数据 */
  baseList?: OperationInterface.ExpandList;
  /** 处理分页 */
  handlePagination(config: PaginationConfig): void;
  // 处理table复选框
  handleSelectRowKeys(params: string[]): void;
  /** 当前页数 */
  pagination?: PaginationConfig;
  /** 经营管理数据删除 */
  expandDel(p: OperationInterface.ExpandDelRequest): void;
  companyList?: ComponyList[];
  /** 获取列表 */
  getExpandList(): void;
}

type DataType = OperationInterface.ExpandList["list"][number] & {
  /** key */
  key: string;
  /** 序号 */
  序号: string;
};

interface S {
  /** 选择的行数 的key集合 */
  selectedRowKeys: string[];
  /** 二维麻状态 */
  qrstatus?: boolean;
  /** data */
  data?: DataType[];
  /** 当前点击编辑的 的种质资源 的种质编号*/
  activeData?: OperationInterface.ExpandList["list"][number];
  /** 当前点击详情的 的种质资源 的种质编号*/
  detailNumber?: OperationInterface.ExpandList["list"][number];
  /** h5 modal number */
  h5ModalNumber?: string;
}

/**
 * 左侧第二个模块 表格组件
 * @author maoshengtao
 */
export class LeftModule2 extends React.PureComponent<P, S> {
  columns: any[];

  constructor(props: P) {
    super(props);
    const { baseList } = this.props;

    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      data: this.handleData(baseList),
    };

    let titleList3: CloumnType[] = baseList ? this.handleJqul(baseList) : [];
    if (titleList3 && titleList3.length > 0) {
      titleList3 = [
        {
          title: "企业年推广种质面积",
          children: titleList3,
        }
      ]
    }
    
    this.columns = [
      {
        title: <span title={"序号"}>{"序号"}</span>,
        dataIndex: "序号",
        width: 60,
        fixed: "left",
        ellipsis: true,
      },
      this.renderTableName(),
      ...this.handleCloumn(titleList),
      ...this.handleCloumn(titleList3),
      ...this.handleCloumn(titleList2),
      this.renderOperation2(),
    ];
  }

  /** 动态添加企业推广的表格 */
  handleJqul = (
    data: OperationInterface.ExpandList
  ) => {
    let companyExtend: OperationInterface.ExpandList["list"][number]["plantInformation"]  = [];
    let maxYears = 0;
    let cloumns: CloumnType[] = [];
    data.list.forEach((item) => {
      if (item.plantInformation && item.plantInformation.length > maxYears) {
        maxYears = item.plantInformation.length;
        companyExtend = item.plantInformation;
      }
    });
    if (companyExtend && companyExtend.length !== 0) {
      cloumns = companyExtend.map((item) => {
        return {
            title: item.year,
            dataIndex: item.year,
          };
      })
    }
    return cloumns;
  };

  handleCloumn = (data: CloumnType[]) => {
    return data.map((item, ind) => {
      return {
        title: <span title={item.title}>{item.title}</span>,
        dataIndex: item.dataIndex,
        width: 140,
        ellipsis: true,
        children: item.children
          ? item.children.map((elm) => ({
              title: <div title={elm.title}>{elm.title}</div>,
              dataIndex: elm.dataIndex,
              width: 140,
              ellipsis: true,
            }))
          : undefined,
      };
    });
  };

  /** 溯源大屏数据字典数据更新 */
  expandUpdate = async (params: OperationInterface.ExpandUpdateRequest) => {
    OperationProvider.expandUpdate(params).then(() => {
      message.success("更新成功");
      this.props.getExpandList();
      this.handleEditCancle();
    });
  };

  renderTableName = () => {
    return {
      title: "企业名称",
      dataIndex: "companyName",
      width: 200,
      ellipsis: true,
      render: (v: any, o: any) => {
        return (
          <>
            <StyleButton onClick={this.handleDetailClick.bind(this, o)}>
              {v}
            </StyleButton>
          </>
        );
      },
    };
  };

  renderOperation2 = () => {
    const { expandDel } = this.props;
    return {
      title: "操作",
      fixed: "right",
      width: 120,
      render: (v: any, o: any) => (
        <>
          <StyleButton onClick={this.handleEditClick.bind(this, o)}>
            编辑
          </StyleButton>
          <Divider type="vertical" />
          <Popconfirm
            title="确认删除嘛？"
            okText="确认"
            cancelText="取消"
            onConfirm={expandDel.bind(this, { id: v.id })}
          >
            <StyleButton>删除</StyleButton>
          </Popconfirm>
        </>
      ),
    };
  };

  componentDidUpdate(preProps: P) {
    if (this.props.baseList !== preProps.baseList) {
      this.setState({ data: this.handleData(this.props.baseList) });
    }
  }

  /**选择发生变化 */
  onSelectChange = (selectedRowKeys: any) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    const { handleSelectRowKeys } = this.props;
    this.setState({ selectedRowKeys });
    handleSelectRowKeys(selectedRowKeys);
  };

  /** handle data */
  handleData = (rawData?: OperationInterface.ExpandList) => {
    if (!rawData) {
      return [];
    }
    return rawData.list.map((item, ind) => {
      const plants = item.plantInformation.reduce((pre, old) => ({...pre, [old.year]: old.value }), {})
      return {
        ...item,
        ...plants,
        key: (item.id || ind).toString(),
        序号: `${ind + 1}` || "-",
      };
    });
  };

  /** handle overlay open */
  handleEditClick = (
    activeData: OperationInterface.ExpandList["list"][number]
  ) => {
    this.setState({ activeData });
  };

  /** handle overlay close*/
  handleEditCancle = () => {
    this.setState({ activeData: undefined });
  };

  renderEditOverlay = () => {
    const { activeData } = this.state;
    const { companyList } = this.props;
    if (!activeData || !companyList) {
      return null;
    }
    return (
      <OperationOverlay
        key={"edit"}
        type="edit"
        companyList={companyList}
        visible={!!activeData}
        title={"编辑企业经营数据"}
        oldData={activeData}
        onCancel={this.handleEditCancle}
        onEdit={this.expandUpdate}
      />
    );
  };
  /** handle overlay open */
  handleDetailClick = (
    detailNumber: OperationInterface.ExpandList["list"][number]
  ) => {
    this.setState({ detailNumber });
  };

  /** handle overlay close*/
  handleDetailCancle = () => {
    this.setState({ detailNumber: undefined });
  };

  renderDetailOverlay = () => {
    const { detailNumber } = this.state;
    const { companyList } = this.props;
    if (!detailNumber) {
      return null;
    }
    return (
      <OperationOverlay
        type="detail"
        companyList={companyList}
        visible={!!detailNumber}
        title={"查看企业经营数据"}
        oldData={detailNumber}
        onCancel={this.handleDetailCancle}
      />
    );
  };

  /** 处理分页 */
  handlePagination = (pagination: PaginationConfig) => {
    this.props.handlePagination(pagination);
  };

  render() {
    const { selectedRowKeys, data } = this.state;
    const { baseList, pagination } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "选择全部",
          onSelect: () => {
            this.setState({
              selectedRowKeys: data ? [...data.map((item) => item.key)] : [], // 0...45
            });
          },
        },
        {
          key: "odd",
          text: "选择奇数行",
          onSelect: (changableRowKeys: any) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter(
              (key: string, index: number) => {
                if (index % 2 !== 0) {
                  return false;
                }
                return true;
              }
            );
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: "even",
          text: "选择偶数行",
          onSelect: (changableRowKeys: any) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter(
              (key: string, index: number) => {
                if (index % 2 !== 0) {
                  return true;
                }
                return false;
              }
            );
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
    const h = window.document.documentElement.offsetHeight - 400;
    return (
      <>
        <Table
          // style={{ width: "100%" }}
          // tableLayout={"fixed"}
          key={`${data && data.length}`}
          bordered
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={data || []}
          scroll={{ x: 500, y: `${h < 0 ? 100 : h}px` }}
          onChange={this.handlePagination}
          pagination={{
            pageSize: pagination && pagination.pageSize,
            current: pagination && pagination.current,
            showQuickJumper: true,
            onShowSizeChange: noop,
            showSizeChanger: true,
            total: baseList ? baseList.count : undefined,
            // tslint:disable-next-line: jsx-alignment
          }}
        />
        {this.renderEditOverlay()}
        {this.renderDetailOverlay()}
      </>
    );
  }
}
