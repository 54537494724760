import React from "react";
import base1 from "./images/base/1.svg";
import base2 from "./images/base/2.svg";
import base3 from "./images/base/3.svg";
import base4 from "./images/base/3.svg";
import base5 from "./images/base/4.svg";
import base6 from "./images/base/5.svg";
import base7 from "./images/base/6.svg";
import base8 from "./images/base/7.svg";

import mapBase1 from "./images/Type-mappoint-2/base-1.png";
import mapBase2 from "./images/Type-mappoint-2/base-2.png";
import mapBase3 from "./images/Type-mappoint-2/base-3.png";
import mapBase4 from "./images/Type-mappoint-2/base-4.png";
import mapBase5 from "./images/Type-mappoint-2/base-5.png";
import mapBase6 from "./images/Type-mappoint-2/base-6.png";
import mapBase7 from "./images/Type-mappoint-2/base-7.png";


import germplasm1 from "./images/germplasm/1.svg";
import germplasm2 from "./images/germplasm/2.svg";
import germplasm3 from "./images/germplasm/3.svg";
import germplasm4 from "./images/germplasm/4.svg";
import germplasm5 from "./images/germplasm/5.svg";
import germplasm6 from "./images/germplasm/6.svg";
import germplasm7 from "./images/germplasm/7.svg";

import mapG1 from "./images/Type-mappoint-2/g-1.png";
import mapG2 from "./images/Type-mappoint-2/g-2.png";
import mapG3 from "./images/Type-mappoint-2/g-3.png";
import mapG4 from "./images/Type-mappoint-2/g-4.png";
import mapG5 from "./images/Type-mappoint-2/g-5.png";
import mapG6 from "./images/Type-mappoint-2/g-6.png";
import mapG7 from "./images/Type-mappoint-2/g-7.png";

import park1 from "./images/park/1.svg";
import park2 from "./images/park/2.svg";
import park3 from "./images/park/3.svg";
import park4 from "./images/park/4.svg";
import park5 from "./images/park/5.svg";
import park6 from "./images/park/6.svg";
import park7 from "./images/park/7.svg";
import park8 from "./images/park/8.svg";

import mapPark1 from "./images/Type-mappoint-2/park-1.png";
import mapPark2 from "./images/Type-mappoint-2/park-2.png";
import mapPark3 from "./images/Type-mappoint-2/park-3.png";
import mapPark4 from "./images/Type-mappoint-2/park-4.png";
import mapPark5 from "./images/Type-mappoint-2/park-5.png";
import mapPark6 from "./images/Type-mappoint-2/park-6.png";
import mapPark7 from "./images/Type-mappoint-2/park-7.png";
import mapPark8 from "./images/Type-mappoint-2/park-8.png";

export interface LegendP {
  /** tab类型 */
  tabType: "base" | "germplasm" | "park";
  /** 是否是地图 icon*/
  isMap?: boolean;
}
const base = [
  {
    name: "中草药基地",
    icon: base1,
  },
  {
    name: "综合基地",
    icon: base2,
  },
  {
    name: "小麦基地",
    icon: base3,
  },
  {
    name: "果蔬基地",
    icon: base4,
  },
  {
    name: "牧草基地",
    icon: base5,
  },
  {
    name: "玉米基地",
    icon: base6,
  },
  {
    name: "葵花基地",
    icon: base7,
  },
];

const baseWithMap = [
  {
    name: "中草药,红花,黄芪",
    icon: mapBase1,
  },
  {
    name: "基地",
    icon: mapBase2,
  },
  {
    name: "小麦",
    icon: mapBase3,
  },
  {
    name: "蔬菜,果蔬",
    icon: mapBase4,
  },
  {
    name: "牧草,草业",
    icon: mapBase5,
  },
  {
    name: "玉米",
    icon: mapBase6,
  },
  {
    name: "向日葵,葵花",
    icon: mapBase7,
  },
];

const germplasm = [
  {
    name: "中草药",
    icon: germplasm1,
  },
  {
    name: "果树",
    icon: germplasm2,
  },
  {
    name: "热带作物",
    icon: germplasm3,
  },
  {
    name: "牧草绿肥",
    icon: germplasm4,
  },
  {
    name: "粮食作物",
    icon: germplasm5,
  },
  {
    name: "经济作物",
    icon: germplasm6,
  },
  {
    name: "蔬菜",
    icon: germplasm7,
  },
];

const germplasmWithMap = [
  {
    name: "中草药",
    icon: mapG1,
  },
  {
    name: "果树",
    icon: mapG2,
  },
  {
    name: "热带作物",
    icon: mapG3,
  },
  {
    name: "牧草绿肥",
    icon: mapG4,
  },
  {
    name: "粮食作物",
    icon: mapG5,
  },
  {
    name: "经济作物",
    icon: mapG6,
  },
  {
    name: "蔬菜",
    icon: mapG7,
  },
];

const park = [
  {
    name: "中草药产业园",
    icon: park1,
  },
  {
    name: "综合产业园",
    icon: park2,
  },
  {
    name: "小麦产业园",
    icon: park3,
  },
  {
    name: "果蔬产业园",
    icon: park4,
  },
  {
    name: "玉米产业园",
    icon: park6,
  },
  {
    name: "草业产业园",
    icon: park7,
  },
  {
    name: "葵花产业园",
    icon: park8,
  },
  {
    name: "核心区",
    icon: park5,
  },
];

const parkWithMap = [
  {
    name: "中草药,红花,黄芪",
    icon: mapPark1,
  },
  {
    name: "产业园",
    icon: mapPark2,
  },
  {
    name: "小麦",
    icon: mapPark3,
  },
  {
    name: "蔬菜,果蔬",
    icon: mapPark4,
  },
  {
    name: "玉米",
    icon: mapPark6,
  },
  {
    name: "草业",
    icon: mapPark7,
  },
  {
    name: "向日葵,葵花",
    icon: mapPark8,
  },
  {
    name: "核心区",
    icon: mapPark5,
  },
];
/**
 * 
 * 获取 图例 icon
 *
 */

export const getLegendIcon = (params: LegendP) => {
  const { tabType, isMap } = params;
  if (tabType === "base" && !isMap) {
    return base;
  }
  if (tabType === "base" && isMap) {
    return baseWithMap;
  }

  if (tabType === "germplasm" && !isMap) {
    return germplasm;
  }

  if (tabType === "germplasm" && isMap) {
    return germplasmWithMap;
  }

  if (tabType === "park" && !isMap) {
    return park;
  }

  if (tabType === "park" && isMap) {
    return parkWithMap;
  }

  return base;
}