import { ITable } from "@gago/frame";
import { tuple } from '../../utils'
import { SliderValue } from "antd/lib/slider";
// import { serverConfig } from '../../network/serverConfig';
import { serverConfig } from '../../src-anxi/network/serverConfig';
import { CropType } from './index'


export const mapToken = serverConfig.appToken;
export const sliderList = tuple("cornTemperature", "temperature", "rain", "river", "traffic");
export type SliderList = (typeof sliderList)[number];

/** slider用到的接口 */
export interface SliderConfig {
  /** 图标 */
  icon: string;
  /** 标题 */
  title: string;
  /** 单位 */
  unit: string;
  /** 最大值 */
  max: number;
  /** 最小值 */
  min: number;
  /** 步长 */
  step?: number;
  /** 初始化范围 */
  defaultValue: SliderValue;
}
export interface ElementMap {
  bd: string;
  ph: string;
  an: string;
  som: string;
  ak: string;
  texture: string;
}
export type ElementKey = keyof ElementMap
/** 地图 mask 的配置 */
export interface MapConfig {
  /** source 和 layer 的 id*/
  id: string;
  /** 瓦片路径 */
  [tiles: string]: string;
}

/** 配置 */
export interface Config {
  /** slider配置 */
  sliderConfig: SliderConfig;
  /** 地图配置 */
  mapConfig: MapConfig;
}
const cropTypes = tuple('all', 'sunflower', 'corn', 'wheat', 'other')


export const mapElementList: { label: string; value: string }[] = [
  { label: "有机质及物理指标", value: "organic_matter" },
  { label: "土壤元素", value: "land_element" }
];

export const elementList = [
  {
    label: '酸碱度', value: 'ph'
  }, {
    label: '土壤容重', value: 'bd'
  }, {
    label: '全氮', value: 'an'
  }, {
    label: '有机质', value: 'som'
  }, {
    label: '有效磷', value: 'ap'
  }, {
    label: '速效钾', value: 'ak'
  }]
export const mapDataList: { label: string; value: CropType }[] = [
  { label: "全部", value: cropTypes[0] },
  { label: "向日葵", value: cropTypes[1] },
  { label: "玉米", value: cropTypes[2] },
  { label: "小麦", value: cropTypes[3] },
  { label: "其他", value: cropTypes[4] }
];

export const dataS1N1: ITable<1, 1> = {
  title: "土壤成分",
  subtitle: "土壤成分",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", "土壤养分水平"],
  data: [],
  suffix: ["", "", "分"],
  prefix: ["", "", ""],
};
export const columns = [
  {
    title: '监测项目',
    ellipsis: true,
    dataIndex: 'name',
    key: 'accessionNumber'
  },
  {
    title: '土壤测试结果',
    dataIndex: 'value',
    key: 'value',
    ellipsis: true,

  }, {
    title: '土壤养分水平',
    ellipsis: true,
    dataIndex: 'rate',
    key: 'rate'
  }

]
export const elementMap = {
  bd: '土壤容重',
  an: '全氮',
  ph: '酸碱度',
  som: '有机质',
  ap: '有效磷',
  ak: '速效钾',
  texture: '土壤质地'
}
export const cornTemperatureMaskConfig: Config = {
  sliderConfig: {
    icon: "high-temperature",
    title: "温度",
    unit: "℃",
    max: 20,
    min: 12,
    defaultValue: [12, 20],
  },
  mapConfig: {
    id: "corn-temperature-mask-id",
    tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_map/20200501/{z}/{x}/{y}/lerc",
  },
};

/** 控制的内容 */
export const maskConfig: { [Key in SliderList]: Config } = {
  cornTemperature: cornTemperatureMaskConfig,
  temperature: {
    sliderConfig: {
      icon: "high-temperature",
      title: "温度",
      unit: "℃",
      max: 13,
      min: 6,
      defaultValue: [6, 13],
    },
    mapConfig: {
      id: "map-analysis-temperature-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_map/20200401/{z}/{x}/{y}/lerc",
    },
  },
  rain: {
    sliderConfig: {
      icon: "rain-heavy",
      title: "雨量",
      unit: "mm",
      max: 350,
      min: 100,
      defaultValue: [100, 350],
    },
    mapConfig: {
      id: "map-analysis-rain-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_map/20200102/{z}/{x}/{y}/lerc",
    },
  },
  river: {
    sliderConfig: {
      icon: "fog-normal",
      title: "河流距离",
      unit: "km",
      max: 30,
      min: 0,
      defaultValue: [0, 30],
    },
    mapConfig: {
      id: "map-analysis-river-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_map/20200103/{z}/{x}/{y}/lerc",
    },
  },
  traffic: {
    sliderConfig: {
      icon: "meter",
      title: "交通距离",
      unit: "km",
      max: 30,
      min: 0,
      defaultValue: [0, 30],
    },
    mapConfig: {
      id: "map-analysis-traffic-mask-id",
      tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/factor_map/20200104/{z}/{x}/{y}/lerc",
    },
  },
};

export const mapAnalysis: MapConfig = {
  /** source 和 layer 的 id*/
  id: "map-analysis-suitability-id",
  /** 瓦片路径 */
  all_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200101/{z}/{x}/{y}/lerc",
  corn_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200101/{z}/{x}/{y}/lerc",
  wheat_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200102/{z}/{x}/{y}/lerc",
  sunflower_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200103/{z}/{x}/{y}/lerc",
  other_tiles: "http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/bottom_map/20200103/{z}/{x}/{y}/lerc",
};
