// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Button, Form, message } from "antd";
import { PageBody } from "./styled";
import { Card1 as Card, ITable, RadarChart } from "@gago/frame";

import { HeaderBox } from "../../containers/header-box";
import { Card1 } from "../../containers/card1";
import { LeftModule1 } from "./left-module1";
import { LeftModule2 } from "./left-module2";
import ModalForm from '@gago/lib/components/template-modal-form'

import blue from "./img/blue.png";
import green from "./img/green.png";
import yellow from "./img/yellow.png";
import { chartStyle3 as chartStyle, colorPalette } from "../../color-palette";
import { getDataS1N1, getDataS2N1 } from "../../utils/example-data";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { CropPercentChart1 } from "@gago/lib/components/crop-percent-chart-1";
import { AddOverlay } from "../../containers/add-overlay";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
// tslint:disable-next-line: ter-max-len
import {  CropCategory, RegionSummary, ResourceList, Upload, ResourceListRequest, ResourctFindOne, importResource, TemplateRequest } from "../../models/germplasm-database";
import CommodityGermplasmProvider from "../../network/providers/commodity-germplasm.provider";
import { CommoditySummary, CommodityTrend, CategorySummary, ClassifySummary } from "../../models/commodity-germplasm"

import { cloneDeep, round, sumBy } from "lodash";
import { RankingList } from "@gago/lib/components/ranking-list";
import { PaginationConfig } from "antd/lib/table";
import { FormComponentProps } from "antd/lib/form";

const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", ""],
  data: [],
  suffix: ["", "", "", "份"],
  prefix: ["", "", "", ""],
};

const dataS1N1: ITable<1, 1> = {
  title: "",
  subtitle: "总种类",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", ""],
  data: [],
  suffix: ["", "", "类"],
  prefix: ["", "", ""],
};

interface S {
  /** 对话框是否可见 */
  visible: boolean;
  /** 种植资源库汇总 */
  commoditySummary?: CommoditySummary;
  /** 种植资源入库趋势 */
  commodityTrend?: CommodityTrend[];
  /** 种植类型数量统计 */
  categorySummary?: CategorySummary[];
  /** 商品种质排名 */
  classifySummary?: ClassifySummary[];
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 入库年份 */
  storageYears?: number[];
  /** 种质数据列表 */
  resourceList?: ResourceList;
  /** 顶部数据搜索 */
  topSearch?: any;
  /** 当前页数 */
  pagination?: PaginationConfig;
  // 导入导出弹框是否可见
  isShow:boolean;
  // 上传文件的url地址
  uploadUrl:string;
  // 导入导出下载模板请求所需参数
  requestKeys:string[];
  // 选择的数据行数
  selectRowKeysCopy?:string[];
  // openType ---> 下载模板：download 导入：import 导出：exoprt
  openType:string;
  //  手动上传url
  handleUploadUrl:string;
  /** 顶部卡片类型 */
  cardType?: number;
  /** 顶部搜索表单 */
  topForm?: FormComponentProps["form"];
}

/**
 * 商品种质资源库管理
 * @author maoshengtao
 */
export class CommodityGermplasm extends React.PureComponent<{}, S> {

  state: S = {
    visible: false,
    topSearch: {},
    isShow: false,
    requestKeys:['oneLevelId','twoLevelId'],
    uploadUrl:'',
    openType:'download',
    handleUploadUrl:'',
    selectRowKeysCopy:[]
  };

  componentDidMount() {
    this.getCropCategory();
    this.getStorageYears();
    this.getResourceSummary();
    this.getResourceTrend();
    this.getCategorySummary();
    this.getRegionSummary();
  }

  /** get 作物分类 */
  getCropCategory = async () => {
    const cropCategory = await GermplasmDatabaseProvider.getCropCategory();
    /** 第一次加载列表项 */
    if (cropCategory && cropCategory.length > 0) {
      this.setState({
        topSearch: {
          oneLevel: cropCategory[0].name,
          twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        }
       })
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
      });
    }
    this.setState({ cropCategory });
  }

  /** get 入库年份 */
  getStorageYears = async () => {
    const storageYears = await GermplasmDatabaseProvider.getStorageYears();
    this.setState({ storageYears });
  }

  /** get 种质数据列表 */
  getResourceList = async (req: ResourceListRequest) => {
    const params: ResourceListRequest = {
      ...req,
      oneLevel: req.oneLevel === "全部" ? undefined : req.oneLevel,
      twoLevel: req.twoLevel === "全部" ? undefined : req.twoLevel,
      isCirculate: 1,
    }
    GermplasmDatabaseProvider.getResourceList(params).then((resourceList) => {
      this.setState({ resourceList });
    }).catch((err) => {
      message.error("获取列表失败");
    });
  }

  /** get 商品种质资源管理-资源库汇总 */
  getResourceSummary = async () => {
    const commoditySummary = await CommodityGermplasmProvider.getCommoditySummary();
    this.setState({ commoditySummary });
  }

  /** get 种植资源入库趋势 */
  getResourceTrend = async () => {
    const commodityTrend = await CommodityGermplasmProvider.getCommodityTrend();
    this.setState({ commodityTrend });
  }

  /** get 种植类型数量统计 */
  getCategorySummary = async () => {
    const categorySummary = await CommodityGermplasmProvider.getCategorySummary();
    this.setState({ categorySummary });
  }

  /** get 排名 */
  getRegionSummary = async () => {
    const classifySummary = await CommodityGermplasmProvider.getClassifySummary();
    this.setState({ classifySummary });
  }

  /** top search */
  onTopSearch = (topSearch: any) => {
    const { pagination, topForm, cardType } = this.state;
    this.handleCardClick(0);
    this.setState({ topSearch }, () => {
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.入库年份,
        accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        departmentName: topSearch.选育单位,
        size: 10,
        page: pagination && pagination.current || 1,
      });
    });
  }

  /** 删除种质资源数据 */
  resourceDel = async (id: string) => {
    GermplasmDatabaseProvider.resourceDel(id).then((data) => {
      if (data && data.code === 200) {
        message.success("删除成功");
        const { topSearch } = this.state;
        this.getResourceList({
          oneLevel: topSearch.作物分类,
          twoLevel: topSearch.二类名称,
          year: topSearch.入库年份,
          accessionNumber: topSearch.种质编号,
          genebankNumber: topSearch.天赋河套种质库编号,
          accessionName: topSearch.种质名称,
          departmentName: topSearch.选育单位,
          size: 10,
          page: 1,
        });
        this.getResourceSummary();
      } else {
        message.error("删除失败");
      }
    }).catch((err) => {
      message.error("删除失败");
    });
  }

  /** 处理分页编号请求 */
  handlePagination = (pagination: PaginationConfig) => {
    if (pagination && pagination.current) {
      const { topSearch, cardType } = this.state;
      this.setState({ pagination });
      this.getResourceList({
        oneLevel: topSearch.作物分类,
        twoLevel: topSearch.二类名称,
        year: topSearch.入库年份,
        accessionNumber: topSearch.种质编号,
        genebankNumber: topSearch.天赋河套种质库编号,
        accessionName: topSearch.种质名称,
        departmentName: topSearch.选育单位,
        size: 10,
        page: pagination.current,
        isExistGenebankNumber: cardType === 2 ? 1 : undefined,
        isTs: cardType === 3 ? 1 : undefined,
      });
    }
  }


  // 获取表单的selectRowKeys
  handleSelectRowKeys = (params:string[]) => {
    this.setState({
      selectRowKeysCopy:params
    })
  }

  // 处理导入导出弹框是否开启
  handleModalShow = (type:string)  => {
    this.setState({
      isShow:true,
      openType:type
    })
  }

  // 关闭导入导出弹框
  handleCancelModal = () => {
    this.setState({
      isShow:false,
      handleUploadUrl: ''
    })
  }


  // 处理导出操作
  handleExport = () => {
    const { selectRowKeysCopy, resourceList  } = this.state;
    let ids:string[] = [];
    console.log(selectRowKeysCopy,'//---')
    const token = window.sessionStorage.getItem("token") as string;
    if (selectRowKeysCopy && selectRowKeysCopy.length == 0) {
      return  message.info('您还没有选择导出的数据！')
    } else {
      selectRowKeysCopy && selectRowKeysCopy.forEach((item:string) => {
        if (resourceList &&  resourceList.list && resourceList.list.length > 0) {
          const ind:any = resourceList.list.findIndex(resource => resource.accessionNumber == item)
          ids.push(resourceList.list[ind].id)
        }
      })
      const idString:string = ids.join(',');
      // window.location.href =`${window.config.publisher}/api/v1/plant/resource/export?ids=${idString}&token=${token}`
      GermplasmDatabaseProvider.download(`/plant/resource/export?ids=${idString}`);

    }
  } 

  // 处理导入确定
  handleImport = async (params:importResource) => {
    const data = await GermplasmDatabaseProvider.importGermplasmDatabase(params);
    
      this.handleCancelModal();
      message.success('导入成功，请稍后刷新查看最新数据')
    console.log('import')
  
  }

  // 打开模板下载弹窗
  handleDownload = async (params:TemplateRequest) => {
    GermplasmDatabaseProvider.download(`/plant/resource/template?oneLevelId=${params.oneLevelId}&twoLevelId=${params.twoLevelId}`);
    this.handleCancelModal();
  }

  handleOk = (params:any) => {
    const {openType } = this.state;
    if ( openType === 'Download') {
      return this.handleDownload(params);
    } else if (openType === 'Import') {
      return this.handleImport(params);
    } 
  }

  render() {
    const { resourceList, cropCategory, storageYears, requestKeys,uploadUrl, pagination } = this.state;
    return(
      <HeaderBox
        names={["商品种质资源库管理"]}
      >
        <PageBody>

          <div className="page-left">
            <div className="top-card-list">
              {this.renderTopCardList()}
            </div>
            <div className="page-left-bottom">
              <div className="search-module">
                <LeftModule1
                  onTopSearch={this.onTopSearch}
                  cropCategory={cropCategory}
                  storageYears={storageYears}
                  onLoad={this.topFormLoad}
                />
              </div>
              {this.renderButtonList()}
              <div className="table-module">
                <LeftModule2
                  resourceList={resourceList}
                  resourceDel={this.resourceDel}
                  handlePagination={this.handlePagination}
                  handleSelectRowKeys={this.handleSelectRowKeys}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
          <div className="page-right">
            {this.renderRightCard1()}
            {this.renderRightCard2()}
            {this.renderRightCard3()}
          </div>
          {this.renderAddOverlay()}
          <ModalForm
            visible={this.state.isShow}
            cropCategory={cropCategory}
            uploadUrl={uploadUrl}
            requestKeys={requestKeys}
            onOk={this.handleOk}
            onCancel={this.handleCancelModal}
            isUseCropCate={false}
            handleUploadUrl={this.state.handleUploadUrl}
          />
        </PageBody>
      </HeaderBox>
    );
  }

  /** handle 新增 */
  handleAddClick = () => {
    this.setState({ visible: true });
  }

  /** handle 新增 取消*/
  handleAddCancle = () => {
    this.setState({ visible: false });
  }

  renderAddOverlay = () => {
    const { visible, cropCategory, storageYears } = this.state;
    return (
      <AddOverlay
        visible={visible}
        onCancel={this.handleAddCancle}
        title={"新增种质资源"}
        cropCategory={cropCategory}
        storageYears={storageYears}
      />
    );
  }

  renderRightCard1 = () => {
    const { commodityTrend } = this.state;
    if (!commodityTrend) {
      return null;
    }
    const chartData = cloneDeep(dataS2N1);
    chartData.data = commodityTrend.map((item) => {
      return [`${item.year}`, "商品种质入库趋势", `${item.year}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"商品种质入库趋势"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
        <AxisChart
          chartData={chartData}
          colorPalette={{ ...colorPalette, subColor: ["#f78011", ...colorPalette.subColor] }}
          basicConfigOption={{
            seriesType: "line",
            lineSmooth: true,
          }}
        />
        </div>
      </Card>
    );
  }

  renderRightCard2 = () => {
    const { categorySummary } = this.state;
    if (!categorySummary) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = categorySummary.map((item) => {
      return [`${item.source}`, `${item.source}`, round(item.count, 2)];
    });
    chartData.title = `${sumBy(chartData.data, (item) => item[2])}`;
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"种质来源统计"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ minHeight: 200, display: "flex", alignItems: "center" }}>
          <CropPercentChart1
            theme="horizontal"
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#2970ff", "#00aaff", "#70e2ff", "#ffb300", "#ff5275", ...colorPalette.subColor],
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard3 = () => {
    const { classifySummary } = this.state;
    if (!classifySummary) {
      return null;
    }
    const arr = classifySummary.map((item) => {
      return {
        title: item.classify || "——",
        value: item.count || "——"
      }
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"商品种质分类数量排名"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
          <RankingList
            data={arr}
          />
        </div>
      </Card>
    );
  }

  renderButtonList = () => {
    return (
      <div className="button-list">
        <div className="button-list-left">
          {/* <Button style={{ margin: "0px 10px" }} onClick={this.handleAddClick}>
              新增
          </Button> */}
          <Button type="primary" style={{ margin: "0px 10px" }} onClick={() => this.handleModalShow('Download')}>
              下载导入模板
          </Button>
        </div>
        <div className="button-list-right">
          <Button
            onClick={() =>{this.handleModalShow('Import');this.setState({handleUploadUrl:'/plant/resource'})} }
            icon="download"
          >
              导入
          </Button>
          <Button
            icon="export"
            onClick={this.handleExport}
            style={{ margin: "0px 10px" }}
          >
              导出
          </Button>
        </div>
      </div>
    );
  }

  /** top form load */
  topFormLoad = (form: FormComponentProps["form"]) => {
    this.setState({ topForm: form });
  }

  /** handle clear top search */
  handleClearTopSearch = () => {
    const { topForm } = this.state;
    this.setState({ topSearch: {} });
    topForm && topForm.resetFields();
  }

  /** handle top Card click */
  handleCardClick = (n: number) => {
    if (n === this.state.cardType) {
      return;
    }

    const { topForm, cropCategory } = this.state;
    if (n === 1 && cropCategory) {
      this.handleClearTopSearch();
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        isCirculate: 1,
      });
    }
    if (n === 2 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据天赋河套编号是否为空筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        isCirculate: 1,
        isExistGenebankNumber: 1,
      });
    }

    if (n === 3 && cropCategory) {
      this.handleClearTopSearch();
      /** 根据是否为区域特色筛选 */
      this.getResourceList({
        oneLevel: cropCategory[0].name,
        twoLevel: cropCategory[0].children && cropCategory[0].children[0].name,
        size: 10,
        page: 1,
        isCirculate: 1,
        isTs: 1,
      });
    }

    this.setState({ cardType: n });
  }

  /** render 顶部卡片列表 */
  renderTopCardList = () => {
    const { commoditySummary, cardType } = this.state;
    if (!commoditySummary) {
      return null;
    }
    return (
      <>
        {
          [
            {
              title: commoditySummary && commoditySummary.germplasmCount || 0,
              suffix: "(条)",
              description: "入库编目商品种质数据",
              img: green,
              type: 1,
            },
            {
              title: commoditySummary && commoditySummary.inventoryCount || 0,
              suffix: "(份)",
              description: "入库保存商品种质数量",
              img: yellow,
              type: 2,
            },
            {
              title: commoditySummary && commoditySummary.featureCount || 0,
              suffix: "(种份)",
              description: "区域特色商品种质数量",
              img: blue,
              type: 3,
            },
          ].map((item) => {
            return (
              <Card1
                active={cardType === item.type}
                onClick={this.handleCardClick.bind(this, item.type)}
                key={item.title}
                title={item.title}
                suffix={item.suffix}
                description={item.description}
                img={item.img}
              />
            );
          })
        }
      </>
    );
  }

}
