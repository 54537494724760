import React from "react";
import { colorPalette } from "../../color-palette";
import { LineChartDouble, BarChartDouble } from "@gago/lib/components";
import { Card1 as Card, ITable } from "@gago/frame";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { isNil } from "lodash";
import { WeatherAnalyse } from "../../containers/weather-analyse";
import {
  AnalysisTmp,
  BaseAnalysis,
} from "../../models/meteorological-service";
const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", "单位"],
  data: [],
  suffix: ["", "", "", "℃"],
  prefix: ["", "", "", ""],
};
const Columns = [
  {
    title: "序号",
    render: (text: any, record: any, index: number) => `${index + 1}`,
  }, {
    title: "品种",
    dataIndex: ""
  }, {
    title: "物侯期",
    dataIndex: ""
  }];
/** render left1 */
const RenderLeft1 = () => {
  return (
      <AxisChart
        chartData={getDataS2N1("region", ["识别面积", "统计面积"])}
        colorPalette={{ ...colorPalette, subColor: ["#13d7c8", "#e77300", ...colorPalette.subColor] }}
      />
    );
};

interface P {
  data?: any[];
  tmpList: AnalysisTmp;
  sumtmpList: BaseAnalysis;
  apcpList: BaseAnalysis;
}

interface S {
 /** 高亮的地区 */
  region: Region;
}

// const temperatureData1 = [
//   {
//     title: "今日",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: -20,
//     unit: "℃",
//   },
//   {
//     title: "十日最高气温",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: 39,
//     unit: "℃",
//   }, 
//   {
//     title: "十年平均对比",
//     trend: "asc",
//     num: 12,
//     unit: "%",
//   },
// ];
// const temperatureData2 = [
//   {
//     title: "今日",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: -20,
//     unit: "℃"
//   },
//   {
//     title: "十日最低气温",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: -20,
//     unit: "℃"
//   }, {
//     title: "十年平均对比",
//     trendIcon: "desc",
//     num: 12,
//     unit: "%"
//   },
// ];
// const humidityData = [
//   {
//     title: "今年",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: 377.9,
//     unit: "mm"
//   }, {
//     title: "十年平均",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: 456,
//     unit: "mm"
//   }, {
//     title: "与十年平均对比",
//     trendIcon: "desc",
//     num: 34,
//     unit: "%"
//   },
// ];

// const AccumulatedTempetatureData = [
//   {
//     title: "今年",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: 377.9,
//     unit: "mm"
//   }, {
//     title: "十年平均",
//     // trendIcon:<Icon type="arrow-up" />,
//     num: 456,
//     unit: "mm"
//   }, {
//     title: "与十年平均对比",
//     trendIcon: "desc",
//     num: 34,
//     unit: "%"
//   },
// ];
export default class MeteorologicalHistory extends React.PureComponent<P, S> {

  state: S = {
    region: {
      code: undefined,
      name: undefined,
      level: "city",
    },
  };

   /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
     this.setState({ region });
   }

  getMaxMinData = (countList: (number | string)[], unit: string, trend: number, secondTitle: string, firstTitle: string) => {
    const maxMinData: {title: string; num: number; unit: string; trend?: number}[] = countList.map((item, index: number) => {
      let itemObj = {};
      if (index  === 0) {
        itemObj = {
          title: firstTitle,
          num: item,
          unit,
        };
      } else if (index === 1) {
        itemObj = {
          title: secondTitle,
          num: item,
          unit,
        };
      } else {
        itemObj = {
          title: "与十年平均对比",
          num: item,
          trend,
          unit: trend === 0 ? "" : "%"
        };
      }
      return itemObj as {title: string; num: number; unit: string; trend?: number};
    });
    return maxMinData;
  }

  getMaxMin = (cur:number,history:number,unit:string,trend:boolean,secondTitle:string,firstTitle:string) => {
    const ratio = (Math.abs(cur) - Math.abs(history)) / Math.abs(history) * 100;
    const decadeTrend = cur === history ? 0 : cur > history ? 1 : -1 ;
                             
    let targetObj = {};
    const maxMinData: {title: string; num: number; unit: string; trend?: number}[] = [cur,history,Math.abs(ratio).toFixed(2)].map((item,index:number)=> {
      if (index  === 0) {
        targetObj = {
          title: firstTitle,
          num: item,
          unit,
        };
      } else if (index === 1) {
        targetObj = {
          title: secondTitle,
          num: item,
          unit,
        };
      } else {
        targetObj = {
          title: "与十年平均对比",
          num: decadeTrend === 0 ? "" : item,
          trend:decadeTrend,
          unit: decadeTrend === 0 ? "" : "%"
        };
      }
      return targetObj as {title: string; num: number; unit: string; trend?: number};
    });
    return maxMinData

  }
  renderTemperatureAnalyse = () => {
    console.log(getDataS2N1("month", ["今年", "十年平均"], "单位", "℃"), "example");

    const { tmpList } = this.props;
    if (!tmpList || tmpList.currentMaxMinList.length === 0 || tmpList.historyMaxMinList.length === 0) {
      return null;
    }

    const dataS1N4: ITable<1, 4> = {
      title: "数据量（万条）",
      subtitle: "",
      description: "",
      range: [[], [], [], [], [], []],
      head: ["ID", "月份", "今年月平均最高温", "十年月平均最高温", "今年月平均最低温", "十年月平均最低温"],
      defaultValue: [0, 0, 0, 0, 0, 0],
      data: tmpList.historyMaxMinList.map<ITable<1, 4>["data"][0]>((item, index) => [
        `${item.month}月`,
        `${item.month}月`,
        !isNil(tmpList.currentMaxMinList[index].max) ? tmpList.currentMaxMinList[index].max : ("-" as any),
        !isNil(item.max) ? item.max : ("-" as any),
        !isNil(tmpList.currentMaxMinList[index].min) ? tmpList.currentMaxMinList[index].min : ("-" as any),
        !isNil(item.min) ? item.min : ("-" as any),
      ]),
      suffix: ["", "", "℃", "℃", "℃", "℃"],
    };

    return (
      <>
        <McCard

            title={"温度分析"}
          >
            <WeatherAnalyse data={this.getMaxMin(Number(tmpList.currentMax) ,Number(tmpList.historyMax) , "℃", tmpList.arrowMax, "十年日最高温", "今日最高温")}/>
            <WeatherAnalyse data={this.getMaxMin(Number(tmpList.currentMin), Number(tmpList.historyMin) , "℃", tmpList.arrowMin, "十年日最低温", "今日最低温")}/>
            <div style={{ height: "150px"}}>
            <LineChartDouble
                chartData={dataS1N4}
                colorPalette={{ ...colorPalette, subColor: ["#ee414d", "#e08686", "#14a7be", "#74c7d4"] }}
            />
            </div>

        </McCard>
      </>
    );
  }

  renderRaininess = () => {
    const { apcpList } = this.props;
    if (apcpList.currentList.length === 0 || apcpList.historyList.length === 0) {
      return null;
    }
    const dataS1N2: ITable<1, 2> = {
      title: ``,
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["ID", "月份", "当前值", "历史值"],
      data: apcpList.historyList.map<ITable<1, 2>["data"][0]>((item, index) => [
        item.month.toString(),
        item.month.toString() + "月",
        apcpList.currentList[index].monthValue,
        item.monthValue ? item.monthValue : ("0" as any),
      ]),
      suffix: ["", "", "mm", "mm"],
    };
    return (
      <>
        <McCard
            title={"累计降水分析"}
          >
            <WeatherAnalyse data={this.getMaxMin(apcpList.current, apcpList.history, "mm", apcpList.arrow, "十年平均", "今年")}/>
            {/* <AxisChart
              chartData={getDataS2N1("month", ["今年", "十年平均"])}
              colorPalette={{ ...colorPalette, subColor: ["#13d7c8", "#e77300", ...colorPalette.subColor] }}
            /> */}
          <div style={{ height: "150px"}}>

            <BarChartDouble
              chartData={dataS1N2}
              colorPalette={{ ...colorPalette, subColor: ["#14a7be", "#8ae6f0"] }}
            />
          </div>

        </McCard>
      </>

    );
  }

  renderAccumulateTemperature = () => {
    const { sumtmpList, sumtmpList:{ current, history, ratio, arrow } } = this.props;
    if (sumtmpList.currentList.length === 0 || sumtmpList.historyList.length === 0) {
      return null;
    }
    const dataS1N2: ITable<1, 2> = {
      title: ``,
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["ID", "月份", "当前值", "历史值"],
      data: sumtmpList.historyList.map<ITable<1, 2>["data"][0]>((item, index) => [
        item.month.toString(),
        item.month.toString() + "月",
        sumtmpList.currentList[index].monthValue,
        item.monthValue ? item.monthValue : ("0" as any),
      ]),
      suffix: ["", "", "℃·d", "℃·d"],
    };
    return (
      <>
        <McCard
            title={"有效积温分析"}
          >
            <WeatherAnalyse data={this.getMaxMin(current, history, "℃·d", arrow, "十年平均", "今年")}/>
          <div style={{ height: "150px"}}>

            <LineChartDouble
                chartData={dataS1N2}
                colorPalette={{ ...colorPalette, subColor: ["#ee414d", "#e08686"] }}
            />
          </div>

        </McCard>
      </>

    );
  }
  render() {
    const { region } = this.state;
    return (
      <>
        {this.renderTemperatureAnalyse()}
        {this.renderRaininess()}
        {this.renderAccumulateTemperature()}
      </>
    );
  }

 }
