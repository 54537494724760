import React from "react";
import {User} from "../models/user";
import userProvider from "../network/providers/user.provider";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {tuple} from "../utils/type";
import {updateServerConfigApiToken, serverConfig} from "../network/server-config";
import authFailEmiter from "../utils/auth-fail-emiter";
import {HttpClient} from "../network/http-client";
import Cookies from "js-cookie";
import qs from "query-string";
import Axios from "axios";
import cropProvider from "../network/providers/crop.provider";

const SY_SESSION_ID = "jeesite.session.id";

const defaultUserInfo: User = {
    username: "",
    token: "empty_token",
    role: "user",
    name: "",
    userId: -1,
    displayName: "",
    departmentName: "",
    rolesInfo: {},
};

/** 默认值 */
const defaultValue = {
    /** 登陆用户 */
    userInfo: defaultUserInfo as User,
    /** 登入 */
    async login(username: string, password: string) {
        return {username};
    },
    /** 登出 */
    async logout() {
    },
    /** 已登陆 */
    isLogin: false,
};

// tslint:disable-next-line:variable-name
export const UserContext = React.createContext(defaultValue);

export type UserState = typeof defaultValue;

type UserKeys = keyof User;

/** 保存在 sessionStorage 中的 key 信息 */
export const sessionKeys: UserKeys[] = tuple("username", "token", "rolesInfo", "displayName", "role");

/**
 * 用户信息相关信息和操作
 * @author luyaxiang
 * @date 2019.9.20
 * @class UserContextDefaultProvider
 * @extends {React.Component<RouteComponentProps, UserState>}
 */
class UserContextDefaultProvider extends React.Component<RouteComponentProps, UserState> {
    http: HttpClient;

    constructor(props: any) {
        super(props);
        this.http = new HttpClient();

        authFailEmiter.fail = this.fail;
        const newUserMessage: UserState = {...defaultValue};
        const tempUsername = sessionStorage.getItem("username");
        const tempToken = sessionStorage.getItem("token");
        const tempRole = sessionStorage.getItem("role");
        const tempRolesInfo = sessionStorage.getItem("rolesInfo");
        const tempName = sessionStorage.getItem("displayName");
        if (typeof tempUsername === "string") {
            newUserMessage.userInfo.username = tempUsername;
        }

        if (tempRole === "admin" || tempRole === "user") {
            newUserMessage.userInfo.role = tempRole;
        }

        if (typeof tempName === "string") {
            newUserMessage.userInfo.name = tempName;
        }

        if (tempToken) {
            newUserMessage.isLogin = true;
            newUserMessage.userInfo.token = tempToken;
            updateServerConfigApiToken(tempToken);
        }

        newUserMessage.userInfo.rolesInfo = tempRolesInfo ? JSON.parse(tempRolesInfo) : {};

        this.state = {
            ...newUserMessage, ...{
                login: this.login,
                logout: this.logout,
                setUerInfo: this.setUerInfo,
            },
        };
    }

    /** 失败 */
    fail = () => {
        this.setState(
            {
                userInfo: defaultUserInfo,
                isLogin: false,
            },
        );
    }

    /** 更新（设置）用户信息 */
    setUerInfo = (result: User) => {
        sessionKeys.forEach((key: UserKeys) => {
            let tempValue = result[key].toString();
            if (typeof result[key] === "object") {
                tempValue = JSON.stringify(result[key]);
            }
            sessionStorage.setItem(key, tempValue);
        });
        updateServerConfigApiToken(result.token);
        this.setState({
            userInfo: result,
            isLogin: true,
        }, () => {
            // this.props.history.replace("/");
        });
        return result;
    }

    /** 登录 */
    login = async (username: string, password: string) => {
        return userProvider.login(username, password).then(
            async res => {
                if (res.message === "USERNAME_OR_PASSWORD_ERROR") {
                    return res;
                }
                await this.loginSy({username, password});
                this.setUerInfo(res);
                await cropProvider.ruoYiLogin()
                return res;
            },
        );
    }

    /**
     * 登陆溯源系统
     *
     * @param {string} url
     * @param {{ [key: string]: string }} params
     * @param {string} [target]
     * @memberof UserContextDefaultProvider
     */
    loginSy = async (params: { [key: string]: string }) => {
        const loginUrl = `${serverConfig.api_sy_v1}/login`;
        const res = await this.http.get(loginUrl);
        if (res.data) {
            Cookies.set(SY_SESSION_ID, res.data);
        }
        const resP = await Axios.post(
            loginUrl, qs.stringify(params),
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        ).then((resSuc) => resSuc)
            .catch((resError) => resError);
        return resP;
    }

    /**
     * 登出
     *
     * @memberof UserContextDefaultProvider
     */
    logout = () => {
        return userProvider.logout()
            .then(
                async res => {
                    await this.logoutSy();
                    sessionStorage.removeItem("token");
                    updateServerConfigApiToken("empty_token");
                    this.setState({
                        userInfo: {...defaultUserInfo},
                        isLogin: false,
                    });
                },
            );
    }

    /**
     * 溯源退出
     *
     * @memberof UserContextDefaultProvider
     */
    logoutSy = async () => {
        await Axios.get(
            `${serverConfig.api_sy_v1}/logout`,
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
            .then((resSuc) => console.log(resSuc))
            .catch((resError) => console.log(resError));
        Cookies.remove(SY_SESSION_ID);
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default withRouter(UserContextDefaultProvider);
