import { Avatar, Divider } from "antd";
import React from "react";
import styled from "styled-components";
import droughtWarning from './img/droughtWarning.png';
import coldWarning from './img/coldWarning.png';
import rainWarning from './img/rainWarning.png';
import temperatureWarning from './img/temperatureWarning.png';
import bg from "./img/card-bg@2x.png";


const Wrap = styled.div`
      padding:8px;
      border-radius: 6px;
      background-color: rgba(0, 22, 23, 0.04);
      background-image: url(${bg});
      background-size: 100% 100%;
      width:100%;

      .warning-header {
        display:flex;
        align-items:center;
        .warning-title {
          margin-left:8px;
          font-size:14px;
          color:#00e5ff;
          font-weight: 500;
          line-height:1;
        }
      }

      .desc-title {
        opacity: 0.9;
        font-family: PingFangSC;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #e0feff;
        margin:8px 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .desc-content {
        opacity: 0.45;
        font-family: PingFangSC;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #e0feff;
      }

`;

const getWaringIcon = (disaster:string) => {
  switch(disaster) {
    case '干旱':return droughtWarning;break;
    case '低温':return coldWarning;break;
    case '暴雨':return rainWarning;break;
    case '高温':return temperatureWarning;break;
  }
} 

interface P {
  /** 灾害*/
  disaster: string;
  /** 影响 */
  influence?: string;
  /** 措施 */
  measure?: string;
}

/**
 * 气象预警卡片
 */
export const WarningCard = (props: P) => {
    const { disaster, influence, measure } = props;
    return (
      <Wrap>
        <div className="warning-header">
          <Avatar size={24} src = {getWaringIcon(disaster)}/>
          <div className="warning-title">{disaster}预警</div>
        </div>
        <div style={{ marginBottom: 12 }} className="desc-title" title={influence || ""} >造成影响：{influence || ""}</div>
        <div className="desc-title" title={measure || ""}>措施：{measure || ""}</div>
      </Wrap>
    );
}