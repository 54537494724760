import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
// tslint:disable-next-line: ter-max-len
import { CommoditySummary, CommodityTrend, ClassifySummary, CategorySummary } from "../../models/commodity-germplasm";
import { ITable } from "@gago/frame";
// tslint:disable:ter-max-len

/**
 * 商品种质资源管理，数据服务层
 * @author maoshengtao
 */
export default class CommodityGermplasmProvider extends BaseProvider {

  /** 获取 商品种质资源管理-资源库汇总 */
  static async getCommoditySummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<CommoditySummary>>("/plant/resource/commodity/summary");
    return data;
  }

  /** 获取 商品种质资源管理-种植资源入库趋势 */
  static async getCommodityTrend() {
    const { data } = await BaseRequestStaticServer.get<ResultType<CommodityTrend[]>>("/plant/resource/commodity/putInventory");
    return data;
  }

  /** 获取 商品种质资源管理-种质来源统计 */
  static async getCategorySummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<CategorySummary[]>>("/plant/resource/commodity/sourceSummary");
    return data;
  }

  /** 获取 商品种质资源管理-商品种质分类数量排名 */
  static async getClassifySummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ClassifySummary[]>>("/plant/resource/commodity/classifySummary");
    return data;
  }

}
