import { ITable } from "@gago/frame";
import { ResultType } from "../../models/common";
import {
  BaseRequest,
  AreaDistribution,
  ProductionSummary,
  Phenophase,
} from "../../models/production-forecast";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";

/**
 * 繁育气候
 * @author wangchengbing
 * @date 2021-1-12
 * @export
 * @class
 * @extends {BaseProvider}
 */
export default class ProductionForecastProvider extends BaseProvider {
  /** 产量地区分布 */
  static async getAreaDistribution(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<AreaDistribution[]>>("/plant/breed/yield/areaDistribution", { params });
    return data;
  }
  /** 产量汇总 */
  static async getProductionSummary(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ProductionSummary>>("/plant/breed/yield/summary", { params });
    return data;
  }

  /** 作物理论物候期 */
  static async getPhenophase() {
    const { data } = await BaseRequestStaticServer.get<ResultType<Phenophase[]>>("/plant/breed/cropPeriod", { });
    return data;
  }
}
