// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import {Form, Row, Col, Input, Button, Select, DatePicker, Upload, Icon, Modal, Empty} from "antd";
import styled from "styled-components";
import {FormComponentProps} from "antd/lib/form";
import {BaseInfo} from "../../models/germplasm-database";
import {UploadChangeParam} from "antd/lib/upload";
import {UploadFile} from "antd/lib/upload/interface";
import {get, uniqueId} from "lodash";

const {Option} = Select;


const Wrap = styled.div`
  width: 100%;
  margin-top: 16px;

  .info {
    display: flex;
    align-items: center;
    font-family: PingFangSC;
    font-size: 22px;
    font-weight: bold;
    color: #001617;
    margin-bottom: 10px;

    .info-icon {
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      margin-right: 8px;
    }

    .right-component {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  border-radius: 4px;
  //background-color: rgba(241, 245, 245, 0.45);
  width: 92%;

  .ant-form-item {
    display: flex;
    margin-bottom: 12px;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

type P = {
    /** title */
    title: React.ReactNode;
    /** 一行几列 一行 24等分 默认 一列为6 即 一行4个*/
    colNum?: number;
    /** data */
    data?: any;
    typeNum: number;
    /** 右侧 头部*/
    rightComponent?: React.ReactNode;
    /** 是否为预览模式的多张图片上传 */
    useMultiplePreview?: boolean;
};

interface S {
    /** 预览文件链接*/
    previewFile?: string;
    /** 文件 */
    filelist?: UploadFile<any>[];
    /** 更新文件预览 */
    updatePreviewFile?: UploadFile<any>;
}

/**
 * 生成 表单
 * @author maoshengtao
 */
class GenerateCategory extends React.Component<P, S> {
    state: S = {
        filelist: [],
    }
    baseName: any = {
        province: '省',
        city: '市',
        district: '园区',
        base: '基地',
        plotName: '地块',
        breedName: '品种',
        useDate: "使用时间",
        methodName: "方法",
        beforePh: '施肥前ph',
        organicContent: '施肥有机质含量',
        nutrientContent: '施肥前养分含量',
        rainfall: '降雨量',
        airTemp: '气温',
        airHum: '空气湿度',
        soilTemp: '土壤温度',
        soilHum: '土壤湿度',
        accTemp: '积温',
        lat: "纬度",
        lon: "经度",
        photo: "照片",
        plant: '品种植株性状调查',
        fruit: '品种果实性状调查',
        resistance: '品种抗性调查',
        yield: '品种产量',
        method: "方法",
        period: "时间"
    }


    componentDidMount() {

    }

    /** 生成 form item */
    getFields = () => {
        const {colNum, data, typeNum} = this.props;
        if (!data || data.length === 0) {
            return null;
        }
        let dataInfo = [];
        for (let i in this.baseName) {
            if (data[i]) {
                let r = {
                    name: this.baseName[i],
                    eName: i,
                    value: data[i]
                }
                dataInfo.push(r)
            }
        }
        return dataInfo.map((item: any) => {
            return (
                <Col span={8} key={item.name}>
                    <Form.Item label={`${item.name}${item.unit ? `(${item.unit})` : ""}`}>
                        {this.renderItemType(item)}
                    </Form.Item>
                </Col>
            );
        })
    }

    renderItemDetail() {
        const {typeNum, data} = this.props;
        if (!data || !data.detailList) {
            return null;
        }
        let dataInfo: any = data.detailList
        if (typeNum == 1 || typeNum == 2) {
            return dataInfo.map((item: any) => {
                return (
                    <Col span={8} key={item.fertilizerName}>
                        <Form.Item label={`${item.fertilizerName}${item.unit ? `(${item.unit})` : ""}`}>
                            <Input value={item.amount || "——"} disabled/>;
                        </Form.Item>
                    </Col>
                );
            })
        } else if (typeNum == 3) {
            return dataInfo.map((item: any) => {
                return (
                    <>
                        <Col span={6} key={`农药名称`}>
                            <Form.Item label={`农药名称`}>
                                <Input value={item.medicineName || "——"} disabled/>;
                            </Form.Item>
                        </Col>
                        <Col span={6} key={`农药类型`}>
                            <Form.Item label={`农药类型`}>
                                <Input value={item.kind || "——"} disabled/>;
                            </Form.Item>
                        </Col>
                        <Col span={6} key={`使用方法`}>
                            <Form.Item label={`使用方法`}>
                                <Input value={item.method || "——"} disabled/>;
                            </Form.Item>
                        </Col>
                        <Col span={6} key={`亩均用量(单位:公斤每亩)`}>
                            <Form.Item label={`亩均用量(单位:公斤每亩)`}>
                                <Input value={item.amount || "——"} disabled/>;
                            </Form.Item>
                        </Col>
                    </>
                );
            })
        } else if (typeNum == 4) {
            return dataInfo.map((item: any) => {
                return (
                    <>
                        <Col span={8} key={`农药名称`}>
                            <Form.Item label={`农药名称`}>
                                <Input value={item.medicineName || "——"} disabled/>;
                            </Form.Item>
                        </Col>
                        <Col span={8} key={`使用方法`}>
                            <Form.Item label={`使用方法`}>
                                <Input value={item.method || "——"} disabled/>;
                            </Form.Item>
                        </Col>
                        <Col span={8} key={`亩均用量(单位:公斤每亩)`}>
                            <Form.Item label={`亩均用量(单位:公斤每亩)`}>
                                <Input value={item.amount || "——"} disabled/>;
                            </Form.Item>
                        </Col>
                    </>
                );
            })
        }
    }

    /** render form item 类型 */
    renderItemType = (item: any) => {
        if (item.eName === "photo") {
            let data = JSON.parse(item.value);
            if (data && data.length > 0) {
                return data.map((ite: any) => {
                    return (<Button onClick={this.handleOk.bind(this, `${ite || ""}`)}> <Icon type="eye"/> 预览</Button>);
                })
            } else {
                return null
            }
        }
        return <Input value={item.value || "——"} disabled/>;

    }

    /** preview ok */
    handleOk = (file: string) => {
        this.setState({
            previewFile: file,
        });
    }

    /** preivew cancle */
    handleCancel = () => {
        this.setState({
            previewFile: undefined,
        });
    }

    /** render upload preview */
    renderPreview = () => {
        const {previewFile} = this.state;
        if (!previewFile) {
            return null;
        }
        let child = <Empty description="不支持预览"/>;
        if (/.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(previewFile)) {
            child = <img src={"http://zhongzhi.admin.grassict.cn" + previewFile} style={{maxWidth: "100%"}}/>;
        }
        if (/pdf$/.test(previewFile)) {
            child = <iframe src={previewFile} width="100%" height={660}/>;
        }
        if (previewFile && /(doc|docx|ppt|pptx|xls|xlsx)$/.test(previewFile)) {
            child = <iframe width="660px" height="660px" src={`https://view.officeapps.live.com/op/view.aspx?src=${previewFile}`}></iframe>
        }
        return (
            <Modal
                wrapClassName={"control-width-660"}
                title={`预览`}
                visible={!!this.state.previewFile}
                footer={<Button type={"primary"} onClick={this.handleCancel}>关闭</Button>}
                onCancel={this.handleCancel}
            >
                <div style={{display: "flex", justifyContent: "center"}}>
                    {child}
                </div>
            </Modal>
        );
    }

    render() {
        return (
            <Wrap>
                <div className="info">
                    <span className="info-icon"/>
                    <span>{'基本信息'}</span>
                </div>
                {this.renderPreview()}
                <WrapForm>
                    <Row gutter={24}>
                        {this.getFields()}
                    </Row>
                </WrapForm>
                <div className="info">
                    <span className="info-icon"/>
                    <span>{'附加信息'}</span>
                </div>
                <WrapForm>
                    <Row gutter={24}>
                        {this.renderItemDetail()}
                    </Row>
                </WrapForm>
            </Wrap>
        );
    }
}

export {
    GenerateCategory,
}
