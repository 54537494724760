// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Button, Form, Modal, message } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { PageBody } from "./styled";

import { HeaderBox } from "../../containers/header-box";
import { LeftModule1 } from "./left-module1";
import { LeftModule2 } from "./left-module2";
// tslint:disable-next-line: ter-max-len

import DictionaryProvider from "../../network/providers/dictionary-base.provider";
import * as DictionaryBaseInterface from "../../models/dictionary-base";
import { PaginationConfig } from "antd/lib/table";

interface S {
  /** 对话框是否可见 */
  visible: boolean;
  /** 顶部数据搜索 */
  topSearch?: any;
  /** 当前页数 */
  pagination?: PaginationConfig;
  // 选择的数据行数
  selectRowKeysCopy: string[];
  /** 顶部卡片类型 */
  cardType?: number;
  /** 顶部搜索表单 */
  topForm?: FormComponentProps["form"];
  /** 地块列表 */
  baseLandList?: DictionaryBaseInterface.BaseLandList;
}

/**
 * 数据字典 —— 基地管理
 * @author maoshengtao
 */
export class DictionaryBaseManagement extends React.PureComponent<{}, S> {
  state: S = {
    visible: false,
    topSearch: {},
    selectRowKeysCopy: [],
  };

  componentDidMount() {
   this.getBaseList();
  }

  /** get 地块列表 */
  getBaseList = async () => {
    const { topSearch, pagination } = this.state;
    const baseLandList = await DictionaryProvider.getBaseLandList({
      ...topSearch,
      page: pagination?.current || 1,
      size: pagination?.pageSize || 10,
    });
    this.setState({ baseLandList });
  };

  // 获取表单的selectRowKeys
  handleSelectRowKeys = (params: string[]) => {
    this.setState({
      selectRowKeysCopy: params,
    });
  };

  /** top search */
  onTopSearch = (topSearch: any) => {
    this.setState({ topSearch }, () => {
     this.getBaseList();
    });
  };


  /** 处理分页编号请求 */
  handlePagination = (pagination: PaginationConfig) => {
    if (pagination && pagination.current) {
      this.setState({ pagination }, () => {
        this.getBaseList();
      });
      
    }
  };

  render() {
    const {
      pagination,
      baseLandList,
    } = this.state;
    return (
      <HeaderBox names={["基地管理"]}>
        <PageBody>
          <div className="page-left">
            <div className="page-left-bottom">
              <div className="search-module">
                <LeftModule1
                  onTopSearch={this.onTopSearch}
                  onLoad={this.topFormLoad}
                />
              </div>
              <div className="table-module">
                <LeftModule2
                  handleSelectRowKeys={this.handleSelectRowKeys}
                  handlePagination={this.handlePagination}
                  pagination={pagination}
                  baseList={baseLandList}
                />
              </div>
            </div>
          </div>
        </PageBody>
      </HeaderBox>
    );
  }

  /** top form load */
  topFormLoad = (form: FormComponentProps["form"]) => {
    this.setState({ topForm: form });
  };

  /** handle clear top search */
  handleClearTopSearch = () => {
    const { topForm } = this.state;
    this.setState({ topSearch: {} });
    topForm && topForm.resetFields();
  };
}
