// tslint:disable: variable-name naming-convention jsx-no-multiline-js jsx-wrap-multiline
import React from "react";
import { Divider, Modal, Popover, Table, Popconfirm, Button } from "antd";
import { divide, isNull, noop } from "lodash";
import styled, { createGlobalStyle } from "styled-components";
import { ResourceList } from "../../models/germplasm-database";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { DownloadQR } from "@gago/lib/components/qr-code-download";
import { AddOverlay } from "../../containers/add-overlay";
import { EditOverlay } from "../../containers/edit-overlay";
import { DetailOverlay } from "../../containers/detail-overlay"
import { PaginationConfig } from "antd/lib/table";
import SourceInfo from "../../pages/source-info";


const StyleButton = styled.a`
  font-family: PingFangSC;
  font-size: 20px;
  color: #00adad;
`;

const titleList1 = [ "种质编号", "天赋河套编号" ];
const titleList2 = [ "作物分类", "二级分类", "选育单位"];
interface Datatype {
  /** key */
  key: string;
  /** name */
  天赋河套编号: string;
  /** name */
  种质编号: string;
  /** name */
  种质名称: string;
  /** name */
  作物分类: string;
  /** name */
  二级分类: string;
  /** name */
  选育单位: string;
};

interface P {
  /** 表格数据 */
  resourceList?: ResourceList;
  /** 删除种质数据 */
  resourceDel(id: string): void;
  /** 处理分页 */
  handlePagination(config: PaginationConfig): void;
  // 处理table复选框
  handleSelectRowKeys(params:string[]):void;
  /** 当前页数 */
  pagination?: PaginationConfig;
}

interface S {
  /** 选择的行数 的key集合 */
  selectedRowKeys: string[];
  /** 二维麻状态 */
  qrstatus?: boolean;
  /** data */
  data?: Datatype[];
  /** 当前点击编辑的 的种质资源 的种质编号*/
  activeNumber?: string;
  /** 当前点击详情的 的种质资源 的种质编号*/
  detailNumber?: string;
  /** h5 modal number */
  h5ModalNumber?: string;
  
}

/**
 * 左侧第二个模块 表格组件
 * @author maoshengtao
 */
export class LeftModule2 extends React.PureComponent<P, S> {
  columns: any[];

  static defaultProps = {
    pagination: {},
  }
  

  constructor(props: P) {
    super(props);
    const { resourceList } = this.props;
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      data: this.handleData(resourceList),
    };
    this.columns = [
      ...titleList1.map((item, ind) => ({
        title: <span title={item}>{item}</span>,
        dataIndex: item,
        ellipsis: true,
      })),
      this.renderTableName(),
      ...titleList2.map((item, ind) => ({
        title: <span title={item}>{item}</span>,
        dataIndex: item,
        ellipsis: true,
      })),
      this.renderOperation1(),
      this.renderOperation2(),
    ] 
  }

  renderTableName = () => {
    return {
      title: "种质名称",
      dataIndex: "种质名称",
      ellipsis: true,
      render: (v: any, o: any) => {
        return  (<>
          <StyleButton onClick={this.handleDetailClick.bind(this, o.种质编号)}>{v}</StyleButton>
        </>)
      }
    };
  }

  renderOperation1 = () => {
    const token = window.sessionStorage.getItem("token") as string;
    return {
        title: "二维码",
        fixed: "right",
        width: 215,
        render: (v: any) => {
          return (<>
                  <Popover
                    title="请用手机扫码二维码"
                    // visible={qrstatus}
                    trigger="hover"
                    content={
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <QRCode
                          value={encodeURI(`${window.location.origin}/source-info/${v.key}?token=${token}`)}
                          size={100}
                          fgColor="#000000"
                          level="M"
                        />
                      </div>
                    }
                  >
                   <StyleButton>预览</StyleButton>
                  </Popover>
                  <Divider type="vertical"/>
                  <StyleButton 
                    onClick={this.handleH5Click.bind(this, v.种质编号)}
                  >
                    h5预览
                  </StyleButton>
                  <Divider type="vertical"/>
                  <StyleButton>
                    <DownloadQR
                      url={encodeURI(`${window.location.origin}/source-info/${v.key}?token=${token}`)}
                      id={v.key}
                      text={"下载"}
                    />
                  </StyleButton>
                </>)
        }
      };
  }

  renderOperation2 = () => {
    const { resourceDel } = this.props;
    return {
      title: "操作",
      fixed: "right",
      width: 150,
      render: (v: any) =>
        <>
          <StyleButton 
            onClick={this.handleEditClick.bind(this, v.种质编号)}
          >
            编辑
          </StyleButton>
          <Divider type="vertical"/>
          <Popconfirm title="确认删除嘛？" okText="确认" cancelText="取消" onConfirm={resourceDel.bind(this, v.id)}>
            <StyleButton>删除</StyleButton>
          </Popconfirm>
        </>,
    };
  }

  componentDidUpdate(preProps: P) {
    if (this.props.resourceList !== preProps.resourceList) {
      this.setState({ data: this.handleData(this.props.resourceList) });
    }
  }

  /**选择发生变化 */
  onSelectChange = (selectedRowKeys: any) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    const { handleSelectRowKeys } = this.props;
    this.setState({ selectedRowKeys });
    handleSelectRowKeys(selectedRowKeys);
    
  }

  /** handle data */
  handleData = (rawData?: ResourceList) => {
    if (!rawData) {
      return [];
    }
    return rawData.list.map((item, ind) => {
      return {
        key: (item.accessionNumber || ind).toString(),
        id: item.id,
        种质编号: item.accessionNumber || "-",
        天赋河套编号: item.genebankNumber || "-",
        种质名称: item.accessionName || "-",
        作物分类: item.oneLevel || "-",
        二级分类: item.twoLevel || "-",
        选育单位: item.departmentName || "-",
      };
    })
  }

/** handle overlay open */
handleEditClick = (activeNumber: string) => {
  this.setState({ activeNumber });
}

/** handle overlay close*/
handleEditCancle = () => {
  this.setState({ activeNumber: undefined });
}

renderEditOverlay = () => {
  const { activeNumber } = this.state;
  if (!activeNumber) {
    return null;
  }
  return (
    <EditOverlay
      visible={!!activeNumber}
      accessionNumber={activeNumber}
      onCancel={this.handleEditCancle}
      title={"编辑种质资源"}
      type="commodity"
    />
  );
}

/** handle overlay open */
handleDetailClick = (detailNumber: string) => {
  this.setState({ detailNumber });
}

/** handle overlay close*/
handleDetailCancle = () => {
  this.setState({ detailNumber: undefined });
}

renderDetailOverlay = () => {
  const { detailNumber } = this.state;
  if (!detailNumber) {
    return null;
  }
  return (
    <DetailOverlay
      visible={!!detailNumber}
      accessionNumber={detailNumber}
      onCancel={this.handleDetailCancle}
      title={"查看种质资源"}
    />
  );
}
/** handle overlay open */
handleH5Click = (h5ModalNumber: string) => {
  this.setState({ h5ModalNumber });
}

/** handle overlay close*/
handleH5Cancle = () => {
  this.setState({ h5ModalNumber: undefined });
}

renderH5Modal = () => {
  const { h5ModalNumber } = this.state;
  if (!h5ModalNumber) {
    return null;
  }
  return (
    <Modal
      visible={!!h5ModalNumber}
      onCancel={this.handleH5Cancle}
      footer={<Button type="primary" onClick={this.handleH5Cancle}>关闭</Button>}
    >
      <div style={{ height: 700, position: "relative", overflowY: "auto" }}>
        <SourceInfo accessionNumber={h5ModalNumber} />
      </div>
    </Modal>
  );
}

  

  /** 处理分页 */
  handlePagination = (pagination: PaginationConfig) => {
    this.props.handlePagination(pagination);
  }

  render() {
    const { selectedRowKeys, data } = this.state;
    const { resourceList, pagination } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "选择全部",
          onSelect: () => {
            this.setState({
              selectedRowKeys: data ? [...data.map((item) => item.key)] : [], // 0...45
            });
          },
        },
        {
          key: "odd",
          text: "选择奇数行",
          onSelect: (changableRowKeys: any) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key: string, index: number) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: "even",
          text: "选择偶数行",
          onSelect: (changableRowKeys: any) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key: string, index: number) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
    const h = window.document.documentElement.offsetHeight - 545;
    return (
      <>
        <Table
          className={"table-font-control"}
          key={`${data && data.length}`}
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={data || []}
          scroll={{ x: "100%", y: `${h < 0 ? 100 : h}px` }}
          onChange={this.handlePagination}
          pagination={
            {
              current: pagination && pagination.current,
              showQuickJumper: true,
              onShowSizeChange: noop,
              showSizeChanger: true,
              total: resourceList ? resourceList.count : undefined,
              // tslint:disable-next-line: jsx-alignment
            }
          }
        />
        {this.renderEditOverlay()}
        {this.renderDetailOverlay()}
        {this.renderH5Modal()}

      </>
    );
  }
}
