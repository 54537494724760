import styled from "styled-components";

// tslint:disable-next-line:variable-name
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  .breed-timeline {
    left:10px;
    position:absolute;
    bottom:16px;
    /* transform:translateX(0); */
    max-width:52%;
    min-width:26%
  }
  .breeding-distribute-side{
    .sidebar-header-box {
        background-color: #00adad;
      }
  }
`;
export const mapDataList: {label: string; value: string}[] = [
  { label: "向日葵", value: "sunflower" },
  { label: "玉米", value: "corn" },
  { label: "小麦", value: "wheat" },
  { label: "辣椒", value: "chilli" },
];

/**获取真实切片名称 */
export const switchQiePianName = (crop?: string) => {
  if (crop === "grassland") return "grass";
  return crop;
};
