// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Modal, Button, Steps, Select, message, Spin, Cascader } from "antd";
import { ICSSProps } from "@gago/frame";
import { ModalProps } from "antd/lib/modal";
import styled, { createGlobalStyle } from "styled-components";
import { TopCategory } from "./top-category";
import { GenerateCategory } from "./generate-category";
import { FormComponentProps } from "antd/lib/form";
import { CropCategory, BaseInfo, RegionTree, ResourctFindOne } from "../../models/germplasm-database";
import { TestReport } from "../../models/germplasm-test-report";
import { BreedingReport } from "../../models/molecular-breeding-report";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import GermplasmTestReportProvider from "../../network/providers/germplasm-test-report.provider";
import MolecularBreedingProvider from "../../network/providers/molecular-breeding-report.provider";
import { TESTREPORTLIST, BREEDINGREPORTLIST } from "./const";

import { cloneDeep, get, omit } from "lodash";

const { Option } = Select;
const { Step } = Steps;
const Wrap = styled.div`
  width: 1336px;
  height: 662px;
  overflow-y: auto;
  input:-webkit-autofill {
    /* 选择历史记录的文字颜色*/
    -webkit-text-fill-color: #333!important;
  }
`;

const GloabalStyle = createGlobalStyle`
.detail-overlay{
  .ant-modal-title{
    font-size: 20px;
  }
  .ant-form-item label{
    color: rgb(0,22,23, 0.95);
    font-size: 20px;
  }
  .ant-input[disabled] {
    border: none;
    color: rgb(0,22,23, 0.7);
    background: transparent;
    font-size: 20px;
    height: auto;
  }
  .ant-modal{
    width: 1368px!important;
  }
  .ant-steps-item-title {
    font-size: 20px!important;
  }
}
`;

type P = ICSSProps & ModalProps & {
  /** 种质编号 */
  accessionNumber: string;
  /** wrap classname */
  wrapClassName?: string;
};

interface S {
  /** 当前第几步骤 */
  step: number;
  /** 表单映射 */
  formMap: {
    [key: string]: FormComponentProps["form"];
  };
  /** 获取列表1 */
  list1?: BaseInfo[];
  /** 获取列表2 */
  list2: BaseInfo[];
  /** 获取列表3 */
  list3: BaseInfo[];
  /** 是否为loading */
  loading?: boolean;
  /** 种质编号 */
  accessionNumber?: number | string;
  /** 行政区划数 */
  regionTree?: RegionTree[];
  /** 获取单个种质资源信息 */
  resourctFindOne?: ResourctFindOne;
}

/**
 * 详情 弹窗
 * @author maoshengtao
 */
export class DetailOverlay extends React.Component<P, S> {
  state: S = {
    step: 0,
    formMap: {},
    list2: TESTREPORTLIST,
    list3: BREEDINGREPORTLIST,
  };

  componentDidMount() {
    this.getResourctFindOne();
    this.getOneResourceTestReport();
    this.getOneResourceBredingReport();
  }

  /** 获取单个种质资源信息 */
  getResourctFindOne = async () => {
    const { accessionNumber } = this.props;
    this.setState({ loading: true });
    const resourctFindOne = await GermplasmDatabaseProvider.getResourctFindOne(accessionNumber);
    this.setState({ resourctFindOne, list1: resourctFindOne.information, loading: false });
  }

  /** 获取单个种质 测试报告数据 */
  getOneResourceTestReport = async () => {
    const { accessionNumber } = this.props;
    const { list2 } = this.state;
    const tempData = await GermplasmTestReportProvider.getTestReport(accessionNumber);
    const templist2 = tempData && tempData.map((item, ind) => {
      const templist = cloneDeep(list2[0].list);
      Object.keys(item).forEach((i) => {
        templist.map((elm) => {
          if (elm.eName === i) {
            elm.value = item[i as keyof TestReport];
          }
        });
      });
      return {
        name:  `测试报告${ind}`,
        list: templist,
      };
    });
    this.setState({ list2: templist2 });
  }

  /** 获取单个种质 育种报告数据 */
  getOneResourceBredingReport = async () => {
    const { accessionNumber } = this.props;
    const tempData = await MolecularBreedingProvider.getBreedingReport(accessionNumber);
    const { list3 } = this.state;
    const templist3 = tempData && tempData.map((item, ind) => {
      const templist = cloneDeep(list3[0].list);
      Object.keys(item).forEach((i) => {
        templist.map((elm) => {
          if (elm.eName === i) {
            elm.value = item[i as keyof BreedingReport];
          }
        });
      });
      return {
        name:  `育种报告${ind}`,
        list: templist,
      };
    });
    this.setState({ list3: templist3 });
  }

  // tslint:disable-next-line: cyclomatic-complexity
  handleOk = (e: any) => {
    const { step, formMap } = this.state;
    if (step === 0) {
      this.setState({ step: 1 });
      return;
    }
    if (step === 1) {
      this.setState({ step: 2 });
      return;
    }
  }

  render() {
    const { onCancel, wrapClassName, ...other } = this.props;
    const { loading, resourctFindOne } = this.state;
    return (
        <>
        <Modal
          footer={this.renderModalFooter()}
          wrapClassName={`detail-overlay ${wrapClassName || ""}`}
          onCancel={onCancel}
          {...other}
        >
          <Wrap>
          {resourctFindOne && <TopCategory topCategory={omit(resourctFindOne, "information")} />}
            {this.renderStep()}
            <>
              {loading && (
                <div style={{ width: "100%", height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Spin/>
                </div>)
              }
              {this.renderGenerate1()}
              {this.renderGenerate2()}
              {this.renderGenerate3()}
            </>
          </Wrap>
        </Modal>
        <GloabalStyle/>
      </>
    );
  }

  /** render modal footer */
  renderModalFooter = () => {
    const { step } = this.state;
    const { onCancel } = this.props;
    return (
      <>
        <Button onClick={onCancel}>{"关闭"}</Button>
        {/* {step !== 2 && (
          <Button type={"primary"} onClick={this.handleOk} >{"下一个"}</Button>
        )} */}
      </>
    );
  }

  /** load form */
  onLoadForm1 = (name: string, form: FormComponentProps["form"]) => {
    this.setState((preState) => ({ formMap: { ...preState.formMap, [name]: form } }));
  }

  /** render form 1 */
  renderGenerate1 = () => {
    const { step, list1 } = this.state;
    if (step !== 0) {
      return null;
    }
    return (
      <>
        {list1 && list1.map((item) => {
          return (
            <GenerateCategory
              useMultiplePreview
              data={item}
              key={item.name}
              title={item.name}
            />
          );
        })}
      </>
    );
  }

  /** render form 1 */
  renderGenerate2 = () => {
    const { step, list2 } = this.state;
    if (step !== 1) {
      return null;
    }
    return (
      <>
        {list2 && list2.map((item, ind) => {
          return (
            <GenerateCategory
              data={item}
              key={`测试报告${ind}`}
              title={"报告明细"}
              colNum={12}
            />
          );
        })}
      </>
    );
  }

  /** render form 3 */
  renderGenerate3 = () => {
    const { step, list3 } = this.state;
    if (step !== 2) {
      return null;
    }
    return (
      <>
        {list3 && list3.map((item, ind) => {
          return (
            <GenerateCategory
              data={item}
              key={`${ind}`}
              title={"报告明细"}
              colNum={12}
            />
          );
        })}
      </>
    );
  }

  /** handle step change */
  handleStepChange = (current: number) => {
    this.setState({ step: current });
  }

  /** render 步骤条 */
  renderStep = () => {
    const { step } = this.state;
    return (
      <Steps
          type="navigation"
          size="small"
          current={step}
          onChange={this.handleStepChange}
          style={{
            border: "solid 1px rgba(0, 22, 23, 0.15)",
            fontSize: "20px",
          }}
        >
        <Step title="种质基本信息" />
        <Step title="种质测试报告" />
        <Step title="分子育种报告" />
      </Steps>
    );
  }

}
