import React from "react";
import { BaseLayer, LayerEvents, MapEvent, MapPosition, Source, GeoJSONSource, Layer, LineLayer, SymbolLayer, FillLayer, mapDefault, MapGLProps, MapGL } from "@gago-react-gl/gago-react-gl";
import { uniqueId } from "lodash";
import { Region } from "../../models/common";
import { bbox } from "@turf/turf";
import { MapContext, MapState } from "../../contexts/map"


const main = "bameng";
export const baMengMapConfig = {
  city: {
    source: `${main}-city-source`,
    line: `${main}-city-line`,
    fill: `${main}-city-fill`,
    shadow: `${main}-city-line-${uniqueId()}`,
    highLine: `${main}-city-high-line`,
    symbol: `${main}-city-symbol`,
    minzoom: 0,
    maxzoom: 17,
  },
  county: {
    source: `${main}-county-source`,
    line: `${main}-county-line`,
    fill: `${main}-county-fill`,
    shadow: `${main}-county-line-${uniqueId()}`,
    highLine: `${main}-county-high-line`,
    symbol: `${main}-county-symbol`,
    minzoom: 0,
    maxzoom: 17,
  },
  town: {
    source: `${main}-town-source`,
    line: `${main}-town-line`,
    fill: `${main}-town-fill`,
    shadow: `${main}-town-line-${uniqueId()}`,
    highLine: `${main}-town-high-line`,
    symbol: `${main}-town-symbol`,
    minzoom: 7,
    maxzoom: 17,
  },
}

const initBBox: [[number, number], [number, number]] = [[104.94727433007114, 39.68476362731326], [113.00003951135619, 42.98148907004972]];


interface P {
  /** 高亮的code */
  region: Region;
  /** 改变选择的区域 */
  changeRegion(region: Region): void;
}


/**
 * 巴盟 种质繁育模块地图
 * @author maoshengtao
 */
export class BaMengMap extends React.PureComponent<P>{

  static contextType = MapContext;
  
  render() {
    return (
      <>
        <MapPosition
          bbox={initBBox} moveMethod="jumpTo"
        />
        <BaseLayer id="tiandi_base_layer" type="TianDiTu_Satellite_Map"  />
        {this.renderCityLayer()}
        {this.renderCountyLayer()}
        {this.renderTownLayer()}
        {this.renderMapEvent()}
      </>
    );
  }

  /** render 市级 layer */
  renderCityLayer = () => {
    const { city } = baMengMapConfig;
    return (
      <>
        <Source<GeoJSONSource>
          id={city.source}
          type="geojson"
          data="/static/data/city.geojson"
        />
        <Layer<LineLayer>
          type="line"
          source={city.source}
          id={city.shadow}
          paint={{ "line-color": "#45f3ff", "line-width": 0.5, "line-translate": [0, 16] }}
        />
        <Layer<LineLayer>
          type="line"
          source={city.source}
          id={`${city.shadow}-2`}
          paint={{ "line-color": "#45f3ff", "line-width": 0.8, "line-translate": [0, 8] }}
        />
        <Layer<LineLayer>
          type="line"
          source={city.source}
          id={city.line}
          paint={{ "line-color": "#45f3ff", "line-width": 3 }}
        />
      </>
    );
  }

  /** render 县级 layer */
  renderCountyLayer = () => {
    const { region } = this.props;
    const { county } = baMengMapConfig;
    return (
      <>
        <Source<GeoJSONSource>
          id={county.source}
          type="geojson"
          data="/static/data/county.geojson"
          maxzoom={5}
          roundZoom
        />
        <Layer<FillLayer>
          type="fill"
          source={county.source}
          id={county.fill}
          paint={{ "fill-color": "rgba(0, 221, 255, 0.2)" }}
        />
        <Layer<LineLayer>
          type="line"
          source={county.source}
          id={county.shadow}
          paint={{ "line-color": "#000", "line-width": 2 }}
        />
        <Layer<LineLayer>
          type="line"
          source={county.source}
          id={county.line}
          paint={{ "line-color": "#45f3ff", "line-width": 1 }}
        />
        <Layer<LineLayer>
          type="line"
          source={county.source}
          id={county.highLine}
          paint={{ "line-color": "#ffcc3b", "line-width": 2.5 }}
          filter={["==", ["get", "countyCode"], region.code || ""]}
        />
        <Layer<SymbolLayer>
          type="symbol"
          source={county.source}
          id={county.symbol}
          layout={{
            "text-field": "{name}",
            "text-max-width": 6,
            "text-size": ["interpolate", ["linear"], ["zoom"], 8, 12, 10, 13],
            "text-font": ["Arial Unicode MS Regular"],
          }}
          paint={{ "text-color": "#fff", "text-halo-color": "#000", "text-halo-width": 1 }}
        />
      </>
    );
  }

  /** render 乡村  layer*/ 
  renderTownLayer = () => {
    const { town } = baMengMapConfig;
    const { region } = this.props;
    return (
      <>
         <Source<GeoJSONSource>
          id={town.source}
          type="geojson"
          data="/static/data/town.geojson"
        />
        <Layer<LineLayer>
          type="line"
          source={town.source}
          filter={["==", ["get", "countyCode"], region.code || ""]}
          id={town.line}
          paint={{ "line-color": "#45f3ff", "line-width": 0.8, "line-translate": [0, 8] }}
        />
         <Layer<SymbolLayer>
          type="symbol"
          source={town.source}
          filter={["==", ["get", "countyCode"], region.code || ""]}
          id={town.symbol}
          layout={{
            "text-field": "{name}",
            "text-max-width": 6,
            "text-size": ["interpolate", ["linear"], ["zoom"], 8, 12, 10, 13],
            "text-font": ["Arial Unicode MS Regular"],
          }}
          paint={{ "text-color": "#fff", "text-halo-color": "#000", "text-halo-width": 1 }}
        />
      </>
    );
  }

  /** 点击任意layer图层 */
  // tslint:disable-next-line: no-any
  handleLayerClick = (e: any) => {
    e.preventDefault();
    if (!e.features) {
      return ;
    }
    const { changeRegion } = this.props;

    const { properties:  { countyCode, name, cityCode, Lon, Lat, } } = e.features[0];

    const target: Region = {
      name,
      code: countyCode,
      parentCode: cityCode,
      level: "village",
      longitude: Lon,
      latitude: Lat,
    }
    
    changeRegion && changeRegion(target);

    if (this.context.map) {
      this.context.map.fitBounds(bbox(e.features[0]) as mapboxgl.LngLatBoundsLike)
    }

  }

  /** 点击地图有效区域以外 */
  // tslint:disable-next-line: no-any
  private handleOutMapClick = (e: any) => {
    if (e.defaultPrevented) return;
    this.props.changeRegion && this.props.changeRegion({ code: undefined, name: undefined, level: undefined, longitude: undefined, latitude: undefined, parentCode: undefined, parentName: undefined });
    if (this.context.map) {
      this.context.map.fitBounds(initBBox)
    }
  }

  renderMapEvent = () => {
    const { county } = baMengMapConfig;
    return (
      <>
        <LayerEvents layer={county.fill} onClick={this.handleLayerClick} />
        <MapEvent type="click" onChange={this.handleOutMapClick} />
      </>
    );
  }
}