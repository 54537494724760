// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Avatar, Rate,  } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";
import { ExpertCateInfo, AreaList, ExpertEditRequest } from "../../models/expert-database";
import { ImageSourceRaw } from "gago-mapbox-gl";
import titlePrefix from './expertDetailprefix.png';
import defaultAvatar from './defaultAvatar.png';

const PageDetail = styled.div`
  .detail-page {
    display:flex;
    /* padding:24px; */
    height:433px;
    .detail-left-container {
      width:176px;
      .expert-instroduce {
        
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        .expertName {
          text-align:center;
          font-size:16px;
          margin-top:8px;
          margin-bottom:15px;
          line-height:1;
        }
        .job-level {
          text-align:left;
          font-size:14px;
          margin-bottom:8px;
          color: #001617;
          line-height:1;
          width:100%;
        }      
        .position-title {
          text-align:left;
          font-size:14px;
          color: #001617;
          line-height:1; 
          width:100%;
        }
      }

    }
    .detail-right-container {
      flex:1;

      .detail-title {
          display:flex;
          align-items:center;
          .detail-title-icon {
            width:16px;
            height:16px;
          }
          .title-desc {
            font-size:16px;
            margin-left:8px;
            margin-bottom:0;
          }
        }

      .export-description-container {
        margin-bottom:32px;
        
        .presentation {
          height:200px;
          overflow-y:auto;
          padding-top:16px;
        }
      }

      .interested-expert-container {
        .interested-exports-list {
          display:flex;
          margin-top:16px;
          overflow-y:auto;
          flex-wrap:wrap;
          height:128px;

          .expert-card:hover{
              box-shadow: 0 4px 16px 0 rgba(0, 22, 23, 0.09);
              border:none;
          }
          .expert-card {
            margin-right:16px;
            padding-top:8px;
            margin-bottom:16px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center; 
            width: 96px;
            height: 128px;
            border-radius: 6px;
            border: solid 1px rgba(0, 22, 23, 0.09);
            background-color: #ffffff;

            
            .expert-avatar-box {
              overflow:hidden;
              width:40px;
              height:40px;
              border-radius:32px;
              .expert-avatar {
                width:40px;
                height:40px;
              }
            }
            .expert-name {
              font-size:14px;
              text-align:center;
              line-height:14px;
              margin-top:8px;
              margin-bottom:12px;
            }
            .expert-card-type {
              font-size:12px;
              text-align:center;
              color: #001617;
              line-height:12px;
              margin-bottom:6px;
            }
            .expert-position {
              font-size:12px;
              text-align:center;
              color: #001617;
              line-height:12px;
              margin-bottom:0;
            }
          }

        }
      }
    }
  }
`;

type P =  {
  /**get 数据列表 */
  // getResourceList(data: ResourceListRequest): void;
  /**专家分类 */
  expertCategory?: ExpertCateInfo;
  /** 专家类型 */
  expertType?: ExpertCateInfo;
  
  /** 所在地区 */
  areaList?: AreaList[];
  expertInfo?:any;
  interestedList?:ExpertEditRequest[];


};

interface S {
  
}

/**
 * 高级搜索
 * @author wangchengbing
 */
class ExpertDetail extends React.Component<P, S> {
  state: S = {

  };

  renderExpertCard = (cardList:ExpertEditRequest[] = []) => {
    if (!cardList) return null;
    return(
      <>
      {cardList.map((item) => {
        return (
          <div className="expert-card">
            {/* <div className="expert-avatar-box">
              <img src={item.headUrl} alt="" className="expert-avatar"/>
            </div> */}
            <Avatar src={item.headUrl ? item.headUrl : defaultAvatar} size={40}/>
            <h5 className="expert-name">{item.name}</h5>
            <p className="expert-card-type">{item.type}</p>
            <p className="expert-position">{item.position}</p>
          </div>
          )
      })
      }
      </>
    )
  }
  
  render() {
    // const { storageYears } = this.props;
    const { expertInfo, interestedList } = this.props;
    console.log('interestedExpert-->1',interestedList)

    return (
      <>
      <PageDetail>
        <div className="detail-page">

          <div className="detail-left-container">
            <div className="expert-instroduce">
              <Avatar src={expertInfo?.headUrl ? expertInfo?.headUrl : defaultAvatar} size={64}></Avatar>
              <h5 className="expertName">{expertInfo?.name}</h5>
              <div className="job-desc">
                <div className="job-level">级别：{expertInfo?.type}</div>
                <div className="position-title">职务：{expertInfo?.position}</div>
              </div>
              
            </div>
          </div>

          <div className="detail-right-container">
            <div className="export-description-container">
              <div className="detail-title">
                <img src={titlePrefix} alt="" className="detail-title-icon"/>
                <h5 className="title-desc">专家介绍</h5>
              </div>
              <p className="presentation">{expertInfo?.presentation}</p>
            </div>
            <div className="interested-expert-container">
              <div className="detail-title">
                <img src={titlePrefix} alt="" className="detail-title-icon"/>
                <h5 className="title-desc">你可能感兴趣的同类专家</h5>
              </div>
              <div className="interested-exports-list">
                {this.renderExpertCard(interestedList)}
              </div>
            </div>
          </div>
        </div>
      </PageDetail>
      </>
    );
  }
}
export default ExpertDetail;

// export const ExpertSearch = Form.create<P>({ name: "experts_search" })(ExpertSearchForm);
