import { observable, action, computed, reaction } from 'mobx';

import { Department, UserInfo } from './user-manage.service';

export interface TreeData {
  title: string;
  key: string;
  children?: TreeData[];
}

export interface RoleList {
  role: string;
  roleId: number;
}

function loop(departments: Department[], id: number | null): TreeData[] {
  return departments.filter(({ parentDepartmentId }) => parentDepartmentId === id)
    .map(({ departmentId, departmentName }) => ({
      title: departmentName,
      key: departmentId.toString(),
      children: loop(departments, departmentId),
    }));
}

const getDepartments = (deps: Department[], id: number) => {
  const childIds: number[] = [];
  const currentDeps = (depId: number) => {
    const currentDepInfos = deps.find(item => item.departmentId === depId);
    if (!currentDepInfos) return;
    const { departmentId, childDepartments } = currentDepInfos;
    childIds.push(departmentId);
    if (childDepartments.length !== 0) {
      childDepartments.map(item => currentDeps(item));
    }
  };
  currentDeps(id);
  return childIds;
};

class UserManageStore {
  @observable.ref treeData: TreeData[] = [];
  @observable.ref users: UserInfo[] = [];
  @observable.ref departments: Department[] = [];
  // @ts-ignore
  @observable currentDepartmentId: number = null;
  @observable.ref roleTree: RoleList[] = [];
  @computed get filterUsers() {
    if (this.currentDepartmentId === null) {
      return this.users;
    }
    const childIds = getDepartments(this.departments, this.currentDepartmentId);
    return this.users.filter(({ departmentId }) => childIds.includes(departmentId));
  }

  @computed get roleList() {
    return this.users.map(item => ({ key: item.role, name: item.role }));
  }

  constructor() {
    reaction(() => ({
      users: this.users,
      departments: this.departments,
    }), ({ users, departments }) => {
      this.setTreeData(loop(departments, null));
    });
  }

  @action
  setTreeData(data: TreeData[]) {
    this.treeData = data;
  }

  @action
  setCurrentDepartmentId(id: string) {
    this.currentDepartmentId = Number.parseInt(id, 10);
  }

  @action
  resetDepartmentId() {
    // @ts-ignore
    this.currentDepartmentId = null;
  }

  @action
  setUsers(data: UserInfo[]) {
    this.users = data;
  }

  @action
  setDepartments(data: Department[]) {
    console.log("departments", data);
    this.departments = data;
  }
  @action
  setRoleTree(data: any[]) {
    const newData = data.map(item => ({ role: item.role, roleId: item.roleId }));
    this.roleTree = newData;
  }
}

export default new UserManageStore();
