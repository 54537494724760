import { HttpClient } from "../../network/http-client";
import UserManageStore from "./user-manage.store";
import { message } from "antd";
import DeviceService from "../role-manage/role-manage.service";
// import { Omit } from "../../util/type";
import { BaseRequestStaticServer } from "../../network/servers/base-request.server";
import { BaseProvider } from "../../network/providers/base.provider";

export interface Department {
  departmentId: number;
  departmentName: string;
  parentDepartmentId: number | null;
  childDepartments: number[];
}

export interface UserInfo {
  userId: number;
  username: string;
  displayName: string;
  enterpriseId: string;
  departmentId: number;
  telephone: string;
  dataAuthorityId: number;
  dataAuthorityName: string;
  createdAt: string;
  role: string;
  roleId: number;
}

type CreateUserInfo = Pick<UserInfo, "username" | "displayName" | "telephone" | "departmentId" | "dataAuthorityId">;

interface Data {
  departments: Department[];
  users: UserInfo[];
}

class UserManageService extends BaseProvider {
  async getUserAndDepartmentList() {
    return Promise.all([this.getAllUsers(), this.getDepartmentsList(), this.getRoleList()]);
  }

  async getAllUsers(search?: string) {
    return BaseRequestStaticServer.get<{ users: UserInfo[] }>("/auth/users", { params: { search } }).then(( res: any) => {
      UserManageStore.setUsers(res.users || []);
    });
  }

  async createUser(params: CreateUserInfo) {
    return BaseRequestStaticServer.post("/auth/users", params).catch((error: any) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message === "user already existed"
      ) {
        message.error("用户已存在！");
        return;
      }
      message.error("用户创建失败！");
      return;
    });
  }

  async deleteUser(id: number) {
    return BaseRequestStaticServer.delete(`/auth/users/${id}`);
  }

  async updateUser(id: number, data: Partial<Omit<UserInfo, "userId">>) {
    return BaseRequestStaticServer.put(`/auth/users/${id}`, data);
  }

  async createDepartment(parentId: number | string, name: string) {
    return BaseRequestStaticServer.post("/auth/departments", { parentId, name });
  }

  async deleteDepartment(id: number) {
    return BaseRequestStaticServer.delete(`/auth/departments/${id}`);
  }

  async updateDepartment(id: number | string, name: string) {
    return BaseRequestStaticServer.put(`/auth/departments/${id}`, { name });
  }

  async getDepartmentsList() {
    return BaseRequestStaticServer.get<Pick<Data, "departments">>("/auth/departments/info").then((res: any) => {
      UserManageStore.setDepartments(res.departments || []);
    });
  }

  async editPassword(id: number, password: string | number) {
    return BaseRequestStaticServer.put(`/auth/users/${id}/password`, { newPassword: password });
  }

  async getRoleList() {
    DeviceService.getRoleData("")
      .then(result => {
        UserManageStore.setRoleTree(result.data);
      }).catch(error => {
        console.error(error);
      })
    ;
  }
}

export default new UserManageService();
