import React from "react";
import { Tree } from "antd";
import styles from "./tree.module.scss";

const { TreeNode } = Tree;

export interface RoleCheckParent {
  key: string;
  title: string;
  disabled?: boolean;
  checked?: boolean;
}

export interface RoleCheckBox extends RoleCheckParent {
  children?: RoleCheckParent[];
}

interface TreeProps {
  data: RoleCheckBox[];
  onChange?(selectedKeys: any): void;
  value?: any;
}

export class ITree extends React.Component<TreeProps> {

  onCheck = (checkedKeys: {
    checked: string[];
    halfChecked: string[];
  }) => {
    const { onChange } = this.props;
    onChange && onChange(checkedKeys.checked.filter(item => item !== ""));
  }

  renderTreeNodes = (data: any) => data.map((item: any) => {
    if (item.children) {
      return (
        <TreeNode disableCheckbox className="tree-container" title={item.title} key={item.key} dataRef={item}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode key={item.key} {...item} />;
  })

  render() {
    return (
      <div className={styles.roleManageWrapper}>
      <Tree
        checkable
        checkStrictly
        onCheck={this.onCheck as any}
        checkedKeys={this.props.value}
      >
        {this.props.data && this.renderTreeNodes(this.props.data)}
      </Tree>
      </div>
    );
  }
}
