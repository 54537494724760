import styled from 'styled-components';
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;

  /* .growthTimeLine {
    left:10px;
    transform:translateX(0);
    width:52%;
  } */
  .weatherTimeLine {
    position:absolute;
    left:0;
    bottom:16px;
    width:50%;
  }
  .mapSelect {
    position:absolute;
    z-index:99;
    top:8px;
    left:8px;
  }
  .breeding-distribute-side {
    width:400px;
    padding:0;
    .sidebar-header-box {
        background-color: #00adad;
    }
    .sidebar-content {
      .sidebar-display {
        padding:0;
      }
    }
  }
  .measureWrap {
    padding:16px;
    background-color: #f7fafa;
    list-style:none;
    .levelItem {
      text-align:left;
      .levelColor {
        width: 4px;
        height: 8px;
        /* margin: 2px 8px 2px 0; */
        margin-right:8px;
        border-radius: 2px;
        display:inline-block;
        background-color: #37a0f0
      }
      .levelName {
        font-size:12px;
        font-weight:500;
        line-height:1;
        margin-right:16px;
      }
      .levelMeasure {
        font-size:12px;
        color: rgba(0, 21, 23, 0.45);
        line-height:1;
      }
      
    }
  }
` 

