import styled from 'styled-components';
export const PageBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;

  .mapSelect {
    position:absolute;
    z-index:99;
    top:8px;
    left:8px;
  }
  .growthTimeLine {
    left:10px;
    position:absolute;
    bottom:16px;
    /* transform:translateX(0); */
    max-width:52%;
    min-width:26%
  }
  .breeding-distribute-side {
    width:400px;
    padding:0;
    .sidebar-header-box {
        background-color: #00adad;
    }
    .sidebar-content {
      .sidebar-display {
        padding:0;
      }
    }
  }
` 

