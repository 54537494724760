import styled, { createGlobalStyle } from "styled-components";
import { colorPalette } from "../../color-palette";

// tslint:disable-next-line:variable-name
export const RootStyle = styled.div`
  width: 100%;
  height: 100%;
  .App {
    text-align: center;
  }

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 5vmin;
  }

  .App-header {
    background-color: #282c34;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: ${colorPalette.mainColor[6]};
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
  }
`;
