import React, { PureComponent } from "react";
import { Button, Form, Modal, message, Upload} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { PaginationConfig } from 'antd/lib/table';
import { HeaderBox } from "../../containers/header-box";
import { Card1 as Card, ITable, RadarChart } from "@gago/frame";
import { ExpertSearch } from "./expert-search";
import { ExpertList } from "./expert-list";
import { ExpertAddForm } from './expert-add-form'
import ExpertDetail from './exprt-detail';
import { UploadFile } from "antd/lib/upload/interface";
import { UploadChangeParam, RcFile  } from "antd/lib/upload";


import { AxisChart } from "@gago/lib/components/axis-chart";
import { cloneDeep, round, sumBy, uniqueId } from "lodash";
import {ExpertEditRequest, ExpertCateInfo, AreaList, SelectedResults, ExpertRequestList, ExpertListType, DistributionProvinceRequest, DistributionSpecialty, delType, DistributionWorkingSeniority, DistributionProvince, ExpertInterestRequestList} from "../../models/expert-database";
import { CropPercentChart1 } from "@gago/lib/components/crop-percent-chart-1";
import { getDataS1N1, getDataS2N1 } from "../../utils/example-data";
import { chartStyle3 as chartStyle, colorPalette } from "../../color-palette";
import { PieChartLegend } from "@gago/lib/components/pie-chart-legend";
import ExpertDataBaseProvider from "../../network/providers/expert-database.provider";



import { PageBody } from "./styled";
import { fileToObject } from "antd/lib/upload/utils";
import { info } from "console";
import { threadId } from "worker_threads";

/**
 * 专家知识库管理
 * @author 马俊峰
 * @date 2020-11-14
 * @export
 * @class ExpertDatabase
 * @extends {Component}
 */

const dataS2N1: ITable<2, 1> = {
  title: "",
  subtitle: "",
  description: "",
  defaultValue: [0, 0, 0, 0],
  range: [[], [], [], []],
  head: ["", "", "", ""],
  data: [],
  suffix: ["", "", "", "份"],
  prefix: ["", "", "", ""],
};

const dataS1N1: ITable<1, 1> = {
  title: "",
  subtitle: "总人数",
  description: "",
  defaultValue: [0, 0, 0],
  range: [[], [], []],
  head: ["", "", ""],
  data: [],
  suffix: ["", "", "人"],
  prefix: ["", "", ""],
};
interface S {
  
  /** 地区 */
  areaList?: AreaList[];
  /**专家分类 */
  expertCategory: ExpertCateInfo[];
  /**专家类型 */
  expertType: ExpertCateInfo[]
  /**新增弹窗显隐 */
  isAddShow: boolean;
  /**详情弹窗显隐 */
  isDetailShow: boolean;
  
  /** 专家专业分布*/
  expertsProfession?: DistributionSpecialty[];
  // 表单数据
  tableResource?:ExpertListType;
  // 专家省份分布
  provinceExperts?: DistributionProvince[];
  // 选择的专家分类
  selectedCate: string[];
  // 选择的专家类型
  selectedType: string[];
  // 选择的专家省份
  selectedProv: string[];
  // 分页器size
  size?:number;
  // 分页器页码
  page?:number;
  // 单个专家信息，点击详情/修改的时候存储的信息，新增专家信息的时候也用
  expertInfo?:ExpertEditRequest | {};
  // 感兴趣专家列表
  interestedList?:ExpertEditRequest[];
  // 专家按工作年限分布
  workSenior?:DistributionWorkingSeniority[];
  // table 选择的行 存储下标  
  selectedRowKeys?:number[];
  // 用来存储this.props.form
  formMap?:any;
  // 文件上传列表
  fileList:UploadFile<any>[];
  // 上传头像的url
  headUrl?:string;
  // 是否从编辑按钮进入弹窗
  isFromEdit:boolean;
  // 预览图片地址
  previewImage?:string;
  // 控制预览弹窗 
  previewVisible:boolean;
  
  selectedResults:string[];

}
export class ExpertDatabase extends PureComponent< {}, S> {
  state: S = {
    // 新增/编辑弹窗是否显示
    isAddShow: false,
    // 专家详情是否显示
    isDetailShow: false,
    // 专家按专业分布
    expertsProfession: [],
  // 专家按省份分布
    provinceExperts:[], 
    // 省市区数据
    areaList: [],
    // 专家所有分类信息
    expertCategory: [],
    // 专家类型信息
    expertType: [],
    // table size
    size:10,
    // 页码
    page:0,
    // 新增和修改公用一个弹窗，判断是否从编辑打开
    isFromEdit:false,
    // 新增或者编辑用户上传图片的地址
    headUrl: '',
    // 用户在表格选择复选框的下标集合
    selectedRowKeys: [],
    // 按工作年限分布
    workSenior :[],
    // 用户选择的分类
    selectedCate:['全部'],
    // 用户选择的省份
    selectedProv:['全部'],
    // 用户选择的专家类型
    selectedType:['全部'],
    // 预览图片url
    previewImage:'',
    previewVisible:false,
    selectedResults:[],
    fileList:[]
  
  };
  componentDidMount() {
    this.getAreaList();
    this.getexpertCateInfo('planting_expert_type');
    this.getexpertCateInfo('planting_expert_specialty');
    this.getTableList();
    this.initData();
  }
  renderTable = () => {

  }
  /**获取列表数据 */
  getTableList = async () => {
    const { selectedProv, selectedCate, selectedType, size, page } = this.state;
    const selectedProvs = selectedProv.filter(item => item !== '全部')
    const selectedCates = selectedCate.filter(item => item !== '全部')
    const selectedTypes = selectedType.filter(item => item !== '全部')
    const provinceName = selectedProvs && selectedProvs.join(',');
    const type = selectedTypes && selectedTypes.join(',')
    const specialty = selectedCates && selectedCates.join(',');
    const tableResource = await ExpertDataBaseProvider.getExpertList({type,specialty,provinceName,size,page}) || [];
    this.setState({
      tableResource
    })
  }

  /**获取省市区地址 */
  getAreaList = async () => {
    const areaList = await ExpertDataBaseProvider.getAreaList() || [];
    this.setState({areaList})
  }

  /** 获取专家类型或者分类 */
  getexpertCateInfo = async (type:string) => {
    const expertInfo = await ExpertDataBaseProvider.asyncExpertCateInfo({type}) || [];
    if (type === 'planting_expert_type') {
      this.setState({expertType:expertInfo})
    } else if(type === 'planting_expert_specialty'){
      this.setState({
        expertCategory:expertInfo
      })
    }
  }

  /**获取专家分布-省份 */
  getProvinceExperts = async () => {
    const provinceExperts = await ExpertDataBaseProvider.getDistributionProvince() || [];
    this.setState({
      provinceExperts
    })
  }
  
  // 初始化数据
  initData = async () => {
    const workSenior = await ExpertDataBaseProvider.getDistributionWorkingSeniority()
    const specialty = await ExpertDataBaseProvider.getDistributionSpecialty()
    console.log('workSenior',workSenior);
    console.log('specialty',specialty);
    this.getProvinceExperts()
    this.setState({
      workSenior,
      expertsProfession:specialty
    })
  }

  // 删除专家
  deleteExpert = async (params:delType) => {
    
    await ExpertDataBaseProvider.deleteExpert(params).then(res => {
      if (res.code === 200) {
        message.success('删除成功');
        this.getTableList();
      }
    }).catch(err => {
      message.error('操作失败')
    })

  }

  // 处理省份选择
  handleChangeProv =  (params:string[]) => {
    this.setState({
      selectedProv:params
    },()=> {
      this.getTableList();
      this.initData();
    })
  }
  // 处理分类选择
  handleChangeCate =  (params:string[]) => {

    this.setState({
      selectedCate:params
    },()=> {
      this.initData();
      this.getTableList();
    })
  }
  // 处理类型选择
  handleChangeType =  (params:string[]) => {
    this.setState({
      selectedType:params
    },()=> {
      this.initData();
      this.getTableList();
    })
  }
  // 处理用户的选择题条件
  handleSelectedResults = (results:string[]) => {
    this.setState({
      selectedResults:results
    })
  }

  //  获取感兴趣专家
  getInterestedList = async (params:ExpertInterestRequestList) => {
    const interestedList = await ExpertDataBaseProvider.getInterestedExpert(params);
    this.setState({
      interestedList
    })
  } 
  // 点击详情
  handleDetail = (params:ExpertEditRequest) => {
    const specialty = params && params.specialty;
    const provinceName = params && params.provinceName;
    const interestRequest:ExpertInterestRequestList = {
      specialty,
      provinceName
    }
    this.setState({
      isDetailShow:true,
      expertInfo:params
    })
    this.getInterestedList(interestRequest);
  }
  renderRightCard1 = () => {
    const { workSenior } = this.state;
    
    if (!workSenior || workSenior.length === 0) {
      return null;
    }
    
    const chartData = cloneDeep(dataS1N1);
    chartData.data = workSenior.map((item: any) => {
      return [`${item.key}`, `${item.key}`, round(item.value, 2)];
    });
    chartData.title = `${sumBy(chartData.data, (item) => item[2])}`;

    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"专家从业年限分布"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
        <CropPercentChart1
            theme="horizontal"
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#2970ff", "#00aaff", "#70e2ff", "#ffb300", "#ff5275", ...colorPalette.subColor],
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard2 = () => {
    const { expertsProfession } = this.state;
    if (!expertsProfession ||  expertsProfession.length === 0) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = expertsProfession.map((item: any) => {
      return [`${item.specialty}`, `${item.specialty}`, round(item.count, 2)];
    });
    chartData.title = `${sumBy(chartData.data, (item) => item[2])}`;
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"专家专业范围分布"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ minHeight: 260 }}>
          <CropPercentChart1
            theme="horizontal"
            chartData={chartData}
            colorPalette={{
              ...colorPalette,
              subColor: ["#2970ff", "#00aaff", "#70e2ff", "#ffb300", "#ff5275", ...colorPalette.subColor],
            }}
          />
        </div>
      </Card>
    );
  }

  renderRightCard3 = () => {
    const { provinceExperts } = this.state;
    if (!provinceExperts || provinceExperts && provinceExperts.length === 0) {
      return null;
    }
    const chartData = cloneDeep(dataS1N1);
    chartData.data = provinceExperts.map((item) => {
      return [`${item.provinceName}`, `${item.provinceName}`, round(item.count, 2)];
    });
    return (
      <Card
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{"专家各省分布"}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        <div style={{ height: 200 }}>
          <RadarChart
            theme="light"
            colorPalette={colorPalette}
            chartData={chartData}
          />
        </div>
      </Card>
    );
  }

  renderButtonList = () => {
    const token = window.sessionStorage.getItem("token") as string;
    return (
      <div className="button-list">
        <div className="button-list-left">
          <Button style={{ marginRight: "10px" }} onClick={this.handleAddClick}>
              新增
          </Button>
          <Button type="primary" onClick={this.handleDownload} >
              下载导入模板
          </Button>
        </div>
        <div className="button-list-right">
          <Upload
            name = 'excel'
            action={`${window.config.publisher}/api/v1/plant/expert/upload`}
            headers={{ token }}
            showUploadList={false}
            onChange={this.handleUploadExport}
            >
            <Button
              icon="download"
            >
                导入
            </Button>
          </Upload>
          <Button
            icon="export"
            style={{ marginLeft: "10px" }}
            onClick={this.handleExport}
          >
              导出
          </Button>
        </div>
      </div>
    );
  }

  handleExport = async() => {
    const { selectedRowKeys, tableResource } = this.state;
    let ids:number[] = [];
    const token = window.sessionStorage.getItem("token") as string;
    if (selectedRowKeys && selectedRowKeys.length == 0) {
      message.info('您还没有选择导出的数据！')
    } else {
      selectedRowKeys && selectedRowKeys.forEach((item:number) => {
        if (tableResource &&  tableResource.list && tableResource.list.length > 0) {
          ids.push(tableResource.list[item].id)
        }
      })
      const idString:string = ids.join(',');
      ExpertDataBaseProvider.download(`/plant/expert/export?ids=${idString}`)
      // window.location.href =`${window.config.publisher}/api/v1/plant/expert/export?ids=${idString}&token=${token}`
    }

  }

  handleUploadExport = (data:UploadChangeParam<UploadFile<any>>) => {
    if (data.file.status === 'done' && data.file.response.data.code == 200) {
      message.success('文件上传中，请稍后刷新页面查看最新数据')
    } else if (data.file.status === 'done' && data.file.response.data.code != 200) {
      message.error('操作失败')
    }
  }


  // 下载模板
  handleDownload = async () => {
    // const data = await ExpertDataBaseProvider.downloadModule(); 
    // await ExpertDataBaseProvider.getTemplate(data)
    ExpertDataBaseProvider.download(`/plant/expert/template`);

    // window.location.href = data;
  }
  // 控制新增编辑弹框显示
  handleAddClick = () => {
    this.setState({
      isAddShow:true
    })
  }
  // 新增编辑弹框隐藏
  handleCancle = () => {
    this.setState({
      isAddShow:false,
      isFromEdit:false,
      expertInfo: {},
      fileList:[],
      headUrl:''
    })
  }
  // 详情弹框隐藏
  handleDetailCancle = () => {
    this.setState({
      isDetailShow:false,
      expertInfo:{},
      interestedList:[],
    })
  }

  // 处理选中的数据
  handleSelectChange = (selectedRowKeys:number[]) => {
    console.log(selectedRowKeys)
    this.setState({
      selectedRowKeys
    })
  }

  // 用来带出弹窗中this.props.form
  handleAddForm = ( form:FormComponentProps['form']) => {
    this.setState({ formMap: form });
  }

  // 处理分页器函数
  handlePagination = (pagination:PaginationConfig)  => {
    console.log(pagination,'pagination')
    const { current, pageSize } = pagination;
    this.setState({
      page: current,
      size: pageSize
    }, () => {
      this.getTableList();
    })
  }

  // 新增/编辑 弹框确定按钮
  saveExpertForm = async () => {
    const { formMap, headUrl, isFromEdit } = this.state;

    let result = false;
    let tempObj:any = {}
    formMap.validateFieldsAndScroll((err:any, v:any) => {
      result = !err;
      if(v.provinceName) {
        tempObj = {...v,
          birthDate:v.birthDate.format('YYYY-MM-DD'),
          provinceName:v.provinceName[0],
          cityName:v.provinceName[1]
        }
      } else {
        tempObj = {...v,
          birthDate:v.birthDate.format('YYYY-MM-DD')
        }
      }
      
    });
    console.log(tempObj,'tempObj')
    if (result && isFromEdit) {
      await ExpertDataBaseProvider.updateExpert({headUrl,...tempObj}).then(editData => {
        if (editData.code === 200) {
          message.success('修改信息成功');
          this.handleCancle(); 
          this.getTableList();
        }
      }).catch(err => {
        message.error('操作失败')
      })
    } else if( result && !isFromEdit){
      await ExpertDataBaseProvider.addExpert({headUrl,...tempObj}).then(addData => {
        if (addData.code === 200) {
          message.success('新增专家成功');
          this.handleCancle();
          this.getTableList();
        }
      }).catch(err => {
        message.error('操作失败')
      })
    }
  }

  // 生成预览图片
  handleGetBase64 =  (file:File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error)
    })
  }

  uploadChange = (data: UploadChangeParam<UploadFile<any>>) => {
    let headUrl = data?.file?.response?.data?.data[0].file || '';
    // data.fileList.forEach(item => {
    //   headUrl = item?.response?.data?.data[0].file
    // })
    // if (data?.file?.status === 'done') {
      this.setState({headUrl, fileList: [...data.fileList] });
      

      // if (data.file.status === 'uploading') {
      //   return 
      // } 
      // if (data.file.status === 'done') {
      // }
    // }

  }

  handlePreview = async (file:any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.handleGetBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  }

  handleCancelPreview = () => {
    this.setState({
      previewVisible: false
    })
  }

  handleEdit = (params:ExpertEditRequest) => {
    const fileList:UploadFile<any>[] =  params.headUrl ? [{
      uid: uniqueId("img"),
      name: "图片",
      type: "image/png",
      status: 'done',
      url: params.headUrl,
    }] as UploadFile<any>[] : [];
    this.setState({fileList})
    this.setState({
      isFromEdit:true,
      isAddShow:true,
      expertInfo:params,
      fileList
    })
  }
  
  render() {
    const { selectedResults, areaList, expertCategory, expertType, isFromEdit, expertInfo, tableResource, selectedRowKeys, selectedCate, selectedProv, selectedType } = this.state;
    let modalTitle = isFromEdit ? '编辑专家信息' : '新增专家信息';
    // const expertModalInfo = isFromEdit ? expertInfo : {} ;
    console.log(isFromEdit,'isFromEdit')
    return(
      <HeaderBox names={["专家库管理"]}>
          <PageBody>
            <div className="page-left-container">
                <ExpertSearch
                  areaList={areaList}
                  expertCategory={expertCategory}
                  expertType={expertType}
                  selectedCate={selectedCate}
                  selectedProv={selectedProv}
                  selectedType={selectedType}
                  handleChangeProv={this.handleChangeProv}
                  handleChangeCate={this.handleChangeCate}
                  handleChangeType={this.handleChangeType}
                  selectedResults={selectedResults}
                  handleSelectedResults = {this.handleSelectedResults}
                >
                </ExpertSearch>
                {this.renderButtonList()}
                <ExpertList
                  deleteExpert = {this.deleteExpert}
                  tableResource = {tableResource?.list}
                  handleDetail = {this.handleDetail}
                  handleSelectChange = {this.handleSelectChange}
                  handleEdit = {this.handleEdit}
                  selectedRowKeys = {selectedRowKeys}
                  count = {tableResource?.count}
                  handlePagination = { this.handlePagination}
                ></ExpertList>
            </div>
            <div className="page-right-container">
              {this.renderRightCard1()}
              {this.renderRightCard2()}
              {this.renderRightCard3()}
            </div>
          </PageBody>
          <Modal
            title={modalTitle}
            visible={this.state.isAddShow}
            width="1200px"
            onCancel={this.handleCancle}
            onOk={this.saveExpertForm}
            destroyOnClose
          >
            <ExpertAddForm areaList={this.state.areaList} expertCategory = {expertCategory} handlePreview={this.handlePreview} onLoad={this.handleAddForm} fileList={this.state.fileList} uploadChange={this.uploadChange} expertInfo={expertInfo} isFromEdit={this.state.isFromEdit} exportType = {expertType}/>
          </Modal>
          <Modal
            title="专家详情"
            visible={this.state.isDetailShow}
            width={692}
            style={{height:'517px'}}
            onCancel={this.handleDetailCancle}
            footer={null}
            bodyStyle={{paddingLeft:'0'}}
            destroyOnClose
            >
            <ExpertDetail
              expertInfo = {this.state.expertInfo}
              interestedList= {this.state.interestedList}
            />
          </Modal>
          <Modal
            visible={this.state.previewVisible}
            onCancel={this.handleCancelPreview}
            footer={null}
            closable={false}
            width={150}
            bodyStyle={{width:'150px',padding:'0'}}
          >
            <img src={this.state.previewImage} style={{height:'150px',width:'150px'}} alt=""/>
          </Modal>
      </HeaderBox>
    );
  }
}
