import { observable, action, computed } from "mobx";
import moment from "moment";

const defaultClickCur = {
  roleId: 0,
  role: "",
  web: "",
  app: "",
  update: "",
  dataAuthority: 2,
};

export class DeviceStore {
  @observable.ref tableData: any[] = []; // 表格数据
  @observable clickCur: any = defaultClickCur; // 点击的条目信息
  @observable isNew = true; // 是否为新增设备
  @observable addEditDeviceModel = false; // 新增定位设备

  @computed get editRoleInfo() {
    const { web, app, ...rest } = this.clickCur;
    const dataFormat = {
      web: web.split(","),
      app: app.split(","),
    };
    return Object.assign({}, rest, dataFormat);
  }
  dataFormate(data: any[]): string {
    if (!data) {
      return "";
    }

    return data.filter(item => item !== "").join(",");
  }
  // 角色表格
  @action
  setTableData(data: any) {
    const dataFormate = data.data.map((item: any, index: number) => ({
      index: index + 1,
      roleId: item.roleId,
      role: item.role,
      web: this.dataFormate(item.web),
      app: this.dataFormate(item.app),
      update: moment(item.update).format("YYYY-MM-DD"),
      dataAuthority: item.dataAuthority,
    }));
    this.tableData = dataFormate;
  }

  /** 更改modal状态 */
  @action
  setAddEditDeviceModel(value: boolean) {
    this.addEditDeviceModel = value;
  }

  /** 点击的条目信息 */
  @action
  setClickCur(data: any) {
    this.clickCur = data;
  }

  /** 改变新增编辑状态 */
  @action
  setIsNew(value: boolean) {
    this.isNew = value;
  }
}

export default new DeviceStore();
