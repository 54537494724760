import { ITable } from "@gago/frame";
import { ResultType } from "../../models/common";
import {
  BaseRequest,
  AgricultureWarning,
  AgricultureWarningRequest,
  AnalysisBaseRequest,
  PhenologicalPeriod,
  WeekForecast,
  AnalysisTmp,
  BaseAnalysis,
} from "../../models/meteorological-service";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";

/**
 * 繁育气候
 * @author wangchengbing
 * @date 2021-1-8
 * @export
 * @class
 * @extends {BaseProvider}
 */
export default class MeteorologicalServiceProvider extends BaseProvider {
  /** 获取七天天气预报 */
  static async getWeekForecast(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<WeekForecast[]>>("/plant/breed/weather/future7", { params });
    return data;
  }
  /** 获取今日农业预警分析 */
  static async getAgricultureWarning(params: AgricultureWarningRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<AgricultureWarning[]>>("/plant/breed/weather/weatherWarning", { params });
    return data;
  }
  /** 作物理论生育期 */
  static async getPhenologicalPeriod(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<PhenologicalPeriod[]>>("/plant/breed/weather/phenologicalPeriod", { params });
    return data;
  }
  /** 温度分析 */
  static async getAnalysisTmp(params: AnalysisBaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<AnalysisTmp>>("/plant/breed/weather/analysis/tmp", { params });
    return data;
  }
  /** 累计降水分析 */
  static async getAnalysisApcp(params: AnalysisBaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<BaseAnalysis>>("/plant/breed/weather/analysis/apcp", { params });
    return data;
  }
  /** 累计降水分析 */
  static async getAnalysisSumTmp(params: AnalysisBaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<BaseAnalysis>>("/plant/breed/weather/analysis/sumtmp", { params });
    return data;
  }
  /** 获取lerc数据起报时间*/
  static async getStartDate() {
    const { data } = await BaseRequestStaticServer.get<ResultType<string[]>>("/plant/breed/weather/dailylist", { });
    return data;
  }
}
