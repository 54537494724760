// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select, message, Cascader } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import { CropCategory } from "../../models/germplasm-database";
import { regionCascaderOtption } from "../../containers/region-cascader-option";
import { CascaderProps } from "antd/lib/cascader";
import { get } from "lodash";

const { Option } = Select;
const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

export interface SearchProps {
  /** 表名称 */
  tableName: string;
  /** 作物类别1 */
  data1: CropCategory;
  /** 作物类别2 */
  data2: CropCategory | undefined;
  /** 是否区域特色种质 */
  isTs: string;
  /** 是否市场流通种质 */
  isCirculate: string;
  /** 省市数组 */
  regionTree: [string, string];
}

type P = FormComponentProps & {
  /** 作物分类 */
  cropCategory?: CropCategory[];
  /** 当点击 search时*/
  onSearch(search: SearchProps): void;
  /** disable 所有的 */
  disableAll?: boolean;
}

interface S {
  /** 行政级联选择option */
  cascaderOption?: CascaderProps["options"];
}

/**
 * 顶部高级搜索
 * @author maoshengtao
 */
class AdvanceSearch extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.getCascaderOption();
  }

  /** get cascader option */
  getCascaderOption = async () => {
    const cascaderOption = await regionCascaderOtption() || undefined;
    this.setState({ cascaderOption })
  }

  /** search */
  handleSearch = (e: any) => {
    e.preventDefault();
    const { form, cropCategory, onSearch } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return;
      }
      if (!cropCategory || cropCategory && cropCategory.length === 0 || !onSearch) return;
      const tempData = cropCategory.find((item) => item.name === values.作物分类);
      if (tempData) {
        const tempData2 = tempData.children && tempData.children.find((elm) => elm.name === values.二类名称);
        const selectData = tempData2 ? tempData2 : tempData;
        onSearch({
          tableName: selectData.tableName,
          data1: tempData,
          data2: tempData2, 
          isTs: values.isTs,
          isCirculate: values.isCirculate,
          regionTree: values.regionTree,
        });
      }
    });
  }

  /** render 作物分类 */
  renderSelect1 = () => {
    const { getFieldDecorator } = this.props.form;
    const { cropCategory, disableAll } = this.props;
    if (!cropCategory || cropCategory && cropCategory.length === 0) {
      return null;
    }

    return (
      getFieldDecorator(`作物分类`, {
        initialValue: cropCategory[0].name,
      })(
        <Select
          disabled={disableAll}
        >
          {cropCategory.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>
      )
    );
  }

  /** render 二类名称 */
  renderSelect2 = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { cropCategory, disableAll } = this.props;
    if (!cropCategory || cropCategory && cropCategory.length === 0) {
      return null;
    }
    const selectdata = cropCategory.find((item, index) => item.name === getFieldValue("作物分类")) || cropCategory[0];
    if (!selectdata.children || selectdata && selectdata.children.length === 0) {
      return (<Select key={"无值"} placeholder="无二级分类" disabled />);
    }
    return (
      getFieldDecorator(`二类名称`, {
        initialValue: selectdata.children[0].name,
      })(
        <Select
         disabled={disableAll}
        >
          {selectdata.children.map((item) => {
            return <Option key={item.name} value={item.name}>{item.name}</Option>;
          })}
        </Select>
      )
    );
  }

  /** render 行政级联 */
  renderCascader = () => {
    const { getFieldDecorator } = this.props.form;
    const { cascaderOption } = this.state;
    const { disableAll } = this.props;
    if (!cascaderOption) {
      return (
        getFieldDecorator(`regionTree`, {
        })(
            <Cascader disabled={disableAll} allowClear={false} options={cascaderOption} placeholder="请选择所在地区" />
          )
      );
    }
    return (
      getFieldDecorator(`regionTree`, {
        initialValue: [get(cascaderOption, "[0].value", undefined), get(cascaderOption, "[0].children[0].value", undefined)],
      })(
          <Cascader disabled={disableAll} allowClear={false} options={cascaderOption} placeholder="请选择所在地区" />
        )
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { cascaderOption } = this.state;
    const { disableAll } = this.props;
    return (
      <>
        <WrapForm onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col span={4} >
              <Form.Item label={`种质分类`} >
                {this.renderSelect1()}
              </Form.Item>
            </Col>
            <Col span={4} >
              <Form.Item label={`二级分类`} >
                {this.renderSelect2()}
              </Form.Item>
            </Col>
            <Col span={4} >
              <Form.Item label={`地区选择`} >
                {this.renderCascader()}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={`是否区域特色种质`} >
              {getFieldDecorator(`isTs`, {
                initialValue: "是",
              })(
                  <Select
                    disabled={disableAll}
                  >
                    <Option value="是">是</Option>
                    <Option value="否">否</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={`是否市场流通种质`} >
              {getFieldDecorator(`isCirculate`, {
                initialValue: "是",
              })(
                  <Select
                    disabled={disableAll}
                  >
                    <Option value="是">是</Option>
                    <Option value="否">否</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Button style={{ marginLeft: 5 }} type="primary" htmlType="submit" disabled={disableAll}>
                确定
              </Button>
            </Col>
          </Row>
        </WrapForm>
      </>
    );
  }
}

export const TopCategory = Form.create<P>({ name: "advanced_search" })(AdvanceSearch);