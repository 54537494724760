import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
// tslint:disable-next-line: ter-max-len
import { CropCategory, RegionSummary, ResourceList, ResourceSummary, ResourceTrend, CategorySummary, Upload, ResourceListRequest, BaseInfo, ResourceAddRequest, ResourceUpdateRequest, ResourctFindOne, RegionTree, TemplateRequest, importResource } from "../../models/germplasm-database";
import { ITable } from "@gago/frame";
import axios from "axios";
import { message } from "antd";
import { cloneDeep, get } from "lodash";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
// tslint:disable:ter-max-len
/** ID */
// id: number;
// /** 分类名称 */
// name: string;
// /** 上级分类ID */
// parentId: number;
// /** 下级分类信息 */
// children?: CropCategory[];
// /** 表名称 */
// tableName: string;

/** handle 作物分类数据 */
const handleCropCategory = (temp: CropCategory[]) => {
  const datas = cloneDeep(temp);
  datas.map((data) => {
    if (data.children && data.children.length >= 2 && !data.children.find((item) => item.name === "全部")) {
      data.children.unshift({
        id: -1,
        name: "全部",
        parentId: -1,
        tableName: "",
      });
    }
  });

  return datas;
};

/**
 * 种质资源数据库，数据服务层
 * @author maoshengtao
 *
 */
export default class GermplasmDatabaseProvider extends BaseProvider {
  /** 获取 作物分类 */
  static async getCropCategory() {
    const { data } = await BaseRequestStaticServer.get<ResultType<CropCategory[]>>("/plant/type");
    if (data && data.length > 0 && !data.find((item) => item.name === "全部")) {
      data.unshift({
        id: -1,
        name: "全部",
        parentId: -1,
        children: [
          {
            id: -1,
            name: "全部",
            parentId: -1,
            tableName: "",
          },
        ],
        tableName: "",
      });
    }
    return handleCropCategory(data);
  }

  /** 获取 年份 */
  static async getStorageYears() {
    const { data } = await BaseRequestStaticServer.get<ResultType<number[]>>("/plant/year");
    return data;
  }

  /** 获取 种植资源库汇总 */
  static async getResourceSummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ResourceSummary>>("/plant/resource/summary");
    return data;
  }

  /** 获取 种植资源入库趋势 */
  static async getResourceTrend() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ResourceTrend[]>>("/plant/resource/putInventory");
    return data;
  }

  /** 获取 种植类型数量统计 */
  static async getCategorySummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<CategorySummary[]>>("/plant/resource/category/summary");
    return data;
  }

  /** 获取 黄河流域各省份入库种质资源统计 */
  static async getRegionSummary() {
    const { data } = await BaseRequestStaticServer.get<ResultType<RegionSummary[]>>("/plant/resource/regionSummary");
    return data;
  }

  /** 获取 种质数据列表 */
  static async getResourceList(params: ResourceListRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ResourceList>>("/plant/resource/list", {
      params,
    });
    return data;
  }

  /** 获取 上传图片或者报告资源 */
  // static async getUpload(params: RcCustomRequestOptions) {
  //   const { data } = await BaseRequestStaticServer.get<ResultType<Upload[]>>("/plant/file/upload", {
  //     params: {
  //       file: params.data,
  //     }
  //   });
  //   return data;
  // }

  /** 获取单个种质资源信息 */
  static async getResourctFindOne(accessionNumber: string) {
    /** /plant/column/tableName?tableName=plant_shui_dao */
    const { data } = await BaseRequestStaticServer.get<ResultType<ResourctFindOne>>("/plant/resource/findOne", {
      params: {
        accessionNumber,
      },
    });
    return data;
  }

  /** 新增 get 基础信息 */
  static async getBaseInfo(tableName: string) {
    /** /plant/column/tableName?tableName=plant_shui_dao */
    const { data } = await BaseRequestStaticServer.get<ResultType<BaseInfo[]>>("/plant/column/tableName", {
      params: {
        tableName,
      },
    });
    return data;
  }

  /** 种质资源新增 */
  static async resourceAdd(params: ResourceAddRequest) {
    const data: any = await BaseRequestStaticServer.post("/plant/resource/add", params).catch((err) => {
      if (err.response) {
        message.error(get(err, "response.data.error.message", "新增失败"));
      }
    });
    return data.data;
  }

  /** 种质资源更新 */
  static async resourceUpdate(params: ResourceUpdateRequest) {
    const data: any = await BaseRequestStaticServer.post("/plant/resource/update", params).catch((err) => {
      if (err.response) {
        message.error(get(err, "response.data.error.message", "更新失败"));
      }
    });
    return data.data;
  }

  /** 种质资源删除 */
  static async resourceDel(id: string) {
    const data: ResultType<number> = await BaseRequestStaticServer.post("/plant/resource/del", {
      id,
    });
    return data;
  }

  /** 种质资源批量删除 */
  static async resourceDelIds(ids: string) {
    const data: ResultType<number> = await BaseRequestStaticServer.get(`/plant/resource/delByIds?ids=${ids}`);
    return data;
  }

  /** 获取行政区划 */
  static async getRegionTree() {
    const { data } = await BaseRequestStaticServer.get<ResultType<RegionTree[]>>("/plant/region/tree");
    return data;
  }

  /**  获取种质资源模板 */
  static async getTemplate(params: TemplateRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<string>>("/plant/resource/templateUrl", {
      params,
    });
    return data;
  }

  /** 导入种质资源 */
  static async importGermplasmDatabase(params: importResource) {
    const { excel, oneLevel, oneLevelId, twoLevel, twoLevelId } = params;
    const data: ResultType<number> = await BaseRequestStaticServer.post(`/plant/resource/upload?oneLevelId=${oneLevelId}&oneLevel=${oneLevel}&twoLevelId=${twoLevelId}&twoLevel=${twoLevel}`, excel);
    return data;
  }

  /** 下载数据 */
  static async download(url: string) {
    await BaseRequestStaticServer.downloadFile(url, "种质数据.xlsx");

  }

}
