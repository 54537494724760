// tslint:disable: variable-name naming-convention jsx-no-multiline-js jsx-wrap-multiline
import React from "react";
import { Divider, Modal, Popover, Table, Button } from "antd";
import { divide, isNull, noop } from "lodash";
import styled, { createGlobalStyle } from "styled-components";
import { BaseLandList } from "../../models/dictionary-base";
import { DetailOverlay } from "../../containers/detail-overlay";
import { DictionaryBaseOverlay } from "../../containers/dictionary-base-overlay";
import { PaginationConfig } from "antd/lib/table";

const StyleButton = styled.a`
  font-family: PingFangSC;
  font-size: 14px;
  color: #00adad;
`;

const titleList1 = ["序号"];
const titleList2 = ["地区", "企业", "基地类型", "地块状态"];

interface Datatype {
  /** key */
  key: string;
  /** name */
  序号: string;
  /** name */
  地区: string;
  /** name */
  企业: string;
  /** name */
  基地类型: string;
  /** name */
  地块状态: string;
}

interface P {
  /** 表格数据 */
  baseList?: BaseLandList;
  /** 处理分页 */
  handlePagination(config: PaginationConfig): void;
  // 处理table复选框
  handleSelectRowKeys(params: string[]): void;
  /** 当前页数 */
  pagination?: PaginationConfig;
}

interface S {
  /** 选择的行数 的key集合 */
  selectedRowKeys: string[];
  /** 二维麻状态 */
  qrstatus?: boolean;
  /** data */
  data?: Datatype[];
  /** 当前点击编辑的 的种质资源 的种质编号*/
  activeNumber?: string;
  /** 当前点击详情的 的种质资源 的种质编号*/
  detailNumber?: string;
  /** h5 modal number */
  h5ModalNumber?: string;
}

/**
 * 左侧第二个模块 表格组件
 * @author maoshengtao
 */
export class LeftModule2 extends React.PureComponent<P, S> {
  columns: any[];

  constructor(props: P) {
    super(props);
    const { baseList } = this.props;
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      data: this.handleData(baseList),
    };
    this.columns = [
      {
        title: <span title={"序号"}>{"序号"}</span>,
        dataIndex: "序号",
        width: 60,
      },
      this.renderTableName(),
      ...titleList2.map((item, ind) => ({
        title: <span title={item}>{item}</span>,
        dataIndex: item,
        ellipsis: true,
      })),
      this.renderOperation2(),
    ];
  }

  renderTableName = () => {
    return {
      title: "地块名称",
      dataIndex: "地块名称",
      ellipsis: true,
      render: (v: any, o: any) => {
        return (
          <>
            <StyleButton onClick={this.handleDetailClick.bind(this, o.id)}>
              {v}
            </StyleButton>
          </>
        );
      },
    };
  };

  renderOperation2 = () => {
    return {
      title: "操作",
      fixed: "right",
      width: 60,
      render: (v: any) => (
        <>
          <StyleButton onClick={this.handleEditClick.bind(this, v.id)}>
            编辑
          </StyleButton>
        </>
      ),
    };
  };

  componentDidUpdate(preProps: P) {
    if (this.props.baseList !== preProps.baseList) {
      this.setState({ data: this.handleData(this.props.baseList) });
    }
  }

  /**选择发生变化 */
  onSelectChange = (selectedRowKeys: any) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    const { handleSelectRowKeys } = this.props;
    this.setState({ selectedRowKeys });
    handleSelectRowKeys(selectedRowKeys);
  };

  /** handle data */
  handleData = (rawData?: BaseLandList) => {
    if (!rawData) {
      return [];
    }
    return rawData.list.map((item, ind) => {
      return {
        key: (item.id || ind).toString(),
        id: item.id,
        序号: `${ind + 1}` || "-",
        地块名称: item.baseName || "-",
        地区: `${item.provinceName}${item.cityName ? `/${item.cityName}` : ""}`,
        企业: item.company || "-",
        基地类型: item.secTypeName || "-",
        地块状态: item.state === 0 ? "下架" : "上架",
      };
    });
  };

  /** handle overlay open */
  handleEditClick = (activeNumber: string) => {
    this.setState({ activeNumber });
  };

  /** handle overlay close*/
  handleEditCancle = () => {
    this.setState({ activeNumber: undefined });
  };

  renderEditOverlay = () => {
    const { activeNumber } = this.state;
    if (!activeNumber) {
      return null;
    }
    return (
      <DictionaryBaseOverlay
        dataId={activeNumber}
        handleCancel={this.handleEditCancle}
        modelOption={{
          visible: !!activeNumber,
          onCancel: this.handleEditCancle,
          title: "编辑地块信息",
        }}
      />
    );
  };
  /** handle overlay open */
  handleDetailClick = (detailNumber: string) => {
    this.setState({ detailNumber });
  };

  /** handle overlay close*/
  handleDetailCancle = () => {
    this.setState({ detailNumber: undefined });
  };

  renderDetailOverlay = () => {
    const { detailNumber } = this.state;
    if (!detailNumber) {
      return null;
    }
    return (
      <DictionaryBaseOverlay
        disabled={true}
        dataId={detailNumber}
        handleCancel={this.handleDetailCancle}
        modelOption={{
          visible: !!detailNumber,
          title: "查看地块信息",
        }}
      />
    );
  };

  /** 处理分页 */
  handlePagination = (pagination: PaginationConfig) => {
    this.props.handlePagination(pagination);
  };

  render() {
    const { selectedRowKeys, data } = this.state;
    const { baseList, pagination } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "选择全部",
          onSelect: () => {
            this.setState({
              selectedRowKeys: data ? [...data.map((item) => item.key)] : [], // 0...45
            });
          },
        },
        {
          key: "odd",
          text: "选择奇数行",
          onSelect: (changableRowKeys: any) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter(
              (key: string, index: number) => {
                if (index % 2 !== 0) {
                  return false;
                }
                return true;
              }
            );
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: "even",
          text: "选择偶数行",
          onSelect: (changableRowKeys: any) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter(
              (key: string, index: number) => {
                if (index % 2 !== 0) {
                  return true;
                }
                return false;
              }
            );
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
    const h = window.document.documentElement.offsetHeight - 400;
    return (
      <>
        <Table
          key={`${data && data.length}`}
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={data || []}
          scroll={{ x: "100%", y: `${h < 0 ? 100 : h}px` }}
          onChange={this.handlePagination}
          pagination={{
            pageSize: pagination && pagination.pageSize,
            current: pagination && pagination.current,
            showQuickJumper: true,
            onShowSizeChange: noop,
            showSizeChanger: true,
            total: baseList ? baseList.count : undefined,
            // tslint:disable-next-line: jsx-alignment
          }}
        />
        {this.renderEditOverlay()}
        {this.renderDetailOverlay()}
      </>
    );
  }
}
