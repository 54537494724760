import {Business} from "./business.server";
import {AxiosRequestConfig} from "axios";
import {serverConfig} from "../server-config";
// tslint:disable:max-classes-per-file
// tslint:disable:ter-max-len

/**
 * 项目基础请求服务
 * @author 马俊峰
 * @date 2019-11-28
 * @export
 * @class BaseRequestServer
 * @extends {Business}
 */
export class BaseRequestServer extends Business {
    constructor() {
        super(`${serverConfig.api_v1}/api/v1`);
    }
}

/**
 * 数管域名基础请求
 * @author gechengtong
 * @date 17/08/2023
 * @export
 * @class BaseRequestServer1
 * @extends {Business}
 */
export class BaseRequestServer1 extends Business {
    constructor() {
        super(`${serverConfig.api_v2}`);
    }
}

export class BaseRequestServerRuoYI extends Business {
    constructor() {
        super(`${serverConfig.api_v3}`);
    }
}

/**
 * 定义静态基础服务请求
 * @author 马俊峰
 * @date 2019-11-28
 * @export
 * @class BaseRequestStaticServer
 */
export class BaseRequestStaticServer {
    /** get请求 */
    static async get<T>(url: string, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().get<T>(url, {...setting, headers: {token}});
    }

    /** post请求 */
    static async post<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().post<T, P>(url, data, {...setting, headers: {token}});
    }

    /** put请求 */
    static async put<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().put<T, P>(url, data, {...setting, headers: {token}});
    }

    // 下载文件
    static async downloadFile(url: string, fileName?: string, contentType?: string, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().downloadFile(url, fileName, contentType, {...setting, headers: {token}});
    }

    /** delete请求 */
    static async delete(url: string, setting ?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().delete(url, {...setting, headers: {token}});
    }
}

export class BaseRequestStaticServer2 {
    /** get请求 */
    async get<T>(url: string, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().get1<T>(url, {...setting, headers: {token}});
    }

    /** post请求 */
    async post<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().post<T, P>(url, data, {...setting, headers: {token}});
    }

    /** put请求 */
    async put<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().put<T, P>(url, data, {...setting, headers: {token}});
    }

    // 下载文件
    async downloadFile(url: string, fileName?: string, contentType?: string, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().downloadFile(url, fileName, contentType, {...setting, headers: {token}});
    }

    /** delete请求 */
    async delete(url: string, setting ?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("token") as string;
        return new BaseRequestServer().delete(url, {...setting, headers: {token}});
    }
}

export default new BaseRequestStaticServer2();

export class BaseRequestStaticServer1 {
    /** get请求 */
    static async get<T>(url: string, setting?: AxiosRequestConfig) {
        console.log("****set---", setting);
        return new BaseRequestServer1().get1<T>(url, {...setting, headers: {Authorization: window.config.permanentToken}}).then(
            res => {
                return res;
            },
        );
    }
}

export class BaseRequestStaticServer3 {
    /** get请求 */
    async get<T>(url: string, setting?: AxiosRequestConfig) {
        return new BaseRequestServer1().get1<T>(url, {...setting, headers: {Authorization: window.config.permanentToken}});
    }
}

export class BaseRequestStaticServer4 {
    /** get请求 */
    async get<T>(url: string, setting?: AxiosRequestConfig) {
        return new BaseRequestServer1().get<T>(url, {...setting, headers: {Authorization: window.config.permanentToken}});
    }
}

export class BaseRequestStaticServerRuoYI {
    /** get请求 */
    static async get<T>(url: string, setting?: AxiosRequestConfig) {
        return new BaseRequestServerRuoYI().get1<T>(url, {...setting});
    }

    /** post请求 */
    static async post<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        return new BaseRequestServerRuoYI().post1<T, P>(url, data, {...setting});
    }
    // 下载文件
    static async downloadFile(url: string, fileName?: string, contentType?: string, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("RouYiToken") as string;
        return new BaseRequestServerRuoYI().downloadFile(url, fileName, contentType, {...setting, headers: {token}});
    }
}

export class BaseRequestStaticServerRuoYiByToken {
    /** get请求 */
    static async get<T>(url: string, setting?: AxiosRequestConfig) {
        const RouYiToken = window.sessionStorage.getItem("RouYiToken") as string;
        return new BaseRequestServerRuoYI().get1<T>(url, {...setting, headers: {Authorization: RouYiToken}});
    }

    /** post请求 */
    static async post<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        const RouYiToken = window.sessionStorage.getItem("RouYiToken") as string;
        return new BaseRequestServerRuoYI().post1<T, P>(url, data, {...setting, headers: {Authorization: RouYiToken}});
    }

    /** put请求 */
    static async put<T, P>(url: string, data: P, setting?: AxiosRequestConfig) {
        const RouYiToken = window.sessionStorage.getItem("RouYiToken") as string;
        return new BaseRequestServerRuoYI().put1<T, P>(url, data, {...setting, headers: {Authorization: RouYiToken}});
    }

    /** delete请求 */
    static async delete(url: string, setting ?: AxiosRequestConfig) {
        const RouYiToken = window.sessionStorage.getItem("RouYiToken") as string;
        return new BaseRequestServerRuoYI().delete(url, {...setting, headers: {Authorization: RouYiToken}});
    }

    // 下载文件
    static async downloadFile(url: string, fileName?: string, contentType?: string, setting?: AxiosRequestConfig) {
        const token = window.sessionStorage.getItem("RouYiToken") as string;
        return new BaseRequestServerRuoYI().downloadFile(url, fileName, contentType, {...setting, headers: {token}});
    }
}
