/*
 * @license
 * @author: ZQ.xia
 * @from: antd
 * @link: https://github.com/ant-design/ant-design/edit/master/components/_util/type.ts
 * @created: 2019/2/21 10:22 AM
 */
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
// https://stackoverflow.com/questions/46176165/ways-to-get-string-literal-type-of-array-values-without-enum-overhead
export const tuple = <T extends string[]>(...args: T) => args;

/**
 * 是所有参数是可选的
 * @author: ZQ.xia
 * @date: 2019/2/27
 * @type: Partial
 */
export type Partial<T> = {
  [P in keyof T]?: T[P];
};
