import React from "react";
import { HeaderBox } from "../../containers/header-box";
import { PageBody, mapDataList, switchQiePianName } from "./style";
import Map from "./map";
import { ITable, SideBar4, Timeline8 } from "@gago/frame";
import { colorPalette } from "../../color-palette";
import { noop, sortBy } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { useProvider } from "../../utils/use-provider";
import BreedDistributeProvider from "../../network/providers/breeding-distribute";
import { BaseRequest } from "../../models/breeding-distribute";
import { EllipseRadioSelect, LocationSelect } from "@gago/lib/components";
import CommonProvider from "../../network/providers/common.provider";
import moment from "moment";
import UserProvider from "../../network/providers/user.provider";
import BreedingBigscreenProvider from "../../network/providers/breeding-bigscreen.provider";
/** render left1 */
const RenderLeft1 = (params: BaseRequest) => {
  const state = useProvider([params], BreedingBigscreenProvider.getAreaSummary.bind(BreedingBigscreenProvider));
  if (!state) {
    return null;
  }
  return (
      <AxisChart
        // chartData={getDataS2N1("region", ["识别面积", "统计面积"])}
        chartData={state}
        colorPalette={{ ...colorPalette, subColor: ["#13d7c8", "#e77300", ...colorPalette.subColor] }}
      />
  );
};

/** render left2 */
const RenderLeft2 = (params: BaseRequest) => {
  const state = useProvider([params], BreedingBigscreenProvider.getAreaDistribution.bind(BreedingBigscreenProvider));
  if (!state) {
    return <>暂无数据...</>;
  }
  return (
    <AxisChart
      basicConfigOption={{
        showCategoryAxisLine: false,
        showValueAxis: false,
        barWidth: 8,
        barBorderRadius: 7,
        showBarTopRect: false,
        showSeriesValueLabel: true,
        assignBarColor: true,
      }}
      theme={"horizontal"}
      // chartData={getDataS2N1("region", ["种植面积分布"], "", "万亩")}
      chartData={state}
      colorPalette={{
        ...colorPalette,
        subColor: ["#1df0ff", "#00c0ee"],
        assignColor: [
        ["#1df0ff", "#00c0ee"],
        ] ,
      }}
    />
  );
};

interface P {

}

interface S {
 /** 高亮的地区 */
  region: Region;
 /** 当前选择的作物类型 */
  cropType: string;
 /**时间轴 */
  timeList: string[];
 /**选中的时间 */
  selectedDate: string;
  /** 当前是否使用数管域名 */
  isDataManage: "on" | "off" | null;
  /** 当前选择的作物类型名称 */
  cropName?: string;
}

export default class BreedingDistribute extends React.PureComponent<P, S> {

  state: S = {
    region: {
      code: undefined,
      name: undefined,
      level: "city",
    },
    cropType: mapDataList[0].value,
    selectedDate: "2021",
    timeList: [],
    isDataManage: null,
  };

   /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
    this.setState({ region });
  }

  componentDidMount() {
    const params = {
      pageNum: 1,
      pageSize: 10,
      systemCode: "seedBreedDistribution",
    };
    UserProvider.getDomain(params).then(res => {
      const { cropType } = this.state;
      const data: "on" | "off" = res && res.rows && res.rows.length > 0 ? res.rows[0].systemStatus : "off";
      const provider = new BreedingBigscreenProvider(data);
      this.setState({ isDataManage: data }, () => {
        // changeServer(this.state.isDataManage);
        this.getBreedTime(cropType);
      });
    });
  }
  /**获取切片时间 */
  getBreedTime = async (cropName?: string) => {
    const { cropType, isDataManage } = this.state;
    console.log("****type", cropName);

    const crop = (cropName === "chilli" && isDataManage === "off") ? "wheat" : cropName;
    // const crop = cropName ? cropName : cropType;
    let timeData: string[] = [];
    if (isDataManage === "on") {
      timeData = await BreedingBigscreenProvider.getNewPublishTimeline(crop);
    } else {
      timeData = await CommonProvider.getPublishTimeline(`crop_${switchQiePianName(crop)}`);
    }
    this.setState({ timeList: timeData, selectedDate: timeData.length > 0 ? `${moment(timeData[timeData.length - 1]).years()}` : "" });
    // const yearList = timeData.map(item => moment(item).years());
    // const year = [...Array.from(new Set(yearList))];
    // const sortedYear = year.sort(( a, b) => a - b);
    // const latestTimeData = timeData.filter(item => moment(item).years() === sortedYear[sortedYear.length - 1]);
    // this.setState({timeData, date: latestTimeData[latestTimeData.length - 1]})
  }

  render() {
    const { region, cropType, cropName, isDataManage } = this.state;
    return (
      <HeaderBox
        names={["种质繁育分布"]}
        noPadding
      >
        <PageBody>
          <Map
            region={region}
            changeRegion={this.changeRegion}
            cropType={cropType === "chilli" ? "wheat" : cropType}
            cropName={cropName}
            // key={cropType}
            timeline={this.state.timeList}
            selectedDate={this.state.selectedDate}
            isDataManage={isDataManage}
          />
          {this.renderSidebar()}
          {this.state.timeList.length > 0 && this.renderTimeline()}

          {this.renderTypeSelect()}
        </PageBody>
      </HeaderBox>
    );
  }

  onTypeSelectChange = (type: string, name?: string) => {
    this.setState({ cropType: type, cropName: name });
    this.getBreedTime(type);
  }
  /**切换时间 */
  handleChangeTime = (value: string) => {
    // console.log(value, "selectedDate--->");
    this.setState({ selectedDate: value });
  }

  renderTimeline = () => {
    const { timeList, selectedDate } = this.state;
    const data = timeList.map(item => `${moment(item).years()}`);
    return (
      <div className="breed-timeline">
        <Timeline8
            hideGroup
            data={data}
            selected={selectedDate}
            colorPalette={colorPalette}
            loop={"all" as "all"}
            interval={5000}
            onChange={this.handleChangeTime}
        />
      </div>

    );
  }

  renderTypeSelect = () => {
    return (
        <EllipseRadioSelect
          style={{
            position: "absolute",
            top: 16,
            left: 16,
          }}
          data={mapDataList}
          colorPalette={colorPalette}
          backSelectChange={this.onTypeSelectChange}
        />
    );
  }

  /** render sider bar*/
  renderSidebar = () => {
    const { region, cropType, selectedDate, isDataManage } = this.state;
    return (
      <SideBar4
        className="breeding-distribute-side"
        colorPalette={colorPalette}
        sizeOnChange={noop}
        theme="light"
        style={{ width: 400, zIndex: 999 }}
        // title={"种质繁育"}
      >
        <McCard
          title={"识别面积"}
        >
         <div style={{ height: 200 }}>
          {isDataManage && <RenderLeft1 code={region.code} year={Number(selectedDate) || undefined} isDataManage={isDataManage}/>}
        </div>
        </McCard>
        <McCard
          title={"各地区种植面积分布"}
        >
         <div style={{ height: 200 }}>
          {isDataManage && <RenderLeft2 code={region.code} crop={(isDataManage === "off" && cropType === "chilli") ? "wheat" : cropType} year={Number(selectedDate) || undefined} />}
        </div>
        </McCard>
      </SideBar4>
    );
  }

}
