import React from "react";
import { BaseMap, BaseMapProps } from "../../containers/base-map";
import {
  ICSSProps,
  ClassificationLegend,
  GradientLegend1,
  globalColorPalette1,
} from "@gago/frame";
import { Region } from "../../models/common";
import { BaMengMap, baMengMapConfig } from "../../containers/bameng-map";
import {
  ClassificationLayer,
  GradientLayer,
  LercTileLayer,
  LercTileSource,
  Source,
  Layer,
} from "@gago-react-gl/gago-react-gl";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import moment from "moment";

interface P extends Partial<BaseMapProps>, ICSSProps {
  /** 高亮的地区 */
  region: Region;
  /** 切换高亮的地区 */
  changeRegion(region: Region): void;
  /**作物种类 */
  crop: string;
  date: string;
  //  lerc 起报时间
  startDate: string;
}

interface S {
  /** map */
  map?: mapboxgl.Map;
}

/**
 * map
 *
 */
export default class Map extends React.PureComponent<P, S> {
  state: S = {
    map: undefined,
  };

  onLoadMap = (map: mapboxgl.Map) => {
    this.setState({ map });
  };
  /** 根据作物类型判断，请求时间类型 */
  judgeCropTimeType = (type: string) => {
    switch (type) {
      case "wheat":
        return "ndvi8";
      case "corn":
        return "ndvi_corn8";
      case "sunflower":
        return "ndvi_sunflower8";
      case "grassland":
        return "ndvi_glass8";
      default:
        return "ndvi_sunflower8";
    }
  };

  render() {
    const { region, changeRegion, crop, date } = this.props;
    const cropName = this.judgeCropTimeType(crop);
    return (
      <>
        <BaseMap onLoad={this.onLoadMap}>
          <BaMengMap region={region} changeRegion={changeRegion} />
            {/* {this.renderMask()} */}
            {this.renderDiseaseLayer()}
        </BaseMap>
      </>
    );
  }

  /**渲染病害 */
  renderDiseaseLayer = () => {
    const { date, crop, startDate } = this.props;
    
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");

    const token = window.sessionStorage.getItem("token") as string;
    const cropName = crop === "grassland" ? "grass" : crop;
    console.log(startDate, "startDate");
    const source: LercTileSource = {
      id: crop,
      type: "lerc_v2",
      maxzoom: 8,
      roundZoom: true,
      tileSize: 256,
      minzoom: 1,
      headers: {
        token,
      },
      tiles: [
        `http://zhongzhi.grassict.cn/mc-lerc/api/v1/plant/breed/disease/${moment().format('YYYYMMDD')}/${cropName}/${time}/{z}/{x}/{y}.lerc`,
      ],
    };

    const colorValue: [number, string][] = [
      [-0.1, "#49b87d"],
      [1.1, "#bbe06c"],
      [2, "#ffe066"],
      [3, "#ff925c"],
      [4, "#e03a5c"],
      [5, "#e03a5c"],

      // [5.1, "#e61c30"],
    ];

    const layer: LercTileLayer = {
      id: crop,
      paint: {
        "raster-opacity": 0.85,
        "lerc-include": [[0, 5]],
        "raster-domain-min": 0,
        "raster-domain-max": 5,
        "raster-colorscale": {
          type: "interval",
          stops: [
            [0, "#49b87d"],
            [1, "#bbe06c"],
            [2, "#ffe066"],
            [3, "#ff925c"],
            [4, "#e03a5c"],
            [5, "#e03a5c"],
            // [5.1, "#e61c30"],
          ],
        },
        // "geojson-mask-source": baMengMapConfig.county.fill,
        "lerc-mask-source": `${crop}_mask`,
        "lerc-mask-include": [[0, 5]],
      },
      type: "lercv2" as "lercv2",
      source: crop,
      before: baMengMapConfig.county.line,
    };
    const colors = colorValue.map((item, index) => ({
      key: `${index}`,
      color: item[1],
      show: true,
    })).slice(0, -1);
    return (
      <>
        {this.renderMask()}
        {/* <ClassificationLayer<{
          c0: "c0";
        }>
            before={baMengMapConfig.county.line}
            key="sunflower_mask"
            id="sunflower_mask"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_mask/20210221/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_mask?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 5] }}
            insideActiveSorts={"all"}
          />
          <ClassificationLayer<{
            c0: "c0";
          }>
            before={baMengMapConfig.county.line}
            key="wheat_mask"
            id="wheat_mask"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_mask/20210206/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_mask?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 5] }}
            insideActiveSorts={"all"}
          />
          <ClassificationLayer<{
            c0: "c0";
          }>
            before={baMengMapConfig.county.line}
            key="wheat_mask"
            id="wheat_mask"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_mask/20210206/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_mask?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 5] }}
            insideActiveSorts={"all"}
          /> */}
        <Source<LercTileSource> {...source} key={crop} />
        <ClassificationLegend
          colors={colors}
          style={{ position: "absolute", bottom: "86px", right: "416px" }}
          names={["一级", "二级", "三级", "四级", "五级"]}
          colorPalette={globalColorPalette1}
          className="123"
        />
        <Layer {...layer} key={`${crop}_test`} />
      </>
    );
  };


  renderMask = () => {
    const { crop } = this.props;
    switch (crop) {
      case "sunflower":
        return (
          <ClassificationLayer<{
            c0: "c0";
          }>
            before={baMengMapConfig.county.line}
            key="sunflower_mask"
            id="sunflower_mask"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_mask/20210221/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_mask?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 5] }}
            insideActiveSorts={"all"}
          />
        );
        break;
      case "corn":
        return (
          <ClassificationLayer<{
            c0: "c0";
          }>
            before={baMengMapConfig.county.line}
            key="corn_mask"
            id="corn_mask"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_mask/20210207/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_mask?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 5] }}
            insideActiveSorts={"all"}
          />
        );
        break;
      case "wheat":
        return (
          <ClassificationLayer<{
            c0: "c0";
          }>
            before={baMengMapConfig.county.line}
            key="wheat_mask"
            id="wheat_mask"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_mask/20210206/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_mask?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 5] }}
            insideActiveSorts={"all"}
          />
        );
        break;
      default: return (
          <ClassificationLayer<{
            c0: "c0";
          }>
            before={baMengMapConfig.county.line}
            key="crop_mask_3"
            id="crop_mask_3"
            url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/crop_mask/20210207/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/crop_mask?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
            insideSorts={{ c0: [0, 5] }}
            insideActiveSorts={"all"}
          />
      )
    }

  }
}
