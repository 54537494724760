import { retainValidNumbers } from "@gago/frame";

/** 大屏标题颜色统一，用过个方法统一下 */
export const getTitleStyle = (color: string) => {
  return `
    width: 100%;
    height: 32px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${color};
    `;
};

/**
 * 对数字进行有效数字取值
 * effectiveNumber 保留几位有效数字，默认为自动
 */
export const numberFix = (oldNumber: number, effectiveNumber?: number): number => {
  if (typeof oldNumber !== "number") {
    return oldNumber;
  }
  const tempNumber = (typeof oldNumber === "number") ? oldNumber : 0;
  if (effectiveNumber === undefined) {
    return retainValidNumbers(oldNumber);
  }
  return Number(tempNumber.toFixed((typeof effectiveNumber === "number") ? effectiveNumber : 2));

};

/**
 * 数据归一化函数（将源数据区间映射到 0-1 之间）
 * @author ZQ.xia
 * @param source
 * @param beta
 */
export function normalize(source: number[], beta: number) {
  if (!Array.isArray(source)) {
    throw new TypeError("normalize source params is Array<number>");
  }
  const max = Math.max(...source);
  const min = Math.min(...source);

  if (max === min) {
    return 1;
  }

  return (beta - min) / (max - min);
}
