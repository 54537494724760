/**
 * 项目中滚动条的统一样式
 * @author luyaxiang
 * @created 2019/3/20
 */

const scrollStyle = `
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-button {
  }
  ::-webkit-scrollbar-track {
    width: 0px;
  }
  ::-webkit-scrollbar-track-piece {
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 48px;
    width: 0px;
    /** background: rgba(0,0,0,0.5); */
  }
  ::-webkit-scrollbar-corner {
  }
  ::-webkit-resizer {
  }
`;

export default scrollStyle;
