// tslint:disable: variable-name naming-convention jsx-no-multiline-js jsx-wrap-multiline
import React from "react";
import { Divider, Modal, Popover, Table } from "antd";
import { PaginationConfig, TableRowSelection } from 'antd/lib/table'
import { divide, isNull, noop } from "lodash";
import styled, { createGlobalStyle } from "styled-components";
import { delType, ExpertEditRequest } from "../../models/expert-database";


const StyleButton = styled.a`
  font-family: PingFangSC;
  font-size: 20px;
  color: #00adad;
`;

const GloabalStyle = createGlobalStyle`
  .ant-modal{
    width: auto!important;
  }
  .ant-modal-body{
    padding: 0px;
  }
`;

const titleList = [
  // {
  //   title: "姓名",
  //   key: "name",
  // },
  {
    title: "工作单位",
    key: "departmentName",
  }, {
    title: "职务（职称）",
    key: "position",
  }, {
    title: "专业特长",
    key: "specialty"
  },{
    title:'从业时间',
    key:'workingSeniority'
  },{
    title: "省份",
    key: "provinceName",
  }, {
    title: "市",
    key: "cityName",
  },{
    title: "专家类型",
    key: "type",
  },
];
// const columns: any[] = titleList.map((item, ind) => ({
//   title: item.title,
//   dataIndex: item.key,
//   ellipsis: true,
// }));


interface P {
  /** 表格数据 */
  // 删除信息
  deleteExpert:(params:delType) => void;
  // 表单数据---》数组
  tableResource?:ExpertEditRequest[];
  // 点击详情
  handleDetail:(parmas:ExpertEditRequest) => void;
  // 选中数据
  handleSelectChange?:(params:number[]) => void;
  // 已经选中的数据集合
  selectedRowKeys?:number[];
  // 点击编辑
  handleEdit:(params:ExpertEditRequest) => void;
  // 列表总数
  count?:number;
  // 处理分页数据
  handlePagination(config:PaginationConfig):void;
}

interface S {
  /** 选择的行数 的key集合 */
  
}

/**
 * 左侧第二个模块 表格组件
 * @author wangchengbing
 */
export class ExpertList extends React.PureComponent<P, S> {
  columns:any[];
  

  constructor(props: P) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
    };
    this.columns = [
      this.renderTableUser(),
      ...titleList.map((item,ind) => ({
        title: item.title,
        dataIndex: item.key,
        ellipsis: true,
      })),
      this.renderTableOperation(),
    ];
    // this.renderTableOperation();
  }

  renderTableUser = () => {
    return {
      title: "姓名",
      dataIndex: "name",
      render: (text:any,record:ExpertEditRequest) =>
        (<>
          <StyleButton onClick={(e) => {e.preventDefault(); this.props.handleDetail(record)}}>{text}</StyleButton>
        </>),
    };
  }
  



  /** render table operation */
  renderTableOperation = () => {
         
     return {
      title: "操作",
      fixed: "right",
      width: 150,
      render: (text:any,record:ExpertEditRequest) =>
        (<>
          {/* <StyleButton onClick={(e) => {e.preventDefault(); this.props.handleDetail(record)}}>详情</StyleButton>
          <Divider type="vertical"/> */}
          <StyleButton onClick={(e) => {e.preventDefault();this.props.handleEdit(record)}}>修改</StyleButton>
          <Divider type="vertical"/>
          <StyleButton onClick={(e) => { e.preventDefault;this.props.deleteExpert({id:record.id})}}>删除</StyleButton>
        </>),
    };

  }

  

  // 处理分页
  


  render() {
    const { selectedRowKeys, count, handleSelectChange, handlePagination } = this.props;
    const rowSelection:any = {
      selectedRowKeys,
      onChange: handleSelectChange
    };
    const h = window.document.documentElement.offsetHeight - 545;
    return (
      <>
        <Table
          className={"table-font-control"}
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={this.props.tableResource}
          scroll={{ x: "100%", y: `${h < 0 ? 100 : h}px` }}
          onChange = { handlePagination }
          pagination={
            {
              showQuickJumper: true,
              onShowSizeChange: noop,
              showSizeChanger: true,
              total: count ? count :undefined ,
              // pageSizeOptions:['5', '10', '20', '50']
              
              // tslint:disable-next-line: jsx-alignment
            }
          }
        />
      </>
    );
  }
}
