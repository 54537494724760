import React from "react";
import { HeaderBox } from "../../containers/header-box";
import { PageBody } from "./styled";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import Map from "./map";
import { EllipseRadioSelect, LocationSelect, GradualGauge,McBarChart, McLineChart } from '@gago/lib/components';
import { 
  BaseRequest,
  WeekDisease,
  DiseaseWarning,
  DiseaseWarningRequest,
  Phenophase,
  DiseaseMeasure
} from "../../models/disease-analysis";
import { RegionTree } from "../../models/land-evalute";
import LandEvaluateProvider from '../../network/providers/land-evaluate';
import DiseaseAnalysisProvider from '../../network/providers/disease-analysis';
// import { Card1 as Card } from "@gago/frame";
import { ITable, SideBar4,  } from "@gago/frame";
import { Timeline7 } from "@gago/lib/components/timeline-7";

import { noop } from "lodash";
import { McCard } from "../../containers/mc-card";
import { AxisChart } from "@gago/lib/components/axis-chart";
import { getDataS2N1 } from "../../utils/example-data";
import { Region } from "../../models/common";
import { Icon, Tag } from 'antd';
import { chart1N2D } from '../germplasm-breed/style';
import { BreedingTimeline } from '../../containers/breeding-timeline';
import moment from 'moment';
import { daysInfuture } from '../../utils/example-data';


export const mapDataList: {label: string; value: string}[] = [
  { label: "向日葵", value: "sunflower" },
  { label: "玉米", value: "corn" },
  { label: "小麦", value: "wheat" },
  // { label: "草地", value: "grassland" },
];


const levelColorMap:Types = {
  one:'#49b87d',
  two:'#bbe06c',
  three:'#ffe066',
  four:'#ff925c',
  five:'#e03a5c'
}
interface P {
 
}

interface S {
  /** 高亮的地区 */
  region: Region;
  
  /** 向日葵|玉米|小麦|草地*/
  crop:string;
  /**未来七天病害分析 */
  weekDisease:WeekDisease[];
  /** 各旗，区县灾害对比*/
  diseaseWarning:DiseaseWarning[]
  phenophaseList:Phenophase[];
  date:string;
  timeData:string[];
  diseaseMeasure:DiseaseMeasure;
  //lerc 起报时间
  startDate:string;
  // 地区列表
  areaList:RegionTree[];

}
type Keys = keyof DiseaseWarning;
type Types = {
  one:string;
  two:string;
  three:string;
  four:string;
  five:string;
}
const keyMap:Types = {
  one:'一级',
  two:'二级',
  three:'三级',
  four:'四级',
  five:'五级'
}

export default class GrowthMonitoring extends React.PureComponent<P, S> {

  state: S = {
    region: {
      code: '150800000',
      name: undefined,
      level: "city",
    },
    crop:'sunflower',
    weekDisease:[],
    diseaseWarning:[],
    phenophaseList:[],
    date:moment().format('YYYY-MM-DD'),
    timeData:daysInfuture(moment(), 8, "YYYY-MM-DD"),
    diseaseMeasure:{
      one:'',
      two:'',
      three:'',
      four:'',
      five:''
    },
    startDate:moment(new Date()).add(-1,'days').format('YYYYMMDD'),
    areaList:[]
  }

  componentDidMount() {
    this.initData();
    this.getArealist();
    // this.getPhenophsae();
    // this.getStartDate();

  }
  // 获取未来七天病害分析
  getWeekDisease = async () => {
    const { crop,  region: {code}} = this.state;
    const weekDisease = await DiseaseAnalysisProvider.getWeekDisease({crop, code:code as string});
    this.setState({weekDisease})
  }

  // 获取各区县病害分析
  getDiseaseWarning = async () => {
    const { crop, region: {code}, date} = this.state;
    const dateString = moment(date).format('YYYY-MM-DD')
    const diseaseWarning = await DiseaseAnalysisProvider.getDiseaseWarning({crop,date:dateString, code:code as string});
    this.setState({ diseaseWarning });
  }

  // 获取灾害等级相应措施
  getDiseaseMesaure = async () => {
    const { date , crop } = this.state;
    const diseaseMeasure = await DiseaseAnalysisProvider.getDiseaseMeasure({crop,date});
    this.setState({diseaseMeasure});
  }
  // 获取起报时间
  getStartDate = async () => {
    const data = await DiseaseAnalysisProvider.getStartDate();
    console.log(data,'dailyDate');
    const startDate = moment(data[data.length - 1]).format('YYYYMMDD')
    this.setState({startDate})
  }

  // 获取地区列表
  getArealist = async () => {
    const areaList = await LandEvaluateProvider.getRegionTree();
    this.setState({areaList})
  }

  initData = () => {
    this.getWeekDisease();
    this.getDiseaseWarning();
    this.getDiseaseMesaure();
  }

  //  切换作物类型
  onChangeCropType = ( value:string) => {
    
    this.setState({
      crop:value
    }, () => {
      this.initData();
      // this.handlePhnophase()
    })
  }
  /** 切换高亮的地区 */
  changeRegion = (region: Region) => {
    this.setState({region},()=> {
      this.initData();

      // this.handlePhnophase();
    })
  }

  
  getCropName = (crop:string) => {
    const targetCrop = mapDataList.find(item => item.value == crop);
    if (!targetCrop){return "向日葵"}
    return targetCrop.label;
  }
  
  changeDate = (date:string) => {
    console.log(date,'---->date')
    const time = date && moment(date, "YYYY-MM月DD日").format("YYYYMMDD");
    const year = date && moment(date, "YYYY-MM月DD日").year();
    this.setState({date:time})
  }
  getName = (crop:string) => {
    switch (crop) {
      case 'sunflower': return '向日葵';break;
      case 'corn': return '玉米';break;
      case 'wheat': return '小麦';break;
      case 'grassland': return '草地';break;
    }
  }

  // 时间轴改变时间
  handleChangeDate = (date:string) => {

    console.log(date,'cahngedate')
    this.setState({date},() => this.initData())
  }

  render () {
    const { region,timeData,crop, region:{code}, date, startDate } = this.state;
    const cropName = this.getCropName(crop)
    return (
      <HeaderBox
        names={["病害预警"]}
        noPadding
      >
        <PageBody>
          <Map changeRegion={this.changeRegion} region = {region} crop={crop} date = {date} startDate={startDate}/>
          {this.renderSidebar()}
          <EllipseRadioSelect data = {mapDataList} className="mapSelect" colorPalette= {colorPalette} backSelectChange={this.onChangeCropType}/>
          {/* {this.renderDiseaseMeasure()} */}
          {/* {timeData.length > 0 && <BreedingTimeline  wrapClassName='growthTimeLine' onChange={this.changeDate} data={timeData} crop={ cropName} code={ Number(code + '000')}/>}          */}
          <Timeline7 className="weatherTimeLine"  selected={date} data={timeData} colorPalette={colorPalette} loop={"all" as "all"} interval={5000} onChange = {this.handleChangeDate}/>

        </PageBody>
      </HeaderBox>
    );
  }
  renderDiseaseMeasure = () => {
    const { diseaseMeasure } = this.state;
    const values = Object.values(diseaseMeasure);
    const measureMap = Object.entries(diseaseMeasure).filter(item => item[1] != '');
    console.log(measureMap,'mesaureMap')
    if (values.every( item => item == '')) return null;
    return (
      <>
        <McCard
        title="灾害防治措施">

        <ul className="measureWrap">
          {measureMap.map(item => {
            return (
              <>
                <li className="levelItem">
                  <span className="levelColor" style ={{backgroundColor:levelColorMap[item[0] as string as keyof Types]}}></span>
                  <span className="levelName">{keyMap[item[0] as string as keyof Types]}</span>
                  <span className="levelMeasure">{item[1]}</span>
                </li>
              </>
            )
          })}
          
          {/* <li className="levelItem">
            <span className="levelColor"></span>
            <span className="levelName"></span>
            <span className="levelMeasure"></span>
          </li>
          <li className="levelItem">
            <span className="levelColor"></span>
            <span className="levelName"></span>
            <span className="levelMeasure"></span>
          </li>
          <li className="levelItem">
            <span className="levelColor"></span>
            <span className="levelName"></span>
            <span className="levelMeasure"></span>
          </li>
          <li className="levelItem">
            <span className="levelColor"></span>
            <span className="levelName"></span>
            <span className="levelMeasure"></span>
          </li> */}
        </ul>
      </McCard>

      </>
    )
  }
  // renderPhenophaseTag = () => {
  //   const { phenophaseList, date, crop, region:{ code }} = this.state;
  //   if (!phenophaseList || phenophaseList.length === 0 ) {
  //     return null;
  //   }
  //   const year = moment(date).year() || 2021
  //   const targetList = phenophaseList.filter(item => { return item.CODE === Number(code + '000') && item.CROP == this.getCropName(crop) });
  //   console.log(targetList,'targetList')
  //   const res = targetList.find(item => {
  //     if (moment(date).unix() >= moment(`${year}-${item.START}`).unix() && moment(date).unix() <= moment(`${year}-${item.END}`).unix()){
  //       return true
  //     } else {
  //       return false;
  //     }
  //   })
    
  //   return (
  //     <>
  //       <McCard
  //         title="理论物候期"
  //       >
  //        { res && <Tag color="#108ee9" >{res.PHE}</Tag> }
  //       </McCard>
  //     </>
  //   )

  // }

  // renderGrowthAnysis = () => {
  //   const { growthAnalysis } = this.state;
  //   const chartData:ITable<0,1> = {
  //     title: `${growthAnalysis.value}`,
  //     subtitle: `较上期${growthAnalysis.type === 'up' ? '上升' : '下降'}（%）`,
  //     description: "成熟度（%）",
  //     defaultValue: [0, 0],
  //     range: [[], [0, 10, 20, 40, 50, 60, 80, 90, 100]],
  //     head: ["ID", "较上期增长情况"],
  //     data: [
  //       ["0", growthAnalysis.value],
  //     ],
  //     suffix: ["", ""],
  //   };
  //   return (
  //     <>
  //       <McCard
  //       title="长势分析"
        
  //       >
  //         <div style={{height:'170px'}}>
  //           <GradualGauge
  //               chartData={chartData}
  //               colorPalette={{ ...colorPalette, subColor: ["#5a84ef", "#35a3de", "#40c2ad", "#31be59", "#73b500", "#f1e500", "#ffbb00", "#f78011", "#eb4c28"] }}
  //           />
  //         </div>
          
  //       </McCard>
  //     </>
  //   )
    
  // }

  renderDiseaseWarning = () => {
    const { diseaseWarning } = this.state;
    if (!diseaseWarning || diseaseWarning.length === 0) {
      return null;
    }
    

    let dataMap:{name:string;value:number;type:string}[] = []
    diseaseWarning.forEach(item => {
      const keyArray:Keys[]  = Object.keys(item) as Keys[];
      const chartDataArray = keyArray.filter(item => item !== "code" && item !== 'name').map(level => {
        return {
          name:item.name,
          value:item[level] as number,
          type:keyMap[level as keyof Types]
        }
      })
      dataMap = [...dataMap, ...chartDataArray]
    })
    const chartData:ITable<2,1> = {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", "单位"],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", "%"],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: dataMap.map<ITable<2, 1>["data"][0]>(item => ["", `${item.type}`, item.name, item.value * 100]),
    }
    
    return (
      <>
      <McCard
          title='各旗县各区域风险等级比较'
        >
          <div className="" style={{height:'150px'}}>

            <McBarChart
              chartData={chartData}
              chartStyle={chartStyle}
              ySplitNumber={5}
              barGap={-0.4}
              stackOne
              colorPalette={{ ...colorPalette, subColor: ["#49b87d", "#bbe06c", "#ffe066", "#ff925c", "#e03a5c", "#eb4c28"] }}
            />
          </div>

        </McCard>

      </>
    )
        
          
  }

  renderWeekDisease = () => {
    const { weekDisease, crop } = this.state;
    if (!weekDisease || weekDisease.length === 0) {
      return null;
    } 
    const chartData:ITable<2,1> = {
      title: "",
      subtitle: "",
      head: ["ID", "类型", "年份", ""],
      range: [[], [], [], []],
      prefix: ["", "", "", ""],
      suffix: ["", "", "", ""],
      defaultValue: [undefined, undefined, undefined, undefined],
      data: weekDisease.map<ITable<2, 1>["data"][0]>(item => ["", `${this.getName(crop)}`, moment(item.date).format('MM/DD') , Number(item.value.toFixed(2))])
      
    };
     return (
       <>

        <McCard
          title="未来七天风险指数"
        >
       <div style={{height:'150px'}}>

          <McLineChart
            hideLabel={true}
            chartData={chartData}
            showSymbol={false}
            chartStyle={chartStyle}
            ySplitNumber={5}
            colorPalette={pieChartColor}
          />
        </div>

        </McCard>
       
       </>
     )
     
  }

  // renderDisasterLevel = () => {
  //   return (
  //     <>
  //       <div className="levelWrapper">
  //         <p className="disasterName">旱情</p>

  //         <div className="level-container">
  //           <div className="levelItem">
  //             <span className="levelColor" style={{backgroundColor:'#438df8'}}></span>
  //             <span className="levelName">正常</span>
  //           </div>  
  //           <div className="levelItem">
  //             <span className="levelColor" style={{backgroundColor:'#ffc802'}}></span>
  //             <span className="levelName">轻度</span>
  //           </div>  
  //           <div className="levelItem">
  //             <span className="levelColor" style={{backgroundColor:'#f07a26'}}></span>
  //             <span className="levelName">中度</span>
  //           </div>  
  //           <div className="levelItem">
  //             <span className="levelColor" style={{backgroundColor:'#e9364e'}}></span>
  //             <span className="levelName">严重</span>
  //           </div>
  //         </div>
          
  //       </div>
  //     </>
  //   )
  // }
  // getName = (crop:string) => {
  //   switch (crop) {
  //     case 'sunflower': return '向日葵';break;
  //     case 'corn': return '玉米';break;
  //     case 'wheat': return '小麦';break;
  //     case 'grassland': return '草地';break;
  //   }
  // }
  // /** render sider bar*/
  judgeDesease = (crop:string) => {
    switch (crop) {
          case 'sunflower': return '黄萎病';break;
          case 'corn': return '玉米锈病';break;
          case 'wheat': return '小麦锈病';break;
          // case 'grassland': return '草地';break;
          default:return '黄萎病'
    }
  }
  renderSidebar = () => {
    // const { forecastType, } = this.state;
    const { crop, region:{code},  areaList } = this.state;
    let areaName = '';
    if (code === '150800000') {
      areaName = '巴彦淖尔市'
    } else {
      areaList.forEach(item => {
        if (item.code === code) {
          areaName = item.name
        }
      })
    }
    
    return (
      <SideBar4
        className="breeding-distribute-side"
        
        colorPalette={colorPalette}
        sizeOnChange={noop}
        theme="light"
        style={{ width: 400, zIndex: 999 }}
        title={ <>

            <span style={{position:'absolute',left:'10px'}}>{areaName}</span>
            <span>{this.judgeDesease(crop)}</span>

          </> }
        // title= {<EllipseRadioSelect colorPalette = {colorPalette} data={mapMeteorologicalList} className="meteorologicalSelect" backSelectChange={this.onChangeForecastType}/>}
      >
         {/* { forecastType === 'meteorological_warning' 
          ?  <MeteorologicalWarning data={ phenologicalList } weekForecast = {weekForecast} agricultureWarning={agricultureWarning}/> 
          :  <MeteorologicalHistory tmpList={tmpList} apcpList={apcpList} sumtmpList={sumtmpList} />} */}
        {/* {this.renderPhenophaseTag()} */}
        {this.renderDiseaseWarning()}
        {this.renderWeekDisease()}
        {this.renderDiseaseMeasure()}
        
      </SideBar4>
    );
  }

 }