import React from "react";
import { colorPalette } from "../../color-palette";
import { Card1, CardProps } from "@gago/frame";

interface P extends CardProps {

}

export class McCard extends React.PureComponent<P> {
  render() {
    return (
      <Card1
        title={
          <>
          <span
            style={{
              display: "inline-block",
              width: 4,
              height: 14,
              background: "#00adad",
              marginRight: 6,
            }}
          />
          <span>{this.props.title || ""}</span>
          </> as unknown as string
        }
        colorPalette={colorPalette}
        showExpandButton
      >
        {this.props.children}
      </Card1>
    );
  }
}