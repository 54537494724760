import { ResultType } from "../../models/common";
import * as DictionaryInterface from "../../models/dictionary-base";
import * as OperationInterface from "../../models/dictionary-operation";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";

/**
 * 数据字典 经营管理
 * @author maoshengtao
 */
export default class DictionaryBase extends BaseProvider {


  /** 公司数据列表 */
  static async getCompanyList() {
    const { data } = await BaseRequestStaticServer.get<ResultType<DictionaryInterface.ComponyList[]>>("/baseland/company", { });
    return data;
  }

  /** 表格 获取溯源大屏数据字典数据 */
  static async getExpandList(params: OperationInterface.ExpandListRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<OperationInterface.ExpandList>>("/plant/expand/list", {
      params,
    });
    return data;
  }

  /** 溯源大屏数据字典数据修改 */
  static async expandUpdate(params: OperationInterface.ExpandUpdateRequest) {
    const { data } = await BaseRequestStaticServer.post("/plant/expand/update", { 
      ...params,
    });
    return data;
  }

  /** 溯源大屏数据字典数据新增 */
  static async expandAdd(params: OperationInterface.ExpandAddRequest) {
    const { data } = await BaseRequestStaticServer.post("/plant/expand/add", { 
      ...params,
    });
    return data;
  }

  /** 溯源大屏数据字典数删除 */
  static async expandDel(params: OperationInterface.ExpandDelRequest) {
    const { data } = await BaseRequestStaticServer.post("/plant/expand/del", { 
      ...params,
    });
    return data;
  }
}
