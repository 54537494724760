import React from "react";
import { Layer, LineLayer, FillLayer } from "@gago-react-gl/gago-react-gl";
import { omit } from "lodash";

import { MAP_IDS } from "../config";

interface Props {
  /** 高亮地区的id */
  code: string | null;
  /** 高亮地区的name */
  name: string | null;
  /** 匹配的code */
  filterCode: string;
  /** 匹配的name */
  filterName: string;
  lineOption: Omit<LineLayer, "type">;
  fillOption: Omit<FillLayer, "type">;
}

/**
 * map HighLayer
 *
 * @export
 * @class MapHighLayer
 * @extends {React.PureComponent}
 */
export class MapHighLayer extends React.PureComponent<Props> {
  static defaultProps = {
    code: null,
    name: "proName",
    filterCode: "proCode",
    filterName: "proName",
    fillOption: {},
  };

  /** filter */
  getLayerFilter = () => {
    const { code, name, filterCode, filterName } = this.props;
    if (code) {
      return ["any", ["==", filterCode, Number(code)], ["==", filterCode, String(code)]];
    }
    return ["==", filterName, name];
  }

  /** HighLineLayer */
  renderHighLineLayer = () => {
    return (
      <Layer<LineLayer>
        type="line"
        // before={MAP_IDS.zIndex2}
        paint={{
          "line-color": "#f00",
          "line-width": 2,
          ...this.props.lineOption.paint,
        }}
        filter={this.getLayerFilter()}
        {...omit(this.props.lineOption, "paint")}
      />
    );
  }

  /** HighFillLayer */
  renderHighFillLayer = () => {
    return (
      <Layer<FillLayer>
        type="fill"
        // before={MAP_IDS.zIndex2}
        paint={{
          "fill-opacity": 0,
          "fill-color": "rgb(0, 229, 255)",
          ...this.props.fillOption.paint,
        }}
        filter={this.getLayerFilter()}
        {...omit(this.props.fillOption, "paint")}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderHighLineLayer()}
        {this.renderHighFillLayer()}
      </>
    );
  }
}
