import { ITable } from "@gago/frame";
import { BaseProvider } from "./base.provider";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { ResultType } from "../../models/common";
import { BaseRequest, ExpandComponyList, ExpandStatistics, ExpandAnalysis, ExpandPlant, ExpandSales, ExpandAppStatistics, ExpandFarmwork, ExpandProductTraceability, ExpandLot } from "../../models/extension-bigscreen";
import { PopularizeMapType } from "../../models/extension-screen";
import { round } from "lodash";

/**
 * 种质推广溯源一张图， 数据服务层
 * @author maoshengtao
 */
export default class ExtensionBigScreenProvider extends BaseProvider {

  /** 销售地图数据 */
  static async getPopularizeMap(params: { companyId?: string }) {
    const { data } = await BaseRequestStaticServer.get<ResultType<PopularizeMapType[]>>("/popularize/map", { params });
    return data;
  }
  
  /** 推广公司统计 */
  static async getExpandStatistics() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandStatistics[]>>("/plant/expand/statistics");

    const chartData: ITable<1, 1> = {
      title: "24153",
      subtitle: "总面积(万亩)",
      description: "",
      defaultValue: [0, 0, 0],
      range: [[], [], []],
      head: ["", "", ""],
      data: [],
      suffix: ["", "", "万亩"],
      prefix: ["", "", ""],
    }
    chartData.data = data.map((item, ind) => {
      return [`${ind}`, item.name, round(item.value, 2)];
    })

    return chartData;
  }

  /** 公司数据列表 */
  static async getCompanyList() {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandComponyList[]>>("/plant/expand/company", { });
    return data;
  }

  /** 销售渠道分析 */
  static async getExpandAnalysis(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandAnalysis[]>>("/plant/expand/analysis", { params });
    const chartData: ITable<1, 1> = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0],
      range: [[], [], []],
      head: ["", "", ""],
      data: [["", "线下展会", 5529], ["", "直营", 3493], ["", "电商", 6297], ["", "其他", 8774]],
      suffix: ["", "", "万元"],
      prefix: ["", "", ""],
    }
    chartData.data = data.map((item, ind) => {
      return [`${ind}`, item.name, round(item.value, 2)];
    })

    return chartData;
  }

  /** 企业年推广种植数量 */
  static async getExpandPlant(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandPlant[]>>("/plant/expand/plant", { params });
    const chartData: ITable<2, 1> = {
      title: "",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0],
      range: [[], [], [], []],
      head: ["", "", "", "单位:"],
      data: [],
      suffix: ["", "", "", "种"],
      prefix: ["", "", "", ""],
    };

    chartData.data = data.map((item, ind) => {
      return [`${ind}`, "企业年推广种质数量", item.year.toString(), round(item.value, 2)];
    })
    return chartData;
  }

  /** 企业销售统计 */
  static async getExpandSales(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandSales[]>>("/plant/expand/sales/statistics", { params });
    return data.reduce((pre, old) => {
      return {...pre, [old.name]: old.value }
    }, {}) as {
      [key: string]: number;
    } ;   
  }

  /** 天赋河套APP 统计 */
  static async getExpandAppStatistics(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandAppStatistics[]>>("/plant/expand/app/statistics", { params });
    
    return data.reduce((pre, old) => {
      return {...pre, [old.name]: old.value }
    }, {}) as {
      [key: string]: number;
    } ;
  }

  /** 农事记录 */
  static async getExpandFarmwork(params: BaseRequest) {
    // tslint:disable:ter-max-len
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandFarmwork>>("/plant/expand/farmwork", { params });
    const tableData: ITable<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9> = {
      title: "table表格",
      subtitle: "",
      description: "",
      defaultValue: [0, 0, 0, 0, 0],
      range: [[], [], [], [], []],
      head: ["序号", "操作日期", "操作内容", "操作面积", "操作人"],
      data: [],
      suffix: ["", "", "", "", ""],
    };
    tableData.data = data.list.map((item, ind) => {
      return [`${ind < 9 ? 0 : ""}${ind + 1}`, item.date || "——", item.content || "——", item.area ? `${item.area}亩` : "——", item.operator || "——"];
    });
   
    
    return tableData;
  }

  /** 产品溯源 */
  static async getExpandProductTraceability(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandProductTraceability>>("/plant/expand/productTraceability", { params });
    return data;
  }

  /** LOT 智能检测 */
  static async getExpandLot(params: BaseRequest) {
    const { data } = await BaseRequestStaticServer.get<ResultType<ExpandLot[]>>("/plant/expand/lot", { params });
    return data.reduce((pre, old) => {
      return {...pre, [old.type]: old.value }
    }, {}) as {
      [key: string]: number;
    };
  }
  
}
