import React from "react";
import styled from "styled-components";
import { ICSSProps, ITable } from "@gago/frame";
import { ScrollTableHead } from "../scroll-table-head";
import { AutoScroll, AutoScrollProps } from "../auto-scroll";
import { ScrollTableContent } from "../scroll-table-content";
import lineImg from "./img/line.png";

interface CP extends AutoScrollProps {
  /** 表格数据 */
  tableData: ITable<0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9, 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9>;
  /**点击事件 */
  handleClick(recordes: any): void;
  /**滚动条高度 */
  scrollHeight?:string
  scrollTime?:number
}

const Wrap = styled.div`
  height: 100%;
  position: relative;
  /* border-top: 2px solid; */
  /* border-bottom: 2px solid; */
  /* border-image: url(${lineImg});
  border-image-slice: 1;
  border-image-repeat: no-repeat; */
`;

/**
 * 滚动 表格
 * @author maoshengtao
 *
 */
export const ScrollTable = (props: CP & ICSSProps) => {
  const { style, className, tableData, handleClick,scrollHeight, ...other } = props;
  return (
    <Wrap style={style} className={className}>
      <ScrollTableHead headData={tableData.head} />
      <div style={{ height: scrollHeight ? scrollHeight:'200px' }}>
        <AutoScroll
          {...other}
        >
          <ScrollTableContent chartData={tableData} handleClick={handleClick}/>
        </AutoScroll>
        <div className="bottom-bg" />
      </div>
    </Wrap>
  );
};
