// tslint:disable: jsx-alignment jsx-no-multiline-js
import * as React from "react";
import { PopoverMark } from "../popover-mark";
import styled from "styled-components";
import { round } from "lodash";
import tinycolor from "tinycolor2";

/** 类型约束 */
export interface DistributeLayerType {
  /** 行政编码 */
  code?: string;
  /** 地区 */
  region: string;
  /** 值 */
  value: number;
  /** 中心点坐标 */
  center: number[];
  /** 单位 */
  unit?: string;
}

interface DistributeLayerProps<T> {
  mapMarker?: T[] | null;
  /** 颜色 */
  color: string;
  /** 单位 */
  unit?: string;
  /** 圆圈比例 */
  ratio?: number;
  markStyle?: React.CSSProperties;
}

interface MarkWrapProps {
  /** 半经 */
  radio: number;
  /** 颜色 */
  color: string;
}

// tslint:disable:variable-name
const MarkWrap = styled.div<MarkWrapProps>`
  width: ${(props) => (props.radio * 2)}px;
  height: ${(props) => (props.radio * 2)}px;
  border-radius: ${(props) => (props.radio)}px;
  transform: translate(-50%, -50%);
  line-height: 1;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgba(2, 20, 57, 0.45);
  border: solid 0.5px ${p => p.color};
  background-color: ${p => tinycolor(p.color).setAlpha(0.4).toString()};
  text-shadow: 0 2px 1px #45123f;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
  .marker-value{
    text-shadow: 0 2px 1px #45123f;
    font-family: BebasNeue;
    font-size: 32px;
    color: #e0feff;
  }
  .marker-region{
    margin-bottom: 4px;
  }
`;

/**
 * 地图-展示圆信息marker
 * @author luyaxiang
 * @extends {React.PureComponent}
 */
export class MapCircleLayer<T extends DistributeLayerType> extends React.PureComponent<DistributeLayerProps<T>> {
  /** 默认 */
  static defaultProps = {
    color: "#e64279",
  };

  render() {
    const { mapMarker, color, unit, ratio, markStyle } = this.props;

    if (!mapMarker) {
      return null;
    }
    return mapMarker.map((item) => {
      const tempValue = round(item.value, 1);
      const len = `${round(tempValue, 0)}`.length;
      const mark = (
        <MarkWrap
          radio={Math.max(len * (ratio || 10.5), 50)}
          color={color}
          style={markStyle}
        >
          <span className="marker-region">{item.region}</span>
          <span><span className="marker-value">{tempValue}</span>{item.unit || unit}</span>
        </MarkWrap>
      );
      return (
        <PopoverMark
          key={`${item.code}${item.center.join("")}`}
          coordinates={item.center}
          mark={mark}
        />
      );
    });
  }
}

