import styled from "styled-components";
import { ColorProps, GlobalColorPalette, ITable } from "@gago/frame";
import { Marker } from "@gago-react-gl/gago-react-gl";
import locationIcon from "./images/location-icon.png";
import topImg from "./images/top.png";
import closeIcon from "./images/close.png";

// tslint:disable:variable-name
export const BigScreenBody = styled.div<ColorProps<GlobalColorPalette>>`
  height: 100%;
  width: 100%;
  background-color: #09131f;
  background-image: url("/static/images/big-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .ant-form-item-label label{
    color:#65fbff;
    font-size:20px;
  }
  .DetailSearch {
    .ant-input {
      color:#e0feff;
      background-color: rgba(18, 53, 69, 0.65)
    }
    .ant-input-suffix > .ant-input-search-icon {
      color:#e0feff;

    }
  }
  .ant-table-placeholder {
    background-color: rgba(18, 53, 69, 0.65)

  }
  .ant-pagination {
    /* display:flex; */
    .ant-pagination-item-ellipsis {
      color:#e0feff!important;
    }
    .ant-pagination-item-active {
      & > a {
        font-weight:700;
      }
    }
    .ant-pagination-options {
      float:right;
      color:#e0feff;
    }
  }
    
  
  .header-box{
    /* height: 80px; */
    height: 110px;
    width: 100%;
    /* background-image: url("/static/images/top-title.svg"); */
    /* background-repeat: no-repeat;
    background-size: cover; */
    background-image: url('${topImg}');
    background-size: 100% 100%;
    position: relative;
    z-index: 3;
    .header-name{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 3px;
      color: #fff;
    }
  }
  .body-content{
    height: calc(100% - 80px);
    width: 100%;
    padding: 16px;
    .mc-chart-box{
      display: flex;
      justify-content: flex-start;
      height: 108px;
      > div{
        flex: 1 1 auto;
      }
      .w-296{
        width: 296px;
        margin-right: 16px;
      }
      .w-132{
        width: 132px;
        margin-right: 24px;
      }
      .w-270{
        width: 200px;
        margin-right: 16px;
      }
      .w-216{
        width: 200px;
      }
      .line-card-box{
        width: 211px;
        margin-right: 24px;
        .mt-8{
          margin-top: 8px;
        }
      }
      .w-162{
        width: 200px;
        margin-right: 16px;
      }
    }
    .mc-map-layer{
      margin-top: 16px;
      height: calc(100% - 124px);
      padding: 8px;
      position: relative;
      .changeTab {
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 100;
        width: 631px;
        padding: 0 16px;
        height: 233px;
        border: solid 1px rgba(0, 229, 255, 0.45);
        background-color: rgba(0, 42, 66, 0.8);
        transition: all 0.3s ease-out;
        overflow: hidden;
      }
      .ExpertInformation{
          position: absolute;
          left: 520px;
          bottom: 100px;
          width:490px;
          height:500px;
          border:solid 1px #6bc2bf;
          background-color: rgba(0,42,66,0.4);
          color:rgba(236, 255, 253, 1);
          padding: 10px;
          .ExpertTitle{
            display: flex;
            justify-content: space-between;
            border-bottom:solid 1px rgba(0, 253, 255, 0.5);
            font-size:16px;
            .closeBtn{
              height: 20px;
              width: 20px;
              background-image: url("${closeIcon}");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              display: block;
            }
          }
          .ExpertCont{
            overflow-y: scroll;
            height: 460px;
            padding: 5px 0;
            .ExpertImg{
              display:flex;
              img{
                width:130px;
                height:140px;
              }
              .baseInfo{
                display:flex;
                margin-left:10px;
                flex-direction: column;
                justify-content: center;
                .name{
                  color:rgba(101, 251, 255, 1);
                  font-size:20px;
                }
                .infoList{
                  div{
                    .infoTit{
                      color:rgba(101, 251, 255, 1);
                      margin-right:10px;
                    }
                  }
                }
              }
            }
            .ExpertCont1{
              .baseInfo1{
                width:49%;
                margin:1% 1% 1% 0;
                display:inline-block;
                background-color: rgba(0,42,66,0.5);
                padding:10px;
                .infoTit{
                  color:rgba(101, 251, 255, 1);
                  margin-right:10px;
                }
              }
              .baseInfo2{
                width:99%;
                margin:1% 1% 0 0;
                display:inline-block;
                background-color: rgba(0,42,66,0.5);
                padding:10px;
                .infoTit{
                  color:rgba(101, 251, 255, 1);
                  margin-right:10px;
                }
              }
            }
            .ExpertCont2{
              .contTit{
                color:rgba(101, 251, 255, 1);
                padding:10px 10px 10px 0;
              }
              .contBody{
                width:99%;
                background-color: rgba(0,42,66,0.5);
                padding:10px;
              }
             
            }
          }
      }
      .left-search{
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 100;
        width: 631px;
        padding: 0 16px;
        height: calc(100% - 32px);
        border: solid 1px rgba(0, 229, 255, 0.45);
        background-color: rgba(0, 42, 66, 0.2);
        transition: all 0.3s ease-out;
        -webkit-text-stroke: 0.1px #000e1b;
        overflow: hidden;
        .ant-table-header {
          overflow:auto!important;
          margin-bottom:0!important;
          padding-bottom:0!important;
          background-color: rgba(18, 53, 69, 0.65);

        }

        .ant-table-thead {
          
          & > tr > th {
            background-color: rgba(18, 53, 69, 0.65);
          }
          
        }

        .ant-table-body {
          background-color: rgba(18, 53, 69, 0.65);
          scrollbar-width: none; /* firefox */
          -ms-overflow-style: none; /* IE 10+ */
          &::-webkit-scrollbar {           
            display:none;
          }
          .ant-table-tbody > tr > td {
            color:#e0feff;
          }
        }
        .ant-table-row-cell-ellipsis .ant-table-column-title {
          color:#65fbff;

        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          /* background-color:#65feff; */
          /* opacity:0.25; */
          background-color: rgba(101, 254, 255, 0.15);
          /* opacity: 0.25; */
          /* background-color: #65fbff; */
          color:#65fbff;

        }

        .cateTitle  {
          height: 20px;
          margin: 24px 0 16px 0;
          font-family: PingFangSC;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #e0feff;
        }
        & > ul {
          list-style-type:none;
          font-size:20px;
          line-height:1;
          padding:0;
          height:calc(100% - 124px);
          overflow-y:auto;
          & > .summaryItem {
            margin-bottom:16px;
            font-weight: 500;
            color: #ff0;
            text-shadow: 2px 2px #000;
            & > .summaryItemCount {
              color: #ff0;
              text-shadow: none;
            } 
          }
          & > .summaryItem1 {
            color: #ff0;
            text-shadow: 2px 2px #000;
            font-weight: 500;
            & > .summaryItemCount1 {
              color: #ff0;
              text-shadow: none;
            } 
          }
        }
        & > ul {
          list-style-type:none;
          font-size:20px;
          line-height:1;
          color:#65fbff;
          padding:0;
          & > li > .summaryItemCount {
            color: #e0feff;
            margin-left: 10px;
          }
        }
        .ls-title{
          // height: 46px;
          height:72px;
          padding: 4px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .lst-left{
            display: flex;
            justify-content: space-around;
            width:calc(100% - 14px);
            align-items: center;
            .info-btn {
              border-radius:40px;
              /* background-color: #002a42; */
              background:rgba(0,42,66,0.45);
              /* opacity:0.45; */
              color:#65fbff;
              font-size:20px;
              border: solid 1px rgba(27, 208, 213, 0.45);
              width:100%;
              
            }
            .info-btn-active {
              border-radius:40px;
              background-color: #002a42;
              color:#e0feff;
              font-size:20px;
              background:linear-gradient(rgba(0, 252, 255, 0.65) , rgba(0, 60, 77, 0.9));
              width:100%;


            }
            .lstl-icon{
              height: 14px;
              width: 14px;
              background-image: url("/static/images/title-icon.svg");
              background-repeat: no-repeat;
              background-size: cover;
            }
            .lstl-value{
              margin-left: 8px;
              font-size: 24px;
              font-weight: 500;
              line-height: 1;
              color: rgb(236, 255, 253);
            }
          }
          .lst-right{
            cursor: pointer;
            font-size: 14px;
            color: #65fbff;
          }
        }
        .search-box{
          padding-top: 20px;
          .ant-input{
            border: solid 1px rgba(27, 208, 213, 0.45);
            background-color: rgba(18, 53, 69, 0.35);
            color: rgba(27, 208, 213, 0.65);
            &::-webkit-input-placeholder { /* WebKit browsers */
              color: rgba(27, 208, 213, 0.65);
            }

            &::-moz-placeholder { /* Mozilla Firefox 19+ */
              color: rgba(27, 208, 213, 0.65);
            }

            &:-ms-input-placeholder { /* Internet Explorer 10+ */
              color: rgba(27, 208, 213, 0.65);
            }
          }
          .ant-input-suffix{
            .ant-input-search-icon{
              color: rgba(27, 208, 213, 0.65);
            }
          }
        }
        .tree-border{
          padding-top: 16px;
          height: calc(100% - 112px);
          overflow-y: auto;
          overflow-x: hidden;
          .point-fill{
            margin-top: 0px;
          }
          .ant-tree{
            color: #65fbff;
            font-size: 20px;
            >li{
              line-height: 32px;
              &:first-child{
                padding-top: 0;
              }
              .ant-tree-switcher{
                height: 32px;
                line-height: 32px;
                .ant-tree-switcher-icon{
                  font-size: 16px;
                }
              }
              .ant-tree-node-content-wrapper{
                height: 32px;
                color: #65fbff;
                line-height: 32px;
                &::before{
                  height: 32px;
                }
                &:hover{
                  &::before{
                    background: rgba(101, 251, 255, 0.09);
                  }
                }
              }
              .ant-tree-node-selected{
                color: rgb(224, 254, 255);
                font-weight: 500;
                background: transparent;
                &::before{
                  background: rgba(101, 251, 255, 0.25);
                }
                &:hover{
                  background: transparent;
                  &::before{
                    background: rgba(101, 251, 255, 0.25);
                  }
                }
              }
            }
            .ant-tree-child-tree > li:first-child{
              padding-top: 0px;
            }
          }
        }
      }
      .right-chart{
        position: absolute;
        z-index: 100;
        bottom: 8px;
        right: 8px;
        width: 452px;
        height: 544px;
        transition: all 0.3s ease-out;
        .float-icon{
          cursor: pointer;
          height: 30px;
          width: 40px;
          border: solid 1px rgba(0, 229, 255, 0.45);
          line-height: 28px;
          text-align: center;
          font-size: 14px;
          color: #65fbff;
          position: absolute;
          top: -38px;
          right: 0;
        }
        .chart-border{
          height: 100%;
          width: 100%;
          border: solid 1px rgba(0, 229, 255, 0.45);
          background-color: rgba(0, 42, 66, 0.8);
          .h-110{
            height: 110px;
            padding-top: 16px;
            display: flex;
            justify-content: flex-start;
            .w-30{
              width: 30%;
              flex: 1 1 auto;
              margin-right: 8px;
              &:last-of-type{
                margin-right: 0;
              }
            }
          }
          .chart-mt-12{
            margin-top: 12px;
          }
          .h-200{
            height: 200px;
            padding-top: 12px;
            .img{
              width: 100%;
              height: 100%;
            }
            .vider-border{
              height: 100%;
              width: 100%;
              outline: none;
            }
          }
        }
      }
    }
  }
`;

// tslint:disable-next-line:one-variable-per-declaration
export const MarkerIcom = styled(Marker) <{ colorPalette: GlobalColorPalette }>`
  pointer-events: none;
  transition: zIndex 0.3s ease-out;
  z-index: 1;
  &:hover{
    z-index: 5;
  }
  .maker-box{
    position: absolute;
    pointer-events: auto;
    top: -32px;
    left: -16px;
    .icon-box{
      height: 32px;
      width: 32px;
      user-select: none;
      cursor: pointer;
      transition: all 0.3s ease-out;
      background-image: url("${locationIcon}");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .type-marker{
    position: absolute;
    pointer-events: auto;
    top: -1px;
    left: 0px;
    .icon-marker{
      height: 1px;
      width: 1px;
      user-select: none;
      background-color: transparent;
    }
    .prise-info-box{
      position: absolute;
      left: 16px;
      bottom: 1px;
      z-index: 99;
      padding: 0 16px;
      min-width: 200px;
      width: 400px;
      border: solid 1px rgba(0, 229, 255, 0.45);
      background-color: rgba(0, 42, 66, 0.8);
      transform: translateY(50%);
      .ls-title{
        // height: 46px;
        padding: 4px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .lst-left{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .lstl-icon{
            height: 14px;
            width: 14px;
            background-image: url("/static/images/title-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .lstl-value{
            margin-left: 8px;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.4;
            color: rgb(236, 255, 253);
          }
        }
        .lst-right{
          cursor: pointer;
          font-size: 14px;
          color: #65fbff;
          .gago-frame-jiage{
            transform: rotate(45deg);
          }
        }
      }
      .pib-border{
        padding: 16px 12px;
        .title-tool{
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          line-height: 1.2;
          margin-bottom: 16px;
          .tool-name{
            width: 83px;
            font-size: 18px;
            font-weight: 500;
            white-space: nowrap;
            color: rgb(27, 208, 213);
          }
          .tool-value{
            font-size: 18px;
            color: rgb(224, 254, 255);
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
          .tool-btn{
            
            margin-left:5px;
          }
        }
        .preview-img{
          max-width: 142px;
          max-height: 142px;
          overflow: hidden;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .info-img{
          width: 100%;
          max-width: 100%;
          .img-url{
            width: 100%;
            max-width: 100%;
            display: block;
          }
        }
      }
    }
  }
`;

/** 种质资源数据列表 */
export const germplasResourceData = [
  {
    title: "种质资源",
    key: "种质资源",
    children: [
      {
        title: "按照区域查询",
        key: "按照区域查询",
        children: [
          { title: "内蒙古", key: "code_150000000" },
          { title: "巴彦淖尔市", key: "code_150800000" },
        ],
      },
      {
        title: "按种质类型",
        key: "按种质类型",
        children: [
          { title: "沙生植物", key: "type_沙生植物" },
          { title: "作物", key: "type_作物" },
          { title: "其它", key: "type_其它" },
        ],
      },
    ],
  },
];
/** 园区基地范围及信息查询 */
export const baseDistrictPark = [
  {
    title: "一区多园N基地",
    key: "一区多园N基地",
    children: [
      { title: "核心区", key: "baseType_district" },
      { title: "专项产业园", key: "baseType_park" },
      { title: "良种繁育基地", key: "baseType_base" },
      // children: [
      //     { title: "小麦", key: "type_wheat" },
      //     { title: "蔬菜", key: "type_vegetables" },
      //     { title: "草业", key: "type_grass" },
      //     { title: "红花", key: "type_flower" },
      //     { title: "黄芪", key: "type_astra" },
      //     { title: "玉米", key: "type_corn" },
      //     { title: "向日葵", key: "type_sunflower" },
      // ]
    ],
  },
];

export const chart2N1D: ITable<2, 1> = {
  title: "",
  subtitle: "",
  head: ["ID", "类型", "年份", "单位"],
  range: [[], [], [], []],
  prefix: ["", "", "", ""],
  suffix: ["", "", "", "mm"],
  defaultValue: [undefined, undefined, undefined, undefined],
  data: [
    ["", "玉米", "2018", 120],
    ["", "水稻", "2018", 100],
    ["", "大豆", "2018", 90],
    ["", "玉米", "2019", 120],
    ["", "水稻", "2019", 100],
    ["", "大豆", "2019", 90],
    ["", "玉米", "2020", 120],
    ["", "水稻", "2020", 100],
    ["", "大豆", "2020", 90],
  ],
  // tslint:disable:max-file-line-count
};

export const cateMap = {
  GERMRESOURCE: "germresource",
  BASES: "bases",
  AREASPECIALTY: "areaspecialty",
  EXPERTDATABASE: "expertdatabase",
};

export const DataCardTable = {
  germresource: {
    cloumns: [
      {
        title: "专家姓名",
        dataIndex: "name",
      },
      {
        title: "专家特长",
        dataIndex: "specialty",
      },
      {
        title: "归属地",
        dataIndex: "provinceName",
      },
    ],
  },
};
