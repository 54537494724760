import React from "react";
import { BaseMap, BaseMapProps } from "../../containers/base-map";
import { ICSSProps, ClassificationLegend3, GradientLegend1, globalColorPalette1, ClassificationLegend } from "@gago/frame";
import { Region } from "../../models/common";
import { BaMengMap } from "../../containers/bameng-map";
import { ClassificationLayer, GradientLayer, ClassificationLegendProps } from "@gago-react-gl/gago-react-gl";
import { colorPalette, chartStyle, pieChartColor } from "../../color-palette";
import moment from "moment";

interface P extends Partial<BaseMapProps>, ICSSProps {
  /** 高亮的地区 */
  region: Region;
 /** 切换高亮的地区 */
  changeRegion(region: Region): void;
 /**作物种类 */
  cropName: string;
  date: string;
  timeData: string[];
  /** 当前是否使用数管域名 */
  isDataManage: "on" | "off" | null;
}

interface S {
  /** map */
  map?: mapboxgl.Map;
}

/**
 * map
 *
 */
export default class Map extends React.PureComponent<P, S> {

  state: S = {
    map: undefined,
  };

  onLoadMap = (map: mapboxgl.Map) => {
    this.setState({ map });
  }

  /* 判断切片请求类型*/
  judgeName = (cropName: string) => {
    switch (cropName) {
      case "yield_xrk_2021": return {
        names: ["160千克/亩以下", "160-190千克/亩", "190-220千克/亩", "220-250千克/亩", "250千克/亩以上"],
        insideSorts: { c0: [13000, 16000], c1: [16000, 19000], c2: [19000, 22000], c3: [22000, 25000], c4: [25000, 28000] }}; break;
      case "yield_maize_2021": return {
        names: ["400千克/亩以下", "400-500千克/亩", "500-600千克/亩", "600-700千克/亩", "700千克/亩以上"],
        insideSorts: { c0: [30000, 40000], c1: [40000, 50000], c2: [50000, 60000], c3: [60000, 70000], c4: [70000, 80000] }}; break;
      case "yield": return {
        names: ["220千克/亩以下", "220-240千克/亩", "240-260千克/亩", "260-280千克/亩", "280千克/亩以上"],
        insideSorts: { c0: [20000, 22000], c1: [22000, 24000], c2: [24000, 26000], c3: [26000, 28000], c4: [28000, 32000] }}; break;
      case "yield_grass_2021": return {
        names: ["38千克/亩以下", "38-46千克/亩", "46-54千克/亩", "54-64千克/亩", "62千克/亩以上"],
        insideSorts: { c0: [3000, 3800], c1: [3800, 4600], c2: [4600, 5400], c3: [5400, 6200], c4: [6200, 7000] }}; break;
      default: return {
        names: ["160千克/亩以下", "160-190千克/亩", "190-220千克/亩", "220-250千克/亩", "250千克/亩以上"],
        insideSorts: { c0: [13000, 16000], c1: [16000, 19000], c2: [19000, 22000], c3: [22000, 25000], c4: [25000, 28000] }};
    }
  }

  render() {
    const { region, cropName, date , timeData, changeRegion } = this.props;
    // const formatTime = moment(new Date().toDateString()).format("YYYYMMDD");
    const realTime = timeData.find(item => moment(item).format("YYYY") === date);
    console.log(timeData, date, "mapTimdatee");
    return (
      <>
        <BaseMap
          onLoad={this.onLoadMap}
        >
          <BaMengMap
            region={region}
            changeRegion={changeRegion}
          />

        {/* <GradientLayer
          id="ndvi_glass8"
          url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${realTime}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
          paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        >
          { colors => (
            <GradientLegend1
              {...{ ...colors }}
              style={{ position: "absolute", bottom: "16px", right: "416px" }}
              names={["220kg/亩以下", "220-240kg/亩", "240-260kg/亩", "260-280kg/亩","280kg/亩以上"]}
              colorPalette={globalColorPalette1}
            />
          )}
          </GradientLayer> */}
          {/* <ClassificationLayer<{
            c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
          }>
          id="yield_maize_2"
          url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${realTime}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
          paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
          insideSorts={{ c0: [30000, 40000], c1: [40000, 50000], c2: [50000, 60000], c3: [60000, 70000], c4: [70000, Infinity] }}
          insideActiveSorts={"all"}
          >
            { colors => (
              <ClassificationLegend

                {...{ ...colors }}
                style={{ position: "absolute", bottom: "16px", right: "416px" }}
                names={this.judgeName(cropName) as string[]}
                colorPalette={globalColorPalette1}
              />
            )}
          </ClassificationLayer> */}
          {this.renderProductionLayer()}
        </BaseMap>
      </>
    );
  }
  judgeCropType = (crop: string) => {
    switch (crop) {
      case "sunflower": return "yield_sunflower"; break;
      case "corn": return "yield_maize_2"; break;
      case "wheat": return "yield_wheat"; break;
      case "grassland": return "yield_grass"; break;
      default: return "yield_sunflower";
    }
  }

  renderProductionLayer =  () => {
    const { region, cropName, date , timeData, changeRegion, isDataManage } = this.props;

    const realTime = timeData?.find(item => moment(item).format("YYYY") === date);
    console.log(timeData, date, cropName, "mapTimdatee");
    // const cropName = this.judgeCropType(crop);
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
      }>
        id={cropName}
        key={cropName}
        url={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/${cropName}/${isDataManage === "on" ? this.matchDate1(cropName) : moment(realTime).format("YYYYMMDD")}/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        paintUrl={`http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/${cropName}?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92`}
        insideSorts={this.judgeName(cropName).insideSorts as unknown as any}
        insideActiveSorts={"all"}
      >
        { colors => (
          <ClassificationLegend
            {...{ ...colors }}
            style={{ position: "absolute", bottom: "86px", right: "416px" }}
            names={this.judgeName(cropName).names}
            colorPalette={globalColorPalette1}
          />
        )}
      </ClassificationLayer>
    );
  }

  renderSunflowerProduction = () => {
    const { region, cropName, date , timeData, changeRegion } = this.props;

    const realTime = timeData?.find(item => moment(item).format("YYYY") === date);
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
      }>
          id="yield_sunflower"
          url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/yield_sunflower/20201221/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
          paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/yield_sunflower?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
          insideSorts={{ c0: [13000, 16000], c1: [16000, 19000], c2: [19000, 22000], c3: [22000, 25000], c4: [25000, 28000] }}
          insideActiveSorts={"all"}
        >
          { colors => (
            <ClassificationLegend
              {...{ ...colors }}
              style={{ position: "absolute", bottom: "16px", right: "416px" }}
              names={["160千克/亩以下", "160-190千克/亩", "190-220千克/亩", "220-250千克/亩", "250千克/亩以上"]}
              colorPalette={globalColorPalette1}
            />
          )}
  </ClassificationLayer>
    );
  }
  renderWheatProduction = () => {
    const { region, cropName, date , timeData, changeRegion } = this.props;

    const realTime = timeData?.find(item => moment(item).format("YYYY") === date);
    return (
      <ClassificationLayer<{
        c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
      }>
        id="yield_wheat"
        url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/yield_wheat/20201219/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/yield_wheat?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
        insideSorts={{ c0: [20000, 22000], c1: [22000, 24000], c2: [24000, 26000], c3: [26000, 28000], c4: [28000, 32000] }}
        insideActiveSorts={"all"}
      >
        { colors => (
          <ClassificationLegend
            {...{ ...colors }}
            style={{ position: "absolute", bottom: "16px", right: "16px" }}
            names={["220千克/亩以下", "220-240千克/亩", "240-260千克/亩", "260-280千克/亩", "280千克/亩以上"]}
            colorPalette={globalColorPalette1}
          />
        )}
      </ClassificationLayer>
    );
  }
  renderCornProduction = () => {
    const { region, cropName, date , timeData, changeRegion } = this.props;

    const realTime = timeData?.find(item => moment(item).format("YYYY") === date);
    return (
        <ClassificationLayer<{
          c0: "c0"; c1: "c1"; c2: "c2"; c3: "c3"; c4: "c4";
        }>
          id="yield_maize_2"
          url={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/data/yield_maize_2/20201224/{z}/{x}/{y}/lerc?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
          paintUrl={"http://zhongzhi.grassict.cn/mc-lerc/publisher/v2/colour/yield_maize_2?token=cd61f0fc3660646bf84723cc62be7b587ed477223e142e692537cc3065e00f9577545892fd0562dcbb7911839489259a02e748659e1ff56827a1eba3bf5baf4fa047ea9fbfc3db740b66c7f2370715e00297a16e590da60a6e1f2bf69e4495c99774790d298ce1228d6d20f808b0851b1f60156ec2ac393efb14e2ae90bf5d92"}
          insideSorts={{ c0: [30000, 40000], c1: [40000, 50000], c2: [50000, 60000], c3: [60000, 70000], c4: [70000, 80000] }}
          insideActiveSorts={"all"}
        >
          { colors => (
            <ClassificationLegend
              {...{ ...colors }}
              style={{ position: "absolute", bottom: "16px", right: "416px" }}
              names={["400千克/亩以下", "400-500千克/亩", "500-600千克/亩", "600-700千克/亩", "700千克/亩以上"]}
              colorPalette={globalColorPalette1}
            />
          )}
        </ClassificationLayer>
    );
  }

  /** 匹配作物lerc特定日期 */
  matchDate1 = (type: string) => {
    if (type === "yield_maize_2021") {
      return "20201202";
    }
    if (type === "yield_xrk_2021") {
      return "20201203";
    }
    if (type === "yield") {
      return "20201201";
    }
  }

}
