import { Cascader } from "antd";
import { CascaderProps } from "antd/lib/cascader";
import React from "react";
import styled from "styled-components";
import { RegionTree } from "../../models/germplasm-database";
import GermplasmDatabaseProvider from "../../network/providers/germplasm-database.provider";

/** handle item */
const handleItem = (regionItem: RegionTree): CascaderProps["options"][number] => {
  if (regionItem.children && regionItem.children.length > 0) {
    return {
      value: regionItem.name,
      label: regionItem.name,
      children: regionItem.children.map(handleItem),
    };
  }
  return {
    value: regionItem.name,
    label: regionItem.name,
    children: undefined,
  };
}

/** handle region list */
const handleRegionTree = (regionTree: RegionTree[]) => {
  return regionTree.map((elm) => {
    return handleItem(elm);
  });
};

/**
 * 行政区划 option
 * @author maoshengtao
 */
export const regionCascaderOtption = async (): Promise<CascaderProps["options"]> => {
  const regionTree = await GermplasmDatabaseProvider.getRegionTree() || undefined;
  return handleRegionTree(regionTree);
}
