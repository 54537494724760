// tslint:disable: variable-name naming-convention jsx-no-multiline-js
import React from "react";
import { Form, Row, Col, Input, Button, Select, DatePicker, Upload, Icon, Modal, Empty } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/lib/form";
import bgicon from "./icon.png";
import { BaseInfo } from "../../models/germplasm-database";

const { Option } = Select;



const Wrap = styled.div`
  width: 100%;
  margin-top: 16px;
  .info{
    display: flex;
    align-items: center;
    font-family: PingFangSC;
    font-size: 16px;
    font-weight: 500;
    color: #001617;
    margin-bottom: 6px;
    .info-icon{
      width: 16px;
      height: 16px;
      background-image: url(${bgicon});
      background-size: 100% 100%;
      margin-right: 8px;
      margin-left: 8px;
    }
    .right-component{
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

const WrapForm = styled(Form)`
  padding: 16px 16px 0px;
  border-radius: 4px;
  background-color: rgba(241, 245, 245, 0.45);
  .ant-form-item {
    display: flex;
    margin-bottom: 12px;
  }
  /* .ant-form-item-control-wrapper {
    flex: 1;
  } */
`;

type P = {
  /** title */
  title: React.ReactNode;
  /** 一行几列 一行 24等分 默认 一列为6 即 一行4个*/
  colNum?: number;
  /** data */
  data?: BaseInfo;
  /** 右侧 头部*/
  rightComponent?: React.ReactNode;
};

interface S {
  /** 预览文件链接*/
  previewFile?: string;
}

/**
 * 生成 表单
 * @author maoshengtao
 */
class GenerateCategory extends React.Component<P, S> {
  state: S = {

  }

  /** 生成 form item */
  getFields = () => {
    const { colNum, data } = this.props;
    if (!data || data && data.list && data.list.length === 0) {
      return null;
    }
    return data.list.map((item) => {
      return (
        <Col span={colNum || 6} key={item.eName}>
          <Form.Item label={`${item.name}${item.unit ? `(${item.unit})` : ""}`}>
            {this.renderItemType(item)}
          </Form.Item>
        </Col>
      );
    })
  }

  /** render form item 类型 */
  renderItemType = (item: BaseInfo["list"][number]) => {
    if (item.type === "upload") {
       return (<Button onClick={this.handleOk.bind(this, `${item.value || ""}`)}>
                <Icon type="eye" /> 预览
              </Button>);
    }
    return <Input value={item.value || "——"} disabled />;

}

  /** preview ok */
  handleOk = (file: string) => {
    this.setState({
      previewFile: file,
    });
  }

  /** preivew cancle */
  handleCancel = () => {
    this.setState({
      previewFile: undefined,
    });
  }

  /** render upload preview */
  renderPreview = () => {
    const { previewFile } = this.state;
    if (!previewFile) {
      return null;
    }
    let child = <Empty description="不支持预览"/>;
    if (/.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(previewFile)) {
      child = <img src={previewFile} style={{ maxWidth: 400 }} />;
    }
    if (/pdf$/.test(previewFile)) {
      child = <iframe src={previewFile} width="100%" height={660} />;
    }
    return (
      <Modal
        title={`预览`}
        visible={!!this.state.previewFile}
        footer={<Button type={"primary"} onClick={this.handleCancel} >关闭</Button>}
        onCancel={this.handleCancel}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {child}
        </div>
      </Modal>
    );
  }

  render() {
    const { title, rightComponent } = this.props;
    return (
      <Wrap>
        <div className="info">
          <span className="info-icon" />
          <span>{title}</span>
          <div className="right-component">
            {rightComponent}
          </div>
        </div>
        {this.renderPreview()}
        <WrapForm>
          <Row gutter={24}>
            {this.getFields()}
          </Row>
        </WrapForm>
      </Wrap>
    );
  }
}

export { 
  GenerateCategory,
}
