import React, {PureComponent} from "react";
import styled from "styled-components";
import {TableComponent} from "./table/table";
import {TreeComponent} from "./list/list";
import {observer} from "mobx-react";
import {HeaderBox} from "../../containers/header-box";
// tslint:disable:variable-name
const ContainerWithStyle = styled.div`
    flex: auto;
    display: flex;
    justify-content: center;
    padding: 16px;
    width: 100%;
    height: calc(100vh - 100px);
`;

@observer
export class FieldOperations extends PureComponent {

    componentDidMount() {
        // UserManageService.getUserAndDepartmentList();
    }

    render() {
        return (
            <HeaderBox names={["田间操作"]}>
                <ContainerWithStyle>
                    <TreeComponent/>
                    <TableComponent/>
                </ContainerWithStyle>
            </HeaderBox>
        );
    }
}
