import styled from "styled-components";

/**
 * 登陆页:样式
 *
 * @author luyaxiang
 * @date 2019-4-9
 * @class Page1
 */

const formWidth = 415;
const formHeight = 315;
const inputAndButtonWith = 300;

// tslint:disable-next-line:variable-name
export const LoginBorder = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left: 0;
  overflow: hidden;
  background-color: #161616;
  z-index: 999;
  background: url("/static/images/bigping.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .lt-title{
    font-family: FZZZHONGJW-GB1-0;
    font-size: 32px;
    color: rgb(255, 255, 255);
    line-height: 1;
    letter-spacing: 4px;
    margin-bottom: 72px;
    text-align: center;
  }
  .logoBorder {
    display: flex;
    margin-bottom: 18px;
    align-items: center;

    .logoImg {
      width: 45px;
      margin-right: 10px;
    }
    .title {
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      letter-spacing: 4px;
      z-index: 1;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${formWidth + 10}px;
    height: ${formHeight + 10}px;
    border-radius: 16px;
    z-index: 1;
    position: relative;
    align-items: center;
    overflow: hidden;
    border: 1px solid #31ada5;
    .ant-form-item, .ant-btn-primary {
      width: ${inputAndButtonWith}px;
    }

    .ant-input-prefix {
      color: #31ada5 !important;
    }

    input {
      background-color: transparent;
      border-radius: 20px;
      -webkit-text-fill-color: white;
      caret-color:#31ada5;
      &:hover {
        border-color: #31ada5 !important;
      }

      &:focus {
        border-color: #31ada5 !important;

        .ant-input-prefix {
          color: #31ada5 !important;
        }
      }
    }
  }
  .login-title{
    height: 40px;
    width: 300px;
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
    text-indent: 8px;
  }
  .form-border {
    border: 1px solid #31ada5;
    border-radius: 16px;
    width: ${formWidth * 2}px;
    height: ${formHeight * 2}px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%) scale(0.5);
  }

  .cover-ant-button {
    border-radius: 20px;
    background-color: #31ada5;
    border-color: #31ada5;

    &:focus {
      background-color: #31ada5;
      border-color: #31ada5;
    }

    &:hover {
      background-color: #31ada5;
      border-color: #31ada5;
    }
  }
`;
