import React, { PureComponent } from "react";
import { ICSSProps, ColorProps, GlobalColorPalette, ICSS, Color } from "@gago/frame";
import styled from "styled-components";
import numberBg from "./bg@2x.png";
import icon1 from "./corn.svg";
import icon2 from "./rice.svg"
import icon3 from "./sunflower.svg"
import icon4 from "./grassland.svg"


// tslint:disable:variable-name ter-max-len completed-docs jsx-no-multiline-js
const CropAreaCardBody = styled.div<{colorPalette: GlobalColorPalette}>`
  height: 100%;
  width: 100%;
  padding: 16px 12px;
  background-image: url(${numberBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .header-title{
    font-family: PingFangSC;
    font-size: 16px;
    font-weight: 500;
    color: #00e5ff;
    .header-icon{
      display: inline-block;
      margin-right: 6px;
      width: 14px;
      height: 14px;
      background-image: url(${icon1});
      background-size: 100% 100%;

      &.icon-1 {
        background-image: url(${icon1});
      }
      &.icon-2 {
        background-image: url(${icon2});
      }
      &.icon-3 {
        background-image: url(${icon3});
      }
      &.icon-4 {
        background-image: url(${icon4});
      }
    }
  }
  .crop-value{
    padding-top: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    position: relative;
    .sv-value{
      min-width: 28px;
      height: 28px;
      font-size: 28px;
      line-height: 1;
      font-family: BebasNeue;
      color: #e0feff;
      margin-right: 4px;
    }
    .sv-suffix{
      font-size: 14px;
      line-height: 1;
      color: #e0feff;
    }
    .sv-bg{
      position: absolute;
      top: 0px;
      right: -4px;
      z-index: 0;
      height: 52px;
      width: 52px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
`;
type Cprops = {
  /** 标题 */
  title: string;
  /** 数量 */
  value: number | string;
  /** 单位 */
  suffix: string;
  /** icon 类型 */
  icon?: string
} & ICSSProps & ColorProps<GlobalColorPalette>;

/**
 * 第 3 种样式的card
 * @author maoshengtao
 */
export class Card3 extends PureComponent<Cprops> implements ICSS, Color {
  render() {
    const { colorPalette, style, className, title, value, suffix, icon } = this.props;
    return(
      <CropAreaCardBody colorPalette={colorPalette} style={style} className={className}>
        <div className="header-title">
          <span className={`header-icon ${icon}`} />
          <span>{title}</span>
        </div>
        <div className="crop-value">
          <span className="sv-value">{value}</span>
          <span className="sv-suffix">{suffix}</span>
        </div>
      </CropAreaCardBody>
    );
  }
}
