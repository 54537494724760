import React, { Component } from "react";
import { observable, action, transaction } from "mobx";
import { observer } from "mobx-react";
import { Tree, Icon, Popconfirm, message } from "antd";

import UserManageService from "../user-manage.service";
import UserManageStore, { TreeData } from "../user-manage.store";
import { DepartmentModalComponent, FormData } from "./department-modal";

import styles from "./tree.module.scss";

const ADMIN_DEPARTMENT_ID = "1";

const TreeNode = Tree.TreeNode;

@observer
export class TreeComponent extends Component {

  @observable title = "";
  @observable visible = false;
  @observable confirmLoading = false;
  @observable formData: FormData = {
    name: "",
    // @ts-ignore
    departmentId: null,
  };

  @action.bound
  private setConfirmLoading(value: boolean) {
    this.confirmLoading = value;
  }

  @action.bound
  private cancelModal() {
    this.title = "";
    this.visible = false;
  }

  private submitForm = async ({ departmentId, name }: FormData) => {
    this.setConfirmLoading(true);
    if (this.title === "添加子部门") {
      await UserManageService.createDepartment(departmentId, name);
    } else {
      await UserManageService.updateDepartment(departmentId, name);
    }
    await UserManageService.getUserAndDepartmentList();
    this.setConfirmLoading(false);
    this.cancelModal();
  }

  private async deleteDepartmentById(data: TreeData) {
    // @ts-ignore
    if (data.children.length > 0) {
      message.warn("该部门下还有子部门或员工，部门为空时才可删除！");
      return;
    }
    await UserManageService.deleteDepartment(Number(data.key));
    UserManageService.getUserAndDepartmentList();
  }

  @action.bound
  private updateDepartmentName(name: string, id: string) {
    transaction(() => {
      this.title = "编辑部门名称";
      this.visible = true;
      this.formData.name = name;
      this.formData.departmentId = id;
    });
  }

  @action.bound
  private createDepartment(id?: string) {
    transaction(() => {
      this.title = "添加子部门";
      this.visible = true;
      this.formData.name = "";
      // @ts-ignore
      this.formData.departmentId = id;
    });
  }

  renderCustomTreeNode(data: TreeData) {
    const { key: id, title } = data;
    return (
      <div className={styles.treeParent} onClick={() => UserManageStore.setCurrentDepartmentId(id)}>
        <span style={{ paddingRight: 5 }}>{title}</span>
        <span className={styles.treeParentActions}>
          <Icon type="edit" onClick={() => this.updateDepartmentName(name, id)} />
          <Popconfirm title="确认删除此部门吗？" onConfirm={() => this.deleteDepartmentById(data)}>
            <Icon type="minus-circle-o" />
          </Popconfirm>
          <Icon type="plus-circle-o" onClick={() => this.createDepartment(id)} />
          {/* {ADMIN_DEPARTMENT_ID === id ? <Icon type="plus-circle-o" onClick={() => this.createDepartment(id)} /> : null} */}
        </span>
      </div>
    );
  }

  renderTreeNodes = (data: TreeData[]) => {
    if (data.length <= 0) {
      return null;
    }
    return data.map((item) => {
      const { key, children, title } = item;
      if (Array.isArray(children)) {
        const childrenNode: any = this.renderTreeNodes(children);
        return (
          <TreeNode className={styles.treeActionWrap} icon={<Icon type="smile-o" />} title={this.renderCustomTreeNode(item)} key={key}>
            {childrenNode}
          </TreeNode>
        );
      }
      return <TreeNode icon={<Icon type="user" />} title={title} key={key} />;
    });
  }

  render() {
    return (
      <div className={styles.container}>
        {/* <Search placeholder="请输入用户名" style={{ width: 200 }} /> */}
        {
          UserManageStore.treeData.length > 0 ?
          <Tree showIcon defaultExpandedKeys={["root"]} switcherIcon={<Icon type="down" />}>
            {this.renderTreeNodes(UserManageStore.treeData)}
          </Tree> :
          null
        }
        <DepartmentModalComponent
          title={this.title}
          visible={this.visible}
          confirmLoading={this.confirmLoading}
          onCancel={this.cancelModal}
          data={this.formData}
          submitForm={this.submitForm}
        />
      </div>
    );
  }
}
