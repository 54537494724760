import { globalColorPalette1 } from "@gago/frame";
import { GlobalColorPalette as GCP, Color5Levels } from "@gago/frame/es/interface";
/**
 * 自定义全局调色板（包含高亮颜色）
 * @author luyaxiang
 * @date 2019.6.4
 */

export type GlobalColorPalette = GCP & {
  /** 高亮 */
  highLight: Color5Levels;
};

export const colorPalette: GlobalColorPalette = {
  ...globalColorPalette1,
  highLight: [
    "#a1ecde",
    "#74fff6",
    "#34cbe9",
    "#0d7da6",
    "#227c8e",
  ],
  mainColor: [
    "#f0fffb",
    "#d8fbf3",
    "#287768",
    "#bac5c4",
    "#a8c9d0",
    "#cfc8af",
    "#d1d1a6",
    "#1a6073",
    "#01374d",
    "#01263d",
  ],
  subColor: [
    "#f0f2f7",
    "#d9deef",
    "#faffc7",
    "#d0ff93",
    "#dcff67",
    "#e6f448",
    "#909748",
    "#222361",
    "#12123b",
    "#070614",
  ],
  neutralColor: [
    "#fafafa",
    "#f5f5f5",
    "#e8e8e8",
    "#d9d9d9",
    "#bfbfbf",
    "#8c8c8c",
    "#595959",
    "#262626",
  ],
};

export const colorPalette1: GlobalColorPalette = {
  ...colorPalette,
  mainColor: [
    "#f2fcfb",
    "#e3faf7",
    "#b6f4ed",
    "#73e6da",
    "#34d0c8",
    "#00adad",
    "#00888c",
    "#006166",
    "#00454a",
    "#032c30",
  ],
};
