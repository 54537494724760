import { ITable } from "@gago/frame";
import { ResultType } from "../../models/common";
import { 
  RegionTree,
  FertilizeMesuare,
  FertilizeRequest,
  SamplePointList,
  SampleRequest,
  LandReport,
  SampleDetail,
  SamplePoint
} from "../../models/land-evalute";
import { BaseRequestStaticServer } from "../servers/base-request.server";
import { BaseProvider } from "./base.provider";


/**
 * 繁育气候
 * @author wangchengbing
 * @date 2021-1-12
 * @export
 * @class 
 * @extends {BaseProvider}
 */
export default class LandEvaluateProvider extends BaseProvider {
    /** 获取行政区划 */
    static async getRegionTree() {
      const { data } = await BaseRequestStaticServer.get<ResultType<RegionTree[]>>("/plant/soil/tree");
      return data;
    }

    /** 获取施肥建议 */
    static async getFertilizeMesuare(params:FertilizeRequest) {
      const { data } = await BaseRequestStaticServer.get<ResultType<string[]>>("/plant/soil/opinion",{ params });
      return data;
    }

    /**获取土壤样点数据 */
    static async getSamplePoints(params:SampleRequest) {
      const { data } = await BaseRequestStaticServer.get<ResultType<SamplePoint[]>>("/plant/soil/samplingPointAll",{ params });
      return data;
    }

    /**获取土壤评分 */
    static async getLandRate(params:{id:number}) {
      const { data } = await BaseRequestStaticServer.get<ResultType<LandReport>>("/plant/soil/report",{ params });
      return data;
    }

    /**获取采样点详细信息 */
    static async getSampleDetail(params:number) {
      const { data } = await BaseRequestStaticServer.get<ResultType<SampleDetail>>(`/plant/soil/samplingPoint/${params}`,{ });
      return data;
    }
}
